var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop } from 'vue-property-decorator';
import CurrentUserMixin from '~/nasa_ui/mixins/CurrentUserMixin';
import HttpMixin from '~/nasa_ui/mixins/HttpMixin';
import { EntityType, EntityTypeSingularDisplay } from '~/nasa_ui/types';
import MiniActivity from '../MiniActivity/MiniActivity.vue';
import MiniChangeSet from '../MiniChangeSet/MiniChangeSet.vue';
import MiniContact from '../MiniContact/MiniContact.vue';
import MiniContract from '../MiniContract/MiniContract.vue';
import MiniDocument from '../MiniDocument/MiniDocument.vue';
import MiniEvent from '../MiniEvent/MiniEvent.vue';
import MiniLocation from '../MiniLocation/MiniLocation.vue';
import MiniOrganization from '../MiniOrganization/MiniOrganization.vue';
import MiniPhysicalInventoryAudit from '../MiniPhysicalInventoryAudit/MiniPhysicalInventoryAudit.vue';
import MiniUser from '../MiniUser/MiniUser.vue';
let UserFavorites = class UserFavorites extends Mixins(HttpMixin, CurrentUserMixin) {
    user = null;
    favorites = null;
    hideTitle;
    get hasFavorites() {
        return Boolean(this.allFavoritesFlattened.length);
    }
    get allFavoritesFlattened() {
        return Object.keys(this.favorites || {}).reduce((output, entityType) => {
            if (!this.favorites || !this.favorites[entityType]) {
                return [];
            }
            this.favorites[entityType].forEach((id) => {
                output.push({
                    entityType: entityType,
                    id
                });
            });
            return output;
        }, []);
    }
    get favoritesFlattened() {
        if (!this.favorites) {
            return [];
        }
        // remove these types because they are listed manually in the template
        const entityTypesListedManuallyInTemplate = [
            EntityType.ASSEMBLY,
            EntityType.ASSEMBLY_TEMPLATE,
            EntityType.HARDWARE_LIST,
            EntityType.HARDWARE_LIST_TEMPLATE,
            EntityType.INVENTORY,
            EntityType.ITEM_DRAWING,
            EntityType.ITEM_INSTANCE,
            EntityType.ITEM_MASTER,
            EntityType.USER_GROUP
        ];
        return Object.keys(this.favorites || {})
            .filter((key) => !entityTypesListedManuallyInTemplate.includes(key))
            .reduce((output, entityType) => {
            if (!this.favorites || !this.favorites[entityType]) {
                return [];
            }
            this.favorites[entityType].forEach((id) => {
                output.push({
                    entityType: entityType,
                    id,
                    code: id.code,
                    numberForMini: id.number
                });
            });
            return output;
        }, [])
            .sort();
    }
    created() {
        this.fetchEntity();
    }
    async fetchEntity() {
        try {
            // using current user since you can only view your own favorites
            const resp = await this.httpGet(`/users/current`);
            this.user = resp?.data;
            this.favorites = this.currentUserActiveSupportContext
                ? this.user?.attributes?.favorites?.[this.currentUserActiveSupportContext]
                : {};
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    miniByFavorite(favorite) {
        const miniName = EntityTypeSingularDisplay.get(favorite.entityType)
            ? `Mini${EntityTypeSingularDisplay.get(favorite.entityType).replace(/\s/g, '')}`
            : '';
        return miniName;
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", typeof (_a = typeof Boolean !== "undefined" && Boolean) === "function" ? _a : Object)
], UserFavorites.prototype, "hideTitle", void 0);
UserFavorites = __decorate([
    Component({
        components: {
            MiniActivity,
            MiniChangeSet,
            MiniContact,
            MiniContract,
            MiniDocument,
            MiniEvent,
            MiniLocation,
            MiniOrganization,
            MiniPhysicalInventoryAudit,
            MiniUser
        }
    })
], UserFavorites);
export default UserFavorites;
