var _a, _b, _c, _d;
import { __decorate, __metadata } from "tslib";
import { FetchPolicy } from 'apollo-client';
import { find } from 'lodash';
import { Debounce } from 'lodash-decorators';
import isBoolean from 'lodash/isBoolean';
import { Component, Emit, Mixins, Prop, Vue, Watch } from 'vue-property-decorator';
import { EntityType, EntityTypeDisplayMap, OutputMode } from '~/nasa_ui/types';
import { transformEntitiesToTableFormat } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
import { DEFAULT_ROWS_PER_PAGE_ITEMS } from '../constants/tablePagination';
import BaseCosmic from './BaseCosmic';
// Taken from https://dev.to/kingdaro/indexing-objects-in-typescript-1cgi
function hasKey(obj, key) {
    return key in obj;
}
function transformEntitySubType(entityType, filters) {
    return (key) => {
        const value = filters[key];
        if (value === undefined) {
            return;
        }
        if (key === 'subType' && entityType) {
            return {
                text: EntityTypeDisplayMap.get(entityType).get(value) || '?',
                value: key,
                color: 'primary',
                close: true,
                outline: true
                // textColor?: string
            };
        }
        return;
    };
}
export function srFilterOutSelected(node) {
    return !(node && node.__SELECTED);
}
let BaseSearch = class BaseSearch extends Mixins(BaseCosmic) {
    entityType;
    hint;
    isLoading = false;
    placeholder = 'search';
    rawSearchResponse = null;
    SEARCH_QUERY;
    searchFilters = {};
    searchInput = '';
    searchResults = null;
    searchTermKey = 'query';
    showFilters = false;
    showTable = false;
    userSelectedMaxResults = 500;
    // User wanted more default results on the first page for hardware searches
    defaultHardwareTablePagination = [50, 100, 250, { text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 }];
    //#region Props
    autofocus;
    autosearch;
    fetchPolicy;
    filters;
    // If you plan on handling the results of the search yourself
    // The Selector____ components use this
    forceHideResults;
    initialRawSearchResponse;
    keepSearchResultsOpen;
    keepSearchValueAfterSelection;
    existingValues;
    lockedFilters;
    noReset;
    outputMode;
    rowsPerPageItems;
    showFiltersOnCreate;
    showFilterButton;
    showMaxResults;
    //#endregion
    //#region Computed getters
    get anyFiltersCurrentlyActive() {
        return this.chipsFromFilters.length > 0;
    }
    get chipsFromFilters() {
        if (!this.pureSearchFilters || Object.keys(this.pureSearchFilters).length === 0) {
            return [];
        }
        const subTypeAppChips = Object.keys(this.pureSearchFilters).map(transformEntitySubType(this.entityType ?? null, this.pureSearchFilters));
        return [...subTypeAppChips].filter((key) => key !== undefined);
    }
    get entityTypeDisplay() {
        if (!this.entityType) {
            return '';
        }
        return this.$typeDisplay(this.entityType);
    }
    /**
     * Returns the variables required to perform the search query
     */
    get gqlSearchQueryVariables() {
        const base = {
            firstResults: this.userSelectedMaxResults
        };
        const query = {};
        query.query = this.searchInput || null;
        const filters = this.pureSearchFilters;
        const variables = Object.assign({}, base, filters, query);
        return variables;
    }
    get isOutputModeString() {
        return this.outputMode === OutputMode.string;
    }
    /**
     * Intended to be a representation of the filters with empty string | empty [] | undefined | null properties removed
     */
    get pureSearchFilters() {
        const clone = Object.assign({}, this.searchFilters);
        const keys = Object.keys(clone);
        keys.forEach((key) => {
            if (isBoolean(clone[key])) {
                return;
            }
            if (!clone[key] || (Array.isArray(clone[key]) && clone[key].length === 0)) {
                delete clone[key];
            }
        });
        return clone;
    }
    get resultTableTitle() {
        return !this.searchResults
            ? this.entityTypeDisplay
            : `Found <span class="monospace_font">${this.searchResults.length}</span> ${this.entityTypeDisplay} results`;
    }
    get shouldShowTable() {
        if (this.forceHideResults) {
            return false;
        }
        return Boolean(this.showTable && this.searchResults);
    }
    get shouldShowFilters() {
        return this.showFilters;
    }
    // this is a potentially expensive operation, so we do it only when the
    // underlying search response changes.
    get transformedSearchResponse() {
        if (!this.rawSearchResponse) {
            return [];
        }
        if (!this.entityType) {
            throw new Error('An entityType is required to transform search results in BaseSearch. Provide an entityType or override this getter.');
        }
        let results;
        if (Array.isArray(this.rawSearchResponse)) {
            // if it's already an array, just use it as is.
            results = this.rawSearchResponse;
        }
        else if (Array.isArray(this.rawSearchResponse.results)) {
            // if it's an api2 object, get the results array
            results = this.rawSearchResponse.results;
        }
        else {
            // if it's a search result coming from the old gql api, find the `nodes` array
            results = find(this.rawSearchResponse, 'nodes')?.nodes || [];
        }
        return transformEntitiesToTableFormat(this.entityType, [...results]);
    }
    //#endregion
    //#region Vue Lifecycle Hooks
    created() {
        this.initializeSearchInput();
        // this.showFilters = this.showFiltersOnCreate;
    }
    /**
     * Checks to see if the filter by the name passed is supposed to be a locked field
     */
    isFilterLocked(filterName) {
        return this.lockedFilters.includes(filterName);
    }
    buildAppChipForProperty(propertyName, color = 'primary', displayMap) {
        let searchFilterValue = this.$get(this, `pureSearchFilters.${propertyName}`);
        if (!searchFilterValue) {
            return [];
        }
        if (searchFilterValue && !Array.isArray(searchFilterValue)) {
            // @ts-ignore
            searchFilterValue = [searchFilterValue];
        }
        return searchFilterValue
            .map((value) => {
            const text = displayMap ? displayMap.get(value) : value;
            const hasProperty = value !== '' && value !== undefined && value !== null;
            if (!hasProperty) {
                return;
            }
            const isPropertyLocked = this.isFilterLocked(propertyName);
            const appChip = {
                close: !isPropertyLocked,
                color,
                disabled: isPropertyLocked,
                filterType: propertyName,
                outline: true,
                text: text || '?',
                value: value
            };
            return appChip;
        })
            .filter((chip) => chip !== undefined);
    }
    initializeSearchInput() {
        if (this.searchFilters.hasOwnProperty(this.searchTermKey)) {
            this.searchInput = this.searchFilters[this.searchTermKey];
        }
    }
    onClearAllFilters() {
        const lockedSearchFilters = this.lockedFilters.map((filterKey) => {
            const filter = {};
            filter[filterKey] = this.searchFilters[filterKey];
            return filter;
        });
        // keep locked filters
        this.searchFilters = Object.assign({}, ...lockedSearchFilters);
    }
    onClickOfRemoveFilter(filterToRemove) {
        const searchFilters = Object.assign({}, this.searchFilters);
        if (filterToRemove.filterType && hasKey(searchFilters, filterToRemove.filterType)) {
            if (Array.isArray(searchFilters[filterToRemove.filterType]) &&
                searchFilters[filterToRemove.filterType].includes(filterToRemove.value)) {
                const index = searchFilters[filterToRemove.filterType].indexOf(filterToRemove.value);
                if (index > -1) {
                    searchFilters[filterToRemove.filterType].splice(index, 1);
                }
                // remove the filter category if no items left
                if (searchFilters[filterToRemove.filterType].length === 0) {
                    delete searchFilters[filterToRemove.filterType];
                }
                Vue.set(this, 'searchFilters', searchFilters);
            }
            else {
                // handle filter enums where only one option can be selected
                delete searchFilters[filterToRemove.filterType];
                Vue.set(this, 'searchFilters', searchFilters);
            }
        }
    }
    onClickOfFilterIcon() {
        this.showFilters = !this.showFilters;
    }
    onClickOfSearchButton() {
        this.emitSearchButtonClicked();
        this.emitTermSearched();
        this.performSearch();
        this.showTable = true;
    }
    resetSearchResults() {
        if (this.keepSearchResultsOpen) {
            return;
        }
        this.searchResults = null;
        this.rawSearchResponse = null;
    }
    reset() {
        if (!this.keepSearchValueAfterSelection) {
            this.searchInput = '';
        }
        this.resetSearchResults();
        this.showFilters = false;
        this.showTable = this.autosearch || this.keepSearchResultsOpen ? true : false;
    }
    async performSearch() {
        try {
            this.isLoading = true;
            // Execute query
            const response = await this.$apollo.query({
                query: this.SEARCH_QUERY,
                variables: this.gqlSearchQueryVariables,
                fetchPolicy: this.fetchPolicy
            });
            this.isLoading = false;
            // Set the raw response in case we need that
            this.rawSearchResponse = response.data;
            this.emitSearchPerformed();
        }
        catch (error) {
            console.log(error);
            this.$errorUtility({ err: error });
        }
    }
    /**
     * When the search response changes, we need to mark selected items if any and
     * update the search results.
     */
    onChangeOfTransformedResponse(val) {
        if (!val) {
            this.$set(this, 'searchResults', []);
            return;
        }
        const results = this.addSelectedPropIfSelected(val);
        this.$set(this, 'searchResults', results.filter(srFilterOutSelected));
    }
    resultsUpdated() {
        return this.searchResults;
    }
    emitSearchButtonClicked() {
        return this.pureSearchFilters;
    }
    emitSearchPerformed() {
        return {
            filters: { ...this.pureSearchFilters },
            // api v2 returns an array of results
            response: Array.isArray(this.rawSearchResponse) ? [...this.rawSearchResponse] : { ...this.rawSearchResponse },
            searchInput: this.searchInput,
            searchTermKey: this.searchTermKey
        };
    }
    emitTermSearched() {
        return this.searchInput;
    }
    onClickOfSearchResult(selectedItem) {
        this.reset();
        if (this.isOutputModeString && selectedItem.nodeId) {
            return selectedItem.nodeId;
        }
        else if (this.isOutputModeString && selectedItem.id) {
            return selectedItem.id;
        }
        else if (this.isOutputModeString) {
            new Error('The search control is in string output mode but no nodeId or id found on the selected item. You may need to override this method.');
            return;
        }
        return selectedItem;
    }
    onMetaClickOfSearchResult(selectedItem) {
        if (this.isOutputModeString && selectedItem.nodeId) {
            return selectedItem.nodeId;
        }
        else if (this.isOutputModeString && selectedItem.id) {
            return selectedItem.id;
        }
        else if (this.isOutputModeString) {
            new Error('The search control is in string output mode but no nodeId or id found on the selected item. You may need to override this method.');
            return;
        }
        return selectedItem;
    }
    onAutosearchChange(val) {
        if (val) {
            this.onClickOfSearchButton();
        }
    }
    onChangeOfSearchFilter(val, old) {
        // prevent initializeSearchInput from causing an infinite event loop
        if (JSON.stringify(val) === JSON.stringify(old)) {
            return;
        }
        this.initializeSearchInput();
    }
    onChangeOfFilterProp(newFilters) {
        if (!newFilters) {
            return;
        }
        this.$set(this, 'searchFilters', { ...this.searchFilters, ...newFilters } || { attributes: {} });
    }
    onChangeOfEntityTypeProp(newEntityType) {
        this.entityType = newEntityType;
        this.reset();
    }
    /**
     * Adds the property __SELECTED to the results so the searches can filter it out if needed
     */
    onExistingValueChange() {
        if (this.existingValues && this.transformedSearchResponse) {
            let data = [...this.transformedSearchResponse];
            // add __SELECTED and pull up nodes a level
            data = this.addSelectedPropIfSelected(data || []);
            this.$set(this, 'searchResults', data.filter(srFilterOutSelected));
        }
    }
    onInitialRawSearchResponseChange(resp) {
        if (resp) {
            this.rawSearchResponse = resp;
            this.showTable = true;
        }
    }
    addSelectedPropIfSelected(data) {
        if (!data || !this.existingValues) {
            return data || [];
        }
        return data.map((node) => {
            if (!node.nodeId && !node.id) {
                return node;
            }
            let ev = this.existingValues || [];
            if (!Array.isArray(ev)) {
                ev = [ev];
            }
            node.__SELECTED = ev.includes(node.nodeId) || ev.includes(node.id);
            return node;
        });
    }
};
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], BaseSearch.prototype, "autofocus", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], BaseSearch.prototype, "autosearch", void 0);
__decorate([
    Prop({
        type: String,
        default: 'network-only'
    }),
    __metadata("design:type", typeof (_a = typeof FetchPolicy !== "undefined" && FetchPolicy) === "function" ? _a : Object)
], BaseSearch.prototype, "fetchPolicy", void 0);
__decorate([
    Prop({ default: () => { } }),
    __metadata("design:type", Object)
], BaseSearch.prototype, "filters", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], BaseSearch.prototype, "forceHideResults", void 0);
__decorate([
    Prop({
        required: false
    }),
    __metadata("design:type", Object)
], BaseSearch.prototype, "initialRawSearchResponse", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], BaseSearch.prototype, "keepSearchResultsOpen", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], BaseSearch.prototype, "keepSearchValueAfterSelection", void 0);
__decorate([
    Prop({
        type: Array
    }),
    __metadata("design:type", Array)
], BaseSearch.prototype, "existingValues", void 0);
__decorate([
    Prop({ default: () => [], type: Array }),
    __metadata("design:type", Array)
], BaseSearch.prototype, "lockedFilters", void 0);
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], BaseSearch.prototype, "noReset", void 0);
__decorate([
    Prop({ default: OutputMode.string, type: [String, String] }),
    __metadata("design:type", Object)
], BaseSearch.prototype, "outputMode", void 0);
__decorate([
    Prop({
        default: () => DEFAULT_ROWS_PER_PAGE_ITEMS
    }),
    __metadata("design:type", typeof (_c = typeof Array !== "undefined" && Array) === "function" ? _c : Object)
], BaseSearch.prototype, "rowsPerPageItems", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], BaseSearch.prototype, "showFiltersOnCreate", void 0);
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], BaseSearch.prototype, "showFilterButton", void 0);
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], BaseSearch.prototype, "showMaxResults", void 0);
__decorate([
    Debounce(200),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], BaseSearch.prototype, "performSearch", null);
__decorate([
    Watch('transformedSearchResponse', { deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], BaseSearch.prototype, "onChangeOfTransformedResponse", null);
__decorate([
    Watch('searchResults'),
    Emit('results'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseSearch.prototype, "resultsUpdated", null);
__decorate([
    Emit('searchButtonClicked'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseSearch.prototype, "emitSearchButtonClicked", null);
__decorate([
    Emit('searchPerformed'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseSearch.prototype, "emitSearchPerformed", null);
__decorate([
    Emit('termSearched'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseSearch.prototype, "emitTermSearched", null);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], BaseSearch.prototype, "onClickOfSearchResult", null);
__decorate([
    Emit('metaRowClicked'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], BaseSearch.prototype, "onMetaClickOfSearchResult", null);
__decorate([
    Watch('autosearch', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], BaseSearch.prototype, "onAutosearchChange", null);
__decorate([
    Watch('searchFilters', { deep: true, immediate: false }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], BaseSearch.prototype, "onChangeOfSearchFilter", null);
__decorate([
    Watch('filters', { deep: true, immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], BaseSearch.prototype, "onChangeOfFilterProp", null);
__decorate([
    Watch('entityType'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_d = typeof EntityType !== "undefined" && EntityType) === "function" ? _d : Object]),
    __metadata("design:returntype", void 0)
], BaseSearch.prototype, "onChangeOfEntityTypeProp", null);
__decorate([
    Watch('existingValues', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseSearch.prototype, "onExistingValueChange", null);
__decorate([
    Watch('initialRawSearchResponse', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], BaseSearch.prototype, "onInitialRawSearchResponseChange", null);
BaseSearch = __decorate([
    Component
], BaseSearch);
export default BaseSearch;
