import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import BaseModal from '~/nasa_ui/base/BaseModal';
import CHANGELOG from '../../../../CHANGELOG.md';
let ModalChangeLog = class ModalChangeLog extends BaseModal {
};
ModalChangeLog = __decorate([
    Component({
        components: {
            CHANGELOG
        }
    })
], ModalChangeLog);
export default ModalChangeLog;
