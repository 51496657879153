import { __decorate, __metadata } from "tslib";
import { add } from 'date-fns';
import gql from 'graphql-tag';
import { uniqBy } from 'lodash';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { COSMIC_APPLICATION } from '~/application.config';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { CosmicUserSlimFragment } from '~/nasa_ui/DAL/user/slim';
import { EntityType } from '~/nasa_ui/types';
import { getAssetPath } from '~/nasa_ui/utils/helpers/assets';
import { parseNodeId } from '~/nasa_ui/utils/helpers/parseNodeId';
import { sortObjectNumericallyBy } from '~/nasa_ui/utils/helpers/sortObjectNumericallyBy';
let TopPages = class TopPages extends BaseCosmic {
    communityTopPages = [];
    myTopPages = [];
    isLoadingCommunityTopSites = false;
    isLoadingMyTopSites = false;
    userId;
    get communityTopPagesToUrl() {
        return this.filterLogsByToUrl(this.communityTopPages);
    }
    get communityTopPagesGroupedByToUrl() {
        return this.groupLogsByToUrl(this.communityTopPagesToUrl);
    }
    get communityTopSitesSortedGrouped() {
        const sites = Object.values(this.communityTopPagesGroupedByToUrl);
        return this.transformTopSites(sites);
    }
    /**
     * /src/static_resources/assets/esoc/og/favicon.svg
     */
    get cosmicFaviconSrc() {
        return `${getAssetPath(COSMIC_APPLICATION)}/favicon.svg`;
    }
    get myTopPagesFiltered() {
        return this.filterLogsByToUrl(this.myTopPages);
    }
    get myTopPagesGroupedByToUrl() {
        return this.groupLogsByToUrl(this.myTopPagesFiltered);
    }
    get myTopSitesSortedGrouped() {
        const sites = Object.values(this.myTopPagesGroupedByToUrl);
        return this.transformTopSites(sites);
    }
    async created() {
        try {
            this.isLoadingCommunityTopSites = true;
            const resp = await this.fetchCommunityTopPages();
            this.communityTopPages = (resp.data.errorLogs?.nodes || []);
            this.isLoadingCommunityTopSites = false;
        }
        catch (error) {
            this.isLoadingCommunityTopSites = false;
            this.$errorUtility({ err: error });
        }
    }
    async onAuidChange() {
        if (this.userId) {
            try {
                this.isLoadingMyTopSites = true;
                const resp = await this.fetchMyTopPages(this.userId);
                this.myTopPages = (resp.data.userById?.errorLogs.nodes || []);
                this.isLoadingMyTopSites = false;
            }
            catch (error) {
                this.$errorUtility({ err: error });
            }
        }
    }
    fetchMyTopPages(id) {
        return this.$apollo.query({
            query: gql `
        ${CosmicUserSlimFragment}

        query MyTopPagesVisited($id: UUID!, $awhileAgo: Datetime) {
          userById(id: $id) {
            ...CosmicUserSlim

            errorLogs(
              filter: { subType: { equalTo: "TELEMETRY" }, createdDateTime: { greaterThanOrEqualTo: $awhileAgo } }
              first: 5000
              orderBy: CREATED_DATE_TIME_DESC
            ) {
              nodes {
                attributes
                createdDateTime
              }
            }
          }
        }
      `,
            variables: {
                id,
                awhileAgo: this.$dateDisplay(add(new Date(), {
                    days: -21
                }))
            }
        });
    }
    fetchCommunityTopPages() {
        return this.$apollo.query({
            query: gql `
        query CommunityTopPagesVisited($awhileAgo: Datetime) {
          errorLogs(
            filter: { subType: { equalTo: "TELEMETRY" }, createdDateTime: { greaterThanOrEqualTo: $awhileAgo } }
            first: 5000
            orderBy: CREATED_DATE_TIME_DESC
          ) {
            nodes {
              attributes
              createdDateTime
            }
          }
        }
      `,
            variables: {
                awhileAgo: this.$dateDisplay(add(new Date(), {
                    days: -21
                }))
            }
        });
    }
    filterLogsByToUrl(arr) {
        return arr.filter((page) => page.hasOwnProperty('attributes') &&
            page.attributes.hasOwnProperty('toUrl') &&
            page.attributes.toUrl !== '/' &&
            page.attributes.toUrl !== '/cosmic' &&
            !page.attributes.toUrl.startsWith('/search') &&
            !page.attributes.toUrl.startsWith('/c') &&
            !page.attributes.toUrl.includes('/admin'));
    }
    groupLogsByToUrl(arr) {
        return arr.reduce((agg, curr) => {
            const toUrl = curr.attributes.toUrl;
            if (!toUrl) {
                return agg;
            }
            if (toUrl && agg.hasOwnProperty(toUrl)) {
                agg[toUrl].count = agg[toUrl].count + 1;
            }
            else {
                const foundRoute = this.$router.resolve(toUrl);
                // if you didnt find a route just ignore that log
                if (!foundRoute) {
                    return agg;
                }
                const route = foundRoute.route;
                // if the route you found was the NotFound route... ignore that log
                if (route.name === 'NotFound') {
                    return agg;
                }
                let routeName = route.meta?.displayName;
                if (!routeName) {
                    return agg;
                }
                if (route.path.startsWith('/reporting') && route.path !== '/reporting') {
                    routeName = `${routeName} report`;
                }
                // manage pages have params that contain a nodeId
                // in those cases we want to append some extra SIZZLE :)
                if (routeName.includes('manage') && route.params.nodeId) {
                    const nodeId = route.params.nodeId;
                    try {
                        const parse = parseNodeId(nodeId);
                        if (parse && !parse.hasOwnProperty('id')) {
                            if (parse.entityType === EntityType.ORGANIZATION) {
                                const orgParse = parse;
                                routeName = `${routeName} - ${orgParse.code}`;
                            }
                            if (parse.entityType === EntityType.USER_GROUP) {
                                const ugParse = parse;
                                routeName = `${routeName} - ${ugParse.code}`;
                            }
                            if (parse.entityType === EntityType.USER) {
                                const userParse = parse;
                                routeName = `${routeName} - ${userParse.id}`;
                            }
                            if (parse.entityType === EntityType.ITEM_DRAWING || parse.entityType === EntityType.ITEM_MASTER) {
                                const itemNumberDisplay = this.$itemNumberDisplay(parse);
                                if (itemNumberDisplay !== DEFAULT_DASH) {
                                    routeName = `${routeName} - ${itemNumberDisplay}`;
                                }
                            }
                        }
                    }
                    catch (error) {
                        return agg;
                    }
                }
                // This is used for the tooltip
                const description = route.meta?.description || null;
                agg[toUrl] = {
                    count: 1,
                    description,
                    toUrl,
                    humanizedToUrlName: routeName
                };
            }
            return agg;
        }, {});
    }
    onClick(event, toUrl) {
        if (!event.metaKey && !event.ctrlKey) {
            event.preventDefault();
            this.$router.push(toUrl);
        }
    }
    transformTopSites(sites, numberToSlice = 5) {
        // map over sites, if toUrl contains the path "/search" remove any query string
        sites = sites.map((site) => {
            if (site.toUrl.includes('/search')) {
                site.toUrl = site.toUrl.split('?')[0];
            }
            return site;
        });
        // remove duplicate toUrls
        sites = uniqBy(sites, 'toUrl');
        sites.sort(sortObjectNumericallyBy('count', true));
        return sites.filter((topSite) => topSite.humanizedToUrlName !== '').slice(0, numberToSlice);
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], TopPages.prototype, "userId", void 0);
__decorate([
    Watch('userId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], TopPages.prototype, "onAuidChange", null);
TopPages = __decorate([
    Component
], TopPages);
export default TopPages;
