import { UsState } from '~/db_types';
export const UsStateDisplay = new Map([
    [UsState.AL, 'Alabama'],
    [UsState.AK, 'Alaska'],
    [UsState.AZ, 'Arizona'],
    [UsState.AR, 'Arkansas'],
    [UsState.CA, 'California'],
    [UsState.CO, 'Colorado'],
    [UsState.CT, 'Connecticut'],
    [UsState.DE, 'Delaware'],
    [UsState.DC, 'District Of Columbia'],
    [UsState.FL, 'Florida'],
    [UsState.GA, 'Georgia'],
    [UsState.GU, 'Guam'],
    [UsState.HI, 'Hawaii'],
    [UsState.ID, 'Idaho'],
    [UsState.IL, 'Illinois'],
    [UsState.IN, 'Indiana'],
    [UsState.IA, 'Iowa'],
    [UsState.KS, 'Kansas'],
    [UsState.KY, 'Kentucky'],
    [UsState.LA, 'Louisiana'],
    [UsState.ME, 'Maine'],
    [UsState.MD, 'Maryland'],
    [UsState.MA, 'Massachusetts'],
    [UsState.MI, 'Michigan'],
    [UsState.MN, 'Minnesota'],
    [UsState.MS, 'Mississippi'],
    [UsState.MO, 'Missouri'],
    [UsState.MT, 'Montana'],
    [UsState.NE, 'Nebraska'],
    [UsState.NV, 'Nevada'],
    [UsState.NH, 'New Hampshire'],
    [UsState.NJ, 'New Jersey'],
    [UsState.NM, 'New Mexico'],
    [UsState.NY, 'New York'],
    [UsState.NC, 'North Carolina'],
    [UsState.ND, 'North Dakota'],
    [UsState.OH, 'Ohio'],
    [UsState.OK, 'Oklahoma'],
    [UsState.OR, 'Oregon'],
    [UsState.PW, 'Palau'],
    [UsState.PA, 'Pennsylvania'],
    [UsState.PR, 'Puerto Rico'],
    [UsState.RI, 'Rhode Island'],
    [UsState.SC, 'South Carolina'],
    [UsState.SD, 'South Dakota'],
    [UsState.TN, 'Tennessee'],
    [UsState.TX, 'Texas'],
    [UsState.UT, 'Utah'],
    [UsState.VT, 'Vermont'],
    [UsState.VI, 'Virgin Islands'],
    [UsState.VA, 'Virginia'],
    [UsState.WA, 'Washington'],
    [UsState.WV, 'West Virginia'],
    [UsState.WI, 'Wisconsin'],
    [UsState.WY, 'Wyoming']
]);
