var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { IAppChip } from '~/nasa_ui/types';
let AppChip = class AppChip extends Vue {
    chips;
    color;
    textColor;
    get computedChips() {
        if (typeof this.chips === 'string') {
            return [
                {
                    text: this.chips,
                    value: this.chips,
                    close: false,
                    color: this.color
                }
            ];
        }
        if (typeof this.chips === 'object' && !Array.isArray(this.chips)) {
            return [this.chips];
        }
        return this.chips.map((c) => typeof c !== 'string'
            ? c
            : {
                text: c,
                value: c,
                close: false,
                color: this.color
            });
    }
    onClickOfClose(clickedChip) { }
};
__decorate([
    Prop({
        type: [String, Object, Array]
    }),
    __metadata("design:type", Object)
], AppChip.prototype, "chips", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppChip.prototype, "color", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppChip.prototype, "textColor", void 0);
__decorate([
    Emit('closeClicked'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof IAppChip !== "undefined" && IAppChip) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], AppChip.prototype, "onClickOfClose", null);
AppChip = __decorate([
    Component
], AppChip);
export default AppChip;
