import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import pkg from '~/../../package.json';
import BaseVue from '~/nasa_ui/base/BaseVue';
import CurrentUserMixin from '~/nasa_ui/mixins/CurrentUserMixin';
import { AlertType } from '~/nasa_ui/types';
import { getBaseImagePath } from '~/nasa_ui/utils/helpers/assets';
import { getKnowledgeBaseUrl } from '~/nasa_ui/utils/url';
let TheFooter = class TheFooter extends Mixins(BaseVue, CurrentUserMixin) {
    pkg = pkg;
    navStateIsOpen;
    get currentUiVersion() {
        if (!this.pkg.version) {
            return '';
        }
        return `v${this.pkg.version}`;
    }
    get feedbackLink() {
        const v = this.currentUiVersion;
        const userId = this.currentUser?.id ?? '';
        const userEmail = this.currentUser?.email ?? '';
        const userFirst = this.currentUser?.firstName ?? '';
        const userLast = this.currentUser?.lastName ?? '';
        const currentActiveUrl = this.$route.fullPath ?? '';
        const gitlabProject = `cosmic_${this.currentUserActiveSupportContext}_ctx_feedback`;
        const queryString = `?issue[title]=USER%20FEEDBACK:%20${v}%20${userId}&issue[description]=User details:%0A%2A%20${userId}%0A%2A%20${userFirst}%0A%2A%20${userLast}%0A%2A%20${userEmail}%0A%0ACurrent%20Active%20URL:${currentActiveUrl}`;
        return `https://appdat.jsc.nasa.gov/cosmic/feedback/${gitlabProject}/-/issues/new${queryString}`;
    }
    get knowledgebaseLink() {
        return getKnowledgeBaseUrl();
    }
    /**
     * /src/static_resources/assets/images/NASA_logo.svg
     */
    get nasaLogoSrc() {
        return `${getBaseImagePath()}/NASA_logo.svg`;
    }
    async onClickOfLogout() { }
    onClickOfClearCache() {
        let clearedEntries = 0;
        const lsKeysToNotClear = ['currentSupportContext', 'navOpenState'];
        Object.entries(localStorage).forEach((entry) => {
            const key = entry[0];
            console.log(key);
            // We do NOT want to clear navOpenState or currentSupportContext
            if (!key || lsKeysToNotClear.includes(key)) {
                return;
            }
            console.log(`removing key: ${key}`);
            localStorage.removeItem(key);
            clearedEntries++;
            console.log(clearedEntries);
        });
        this.$notification.add({
            type: AlertType.INFO,
            text: `Cleared ${this.$numberDisplay(clearedEntries)} entries.`
        });
    }
};
__decorate([
    Prop({
        type: Boolean,
        required: true,
        default: true
    }),
    __metadata("design:type", Boolean)
], TheFooter.prototype, "navStateIsOpen", void 0);
__decorate([
    Emit('logout'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], TheFooter.prototype, "onClickOfLogout", null);
TheFooter = __decorate([
    Component
], TheFooter);
export default TheFooter;
