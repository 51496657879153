var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.ASSEMBLY_TEMPLATE,
      "hide-title-section": "",
      "modal-width": "95vw"
    },
    on: {
      "modal-close": _vm.emitModalClose,
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.closeModal.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('v-fade-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.isLoading ? _c('AppLoader', {
          attrs: {
            "type": "linear",
            "center": "",
            "color": _vm.EntityType.ASSEMBLY_TEMPLATE
          }
        }) : _vm._e()], 1), _c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [!_vm.isLoading ? _c('div', [_vm.transformedSelectedEntity && _vm.transformedSelectedEntity.autoHardwareListId ? _c('div', {
          staticClass: "c-grid"
        }, [_c('div', {
          staticClass: "col-full"
        }, [_c('AppPageDescription', {
          attrs: {
            "remove-margin-left": "",
            "remove-margin-bottom": "",
            "color": "inventories"
          }
        }, [_vm._v(" This template has an item listing assigned: ")]), _c('AppBlockquote', [_c('div', {
          staticClass: "c-flex align-items-center",
          staticStyle: {
            "height": "40px"
          }
        }, [_c('IconAutoHardwareList', {
          attrs: {
            "icon-size": "24px"
          }
        }), _c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(_vm._s(_vm.transformedSelectedEntity.autoHardwareListName))])], 1)])], 1)]) : _vm._e(), _vm.transformedPartReferences && _vm.transformedPartReferences.length ? _c('div', {
          staticClass: "c-grid"
        }, [_c('div', {
          staticClass: "col-full"
        }, [_c('AppPageDescription', {
          attrs: {
            "remove-margin-left": ""
          }
        }, [_vm._v(" Below are the list of parts pre-selected for this template slot ")])], 1), _c('div', {
          staticClass: "col-full"
        }, [_c('AppTable', {
          attrs: {
            "items": _vm.transformedPartReferences,
            "headers": _vm.partReferenceTableHeaders,
            "show-title": false
          }
        })], 1)]) : _vm._e(), _vm.transformedPartReferences && _vm.transformedPartReferences.length ? _c('hr', {
          staticClass: "ma-4"
        }) : _vm._e(), _vm.availableInventories.length ? _c('div', {
          staticClass: "c-grid mt-4"
        }, [_c('div', {
          staticClass: "col-full"
        }, [_c('AppPageDescription', {
          attrs: {
            "color": "inventories",
            "remove-margin-left": ""
          }
        }, [_vm._v(" Here is the list of inventory that match the criteria ")])], 1), _c('div', {
          staticClass: "col-full"
        }, [_c('AppTableSelectable', {
          attrs: {
            "items": _vm.availableInventories,
            "headers": _vm.inventoryTableHeaders,
            "show-title": false,
            "dense-rows": "",
            "item-key": "id",
            "rows-per-page-items": [10, 25, 50, 100]
          },
          model: {
            value: _vm.selectedInventories,
            callback: function callback($$v) {
              _vm.selectedInventories = $$v;
            },
            expression: "selectedInventories"
          }
        })], 1)]) : _vm._e(), _vm.transformedSelectedEntity.maxQuantity > _vm.selectedInventories.length && _vm.selectedInventories.length < _vm.transformedSelectedEntity.minQuantity ? _c('hr', {
          staticClass: "ma-4"
        }) : _vm._e(), _vm.transformedSelectedEntity.maxQuantity > _vm.selectedInventories.length && _vm.selectedInventories.length < _vm.transformedSelectedEntity.minQuantity ? _c('div', {
          staticClass: "c-grid mt-2"
        }, [_c('div', {
          staticClass: "col-full"
        }, [_c('AppPageDescription', {
          attrs: {
            "color": "inventories",
            "remove-margin-left": ""
          }
        }, [_vm._v(" Search for any available inventory in COSMIC ")])], 1), _c('div', {
          staticClass: "col-full"
        }, [_c('SearchInventory', {
          attrs: {
            "filters": _vm.inventorySearchFilters,
            "multiple": "",
            "output-mode": "object"
          },
          model: {
            value: _vm.selectedInventories,
            callback: function callback($$v) {
              _vm.selectedInventories = $$v;
            },
            expression: "selectedInventories"
          }
        })], 1)]) : _vm._e(), _vm.transformedSelectedEntity ? _c('div', {
          staticClass: "c-grid mt-2"
        }, [_c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.transformedSelectedEntity.maxQuantity && _vm.selectedInventories.length > _vm.transformedSelectedEntity.maxQuantity ? _c('div', {
          staticClass: "col-full"
        }, [_c('AppPageDescription', {
          attrs: {
            "color": "error",
            "remove-margin-left": ""
          }
        }, [_vm._v(" Max quantity per the template selected is "), _c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(_vm._s(_vm.transformedSelectedEntity.maxQuantity))])])], 1) : _vm._e()]), _c('v-fade-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.transformedSelectedEntity.minQuantity && _vm.selectedInventories.length < _vm.transformedSelectedEntity.minQuantity ? _c('div', {
          staticClass: "col-full"
        }, [_c('AppPageDescription', {
          attrs: {
            "color": "error",
            "remove-margin-left": ""
          }
        }, [_vm._v(" Min quantity per the template selected is "), _c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(_vm._s(_vm.transformedSelectedEntity.minQuantity))])])], 1) : _vm._e()]), _c('v-slide-y-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.selectedInventories.length && _vm.transformedSelectedEntity.minQuantity && _vm.selectedInventories.length >= _vm.transformedSelectedEntity.minQuantity && _vm.transformedSelectedEntity.maxQuantity && _vm.selectedInventories.length <= _vm.transformedSelectedEntity.maxQuantity ? _c('div', {
          staticClass: "col-full"
        }, [_c('AppPageDescription', {
          attrs: {
            "color": "success",
            "remove-margin-left": ""
          }
        }, [_vm._v(" Quantity of "), _c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(_vm._s(_vm.selectedInventories.length))]), _vm._v(" accepted. ")])], 1) : _vm._e()])], 1) : _vm._e()]) : _vm._e()])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('ButtonGeneric', {
          attrs: {
            "icon": _vm.$icons.install,
            "color": "var(--v-success-base)",
            "disabled": _vm.shouldDisableInstallButton
          },
          on: {
            "click": _vm.onClickOfInstall
          }
        }, [_vm._v("Install")]), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };