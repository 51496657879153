function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "cosmic_header",
    class: {
      uppercase: _vm.uppercase
    }
  }, [_vm.isPassedHtml ? _c('div', {
    directives: [{
      name: "safe-html",
      rawName: "v-safe-html",
      value: _vm.vHtml,
      expression: "vHtml"
    }],
    class: _defineProperty(_defineProperty({}, "".concat(_vm.size), _vm.size), "divider", _vm.showHorizontalRule)
  }) : _c('div', {
    class: _defineProperty(_defineProperty({}, "".concat(_vm.size), _vm.size), "divider", _vm.showHorizontalRule)
  }, [_vm._t("default")], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };