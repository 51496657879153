import isBefore from 'date-fns/isBefore';
import { dateFrom } from './dateFrom';
import { removeTime } from './removeTime';
/**
 * This util is modeled after date-fns' `isBefore` except that it interprets the
 * second date argument as midnight on the given date.
 *
 * @param date The date that should be before the other one to return true
 * @param dateToCompare The date to compare with
 * @returns Returns whether the first date is before midnight on the second date
 */
export const isBeforeInclusive = (date, dateToCompare) => {
    if (!date) {
        return false;
    }
    if (!dateToCompare) {
        return false;
    }
    date = dateFrom(date);
    dateToCompare = dateFrom(dateToCompare);
    return isBefore(date, removeTime(dateToCompare));
};
