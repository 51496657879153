import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import BaseInput from '~/nasa_ui/base/BaseInput';
let AppText = class AppText extends BaseInput {
    labelInvert;
    pattern;
    prefix;
    replaceSpacesWithUnderscores;
    upperCase;
    get computedMessages() {
        // if required and no value, show required message
        if (this.required && this.$get(this, 'value', null) === null) {
            return ['REQUIRED'];
        }
        if (this.required && this.$get(this, 'value', undefined) === undefined) {
            return ['REQUIRED'];
        }
        if (this.required && this.$get(this, 'value', '') === '') {
            return ['REQUIRED'];
        }
        if (this.messages) {
            return Array.isArray(this.messages) ? this.messages : [this.messages];
        }
        if (this.upperCase && !this.replaceSpacesWithUnderscores && this.value) {
            return [`${this.value.toUpperCase()} accepted.`];
        }
        if (this.upperCase && this.replaceSpacesWithUnderscores && this.value) {
            // trim value for whitespace
            let val = this.value && this.value.trim ? this.value.trim() : this.value;
            if (this.upperCase && val.toUpperCase) {
                val = val.toUpperCase();
            }
            if (this.replaceSpacesWithUnderscores && val) {
                val = val.split(' ').join('_');
            }
            return [`${val} accepted.`];
        }
        // show messages based on rules
        // @ts-ignore
        return this.localRulesBaseOnProps.filter((_) => typeof _ === 'string');
    }
    emitBlur(val) {
        // trim value for whitespace
        val = val && val.trim ? val.trim() : val;
        if (this.upperCase && val.toUpperCase) {
            val = val.toUpperCase();
        }
        if (this.replaceSpacesWithUnderscores && val) {
            val = val.split(' ').join('_');
        }
        if (this.prefix && val && !val.startsWith(this.prefix)) {
            return `${this.prefix}${val}`;
        }
        return val;
    }
    emitInput(val) {
        // trim value for whitespace
        val = val && val.trim ? val.trim() : val;
        if (this.upperCase && val.toUpperCase) {
            val = val.toUpperCase();
        }
        if (this.replaceSpacesWithUnderscores && val) {
            val = val.split(' ').join('_');
        }
        if (this.prefix && val && !val.startsWith(this.prefix)) {
            return `${this.prefix}${val}`;
        }
        return val;
    }
    emitKeydown() { }
    onPrefixChange() {
        if (!this.prefix) {
            return;
        }
        if (this.prefix && !this.value.startsWith(this.prefix)) {
            this.emitInput(`${this.prefix}${this.value}`);
        }
    }
};
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], AppText.prototype, "labelInvert", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppText.prototype, "pattern", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppText.prototype, "prefix", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], AppText.prototype, "replaceSpacesWithUnderscores", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], AppText.prototype, "upperCase", void 0);
__decorate([
    Emit('blur'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], AppText.prototype, "emitBlur", null);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], AppText.prototype, "emitInput", null);
__decorate([
    Emit('keydown'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppText.prototype, "emitKeydown", null);
__decorate([
    Watch('prefix', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppText.prototype, "onPrefixChange", null);
AppText = __decorate([
    Component
], AppText);
export default AppText;
