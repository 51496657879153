var _a, _b, _c, _d, _e, _f;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Debounce } from 'lodash-decorators';
import uniqBy from 'lodash/uniqBy';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { Maybe, Side } from '~/db_types';
import BaseInput from '~/nasa_ui/base/BaseInput';
import { CosmicItemMasterSlimFragment as CosmicItemMasterSlimFragmentOverride } from '~/nasa_ui/DAL/itemMaster/slim';
import { sortObjectNumericallyBy } from '~/nasa_ui/utils/helpers/sortObjectNumericallyBy';
let AppSelectAsBuiltByDrawingNumber = class AppSelectAsBuiltByDrawingNumber extends BaseInput {
    allPossibleItemMasters = null;
    isLoading = false;
    selectedOption = null;
    asDesignNumber;
    drawingNumber;
    sideFilter;
    get asBuiltOptions() {
        let options = this.allPossibleItemMasters?.map((itemMaster) => {
            const side = itemMaster.side && itemMaster.side !== Side.NONE
                ? ` - ${this.$sideDisplay(itemMaster.side).substr(0, 1)}`
                : '';
            return {
                displayText: `${itemMaster.asBuiltNumber}${side}`,
                value: `${itemMaster.asBuiltNumber}${itemMaster.side}`,
                number: itemMaster.asBuiltNumber,
                side: itemMaster.side
            };
        }) || [];
        if (this.sideFilter) {
            options = options.filter((option) => option?.side === this.sideFilter);
        }
        options.sort(sortObjectNumericallyBy('number'));
        return uniqBy(options, 'displayText');
    }
    get computedLabel() {
        return this.label || 'AsBuilt';
    }
    async fetchAllPossibleItemMastersForThisItemInstance() {
        if (!this.drawingNumber) {
            return;
        }
        try {
            const variables = {
                drawingNumber: this.drawingNumber
            };
            const resp = await this.$apollo.query({
                query: gql `
          ${CosmicItemMasterSlimFragmentOverride}

          query FetchAllItemMastersForAsBuiltSelect($drawingNumber: String) {
            itemMasters(condition: { drawingNumber: $drawingNumber }) {
              nodes {
                ...CosmicItemMasterSlim
              }
            }
          }
        `,
                variables
            });
            this.allPossibleItemMasters = (resp.data.itemMasters &&
                resp.data.itemMasters.nodes);
        }
        catch (err) {
            this.$errorUtility({
                err,
                backupErrorText: 'Error: Unable to AsBuilt options.'
            });
            this.allPossibleItemMasters = null;
        }
    }
    synceValueBasedOnAsDesignNumber() {
        const match = this.asBuiltOptions.find((option) => option.number === this.asDesignNumber);
        if (match) {
            this.selectedOption = match.value;
        }
    }
    emitInput(val) {
        const option = this.asBuiltOptions.find((o) => o.value === val);
        if (!option) {
            return null;
        }
        return {
            number: option.number,
            side: option.side
        };
    }
    async onChangeOfDrawingNumber(drawingNumber) {
        try {
            this.isLoading = true;
            await this.fetchAllPossibleItemMastersForThisItemInstance();
            this.isLoading = false;
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    syncValue(val) {
        if (!val) {
            this.selectedOption = null;
            return;
        }
        this.selectedOption = `${val.number}${val.side}`;
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], AppSelectAsBuiltByDrawingNumber.prototype, "asDesignNumber", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object)
], AppSelectAsBuiltByDrawingNumber.prototype, "drawingNumber", void 0);
__decorate([
    Prop({
        type: String,
        required: false
    }),
    __metadata("design:type", typeof (_c = typeof Side !== "undefined" && Side) === "function" ? _c : Object)
], AppSelectAsBuiltByDrawingNumber.prototype, "sideFilter", void 0);
__decorate([
    Watch('asBuiltOptions'),
    Watch('asDesignNumber', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppSelectAsBuiltByDrawingNumber.prototype, "synceValueBasedOnAsDesignNumber", null);
__decorate([
    Watch('selectedOption', { immediate: true }),
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_d = typeof Maybe !== "undefined" && Maybe) === "function" ? _d : Object]),
    __metadata("design:returntype", typeof (_e = typeof Maybe !== "undefined" && Maybe) === "function" ? _e : Object)
], AppSelectAsBuiltByDrawingNumber.prototype, "emitInput", null);
__decorate([
    Watch('drawingNumber', { immediate: true }),
    Debounce(500),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", Promise)
], AppSelectAsBuiltByDrawingNumber.prototype, "onChangeOfDrawingNumber", null);
__decorate([
    Watch('value', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_f = typeof Maybe !== "undefined" && Maybe) === "function" ? _f : Object]),
    __metadata("design:returntype", void 0)
], AppSelectAsBuiltByDrawingNumber.prototype, "syncValue", null);
AppSelectAsBuiltByDrawingNumber = __decorate([
    Component
], AppSelectAsBuiltByDrawingNumber);
export default AppSelectAsBuiltByDrawingNumber;
