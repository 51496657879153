import gql from 'graphql-tag';
export const CosmicHardwareListSlimFragment = gql `
  fragment CosmicHardwareListSlim on HardwareList {
    addendumDate
    addendumNumber
    authorizer
    changeSetId
    closeDate
    createdByUserId
    createdDateTime
    documentId
    eventId
    hardwareListTemplateId
    id
    name
    nodeId
    number
    revision
    subType
  }
`;
export const CosmicHardwareListAssemblySlimFragment = gql `
  fragment CosmicHardwareListAssemblySlim on HardwareListAssembly {
    addendumDate
    addendumNumber
    externalAsBuiltNumber
    externalBirthDate
    externalCalibrationDate
    externalCalibrationNumber
    externalContractEndingItemNumber
    externalClass
    externalDescription
    externalDrawingNumber
    externalIsTool
    externalLocation
    externalLotNumber
    externalSerialNumber
    externalShelfLifeDate
    externalUsageLifeDate
    id
    isExternal
    itemInstanceId
    nodeId
    notes
    quantity
    sequence
  }
`;
