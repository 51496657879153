import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let AppHeader = class AppHeader extends Vue {
    size;
    uppercase;
    'vHtml';
    showHorizontalRule;
    get isPassedHtml() {
        return Boolean(this.$props.vHtml);
    }
};
__decorate([
    Prop({
        type: String,
        default: ''
    }),
    __metadata("design:type", String)
], AppHeader.prototype, "size", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppHeader.prototype, "uppercase", void 0);
__decorate([
    Prop({
        type: String,
        default: ''
    }),
    __metadata("design:type", String)
], AppHeader.prototype, "vHtml", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: () => true
    }),
    __metadata("design:type", Boolean)
], AppHeader.prototype, "showHorizontalRule", void 0);
AppHeader = __decorate([
    Component
], AppHeader);
export default AppHeader;
