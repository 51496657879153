import gql from 'graphql-tag';
export const CosmicItemDrawingSlimFragment = gql `
  fragment CosmicItemDrawingSlim on ItemDrawing {
    aliases
    attributes
    availableQuantity
    createdDateTime
    description
    drawingNumber
    eeeIdentifier
    initializationStatus
    installedQuantity
    inventoryQuantity
    isArchived
    nodeId
    pointOfContactId
    sizes
  }
`;
