import gql from 'graphql-tag';
export const CosmicAttachmentSlimFragment = gql `
  fragment CosmicAttachmentSlim on Attachment {
    contentType
    createdByUserId
    createdDateTime
    description
    fileName
    id
    isCompleted
    key
    subType
  }
`;
