var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.show && _vm.type === 'circular' ? _c('v-progress-circular', _vm._b({
    class: _vm.center ? 'center' : '',
    attrs: {
      "indeterminate": ""
    }
  }, 'v-progress-circular', _vm.$props, false)) : _vm.show && _vm.type === 'linear' ? _c('v-progress-linear', _vm._b({
    attrs: {
      "indeterminate": ""
    }
  }, 'v-progress-linear', _vm.$props, false)) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };