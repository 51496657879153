export var ContactType;
(function (ContactType) {
    ContactType["GENERAL"] = "GENERAL";
    ContactType["OWNER"] = "OWNER";
    ContactType["ENGINEERING"] = "ENGINEERING";
    ContactType["LOGISTICS"] = "LOGISTICS";
    ContactType["TECHNICIAN"] = "TECHNICIAN";
})(ContactType || (ContactType = {}));
export const ContactTypeDisplay = new Map([
    [ContactType.GENERAL, 'General'],
    [ContactType.OWNER, 'Owner'],
    [ContactType.ENGINEERING, 'Engineering'],
    [ContactType.LOGISTICS, 'Logistics'],
    [ContactType.TECHNICIAN, 'Technician']
]);
