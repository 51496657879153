var _a, _b;
import { __decorate, __metadata } from "tslib";
import { addDays, format } from 'date-fns';
import gql from 'graphql-tag';
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { COSMIC_APPLICATION } from '~/application.config';
import { ChangeSetType } from '~/db_types/swagger_types';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { CURRENT_USER_LS_VARIABLE, DEFAULT_DASH } from '~/nasa_ui/constants';
import { store } from '~/nasa_ui/store';
import { EntityType, EntityTypeSingularDisplay, UserPermission } from '~/nasa_ui/types';
import { dateDisplay } from '~/nasa_ui/utils/dates/dateDisplay';
import { getAssetPath } from '~/nasa_ui/utils/helpers/assets';
import { isNullOrUndefined } from '~/nasa_ui/utils/helpers/isNullOrUndefined';
import LS from '~/nasa_ui/utils/LocalStorageService';
import { CosmicIcons } from '~/nasa_ui/utils/ui/CosmicIcons';
const LIMITED_LIFE_ALERT_POLLING_RATE = 1000 * 60 * 20;
const LAUNCHPAD_ERROR_SLUG = 'Error: Network error: Unexpected token';
let TheNavbar = class TheNavbar extends Mixins(BaseCosmic) {
    alertLimit = 9;
    ariaSelected = false;
    calibrations = null;
    dateDisplay = dateDisplay;
    inventoryQuotas = null;
    isHoveringOverNewTab = false;
    isHoveringOverHamburger = false;
    limitedLifeAlertCount = null;
    navOpenState = null;
    showFavoritesModal = false;
    userNeedsNewSession = false;
    userNeedsSessionInterval = null;
    createMenuItems = [
        {
            destinationUrl: `/${EntityType.ACTIVITY}/create`,
            displayText: EntityTypeSingularDisplay.get(EntityType.ACTIVITY) || DEFAULT_DASH,
            icon: CosmicIcons[EntityType.ACTIVITY],
            permissionToCheck: UserPermission.ACTIVITY_ADMIN,
            textColor: `var(--v-${EntityType.ACTIVITY}-base)`
        },
        {
            destinationUrl: `/users/${EntityType.APPROVAL_ROUTING_TEMPLATE}/create`,
            displayText: EntityTypeSingularDisplay.get(EntityType.APPROVAL_ROUTING_TEMPLATE) || DEFAULT_DASH,
            icon: CosmicIcons[EntityType.APPROVAL_ROUTING_TEMPLATE],
            permissionToCheck: UserPermission.APPROVAL_ROUTING_TEMPLATE_ADMIN,
            textColor: `var(--v-${EntityType.USER_GROUP}-base)`
        },
        // {
        //   destinationUrl: '/hardware/assemblies/create',
        //   displayText: 'Assembly',
        //   icon: CosmicIcons[EntityType.ASSEMBLY],
        //   permissionToCheck: UserPermission.ASSEMBLY_ADMIN,
        //   textColor: `${EntityType.ASSEMBLY}`
        // },
        {
            destinationUrl: `/hardware/${EntityType.ASSEMBLY_TEMPLATE}/create`,
            displayText: 'Assembly Template',
            icon: CosmicIcons[EntityType.ASSEMBLY_TEMPLATE],
            permissionToCheck: UserPermission.ASSEMBLY_TEMPLATE_ADMIN,
            textColor: `var(--v-${EntityType.ASSEMBLY_TEMPLATE}-base)`
        },
        {
            destinationUrl: `/${EntityType.CONTACT}/create`,
            displayText: EntityTypeSingularDisplay.get(EntityType.CONTACT) || DEFAULT_DASH,
            icon: CosmicIcons[EntityType.CONTACT],
            permissionToCheck: UserPermission.CONTACT_ADMIN,
            textColor: `var(--v-${EntityType.CONTACT}-base)`
        },
        {
            destinationUrl: `/${EntityType.CONTRACT}/create`,
            displayText: EntityTypeSingularDisplay.get(EntityType.CONTRACT) || DEFAULT_DASH,
            icon: CosmicIcons[EntityType.CONTRACT],
            permissionToCheck: UserPermission.CONTRACT_ADMIN,
            textColor: `var(--v-${EntityType.CONTRACT}-base)`
        },
        {
            destinationUrl: `/${EntityType.EVENT}/create`,
            displayText: EntityTypeSingularDisplay.get(EntityType.EVENT) || DEFAULT_DASH,
            icon: CosmicIcons[EntityType.EVENT],
            permissionToCheck: UserPermission.EVENT_ADMIN,
            textColor: `var(--v-${EntityType.EVENT}-base)`
        },
        {
            destinationUrl: '/hardware/lists/create',
            displayText: EntityTypeSingularDisplay.get(EntityType.HARDWARE_LIST) || DEFAULT_DASH,
            icon: CosmicIcons[EntityType.HARDWARE_LIST],
            permissionToCheck: UserPermission.HARDWARE_LIST_TEMPLATE_ADMIN,
            textColor: `var(--v-${EntityType.INVENTORY}-base)`
        },
        {
            destinationUrl: '/hardware/list_templates/create',
            displayText: EntityTypeSingularDisplay.get(EntityType.HARDWARE_LIST_TEMPLATE) || DEFAULT_DASH,
            icon: CosmicIcons[EntityType.HARDWARE_LIST_TEMPLATE],
            permissionToCheck: UserPermission.HARDWARE_LIST_TEMPLATE_ADMIN,
            textColor: `var(--v-${EntityType.INVENTORY}-base)`
        },
        {
            destinationUrl: `/hardware/${EntityType.ITEM_DRAWING}/create`,
            displayText: EntityTypeSingularDisplay.get(EntityType.ITEM_DRAWING) || DEFAULT_DASH,
            icon: CosmicIcons[EntityType.ITEM_DRAWING],
            permissionToCheck: UserPermission.ITEM_DRAWING_ADMIN,
            textColor: `var(--v-${EntityType.ITEM_DRAWING}-base)`
        },
        {
            destinationUrl: `/hardware/${EntityType.ITEM_MASTER}/create`,
            displayText: EntityTypeSingularDisplay.get(EntityType.ITEM_MASTER) || DEFAULT_DASH,
            icon: CosmicIcons[EntityType.ITEM_MASTER],
            permissionToCheck: UserPermission.ITEM_MASTER_ADMIN,
            textColor: `var(--v-${EntityType.ITEM_MASTER}-base)`
        },
        {
            destinationUrl: '/hardware/initial_receipt',
            displayText: 'Initial Receipt',
            icon: CosmicIcons[EntityType.INVENTORY],
            permissionToCheck: UserPermission.ITEM_INSTANCE_ADMIN,
            textColor: `var(--v-${EntityType.INVENTORY}-base)`
        },
        {
            destinationUrl: '/hardware/initial_receipt_condensed',
            displayText: 'Initial Receipt - Condensed',
            icon: CosmicIcons[EntityType.INVENTORY],
            permissionToCheck: UserPermission.ITEM_INSTANCE_ADMIN,
            textColor: `var(--v-${EntityType.INVENTORY}-base)`
        },
        {
            destinationUrl: `/${EntityType.LOCATION}/create`,
            displayText: EntityTypeSingularDisplay.get(EntityType.LOCATION) || DEFAULT_DASH,
            icon: CosmicIcons[EntityType.LOCATION],
            permissionToCheck: UserPermission.LOCATION_ADMIN,
            textColor: `var(--v-${EntityType.LOCATION}-base)`
        },
        {
            destinationUrl: '/hardware/failures/create',
            displayText: 'On-Orbit Failure',
            icon: CosmicIcons[ChangeSetType.ITEM_INSTANCE_ON_ORBIT_FAILURE],
            permissionToCheck: UserPermission.ON_ORBIT_FAILURE_ADMIN,
            textColor: `var(--v-${EntityType.CHANGE_SET}-base)`
        },
        {
            destinationUrl: `/${EntityType.ORGANIZATION}/create`,
            displayText: EntityTypeSingularDisplay.get(EntityType.ORGANIZATION) || DEFAULT_DASH,
            icon: CosmicIcons[EntityType.ORGANIZATION],
            permissionToCheck: UserPermission.ORGANIZATION_ADMIN,
            textColor: `var(--v-${EntityType.ORGANIZATION}-base)`
        },
        {
            destinationUrl: `/${EntityType.PRODUCT_BREAKDOWN_STRUCTURE}/create`,
            displayText: EntityTypeSingularDisplay.get(EntityType.PRODUCT_BREAKDOWN_STRUCTURE) || DEFAULT_DASH,
            icon: CosmicIcons[EntityType.PRODUCT_BREAKDOWN_STRUCTURE],
            permissionToCheck: UserPermission.PRODUCT_BREAKDOWN_STRUCTURE_ADMIN,
            textColor: `var(--v-${EntityType.PRODUCT_BREAKDOWN_STRUCTURE}-base)`
        },
        {
            destinationUrl: `/hardware/${EntityType.PHYSICAL_INVENTORY_AUDIT}/create`,
            displayText: EntityTypeSingularDisplay.get(EntityType.PHYSICAL_INVENTORY_AUDIT) || DEFAULT_DASH,
            icon: CosmicIcons[EntityType.PHYSICAL_INVENTORY_AUDIT],
            permissionToCheck: UserPermission.PHYSICAL_INVENTORY_AUDIT_ADMIN,
            textColor: `var(--v-${EntityType.PHYSICAL_INVENTORY_AUDIT}-base)`
        },
        {
            destinationUrl: `/${EntityType.DOCUMENT}/create`,
            displayText: `Tracking ${EntityTypeSingularDisplay.get(EntityType.DOCUMENT)}` || DEFAULT_DASH,
            icon: CosmicIcons[EntityType.DOCUMENT],
            permissionToCheck: UserPermission.DOCUMENT_ADMIN,
            textColor: `var(--v-${EntityType.DOCUMENT}-base)`
        },
        {
            destinationUrl: `/${EntityType.USER}/create`,
            displayText: EntityTypeSingularDisplay.get(EntityType.USER) || DEFAULT_DASH,
            icon: CosmicIcons[EntityType.USER],
            permissionToCheck: UserPermission.USER_ADMIN,
            textColor: `var(--v-${EntityType.USER}-base)`
        },
        {
            destinationUrl: '/users/groups/create',
            displayText: EntityTypeSingularDisplay.get(EntityType.USER_GROUP) || DEFAULT_DASH,
            icon: CosmicIcons[EntityType.USER_GROUP],
            permissionToCheck: UserPermission.USER_GROUPS_ADMIN,
            textColor: `var(--v-${EntityType.USER_GROUP}-base)`
        },
        {
            destinationUrl: '/vehicles/create',
            displayText: EntityTypeSingularDisplay.get(EntityType.VEHICLE) || DEFAULT_DASH,
            icon: CosmicIcons[EntityType.VEHICLE],
            permissionToCheck: UserPermission.LOGISTICS_ADMIN,
            textColor: `var(--v-${EntityType.VEHICLE}-base)`
        }
    ];
    outageAlertState;
    /**
     * src/static_resources/assets/esoc/og/main_app_logo.svg
     */
    get cosmicLogoSrc() {
        return `${getAssetPath(COSMIC_APPLICATION)}/main_app_logo.svg`;
    }
    get hrefToCalibrationsReport() {
        const today = new Date();
        const todayFormatted = format(today, 'yyyy-MM-dd');
        const oneMonthFromNow = format(addDays(today, 30), 'yyyy-MM-dd');
        return `/reporting/calibrations/results?startDate=${todayFormatted}&endDate=${oneMonthFromNow}`;
    }
    get hrefToLimitedLifeReport() {
        // Per requirements
        //  20% timeremaining
        //  20% count remaining
        //  value | service
        const query = '?queryByExpirationValue=true&queryByService=true&intervalThresholdPercent=20&cycleThresholdPercent=20&autoExecute';
        return `/reporting/limited_life_thresholds/results/${query}`;
    }
    get hrefToUserChangeSetApprovals() {
        return '/reporting/user_change_set_approvals';
    }
    get limitedLifeBadgeCount() {
        if (!this.limitedLifeAlertCount) {
            return '0';
        }
        return this.limitedLifeAlertCount > this.alertLimit ? `${this.alertLimit}+` : this.limitedLifeAlertCount;
    }
    get navbarSettings() {
        return (this.currentUserActiveSupportContext &&
            this.currentUser?.attributes?.navbar?.[this.currentUserActiveSupportContext]);
    }
    get shouldShowCalibrationsLink() {
        return isNullOrUndefined(this.navbarSettings?.showCalibrationsLink)
            ? true
            : this.navbarSettings?.showCalibrationsLink;
    }
    get shouldShowFavoritesLink() {
        return isNullOrUndefined(this.navbarSettings?.showFavoritesLink) ? true : this.navbarSettings?.showFavoritesLink;
    }
    get shouldShowLimitedLifeLink() {
        return isNullOrUndefined(this.navbarSettings?.showLimitedLifeLink)
            ? true
            : this.navbarSettings?.showLimitedLifeLink;
    }
    get shouldShowMyApprovals() {
        return isNullOrUndefined(this.navbarSettings?.showMyApprovals) ? true : this.navbarSettings?.showMyApprovals;
    }
    get shouldShowNewBrowserWindowIcon() {
        return isNullOrUndefined(this.navbarSettings?.showNewBrowserWindowIcon)
            ? true
            : this.navbarSettings?.showNewBrowserWindowIcon;
    }
    get shouldShowUserSupport() {
        return isNullOrUndefined(this.navbarSettings?.showUserSupport) ? true : this.navbarSettings?.showUserSupport;
    }
    get userId() {
        return this.currentUser?.id;
    }
    get usersManageLink() {
        return `/users/manage/${this.currentUser?.id}#settings`;
    }
    created() {
        this.navOpenState = localStorage.getItem('navOpenState') === 'true';
        try {
            // inital fetch on create
            this.fetchLimitedLifeAlerts();
            // Set Polling for Limited Life Alerts
            setInterval(() => {
                this.fetchLimitedLifeAlerts();
            }, LIMITED_LIFE_ALERT_POLLING_RATE);
        }
        catch (err) {
            this.handleErrorSituation(err);
        }
        this.$listen('closeTheSideNav', () => this.closeTheSideNav());
        // listen for hardware ChangeSet updates to update navbar alert
        this.$listen('reload_user_alerts', () => {
            this.fetchLimitedLifeAlerts();
        });
        // listen for highlighted navbar items from user settings
        this.$listen('navbar_item_highlighted', (itemName) => {
            this.highlightNavbarItem(itemName);
        });
        this.$listen('user_updated', (user) => {
            LS.setWithEncoding(CURRENT_USER_LS_VARIABLE, user);
            store.currentUser = user;
        });
    }
    highlightNavbarItem(itemRefName) {
        if (itemRefName && this.$refs[itemRefName]) {
            this.$refs[itemRefName].classList.add('highlight');
        }
        else {
            this.$el.querySelectorAll('.nav_bar_item').forEach((item) => {
                item?.classList.remove('highlight');
            });
        }
    }
    closeTheSideNav() {
        this.navOpenState = false;
    }
    handleLaunchpadSessionAuthorizeClick() {
        window.open('/api/oauth/authorize', '_blank');
    }
    async fetchCalibrations() {
        try {
            const oneMonthFromNow = format(addDays(new Date(), 30), 'yyyy-MM-dd');
            const resp = await this.$apollo.query({
                query: gql `
          query getCalibrationAlerts($oneMonthFromNow: Date) {
            getCalibrationAlerts(filter: { calibrationDueDate: { lessThanOrEqualTo: $oneMonthFromNow } }) {
              totalCount
              nodes {
                calibrationQuotaId
              }
            }
          }
        `,
                fetchPolicy: 'network-only',
                variables: {
                    oneMonthFromNow: oneMonthFromNow
                }
            });
            if (resp.data) {
                this.calibrations = resp.data.getCalibrationAlerts.nodes;
            }
            else {
                this.calibrations = null;
            }
        }
        catch (err) {
            this.handleErrorSituation(err);
        }
    }
    async fetchLimitedLifeAlerts() {
        try {
            // TODO: This should default parameters
            const resp = await this.$apollo.query({
                query: gql `
          query GetLimitedLifeAlertsNavbar {
            getLimitedLifeAlerts(expirationThresholdPercent: 0.2, serviceThresholdPercent: 0.2) {
              totalCount
            }
          }
        `,
                fetchPolicy: 'network-only'
            });
            if (resp.data?.getLimitedLifeAlerts) {
                this.limitedLifeAlertCount = resp.data?.getLimitedLifeAlerts.totalCount || 0;
            }
            else {
                this.limitedLifeAlertCount = 0;
            }
        }
        catch (err) {
            this.handleErrorSituation(err);
        }
    }
    onClickOfCosmicLogo(event) {
        // already at home
        if (!event.metaKey && !event.ctrlKey && this.$route.fullPath === '/') {
            event.preventDefault();
            return;
        }
        if (!event.metaKey && !event.ctrlKey) {
            event.preventDefault();
            this.$router.push('/');
        }
    }
    toggleTheSideNavigationOpenState() {
        this.navOpenState = !this.navOpenState;
        this.ariaSelected = !this.ariaSelected;
    }
    onNavStateChange(val) {
        localStorage.setItem('navOpenState', val.toString());
        return val;
    }
    onUserNeedsNewSession(val) {
        if (val) {
            // start 2 sec polling to check for the user getting a fresh session again
            this.userNeedsSessionInterval = setInterval(() => {
                this.fetchCalibrations();
                this.fetchLimitedLifeAlerts();
            }, 5000);
        }
        else {
            if (this.userNeedsSessionInterval) {
                // when the flags is changed and is now false, we need to make sure we stop our polling
                clearInterval(this.userNeedsSessionInterval);
            }
            this.userNeedsSessionInterval = null;
        }
    }
    handleErrorSituation(err) {
        const errString = err.toString();
        // This is nominal in a user timeout situation
        if (this.userNeedsNewSession) {
            return;
        }
        // When the launchpad session times out it returns HTML here which throws the following error message
        if (errString.startsWith(LAUNCHPAD_ERROR_SLUG)) {
            this.userNeedsNewSession = true;
            this.$errorUtility({
                err,
                backupErrorText: 'Error: There was a Launchpad error.'
            });
            return;
        }
        // fallback if there another error
        this.$errorUtility({
            err,
            backupErrorText: 'Navbar failed to load all of its alert data.'
        });
    }
};
__decorate([
    Prop({
        type: String,
        required: false,
        default: null
    }),
    __metadata("design:type", Object)
], TheNavbar.prototype, "outageAlertState", void 0);
__decorate([
    Emit('nav-open-state'),
    Watch('navOpenState'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], TheNavbar.prototype, "onNavStateChange", null);
__decorate([
    Watch('userNeedsNewSession'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], TheNavbar.prototype, "onUserNeedsNewSession", null);
TheNavbar = __decorate([
    Component
], TheNavbar);
export default TheNavbar;
