var _a;
import { __decorate, __metadata } from "tslib";
import { DebounceAll } from 'lodash-decorators';
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Side } from '~/db_types/swagger_types';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import { transformItemMasterResponseWithRefs } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let MiniItemMaster = class MiniItemMaster extends Mixins(ReadOnlyMixin) {
    isFetching = false;
    selectedEntity = null;
    asBuiltNumber;
    drawingNumber;
    entity;
    inlineClear;
    showManageLink;
    showInfoIcon;
    side;
    title;
    get shouldShowInlineClearIcon() {
        return this.inlineClear && !this.readOnly;
    }
    get transformedSelectedEntity() {
        return this.selectedEntity ? transformItemMasterResponseWithRefs([this.selectedEntity])[0] : null;
    }
    get verticalLabelDisplayItems() {
        if (!this.selectedEntity) {
            return [];
        }
        const items = [];
        if (this.transformedSelectedEntity?.side !== Side.NONE) {
            items.push({
                name: 'Side',
                value: this.transformedSelectedEntity?._side
            });
        }
        items.push({
            name: 'FSC',
            value: this.transformedSelectedEntity?._federalSupplyClassNumber
        });
        items.push({
            name: 'Obj cls',
            value: this.transformedSelectedEntity?._objectClass
        });
        return items;
    }
    onClickOfInlineClear() {
        return this.transformedSelectedEntity;
    }
    emitInfoClicked() {
        return this.transformedSelectedEntity;
    }
    onEntityChange() {
        if (!this.entity) {
            return;
        }
        this.selectedEntity = { ...this.entity };
    }
    async fetchEntity() {
        if (!this.asBuiltNumber || !this.drawingNumber || !this.side) {
            return;
        }
        try {
            this.isFetching = true;
            const resp = await this.$http.get(`/hardware/item-masters/${encodeURIComponent(this.drawingNumber)}/${encodeURIComponent(this.asBuiltNumber)}/${this.side}`);
            this.selectedEntity = resp.data;
            this.isFetching = false;
        }
        catch (err) {
            this.isFetching = false;
            this.$errorUtility({
                err
            });
        }
    }
};
__decorate([
    Prop({
        type: String,
        required: false
    }),
    __metadata("design:type", String)
], MiniItemMaster.prototype, "asBuiltNumber", void 0);
__decorate([
    Prop({
        type: String,
        required: false
    }),
    __metadata("design:type", String)
], MiniItemMaster.prototype, "drawingNumber", void 0);
__decorate([
    Prop({
        required: false,
        type: Object
    }),
    __metadata("design:type", Object)
], MiniItemMaster.prototype, "entity", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], MiniItemMaster.prototype, "inlineClear", void 0);
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], MiniItemMaster.prototype, "showManageLink", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], MiniItemMaster.prototype, "showInfoIcon", void 0);
__decorate([
    Prop({
        type: String,
        required: false
    }),
    __metadata("design:type", typeof (_a = typeof Side !== "undefined" && Side) === "function" ? _a : Object)
], MiniItemMaster.prototype, "side", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], MiniItemMaster.prototype, "title", void 0);
__decorate([
    Emit('inlineClear'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MiniItemMaster.prototype, "onClickOfInlineClear", null);
__decorate([
    Emit('infoClicked'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MiniItemMaster.prototype, "emitInfoClicked", null);
__decorate([
    Watch('entity', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MiniItemMaster.prototype, "onEntityChange", null);
__decorate([
    DebounceAll(10),
    Watch('asBuiltNumber', { immediate: true }),
    Watch('drawingNumber', { immediate: true }),
    Watch('side', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], MiniItemMaster.prototype, "fetchEntity", null);
MiniItemMaster = __decorate([
    Component
], MiniItemMaster);
export default MiniItemMaster;
