import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins } from 'vue-property-decorator';
import LoaderMixin from '~/nasa_ui/mixins/LoaderMixin';
import { mutationFactory, queryFactory } from '../base/mixinFactories';
import { CosmicCommentSlimFragment } from '../comment/slim';
import { CosmicDocumentSlimFragment } from '../document/slim';
import { CosmicEventSlimFragment } from '../event/slim';
import { CosmicItemDrawingSlimFragment } from '../itemDrawing/slim';
import { CosmicItemInstanceSlimFragment } from '../itemInstance/slim';
import { CosmicItemMasterSlimFragment } from '../itemMaster/slim';
import { CosmicHardwareListAssemblySlimFragment, CosmicHardwareListSlimFragment } from './slim';
export const CosmicHardwareListAssemblyFragment = gql `
  ${CosmicHardwareListAssemblySlimFragment}
  ${CosmicItemInstanceSlimFragment}

  fragment CosmicHardwareListAssembly on HardwareListAssembly {
    ...CosmicHardwareListAssemblySlim

    itemInstance {
      ...CosmicItemInstanceSlim
    }
  }
`;
export const CosmicHardwareListFragment = gql `
  ${CosmicCommentSlimFragment}
  ${CosmicDocumentSlimFragment}
  ${CosmicEventSlimFragment}
  ${CosmicHardwareListAssemblyFragment}
  ${CosmicHardwareListSlimFragment}
  ${CosmicItemDrawingSlimFragment}
  ${CosmicItemMasterSlimFragment}

  fragment CosmicHardwareList on HardwareList {
    ...CosmicHardwareListSlim

    comments {
      nodes {
        ...CosmicCommentSlim
      }
    }

    document {
      ...CosmicDocumentSlim
    }

    event {
      ...CosmicEventSlim
    }

    hardwareListAssemblies(orderBy: SEQUENCE_ASC) {
      nodes {
        ...CosmicHardwareListAssembly
      }
    }

    hardwareListTemplate {
      id
      name
      nodeId
      subType

      templateItems: hardwareListTemplateSlots {
        totalCount

        nodes {
          id
          maxQuantity
          minQuantity
          name
          nodeId
          sequence

          parts: hardwareListTemplateParts {
            nodes {
              drawingNumber
              id
              nodeId

              itemDrawing {
                ...CosmicItemDrawingSlim
              }

              itemMaster {
                ...CosmicItemMasterSlim
              }
            }
          }
        }
      }
    }
  }
`;
export const CreateHardwareListAssembly = gql `
  mutation AddHardwareListAssembly($input: CreateHardwareListAssemblyInput!) {
    createHardwareListAssembly(input: $input) {
      clientMutationId
    }
  }
`;
export const DeleteHardwareListAssemblyById = gql `
  mutation DeleteHardwareListAssemblyById($id: UUID!) {
    deleteHardwareListAssemblyById(input: { id: $id }) {
      clientMutationId
    }
  }
`;
export const GetHardwareListsByType = gql `
  ${CosmicHardwareListSlimFragment}

  query GetHardwareListsByType($subType: HardwareListType!) {
    hardwareLists(condition: { subType: $subType }) {
      nodes {
        ...CosmicHardwareListSlim
      }
    }
  }
`;
export const GetHardwareListsByTypeFull = gql `
  ${CosmicHardwareListFragment}

  query GetHardwareListsByTypeFull($subType: HardwareListType!) {
    hardwareLists(condition: { subType: $subType }) {
      nodes {
        ...CosmicHardwareList
      }
    }
  }
`;
export const GetHardwareListsById = gql `
  ${CosmicHardwareListFragment}

  query GetHardwareListsById($id: UUID!) {
    hardwareListById(id: $id) {
      ...CosmicHardwareList
    }
  }
`;
export const GetHardwareListAssemblyById = gql `
  ${CosmicHardwareListAssemblySlimFragment}

  query GetHardwareListAssemblyById($id: UUID!) {
    hardwareListAssemblyById(id: $id) {
      ...CosmicHardwareListAssemblySlim
    }
  }
`;
export const GetHardwareListsByIdWithInvDetails = gql `
  ${CosmicHardwareListAssemblySlimFragment}
  ${CosmicHardwareListSlimFragment}

  query GetHardwareListsByIdWithInvDetails($id: UUID!) {
    hardwareListById(id: $id) {
      ...CosmicHardwareListSlim

      hardwareListAssemblies(orderBy: SEQUENCE_ASC) {
        nodes {
          ...CosmicHardwareListAssemblySlim
        }
      }
    }
  }
`;
export const CheckForHardwareListTemplateDuplicates = gql `
  query CheckForHardwareListTemplateDuplicates($name: String, $subType: HardwareListType) {
    hardwareListTemplates(first: 1, condition: { name: $name, subType: $subType }) {
      nodes {
        id
        nodeId
      }
    }
  }
`;
let HardwareListRepo = class HardwareListRepo extends Mixins(LoaderMixin) {
    checkForHardwareListTemplateDuplicates = queryFactory(this, CheckForHardwareListTemplateDuplicates);
    createHardwareListAssembly = mutationFactory(this, CreateHardwareListAssembly);
    deleteHardwareListAssemblyById = mutationFactory(this, DeleteHardwareListAssemblyById);
    getHardwareListsById = queryFactory(this, GetHardwareListsById);
    getHardwareListAssemblyById = queryFactory(this, GetHardwareListAssemblyById);
};
HardwareListRepo = __decorate([
    Component
], HardwareListRepo);
export { HardwareListRepo };
