import { LimitedLifeKind } from '~/db_types/swagger_types';
export default class CosmicLimitedLife {
    limitedLife;
    report = null;
    constructor(ll, llReport) {
        this.limitedLife = { ...ll };
        if (llReport) {
            this.report = [...llReport];
        }
    }
    /**
     * Looks at cycle | flight | eva
     */
    get hasAnyCycleBasedLifeQuotas() {
        return this.hasLimitedLifeCycle || this.hasLimitedLifeFlight || this.hasLimitedLifeEva;
    }
    /**
     * Looks at operation | shelf | usage
     */
    get hasAnyDateBasedLifeQuotas() {
        return this.hasLimitedLifeOperation || this.hasLimitedLifeShelf || this.hasLimitedLifeUsage;
    }
    /**
     * Looks at interval | onOrbit | pressurizedTime | static
     */
    get hasAnyIntervalBasedLifeQuotas() {
        return (this.hasLimitedLifeInterval ||
            this.hasLimitedLifeOnOrbit ||
            this.hasLimitedLifePressurizedTime ||
            this.hasLimitedLifeStatic);
    }
    get hasAnyLimitedLifeQuotas() {
        return this.hasAnyCycleBasedLifeQuotas || this.hasAnyDateBasedLifeQuotas || this.hasAnyIntervalBasedLifeQuotas;
    }
    get hasAnyLimitedLifeReportCycleRelated() {
        return this.limitedLifeReportCycleRelated.length > 0;
    }
    get hasAnyLimitedLifeReportDateRelated() {
        return this.limitedLifeReportDateRelated.length > 0;
    }
    get hasAnyLimitedLifeReportIntervalRelated() {
        return this.limitedLifeReportIntervalRelated.length > 0;
    }
    get hasAnyLimitedLifeReport() {
        return (this.hasAnyLimitedLifeReportCycleRelated ||
            this.hasAnyLimitedLifeReportDateRelated ||
            this.hasAnyLimitedLifeReportIntervalRelated);
    }
    get hasLimitedLifeCodes() {
        return Boolean(this.limitedLife.code) && this.limitedLife.code?.length;
    }
    /**
     * If either exp or service has a value > 0 this is true
     */
    get hasLimitedLifeCycle() {
        return Boolean(this.limitedLife.cycle?.expirationQuotaUses || this.limitedLife.cycle?.serviceQuotaUses);
    }
    get hasLimitedLifeDescription() {
        return Boolean(this.limitedLife.description);
    }
    /**
     * If either exp or service has a value > 0 this is true
     */
    get hasLimitedLifeEva() {
        return Boolean(this.limitedLife.eva?.expirationQuotaUses || this.limitedLife.eva?.serviceQuotaUses);
    }
    /**
     * If either exp or service has a value > 0 this is true
     */
    get hasLimitedLifeFlight() {
        return Boolean(this.limitedLife.flight?.expirationQuotaUses || this.limitedLife.flight?.serviceQuotaUses);
    }
    get hasLimitedLifeInterval() {
        return Boolean(this.limitedLife.interval?.expirationQuotaInterval || this.limitedLife.interval?.serviceQuotaInterval);
    }
    get hasLimitedLifeOnOrbit() {
        return Boolean(this.limitedLife.onOrbit?.expirationQuotaInterval || this.limitedLife.onOrbit?.serviceQuotaInterval);
    }
    get hasLimitedLifeOperation() {
        return (Boolean(this.limitedLife.operation) &&
            (Boolean(this.limitedLife.operation.expirationQuotaInterval) ||
                Boolean(this.limitedLife.operation.serviceQuotaInterval) ||
                Boolean(this.limitedLife.operation.overrideExpirationDate)));
    }
    get hasLimitedLifePressurizedTime() {
        return Boolean(this.limitedLife.pressurizedTime?.expirationQuotaInterval ||
            this.limitedLife.pressurizedTime?.serviceQuotaInterval);
    }
    get hasLimitedLifeShelf() {
        return (Boolean(this.limitedLife.shelf) &&
            (Boolean(this.limitedLife.shelf.expirationQuotaInterval) ||
                Boolean(this.limitedLife.shelf.serviceQuotaInterval) ||
                Boolean(this.limitedLife.shelf.overrideExpirationDate)));
    }
    get hasLimitedLifeStatic() {
        return Boolean(this.limitedLife.static?.expirationQuotaInterval || this.limitedLife.static?.serviceQuotaInterval);
    }
    get hasLimitedLifeUsage() {
        return (Boolean(this.limitedLife.usage) &&
            (Boolean(this.limitedLife.usage.expirationQuotaInterval) ||
                Boolean(this.limitedLife.usage.serviceQuotaInterval) ||
                Boolean(this.limitedLife.usage.overrideExpirationDate)));
    }
    get orderedLimitedLifeCodes() {
        if (this.hasLimitedLifeCodes) {
            const _codes = [...this.limitedLife.code];
            _codes.sort();
            return _codes;
        }
        return [];
    }
    get limitedLifeReportCycleRelated() {
        return (this.report?.filter((item) => item.kind === LimitedLifeKind.USES) ||
            []);
    }
    get limitedLifeReportDateRelated() {
        return (this.report?.filter((item) => item.kind === LimitedLifeKind.DATE) ||
            []);
    }
    get limitedLifeReportIntervalRelated() {
        return (this.report?.filter((item) => item.kind === LimitedLifeKind.INTERVAL) ||
            []);
    }
}
