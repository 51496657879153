import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop } from 'vue-property-decorator';
import CurrentUserMixin from '~/nasa_ui/mixins/CurrentUserMixin';
import { CosmicIcons } from '~/nasa_ui/utils/ui/CosmicIcons';
let TheCreateMenu = class TheCreateMenu extends Mixins(CurrentUserMixin) {
    isHoveringOverCreateMenuIcon = false;
    CosmicIcons = CosmicIcons;
    items;
    get selectOptions() {
        return this.items.filter((option) => this.currentUserHasPermission(option.permissionToCheck));
    }
    get hasAnyCreateItems() {
        if (!this.selectOptions) {
            return false;
        }
        return this.selectOptions && this.selectOptions.length > 0;
    }
    onClickOfCreateItem(option, event) {
        if (!event.metaKey && !event.ctrlKey) {
            event.preventDefault();
            this.$router.push(option.destinationUrl);
            this.$message('closeTheSideNav');
        }
    }
};
__decorate([
    Prop({
        required: true,
        type: Array
    }),
    __metadata("design:type", Array)
], TheCreateMenu.prototype, "items", void 0);
TheCreateMenu = __decorate([
    Component
], TheCreateMenu);
export default TheCreateMenu;
