import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import BaseButton from '~/nasa_ui/base/BaseButton';
let ButtonSave = class ButtonSave extends BaseButton {
    get computedColor() {
        return this.color || 'var(--v-success-base)';
    }
    get computedIcon() {
        return this.$slots.icon ? null : this.icon || this.$icons.save;
    }
    get computedTextColor() {
        return this.textColor || '#fff';
    }
};
ButtonSave = __decorate([
    Component
], ButtonSave);
export default ButtonSave;
