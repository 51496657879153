var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('c-menu', {
    attrs: {
      "align-center": ""
    }
  }, [_vm.hasAnyCreateItems ? _c('ButtonMiniAction', {
    staticClass: "create_button",
    attrs: {
      "slot": "activator",
      "icon": _vm.CosmicIcons.create,
      "icon-color": "#fff",
      "icon-hover-color": "#000",
      "label": "Create a new entity"
    },
    slot: "activator"
  }) : _vm._e(), _vm._l(_vm.selectOptions, function (option) {
    return _c('c-menu-item', {
      key: option.destinationUrl,
      attrs: {
        "href": option.destinationUrl,
        "icon": option.icon,
        "icon-color": option.textColor
      },
      on: {
        "c-click": function cClick($event) {
          return _vm.onClickOfCreateItem(option, $event.detail);
        }
      }
    }, [_vm._v(_vm._s(option.displayText))]);
  })], 2)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };