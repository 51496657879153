var _a, _b, _c, _d, _e;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Debounce } from 'lodash-decorators';
import isEqual from 'lodash/isEqual';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { DocumentType, Maybe } from '~/db_types';
import { AuthorityCode, AuthorityCodeDisplay, AuthorityCodeToDocumentType } from '~/nasa_ui/types/enums/authority-codes';
let AppAuthority = class AppAuthority extends Vue {
    color;
    hideHeader;
    showBorder;
    size;
    showComments;
    required;
    codeTitle;
    commentTitle;
    numberTitle;
    AuthorityCodeDisplay = AuthorityCodeDisplay;
    AuthorityCode = AuthorityCode;
    foundDocument = null;
    authorityCodeFormData = {
        authorityCode: null,
        authorityNumber: null,
        comment: null
    };
    // need this for initial value with v-model
    value;
    get canQueryForDocument() {
        return (this.cosmicDocumentType &&
            this.cosmicDocumentType !== DocumentType.OTHER_DOCUMENT &&
            this.authorityCodeFormData.authorityNumber &&
            this.authorityCodeFormData.authorityNumber.length > 0);
    }
    get cosmicDocumentType() {
        if (this.authorityCodeFormData.authorityCode) {
            return AuthorityCodeToDocumentType.get(this.authorityCodeFormData.authorityCode);
        }
    }
    get foundDocumentId() {
        return this.foundDocument?.id ?? null;
    }
    async queryForDocumentMatch(subType, documentNumber) {
        if (!subType || !documentNumber) {
            return;
        }
        try {
            const resp = await this.$apollo.query({
                query: gql `
          query DocumentMatchOnAppAuthority($subType: [String], $number: [String]) {
            searchDocuments(subType: $subType, number: $number) {
              nodes {
                id
                nodeId
              }
            }
          }
        `,
                variables: {
                    subType: [subType],
                    number: [documentNumber]
                },
                fetchPolicy: 'network-only'
            });
            const result = resp.data?.searchDocuments?.nodes;
            const partialDocument = result && result.length ? result[0] : null;
            this.foundDocument = partialDocument;
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    onValueChange(value) {
        if (!value) {
            return;
        }
        if (isEqual(value, this.authorityCodeFormData)) {
            return;
        }
        this.authorityCodeFormData = Object.assign({}, value);
    }
    upperCaseAuthorityNumber(val) {
        this.authorityCodeFormData.authorityNumber = val.toUpperCase();
    }
    cosmicAuthorityChange() {
        if (this.canQueryForDocument) {
            this.queryForDocumentMatch(this.cosmicDocumentType, this.authorityCodeFormData.authorityNumber);
        }
        return this.authorityCodeFormData;
    }
};
__decorate([
    Prop({ default: 'change_sets' }),
    __metadata("design:type", String)
], AppAuthority.prototype, "color", void 0);
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], AppAuthority.prototype, "hideHeader", void 0);
__decorate([
    Prop({ default: true, type: Boolean }),
    __metadata("design:type", Boolean)
], AppAuthority.prototype, "showBorder", void 0);
__decorate([
    Prop({
        type: String,
        default: '2'
    }),
    __metadata("design:type", String)
], AppAuthority.prototype, "size", void 0);
__decorate([
    Prop({ default: true, type: Boolean }),
    __metadata("design:type", Boolean)
], AppAuthority.prototype, "showComments", void 0);
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], AppAuthority.prototype, "required", void 0);
__decorate([
    Prop({
        type: String,
        default: 'Authority code'
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], AppAuthority.prototype, "codeTitle", void 0);
__decorate([
    Prop({
        type: String,
        default: 'Authority comment'
    }),
    __metadata("design:type", typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object)
], AppAuthority.prototype, "commentTitle", void 0);
__decorate([
    Prop({
        type: String,
        default: 'Authority number'
    }),
    __metadata("design:type", typeof (_c = typeof Maybe !== "undefined" && Maybe) === "function" ? _c : Object)
], AppAuthority.prototype, "numberTitle", void 0);
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_d = typeof Maybe !== "undefined" && Maybe) === "function" ? _d : Object)
], AppAuthority.prototype, "value", void 0);
__decorate([
    Watch('value', { immediate: true, deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_e = typeof Maybe !== "undefined" && Maybe) === "function" ? _e : Object]),
    __metadata("design:returntype", void 0)
], AppAuthority.prototype, "onValueChange", null);
__decorate([
    Watch('authorityCodeFormData.authorityNumber'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], AppAuthority.prototype, "upperCaseAuthorityNumber", null);
__decorate([
    Debounce(300, { leading: false }),
    Watch('authorityCodeFormData', { deep: true }),
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppAuthority.prototype, "cosmicAuthorityChange", null);
AppAuthority = __decorate([
    Component
], AppAuthority);
export default AppAuthority;
