var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { ChangeSetType, Side } from '~/db_types';
import BaseModalAssembly from '~/nasa_ui/base/BaseModalAssembly';
import { INumberOfChangeSets } from '~/nasa_ui/components/MiniAssembly/MiniAssembly';
import { WorkingAssemblyRepoMixin } from '~/nasa_ui/DAL/workingAssemblies';
let ModalAssemblyEdit = class ModalAssemblyEdit extends Mixins(BaseModalAssembly, WorkingAssemblyRepoMixin) {
    formData = {
        authorityCode: null,
        authorityNumber: null,
        installedOn: null,
        sequence: null,
        quantity: null
    };
    numberOfChangeSetsProp;
    get numberOfOpenChildChangeSets() {
        if (!this.numberOfChangeSetsProp?.openChildChangeSets) {
            return;
        }
        return this.numberOfChangeSetsProp?.openChildChangeSets.length;
    }
    get numberOfOpenParentChangeSets() {
        return this.numberOfChangeSetsProp?.openParentChangeSets?.length ?? 0;
    }
    get showRemovalButton() {
        return Boolean(!this.userNeedsApprovalToRemoveAssembly && this.numberOfOpenParentChangeSets === 0);
    }
    get editAssemblyMutationVariables() {
        if (!this.isFormValid || !this.selectedEntity?.id) {
            return null;
        }
        return {
            input: {
                id: this.selectedEntity?.id,
                patch: {
                    installedOn: this.formData.installedOn,
                    sequence: this.formData.sequence
                }
            }
        };
    }
    get shouldDisableEditButton() {
        return !this.isFormValid;
    }
    get removalChangeSetVariables() {
        if (!this.selectedWorkingAssemblyInventory?.id || !this.parentData?.inventory?.id) {
            return null;
        }
        return {
            inventoryId: this.parentData?.inventory?.id,
            childInventoryId: this.selectedWorkingAssemblyInventory?.id,
            openedDate: this.$dateDisplay(new Date()),
            subType: ChangeSetType.ITEM_INSTANCE_INVENTORY_ASSEMBLY_REMOVE
        };
    }
    isNotMoreThanMaxQuantity(minQuantity, maxQuantity) {
        return minQuantity <= maxQuantity;
    }
    async onClickOfRequestRemoval() {
        if (!this.removalChangeSetVariables) {
            return;
        }
        await this.createEntity(this.removalChangeSetVariables);
        if (this.$route.params.id) {
            this.$message(`reload_${this.$route.params.id}`);
        }
    }
    onClickOfEditAssembly() {
        if (this.isFormValid) {
            this.editEntity(this.editAssemblyMutationVariables);
        }
    }
    onMetaPlusEnter() {
        if (!this.isFormValid) {
            return;
        }
        this.editEntity(this.editAssemblyMutationVariables);
    }
    setData() {
        this.formData.sequence = this.selectedEntity?.sequence || 0;
        this.formData.installedOn = this.selectedEntity?.installedOn || Side.NONE;
    }
};
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof INumberOfChangeSets !== "undefined" && INumberOfChangeSets) === "function" ? _a : Object)
], ModalAssemblyEdit.prototype, "numberOfChangeSetsProp", void 0);
__decorate([
    Watch('selectedEntity', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalAssemblyEdit.prototype, "setData", null);
ModalAssemblyEdit = __decorate([
    Component
], ModalAssemblyEdit);
export default ModalAssemblyEdit;
