import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { LinksTypeDisplay } from '~/nasa_ui/types';
import { buildEntityTypeIcons } from '../transformEntityToTableFormat';
export const transformLinks = (links) => {
    return links.map((link) => {
        return Object.assign({}, link, {
            _currentRevision: link?.currentRevision || DEFAULT_DASH,
            _currentVersionUrl: link?.currentVersionUrl
                ? `<a href="${link?.currentVersionUrl}">${link?.currentVersionUrl}</a>`
                : DEFAULT_DASH,
            _entityTypeIcon: buildEntityTypeIcons(link) || DEFAULT_DASH,
            _referenceNumber: link?.referenceNumber || DEFAULT_DASH,
            _subType: LinksTypeDisplay.get(link?.subType) || DEFAULT_DASH,
            _workingVersionUrl: link?.workingVersionUrl
                ? `<a href="${link?.workingVersionUrl}">${link?.workingVersionUrl}</a>`
                : DEFAULT_DASH
        });
    });
};
