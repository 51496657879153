import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins } from 'vue-property-decorator';
import LoaderMixin from '~/nasa_ui/mixins/LoaderMixin';
import { queryFactory } from '../base/mixinFactories';
import { CosmicInventorySlimFragment } from '../inventory/slim';
import { CosmicItemDrawingSlimFragment } from '../itemDrawing/slim';
import { CosmicItemMasterSlimFragment } from '../itemMaster/slim';
import { CosmicUserSlimFragment } from '../user';
import { CosmicWorkingAssemblySlimFragment } from './slim';
export const CosmicWorkingAssemblyFragment = gql `
  ${CosmicInventorySlimFragment}
  ${CosmicItemDrawingSlimFragment}
  ${CosmicItemMasterSlimFragment}
  ${CosmicUserSlimFragment}
  ${CosmicWorkingAssemblySlimFragment}

  fragment CosmicWorkingAssembly on WorkingAssembly {
    ...CosmicWorkingAssemblySlim

    assemblyTemplate {
      autoHardwareListId
      createdByUserId
      createdDateTime
      documentId
      id
      installedOn
      maxQuantity
      minQuantity
      name
      parentId
      pbsItemId
      sequence
      subType
    }

    createdByUser {
      ...CosmicUserSlim
    }

    childWorkingAssemblies {
      nodes {
        ...CosmicWorkingAssemblySlim
      }
    }

    inventory {
      ...CosmicInventorySlim

      # this is bc the assembly_transactions report takes ItemInstanceNodeIds in the query string
      itemInstance {
        id
      }
    }

    itemDrawing {
      ...CosmicItemDrawingSlim
    }

    itemMaster {
      ...CosmicItemMasterSlim

      assemblyTemplates {
        nodes {
          autoHardwareListId
          createdByUserId
          createdDateTime
          documentId
          id
          installedOn
          maxQuantity
          minQuantity
          name
          parentId
          pbsItemId
          sequence
          subType

          children {
            totalCount
          }
        }
      }
    }

    parent {
      ...CosmicWorkingAssemblySlim

      inventory {
        ...CosmicInventorySlim
      }
    }
  }
`;
export const GetWorkingAssemblyById = gql `
  ${CosmicWorkingAssemblyFragment}

  query GetWorkingAssemblyById($id: UUID!) {
    workingAssemblyById(id: $id) {
      ...CosmicWorkingAssembly
    }
  }
`;
export const GetWorkingAssemblySlimById = gql `
  ${CosmicWorkingAssemblySlimFragment}

  query GetWorkingAssemblySlimById($id: UUID!) {
    workingAssemblyById(id: $id) {
      ...CosmicWorkingAssemblySlim
    }
  }
`;
let WorkingAssemblyRepoMixin = class WorkingAssemblyRepoMixin extends Mixins(LoaderMixin) {
    getWorkingAssemblyById = queryFactory(this, GetWorkingAssemblyById);
    getWorkingAssemblySlimById = queryFactory(this, GetWorkingAssemblySlimById);
};
WorkingAssemblyRepoMixin = __decorate([
    Component
], WorkingAssemblyRepoMixin);
export { WorkingAssemblyRepoMixin };
