import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop } from 'vue-property-decorator';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import BaseModal from '~/nasa_ui/base/BaseModal';
/**
 * This component either shows an AHD passing the nodeId directly to the AHD
 */
let ModalAppHardwareDisplay = class ModalAppHardwareDisplay extends Mixins(BaseModal, BaseCosmic) {
    nodeId;
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], ModalAppHardwareDisplay.prototype, "nodeId", void 0);
ModalAppHardwareDisplay = __decorate([
    Component
], ModalAppHardwareDisplay);
export default ModalAppHardwareDisplay;
