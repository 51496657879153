// TODO: this should probably just be `ActivityType`
export var OdtApplicableMetric;
(function (OdtApplicableMetric) {
    OdtApplicableMetric["MAINTENANCE"] = "MAINTENANCE";
    OdtApplicableMetric["CALIBRATION"] = "CALIBRATION";
    OdtApplicableMetric["USAGE_LIFE"] = "USAGE_LIFE";
})(OdtApplicableMetric || (OdtApplicableMetric = {}));
export const OdtApplicableMetricDisplay = new Map([
    [OdtApplicableMetric.MAINTENANCE, 'MAINTENANCE'],
    [OdtApplicableMetric.CALIBRATION, 'CALIBRATION'],
    [OdtApplicableMetric.USAGE_LIFE, 'USAGE LIFE']
]);
