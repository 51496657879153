import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { DebounceAll } from 'lodash-decorators';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { ChangeSetType, InventoryStatus } from '~/db_types/swagger_types';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { AS_BUILT_COL, BASE_DATE_COL, BASE_ICON_COL, BASE_NUMBER_COL, BASE_TEXT_COL, BUILDING_BIN_STOCK_COL, CLASS_COL, DESCRIPTION_COL, DRAWING_NUMBER_COL, INVENTORY_QTY_COL, LOT_COL, PROJECT_CODE_COL, SERIAL_COL, SIDE_COL, UNIT_COST_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { AlertType } from '~/nasa_ui/types';
import { getAssemblyTemplate } from '~/nasa_ui/utils';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { STANDARD_HARDWARE_COLUMNS } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { transformAssemblyTemplateResponseWithChildrens, transformInventoryResponses, transformPartReferenceResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let ModalAssemblyTemplateActualization = class ModalAssemblyTemplateActualization extends Mixins(BaseModal) {
    isLoading = false;
    matchingInventory = [];
    selectedEntity = null;
    selectedInventories = [];
    partReferenceTableHeaders = [
        composeTableHeader(BASE_ICON_COL, {
            text: 'Hw type',
            value: '_hardwareTypeIcon'
        }),
        composeTableHeader(DRAWING_NUMBER_COL, {
            text: `Drawing<br/>number`,
            value: 'drawingNumber'
        }),
        composeTableHeader(AS_BUILT_COL, {
            noWrap: true,
            value: '_asBuiltNumber'
        }),
        composeTableHeader(DESCRIPTION_COL, {
            noWrap: true,
            value: '_description'
        }),
        composeTableHeader(SIDE_COL, {
            value: '_side'
        }),
        composeTableHeader(SERIAL_COL, {
            value: '_serialNumber'
        }),
        composeTableHeader(LOT_COL, {
            value: '_lotNumber'
        }),
        composeTableHeader(CLASS_COL, {
            value: '_itemClasses'
        }),
        composeTableHeader(BASE_NUMBER_COL, {
            text: 'CEI',
            value: '_contractEndingItemNumber'
        }),
        composeTableHeader(BASE_TEXT_COL, {
            noEscape: true,
            text: 'Location',
            value: '_location'
        }),
        composeTableHeader(BASE_DATE_COL, {
            text: 'Birth',
            value: '_birthDate'
        }),
        composeTableHeader(BASE_TEXT_COL, {
            text: 'Calibration<br/>number',
            value: '_calibrationNumber'
        }),
        composeTableHeader(BASE_DATE_COL, {
            text: 'Calibration',
            value: '_lastCalibrationDate'
        }),
        composeTableHeader(BASE_DATE_COL, {
            text: 'Usage<br/>expiry',
            value: '_usageLifeExpirationDate'
        })
    ];
    inventoryTableHeaders = [
        ...STANDARD_HARDWARE_COLUMNS,
        CLASS_COL,
        PROJECT_CODE_COL,
        INVENTORY_QTY_COL,
        UNIT_COST_COL,
        BUILDING_BIN_STOCK_COL
    ];
    assemblyTemplateId;
    parentInventoryId;
    get availableInventories() {
        return transformInventoryResponses(this.matchingInventory);
    }
    get inventorySearchFilters() {
        return {
            status: [InventoryStatus.IN_INVENTORY]
        };
    }
    get shouldDisableInstallButton() {
        if (!this.selectedEntity) {
            return true;
        }
        return (this.selectedInventories.length === 0 ||
            this.selectedInventories.length > this.selectedEntity.maxQuantity ||
            this.selectedInventories.length < this.selectedEntity.minQuantity);
    }
    get transformedSelectedEntity() {
        return this.selectedEntity ? transformAssemblyTemplateResponseWithChildrens([this.selectedEntity])[0] : null;
    }
    get transformedPartReferences() {
        if (!this.selectedEntity) {
            return null;
        }
        const parts = this.transformedSelectedEntity?.parts || [];
        return transformPartReferenceResponses(parts.map((assemblyTemplatePart) => assemblyTemplatePart.partReference));
    }
    async onClickOfInstall() {
        if (!this.selectedInventories.length) {
            return;
        }
        try {
            const variables = {
                childInventoryId: this.selectedInventories[0].id,
                comment: `Installed via template actualization of ${this.transformedSelectedEntity?._name}`,
                inventoryId: this.parentInventoryId,
                openedDate: this.$dateDisplay(new Date()),
                subType: ChangeSetType.ITEM_INSTANCE_INVENTORY_ASSEMBLY_INSTALL,
                payload: {
                    assemblyTemplateId: this.assemblyTemplateId,
                    installedOn: this.selectedInventories[0].side,
                    quantity: this.selectedInventories[0].quantity,
                    sequence: this.transformedSelectedEntity?.sequence
                }
            };
            const resp = await this.$apollo.mutate({
                mutation: gql `
          mutation ModalAssemblyTemplateActualization(
            $comment: String
            $childInventoryId: UUID!
            $inventoryId: UUID!
            $subType: String!
            $payload: JSON
            $openedDate: Date!
          ) {
            createChangeSet(
              input: {
                comment: $comment
                childInventoryId: $childInventoryId # needs to be uuid
                inventoryId: $inventoryId # needs to uuid
                subType: $subType
                payload: $payload
                openedDate: $openedDate
              }
            ) {
              clientMutationId
            }
          }
        `,
                variables
            });
            if (resp.data?.createChangeSet) {
                this.$notification.add({
                    type: AlertType.SUCCESS,
                    text: 'Install transaction created.'
                });
                this.$emit('actualized', this.selectedInventories[0]);
                this.closeDialog();
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchEntity() {
        if (!this.assemblyTemplateId) {
            return;
        }
        try {
            this.isLoading = true;
            const resp = await getAssemblyTemplate(this.assemblyTemplateId);
            this.selectedEntity = resp.data;
        }
        catch (err) {
            this.isLoading = false;
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchInventoryMatchingPartsData() {
        if (!this.transformedPartReferences) {
            return;
        }
        try {
            const params = {
                asBuiltNumber: this.transformedPartReferences.map((tpr) => tpr.asBuiltNumber) || [],
                class: this.transformedPartReferences.flatMap((tpr) => tpr.itemClass) || [],
                drawingNumber: this.transformedPartReferences.map((tpr) => tpr.drawingNumber) || [],
                lotNumber: this.transformedPartReferences.map((tpr) => tpr.lotNumber) || [],
                serialNumber: this.transformedPartReferences.map((tpr) => tpr.serialNumber) || [],
                side: this.transformedPartReferences.map((tpr) => tpr.side) || [],
                status: InventoryStatus.IN_INVENTORY,
                isInstalled: false,
                autoHardwareListId: this.selectedEntity?.autoHardwareListId
                    ? [this.selectedEntity.autoHardwareListId]
                    : undefined,
                take: -1
            };
            const resp = await this.$http.get(`/hardware/inventory`, {
                params
            });
            this.matchingInventory = resp.data.results || [];
            this.isLoading = false;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
};
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", Object)
], ModalAssemblyTemplateActualization.prototype, "assemblyTemplateId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], ModalAssemblyTemplateActualization.prototype, "parentInventoryId", void 0);
__decorate([
    DebounceAll(100),
    Watch('assemblyTemplateId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalAssemblyTemplateActualization.prototype, "fetchEntity", null);
__decorate([
    DebounceAll(100),
    Watch('transformedPartReferences'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalAssemblyTemplateActualization.prototype, "fetchInventoryMatchingPartsData", null);
ModalAssemblyTemplateActualization = __decorate([
    Component
], ModalAssemblyTemplateActualization);
export default ModalAssemblyTemplateActualization;
