var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AlertType } from '~/nasa_ui/types';
let Alert = class Alert extends Vue {
    alert;
    dense;
    dismissible;
    text;
    type;
    shouldShowAlert = true;
    get hasAlertProp() {
        return this.alert !== null && this.alert !== undefined;
    }
    get computedAlertType() {
        const type = this.hasAlertProp ? this.alert.type : this.type;
        return type && Object.values(AlertType).includes(type) ? type : undefined;
    }
    get computedAlertText() {
        const text = this.hasAlertProp ? this.alert.text : this.text;
        return text;
    }
    get computedClasses() {
        const classes = [];
        switch (this.computedAlertType) {
            case AlertType.ERROR:
                classes.push('error');
                break;
            case AlertType.INFO:
                classes.push('info');
                break;
            case AlertType.SUCCESS:
                classes.push('success');
                break;
            case AlertType.WARNING:
                classes.push('warning');
                break;
            default:
                classes.push('info');
        }
        if (this.dense) {
            classes.push('dense');
        }
        return classes.join(' ');
    }
    get computedIcon() {
        switch (this.computedAlertType) {
            case AlertType.ERROR:
                return 'fad fa-exclamation';
            case AlertType.INFO:
                return 'fad fa-info-circle';
            case AlertType.SUCCESS:
                return 'fad fa-check-circle';
            case AlertType.WARNING:
                return 'fad fa-exclamation-triangle';
            default:
                return 'fad fa-info-circle';
        }
    }
    get computedIconColor() {
        switch (this.computedAlertType) {
            case AlertType.ERROR:
                return `var(--v-${AlertType.ERROR}-base)`;
            case AlertType.INFO:
                return `var(--v-${AlertType.INFO}-base)`;
            case AlertType.SUCCESS:
                return `var(--v-${AlertType.SUCCESS}-base)`;
            case AlertType.WARNING:
                return `var(--v-${AlertType.WARNING}-base)`;
            default:
                return `var(--v-${AlertType.INFO}-base)`;
        }
    }
    dismissAlert() {
        this.shouldShowAlert = false;
        this.$emit('input');
    }
};
__decorate([
    Prop({
        type: [Object]
    }),
    __metadata("design:type", typeof (_a = typeof Partial !== "undefined" && Partial) === "function" ? _a : Object)
], Alert.prototype, "alert", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], Alert.prototype, "dense", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", typeof (_b = typeof Boolean !== "undefined" && Boolean) === "function" ? _b : Object)
], Alert.prototype, "dismissible", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], Alert.prototype, "text", void 0);
__decorate([
    Prop({
        type: [String, String]
    }),
    __metadata("design:type", typeof (_c = typeof AlertType !== "undefined" && AlertType) === "function" ? _c : Object)
], Alert.prototype, "type", void 0);
Alert = __decorate([
    Component
], Alert);
export default Alert;
