import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins } from 'vue-property-decorator';
import { CosmicUserFragment } from '~/nasa_ui/DAL/user';
import LoaderMixin from '~/nasa_ui/mixins/LoaderMixin';
import { mutationFactory, queryFactory } from '../base/mixinFactories';
export const CosmicApprovalRoutingTemplateFragment = gql `
  ${CosmicUserFragment}

  fragment CosmicApprovalRoutingTemplate on ApprovalRoutingTemplate {
    createdByUserId
    createdDateTime
    description
    groupCodes
    id
    name
    nodeId
    createdByUser {
      ...CosmicUser
    }
  }
`;
export const GetApprovalRoutingTemplateByNodeId = gql `
  ${CosmicApprovalRoutingTemplateFragment}

  query GetApprovalRoutingTemplate($nodeId: ID!) {
    approvalRoutingTemplate(nodeId: $nodeId) {
      ...CosmicApprovalRoutingTemplate
    }
  }
`;
export const GetAllApprovalRoutingTemplates = gql `
  ${CosmicApprovalRoutingTemplateFragment}

  query GetAllApprovalRoutingTemplates {
    approvalRoutingTemplates {
      nodes {
        ...CosmicApprovalRoutingTemplate
      }
    }
  }
`;
export const GetApprovalRoutingTemplateById = gql `
  ${CosmicApprovalRoutingTemplateFragment}

  query GetApprovalRoutingTemplateById($id: UUID!) {
    approvalRoutingTemplateById(id: $id) {
      ...CosmicApprovalRoutingTemplate
    }
  }
`;
export const SearchApprovalRoutingTemplates = gql `
  ${CosmicApprovalRoutingTemplateFragment}

  query SearchApprovalRoutingTemplates($query: String = "", $firstResults: Int) {
    approvalRoutingTemplates(first: $firstResults, filter: { name: { startsWithInsensitive: $query } }) {
      nodes {
        ...CosmicApprovalRoutingTemplate
      }
    }
  }
`;
export const CreateApprovalRoutingTemplate = gql `
  ${CosmicApprovalRoutingTemplateFragment}

  mutation CreateApprovalRoutingTemplate($input: CreateApprovalRoutingTemplateInput!) {
    createApprovalRoutingTemplate(input: $input) {
      approvalRoutingTemplate {
        ...CosmicApprovalRoutingTemplate
      }
    }
  }
`;
let ApprovalRoutingTemplateRepoMixin = class ApprovalRoutingTemplateRepoMixin extends Mixins(LoaderMixin) {
    // Queries
    getApprovalRoutingTemplateById = queryFactory(this, GetApprovalRoutingTemplateById);
    getApprovalRoutingTemplateByNodeId = queryFactory(this, GetApprovalRoutingTemplateByNodeId);
    getAllApprovalRoutingTemplates = queryFactory(this, GetAllApprovalRoutingTemplates);
    searchApprovalRoutingTemplates = queryFactory(this, SearchApprovalRoutingTemplates);
    // Mutations
    createApprovalRoutingTemplate = mutationFactory(this, CreateApprovalRoutingTemplate);
};
ApprovalRoutingTemplateRepoMixin = __decorate([
    Component
], ApprovalRoutingTemplateRepoMixin);
export { ApprovalRoutingTemplateRepoMixin };
