export const RevisionLetter = {
    A: 'A',
    'A-1': 'A-1',
    'A-10': 'A-10',
    'A-11': 'A-11',
    'A-12': 'A-12',
    'A-13': 'A-13',
    'A-14': 'A-14',
    'A-15': 'A-15',
    'A-19': 'A-19',
    'A-2': 'A-2',
    'A-20': 'A-20',
    'A-21': 'A-21',
    'A-22': 'A-22',
    'A-23': 'A-23',
    'A-24': 'A-24',
    'A-25': 'A-25',
    'A-26': 'A-26',
    'A-27': 'A-27',
    'A-28': 'A-28',
    'A-29': 'A-29',
    'A-3': 'A-3',
    'A-30': 'A-30',
    'A-31': 'A-31',
    'A-32': 'A-32',
    'A-4': 'A-4',
    'A-5': 'A-5',
    'A-6': 'A-6',
    'A-7': 'A-7',
    'A-8': 'A-8',
    'A-9': 'A-9',
    AA: 'AA',
    'AA-1': 'AA-1',
    'AA-2': 'AA-2',
    'AA-3': 'AA-3',
    AB: 'AB',
    'AB-1': 'AB-1',
    'AB-2': 'AB-2',
    AC: 'AC',
    'AC-1': 'AC-1',
    AD: 'AD',
    'AD-1': 'AD-1',
    'AD-2': 'AD-2',
    'AD-3': 'AD-3',
    AE: 'AE',
    'AE-1': 'AE-1',
    AF: 'AF',
    'AF-1': 'AF-1',
    AG: 'AG',
    'AG-1': 'AG-1',
    AH: 'AH',
    'AH-1': 'AH-1',
    'AH-2': 'AH-2',
    'AH-3': 'AH-3',
    'AH-4': 'AH-4',
    AJ: 'AJ',
    'AJ-1': 'AJ-1',
    'AJ-2': 'AJ-2',
    'AJ-3': 'AJ-3',
    'AJ-4': 'AJ-4',
    AK: 'AK',
    AL: 'AL',
    'AL-1': 'AL-1',
    AM: 'AM',
    AN: 'AN',
    AP: 'AP',
    'AP-1': 'AP-1',
    AR: 'AR',
    'AR-1': 'AR-1',
    AT: 'AT',
    AU: 'AU',
    AV: 'AV',
    AW: 'AW',
    AY: 'AY',
    B: 'B',
    'B-1': 'B-1',
    'B-10': 'B-10',
    'B-11': 'B-11',
    'B-12': 'B-12',
    'B-13': 'B-13',
    'B-2': 'B-2',
    'B-23': 'B-23',
    'B-24': 'B-24',
    'B-25': 'B-25',
    'B-26': 'B-26',
    'B-27': 'B-27',
    'B-28': 'B-28',
    'B-29': 'B-29',
    'B-3': 'B-3',
    'B-30': 'B-30',
    'B-31': 'B-31',
    'B-32': 'B-32',
    'B-33': 'B-33',
    'B-34': 'B-34',
    'B-35': 'B-35',
    'B-4': 'B-4',
    'B-5': 'B-5',
    'B-6': 'B-6',
    'B-7': 'B-7',
    'B-8': 'B-8',
    'B-9': 'B-9',
    BA: 'BA',
    'BA-1': 'BA-1',
    BB: 'BB',
    'BB-1': 'BB-1',
    BC: 'BC',
    BD: 'BD',
    BE: 'BE',
    'BE-2': 'BE-2',
    'BE-3': 'BE-3',
    'BE-4': 'BE-4',
    'BE-5': 'BE-5',
    'BE-6': 'BE-6',
    BF: 'BF',
    BG: 'BG',
    'BG-1': 'BG-1',
    'BG-2': 'BG-2',
    'BG-3': 'BG-3',
    'BG-4': 'BG-4',
    'BG-5': 'BG-5',
    BH: 'BH',
    'BH-1': 'BH-1',
    'BH-2': 'BH-2',
    'BH-3': 'BH-3',
    'BH-4': 'BH-4',
    'BH-5': 'BH-5',
    BJ: 'BJ',
    BK: 'BK',
    'BK-1': 'BK-1',
    'BK-2': 'BK-2',
    'BK-3': 'BK-3',
    'BK-4': 'BK-4',
    'BK-5': 'BK-5',
    'BK-6': 'BK-6',
    'BK-7': 'BK-7',
    'BK-8': 'BK-8',
    'BK-9': 'BK-9',
    BL: 'BL',
    'BL-1': 'BL-1',
    BM: 'BM',
    'BM-1': 'BM-1',
    'BM-2': 'BM-2',
    'BM-3': 'BM-3',
    'BM-4': 'BM-4',
    'BM-5': 'BM-5',
    BN: 'BN',
    BP: 'BP',
    'BP-1': 'BP-1',
    BR: 'BR',
    BT: 'BT',
    BU: 'BU',
    'BU-1': 'BU-1',
    BV: 'BV',
    'BV-1': 'BV-1',
    BW: 'BW',
    BX: 'BX',
    BY: 'BY',
    C: 'C',
    'C-1': 'C-1',
    'C-10': 'C-10',
    'C-11': 'C-11',
    'C-12': 'C-12',
    'C-13': 'C-13',
    'C-14': 'C-14',
    'C-15': 'C-15',
    'C-16': 'C-16',
    'C-17': 'C-17',
    'C-18': 'C-18',
    'C-19': 'C-19',
    'C-2': 'C-2',
    'C-20': 'C-20',
    'C-21': 'C-21',
    'C-22': 'C-22',
    'C-23': 'C-23',
    'C-24': 'C-24',
    'C-25': 'C-25',
    'C-3': 'C-3',
    'C-4': 'C-4',
    'C-5': 'C-5',
    'C-6': 'C-6',
    'C-7': 'C-7',
    'C-8': 'C-8',
    'C-9': 'C-9',
    CA: 'CA',
    CB: 'CB',
    'CB-1': 'CB-1',
    CC: 'CC',
    CD: 'CD',
    CE: 'CE',
    CF: 'CF',
    CG: 'CG',
    CH: 'CH',
    'CH 1': 'CH 1',
    'CH 10': 'CH 10',
    'CH 11': 'CH 11',
    'CH 12': 'CH 12',
    'CH 13': 'CH 13',
    'CH 14': 'CH 14',
    'CH 15': 'CH 15',
    'CH 16': 'CH 16',
    'CH 17': 'CH 17',
    'CH 18': 'CH 18',
    'CH 19': 'CH 19',
    'CH 2': 'CH 2',
    'CH 20': 'CH 20',
    'CH 3': 'CH 3',
    'CH 4': 'CH 4',
    'CH 5': 'CH 5',
    'CH 6': 'CH 6',
    'CH 7': 'CH 7',
    'CH 8': 'CH 8',
    'CH 9': 'CH 9',
    'CH. 1': 'CH. 1',
    'CH. 2': 'CH. 2',
    'CH. 3': 'CH. 3',
    'CH. 4': 'CH. 4',
    'CH. 5': 'CH. 5',
    'CH. 6': 'CH. 6',
    'CH. 7': 'CH. 7',
    'CH. 8': 'CH. 8',
    'CH. 9': 'CH. 9',
    'CH.1': 'CH.1',
    'CH.10': 'CH.10',
    'CH.11': 'CH.11',
    'CH.12': 'CH.12',
    'CH.13': 'CH.13',
    'CH.14': 'CH.14',
    'CH.15': 'CH.15',
    'CH.2': 'CH.2',
    'CH.3': 'CH.3',
    'CH.4': 'CH.4',
    'CH.5': 'CH.5',
    'CH.8': 'CH.8',
    'CHG.1': 'CHG.1',
    CJ: 'CJ',
    CK: 'CK',
    'CK-1': 'CK-1',
    CL: 'CL',
    CM: 'CM',
    CN: 'CN',
    CP: 'CP',
    CR: 'CR',
    CT: 'CT',
    CU: 'CU',
    CV: 'CV',
    CW: 'CW',
    CY: 'CY',
    D: 'D',
    'D-1': 'D-1',
    'D-10': 'D-10',
    'D-2': 'D-2',
    'D-3': 'D-3',
    'D-4': 'D-4',
    'D-5': 'D-5',
    'D-6': 'D-6',
    'D-7': 'D-7',
    'D-8': 'D-8',
    'D-9': 'D-9',
    DA: 'DA',
    DB: 'DB',
    'DB-1': 'DB-1',
    DC: 'DC',
    DD: 'DD',
    DE: 'DE',
    'DE-1': 'DE-1',
    DF: 'DF',
    DG: 'DG',
    DH: 'DH',
    DJ: 'DJ',
    DK: 'DK',
    'DK-1': 'DK-1',
    DL: 'DL',
    DM: 'DM',
    DN: 'DN',
    DP: 'DP',
    DR: 'DR',
    DT: 'DT',
    DU: 'DU',
    DV: 'DV',
    DW: 'DW',
    DY: 'DY',
    E: 'E',
    'E-1': 'E-1',
    'E-10': 'E-10',
    'E-11': 'E-11',
    'E-2': 'E-2',
    'E-3': 'E-3',
    'E-4': 'E-4',
    'E-5': 'E-5',
    'E-6': 'E-6',
    'E-7': 'E-7',
    'E-8': 'E-8',
    'E-9': 'E-9',
    EA: 'EA',
    EB: 'EB',
    EC: 'EC',
    ED: 'ED',
    EE: 'EE',
    EF: 'EF',
    EG: 'EG',
    EH: 'EH',
    EJ: 'EJ',
    EK: 'EK',
    EL: 'EL',
    EM: 'EM',
    EN: 'EN',
    EP: 'EP',
    EQ: 'EQ',
    ER: 'ER',
    ET: 'ET',
    EU: 'EU',
    EV: 'EV',
    EW: 'EW',
    EY: 'EY',
    F: 'F',
    'F-1': 'F-1',
    'F-2': 'F-2',
    'F-3': 'F-3',
    'F-4': 'F-4',
    'F-5': 'F-5',
    'F-6': 'F-6',
    'F-7': 'F-7',
    FA: 'FA',
    FB: 'FB',
    FC: 'FC',
    FD: 'FD',
    FE: 'FE',
    FF: 'FF',
    'FF-1': 'FF-1',
    FG: 'FG',
    FH: 'FH',
    FJ: 'FJ',
    FK: 'FK',
    FL: 'FL',
    FM: 'FM',
    FN: 'FN',
    FP: 'FP',
    FR: 'FR',
    FT: 'FT',
    FU: 'FU',
    FV: 'FV',
    FW: 'FW',
    FY: 'FY',
    G: 'G',
    'G-1': 'G-1',
    'G-10': 'G-10',
    'G-11': 'G-11',
    'G-2': 'G-2',
    'G-3': 'G-3',
    'G-4': 'G-4',
    'G-5': 'G-5',
    'G-6': 'G-6',
    'G-7': 'G-7',
    'G-8': 'G-8',
    'G-9': 'G-9',
    GA: 'GA',
    GB: 'GB',
    GC: 'GC',
    GD: 'GD',
    GE: 'GE',
    GF: 'GF',
    GG: 'GG',
    GH: 'GH',
    GJ: 'GJ',
    GK: 'GK',
    GL: 'GL',
    GM: 'GM',
    GN: 'GN',
    GP: 'GP',
    GR: 'GR',
    GT: 'GT',
    GU: 'GU',
    GV: 'GV',
    GW: 'GW',
    GY: 'GY',
    H: 'H',
    'H-1': 'H-1',
    'H-10': 'H-10',
    'H-11': 'H-11',
    'H-2': 'H-2',
    'H-3': 'H-3',
    'H-4': 'H-4',
    'H-5': 'H-5',
    'H-6': 'H-6',
    'H-7': 'H-7',
    'H-8': 'H-8',
    'H-9': 'H-9',
    I: 'I',
    II: 'II',
    J: 'J',
    'J-1': 'J-1',
    'J-10': 'J-10',
    'J-2': 'J-2',
    'J-3': 'J-3',
    'J-4': 'J-4',
    'J-5': 'J-5',
    'J-6': 'J-6',
    'J-7': 'J-7',
    'J-8': 'J-8',
    'J-9': 'J-9',
    K: 'K',
    'K-1': 'K-1',
    'K-10': 'K-10',
    'K-11': 'K-11',
    'K-12': 'K-12',
    'K-13': 'K-13',
    'K-14': 'K-14',
    'K-15': 'K-15',
    'K-16': 'K-16',
    'K-17': 'K-17',
    'K-18': 'K-18',
    'K-19': 'K-19',
    'K-2': 'K-2',
    'K-20': 'K-20',
    'K-21': 'K-21',
    'K-22': 'K-22',
    'K-23': 'K-23',
    'K-3': 'K-3',
    'K-4': 'K-4',
    'K-5': 'K-5',
    'K-6': 'K-6',
    'K-7': 'K-7',
    'K-8': 'K-8',
    'K-9': 'K-9',
    L: 'L',
    'L-1': 'L-1',
    'L-2': 'L-2',
    'L-3': 'L-3',
    'L-4': 'L-4',
    'L-5': 'L-5',
    M: 'M',
    'M-1': 'M-1',
    'M-10': 'M-10',
    'M-11': 'M-11',
    'M-12': 'M-12',
    'M-13': 'M-13',
    'M-14': 'M-14',
    'M-15': 'M-15',
    'M-16': 'M-16',
    'M-2': 'M-2',
    'M-3': 'M-3',
    'M-4': 'M-4',
    'M-5': 'M-5',
    'M-6': 'M-6',
    'M-7': 'M-7',
    'M-8': 'M-8',
    'M-9': 'M-9',
    N: 'N',
    'N-1': 'N-1',
    'N-2': 'N-2',
    'N/A': 'N/A',
    'N/C': 'N/C',
    'N/C-1': 'N/C-1',
    'N/C-2': 'N/C-2',
    'N/C1': 'N/C1',
    'NC-1': 'NC-1',
    NEW: 'NEW',
    P: 'P',
    'P-1': 'P-1',
    'P-2': 'P-2',
    Q: 'Q',
    R: 'R',
    'R-1': 'R-1',
    'R-2': 'R-2',
    'R-3': 'R-3',
    'R-4': 'R-4',
    S: 'S',
    T: 'T',
    'T-1': 'T-1',
    'T-2': 'T-2',
    'T-3': 'T-3',
    'TDC 1': 'TDC 1',
    'TDC 2': 'TDC 2',
    'TDC 3': 'TDC 3',
    'TDC 4': 'TDC 4',
    U: 'U',
    'U-1': 'U-1',
    'U/R': 'U/R',
    V: 'V',
    'V-1': 'V-1',
    'V-10': 'V-10',
    'V-11': 'V-11',
    'V-12': 'V-12',
    'V-13': 'V-13',
    'V-14': 'V-14',
    'V-15': 'V-15',
    'V-16': 'V-16',
    'V-17': 'V-17',
    'V-18': 'V-18',
    'V-19': 'V-19',
    'V-2': 'V-2',
    'V-20': 'V-20',
    'V-21': 'V-21',
    'V-22': 'V-22',
    'V-23': 'V-23',
    'V-24': 'V-24',
    'V-25': 'V-25',
    'V-3': 'V-3',
    'V-4': 'V-4',
    'V-5': 'V-5',
    'V-6': 'V-6',
    'V-7': 'V-7',
    'V-8': 'V-8',
    'V-9': 'V-9',
    W: 'W',
    'W-1': 'W-1',
    'W-10': 'W-10',
    'W-11': 'W-11',
    'W-12': 'W-12',
    'W-13': 'W-13',
    'W-14': 'W-14',
    'W-15': 'W-15',
    'W-16': 'W-16',
    'W-17': 'W-17',
    'W-18': 'W-18',
    'W-19': 'W-19',
    'W-2': 'W-2',
    'W-20': 'W-20',
    'W-21': 'W-21',
    'W-22': 'W-22',
    'W-23': 'W-23',
    'W-24': 'W-24',
    'W-25': 'W-25',
    'W-26': 'W-26',
    'W-27': 'W-27',
    'W-28': 'W-28',
    'W-29': 'W-29',
    'W-3': 'W-3',
    'W-30': 'W-30',
    'W-31': 'W-31',
    'W-32': 'W-32',
    'W-33': 'W-33',
    'W-34': 'W-34',
    'W-35': 'W-35',
    'W-36': 'W-36',
    'W-37': 'W-37',
    'W-38': 'W-38',
    'W-39': 'W-39',
    'W-4': 'W-4',
    'W-40': 'W-40',
    'W-41': 'W-41',
    'W-42': 'W-42',
    'W-43': 'W-43',
    'W-44': 'W-44',
    'W-45': 'W-45',
    'W-46': 'W-46',
    'W-47': 'W-47',
    'W-48': 'W-48',
    'W-49': 'W-49',
    'W-5': 'W-5',
    'W-50': 'W-50',
    'W-6': 'W-6',
    'W-7': 'W-7',
    'W-8': 'W-8',
    'W-9': 'W-9',
    'W/D': 'W/D',
    WW: 'WW',
    X: 'X',
    XX: 'XX',
    Y: 'Y',
    'Y-1': 'Y-1',
    'Y-2': 'Y-2',
    'Y-3': 'Y-3',
    Z: 'Z',
    'Z-1': 'Z-1'
};
