import { Vue } from 'vue-property-decorator';
import Log from '~/nasa_ui/modules/Log';
import { AlertType } from '~/nasa_ui/types';
import { ERROR_MESSAGE_MAPPER } from '../constants/index';
/**
 * @param  { IHandleErrorUtilityOptions } options
 * Takes in an error object, a vue instance, and optional override text, and optional args.
 *
 * ErrorUtility $logs the error object via telemetry and then notifies the user
 * an appropriate error message by parsing err.message in ERROR_MESSAGE_MAPPER.
 *
 * If backupErrorText is supplied, this text is displayed in the notification instead.
 *
 * Override text is a bridge for us to use until we can appropriately map and handle specific
 * GraphQL errors, only use if you absolutely need to.
 */
export function ErrorUtility(options) {
    const { err, backupErrorText, suppressNotification, ...args } = options;
    if (err instanceof Error) {
        // use nasa_ui's log by default
        options.log = options.log || Log;
        if (options.log) {
            const log = new options.log();
            log.error({ err });
        }
        if (suppressNotification) {
            return null;
        }
        // Might have an error message on the response object
        let computedErrorMessage = err.response?.data?.title;
        computedErrorMessage = computedErrorMessage || ERROR_MESSAGE_MAPPER(err.message, backupErrorText);
        const vueInstance = new Vue();
        vueInstance.$notification.add({
            text: computedErrorMessage,
            type: AlertType.ERROR
        });
    }
    return null;
}
