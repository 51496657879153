import { __decorate, __metadata } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import BaseButton from '~/nasa_ui/base/BaseButton';
let ButtonCreate = class ButtonCreate extends BaseButton {
    mini;
    get computedColor() {
        return this.color || '--v-success-base';
    }
    get computedIcon() {
        return this.$slots.icon ? null : this.icon || this.$icons.create;
    }
    get computedTextColor() {
        return this.textColor || '#fff';
    }
};
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], ButtonCreate.prototype, "mini", void 0);
ButtonCreate = __decorate([
    Component
], ButtonCreate);
export default ButtonCreate;
