export var Filter;
(function (Filter) {
    Filter["DRAWING_NUMBER"] = "drawingNumber";
    Filter["AS_BUILT_NUMBER"] = "asBuiltNumber";
    Filter["COMPUTED_STATUS"] = "computedStatus";
    Filter["CONTRACT_ENDING_ITEM_NUMBER"] = "contractEndingItemNumber";
    Filter["DESCRIPTION"] = "description";
    Filter["INITIALIZATION_STATUS"] = "initializationStatus";
    Filter["LOT_NUMBER"] = "lotNumber";
    Filter["OPS_NOM"] = "opsNom";
    Filter["SERIAL_NUMBER"] = "serialNumber";
    Filter["SIZE"] = "size";
    Filter["SPECIAL_HANDLING_TEXT"] = "specialHandlingText";
    Filter["IS_SPECIAL_HANDLING"] = "isSpecialHandling";
    Filter["IS_APPROVED_DATA_PACK"] = "isApprovedDataPack";
    Filter["IS_CLEANLINESS_TRACKING"] = "isCleanlinessTracking";
    Filter["IS_FRACTURE_CRITICAL"] = "isFractureCritical";
    Filter["IS_HAZARDOUS_MATERIAL"] = "isHazardousMaterial";
    Filter["IS_MANIFESTABLE"] = "isManifestable";
    Filter["IS_PHANTOM"] = "isPhantom";
    Filter["IS_TOOL"] = "isTool";
    Filter["IS_REFURBISHABLE"] = "isRefurbishable";
    Filter["ACTIVITY_SUB_TYPE"] = "activitySubType";
    Filter["START_DATETIME"] = "startDateTime";
    Filter["END_DATETIME"] = "endDateTime";
    Filter["CREATED_DATE"] = "createdDateTime";
    Filter["UPDATED_DATE"] = "updatedDateTime";
})(Filter || (Filter = {}));
export const FilterDisplay = new Map([
    [Filter.DRAWING_NUMBER, 'Drawing number'],
    [Filter.AS_BUILT_NUMBER, 'AsBuilt'],
    [Filter.COMPUTED_STATUS, 'Status'],
    [Filter.CONTRACT_ENDING_ITEM_NUMBER, 'Contract Ending Item number'],
    [Filter.DESCRIPTION, 'Description'],
    [Filter.INITIALIZATION_STATUS, 'Initialization status'],
    [Filter.LOT_NUMBER, 'Lot'],
    [Filter.OPS_NOM, 'Ops Nom'],
    [Filter.SPECIAL_HANDLING_TEXT, 'Special Handling Text'],
    [Filter.SERIAL_NUMBER, 'Serial'],
    [Filter.SIZE, 'Size'],
    [Filter.IS_SPECIAL_HANDLING, 'Special Handling'],
    [Filter.IS_APPROVED_DATA_PACK, 'Approved Data Pack'],
    [Filter.IS_CLEANLINESS_TRACKING, 'Cleanliness Tracking'],
    [Filter.IS_FRACTURE_CRITICAL, 'Fracture Critical'],
    [Filter.IS_HAZARDOUS_MATERIAL, 'Hazardous Material'],
    [Filter.IS_MANIFESTABLE, 'Manifestable'],
    [Filter.IS_PHANTOM, 'Phantom'],
    [Filter.IS_TOOL, 'Tool'],
    [Filter.IS_REFURBISHABLE, 'Refurbishable'],
    [Filter.ACTIVITY_SUB_TYPE, 'Type'],
    [Filter.START_DATETIME, 'Start date'],
    [Filter.END_DATETIME, 'End date'],
    [Filter.CREATED_DATE, 'Created date'],
    [Filter.UPDATED_DATE, 'Updated date']
]);
/* -- String filters ---------------------------------------- */
export var StringFilterOperation;
(function (StringFilterOperation) {
    StringFilterOperation["STARTS_WITH_INSENSITIVE"] = "startsWithInsensitive";
    StringFilterOperation["NOT_STARTS_WITH_INSENSITIVE"] = "notStartsWithInsensitive";
    StringFilterOperation["INCLUDES_INSENSITIVE"] = "includesInsensitive";
    StringFilterOperation["NOT_INCLUDES_INSENSITIVE"] = "notIncludesInsensitive";
    StringFilterOperation["ENDS_WITH_INSENSITIVE"] = "endsWithInsensitive";
    StringFilterOperation["NOT_ENDS_WITH_INSENSITIVE"] = "notEndsWithInsensitive";
    StringFilterOperation["LIKE_INSENSITIVE"] = "likeInsensitive";
    StringFilterOperation["IN"] = "in";
    StringFilterOperation["NOT_IN"] = "notIn";
})(StringFilterOperation || (StringFilterOperation = {}));
export const StringFilterOperationDisplay = new Map([
    [StringFilterOperation.IN, 'Include in set'],
    [StringFilterOperation.NOT_IN, 'Exclude from set'],
    [StringFilterOperation.INCLUDES_INSENSITIVE, 'Contains'],
    [StringFilterOperation.NOT_INCLUDES_INSENSITIVE, 'Does not contain'],
    [StringFilterOperation.STARTS_WITH_INSENSITIVE, 'Starts with'],
    [StringFilterOperation.NOT_STARTS_WITH_INSENSITIVE, 'Does not start with'],
    [StringFilterOperation.ENDS_WITH_INSENSITIVE, 'Ends with'],
    [StringFilterOperation.NOT_ENDS_WITH_INSENSITIVE, 'Does not end with'],
    [StringFilterOperation.LIKE_INSENSITIVE, 'Like expression']
]);
export const StringFilterOperationAcceptsArray = new Map([
    [StringFilterOperation.IN, true],
    [StringFilterOperation.NOT_IN, true],
    [StringFilterOperation.INCLUDES_INSENSITIVE, false],
    [StringFilterOperation.NOT_INCLUDES_INSENSITIVE, false],
    [StringFilterOperation.STARTS_WITH_INSENSITIVE, false],
    [StringFilterOperation.NOT_STARTS_WITH_INSENSITIVE, false],
    [StringFilterOperation.ENDS_WITH_INSENSITIVE, false],
    [StringFilterOperation.NOT_ENDS_WITH_INSENSITIVE, false],
    [StringFilterOperation.LIKE_INSENSITIVE, false]
]);
/* -- Boolean filters --------------------------------------- */
export var BooleanFilterOperation;
(function (BooleanFilterOperation) {
    BooleanFilterOperation["IS_EQUAL"] = "isEqual";
    BooleanFilterOperation["IS_NULL"] = "isNull";
})(BooleanFilterOperation || (BooleanFilterOperation = {}));
export var BooleanFilterOperationChoices;
(function (BooleanFilterOperationChoices) {
    BooleanFilterOperationChoices["IS_EQUAL"] = "IS_EQUAL";
    BooleanFilterOperationChoices["IS_NULL"] = "IS_NULL";
    BooleanFilterOperationChoices["IS_NOT_NULL"] = "IS_NOT_NULL";
})(BooleanFilterOperationChoices || (BooleanFilterOperationChoices = {}));
export const BooleanFilterOperationDisplay = new Map([
    [BooleanFilterOperationChoices.IS_EQUAL, 'Equals'],
    [BooleanFilterOperationChoices.IS_NULL, 'Null'],
    [BooleanFilterOperationChoices.IS_NOT_NULL, 'Not null']
]);
export const BooleanFilterOperationMap = new Map([
    [BooleanFilterOperationChoices.IS_EQUAL, BooleanFilterOperation.IS_EQUAL],
    [BooleanFilterOperationChoices.IS_NULL, BooleanFilterOperation.IS_NULL],
    [BooleanFilterOperationChoices.IS_NOT_NULL, BooleanFilterOperation.IS_NULL]
]);
/* -- Change set status filter ------------------------------ */
export var SelectFilterOperationChoices;
(function (SelectFilterOperationChoices) {
    SelectFilterOperationChoices["EQUAL_TO"] = "EQUAL_TO";
    SelectFilterOperationChoices["NOT_EQUAL_TO"] = "NOT_EQUAL_TO";
    SelectFilterOperationChoices["IS_NULL"] = "IS_NULL";
    SelectFilterOperationChoices["IS_NOT_NULL"] = "IS_NOT_NULL";
})(SelectFilterOperationChoices || (SelectFilterOperationChoices = {}));
export var SelectFilterOperation;
(function (SelectFilterOperation) {
    SelectFilterOperation["EQUAL_TO"] = "equalTo";
    SelectFilterOperation["NOT_EQUAL_TO"] = "notEqualTo";
    SelectFilterOperation["IS_NULL"] = "isNull";
})(SelectFilterOperation || (SelectFilterOperation = {}));
export const SelectFilterOperationDisplay = new Map([
    [SelectFilterOperationChoices.EQUAL_TO, 'Equal to'],
    [SelectFilterOperationChoices.NOT_EQUAL_TO, 'Not equal to'],
    [SelectFilterOperationChoices.IS_NULL, 'Is null'],
    [SelectFilterOperationChoices.IS_NOT_NULL, 'Is not null']
]);
export const SelectFilterOperationMap = new Map([
    [SelectFilterOperationChoices.EQUAL_TO, SelectFilterOperation.EQUAL_TO],
    [SelectFilterOperationChoices.NOT_EQUAL_TO, SelectFilterOperation.NOT_EQUAL_TO],
    [SelectFilterOperationChoices.IS_NULL, SelectFilterOperation.IS_NULL],
    [SelectFilterOperationChoices.IS_NOT_NULL, SelectFilterOperation.IS_NULL]
]);
export var ChangeSetStatusFilterValues;
(function (ChangeSetStatusFilterValues) {
    ChangeSetStatusFilterValues["APPROVED"] = "APPROVED";
    ChangeSetStatusFilterValues["OPEN"] = "OPEN";
    ChangeSetStatusFilterValues["REJECTED"] = "REJECTED";
})(ChangeSetStatusFilterValues || (ChangeSetStatusFilterValues = {}));
export const ChangeSetStatusFilterValuesDisplay = new Map([
    [ChangeSetStatusFilterValues.APPROVED, 'Approved'],
    [ChangeSetStatusFilterValues.OPEN, 'Open'],
    [ChangeSetStatusFilterValues.REJECTED, 'Rejected']
]);
export var ActivitySubTypeFilterValues;
(function (ActivitySubTypeFilterValues) {
    ActivitySubTypeFilterValues["AUTOCYCLE"] = "AUTOCYCLE";
    ActivitySubTypeFilterValues["BATTERY_MAINTENANCE"] = "BATTERY_MAINTENANCE";
    ActivitySubTypeFilterValues["CALIBRATION"] = "CALIBRATION";
    ActivitySubTypeFilterValues["CHARGE"] = "CHARGE";
    ActivitySubTypeFilterValues["CHECK_OUT"] = "CHECK_OUT";
    ActivitySubTypeFilterValues["CYCLE"] = "CYCLE";
    ActivitySubTypeFilterValues["DISCHARGE"] = "DISCHARGE";
    ActivitySubTypeFilterValues["DRY_OUT"] = "DRY_OUT";
    ActivitySubTypeFilterValues["DUMP"] = "DUMP";
    ActivitySubTypeFilterValues["EMU_H20_DUMP_AND_FILL"] = "EMU_H20_DUMP_AND_FILL";
    ActivitySubTypeFilterValues["EMU_H20_RECHARGE"] = "EMU_H20_RECHARGE";
    ActivitySubTypeFilterValues["EVA_ACTIVITY"] = "EVA_ACTIVITY";
    ActivitySubTypeFilterValues["FILL"] = "FILL";
    ActivitySubTypeFilterValues["GREASE_AND_CIRCULATE"] = "GREASE_AND_CIRCULATE";
    ActivitySubTypeFilterValues["INSPECTION"] = "INSPECTION";
    ActivitySubTypeFilterValues["IODINATE_LOOP_SCRUB"] = "IODINATE_LOOP_SCRUB";
    ActivitySubTypeFilterValues["ITEM_146_CYCLE"] = "ITEM_146_CYCLE";
    ActivitySubTypeFilterValues["MAINTENANCE"] = "MAINTENANCE";
    ActivitySubTypeFilterValues["O2_POPPET_VERIFICATION"] = "O2_POPPET_VERIFICATION";
    ActivitySubTypeFilterValues["OPERATION"] = "OPERATION";
    ActivitySubTypeFilterValues["OTHER"] = "OTHER";
    ActivitySubTypeFilterValues["PRESSURIZATION"] = "PRESSURIZATION";
    ActivitySubTypeFilterValues["REGENERATION"] = "REGENERATION";
    ActivitySubTypeFilterValues["RESIZE_OR_DRY_RUN"] = "RESIZE_OR_DRY_RUN";
    ActivitySubTypeFilterValues["TAK_DATA_COLLECTION"] = "TAK_DATA_COLLECTION";
    ActivitySubTypeFilterValues["TEN_POINT_TWO_OPS"] = "TEN_POINT_TWO_OPS";
    ActivitySubTypeFilterValues["TEST"] = "TEST";
    ActivitySubTypeFilterValues["TETHER_INSPECTION"] = "TETHER_INSPECTION";
    ActivitySubTypeFilterValues["WETTING_OR_IODINATING"] = "WETTING_OR_IODINATING";
    ActivitySubTypeFilterValues["WLVTA_REPLACEMENT"] = "WLVTA_REPLACEMENT";
    ActivitySubTypeFilterValues["VENT"] = "VENT";
})(ActivitySubTypeFilterValues || (ActivitySubTypeFilterValues = {}));
export const ActivitySubTypeFilterValuesDisplay = new Map([
    [ActivitySubTypeFilterValues.AUTOCYCLE, 'Autocycle'],
    [ActivitySubTypeFilterValues.BATTERY_MAINTENANCE, 'Battery maintenance'],
    [ActivitySubTypeFilterValues.CALIBRATION, 'Calibration'],
    [ActivitySubTypeFilterValues.CHARGE, 'Charge'],
    [ActivitySubTypeFilterValues.CHECK_OUT, 'Check out'],
    [ActivitySubTypeFilterValues.CYCLE, 'Cycle'],
    [ActivitySubTypeFilterValues.DISCHARGE, 'Discharge'],
    [ActivitySubTypeFilterValues.DRY_OUT, 'Dry out'],
    [ActivitySubTypeFilterValues.DUMP, 'Dump'],
    [ActivitySubTypeFilterValues.EMU_H20_DUMP_AND_FILL, 'EMU H20 dump and fill'],
    [ActivitySubTypeFilterValues.EMU_H20_RECHARGE, 'EMU H20 recharge'],
    [ActivitySubTypeFilterValues.EVA_ACTIVITY, 'EVA Activity'],
    [ActivitySubTypeFilterValues.FILL, 'Fill'],
    [ActivitySubTypeFilterValues.GREASE_AND_CIRCULATE, 'Grease and circulate'],
    [ActivitySubTypeFilterValues.INSPECTION, 'Inspection'],
    [ActivitySubTypeFilterValues.IODINATE_LOOP_SCRUB, 'Iodinate loop scrub'],
    [ActivitySubTypeFilterValues.ITEM_146_CYCLE, 'Item 146 cycle'],
    [ActivitySubTypeFilterValues.MAINTENANCE, 'Maintenance'],
    [ActivitySubTypeFilterValues.O2_POPPET_VERIFICATION, 'O2 poppet verification'],
    [ActivitySubTypeFilterValues.OPERATION, 'Operation'],
    [ActivitySubTypeFilterValues.OTHER, 'Other'],
    [ActivitySubTypeFilterValues.PRESSURIZATION, 'Pressurization'],
    [ActivitySubTypeFilterValues.REGENERATION, 'Regeneration'],
    [ActivitySubTypeFilterValues.RESIZE_OR_DRY_RUN, 'Resize or dry run'],
    [ActivitySubTypeFilterValues.TAK_DATA_COLLECTION, 'Tak data collection'],
    [ActivitySubTypeFilterValues.TEN_POINT_TWO_OPS, 'Ten point two ops'],
    [ActivitySubTypeFilterValues.TEST, 'Test'],
    [ActivitySubTypeFilterValues.TETHER_INSPECTION, 'Tether inspection'],
    [ActivitySubTypeFilterValues.WETTING_OR_IODINATING, 'Wetting or '],
    [ActivitySubTypeFilterValues.WLVTA_REPLACEMENT, 'WLVTA replacement'],
    [ActivitySubTypeFilterValues.VENT, 'Vent']
]);
/* -- Datetime filter --------------------------------------- */
export var DatetimeFilterOperation;
(function (DatetimeFilterOperation) {
    DatetimeFilterOperation["EQUAL_TO"] = "equalTo";
    DatetimeFilterOperation["NOT_EQUAL_TO"] = "notEqualTo";
    DatetimeFilterOperation["LESS_THAN"] = "lessThan";
    DatetimeFilterOperation["LESS_THAN_OR_EQUAL_TO"] = "lessThanOrEqualTo";
    DatetimeFilterOperation["GREATER_THAN"] = "greaterThan";
    DatetimeFilterOperation["GREATER_THAN_OR_EQUAL_TO"] = "greaterThanOrEqualTo";
})(DatetimeFilterOperation || (DatetimeFilterOperation = {}));
export const DatetimeFilterOperationDisplay = new Map([
    [DatetimeFilterOperation.EQUAL_TO, 'Equal to'],
    [DatetimeFilterOperation.NOT_EQUAL_TO, 'Not equal to'],
    [DatetimeFilterOperation.LESS_THAN, 'Less than'],
    [DatetimeFilterOperation.LESS_THAN_OR_EQUAL_TO, 'Less than or equal to'],
    [DatetimeFilterOperation.GREATER_THAN, 'Greater than'],
    [DatetimeFilterOperation.GREATER_THAN_OR_EQUAL_TO, 'Greater than or equal to']
]);
