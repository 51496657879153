var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.ASSEMBLY,
      "hide-title-section": "",
      "modal-width": "95vw"
    },
    on: {
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([_vm.selectedEntity ? {
      key: "content",
      fn: function fn() {
        return [_c('div', [_vm.parentAssemblyId ? _c('MiniAssembly', {
          staticClass: "parent_mini",
          attrs: {
            "id": _vm.parentAssemblyId,
            "show-children": false,
            "title": "Installed on (parent)"
          }
        }) : _c('Alert', {
          attrs: {
            "type": "info"
          }
        }, [_vm._v(" There is no parent. This "), _c('code', [_vm._v("Assembly Node")]), _vm._v(" is the highest node in this assembly. ")]), _c('hr', {
          staticClass: "my-3"
        }), _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "sm12": "",
            "md3": "",
            "pr-4": "",
            "mt-4": ""
          }
        }, [_c('AppNumber', {
          attrs: {
            "error-messages": _vm.sequenceNumberErrorMessage,
            "messages": _vm.sequenceNumberMessage,
            "autofocus": "",
            "clearable": Boolean(_vm.parentAssemblyId),
            "min": 0,
            "rules": [_vm.isSequenceZeroOrMore],
            "label": "Sequence",
            "required": !_vm.isSequenceValid,
            "disabled": _vm.shouldDisableSequenceField
          },
          model: {
            value: _vm.formData.sequence,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "sequence", $$v);
            },
            expression: "formData.sequence"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "sm12": "",
            "md3": "",
            "pr-4": "",
            "mt-4": ""
          }
        }, [_c('AppSelectEnum', {
          attrs: {
            "label": "Installed on",
            "enum": _vm.Side,
            "display": _vm.SideDisplay,
            "sort": false,
            "chips": false,
            "required": ""
          },
          model: {
            value: _vm.formData.installedOn,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "installedOn", $$v);
            },
            expression: "formData.installedOn"
          }
        })], 1)], 1), _vm.numberOfOpenParentChangeSets ? _c('v-data-iterator', {
          attrs: {
            "items": _vm.numberOfChangeSetsProp.openParentChangeSets,
            "content-tag": "v-layout",
            "row": "",
            "wrap": "",
            "hide-actions": ""
          },
          scopedSlots: _vm._u([{
            key: "item",
            fn: function fn(_ref) {
              var item = _ref.item;
              return [_c('v-flex', {
                attrs: {
                  "xs12": "",
                  "sm6": "",
                  "md4": "",
                  "lg3": "",
                  "px-1": ""
                }
              }, [_c('SummaryCardChangeSet', {
                attrs: {
                  "change-set-id": item._changeSetId
                }
              })], 1)];
            }
          }], null, false, 1811265741)
        }) : _vm._e(), _vm.numberOfOpenChildChangeSets ? _c('v-data-iterator', {
          attrs: {
            "items": _vm.numberOfChangeSetsProp.openChildChangeSets,
            "content-tag": "v-layout",
            "row": "",
            "wrap": "",
            "hide-actions": ""
          },
          scopedSlots: _vm._u([{
            key: "item",
            fn: function fn(_ref2) {
              var item = _ref2.item;
              return [_c('v-flex', {
                attrs: {
                  "xs12": "",
                  "sm6": "",
                  "md4": "",
                  "lg3": "",
                  "px-1": ""
                }
              }, [_c('SummaryCardChangeSet', {
                attrs: {
                  "change-set-id": item._changeSetId
                }
              })], 1)];
            }
          }], null, false, 1811265741)
        }) : _vm._e(), _vm.numberOfOpenParentChangeSets > 0 ? _c('v-layout', {
          staticClass: "pending-cs-warning",
          attrs: {
            "row": "",
            "no-wrap": "",
            "justify-end": ""
          }
        }, [_c('span', [_c('v-avatar', [_c('c-icon-fa', {
          attrs: {
            "icon": "fad fa-info-circle"
          }
        })], 1), _c('em', [_vm._v("Cannot request removal as there are pending change sets on the parent assembly.")])], 1)]) : _vm._e()], 1)];
      },
      proxy: true
    } : null, {
      key: "actions",
      fn: function fn() {
        return [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('ButtonSave', {
          staticClass: "mr-2",
          attrs: {
            "disabled": _vm.shouldDisableEditButton || _vm.isSaving,
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfEditAssembly
          }
        }), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        }), _c('v-spacer'), !_vm.showRemovalButton && _vm.hasParent ? _c('ButtonCreate', {
          attrs: {
            "color": _vm.EntityType.CHANGE_SET,
            "disabled": _vm.isSaving || _vm.numberOfOpenParentChangeSets > 0
          },
          on: {
            "click": _vm.onClickOfRequestRemoval
          },
          scopedSlots: _vm._u([{
            key: "icon",
            fn: function fn() {
              return [_c('c-icon-fa', {
                staticClass: "pr-2",
                attrs: {
                  "icon": _vm.$icons.uninstall
                }
              })];
            },
            proxy: true
          }], null, false, 2880497182)
        }, [_vm._v(" Request Removal ")]) : _vm._e()], 1)];
      },
      proxy: true
    }], null, true)
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };