import { LinkType } from '~/db_types';
export const LinksTypeDisplay = new Map([
    [LinkType.RIDS, 'RIDs'],
    [LinkType.DOCUMENT, 'Document'],
    [LinkType.EDCC, 'EDCC'],
    [LinkType.EDS, 'EDS'],
    [LinkType.OTHER, 'Other'],
    [LinkType.QARC, 'QARC'],
    [LinkType.COSMIC, 'System'] // idk what to call it generically other than System
]);
