var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Emit, Prop } from 'vue-property-decorator';
import { HardwareList } from '~/db_types';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { AlertType } from '~/nasa_ui/types';
let BaseHardwareListModal = class BaseHardwareListModal extends BaseModal {
    hardwareList;
    formData = {
        addendumNumber: null,
        addendumDate: null,
        authorizer: null,
        nodeId: null,
        name: null,
        number: null,
        revision: null,
        subType: null
    };
    get isFormValid() {
        return Boolean(this.formData.number && this.formData.subType);
    }
    /*
     * Stubs in case we need to have this functionality in the future
     */
    createEntity() { }
    async deleteEntity(hardwareListId) {
        this.isFetching = true;
        if (!hardwareListId) {
            return;
        }
        try {
            const resp = await this.$apollo.mutate({
                mutation: gql `
          mutation DeleteHardwareListById($hardwareListId: UUID!) {
            deleteHardwareListById(input: { id: $hardwareListId }) {
              clientMutationId
              deletedHardwareListId
            }
          }
        `,
                variables: {
                    hardwareListId
                }
            });
            if (resp.errors) {
                throw resp.errors;
            }
            this.emitModalClose();
            this.$router.push('/hardware/lists/search');
        }
        catch (err) {
            this.isFetching = false;
            this.$errorUtility({ err, backupErrorText: 'Could not delete HardwareList, please try again.' });
        }
        this.isFetching = false;
    }
    /*
     * HardwareList is passed by prop, no need to fetch
     */
    fetchEntity() { }
    async editEntity(data) {
        try {
            this.isSaving = true;
            const resp = await this.$apollo.mutate({
                mutation: gql `
          mutation editHardwareListForModal(
            $addendumNumber: Int
            $addendumDate: Date
            $authorizer: String
            $hardwareListNodeId: ID!
            $subType: HardwareListType
            $name: String
            $number: String
            $revision: String
          ) {
            updateHardwareList(
              input: {
                nodeId: $hardwareListNodeId
                patch: {
                  addendumNumber: $addendumNumber
                  addendumDate: $addendumDate
                  authorizer: $authorizer
                  subType: $subType
                  name: $name
                  number: $number
                  revision: $revision
                }
              }
            ) {
              hardwareListTemplate {
                nodeId
              }
            }
          }
        `,
                variables: {
                    addendumNumber: data.addendumNumber,
                    addendumDate: data.addendumDate,
                    authorizer: data.authorizer,
                    hardwareListNodeId: data.nodeId,
                    subType: data.subType,
                    name: data.name,
                    number: data.number,
                    revision: data.revision
                }
            });
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: `${data.name || 'Hardware List Template'} edited.`
            });
            this.isSaving = false;
            this.refreshAndClose();
        }
        catch (err) {
            this.isSaving = false;
            this.$errorUtility({
                err,
                backupErrorText: 'Could not edit the hardware list'
            });
        }
    }
    refreshAndClose() {
        this.$message(`reload_hlm`);
        this.$message('reload_mini');
        this.emitModalClose();
    }
    emitModalClose() { }
};
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof HardwareList !== "undefined" && HardwareList) === "function" ? _a : Object)
], BaseHardwareListModal.prototype, "hardwareList", void 0);
__decorate([
    Emit('modal-close'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseHardwareListModal.prototype, "emitModalClose", null);
BaseHardwareListModal = __decorate([
    Component
], BaseHardwareListModal);
export default BaseHardwareListModal;
