import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { UserSupportContextToValidEntityTypes } from '~/application.config';
import BaseMini from '~/nasa_ui/base/BaseMini';
import { EntityType } from '~/nasa_ui/types';
let BaseTreeMini = class BaseTreeMini extends Mixins(BaseMini) {
    ASSEMBLY_TEMPLATE_MANAGE_VIEW = 'AssemblyTemplateManageView';
    ASSEMBLY_MANAGE_VIEW = 'AssemblyManageView';
    IDENTURED_PARTS_LIST_VIEW = 'IndenturedPartsListView';
    WORKING_ASSEMBLIES = 'working_assemblies';
    localShowChildren = false;
    highlightId;
    highlightConnection;
    showChildren;
    showChildrenInitially;
    showMinMax;
    get chevronIcon() {
        return this.shouldShowChildContainer ? this.$icons.chevron_up : this.$icons.chevron_down;
    }
    get isRoot() {
        return !this.localEntity?.parentId && !this.localEntity?.parent?.id;
    }
    get isAManageView() {
        return this.$route.name === this.ASSEMBLY_TEMPLATE_MANAGE_VIEW || this.$route.name === this.ASSEMBLY_MANAGE_VIEW;
    }
    get isIplView() {
        return this.$route.name === this.IDENTURED_PARTS_LIST_VIEW;
    }
    get myChildren() {
        return []; // implement in consuming mini
    }
    get shouldHighlightNode() {
        return this.localEntity?.id === this.highlightId;
    }
    get shouldShowChildContainer() {
        return this.showChildren && this.myChildren.length > 0 && this.localShowChildren;
    }
    get shouldShowTrackingDocuments() {
        return this.currentUserActiveSupportContext
            ? (UserSupportContextToValidEntityTypes.get(this.currentUserActiveSupportContext) || []).includes(EntityType.DOCUMENT)
            : false;
    }
    // Called from ui
    onClickOfShowChildren() {
        this.localShowChildren = !this.localShowChildren;
    }
    onShowChildrenInitiallyChange(val) {
        this.localShowChildren = val;
    }
};
__decorate([
    Prop({
        type: String || String,
        default: ''
    }),
    __metadata("design:type", Object)
], BaseTreeMini.prototype, "highlightId", void 0);
__decorate([
    Prop({
        type: String,
        default: null
    }),
    __metadata("design:type", String)
], BaseTreeMini.prototype, "highlightConnection", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], BaseTreeMini.prototype, "showChildren", void 0);
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], BaseTreeMini.prototype, "showChildrenInitially", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], BaseTreeMini.prototype, "showMinMax", void 0);
__decorate([
    Watch('showChildrenInitially', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], BaseTreeMini.prototype, "onShowChildrenInitiallyChange", null);
BaseTreeMini = __decorate([
    Component
], BaseTreeMini);
export default BaseTreeMini;
