/**
 * Utilizes the CustomEvent web api standard as a message bus to pass messages around the micro frontend environment
 *
 * More information: https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent
 */
export default class EventBus {
    /**
     * The choice was made to nest the actual event payload under a consistent variable called `payload`
     * This allows you to use this method in the following manner:
     *  EventBus.sendMessage('user', { nodeId: '12', name: 'joe'})
     *  EventBus.sendMessage('sidebarIsOpen', true)
     *
     * This class wraps the payload under `payload` to maintain consistency between CustomEvents
     *
     * @param name name of the Event youd like to send
     * @param payload ANY value
     */
    static sendMessage(name, payload) {
        const event = new CustomEvent(name, { detail: { payload } });
        window.dispatchEvent(event);
        return event;
    }
    /**
     * Wrapper around the standard CustomEvent handler
     *
     * This already drills into the `payload` object
     *
     * @param name name of the Event you are listening for
     * @param callback function to be called when the Event is heard
     */
    static listen(name, callback) {
        const handler = (event) => {
            callback(event.detail.payload);
        };
        window.addEventListener(name, handler);
        return handler;
    }
    /**
     * @desc Removes an event listener.
     * @param name name of the Event you want to stop listening for
     * @param handler function that was passed to `window.addEventListener()`. It is
     * the return value of `EventBus.listen()`.
     */
    static stopListening(name, handler) {
        window.removeEventListener(name, handler);
    }
}
