var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { uniq } from 'lodash';
import uniqBy from 'lodash/uniqBy';
import Vue from 'vue';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { EntityType, Maybe } from '~/nasa_ui/types';
import { sortObjectBy } from '~/nasa_ui/utils';
let AppSelectProjectCode = class AppSelectProjectCode extends Vue {
    EntityType = EntityType;
    projectCodes = null;
    allProjectCodesQueryResult = [];
    isLoading = false;
    allowCreate;
    disabled;
    multiple;
    value;
    get projectCodeSelectOptions() {
        if (!this.allProjectCodesQueryResult?.length) {
            return [];
        }
        return uniqBy(this.allProjectCodesQueryResult
            .map((projectCode) => {
            return {
                displayText: projectCode?.trim(),
                value: projectCode?.trim()
            };
        })
            .sort(sortObjectBy('displayText')), 'value');
    }
    emitInput(val) {
        if (val === '') {
            return null;
        }
        // If multiple and its not an array and its not null|undefined return the val as an array
        if (this.multiple && !Array.isArray(val) && val !== null && val !== undefined) {
            return [val];
        }
        return val;
    }
    syncProjectCodes(val) {
        this.projectCodes = val;
    }
    created() {
        this.fetchAllProjectCodes();
    }
    addNewProjectCode(val) {
        this.allProjectCodesQueryResult?.push(val.toUpperCase());
    }
    async fetchAllProjectCodes() {
        try {
            this.isLoading = true;
            const resp = await this.fetchAllProjectCodesQuery();
            const projectCodes = resp.data.searchProjectCodes || [];
            if (projectCodes.length) {
                const existingOptions = this.allProjectCodesQueryResult ? [...this.allProjectCodesQueryResult] : [];
                this.allProjectCodesQueryResult = uniq([...existingOptions, ...projectCodes]);
                this.isLoading = false;
            }
            else {
                // No project codes found
                this.isLoading = false;
            }
        }
        catch (err) {
            this.isLoading = false;
            this.$errorUtility({
                err,
                backupErrorText: 'Failed to fetch all project codes.'
            });
        }
    }
    fetchAllProjectCodesQuery() {
        return this.$apollo.query({
            query: gql `
        query GetAllProjectCodes {
          searchProjectCodes
        }
      `
        });
    }
};
__decorate([
    Prop({ default: true, type: Boolean }),
    __metadata("design:type", Boolean)
], AppSelectProjectCode.prototype, "allowCreate", void 0);
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], AppSelectProjectCode.prototype, "disabled", void 0);
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], AppSelectProjectCode.prototype, "multiple", void 0);
__decorate([
    Prop({ default: '' }),
    __metadata("design:type", Object)
], AppSelectProjectCode.prototype, "value", void 0);
__decorate([
    Watch('projectCodes', { immediate: true }),
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AppSelectProjectCode.prototype, "emitInput", null);
__decorate([
    Watch('value', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], AppSelectProjectCode.prototype, "syncProjectCodes", null);
AppSelectProjectCode = __decorate([
    Component
], AppSelectProjectCode);
export default AppSelectProjectCode;
