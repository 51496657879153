var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST,
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.emitModalClose,
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.emitModalClose.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('ButtonSave', {
          attrs: {
            "disabled": _vm.shouldDisableSaveButton,
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfSaveHardwareList
          }
        }), _c('v-spacer'), true ? _c('ButtonRemove', {
          on: {
            "click": _vm.onClickOfDeleteHardwareList
          }
        }, [_vm._v("Delete Hardware List")]) : _vm._e()];
      },
      proxy: true
    }])
  }, [_c('template', {
    slot: "content"
  }, [_c('Alert', {
    staticClass: "shake",
    class: {
      on: _vm.isDuplicate,
      off: !_vm.isDuplicate
    },
    attrs: {
      "alert": _vm.duplicateAlert
    }
  }), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs4": "",
      "pr-2": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": "List name",
      "c-value": _vm.formData.name,
      "clearable": ""
    },
    on: {
      "c-input": function cInput($event) {
        _vm.formData.name = $event.detail;
      }
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs4": "",
      "pr-2": ""
    }
  }, [_c('AppSelectEnum', {
    attrs: {
      "chips": false,
      "display": _vm.HardwareListTypeDisplay,
      "enum": _vm.HardwareListType,
      "label": "List type",
      "required": ""
    },
    model: {
      value: _vm.formData.subType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "subType", $$v);
      },
      expression: "formData.subType"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs4": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": "List number",
      "c-value": _vm.formData.number,
      "required": "",
      "clearable": ""
    },
    on: {
      "c-input": function cInput($event) {
        _vm.formData.number = $event.detail;
      }
    }
  })], 1)], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs3": "",
      "mt-4": "",
      "mr-3": ""
    }
  }, [_c('c-text', {
    attrs: {
      "clearable": "",
      "label": "Revision",
      "c-value": _vm.formData.revision
    },
    on: {
      "c-input": function cInput($event) {
        _vm.formData.revision = $event.detail;
      }
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs3": "",
      "mt-4": "",
      "mr-3": ""
    }
  }, [_c('AppNumber', {
    attrs: {
      "clearable": "",
      "label": "Addendum number"
    },
    model: {
      value: _vm.formData.addendumNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "addendumNumber", $$v);
      },
      expression: "formData.addendumNumber"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs3": "",
      "mr-5": ""
    }
  }, [_c('AppDate', {
    attrs: {
      "label": "Addendum"
    },
    model: {
      value: _vm.formData.addendumDate,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "addendumDate", $$v);
      },
      expression: "formData.addendumDate"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": "Authorizer",
      "c-value": _vm.formData.authorizer
    },
    on: {
      "c-input": function cInput($event) {
        _vm.formData.authorizer = $event.detail;
      }
    }
  })], 1)], 1)], 1)], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };