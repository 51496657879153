var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('ButtonMiniAction', {
    attrs: {
      "disabled": _vm.disabled,
      "icon": _vm.$icons.MANAGE_PAGE,
      "to": _vm.to,
      "tooltip": _vm.computedTooltip,
      "tooltip-position": _vm.tooltipPosition,
      "icon-color": _vm.computedIconColor
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };