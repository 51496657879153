import { ItemMasterType } from '~/db_types';
export const EquipmentGroupNumberOnlyDisplay = new Map([
    [ItemMasterType.NON_FCE, '02.00'],
    [ItemMasterType.EMU_LSS_FLIGHT_SSA_NBL, '02.01'],
    [ItemMasterType.ANCILLARY_EQUIPMENT, '02.02'],
    [ItemMasterType.OBS, '02.03'],
    [ItemMasterType.FOOD_AND_MEDICAL_SYSTEMS, '02.04'],
    [ItemMasterType.EVA_TOOLS, '02.05'],
    [ItemMasterType.EVA_SUPPORT_EQUIPMENT, '02.06'],
    [ItemMasterType.FILM, '02.07'],
    [ItemMasterType.BATTERY, '02.08'],
    [ItemMasterType.COMMUNICATIONS_EQUIPMENT, '02.09'],
    [ItemMasterType.PHOTOGRAPHIC_EQUIPMENT, '02.10'],
    [ItemMasterType.VEHICLE_ACCOMMODATIONS, '02.11'],
    [ItemMasterType.DISPLAY_AND_CONTROL_EQUIPMENT, '02.12'],
    [ItemMasterType.CREW_EQUIPMENT_STOWAGE_PROV, '02.13'],
    [ItemMasterType.MANNED_MANEUVERING_UNIT, '02.14'],
    [ItemMasterType.CREW_ESCAPE_SYSTEM, '02.15'],
    [ItemMasterType.GROUND_SUPPORT_EQUIPMENT, '02.16'],
    [ItemMasterType.OTHER_STS_SUPPORT_EQUIPMENT, '02.17'],
    [ItemMasterType.SAFER, '02.18'],
    [ItemMasterType.PGSC_PCS, '02.19'],
    [ItemMasterType.ISS_WORK_THROUGH_FCE, '02.23'],
    [ItemMasterType.ISS_M_AND_O_HARDWARE_PBA, '02.24'],
    [ItemMasterType.MULTIPLE_EQ_GROUPS_FLIGHT, '02.87'],
    [ItemMasterType.MULTIPLE_EQ_GROUPS_PSE, '02.89'],
    [ItemMasterType.PREPACK_BENCH_REVIEW, '02.98'],
    [ItemMasterType.DECALS_PLACARDS_TAGS_PATCHES, '02.99'],
    [ItemMasterType.OH_HOUSTON_OPS_OFFICE_SUPP, '03.00'],
    [ItemMasterType.OH_HOUSTON_OPS_REPROD_SUPP, '03.01'],
    [ItemMasterType.OH_HOUSTON_OPS_JANITOR_SUPP, '03.02'],
    [ItemMasterType.GENERAL_COMPUTER_SUPPLIES, '03.03'],
    [ItemMasterType.SPECIAL_ORDER, '04.00'],
    [ItemMasterType.FACILITIES_CONTRACT, '05.00'],
    [ItemMasterType.OH_SR_QA_ONLY, '06.00'],
    [ItemMasterType.CAPITAL_PROPERTY, '07.00'],
    [ItemMasterType.IDWA, '08.00'],
    [ItemMasterType.ISS_IDWA, '08.01'],
    [ItemMasterType.ISIL_IDWA, '08.02'],
    [ItemMasterType.SHARED_CONSUMABLES, '09.00'],
    [ItemMasterType.VEHICLE_ACCOMMODATIONS, '10.00'],
    [ItemMasterType.XEMU, '11.00'],
    [ItemMasterType.XINFO, '11.01'],
    [ItemMasterType.XPGS, '11.02'],
    [ItemMasterType.XPLSS, '11.03'],
    [ItemMasterType.XFSE, '11.04'],
    [ItemMasterType.CTSD, '12.00'],
    [ItemMasterType.ELECTRICAL, 'Electrical'],
    [ItemMasterType.MECHANICAL, 'Mechanical']
]);
export const EquipmentGroupDisplay = new Map([
    [ItemMasterType.NON_FCE, '02.00 - Non-FCE'],
    [ItemMasterType.EMU_LSS_FLIGHT_SSA_NBL, '02.01 - EMU-LSS/FLIGHT SSA, NBL'],
    [ItemMasterType.ANCILLARY_EQUIPMENT, '02.02 - Ancillary Equipment'],
    [ItemMasterType.OBS, '02.03 - OBS'],
    [ItemMasterType.FOOD_AND_MEDICAL_SYSTEMS, '02.04 - Food & Medical Systems'],
    [ItemMasterType.EVA_TOOLS, '02.05 - EVA Tools'],
    [ItemMasterType.EVA_SUPPORT_EQUIPMENT, '02.06 - EVA Support Equipment'],
    [ItemMasterType.FILM, '02.07 - Film'],
    [ItemMasterType.BATTERY, '02.08 - Battery'],
    [ItemMasterType.COMMUNICATIONS_EQUIPMENT, '02.09 - Communications Equipment'],
    [ItemMasterType.PHOTOGRAPHIC_EQUIPMENT, '02.10 - Photographic Equipment'],
    [ItemMasterType.VEHICLE_ACCOMMODATIONS, '02.11 - Vehicle Accommodations'],
    [ItemMasterType.DISPLAY_AND_CONTROL_EQUIPMENT, '02.12 - Display & Control Equipment'],
    [ItemMasterType.CREW_EQUIPMENT_STOWAGE_PROV, '02.13 - Crew Equipment / Stowage Prov.'],
    [ItemMasterType.MANNED_MANEUVERING_UNIT, '02.14 - Manned Maneuvering Unit'],
    [ItemMasterType.CREW_ESCAPE_SYSTEM, '02.15 - Crew Escape System'],
    [ItemMasterType.GROUND_SUPPORT_EQUIPMENT, '02.16 - Ground Support Equipment'],
    [ItemMasterType.OTHER_STS_SUPPORT_EQUIPMENT, '02.17 - Other STS Support Equipment'],
    [ItemMasterType.SAFER, '02.18 - SAFER'],
    [ItemMasterType.PGSC_PCS, '02.19 - PGSC/PCS'],
    [ItemMasterType.ISS_WORK_THROUGH_FCE, '02.23 - ISS Work Through FCE'],
    [ItemMasterType.ISS_M_AND_O_HARDWARE_PBA, '02.24 - ISS M&O HARDWARE-PBA'],
    [ItemMasterType.MULTIPLE_EQ_GROUPS_FLIGHT, '02.87 - Multiple EQ. Groups - Flight'],
    [ItemMasterType.MULTIPLE_EQ_GROUPS_PSE, '02.89 - Multiple EQ. Groups - PSE'],
    [ItemMasterType.PREPACK_BENCH_REVIEW, '02.98 - Prepack: Bench Review'],
    [ItemMasterType.DECALS_PLACARDS_TAGS_PATCHES, '02.99 - Decals/Placards/Tags/Patches'],
    [ItemMasterType.OH_HOUSTON_OPS_OFFICE_SUPP, '03.00 - O/H-Houston OPs, Office Supp.'],
    [ItemMasterType.OH_HOUSTON_OPS_REPROD_SUPP, '03.01 - O/H-Houston OPs, Reprod. Supp.'],
    [ItemMasterType.OH_HOUSTON_OPS_JANITOR_SUPP, '03.02 - O/H-Houston OPs, Janitor Supp.'],
    [ItemMasterType.GENERAL_COMPUTER_SUPPLIES, '03.03 - General Computer Supplies'],
    [ItemMasterType.SPECIAL_ORDER, '04.00 - Special order'],
    [ItemMasterType.FACILITIES_CONTRACT, '05.00 - Facilities contract'],
    [ItemMasterType.OH_SR_QA_ONLY, '06.00 - O/H-SR & QA Only'],
    [ItemMasterType.CAPITAL_PROPERTY, '07.00 - Capital/Property'],
    [ItemMasterType.IDWA, '08.00 - IDWA'],
    [ItemMasterType.ISS_IDWA, '08.01 - ISS IDWA'],
    [ItemMasterType.ISIL_IDWA, '08.02 - ISIL IDWA'],
    [ItemMasterType.SHARED_CONSUMABLES, '09.00 - Shared consumables'],
    [ItemMasterType.VEHICLE_ACCOMMODATIONS, '10.00 - Vehicle Accommodations'],
    [ItemMasterType.XEMU, '11.00 - xEMU'],
    [ItemMasterType.XINFO, `11.01 - xInfo`],
    [ItemMasterType.XPGS, '11.02 - xPGS'],
    [ItemMasterType.XPLSS, '11.03 - xPLSS'],
    [ItemMasterType.XFSE, '11.04 - xFSE'],
    [ItemMasterType.CTSD, '12.00 - CTSD'],
    [ItemMasterType.ELECTRICAL, 'Electrical'],
    [ItemMasterType.MECHANICAL, 'Mechanical']
]);
