export var DrCause;
(function (DrCause) {
    DrCause["CONTAMINATION_OR_FOD"] = "CONTAMINATION_OR_FOD";
    DrCause["DEFECT_ANALYSIS_NO_PERFORM"] = "DEFECT_ANALYSIS_NO_PERFORM";
    DrCause["DESIGN_DEFICIENTCY"] = "DESIGN_DEFICIENTCY";
    DrCause["ENGINEER_ERROR"] = "ENGINEER_ERROR";
    DrCause["ENVIRONMENTAL_DEGRADATION"] = "ENVIRONMENTAL_DEGRADATION";
    DrCause["EXCESSIVE_USE"] = "EXCESSIVE_USE";
    DrCause["EXPIRED_LIMITED_SHELF_LIFE"] = "EXPIRED_LIMITED_SHELF_LIFE";
    DrCause["FLIGHT_DAMAGE"] = "FLIGHT_DAMAGE";
    DrCause["HARDWARE_PROVIDER_RESPONSIBILITY"] = "HARDWARE_PROVIDER_RESPONSIBILITY";
    DrCause["LAUNCH_DAMAGE"] = "LAUNCH_DAMAGE";
    DrCause["MACHINE_TOOLING_MATERIAL_DEFECTIVE"] = "MACHINE_TOOLING_MATERIAL_DEFECTIVE";
    DrCause["NO_DISCREPANCY_EXPLAINED_CONDITION"] = "NO_DISCREPANCY_EXPLAINED_CONDITION";
    DrCause["OPERATIONAL_DEGRADATION"] = "OPERATIONAL_DEGRADATION";
    DrCause["OPERATOR_ERROR"] = "OPERATOR_ERROR";
    DrCause["PLANNING_ERROR"] = "PLANNING_ERROR";
    DrCause["PLANNING_SCHEDULING_ERROR"] = "PLANNING_SCHEDULING_ERROR";
    DrCause["PROCEDUREAL_DOCUMENTATION_ERROR"] = "PROCEDUREAL_DOCUMENTATION_ERROR";
    DrCause["PROCESSING_ERROR"] = "PROCESSING_ERROR";
    DrCause["RESULT_OF_OTHER_DEFECT"] = "RESULT_OF_OTHER_DEFECT";
    DrCause["SHIPPING_STORAGE_HANDLING_DAMAGE"] = "SHIPPING_STORAGE_HANDLING_DAMAGE";
    DrCause["SOFTWARE_ERROR"] = "SOFTWARE_ERROR";
    DrCause["UNAUTHORIZED_WORK"] = "UNAUTHORIZED_WORK";
    DrCause["UNEXPLAINED_ANOMOLY"] = "UNEXPLAINED_ANOMOLY";
    DrCause["VENDOR_RESPONSIBILITY"] = "VENDOR_RESPONSIBILITY";
})(DrCause || (DrCause = {}));
export const DrCauseDisplay = new Map([
    [DrCause.CONTAMINATION_OR_FOD, '1: Contamination or FOD'],
    [DrCause.DEFECT_ANALYSIS_NO_PERFORM, '56: Defect analysis no perform (INACTIVE AFTER 02-NOV-2004)'],
    [DrCause.DESIGN_DEFICIENTCY, '2: Design Deficientcy'],
    [DrCause.ENGINEER_ERROR, '53:  Engineer Error (DOC) (INACTIVE AFTER 02-NOV-2004)'],
    [DrCause.ENVIRONMENTAL_DEGRADATION, '3: Environmental degradation'],
    [DrCause.EXCESSIVE_USE, '54:  Excessive Use (INACTIVE AFTER 02-NOV-2004)'],
    [DrCause.EXPIRED_LIMITED_SHELF_LIFE, '4:  Expired/Limited shelf life'],
    [DrCause.FLIGHT_DAMAGE, '5:  Flight damage'],
    [DrCause.HARDWARE_PROVIDER_RESPONSIBILITY, '18:  Hardware provider responsibility'],
    [DrCause.LAUNCH_DAMAGE, '6:  Launch damage'],
    [DrCause.MACHINE_TOOLING_MATERIAL_DEFECTIVE, '7:  Machine/Tooling/Material defective'],
    [DrCause.NO_DISCREPANCY_EXPLAINED_CONDITION, '8:  No discrepancy/Explained condition'],
    [DrCause.OPERATIONAL_DEGRADATION, '9:  Operational degradation'],
    [DrCause.OPERATOR_ERROR, '51:  Operator Error (INACTIVE AFTER 02-NOV-2004)'],
    [DrCause.PLANNING_ERROR, '52:  Planning Error (INACTIVE AFTER 02-NOV-2004)'],
    [DrCause.PLANNING_SCHEDULING_ERROR, '10:  Planning/Scheduling error'],
    [DrCause.PROCEDUREAL_DOCUMENTATION_ERROR, '11:  Procedureal/Documentation error'],
    [DrCause.PROCESSING_ERROR, '12:  Processing error'],
    [DrCause.RESULT_OF_OTHER_DEFECT, '55:  Result of other defect'],
    [DrCause.SHIPPING_STORAGE_HANDLING_DAMAGE, '13:  Shipping/Storage/Handling damage'],
    [DrCause.SOFTWARE_ERROR, '14:  Software error'],
    [DrCause.UNAUTHORIZED_WORK, '15:  Unauthorized Work'],
    [DrCause.UNEXPLAINED_ANOMOLY, '16:  Unexplained anomoly'],
    [DrCause.VENDOR_RESPONSIBILITY, '17:  Vendor responsibility']
]);
export var DrDisposition;
(function (DrDisposition) {
    DrDisposition["CALIBRATION_PERFORMED"] = "CALIBRATION_PERFORMED";
    DrDisposition["CLASS_CHANGE_EXPENDABLE"] = "CLASS_CHANGE_EXPENDABLE";
    DrDisposition["CORRECTIVE_ACTION"] = "CORRECTIVE_ACTION";
    DrDisposition["DISCARD_EXPENDABLE"] = "DISCARD_EXPENDABLE";
    DrDisposition["DISCREPANT_PART_CLEANED_AND_RETURNED_TO_SERVICE"] = "DISCREPANT_PART_CLEANED_AND_RETURNED_TO_SERVICE";
    DrDisposition["ENGINEERING_CHANGE_PERFORMED"] = "ENGINEERING_CHANGE_PERFORMED";
    DrDisposition["EXPLAINED_CONDITION_NO_CORRECTIVE_ACTION_TAKEN"] = "EXPLAINED_CONDITION_NO_CORRECTIVE_ACTION_TAKEN";
    DrDisposition["EXTEND_SHELF_LIFE_DUE_DATE"] = "EXTEND_SHELF_LIFE_DUE_DATE";
    DrDisposition["HARDWARE_REPLACED_PROBLEM_RESOLVED_ON_NEW_DR"] = "HARDWARE_REPLACED_PROBLEM_RESOLVED_ON_NEW_DR";
    DrDisposition["INACTIVE_HARDWARE"] = "INACTIVE_HARDWARE";
    DrDisposition["MATERIAL_REVIEW_BOARD_CLASS_CHANGE"] = "MATERIAL_REVIEW_BOARD_CLASS_CHANGE";
    DrDisposition["MATERIAL_REVIEW_BOARD_REPAIR"] = "MATERIAL_REVIEW_BOARD_REPAIR";
    DrDisposition["MATERIAL_REVIEW_BOARD_SCRAP"] = "MATERIAL_REVIEW_BOARD_SCRAP";
    DrDisposition["MATERIAL_REVIEW_BOARD_USE_AS_IS"] = "MATERIAL_REVIEW_BOARD_USE_AS_IS";
    DrDisposition["MATERIAL_REVIEW_BOARD_WAIVER"] = "MATERIAL_REVIEW_BOARD_WAIVER";
    DrDisposition["REPAIR_PER_FINAL_DISPOSITION"] = "REPAIR_PER_FINAL_DISPOSITION";
    DrDisposition["REPAIR_PER_STANDARD_REPAIR_PROCEDURE"] = "REPAIR_PER_STANDARD_REPAIR_PROCEDURE";
    DrDisposition["REPLACE_RECOPY_OR_RELOAD_MEDIA"] = "REPLACE_RECOPY_OR_RELOAD_MEDIA";
    DrDisposition["REPLENISH_FLUID_GAS"] = "REPLENISH_FLUID_GAS";
    DrDisposition["RETEST_SATISFACTORY"] = "RETEST_SATISFACTORY";
    DrDisposition["REVISE_CORRECT_DOCUMENTATION"] = "REVISE_CORRECT_DOCUMENTATION";
    DrDisposition["REVISE_SOFTWARE_CODE_CONFIGURATION_OR_RECOMPILE"] = "REVISE_SOFTWARE_CODE_CONFIGURATION_OR_RECOMPILE";
    DrDisposition["REWORK_TO_DRAWING_REQUIREMENT"] = "REWORK_TO_DRAWING_REQUIREMENT";
    DrDisposition["REWORK_TO_DRAWING_SPECIFICATION"] = "REWORK_TO_DRAWING_SPECIFICATION";
    DrDisposition["SEND_TO_VENDOR"] = "SEND_TO_VENDOR";
    DrDisposition["SHIPPED_OPEN"] = "SHIPPED_OPEN";
})(DrDisposition || (DrDisposition = {}));
export const DrDispositionDisplay = new Map([
    [DrDisposition.CALIBRATION_PERFORMED, '3:  Calibration performed'],
    [DrDisposition.CLASS_CHANGE_EXPENDABLE, '13:  Class change - Expendable'],
    [DrDisposition.CORRECTIVE_ACTION, '71:  Corrective action (INACTIVE AFTER 2-NOV-04)'],
    [DrDisposition.DISCARD_EXPENDABLE, '14:  Discard - Expendable'],
    [DrDisposition.DISCREPANT_PART_CLEANED_AND_RETURNED_TO_SERVICE, '4:  Discrepant part cleaned and returned to service'],
    [DrDisposition.ENGINEERING_CHANGE_PERFORMED, '5:  Engineering change performed'],
    [DrDisposition.EXPLAINED_CONDITION_NO_CORRECTIVE_ACTION_TAKEN, '1:  Explained condition/No corrective action taken'],
    [DrDisposition.EXTEND_SHELF_LIFE_DUE_DATE, '62:  Extend shelf life/due date'],
    [DrDisposition.HARDWARE_REPLACED_PROBLEM_RESOLVED_ON_NEW_DR, '2:  Hardware replaced/Problem resolved on new DR'],
    [DrDisposition.INACTIVE_HARDWARE, '70:  Inactive hardware (INACTIVE AFTER 2-NOV-04)'],
    [DrDisposition.MATERIAL_REVIEW_BOARD_CLASS_CHANGE, '67:  MRB - Class change'],
    [DrDisposition.MATERIAL_REVIEW_BOARD_REPAIR, '61:  MRB - Repair'],
    [DrDisposition.MATERIAL_REVIEW_BOARD_SCRAP, '66:  MRB - Scrap'],
    [DrDisposition.MATERIAL_REVIEW_BOARD_USE_AS_IS, '65:  MRB - Use as is'],
    [DrDisposition.MATERIAL_REVIEW_BOARD_WAIVER, '68:  MRB - Waiver'],
    [DrDisposition.REPAIR_PER_FINAL_DISPOSITION, '6:  Repair per final disposition'],
    [DrDisposition.REPAIR_PER_STANDARD_REPAIR_PROCEDURE, '7:  Repair per standard repair procedure'],
    [DrDisposition.REPLACE_RECOPY_OR_RELOAD_MEDIA, '8:  Replace, recopy, or reload media'],
    [DrDisposition.REPLENISH_FLUID_GAS, '9:  Replenish fluid/gas'],
    [DrDisposition.RETEST_SATISFACTORY, '10:  Retest satisfactory'],
    [DrDisposition.REVISE_CORRECT_DOCUMENTATION, '63:  Revise/Correct documentation'],
    [DrDisposition.REVISE_SOFTWARE_CODE_CONFIGURATION_OR_RECOMPILE, '11:  Revise software code, configuration, or recompile'],
    [DrDisposition.REWORK_TO_DRAWING_REQUIREMENT, '72:  Rework to Drawing req. (INACTIVE AFTER 2-NOV-04)'],
    [DrDisposition.REWORK_TO_DRAWING_SPECIFICATION, '12:  Rework to Drawing/Specification'],
    [DrDisposition.SEND_TO_VENDOR, '64:  Send to vendor'],
    [DrDisposition.SHIPPED_OPEN, '69:  Shipped open (INACTIVE AFTER 2-NOV-04)']
]);
export var DrFault;
(function (DrFault) {
    DrFault["BROKEN_WIRE"] = "BROKEN_WIRE";
    DrFault["CALIBRATION"] = "CALIBRATION";
    DrFault["CHEMICAL_SEAM_SEPARATION"] = "CHEMICAL_SEAM_SEPARATION";
    DrFault["CLASSIFICATION_SUSPECT_UNKNOWN"] = "CLASSIFICATION_SUSPECT_UNKNOWN";
    DrFault["CONFIGURATION"] = "CONFIGURATION";
    DrFault["CONTAMINATION"] = "CONTAMINATION";
    DrFault["CONTAMINATION_CORRODE_RUST"] = "CONTAMINATION_CORRODE_RUST";
    DrFault["CONTAMINATION_OPERATOR_ERROR"] = "CONTAMINATION_OPERATOR_ERROR";
    DrFault["CREW_SQUAWK"] = "CREW_SQUAWK";
    DrFault["DAMAGED_DURING_SHIPMENT"] = "DAMAGED_DURING_SHIPMENT";
    DrFault["DEFECTIVE"] = "DEFECTIVE";
    DrFault["DELAMINATION"] = "DELAMINATION";
    DrFault["DIMENSIONS_UNSPECIFIED_INCORRECT"] = "DIMENSIONS_UNSPECIFIED_INCORRECT";
    DrFault["DISLODGED"] = "DISLODGED";
    DrFault["DOCUMENT_PROCEDURE_DRAWING_SKETCH"] = "DOCUMENT_PROCEDURE_DRAWING_SKETCH";
    DrFault["ELECTRICAL_CHARACTERISTICS"] = "ELECTRICAL_CHARACTERISTICS";
    DrFault["ELECTRICAL_SHORT"] = "ELECTRICAL_SHORT";
    DrFault["EQUIPMENT_ITEM_INSTANCE_DROPPED_OPERATOR_ERROR"] = "EQUIPMENT_ITEM_INSTANCE_DROPPED_OPERATOR_ERROR";
    DrFault["EQUIPMENT_FAILURE"] = "EQUIPMENT_FAILURE";
    DrFault["ERRATIC_OPERATION"] = "ERRATIC_OPERATION";
    DrFault["EXCESSIVE_LOADS_OVERLOAD"] = "EXCESSIVE_LOADS_OVERLOAD";
    DrFault["EXPIRES_PRIOR_TO_NEXT_REPACK"] = "EXPIRES_PRIOR_TO_NEXT_REPACK";
    DrFault["FAILED_BYPASS"] = "FAILED_BYPASS";
    DrFault["FAILED_SPEC"] = "FAILED_SPEC";
    DrFault["FAILED_STRENGTH_STRUCTURAL_TEST"] = "FAILED_STRENGTH_STRUCTURAL_TEST";
    DrFault["FAILED_TEST_FLOW_RATE_HIGH"] = "FAILED_TEST_FLOW_RATE_HIGH";
    DrFault["FAILED_TEST_FLOW_RATE_LOW"] = "FAILED_TEST_FLOW_RATE_LOW";
    DrFault["FAILED_TEST_LEAKAGE_HIGH"] = "FAILED_TEST_LEAKAGE_HIGH";
    DrFault["FAILED_TEST_LEAKAGE_LOW"] = "FAILED_TEST_LEAKAGE_LOW";
    DrFault["FAILED_TEST_PRESSURE_HIGH"] = "FAILED_TEST_PRESSURE_HIGH";
    DrFault["FAILED_TEST_PRESSURE_LOW"] = "FAILED_TEST_PRESSURE_LOW";
    DrFault["FAILED_TEST_VOLTAGE_POWER_HIGH"] = "FAILED_TEST_VOLTAGE_POWER_HIGH";
    DrFault["FAILED_TEST_VOLTAGE_POWER_LOW"] = "FAILED_TEST_VOLTAGE_POWER_LOW";
    DrFault["FAILED_TO_CLOSE_ENGAGE"] = "FAILED_TO_CLOSE_ENGAGE";
    DrFault["FAILED_TO_OPEN_RELEASE"] = "FAILED_TO_OPEN_RELEASE";
    DrFault["FAILED_TO_OPERATE"] = "FAILED_TO_OPERATE";
    DrFault["FAILED_TO_POWER_OFF"] = "FAILED_TO_POWER_OFF";
    DrFault["FAILED_TO_POWER_ON"] = "FAILED_TO_POWER_ON";
    DrFault["FASTENER_STRIPPED_HEADS_THREADS_STUCK"] = "FASTENER_STRIPPED_HEADS_THREADS_STUCK";
    DrFault["FIRED_OFF_CENTER"] = "FIRED_OFF_CENTER";
    DrFault["FOREIGN_MATTER"] = "FOREIGN_MATTER";
    DrFault["FOREIGN_OBJECTS_DEBRIS_FOUND_INTERNAL"] = "FOREIGN_OBJECTS_DEBRIS_FOUND_INTERNAL";
    DrFault["FRAYED_CABLE"] = "FRAYED_CABLE";
    DrFault["FUNCTION_RELIABILITY_SAFETY_UNKNOWN"] = "FUNCTION_RELIABILITY_SAFETY_UNKNOWN";
    DrFault["GSE_TEST_STAND_DISCREPANCY"] = "GSE_TEST_STAND_DISCREPANCY";
    DrFault["HARDWARE_BENT_DEFORMED_KINKED"] = "HARDWARE_BENT_DEFORMED_KINKED";
    DrFault["HARDWARE_BINDING"] = "HARDWARE_BINDING";
    DrFault["HARDWARE_CRACKED"] = "HARDWARE_CRACKED";
    DrFault["HARDWARE_CRACKED_COATING"] = "HARDWARE_CRACKED_COATING";
    DrFault["HARDWARE_CROSS_THREADED_STRIPPED"] = "HARDWARE_CROSS_THREADED_STRIPPED";
    DrFault["HARDWARE_DAMAGED_GENERIC"] = "HARDWARE_DAMAGED_GENERIC";
    DrFault["HARDWARE_FRACTURED_FASTENERS"] = "HARDWARE_FRACTURED_FASTENERS";
    DrFault["HARDWARE_GOUGED_NICKED_SCRATCHED"] = "HARDWARE_GOUGED_NICKED_SCRATCHED";
    DrFault["HARDWARE_PITTING_CORROSION"] = "HARDWARE_PITTING_CORROSION";
    DrFault["HARDWARE_SHARP_EDGES"] = "HARDWARE_SHARP_EDGES";
    DrFault["HEAT_SEAL_SEPARATION"] = "HEAT_SEAL_SEPARATION";
    DrFault["HOUSING_UNRAVELING"] = "HOUSING_UNRAVELING";
    DrFault["IDENTIFICATION"] = "IDENTIFICATION";
    DrFault["INADVERTENT_OPERATION"] = "INADVERTENT_OPERATION";
    DrFault["INCOMPLETE_CERT_ENVIRONMENTAL_TESTING_INCOMPLETE"] = "INCOMPLETE_CERT_ENVIRONMENTAL_TESTING_INCOMPLETE";
    DrFault["INCORRECT_OR_INCOMPLETE_ID_ON_DOCUMENTATION"] = "INCORRECT_OR_INCOMPLETE_ID_ON_DOCUMENTATION";
    DrFault["INCORRECT_OR_INCOMPLETE_ID_ON_ITEM"] = "INCORRECT_OR_INCOMPLETE_ID_ON_ITEM";
    DrFault["INSTALLATION_NOT_IAW_PROCEDURE"] = "INSTALLATION_NOT_IAW_PROCEDURE";
    DrFault["INSUFFICIENT_COOLING"] = "INSUFFICIENT_COOLING";
    DrFault["INSULATION_HEAT_SHRINK_TUBING_DEFECTIVE"] = "INSULATION_HEAT_SHRINK_TUBING_DEFECTIVE";
    DrFault["LABEL_PULLED_LOOSE_MISSING"] = "LABEL_PULLED_LOOSE_MISSING";
    DrFault["LACING_IS_UNLACED_FRAYED"] = "LACING_IS_UNLACED_FRAYED";
    DrFault["LEAK"] = "LEAK";
    DrFault["LOOSE"] = "LOOSE";
    DrFault["LOOSE_FASTENER"] = "LOOSE_FASTENER";
    DrFault["LOST"] = "LOST";
    DrFault["LOST_ELASTICITY"] = "LOST_ELASTICITY";
    DrFault["MECHANICAL_PHYSICAL_CHARACTERSTIC"] = "MECHANICAL_PHYSICAL_CHARACTERSTIC";
    DrFault["MISSING_DAMAGED_CONNECTOR_PIN"] = "MISSING_DAMAGED_CONNECTOR_PIN";
    DrFault["MISSING_INCOMPLETE_HDW"] = "MISSING_INCOMPLETE_HDW";
    DrFault["MISSING_INCORRECT_DOCUMENTATION"] = "MISSING_INCORRECT_DOCUMENTATION";
    DrFault["MISSING_INSULATION"] = "MISSING_INSULATION";
    DrFault["NO_CHUTE_CANOPY_DEPLOY_RELEASE"] = "NO_CHUTE_CANOPY_DEPLOY_RELEASE";
    DrFault["NO_COOLING"] = "NO_COOLING";
    DrFault["NO_DISCREPANCY_EXPLAINED_CONDITION"] = "NO_DISCREPANCY_EXPLAINED_CONDITION";
    DrFault["NO_FLUID_CIRCULATION"] = "NO_FLUID_CIRCULATION";
    DrFault["OUT_OF_CONFIGURATION_UNINTENTIONAL"] = "OUT_OF_CONFIGURATION_UNINTENTIONAL";
    DrFault["OVER_TORQUED"] = "OVER_TORQUED";
    DrFault["PACKAGEING_ANOMALY_MISHAP"] = "PACKAGEING_ANOMALY_MISHAP";
    DrFault["PLASTIC_BENT_DEFORMED_KINKED"] = "PLASTIC_BENT_DEFORMED_KINKED";
    DrFault["PLASTIC_BINDING"] = "PLASTIC_BINDING";
    DrFault["PLASTIC_BLEMISH"] = "PLASTIC_BLEMISH";
    DrFault["PLASTIC_CRACKED"] = "PLASTIC_CRACKED";
    DrFault["PLASTIC_CROSS_THREADED_STRIPPED"] = "PLASTIC_CROSS_THREADED_STRIPPED";
    DrFault["PLASTIC_CUT"] = "PLASTIC_CUT";
    DrFault["PLASTIC_DAMAGED_GENERIC"] = "PLASTIC_DAMAGED_GENERIC";
    DrFault["PLASTIC_NOT_ADJUSTABLE"] = "PLASTIC_NOT_ADJUSTABLE";
    DrFault["PLASTIC_PITTED"] = "PLASTIC_PITTED";
    DrFault["PLASTIC_SHARP_EDGES"] = "PLASTIC_SHARP_EDGES";
    DrFault["PLASTIC_WORN"] = "PLASTIC_WORN";
    DrFault["POOR_WORKMANSHIP"] = "POOR_WORKMANSHIP";
    DrFault["PREMATURE_ACTIVATION"] = "PREMATURE_ACTIVATION";
    DrFault["PRESSURE_CHARACTERISTICS_LKG"] = "PRESSURE_CHARACTERISTICS_LKG";
    DrFault["PROCESS_ANOMALY_MISHAP"] = "PROCESS_ANOMALY_MISHAP";
    DrFault["PROOF_LOAD_SUSPECT_UNKNOWN"] = "PROOF_LOAD_SUSPECT_UNKNOWN";
    DrFault["RETEST_ADJUST"] = "RETEST_ADJUST";
    DrFault["RETIRED"] = "RETIRED";
    DrFault["RUPTURED_LINE_CARRIER"] = "RUPTURED_LINE_CARRIER";
    DrFault["SERVICE_LIFE_EXPIRED"] = "SERVICE_LIFE_EXPIRED";
    DrFault["SHEATHING_DAMAGE"] = "SHEATHING_DAMAGE";
    DrFault["SHELF_LIFE_EXPIRED"] = "SHELF_LIFE_EXPIRED";
    DrFault["SHELF_LIFE_OPERATOR_TIME_LIMIT"] = "SHELF_LIFE_OPERATOR_TIME_LIMIT";
    DrFault["SOFTGOODS_ABRASION_NICK_SCRATCH"] = "SOFTGOODS_ABRASION_NICK_SCRATCH";
    DrFault["SOFTGOODS_BROKEN_STRANDS_THREADS_WEAVES"] = "SOFTGOODS_BROKEN_STRANDS_THREADS_WEAVES";
    DrFault["SOFTGOODS_DAMAGED_GENERIC"] = "SOFTGOODS_DAMAGED_GENERIC";
    DrFault["SOFTGOODS_FRAYED"] = "SOFTGOODS_FRAYED";
    DrFault["SOFTGOODS_HOLE"] = "SOFTGOODS_HOLE";
    DrFault["SOFTGOODS_NICK_CUT_TORN"] = "SOFTGOODS_NICK_CUT_TORN";
    DrFault["SOFTGOODS_PULLED"] = "SOFTGOODS_PULLED";
    DrFault["SOFTGOODS_STAINS"] = "SOFTGOODS_STAINS";
    DrFault["SOFTGOODS_VOID_MISSING_MATERIAL"] = "SOFTGOODS_VOID_MISSING_MATERIAL";
    DrFault["STITCHING_TACKING_THREADS_BROKEN_CUT_RUPTURED"] = "STITCHING_TACKING_THREADS_BROKEN_CUT_RUPTURED";
    DrFault["STITCHING_TACKING_THREADS_FRAYED"] = "STITCHING_TACKING_THREADS_FRAYED";
    DrFault["STITCHING_TACKING_THREADS_MISSING"] = "STITCHING_TACKING_THREADS_MISSING";
    DrFault["STITCHING_TACKING_THREADS_PULLED"] = "STITCHING_TACKING_THREADS_PULLED";
    DrFault["STRAIN_RELIEF_BROKEN_DAMAGED"] = "STRAIN_RELIEF_BROKEN_DAMAGED";
    DrFault["SUSPICIOUS_LOUD_NOISE_SQUEAL"] = "SUSPICIOUS_LOUD_NOISE_SQUEAL";
    DrFault["TAPE_RESIDUE"] = "TAPE_RESIDUE";
    DrFault["TEMPATURE_HIGH"] = "TEMPATURE_HIGH";
    DrFault["TEMPATURE_LOW"] = "TEMPATURE_LOW";
    DrFault["TEST_FAILED_INCOMPLETE"] = "TEST_FAILED_INCOMPLETE";
    DrFault["THERMAL_CHARACTERISTIC"] = "THERMAL_CHARACTERISTIC";
    DrFault["UNBONDING"] = "UNBONDING";
    DrFault["UNDER_TORQUED"] = "UNDER_TORQUED";
    DrFault["UNKNOWN_CLEANLINESS_LEVEL"] = "UNKNOWN_CLEANLINESS_LEVEL";
    DrFault["UNKNOWN_SUSPECT_CONDITION"] = "UNKNOWN_SUSPECT_CONDITION";
    DrFault["USAGE_LIFE_EXPIRED"] = "USAGE_LIFE_EXPIRED";
    DrFault["WELD_CRACKED_ETC"] = "WELD_CRACKED_ETC";
    DrFault["WIRED_IMPROPERLY"] = "WIRED_IMPROPERLY";
    DrFault["WONT_LOCK_IN_PLACE"] = "WONT_LOCK_IN_PLACE";
    DrFault["WORN_PROTECTIVE_COATING"] = "WORN_PROTECTIVE_COATING";
    DrFault["ZIPPER_STOP_MISSING_DAMAGED"] = "ZIPPER_STOP_MISSING_DAMAGED";
})(DrFault || (DrFault = {}));
export const DrFaultDisplay = new Map([
    [DrFault.BROKEN_WIRE, '80:  Broken wire'],
    [DrFault.CALIBRATION, '23:  Calibration'],
    [DrFault.CHEMICAL_SEAM_SEPARATION, '103:  Chemical seam separation'],
    [DrFault.CLASSIFICATION_SUSPECT_UNKNOWN, '123:  Classification suspect/unknown'],
    [DrFault.CONFIGURATION, '24:  Configuration (INACTIVE AFTER 02-NOV-2004)'],
    [DrFault.CONTAMINATION, '113:  Contamination'],
    [DrFault.CONTAMINATION_CORRODE_RUST, '25:  Contamination/Corrode/Rust'],
    [DrFault.CONTAMINATION_OPERATOR_ERROR, '10:  Contamination (Operator error)'],
    [DrFault.CREW_SQUAWK, '120:  Crew squawk'],
    [DrFault.DAMAGED_DURING_SHIPMENT, '136:  Damaged during shipment'],
    [DrFault.DEFECTIVE, '127:  Defective'],
    [DrFault.DELAMINATION, '73:  Delamination'],
    [DrFault.DIMENSIONS_UNSPECIFIED_INCORRECT, '124:  Dimensions unspecified/incorrect'],
    [DrFault.DISLODGED, '133:  Dislodged'],
    [DrFault.DOCUMENT_PROCEDURE_DRAWING_SKETCH, '27:  Document/Procedure/Drawing/Sketch (INACTIVE AFTER 02-NOV-2004)'],
    [DrFault.ELECTRICAL_CHARACTERISTICS, '29:  Electrical characteristics (INACTIVE AFTER 2-NOV-04)'],
    [DrFault.ELECTRICAL_SHORT, '79:  Electrical short'],
    [DrFault.EQUIPMENT_ITEM_INSTANCE_DROPPED_OPERATOR_ERROR, '1:  Equipment/component dropped (Operator error)'],
    [DrFault.EQUIPMENT_FAILURE, '118:  Equipment failure'],
    [DrFault.ERRATIC_OPERATION, '26:  Erratic operation (INACTIVE AFTER 02-NOV-2004)'],
    [DrFault.EXCESSIVE_LOADS_OVERLOAD, '121:  Excessive loads (i.e. Overload)'],
    [DrFault.EXPIRES_PRIOR_TO_NEXT_REPACK, '87:  Expires prior to next repack'],
    [DrFault.FAILED_BYPASS, '97:  Failed bypass'],
    [DrFault.FAILED_SPEC, '130:  Failed spec'],
    [DrFault.FAILED_STRENGTH_STRUCTURAL_TEST, '11:  Failed strength/structural test'],
    [DrFault.FAILED_TEST_FLOW_RATE_HIGH, '16:  Failed test - Flow rate high'],
    [DrFault.FAILED_TEST_FLOW_RATE_LOW, '15:  Failed test - Flow rate low'],
    [DrFault.FAILED_TEST_LEAKAGE_HIGH, '18:  Failed test - Leakage high'],
    [DrFault.FAILED_TEST_LEAKAGE_LOW, '17:  Failed test - Leakage low'],
    [DrFault.FAILED_TEST_PRESSURE_HIGH, '14:  Failed test - Pressure high'],
    [DrFault.FAILED_TEST_PRESSURE_LOW, '13:  Failed test - Pressure low'],
    [DrFault.FAILED_TEST_VOLTAGE_POWER_HIGH, '20:  Failed test - Voltage/Power high'],
    [DrFault.FAILED_TEST_VOLTAGE_POWER_LOW, '19:  Failed test - Voltage/Power low'],
    [DrFault.FAILED_TO_CLOSE_ENGAGE, '93:  Failed to close/engage'],
    [DrFault.FAILED_TO_OPEN_RELEASE, '94:  Failed to open/release'],
    [DrFault.FAILED_TO_OPERATE, '91:  Failed to operate'],
    [DrFault.FAILED_TO_POWER_OFF, '95:  Failed to power-off'],
    [DrFault.FAILED_TO_POWER_ON, '96:  Failed to power-on'],
    [DrFault.FASTENER_STRIPPED_HEADS_THREADS_STUCK, '44: Fastrener stripped heads/threads stuck'],
    [DrFault.FIRED_OFF_CENTER, '92:  Fired off center'],
    [DrFault.FOREIGN_MATTER, '115:  Foreign matter'],
    [DrFault.FOREIGN_OBJECTS_DEBRIS_FOUND_INTERNAL, '114:  Foreign objects/debris found internal'],
    [DrFault.FRAYED_CABLE, '47:  Frayed cable'],
    [DrFault.FUNCTION_RELIABILITY_SAFETY_UNKNOWN, '128:  Function, reliability or safety unknown'],
    [DrFault.GSE_TEST_STAND_DISCREPANCY, '135:  GSE/Test stand discrepancy'],
    [DrFault.HARDWARE_BENT_DEFORMED_KINKED, '33:  Hardware - Bent/Deformed/Kinked'],
    [DrFault.HARDWARE_BINDING, '34:  Hardware - Binding'],
    [DrFault.HARDWARE_CRACKED, '46:  Hardware cracked'],
    [DrFault.HARDWARE_CRACKED_COATING, '36:   Hardware - Cracked coating'],
    [DrFault.HARDWARE_CROSS_THREADED_STRIPPED, '37:  Hardware - Cross-threaded/stripped'],
    [DrFault.HARDWARE_DAMAGED_GENERIC, '40:  Hardware - Damaged (generic)'],
    [DrFault.HARDWARE_FRACTURED_FASTENERS, '41:  Hardware - Fractured fasteners'],
    [DrFault.HARDWARE_GOUGED_NICKED_SCRATCHED, '38:  Hardware - Gouged/nicked/scratched'],
    [DrFault.HARDWARE_PITTING_CORROSION, '35:  Hardware - Pitting/Corrosion'],
    [DrFault.HARDWARE_SHARP_EDGES, '39:  Hardware - Sharp edges'],
    [DrFault.HEAT_SEAL_SEPARATION, '102:  Heat seal separation'],
    [DrFault.HOUSING_UNRAVELING, '134:  Housing unraveling'],
    [DrFault.IDENTIFICATION, '28:  IDENTIFICATION (INACTIVE AFTER 02-NOV-2004)'],
    [DrFault.INADVERTENT_OPERATION, '6:  Inadvertent operation'],
    [DrFault.INCOMPLETE_CERT_ENVIRONMENTAL_TESTING_INCOMPLETE, '12:  Incomplete cert - Environmental testing incomplete'],
    [DrFault.INCORRECT_OR_INCOMPLETE_ID_ON_DOCUMENTATION, '5:  Incorrect or incomplete ID on documentation'],
    [DrFault.INCORRECT_OR_INCOMPLETE_ID_ON_ITEM, '4:  Incorrect or incomplete ID on item'],
    [DrFault.INSTALLATION_NOT_IAW_PROCEDURE, '2:  Installation not IAW procedure'],
    [DrFault.INSUFFICIENT_COOLING, '100:  Insufficient cooling'],
    [DrFault.INSULATION_HEAT_SHRINK_TUBING_DEFECTIVE, '82:  Insulation/Heat shrink tubing defective'],
    [DrFault.LABEL_PULLED_LOOSE_MISSING, '76:  Label pulled loose/missing'],
    [DrFault.LACING_IS_UNLACED_FRAYED, '72:  Lacing is unlaced/frayed'],
    [DrFault.LEAK, '104:  Leak'],
    [DrFault.LOOSE, '108:  Loose'],
    [DrFault.LOOSE_FASTENER, '45:  Loose Fastener'],
    [DrFault.LOST, '3:  Lost'],
    [DrFault.LOST_ELASTICITY, '77:  Lost elasticity'],
    [DrFault.MECHANICAL_PHYSICAL_CHARACTERSTIC, '30:  Mechanical/Physical characterstic (INACTIVE AFTER 02-NOV-2004)'],
    [DrFault.MISSING_DAMAGED_CONNECTOR_PIN, '83:  Missing/Damaged connector pin'],
    [DrFault.MISSING_INCOMPLETE_HDW, '21:  Missing/incomplete HDW (INACTIVE AFTER 02-NOV-2004)'],
    [DrFault.MISSING_INCORRECT_DOCUMENTATION, '129:  Missing/Incorrect documentation'],
    [DrFault.MISSING_INSULATION, '81:  Missing insulation'],
    [DrFault.NO_CHUTE_CANOPY_DEPLOY_RELEASE, '88:  No chute/canopy deploy/release'],
    [DrFault.NO_COOLING, '99:  No cooling'],
    [DrFault.NO_DISCREPANCY_EXPLAINED_CONDITION, '126:  No discrepancy/Explained condition'],
    [DrFault.NO_FLUID_CIRCULATION, '98:  No fluid circulation'],
    [DrFault.OUT_OF_CONFIGURATION_UNINTENTIONAL, '132:  Out of configuration - Unintentional'],
    [DrFault.OVER_TORQUED, '7:  Over-torqued'],
    [DrFault.PACKAGEING_ANOMALY_MISHAP, '117:  Packaging anomaly/mishap'],
    [DrFault.PLASTIC_BENT_DEFORMED_KINKED, '52:  Plastic - Bent/Deformed/Kinked'],
    [DrFault.PLASTIC_BINDING, '48:  Plastic - Binding'],
    [DrFault.PLASTIC_BLEMISH, '56:  Plastic - Blemish'],
    [DrFault.PLASTIC_CRACKED, '49:  Plastic - Cracked'],
    [DrFault.PLASTIC_CROSS_THREADED_STRIPPED, '50:  Plastic - Cross-threaded/stripped'],
    [DrFault.PLASTIC_CUT, '51:  Plastic - Cut'],
    [DrFault.PLASTIC_DAMAGED_GENERIC, '55:  Plastic - Damaged (Generic)'],
    [DrFault.PLASTIC_NOT_ADJUSTABLE, '58:  Plastic - Not adjustable'],
    [DrFault.PLASTIC_PITTED, '53:  Plastic - Pitted'],
    [DrFault.PLASTIC_SHARP_EDGES, '54:  Plastic - Sharp Edges'],
    [DrFault.PLASTIC_WORN, '57:  Plastic - Worn'],
    [DrFault.POOR_WORKMANSHIP, '9:  Poor Workmanship'],
    [DrFault.PREMATURE_ACTIVATION, '89:  Premature activation'],
    [DrFault.PRESSURE_CHARACTERISTICS_LKG, '31:  Pressr characteristics LKG (INACTIVE AFTER 2-NOV-04)'],
    [DrFault.PROCESS_ANOMALY_MISHAP, '116:  Process anomaly/mishap'],
    [DrFault.PROOF_LOAD_SUSPECT_UNKNOWN, '122:  Proof load suspect/unknown'],
    [DrFault.RETEST_ADJUST, '107:  Retest/adjust'],
    [DrFault.RETIRED, '137:  Retired'],
    [DrFault.RUPTURED_LINE_CARRIER, '90:  Ruptured line carrier'],
    [DrFault.SERVICE_LIFE_EXPIRED, '85:  Service life - EXPIRED'],
    [DrFault.SHELF_LIFE_EXPIRED, '86:  Shelf life - EXPIRED'],
    [DrFault.SHEATHING_DAMAGE, '75:  Sheathing damage'],
    [DrFault.SHELF_LIFE_OPERATOR_TIME_LIMIT, '22:  Shelf/life/Operator Time Limit (INACTIVE AFTER 02-NOV-2004)'],
    [DrFault.SOFTGOODS_ABRASION_NICK_SCRATCH, '63:  Softgoods - Abrasion/Nick/Scratch'],
    [DrFault.SOFTGOODS_BROKEN_STRANDS_THREADS_WEAVES, '65:  Softgoods - Broken Strands/Threads/Weaves'],
    [DrFault.SOFTGOODS_DAMAGED_GENERIC, '70:  Softgoods - Damaged (Generic)'],
    [DrFault.SOFTGOODS_FRAYED, '66:  Softgoods - Frayed'],
    [DrFault.SOFTGOODS_HOLE, '67:  Softgoods - Hole'],
    [DrFault.SOFTGOODS_NICK_CUT_TORN, '69:  Softgoods - Nick/Cut/Torn'],
    [DrFault.SOFTGOODS_PULLED, '64:  Softgoods - Pulled'],
    [DrFault.SOFTGOODS_STAINS, '68:  Softgoods - Stains'],
    [DrFault.SOFTGOODS_VOID_MISSING_MATERIAL, '71:  Softgoods - Void/Missing Material'],
    [DrFault.STITCHING_TACKING_THREADS_BROKEN_CUT_RUPTURED, '59:  Stitching/Tacking/Threads - Broken/Cut/Ruptured'],
    [DrFault.STITCHING_TACKING_THREADS_FRAYED, '61:  Stitching/Tacking/Threads - Frayed'],
    [DrFault.STITCHING_TACKING_THREADS_MISSING, '60:  Stitching/Tacking/Threads - Missing'],
    [DrFault.STITCHING_TACKING_THREADS_PULLED, '62:  Stitching/Tacking/Threads - Pulled'],
    [DrFault.STRAIN_RELIEF_BROKEN_DAMAGED, '42:  Strain relief broken/damaged'],
    [DrFault.SUSPICIOUS_LOUD_NOISE_SQUEAL, '131:  Suspicious loud noise/squeal'],
    [DrFault.TAPE_RESIDUE, '111:  Tape residue'],
    [DrFault.TEMPATURE_HIGH, '106:  Temperature-high'],
    [DrFault.TEMPATURE_LOW, '105:  Temperature-low'],
    [DrFault.TEST_FAILED_INCOMPLETE, '119:  Test failed/incomplete'],
    [DrFault.THERMAL_CHARACTERISTIC, '32:  Thermal characteristic (INACTIVE AFTER 02-NOV-2004)'],
    [DrFault.UNBONDING, '74:  Unbonding'],
    [DrFault.UNDER_TORQUED, '8:  Under-torqued'],
    [DrFault.UNKNOWN_CLEANLINESS_LEVEL, '112:  Unknown cleanliness level'],
    [DrFault.UNKNOWN_SUSPECT_CONDITION, '125:  Unknown/suspect condition'],
    [DrFault.USAGE_LIFE_EXPIRED, '84:  Usage life - EXPIRED'],
    [DrFault.WELD_CRACKED_ETC, '109:  Weld-cracked, etc'],
    [DrFault.WIRED_IMPROPERLY, '78:  Wired improperly'],
    [DrFault.WONT_LOCK_IN_PLACE, "101:  Won't lock in place"],
    [DrFault.WORN_PROTECTIVE_COATING, '110:  Worn protective coating'],
    [DrFault.ZIPPER_STOP_MISSING_DAMAGED, '43:  Zipper stop missing/damaged']
]);
export var DrHardwareClassIdentifierGrouping;
(function (DrHardwareClassIdentifierGrouping) {
    DrHardwareClassIdentifierGrouping["DEVELOPMENT_HARDWARE"] = "DEVELOPMENT_HARDWARE";
    DrHardwareClassIdentifierGrouping["FACILITIES"] = "FACILITIES";
    DrHardwareClassIdentifierGrouping["FLIGHT_LIKE_HARDWARE_CLASS_I"] = "FLIGHT_LIKE_HARDWARE_CLASS_I";
    DrHardwareClassIdentifierGrouping["FLIGHT_LIKE_HARDWARE_CLASS_II"] = "FLIGHT_LIKE_HARDWARE_CLASS_II";
    DrHardwareClassIdentifierGrouping["GROUND_SUPPORT_EQUIPMENT_GSE"] = "GROUND_SUPPORT_EQUIPMENT_GSE";
    DrHardwareClassIdentifierGrouping["TRAINING_HARDWARE_CLASS_III"] = "TRAINING_HARDWARE_CLASS_III";
    DrHardwareClassIdentifierGrouping["TRAINING_HARDWARE_CLASS_III_W"] = "TRAINING_HARDWARE_CLASS_III_W";
})(DrHardwareClassIdentifierGrouping || (DrHardwareClassIdentifierGrouping = {}));
export const DrHardwareClassIdentifierGroupingDisplay = new Map([
    [DrHardwareClassIdentifierGrouping.DEVELOPMENT_HARDWARE, '44:  Development hardware'],
    [DrHardwareClassIdentifierGrouping.FACILITIES, '43:  Facilities'],
    [DrHardwareClassIdentifierGrouping.FLIGHT_LIKE_HARDWARE_CLASS_I, '41:  Flight like hardware (Class I)'],
    [DrHardwareClassIdentifierGrouping.FLIGHT_LIKE_HARDWARE_CLASS_II, '1:  Flight like hardware (Class II)'],
    [DrHardwareClassIdentifierGrouping.GROUND_SUPPORT_EQUIPMENT_GSE, '42:  Ground Support Equipment (GSE)'],
    [DrHardwareClassIdentifierGrouping.TRAINING_HARDWARE_CLASS_III, '46:  Training hardware (Class III)'],
    [DrHardwareClassIdentifierGrouping.TRAINING_HARDWARE_CLASS_III_W, '45:  Training hardware (Class III W)']
]);
export var DrPracaReportableCategory;
(function (DrPracaReportableCategory) {
    DrPracaReportableCategory["BASELINED_AS_NON_COMPLEX_GFE"] = "BASELINED_AS_NON_COMPLEX_GFE";
    DrPracaReportableCategory["COSMETIC_IN_NATURE"] = "COSMETIC_IN_NATURE";
    DrPracaReportableCategory["DISCREPANCY_TRANSFERRED_TO_ITEM_INSTANCE_DR"] = "DISCREPANCY_TRANSFERRED_TO_ITEM_INSTANCE_DR";
    DrPracaReportableCategory["DISPOSITIONED_BY_SRP"] = "DISPOSITIONED_BY_SRP";
    DrPracaReportableCategory["DOCUMENTATION_ERROR"] = "DOCUMENTATION_ERROR";
    DrPracaReportableCategory["DR_OPENED_PRIOR_TO_THE_DAY_LIMIT_FUNCTIONALITY"] = "DR_OPENED_PRIOR_TO_THE_DAY_LIMIT_FUNCTIONALITY";
    DrPracaReportableCategory["DR_PENDING_THE_PRT_BUT_PRT_HAS_NOT_SEEN_IT_YET"] = "DR_PENDING_THE_PRT_BUT_PRT_HAS_NOT_SEEN_IT_YET";
    DrPracaReportableCategory["DR_SEEN_BY_PRT_BUT_STILL_UNDER_FIAR_EVALUATION"] = "DR_SEEN_BY_PRT_BUT_STILL_UNDER_FIAR_EVALUATION";
    DrPracaReportableCategory["EXCEEDS_SHELF_LIFE"] = "EXCEEDS_SHELF_LIFE";
    DrPracaReportableCategory["EXPIRED_LIFE_DETECTED_PRIOR_TO_USE_SHIP"] = "EXPIRED_LIFE_DETECTED_PRIOR_TO_USE_SHIP";
    DrPracaReportableCategory["FLIGHT_LIKE_GFE_NON_FLIGHT_ENVIRONMENT"] = "FLIGHT_LIKE_GFE_NON_FLIGHT_ENVIRONMENT";
    DrPracaReportableCategory["GSE_NON_PRACA_REPORTABLE"] = "GSE_NON_PRACA_REPORTABLE";
    DrPracaReportableCategory["LKG_RESOLVED_BY_ROUTINE_PROCEDURE"] = "LKG_RESOLVED_BY_ROUTINE_PROCEDURE";
    DrPracaReportableCategory["NASA_APPROVED_FORWARDING_NO_RECURRING_CONTROL_REQUIRED"] = "NASA_APPROVED_FORWARDING_NO_RECURRING_CONTROL_REQUIRED";
    DrPracaReportableCategory["ON_ORBIT_EXPIRED_ITEMS"] = "ON_ORBIT_EXPIRED_ITEMS";
    DrPracaReportableCategory["OPERATOR_ERROR"] = "OPERATOR_ERROR";
    DrPracaReportableCategory["PRACA_REPORTABLE"] = "PRACA_REPORTABLE";
    DrPracaReportableCategory["PRT_MAKES_THE_DECISION_NOT_TO_WRITE_FIAR"] = "PRT_MAKES_THE_DECISION_NOT_TO_WRITE_FIAR";
    DrPracaReportableCategory["SEND_TO_VENDOR_FOR_FIAR_DETERMINATION"] = "SEND_TO_VENDOR_FOR_FIAR_DETERMINATION";
    DrPracaReportableCategory["TEST_SETUP_ERROR"] = "TEST_SETUP_ERROR";
})(DrPracaReportableCategory || (DrPracaReportableCategory = {}));
export const DrPracaReportableCategoryDisplay = new Map([
    [DrPracaReportableCategory.BASELINED_AS_NON_COMPLEX_GFE, '9:  JSC 28035 4.1.2A Baselined as non complex GFE'],
    [DrPracaReportableCategory.COSMETIC_IN_NATURE, '3:  JSC 28035 4.1.2D - Cosmetic in nature'],
    [DrPracaReportableCategory.DISCREPANCY_TRANSFERRED_TO_ITEM_INSTANCE_DR, '15:  Discrepancy transferred to component DR'],
    [DrPracaReportableCategory.DISPOSITIONED_BY_SRP, '4:  JSC 28035 4.1.2E - Dispositioned by SRP'],
    [DrPracaReportableCategory.DOCUMENTATION_ERROR, '7:  JSC 28035 4.1.2K - Documentation error'],
    [
        DrPracaReportableCategory.DR_OPENED_PRIOR_TO_THE_DAY_LIMIT_FUNCTIONALITY,
        'X:  DR Opened prior to the 21 day limit functionality'
    ],
    [
        DrPracaReportableCategory.DR_PENDING_THE_PRT_BUT_PRT_HAS_NOT_SEEN_IT_YET,
        'P:  DR Pending the PRT but PRT has not seen it yet'
    ],
    [
        DrPracaReportableCategory.DR_SEEN_BY_PRT_BUT_STILL_UNDER_FIAR_EVALUATION,
        'E:  DR seen by PRT but still under FIAR evaluation'
    ],
    [DrPracaReportableCategory.EXCEEDS_SHELF_LIFE, '6:  JSC 28035 4.1.2I - Exceeds shelf life'],
    [
        DrPracaReportableCategory.EXPIRED_LIFE_DETECTED_PRIOR_TO_USE_SHIP,
        '11:  JSC 28035 4.1.2H Expired life detected proir to use/ship'
    ],
    [
        DrPracaReportableCategory.FLIGHT_LIKE_GFE_NON_FLIGHT_ENVIRONMENT,
        '13:  JSC 28035 4.1.2L Flight like GFE, non-flight environment'
    ],
    [DrPracaReportableCategory.GSE_NON_PRACA_REPORTABLE, '8:  GSE - Non-PRACA Reportable'],
    [DrPracaReportableCategory.LKG_RESOLVED_BY_ROUTINE_PROCEDURE, '5:  JSC 28035 4.1.2G - LKG Resolved by routine procedure'],
    [
        DrPracaReportableCategory.NASA_APPROVED_FORWARDING_NO_RECURRING_CONTROL_REQUIRED,
        '10:  JSC 28035 4.1.2F - NASA Approved FW&T,NO RECUR CTRL REQ'
    ],
    [DrPracaReportableCategory.ON_ORBIT_EXPIRED_ITEMS, '12:  JSC 28035 4.1.2J On-orbit expired items'],
    [DrPracaReportableCategory.OPERATOR_ERROR, '1:  JSC 28035 4.1.2B - Operator Error'],
    [DrPracaReportableCategory.PRACA_REPORTABLE, 'Y:  PRACA Reportable'],
    [DrPracaReportableCategory.PRT_MAKES_THE_DECISION_NOT_TO_WRITE_FIAR, '14:  PRT Makes the decision not to write FIAR'],
    [DrPracaReportableCategory.SEND_TO_VENDOR_FOR_FIAR_DETERMINATION, '16:  Send to vendor for FIAR determination'],
    [DrPracaReportableCategory.TEST_SETUP_ERROR, '2:  JSC 28035 4.1.2C - Test set up error']
]);
export var DrLocationCode;
(function (DrLocationCode) {
    DrLocationCode["_1"] = "_1";
    DrLocationCode["_2"] = "_2";
    DrLocationCode["_3"] = "_3";
    DrLocationCode["_4"] = "_4";
    DrLocationCode["_5"] = "_5";
    DrLocationCode["_6"] = "_6";
    DrLocationCode["_7"] = "_7";
    DrLocationCode["_8"] = "_8";
    DrLocationCode["_9"] = "_9";
    DrLocationCode["_10"] = "_10";
    DrLocationCode["_11"] = "_11";
    DrLocationCode["_12"] = "_12";
    DrLocationCode["_13"] = "_13";
    DrLocationCode["_14"] = "_14";
    DrLocationCode["_15"] = "_15";
    DrLocationCode["_16"] = "_16";
    DrLocationCode["_17"] = "_17";
    DrLocationCode["_18"] = "_18";
    DrLocationCode["_19"] = "_19";
    DrLocationCode["_20"] = "_20";
    DrLocationCode["_21"] = "_21";
    DrLocationCode["_22"] = "_22";
    DrLocationCode["_23"] = "_23";
    DrLocationCode["_24"] = "_24";
    DrLocationCode["_25"] = "_25";
    DrLocationCode["_26"] = "_26";
    DrLocationCode["_27"] = "_27";
    DrLocationCode["_28"] = "_28";
    DrLocationCode["_29"] = "_29";
    DrLocationCode["_30"] = "_30";
    DrLocationCode["_31"] = "_31";
    DrLocationCode["_32"] = "_32";
    DrLocationCode["_33"] = "_33";
    DrLocationCode["_34"] = "_34";
    DrLocationCode["_35"] = "_35";
    DrLocationCode["_36"] = "_36";
    DrLocationCode["_37"] = "_37";
    DrLocationCode["_38"] = "_38";
    DrLocationCode["_39"] = "_39";
    DrLocationCode["_40"] = "_40";
    DrLocationCode["_41"] = "_41";
    DrLocationCode["_42"] = "_42";
    DrLocationCode["_43"] = "_43";
    DrLocationCode["_44"] = "_44";
    DrLocationCode["_45"] = "_45";
    DrLocationCode["_46"] = "_46";
    DrLocationCode["_47"] = "_47";
    DrLocationCode["_48"] = "_48";
    DrLocationCode["_49"] = "_49";
    DrLocationCode["_50"] = "_50";
    DrLocationCode["_51"] = "_51";
    DrLocationCode["_52"] = "_52";
    DrLocationCode["_53"] = "_53";
    DrLocationCode["_54"] = "_54";
    DrLocationCode["_55"] = "_55";
    DrLocationCode["_56"] = "_56";
    DrLocationCode["_57"] = "_57";
    DrLocationCode["_58"] = "_58";
    DrLocationCode["_59"] = "_59";
    DrLocationCode["_60"] = "_60";
    DrLocationCode["_61"] = "_61";
})(DrLocationCode || (DrLocationCode = {}));
export const DrLocationCodeDisplay = new Map([
    [DrLocationCode._1, '01: JSC/MISC'],
    [DrLocationCode._2, '02: JSC/B4N'],
    [DrLocationCode._3, '03: JSC/B4S'],
    [DrLocationCode._4, '04: JSC/B5'],
    [DrLocationCode._5, '05: JSC/B7'],
    [DrLocationCode._6, '06: JSC/B8'],
    [DrLocationCode._7, '07: JSC/B9'],
    [DrLocationCode._8, '08: JSC/B13'],
    [DrLocationCode._9, '09: JSC/B30'],
    [DrLocationCode._10, '10: JSC/B32'],
    [DrLocationCode._11, '11: JSC/B33'],
    [DrLocationCode._12, '12: JSC/B35'],
    [DrLocationCode._13, '13: JSC/SESL CHAMBER'],
    [DrLocationCode._14, '14: JSC/ESTA'],
    [DrLocationCode._15, '15: MISC'],
    [DrLocationCode._16, '16: LSS LAB'],
    [DrLocationCode._17, '17: SSA LAB'],
    [DrLocationCode._18, '18: SEWING LAB'],
    [DrLocationCode._19, '19: BATTERY LAB'],
    [DrLocationCode._20, '20: CAMERA LAB (INACTIVE AFTER JULY 2013)'],
    [DrLocationCode._21, '21: GSE LAB'],
    [DrLocationCode._22, '22: CCC LAB'],
    [DrLocationCode._23, '23: CREW EQUIPMENT LAB (INACTIVE AFTER JULY 2013)'],
    [DrLocationCode._24, '24: PREPACK (INACTIVE AFTER JULY 2013)'],
    [DrLocationCode._25, '25: EVA TOOL LAB'],
    [DrLocationCode._26, '26: CEE LAB (INACTIVE AFTER JULY 2013)'],
    [DrLocationCode._27, '27: ELECTRONICS LAB'],
    [DrLocationCode._28, '28: SHIPPING AND RECEIVING'],
    [DrLocationCode._29, '29: FOOD LAB (INACTIVE AFTER JULY 2013)'],
    [DrLocationCode._30, '30: MACHINE SHOP'],
    [DrLocationCode._31, '31: KSC/MISC'],
    [DrLocationCode._32, '32: KSC/O&C'],
    [DrLocationCode._33, '33: KSC/PAD'],
    [DrLocationCode._34, '34: KSC/SSPF'],
    [DrLocationCode._35, '35: WHITE SANDS TEST FACILITY (WSTF)'],
    [DrLocationCode._36, '36: MSFC'],
    [DrLocationCode._37, '37: EAFB'],
    [DrLocationCode._38, '38: EFD'],
    [DrLocationCode._39, '39: RUSSIA'],
    [DrLocationCode._40, '40: CARLTON'],
    [DrLocationCode._41, '41: CONAX'],
    [DrLocationCode._42, '42: DAVID CLARK'],
    [DrLocationCode._43, '43: BOEING'],
    [DrLocationCode._44, '44: HS/MS HAMILTON SUNDSTRAND BLDG 7'],
    [DrLocationCode._45, '45: LOCKHEED MARTIN - CSS'],
    [DrLocationCode._46, '46: VENDOR - (GENERIC)'],
    [DrLocationCode._47, '47: OCEANEERING'],
    [DrLocationCode._48, '48: ON-ORBIT'],
    [DrLocationCode._49, '49: SHUTTLE'],
    [DrLocationCode._50, '50: STATION'],
    [DrLocationCode._51, '51: NBL POOL'],
    [DrLocationCode._52, '52: NBL SUIT LAB'],
    [DrLocationCode._53, '53: NBL EVA TOOL LAB'],
    [DrLocationCode._54, '54: NBL 1G'],
    [DrLocationCode._55, '55: UNKNOWN'],
    [DrLocationCode._56, '56: CWC LAB (INACTIVE AFTER JULY 2013)'],
    [DrLocationCode._57, '57: SORG LAB (INACTIVE AFTER JULY 2013)'],
    [DrLocationCode._58, '58: FABRICATION LAB (INACTIVE AFTER JULY 2013)'],
    [DrLocationCode._59, '59: FCP LAB (INACTIVE AFTER JULY 2013)'],
    [DrLocationCode._60, '60: BOND/STORES'],
    [DrLocationCode._61, '61: HAMILTON SUNSTRAND WINDSOR LOCKS CONN']
]);
export var DrRepairTypeCategory;
(function (DrRepairTypeCategory) {
    DrRepairTypeCategory["STANDARD_REPAIR"] = "STANDARD_REPAIR";
    DrRepairTypeCategory["CORRECTIVE_ACTION"] = "CORRECTIVE_ACTION";
    DrRepairTypeCategory["PREVENTIVE_ACTION"] = "PREVENTIVE_ACTION";
    DrRepairTypeCategory["USAGE_LIFE_EXPIRED"] = "USAGE_LIFE_EXPIRED";
    DrRepairTypeCategory["SHELF_LIFE_EXPIRED"] = "SHELF_LIFE_EXPIRED";
})(DrRepairTypeCategory || (DrRepairTypeCategory = {}));
export const DrRepairTypeCategoryDisplay = new Map([
    [DrRepairTypeCategory.STANDARD_REPAIR, 'S:  Standard Repair'],
    [DrRepairTypeCategory.CORRECTIVE_ACTION, 'C:  Corrective Action'],
    [DrRepairTypeCategory.PREVENTIVE_ACTION, 'P:  Preventive Action'],
    [DrRepairTypeCategory.USAGE_LIFE_EXPIRED, 'U:  Usage Life Expired'],
    [DrRepairTypeCategory.SHELF_LIFE_EXPIRED, 'S:  Shelf Life Expired']
]);
export var DrResponsibleHardwareIdentifierGrouping;
(function (DrResponsibleHardwareIdentifierGrouping) {
    DrResponsibleHardwareIdentifierGrouping["ANCILLARY_EQUIPMENT"] = "ANCILLARY_EQUIPMENT";
    DrResponsibleHardwareIdentifierGrouping["BATTERIES"] = "BATTERIES";
    DrResponsibleHardwareIdentifierGrouping["BOEING_FEPC"] = "BOEING_FEPC";
    DrResponsibleHardwareIdentifierGrouping["CLOTHING_CREW_EQUIPMENT"] = "CLOTHING_CREW_EQUIPMENT";
    DrResponsibleHardwareIdentifierGrouping["CREW_ESCAPE_EQUIPMENT"] = "CREW_ESCAPE_EQUIPMENT";
    DrResponsibleHardwareIdentifierGrouping["DATA_PROCESSING"] = "DATA_PROCESSING";
    DrResponsibleHardwareIdentifierGrouping["DISPLAY_AND_CONTROLS"] = "DISPLAY_AND_CONTROLS";
    DrResponsibleHardwareIdentifierGrouping["ELECTRONICS"] = "ELECTRONICS";
    DrResponsibleHardwareIdentifierGrouping["EMU"] = "EMU";
    DrResponsibleHardwareIdentifierGrouping["EVA_SUPPORT_EQUIPMENT"] = "EVA_SUPPORT_EQUIPMENT";
    DrResponsibleHardwareIdentifierGrouping["FILM"] = "FILM";
    DrResponsibleHardwareIdentifierGrouping["FLIGHT_COMPUTER_PROCESSING"] = "FLIGHT_COMPUTER_PROCESSING";
    DrResponsibleHardwareIdentifierGrouping["FLIGHT_GENERAL"] = "FLIGHT_GENERAL";
    DrResponsibleHardwareIdentifierGrouping["FOOD_AND_MEDICAL_SUPPLIES"] = "FOOD_AND_MEDICAL_SUPPLIES";
    DrResponsibleHardwareIdentifierGrouping["GALLEY_AND_BIOINSTRUMENT"] = "GALLEY_AND_BIOINSTRUMENT";
    DrResponsibleHardwareIdentifierGrouping["GSE"] = "GSE";
    DrResponsibleHardwareIdentifierGrouping["NON_FCE_EVA_HARDWARE"] = "NON_FCE_EVA_HARDWARE";
    DrResponsibleHardwareIdentifierGrouping["PATCHES_FLAGS_DECALS"] = "PATCHES_FLAGS_DECALS";
    DrResponsibleHardwareIdentifierGrouping["PAYLOAD_BAY_TOOL_STOWAGE_ASSEMBLY"] = "PAYLOAD_BAY_TOOL_STOWAGE_ASSEMBLY";
    DrResponsibleHardwareIdentifierGrouping["PHOTOGRAPHIC_EQUIPMENT"] = "PHOTOGRAPHIC_EQUIPMENT";
    DrResponsibleHardwareIdentifierGrouping["PREPACK_BENCH_REVIEW_CUSHION"] = "PREPACK_BENCH_REVIEW_CUSHION";
    DrResponsibleHardwareIdentifierGrouping["PSE_GENERAL"] = "PSE_GENERAL";
    DrResponsibleHardwareIdentifierGrouping["SAFER"] = "SAFER";
    DrResponsibleHardwareIdentifierGrouping["VEHICLE_ACCOMMODATIONS"] = "VEHICLE_ACCOMMODATIONS";
    DrResponsibleHardwareIdentifierGrouping["VENDOR"] = "VENDOR";
})(DrResponsibleHardwareIdentifierGrouping || (DrResponsibleHardwareIdentifierGrouping = {}));
export const DrResponsibleHardwareIdentifierGroupingDisplay = new Map([
    [DrResponsibleHardwareIdentifierGrouping.ANCILLARY_EQUIPMENT, '2:  Ancillary equipment'],
    [DrResponsibleHardwareIdentifierGrouping.BATTERIES, '8:  Batteries'],
    [DrResponsibleHardwareIdentifierGrouping.BOEING_FEPC, 'BF:  BOEING-FEPC (INACTIVE AFTER 02-NOV-2004)'],
    [
        DrResponsibleHardwareIdentifierGrouping.CLOTHING_CREW_EQUIPMENT,
        '13:  Clothing/Crew equipment (Inactive after July 2013)'
    ],
    [DrResponsibleHardwareIdentifierGrouping.CREW_ESCAPE_EQUIPMENT, '14:  Crew escape equipment (Inactive after July 2013)'],
    [DrResponsibleHardwareIdentifierGrouping.DATA_PROCESSING, '22:  Data processing'],
    [DrResponsibleHardwareIdentifierGrouping.DISPLAY_AND_CONTROLS, '12:  Display and controls'],
    [DrResponsibleHardwareIdentifierGrouping.ELECTRONICS, '9:  Electronics'],
    [DrResponsibleHardwareIdentifierGrouping.EMU, '1:  EMU'],
    [DrResponsibleHardwareIdentifierGrouping.EVA_SUPPORT_EQUIPMENT, '6:  Eva support equipment'],
    [DrResponsibleHardwareIdentifierGrouping.FILM, '7:  Film (Inactive after July 2013)'],
    [DrResponsibleHardwareIdentifierGrouping.FLIGHT_COMPUTER_PROCESSING, '17:  Flight computer processing'],
    [DrResponsibleHardwareIdentifierGrouping.FLIGHT_GENERAL, '18:  Flight - General'],
    [
        DrResponsibleHardwareIdentifierGrouping.FOOD_AND_MEDICAL_SUPPLIES,
        '4:  Food and medical supplies (Inactive after July 2013)'
    ],
    [
        DrResponsibleHardwareIdentifierGrouping.GALLEY_AND_BIOINSTRUMENT,
        '3:  Galley and bioinstrument (Inactive after July 2013)'
    ],
    [DrResponsibleHardwareIdentifierGrouping.GSE, '15:  GSE'],
    [DrResponsibleHardwareIdentifierGrouping.NON_FCE_EVA_HARDWARE, '23:  Non-FCE/EVA hardware'],
    [DrResponsibleHardwareIdentifierGrouping.PATCHES_FLAGS_DECALS, '21:  Patches/Flags/Decals'],
    [DrResponsibleHardwareIdentifierGrouping.PAYLOAD_BAY_TOOL_STOWAGE_ASSEMBLY, '5:  Payload bay/Tool stowage assembly'],
    [DrResponsibleHardwareIdentifierGrouping.PHOTOGRAPHIC_EQUIPMENT, '10:  Photographic equipment (Inactive after July 2013)'],
    [DrResponsibleHardwareIdentifierGrouping.PREPACK_BENCH_REVIEW_CUSHION, '20:  Prepack/Bench review/Cushion'],
    [DrResponsibleHardwareIdentifierGrouping.PSE_GENERAL, '19:  PSE - General'],
    [DrResponsibleHardwareIdentifierGrouping.SAFER, '16:  SAFER (Inactive after July 2013)'],
    [DrResponsibleHardwareIdentifierGrouping.VEHICLE_ACCOMMODATIONS, '11:  Vehicle accommodations (Inactive after July 2013)'],
    [DrResponsibleHardwareIdentifierGrouping.VENDOR, 'VN:  VENDOR (INACTIVE AFTER 02-NOV-2004)']
]);
export var DrStatusCategory;
(function (DrStatusCategory) {
    DrStatusCategory["CANCELLED"] = "CANCELLED";
    DrStatusCategory["IN_APPROVAL_LOOP"] = "IN_APPROVAL_LOOP";
    DrStatusCategory["IN_CLOSURE_LOOP"] = "IN_CLOSURE_LOOP";
    DrStatusCategory["IN_IE_SUSPENSE_FILE"] = "IN_IE_SUSPENSE_FILE";
    DrStatusCategory["ON_ORBIT_PENDING_RETURN_TO_SGT"] = "ON_ORBIT_PENDING_RETURN_TO_SGT";
    DrStatusCategory["PENDING_BOND"] = "PENDING_BOND";
    DrStatusCategory["PENDING_BUSINESS_MANAGEMENT"] = "PENDING_BUSINESS_MANAGEMENT";
    DrStatusCategory["PENDING_CALIBRATION"] = "PENDING_CALIBRATION";
    DrStatusCategory["PENDING_CMO_UPDATES"] = "PENDING_CMO_UPDATES";
    DrStatusCategory["PENDING_DRAWING_PROCEDURE_REVISION"] = "PENDING_DRAWING_PROCEDURE_REVISION";
    DrStatusCategory["PENDING_ENGINEERING_OR_IE_REVIEW_OR_DISPOSITION"] = "PENDING_ENGINEERING_OR_IE_REVIEW_OR_DISPOSITION";
    DrStatusCategory["PENDING_FACILITIES"] = "PENDING_FACILITIES";
    DrStatusCategory["PENDING_GSE_DESIGN_REVIEW_OR_DISPOSITION"] = "PENDING_GSE_DESIGN_REVIEW_OR_DISPOSITION";
    DrStatusCategory["PENDING_LIMITED_LIFE_EVALUATION"] = "PENDING_LIMITED_LIFE_EVALUATION";
    DrStatusCategory["PENDING_MATERIAL_PARTS"] = "PENDING_MATERIAL_PARTS";
    DrStatusCategory["PENDING_MATERIALS_AND_PROCESSES"] = "PENDING_MATERIALS_AND_PROCESSES";
    DrStatusCategory["PENDING_NASA_DIRECTION"] = "PENDING_NASA_DIRECTION";
    DrStatusCategory["PENDING_ONEEVA_PMO"] = "PENDING_ONEEVA_PMO";
    DrStatusCategory["PENDING_PROPERTY_ASSIGNMENT_SHIPPER"] = "PENDING_PROPERTY_ASSIGNMENT_SHIPPER";
    DrStatusCategory["PENDING_QRC_UPDATES"] = "PENDING_QRC_UPDATES";
    DrStatusCategory["PENDING_REOPENED_FOR_QUALITY_AUDIT"] = "PENDING_REOPENED_FOR_QUALITY_AUDIT";
    DrStatusCategory["PENDING_SHIPPING"] = "PENDING_SHIPPING";
    DrStatusCategory["PENDING_VENDOR_SUBCONTRACTOR"] = "PENDING_VENDOR_SUBCONTRACTOR";
    DrStatusCategory["PENDING_WORK_PACKAGE_AND_HARDWARE_CONTROL"] = "PENDING_WORK_PACKAGE_AND_HARDWARE_CONTROL";
    DrStatusCategory["READY_TO_WORK_OR_IN_WORK"] = "READY_TO_WORK_OR_IN_WORK";
    DrStatusCategory["RETURN_TO_QUALITY_ASSURANCE"] = "RETURN_TO_QUALITY_ASSURANCE";
})(DrStatusCategory || (DrStatusCategory = {}));
export const DrStatusCategoryDisplay = new Map([
    [DrStatusCategory.IN_APPROVAL_LOOP, 'A: In Approval Loop'],
    [DrStatusCategory.PENDING_BUSINESS_MANAGEMENT, 'B: Pending Business Management'],
    [DrStatusCategory.IN_CLOSURE_LOOP, 'C: In Closure Loop (Work Completed)'],
    [DrStatusCategory.PENDING_DRAWING_PROCEDURE_REVISION, 'D: Pending Drawing/Procedure Revision'],
    [DrStatusCategory.PENDING_ENGINEERING_OR_IE_REVIEW_OR_DISPOSITION, 'E: Pending Engineering and/or Disposition'],
    [DrStatusCategory.PENDING_FACILITIES, 'F: Hardware no longer Needed/Usable'],
    [DrStatusCategory.PENDING_GSE_DESIGN_REVIEW_OR_DISPOSITION, 'G: Pending GSE Design Review and/or Disposition'],
    [DrStatusCategory.PENDING_WORK_PACKAGE_AND_HARDWARE_CONTROL, 'H: Pending Work Package and Hardware Control'],
    [DrStatusCategory.IN_IE_SUSPENSE_FILE, 'I: In Suspense File'],
    [DrStatusCategory.PENDING_ONEEVA_PMO, 'J: Pending OneEVA PMO'],
    [DrStatusCategory.PENDING_CALIBRATION, 'K: Pending Calibration/Service'],
    [DrStatusCategory.PENDING_LIMITED_LIFE_EVALUATION, 'L: Expired - Retained for continued use'],
    [DrStatusCategory.PENDING_MATERIAL_PARTS, 'M: Pending Material/Parts'],
    [DrStatusCategory.PENDING_NASA_DIRECTION, 'N: Pending NASA Direction'],
    [DrStatusCategory.PENDING_CMO_UPDATES, 'O: Pending CMO Updates'],
    [DrStatusCategory.PENDING_PROPERTY_ASSIGNMENT_SHIPPER, 'P: Pending Property Assignment/Shipper'],
    [DrStatusCategory.PENDING_QRC_UPDATES, 'Q: Pending QRC Review/Updates'],
    [DrStatusCategory.RETURN_TO_QUALITY_ASSURANCE, 'R: Return to Quality Assurance'],
    [DrStatusCategory.PENDING_SHIPPING, 'S: Pending Shipping'],
    [DrStatusCategory.PENDING_MATERIALS_AND_PROCESSES, 'T: Pending Materials and Processes'],
    [DrStatusCategory.PENDING_REOPENED_FOR_QUALITY_AUDIT, 'U: Reopened for Quality Audit'],
    [DrStatusCategory.PENDING_VENDOR_SUBCONTRACTOR, 'V: Pending Vendor/Subcontractor'],
    [DrStatusCategory.READY_TO_WORK_OR_IN_WORK, 'W: Ready to Work or In Work with Lab'],
    [DrStatusCategory.ON_ORBIT_PENDING_RETURN_TO_SGT, 'X: On Orbit Pending Return to SGT'],
    [DrStatusCategory.CANCELLED, 'Y: Inactive Hardware'],
    [DrStatusCategory.PENDING_BOND, 'Z: Pending Bond']
]);
export var DrWhenNoticedCategory;
(function (DrWhenNoticedCategory) {
    DrWhenNoticedCategory["ACCEPT_CERTIFIED_QUALITY_TEST"] = "ACCEPT_CERTIFIED_QUALITY_TEST";
    DrWhenNoticedCategory["MANUFACTURER_BUILD_ASSEMBLY"] = "MANUFACTURER_BUILD_ASSEMBLY";
    DrWhenNoticedCategory["RECEIVING"] = "RECEIVING";
    DrWhenNoticedCategory["PRE_FLIGHT_PROCESSING"] = "PRE_FLIGHT_PROCESSING";
    DrWhenNoticedCategory["POST_FLIGHT_PROCESSING"] = "POST_FLIGHT_PROCESSING";
    DrWhenNoticedCategory["FLIGHT_OPERATIONS_IN_ACTIVE_AFTER_NOV"] = "FLIGHT_OPERATIONS_IN_ACTIVE_AFTER_NOV";
    DrWhenNoticedCategory["CALIBRATION"] = "CALIBRATION";
    DrWhenNoticedCategory["MAINTENANCE"] = "MAINTENANCE";
    DrWhenNoticedCategory["TRAINING"] = "TRAINING";
    DrWhenNoticedCategory["SHIPPING"] = "SHIPPING";
    DrWhenNoticedCategory["SURVEILLANCE"] = "SURVEILLANCE";
    DrWhenNoticedCategory["IN_FLIGHT"] = "IN_FLIGHT";
    DrWhenNoticedCategory["PDA"] = "PDA";
    DrWhenNoticedCategory["PIA"] = "PIA";
    DrWhenNoticedCategory["MONTH_OVERHAUL_18"] = "MONTH_OVERHAUL_18";
    DrWhenNoticedCategory["MONTH_OVERHAUL_24"] = "MONTH_OVERHAUL_24";
    DrWhenNoticedCategory["MONTH_OVERHAUL_36"] = "MONTH_OVERHAUL_36";
    DrWhenNoticedCategory["MONTH_OVERHAUL_40"] = "MONTH_OVERHAUL_40";
    DrWhenNoticedCategory["MONTH_OVERHAUL_78"] = "MONTH_OVERHAUL_78";
    DrWhenNoticedCategory["STA"] = "STA";
    DrWhenNoticedCategory["SHORT_PRE_FLIGHT_PROCESSING"] = "SHORT_PRE_FLIGHT_PROCESSING";
    DrWhenNoticedCategory["LONG_PRE_FLIGHT_PROCESSING"] = "LONG_PRE_FLIGHT_PROCESSING";
    DrWhenNoticedCategory["GOLDEN_ROUND"] = "GOLDEN_ROUND";
    DrWhenNoticedCategory["REPACK"] = "REPACK";
    DrWhenNoticedCategory["INSPECTION"] = "INSPECTION";
    DrWhenNoticedCategory["EVALUATION"] = "EVALUATION";
    DrWhenNoticedCategory["TESTING"] = "TESTING";
    DrWhenNoticedCategory["PRE_PACK"] = "PRE_PACK";
    DrWhenNoticedCategory["BENCH_REVIEW"] = "BENCH_REVIEW";
    DrWhenNoticedCategory["CEIT"] = "CEIT";
    DrWhenNoticedCategory["TCDT"] = "TCDT";
    DrWhenNoticedCategory["FOOD_PROCESSING"] = "FOOD_PROCESSING";
    DrWhenNoticedCategory["FOOD_PRODUCTION"] = "FOOD_PRODUCTION";
    DrWhenNoticedCategory["CERTIFICATION"] = "CERTIFICATION";
    DrWhenNoticedCategory["RUSSIAN_TRAINING"] = "RUSSIAN_TRAINING";
    DrWhenNoticedCategory["RE_INSPECTION_REQUEST"] = "RE_INSPECTION_REQUEST";
    DrWhenNoticedCategory["KC_135"] = "KC_135";
    DrWhenNoticedCategory["UNKNOWN"] = "UNKNOWN";
    DrWhenNoticedCategory["PRE_CHAMBER_PROCESSING"] = "PRE_CHAMBER_PROCESSING";
    DrWhenNoticedCategory["POST_CHAMBER_PROCESSING"] = "POST_CHAMBER_PROCESSING";
})(DrWhenNoticedCategory || (DrWhenNoticedCategory = {}));
export const DrWhenNoticedCategoryDisplay = new Map([
    [DrWhenNoticedCategory.ACCEPT_CERTIFIED_QUALITY_TEST, '1:  Accept/Certified quality test'],
    [DrWhenNoticedCategory.MANUFACTURER_BUILD_ASSEMBLY, '2:  Manufacturer build assembly'],
    [DrWhenNoticedCategory.RECEIVING, '3: Receiving'],
    [DrWhenNoticedCategory.PRE_FLIGHT_PROCESSING, '4:  Pre-flight processing'],
    [DrWhenNoticedCategory.POST_FLIGHT_PROCESSING, '5:  Post-flight processing'],
    [DrWhenNoticedCategory.FLIGHT_OPERATIONS_IN_ACTIVE_AFTER_NOV, '6:  Flight operations (INACTIVE AFTER 2-NOV-2004)'],
    [DrWhenNoticedCategory.CALIBRATION, '7:  Calibration'],
    [DrWhenNoticedCategory.MAINTENANCE, '8:  Maintenance'],
    [DrWhenNoticedCategory.TRAINING, '9:  Training'],
    [DrWhenNoticedCategory.SHIPPING, '10:  Shipping'],
    [DrWhenNoticedCategory.SURVEILLANCE, '11:  Surveillance'],
    [DrWhenNoticedCategory.IN_FLIGHT, '12:  In-flight'],
    [DrWhenNoticedCategory.PDA, '13:  PDA'],
    [DrWhenNoticedCategory.PIA, '14:  PIA'],
    [DrWhenNoticedCategory.MONTH_OVERHAUL_18, '15:  18 Month Overhaul'],
    [DrWhenNoticedCategory.MONTH_OVERHAUL_24, '16:  24 Month Overhaul'],
    [DrWhenNoticedCategory.MONTH_OVERHAUL_36, '17:  36 Month Overhaul'],
    [DrWhenNoticedCategory.MONTH_OVERHAUL_40, '18:  40 Month Overhaul'],
    [DrWhenNoticedCategory.MONTH_OVERHAUL_78, '19:  78 Month Overhaul'],
    [DrWhenNoticedCategory.STA, '20:  STA'],
    [DrWhenNoticedCategory.SHORT_PRE_FLIGHT_PROCESSING, '21:  Short pre-flight processing'],
    [DrWhenNoticedCategory.LONG_PRE_FLIGHT_PROCESSING, '22:  Long pre-flight processing'],
    [DrWhenNoticedCategory.GOLDEN_ROUND, '23:  Golden-round'],
    [DrWhenNoticedCategory.REPACK, '24: Repack'],
    [DrWhenNoticedCategory.INSPECTION, '25:  Inspection'],
    [DrWhenNoticedCategory.EVALUATION, '26:  Evaluation'],
    [DrWhenNoticedCategory.TESTING, '27:  Testing'],
    [DrWhenNoticedCategory.PRE_PACK, '28:  Pre-pack'],
    [DrWhenNoticedCategory.BENCH_REVIEW, '29:  Bench review'],
    [DrWhenNoticedCategory.CEIT, '30:  CEIT'],
    [DrWhenNoticedCategory.TCDT, '31:  TCDT'],
    [DrWhenNoticedCategory.FOOD_PROCESSING, '32:  Food processing'],
    [DrWhenNoticedCategory.FOOD_PRODUCTION, '33:  Food production'],
    [DrWhenNoticedCategory.CERTIFICATION, '34:  Certification'],
    [DrWhenNoticedCategory.RUSSIAN_TRAINING, '35:  Russian training'],
    [DrWhenNoticedCategory.RE_INSPECTION_REQUEST, '36:  Re-inspection request'],
    [DrWhenNoticedCategory.KC_135, '37:  KC-135'],
    [DrWhenNoticedCategory.UNKNOWN, '38:  Unknown'],
    [DrWhenNoticedCategory.PRE_CHAMBER_PROCESSING, '39:  Pre-chamber processing'],
    [DrWhenNoticedCategory.POST_CHAMBER_PROCESSING, '40:  Post-chamber processing']
]);
