import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component, Vue } from 'vue-property-decorator';
import { InventoryStatus } from '~/db_types';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { CosmicIntervalFragment } from '~/nasa_ui/DAL/base/interval';
import { CosmicItemInstanceSlimFragment } from '~/nasa_ui/DAL/itemInstance/slim';
import { CosmicLocationSlimFragment } from '~/nasa_ui/DAL/location/slim';
import { AlertType } from '~/nasa_ui/types';
let DueInMixin = class DueInMixin extends Vue {
    inventoryDueBackQueryResults = null;
    pendingInventoryReceiptsQueryResult = null;
    isFetching = false;
    // For the InventoryDueBackTable
    get inventoryDueBackItems() {
        if (!this.inventoryDueBackQueryResults) {
            return [];
        }
        return this.inventoryDueBackQueryResults
            .filter((dueBackQueryResult) => dueBackQueryResult.location?.building !== 'IN_TRANSIT')
            .map(this.formatDueBackHardware);
    }
    // inTransitTableItems
    get inventoryIssuedWeHaveNotHad() {
        if (!this.pendingInventoryReceiptsQueryResult) {
            return [];
        }
        // stuff we have NOT ever had
        return this.pendingInventoryReceiptsQueryResult
            .filter((receipt) => receipt.status === InventoryStatus.ISSUED && receipt?.itemInstance === null)
            .map(this.formatOpenCrossContextReceipt);
    }
    // inTransitTableItems
    get inventoryIssuedToAnotherContext() {
        if (!this.pendingInventoryReceiptsQueryResult) {
            return [];
        }
        // Stuff we have had but issued to another ctx
        return this.pendingInventoryReceiptsQueryResult
            .filter((receipt) => receipt.status === InventoryStatus.ISSUED && receipt?.itemInstance !== null)
            .map(this.formatOpenCrossContextReceipt);
    }
    get inTransitTableItems() {
        return [...this.inventoryIssuedWeHaveNotHad, ...this.inventoryIssuedToAnotherContext].map((item) => {
            return {
                ...item,
                _description: item.itemInstanceCrossContext?.description || ''
            };
        });
    }
    /**
     * InventoryDueBack API call
     * - For this API we do not need to work about "ItemInstanceCrossContext" because by defintion "dueBack"
     *   hardware would have had to be in the user's context before, meaning they have a "itemInstance"
     */
    async fetchInventoryDueBack() {
        try {
            this.isFetching = true;
            const resp = await this.$apollo.query({
                query: gql `
          ${CosmicLocationSlimFragment}
          ${CosmicItemInstanceSlimFragment}

          fragment HardwareDueBack on InventoryCrossContext {
            asBuiltNumber
            drawingNumber
            expectedReturnDate
            expectedReturnOrganizationCode
            inventoryId
            itemInstanceId
            projectCode
            quantity
            side
            unitCost
            createdDateTime
            issueDestinationOrganizationCode
            supportOrganizationCode
            location {
              ...CosmicLocationSlim
            }
            inventory {
              nodeId
              id
              isArchived
            }
            itemInstance {
              ...CosmicItemInstanceSlim
            }
            itemMaster {
              asBuiltNumber
              drawingNumber
              nodeId
              side
            }
            itemInstanceCrossContext {
              asBuiltNumber
              description
              drawingNumber
              id
              lotNumber
              serialNumber
              size
            }
            locationCrossContext {
              id
              building
              bin
              stock
              organization {
                name
              }
            }
          }
          query InventoryDueBackReportView {
            inventoryDueBack {
              nodes {
                ...HardwareDueBack
              }
            }
          }
        `,
                fetchPolicy: 'network-only'
            });
            if (resp.errors) {
                this.isFetching = false;
                throw resp.errors;
            }
            this.inventoryDueBackQueryResults = (resp.data.inventoryDueBack?.nodes || []);
            this.isFetching = false;
        }
        catch (err) {
            this.isFetching = false;
            this.$errorUtility({ err });
            this.$notification.add({
                text: 'An error occurred during the fetching of data',
                type: AlertType.ERROR
            });
        }
    }
    async fetchPendingInventoryReceipts() {
        try {
            const resp = await this.$apollo.query({
                query: gql `
          ${CosmicIntervalFragment}
          ${CosmicItemInstanceSlimFragment}
          ${CosmicLocationSlimFragment}

          fragment PendingInventoryReceipts on InventoryCrossContext {
            asBuiltNumber
            drawingNumber
            initialReceiptDate
            inventoryId
            itemInstanceId
            projectCode
            quantity
            side
            status
            supportOrganizationCode
            unitCost
            itemInstanceCrossContext {
              asBuiltNumber
              description
              drawingNumber
              id
              lotNumber
              serialNumber
              side
              size
            }
            inventory {
              id
              nodeId
              expectedReturnDate
            }
            itemInstance {
              ...CosmicItemInstanceSlim
            }
            itemMaster {
              asBuiltNumber
              drawingNumber
              nodeId
              side
              planning {
                receivingLeadTime {
                  ...CosmicInterval
                }
              }
            }
            location {
              ...CosmicLocationSlim
            }
            locationCrossContext {
              id
              organization {
                name
              }
            }
          }

          query PendingInventoryReceiptsDueInOutReportView {
            pendingInventoryReceipts {
              nodes {
                ...PendingInventoryReceipts
              }
            }
          }
        `,
                fetchPolicy: 'network-only'
            });
            if (resp.errors) {
                throw resp.errors;
            }
            this.pendingInventoryReceiptsQueryResult = (resp.data.pendingInventoryReceipts?.nodes ||
                []);
        }
        catch (err) {
            this.$errorUtility({ err });
            this.$notification.add({
                text: 'An error occurred during the fetching of data',
                type: AlertType.ERROR
            });
        }
    }
    formatDueBackHardware(receipt, index) {
        const _asBuiltNumber = this.$asBuiltNumberDisplay(receipt?.asBuiltNumber);
        const _description = receipt.itemInstanceCrossContext?.description || '';
        const _expectedReturnDate = this.$dateDisplay(receipt.expectedReturnDate);
        const _issueDate = this.$dateDisplay(receipt.createdDateTime);
        const _lotNumber = this.$lotNumberDisplay(receipt?.itemInstance?.lotNumber);
        const _quantity = receipt.quantity?.toString() || '0';
        const _itemNumber = this.$itemNumberDisplay(receipt.itemInstance);
        const _serialNumber = this.$serialNumberDisplay(receipt.itemInstance?.serialNumber);
        const _side = this.$sideDisplay(receipt?.side);
        const _size = this.$sizeDisplay(receipt?.itemInstance?.size);
        const currentLocationOrganizationName = receipt.locationCrossContext?.organization?.name || DEFAULT_DASH;
        const inventoryId = receipt.inventory?.id || null;
        const inventoryNodeId = receipt.inventory?.nodeId;
        const itemInstanceId = receipt.itemInstance?.id || receipt?.itemInstanceId;
        const itemInstanceNodeId = receipt.itemInstance?.nodeId;
        const _unitCost = this.$currencyDisplay(receipt.unitCost);
        return Object.assign({}, receipt, {
            _asBuiltNumber,
            _description,
            _expectedReturnDate,
            _issueDate,
            _lotNumber,
            _quantity,
            _serialNumber,
            _side,
            _size,
            _itemNumber,
            _unitCost,
            currentLocationOrganizationName,
            index,
            inventoryId,
            inventoryNodeId,
            itemInstanceId,
            itemInstanceNodeId
        });
    }
    formatOpenCrossContextReceipt(receipt) {
        const _asBuiltNumber = this.$asBuiltNumberDisplay(receipt?.asBuiltNumber);
        const _expectedReturnDate = this.$dateDisplay(receipt?.inventory?.expectedReturnDate);
        const inventoryId = receipt.inventoryId || null;
        const _issueDate = this.$dateDisplay(receipt.initialReceiptDate);
        const _lotNumber = this.$lotNumberDisplay(receipt?.itemInstanceCrossContext?.lotNumber);
        const _quantity = receipt?.quantity?.toString() || '0';
        const _serialNumber = this.$serialNumberDisplay(receipt?.itemInstanceCrossContext?.serialNumber);
        const _itemNumber = this.$itemNumberDisplay(receipt?.itemInstanceCrossContext);
        const _issuedFromOrg = receipt?.supportOrganizationCode || 'UNKNOWN';
        const itemInstanceId = receipt?.itemInstance?.id || receipt?.itemInstanceId;
        const itemInstanceNodeId = receipt.itemInstance?.nodeId;
        const _side = this.$sideDisplay(receipt?.itemInstanceCrossContext?.side);
        const _size = this.$sizeDisplay(receipt?.itemInstanceCrossContext?.size);
        const _unitCost = this.$currencyDisplay(receipt.unitCost);
        return Object.assign({}, receipt, {
            _asBuiltNumber,
            _expectedReturnDate,
            _issueDate,
            _lotNumber,
            _quantity,
            _serialNumber,
            _side,
            _size,
            _itemNumber,
            _unitCost,
            _issuedFromOrg,
            inventoryId,
            itemInstanceId,
            itemInstanceNodeId
        });
    }
};
DueInMixin = __decorate([
    Component
], DueInMixin);
export default DueInMixin;
