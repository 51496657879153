import { isEmpty } from 'lodash';
import groupBy from 'lodash/groupBy';
import qs from 'qs';
import { InventoryMethod, Side } from '~/db_types';
import { AssemblyTemplateType } from '~/db_types/swagger_types';
import { DB_DEFAULT, DEFAULT_DASH, LOC_DB_DEFAULT } from '~/nasa_ui/constants';
import { EntityType, EntityTypeDisplayMap, EntityTypeSingularDisplay } from '~/nasa_ui/types';
import { AuthorityCodeDisplay } from '~/nasa_ui/types/enums/authority-codes';
import { FederalSupplyClassFCSRef } from '~/nasa_ui/types/enums/federal-supply-class';
import { InventoryMethodDisplay, InventoryUnitTypeAbbreviationDisplay, SideDisplay } from '~/nasa_ui/types/enums/hardware';
import { ProjectCodeDisplay } from '~/nasa_ui/types/enums/project-code';
import { buildNotOkIcon, buildNullUndefinedIcon, buildOkIcon } from '../ui/buildIconAsString';
import { isNullOrUndefined } from './isNullOrUndefined';
import { stripHtmlFromString } from './stripHtmlFromString';
export const authorityDisplayString = (input) => {
    if (!input.authorityCode) {
        return `${input.authorityNumber || DEFAULT_DASH}`;
    }
    return `${AuthorityCodeDisplay.get(input.authorityCode)}: ${input.authorityNumber || DEFAULT_DASH}`;
};
export const numberDisplay = (input) => {
    if (input === null || input === undefined) {
        return '0';
    }
    if (typeof input === 'string') {
        input = parseInt(input);
    }
    return new Intl.NumberFormat('en-US').format(input);
};
export const asBuiltNumberDisplay = (asBuilt) => {
    return asBuilt ? (asBuilt === DB_DEFAULT ? DEFAULT_DASH : asBuilt) : DEFAULT_DASH;
};
export const asDesignNumberDisplay = (asDesign) => {
    return asDesign ? (asDesign === DB_DEFAULT ? DEFAULT_DASH : asDesign) : DEFAULT_DASH;
};
export const binDisplay = (bin) => {
    return bin ? (bin === LOC_DB_DEFAULT ? bin : bin) : DEFAULT_DASH;
};
export const booleanIconDisplay = (bool, title) => {
    if (bool === null || bool === undefined) {
        return buildNullUndefinedIcon(title);
    }
    return bool ? buildOkIcon(title) : buildNotOkIcon(title);
};
export const contactDisplay = (contact) => {
    if (!contact) {
        return DEFAULT_DASH;
    }
    // has no names to display
    if (!contact.firstName && !contact.lastName && !contact.auid && (!contact.email || !contact.email?.length)) {
        return DEFAULT_DASH;
    }
    // has full name
    if (contact.firstName && contact.lastName) {
        return `${contact.firstName} ${contact.lastName}`;
    }
    // has last name
    if (contact.lastName) {
        return contact.lastName;
    }
    // has first name
    if (contact.firstName) {
        return contact.firstName;
    }
    // is cosmic and has no name
    if (contact.firstName?.toLowerCase() === 'cosmic' || contact.lastName?.toLowerCase() === 'cosmic') {
        return 'COSMIC';
    }
    // has auid
    if (contact.auid) {
        return contact.auid;
    }
    if (contact.email && contact.email[0]) {
        return contact.email[0];
    }
    // should be unreachable but idk why not
    return DEFAULT_DASH;
};
export const contactDisplayWithEmail = (contact) => {
    if (!contact) {
        return DEFAULT_DASH;
    }
    if (!contact.firstName && !contact.lastName) {
        return DEFAULT_DASH;
    }
    function strBuildAnchorLinks(emailAddress) {
        return emailAddress
            .map((email) => {
            return `<br/><a :href="mailto:${email}"><small>${email}</small></a>`;
        })
            .join('');
    }
    return `${contact.firstName ? contact.firstName : ''} ${contact.lastName ? contact.lastName : ''}${contact.email ? strBuildAnchorLinks(contact.email) : ''}`;
};
export const roomDisplay = (room) => {
    return room ? room : DEFAULT_DASH;
};
export const stockDisplay = (stock) => {
    return stock ? (stock === LOC_DB_DEFAULT ? stock : stock) : DEFAULT_DASH;
};
export const buildingDisplay = (building) => {
    return building ? (building === LOC_DB_DEFAULT ? building : building) : DEFAULT_DASH;
};
export const serialNumberDisplay = (sn) => {
    return sn ? (sn === DB_DEFAULT ? DEFAULT_DASH : sn) : DEFAULT_DASH;
};
export const sideDisplay = (side) => {
    return side && side !== 'NONE' ? SideDisplay.get(side) || DEFAULT_DASH : DEFAULT_DASH;
};
export const sizeDisplay = (size) => {
    return size ? (size === DB_DEFAULT ? DEFAULT_DASH : size) : DEFAULT_DASH;
};
export const sizesDisplay = (sizes) => {
    return sizes?.length ? sizes.map((s) => (s === DB_DEFAULT ? DEFAULT_DASH : s)).join(', ') : DEFAULT_DASH;
};
export const lotNumberDisplay = (ln) => {
    return ln ? (ln === DB_DEFAULT ? DEFAULT_DASH : ln) : DEFAULT_DASH;
};
/**
 * Transform a FSC item to correctly display as needed on dropdown forms
 *
 * @param value FederalSupplyClass
 * @param key Text description of FSC Item
 * @returns string
 */
export const fscDisplay = (value, key) => {
    const prepend = FederalSupplyClassFCSRef.get(value) || '';
    const display = `${prepend}: ${key}`;
    return display;
};
export const AssemblyTemplateTreeDisplay = new Map([
    [EntityType.ASSEMBLY, 'Assembly'],
    [EntityType.ASSEMBLY_TEMPLATE, 'Assembly Template'],
    [AssemblyTemplateType.EVENT_ASSEMBLY, 'Event Assembly']
]);
export const assemblyTreeDisplay = (assemblyTreeType) => {
    if (!assemblyTreeType) {
        console.error('assemblyTreeType not passing to $assemblyTreeDisplay');
        return DEFAULT_DASH;
    }
    return AssemblyTemplateTreeDisplay.get(assemblyTreeType) || DEFAULT_DASH;
};
/**
 * Pass entityType/subType combo or just entityType and get the display value back
 *
 * @param entityType EntityType
 * @param subType string
 */
export const typeDisplay = (entityType, subType) => {
    if (!entityType) {
        console.error('entityType not passing to $typeDisplay');
        return DEFAULT_DASH;
    }
    if (!subType) {
        return EntityTypeSingularDisplay.get(entityType) || DEFAULT_DASH;
    }
    const subTypeMap = EntityTypeDisplayMap.get(entityType);
    if (!subTypeMap) {
        return DEFAULT_DASH;
    }
    return subTypeMap.get(subType) || DEFAULT_DASH;
};
/**
 *  ID/IM partNumber display
 *
 * @param hardware IPartNumberDisplayInput
 * @returns string
 */
export const partNumberDisplay = (hardware) => {
    if (!hardware) {
        console.log('Hardware not passing to partNumberDisplay');
        return DEFAULT_DASH;
    }
    const drawingNumber = hardware.drawingNumber ||
        hardware.itemDrawing?.drawingNumber ||
        hardware.itemMaster?.drawingNumber ||
        hardware.itemInstance?.drawingNumber ||
        hardware.inventory?.drawingNumber;
    const asBuiltNumber = hardware.asBuiltNumber || hardware.itemMaster?.asBuiltNumber;
    if (asBuiltNumber && asBuiltNumber !== DB_DEFAULT) {
        return `${drawingNumber}-${asBuiltNumber}`;
    }
    // If no drawing number is passed anywhere
    if (!drawingNumber) {
        return DEFAULT_DASH;
    }
    return `${drawingNumber}`;
};
/**
 * This is an II level display
 *
 * @param hardware IItemNumberDisplay
 * @returns {string}
 */
export const itemNumberDisplay = (hardware) => {
    if (!hardware) {
        console.log('Hardware not passing to itemNumberDisplay');
        return DEFAULT_DASH;
    }
    const hideSide = hardware.side === Side.NONE || !hardware.side;
    const sideSlug = hideSide ? '' : `[${sideDisplay(hardware.side)}]`;
    const hideSize = hardware.size === null || hardware.size === undefined || sizeDisplay(hardware.size) === DEFAULT_DASH;
    const sizeSlug = hideSize ? '' : `[${sizeDisplay(hardware.size)}]`;
    const partNumber = partNumberDisplay(hardware);
    if ((!partNumber || partNumber === DEFAULT_DASH) && hideSide && hideSize) {
        return DEFAULT_DASH;
    }
    return `${partNumber}${sideSlug}${sizeSlug}`;
};
/**
 * Pass location and get the location display back, can optionally hide organization code or display as HTML
 *
 * @param location ILocationDisplay - The location to display
 * @param hideOrganizationCode Boolean - Show the associated organization code
 * @param asString Boolean - Display the returning element as a string without HTML tags
 */
export const locationDisplay = (location, hideOrganizationCode, asString, noWrap) => {
    if (!location) {
        return DEFAULT_DASH;
    }
    let building = location.building || DEFAULT_DASH;
    let stock = location.stock || DEFAULT_DASH;
    let bin = location.bin || DEFAULT_DASH;
    let _locSlug = '';
    // if building/bin === 'UNKNOWN' ONLY display 'Unknown'
    if (building === 'UNKNOWN' && bin === 'UNKNOWN') {
        _locSlug = 'Unknown';
    }
    // if building/stock/bin === DEFAULT ONLY display 'Default'
    if (building === DB_DEFAULT && stock === DB_DEFAULT && bin === DB_DEFAULT) {
        _locSlug = 'Default';
    }
    // if building/stock/bin === GENERAL ONLY display 'General'
    if (building === LOC_DB_DEFAULT && stock === LOC_DB_DEFAULT && bin === LOC_DB_DEFAULT) {
        _locSlug = 'General';
    }
    const regexForUnknown = /UNKNOWN/gi;
    const regexForDefault = /DEFAULT/gi;
    //   if building/stock/bin === UNKNOWN replace UNKNOWN with ?
    //   if building/stock/bin === DEFAULT replace DEFAULT with -
    building = building.replace(regexForUnknown, '?').replace(regexForDefault, DEFAULT_DASH);
    stock = stock.replace(regexForUnknown, '?').replace(regexForDefault, DEFAULT_DASH);
    bin = bin.replace(regexForUnknown, '?').replace(regexForDefault, DEFAULT_DASH);
    if (_locSlug === '') {
        _locSlug = `${building}/${stock}/${bin}`;
    }
    if (hideOrganizationCode === undefined || hideOrganizationCode === null) {
        // Most often we WANT to display it
        hideOrganizationCode = false;
    }
    const organizationCode = hideOrganizationCode || !location.organizationCode || location.organizationCode === 'UNKNOWN'
        ? ''
        : `<strong>${location.organizationCode}</strong>: `;
    const noWrapClass = isNullOrUndefined(noWrap) || noWrap ? 'no-wrap' : '';
    return asString
        ? stripHtmlFromString(`${organizationCode}${_locSlug}`)
        : `<span class="${noWrapClass}">${organizationCode}${_locSlug}</span>`;
};
/**
 * Pass multiple locations and get the location display back in a list, can optionally hide organization code
 *
 * @param locations ILocationDisplay[]
 * @param options ILocationsDisplayOptions
 */
export const multipleLocationDisplay = (locations, options = {
    asString: false,
    noWrap: true,
    showInventory: false,
    userCtx: null
}) => {
    if (!locations) {
        return DEFAULT_DASH;
    }
    const listStart = options?.asString ? '' : '<ul class="list-style-none">';
    const list = [];
    const listEnd = options?.asString ? '' : '</ul>';
    locations?.forEach((location) => {
        const locationDisplayValue = locationDisplay(location, null, options?.asString, options.noWrap);
        if (options?.showInventory && options?.userCtx) {
            const _queryVariables = {
                building: location.building,
                stock: location.stock,
                bin: location.bin,
                autoExecute: true,
                supportContext: options?.userCtx
            };
            const _queryString = qs.stringify({ ..._queryVariables }, { indices: false });
            const linkURL = `/reporting/inventory?${_queryString}`;
            list.push(options?.asString
                ? `${locationDisplayValue} (${location?.quantity})`
                : `<a href=${linkURL} target="_blank"><li>${locationDisplayValue} (${location?.quantity})</li></a>`);
        }
        else {
            list.push(options?.asString ? locationDisplayValue : `<li>${locationDisplayValue}\n</li>`);
        }
    });
    list.sort();
    if (list.length === 0) {
        return DEFAULT_DASH;
    }
    return `${listStart}${list.join('')}${listEnd}`;
};
export const currencyDisplay = (num, defaultReturnValue = DEFAULT_DASH) => {
    if (num === null || num === undefined) {
        return defaultReturnValue;
    }
    if (typeof num === 'string') {
        num = parseFloat(num);
    }
    return num.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};
export const decimalToPercent = (decimalValue) => {
    // Multiply by 100 to get the percentage
    const percentValue = decimalValue * 100;
    // Format the result as a string with "%" sign
    return `${percentValue.toFixed(2)} &percnt;`;
};
export const projectCodeDisplay = (projectCode) => {
    if (!projectCode || isEmpty(projectCode)) {
        return DEFAULT_DASH;
    }
    return ProjectCodeDisplay.get(projectCode) || DEFAULT_DASH;
};
export const transformHtmlLineBreak = (string) => {
    return string.replace(/<br\s*[/]?>/gi, '\n');
};
export const quantityDisplay = (value, options) => {
    if (value === null || value === undefined) {
        return DEFAULT_DASH;
    }
    return Intl.NumberFormat('en-US', options).format(Number(value));
};
export const quantityPlusMethodDisplay = (quantity, itemMaster) => {
    const isMethodUnit = itemMaster?.inventoryMethod === InventoryMethod.UNIT;
    const value = quantityDisplay(quantity || 0);
    const methodDisplay = itemMaster?.inventoryMethod ? InventoryMethodDisplay.get(itemMaster?.inventoryMethod) : '';
    const unitTypeDisplay = isMethodUnit && itemMaster?.inventoryUnitType
        ? InventoryUnitTypeAbbreviationDisplay.get(itemMaster?.inventoryUnitType)
        : '';
    return `${value} ${isMethodUnit ? unitTypeDisplay : methodDisplay}`;
};
export const stringListDisplay = (stringList) => {
    if (!stringList?.length) {
        return DEFAULT_DASH;
    }
    const items = stringList.map((value) => `<li>${value}</li>`);
    return `<ul>${items.join('')}</ul>`;
};
export const currentLocationsGroupedByProjectCode = (inventories, userContext, pdfExport = false) => {
    const groupedByProjectCode = groupBy(inventories, 'projectCode');
    return Object.keys(groupedByProjectCode).map((projectCode) => {
        const locations = groupedByProjectCode[projectCode]
            .map((item) => {
            return { ...item?.location, quantity: item?.quantity };
        })
            .filter((location) => Boolean(location.quantity));
        projectCode = projectCode && projectCode !== 'null' ? projectCode : 'No Project Code';
        // Let's setup _currentLocations to
        // be used  on web-version of AHDs.
        const _currentLocationsAsWeb = locations
            ? multipleLocationDisplay(locations, { showInventory: true, userCtx: userContext })
            : DEFAULT_DASH;
        let _currentLocationsAsPdf = [];
        if (pdfExport) {
            // If this is to be used on an PDF AHD export
            // then we'll map through each location and add
            // a unicode tab 'space' along with using `multipleLocationDisplay()`
            _currentLocationsAsPdf = locations
                ? locations
                    .map((location) => `\u200B\t ${multipleLocationDisplay([location], {
                    showInventory: true,
                    userCtx: userContext,
                    asString: true
                })}`)
                    .sort()
                : null;
        }
        return {
            projectCode,
            locations,
            _currentLocations: pdfExport ? _currentLocationsAsPdf : _currentLocationsAsWeb
        };
    });
};
export const drawingAsBuiltSideFormat = (itemMasterInformation) => {
    const { drawingNumber, asBuiltNumber, side } = itemMasterInformation;
    return `${drawingNumber || ''}${asBuiltNumber || ''}${side || ''}`.toLowerCase();
};
