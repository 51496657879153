import parseISO from 'date-fns/parseISO';
/**
 * Removes time specificity from a Date object. Does not mutate the object
 * passed in.
 *
 * @param date Date object or ISO string to act on.
 * @returns A new Date object with the time reset to 00:00:00.
 */
export const removeTime = (date) => {
    if (typeof date === 'string') {
        date = parseISO(date);
    }
    if (typeof date === 'number') {
        date = new Date(date);
    }
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};
