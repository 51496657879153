import { addDateDisplays, addUserDisplays } from '../displayMappers';
export const transformEmailNotifications = (items) => {
    return items
        .map(addUserDisplays)
        .map(addDateDisplays)
        .map((item) => {
        const recipientHtml = (item.recipients || []).map((recipient) => {
            return `
        <li>
          ${recipient.name} (<small class="monospace_font">${recipient.email}</small>)
        </li>
      `;
        });
        item._recipients = ['<ul class="list-style-none pl-0">', ...recipientHtml, '</ul>'].join('');
        return item;
    });
};
