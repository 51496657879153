var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { ChangeSetStatus, ChangeSetType, CosmicChangeSetFragment } from '~/db_types';
import BaseVue from '~/nasa_ui/base/BaseVue';
import { ChangeSetsRepoMixin } from '~/nasa_ui/DAL/changeSet';
import { EntityType } from '~/nasa_ui/types';
import { isNullOrUndefined } from '~/nasa_ui/utils/helpers/isNullOrUndefined';
import { transformChangeSets } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let SummaryCardChangeSet = class SummaryCardChangeSet extends Mixins(BaseVue, ChangeSetsRepoMixin) {
    ChangeSetType = ChangeSetType;
    ChangeSetStatus = ChangeSetStatus;
    changeSetId;
    changeSet;
    localChangeSet = null;
    get transformedChangeSet() {
        return this.changeSetId ? transformChangeSets([this.localChangeSet])[0] : transformChangeSets([this.changeSet])[0];
    }
    get hasAuthority() {
        return Boolean(this.transformedChangeSet?.authorityCode) || Boolean(this.transformedChangeSet?.authorityNumber);
    }
    get isAutoApproved() {
        return (!this.transformedChangeSet?.changeSetAuthorities?.nodes.length &&
            this.transformedChangeSet?.computedStatus === ChangeSetStatus.APPROVED);
    }
    get isDocumentRelated() {
        return Boolean(this.transformedChangeSet?.documentId);
    }
    get isHardwareRelated() {
        return (Boolean(this.transformedChangeSet?.itemDrawing) ||
            Boolean(this.transformedChangeSet?.itemMaster) ||
            Boolean(this.transformedChangeSet?.itemInstance));
    }
    get hardwareIconClass() {
        if (!this.isHardwareRelated) {
            return '';
        }
        if (this.transformedChangeSet?.itemDrawing) {
            return this.$icons[EntityType.ITEM_DRAWING];
        }
        if (this.transformedChangeSet?.itemMaster) {
            return this.$icons[EntityType.ITEM_MASTER];
        }
        if (this.transformedChangeSet?.itemInstance) {
            return this.$icons[EntityType.ITEM_INSTANCE];
        }
        return '';
    }
    get getDocument() {
        return this.transformedChangeSet?._document || null;
    }
    get documentIconClass() {
        const document = this.getDocument;
        if (!this.isDocumentRelated || !document) {
            return '';
        }
        const subTypeIcon = this.$icons[document.subType];
        const defaultIcon = this.$icons[EntityType.DOCUMENT];
        if (!document.subType || subTypeIcon === undefined) {
            return defaultIcon;
        }
        return subTypeIcon;
    }
    async onChangeSetIdChange(changeSetId) {
        if (!isNullOrUndefined(changeSetId)) {
            const resp = await this.changeSetById({ id: changeSetId });
            // API v2
            // this.localChangeSet = resp.data;
            this.localChangeSet = resp?.changeSetById;
        }
    }
};
__decorate([
    Prop({
        type: Number,
        required: false
    }),
    __metadata("design:type", Object)
], SummaryCardChangeSet.prototype, "changeSetId", void 0);
__decorate([
    Prop({
        required: false,
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof CosmicChangeSetFragment !== "undefined" && CosmicChangeSetFragment) === "function" ? _a : Object)
], SummaryCardChangeSet.prototype, "changeSet", void 0);
__decorate([
    Watch('changeSetId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", Promise)
], SummaryCardChangeSet.prototype, "onChangeSetIdChange", null);
SummaryCardChangeSet = __decorate([
    Component
], SummaryCardChangeSet);
export default SummaryCardChangeSet;
