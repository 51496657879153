var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', _vm._g({}, _vm.$listeners), [_vm.isNotDefined ? _c('c-icon-fa', {
    attrs: {
      "icon-size": _vm.iconSize,
      "icon": "fad fa-question-circle"
    }
  }) : _vm.isTruthy ? _c('c-icon-fa', {
    attrs: {
      "icon-color": _vm.greyscale ? 'black' : 'var(--v-success-base)',
      "icon-size": _vm.iconSize,
      "icon": "fad fa-check-circle"
    }
  }) : _vm.isFalsy ? _c('c-icon-fa', {
    attrs: {
      "icon-color": _vm.greyscale ? 'black' : 'var(--v-error-base)',
      "icon-size": _vm.iconSize,
      "icon": "fad fa-times-circle"
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };