import { filter, flatten, get, has, isEmpty, isObject, isString, uniq } from 'lodash';
export default {
    install(Vue) {
        Object.defineProperties(Vue.prototype, {
            $get: { value: get },
            $has: { value: has },
            $filter: { value: filter },
            $flatten: { value: flatten },
            $isObject: { value: isObject },
            $isString: { value: isString },
            $isEmpty: { value: isEmpty },
            $uniq: { value: uniq }
        });
    }
};
