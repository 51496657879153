import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins } from 'vue-property-decorator';
import LoaderMixin from '~/nasa_ui/mixins/LoaderMixin';
import { queryFactory } from '../base/mixinFactories';
import { CosmicLocationFragment } from '../location';
import { CosmicContractSlimFragment } from './slim';
export const CosmicContractWithLocationsFragment = gql `
  ${CosmicContractSlimFragment}
  ${CosmicLocationFragment}

  fragment CosmicContractWithLocations on Contract {
    ...CosmicContractSlim

    locations(orderBy: ORGANIZATION_CODE_DESC) {
      nodes {
        ...CosmicLocation
      }
    }
  }
`;
export const GetSlimContractWithLocationsByNodeId = gql `
  ${CosmicContractWithLocationsFragment}

  query GetSlimContractWithLocationsByNodeId($nodeId: ID!) {
    contract(nodeId: $nodeId) {
      ...CosmicContractWithLocations
    }
  }
`;
export const GetSlimContractByNodeId = gql `
  ${CosmicContractSlimFragment}

  query GetSlimContractByNodeId($nodeId: ID!) {
    contract(nodeId: $nodeId) {
      ...CosmicContractSlim
    }
  }
`;
export const GetAllContracts = gql `
  ${CosmicContractSlimFragment}

  query GetAllContracts {
    contracts(orderBy: [NUMBER_ASC]) {
      nodes {
        ...CosmicContractSlim
      }
    }
  }
`;
let ContractRepoMixin = class ContractRepoMixin extends Mixins(LoaderMixin) {
    getSlimContractWithLocationsByNodeId = queryFactory(this, GetSlimContractWithLocationsByNodeId);
    getSlimContractByNodeId = queryFactory(this, GetSlimContractByNodeId);
    getAllContracts = queryFactory(this, GetAllContracts);
};
ContractRepoMixin = __decorate([
    Component
], ContractRepoMixin);
export { ContractRepoMixin };
