import gql from 'graphql-tag';
export const CosmicItemInstanceSlimFragment = gql `
  fragment CosmicItemInstanceSlim on ItemInstance {
    asBuiltNumber
    asDesignNumber
    birthDate
    drawingNumber
    equipmentControlNumber
    id
    initializationStatus
    inventoryQuantity
    isArchived
    itemDrawingDescription
    lotNumber
    nodeId
    pdaDate
    projectCodes
    serialNumber
    side
    size
    subType
  }
`;
export const CosmicItemInstanceFragment = gql `
  ${CosmicItemInstanceSlimFragment}

  fragment CosmicItemInstance on ItemInstance {
    ...CosmicItemInstanceSlim
    computedHazardousMaterialText
    computedLastInspectionDate
    computedLastServiceDate
    computedSoftwareVersion
    equipmentControlNumber
    hasLimitedLifeErrors
    hasLimitedLifeProperties
    inheritLimitedLifeFromMaster
    itemMasterSubType
    projectCodes
  }
`;
