import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component } from 'vue-property-decorator';
import BaseMini from '~/nasa_ui/base/BaseMini';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { CosmicContractWithLocationsFragment as CosmicContractWithLocationsFragmentOverride } from '~/nasa_ui/DAL/contract';
import { EntityType } from '~/nasa_ui/types';
let MiniContract = class MiniContract extends BaseMini {
    entityType = EntityType.CONTRACT;
    localEntity = null;
    BASE_FETCH = this.nodeId
        ? gql `
        ${CosmicContractWithLocationsFragmentOverride}

        query MiniContractFetch($nodeId: ID!) {
          contract(nodeId: $nodeId) {
            ...CosmicContractWithLocations
          }
        }
      `
        : gql `
        ${CosmicContractWithLocationsFragmentOverride}

        query MiniContractByNumberFetch($number: String!) {
          contractByNumber(number: $number) {
            ...CosmicContractWithLocations
          }
        }
      `;
    get manageLink() {
        return this.localEntity ? `/${EntityType.CONTRACT}/manage/${this.localEntity.number}` : '';
    }
    get name() {
        if (!this.localEntity && !this.isFetching) {
            return 'Not found';
        }
        return this.localEntity?.name || this.localEntity?.number || DEFAULT_DASH;
    }
    get startDateDisplay() {
        return this.$dateDisplay(this.localEntity?.startDate);
    }
    get endDateDisplay() {
        return this.$dateDisplay(this.localEntity?.endDate);
    }
    get verticalLabelDisplayItems() {
        if (!this.localEntity) {
            return [];
        }
        return [
            {
                name: 'Number',
                value: this.localEntity.number || DEFAULT_DASH
            },
            {
                name: 'Start',
                value: this.$dateDisplay(this.localEntity.startDate)
            },
            {
                name: 'End',
                value: this.$dateDisplay(this.localEntity.endDate)
            }
        ];
    }
    onFetchUpdate(data) {
        if (data.contract || data.contractByNumber) {
            this.localEntity = data.contract || data.contractByNumber;
        }
    }
};
MiniContract = __decorate([
    Component
], MiniContract);
export default MiniContract;
