import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let AppLoader = class AppLoader extends Vue {
    size;
    center;
    color;
    type;
    show;
};
__decorate([
    Prop({ default: 32, type: Number }),
    __metadata("design:type", Number)
], AppLoader.prototype, "size", void 0);
__decorate([
    Prop({
        default: false
    }),
    __metadata("design:type", Boolean)
], AppLoader.prototype, "center", void 0);
__decorate([
    Prop({
        default: 'primary'
    }),
    __metadata("design:type", String)
], AppLoader.prototype, "color", void 0);
__decorate([
    Prop({
        default: 'circular'
    }),
    __metadata("design:type", String)
], AppLoader.prototype, "type", void 0);
__decorate([
    Prop({
        default: true
    }),
    __metadata("design:type", Boolean)
], AppLoader.prototype, "show", void 0);
AppLoader = __decorate([
    Component
], AppLoader);
export default AppLoader;
