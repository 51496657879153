import { isEmpty } from 'lodash';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
/**
 * Returns a displayable format for Period Responses.
 *
 * @param val PeriodResponse
 * @param absoluteValue
 */
export const periodResponseDisplay = (val, absoluteValue = false) => {
    const intervalAbbr = new Map([['months', 'M']]);
    if (!val || isEmpty(val)) {
        return DEFAULT_DASH;
    }
    // The items we care to display
    const ordered = {};
    if (val.years) {
        ordered.years = absoluteValue ? Math.abs(val.years) : val.years;
    }
    if (val.months) {
        ordered.months = absoluteValue ? Math.abs(val.months) : val.months;
    }
    if (val.days) {
        ordered.days = absoluteValue ? Math.abs(val.days) : val.days;
    }
    if (val.hours) {
        ordered.hours = absoluteValue ? Math.abs(val.hours) : val.hours;
    }
    if (val.minutes) {
        ordered.minutes = absoluteValue ? Math.abs(val.minutes) : val.minutes;
    }
    if (val.seconds) {
        ordered.seconds = absoluteValue ? Math.abs(val.seconds) : val.seconds;
    }
    return Object.entries(ordered)
        .filter((item) => item[0] !== '__typename')
        .filter((item) => item[1] !== null)
        .map((item) => `${item[1]}${intervalAbbr.get(item[0]) || item[0].substr(0, 1)}`)
        .join(' ');
};
