import { AlertType } from '~/nasa_ui/types';
export const httpGetFactory = (context) => {
    return async (url, message = null, config = null) => {
        context.isLoading = true;
        try {
            const _config = { ...config };
            const resp = await context.$http.get(url, _config);
            return resp;
        }
        catch (err) {
            context.$errorUtility({ err, backupErrorText: message?.error });
            throw err;
        }
        finally {
            context.isLoading = false;
        }
    };
};
export const httpPostFactory = (context, url) => {
    return async (body, message, axiosConfig) => {
        context.isSaving = true;
        try {
            const resp = await context.$http.post(url, body, axiosConfig);
            if (message?.success) {
                context.$notification.add({ type: AlertType.SUCCESS, text: message.success });
            }
            return resp;
        }
        catch (err) {
            context.$errorUtility({ err, backupErrorText: message?.error });
            throw err;
        }
        finally {
            context.isSaving = false;
        }
    };
};
export const httpPutFactory = (context, url) => {
    return async (body, message, axiosConfig) => {
        context.isSaving = true;
        try {
            const resp = await context.$http.put(url, body, axiosConfig);
            if (message?.success) {
                context.$notification.add({ type: AlertType.SUCCESS, text: message.success });
            }
            return resp;
        }
        catch (err) {
            context.$errorUtility({ err, backupErrorText: message?.error });
            throw err;
        }
        finally {
            context.isSaving = false;
        }
    };
};
export const httpDeleteFactory = (context) => {
    return async (url, message = null, config = null) => {
        context.isLoading = true;
        try {
            const _config = { ...config };
            const resp = await context.$http.delete(url, _config);
            if (message?.success) {
                context.$notification.add({ type: AlertType.SUCCESS, text: message.success });
            }
            return resp;
        }
        catch (err) {
            context.$errorUtility({ err, backupErrorText: message?.error });
            throw err;
        }
        finally {
            context.isLoading = false;
        }
    };
};
