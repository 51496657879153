var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop } from 'vue-property-decorator';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { Maybe } from '~/nasa_ui/types';
let MenuMyApprovals = class MenuMyApprovals extends BaseCosmic {
    isOpen = false;
    approvals;
    approvalsLimit;
    approvalsReportUrl;
    badgeCount;
    isError;
    isLoading;
    get computedApprovals() {
        return this.approvals.slice(0, this.approvalsLimit);
    }
    // the number of urgent approvals beyond the limit
    get extraApprovals() {
        return this.approvals.length > this.approvalsLimit ? this.approvals.length - this.approvalsLimit : 0;
    }
    onClickOfRefresh() { }
};
__decorate([
    Prop({
        type: Array,
        default: () => []
    }),
    __metadata("design:type", Array)
], MenuMyApprovals.prototype, "approvals", void 0);
__decorate([
    Prop({
        type: Number,
        default: 5
    }),
    __metadata("design:type", Number)
], MenuMyApprovals.prototype, "approvalsLimit", void 0);
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", String)
], MenuMyApprovals.prototype, "approvalsReportUrl", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], MenuMyApprovals.prototype, "badgeCount", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], MenuMyApprovals.prototype, "isError", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], MenuMyApprovals.prototype, "isLoading", void 0);
__decorate([
    Emit('refresh'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MenuMyApprovals.prototype, "onClickOfRefresh", null);
MenuMyApprovals = __decorate([
    Component
], MenuMyApprovals);
export default MenuMyApprovals;
