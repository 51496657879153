import { __decorate } from "tslib";
import qs from 'qs';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import { UserSupportContextToValidItemMasterTypes } from '~/application.config';
import { ChangeSetStatus, FederalSupplyClass, InventoryControl, InventoryStatus, ItemMasterType, Side } from '~/db_types';
import { ItemMasterPropertyClass } from '~/db_types/swagger_types';
import DueInMixin from '~/mixins/DueInMixin';
import BaseView from '~/nasa_ui/base/BaseView';
import { ChangeSetStatusDisplay, EquipmentGroupDisplay, FederalSupplyClassDisplay, InventoryControlDisplay, InventoryStatusDisplay, ItemClassesWithoutMultiple, ItemInstanceInventoryTypeDisplay, ItemMasterPropertyClassDisplay, SideDisplay, UserPermission } from '~/nasa_ui/types';
import { stripNullProperties } from '~/nasa_ui/utils/helpers/stripNullProperties';
const RefurbExpendSelectOptions = [
    {
        displayText: 'Refurbishable',
        value: true
    },
    {
        displayText: 'Expendable',
        value: false
    }
];
let HomeView = class HomeView extends mixins(BaseView, DueInMixin) {
    ChangeSetStatus = ChangeSetStatus;
    ChangeSetStatusDisplay = ChangeSetStatusDisplay;
    EquipmentGroupDisplay = EquipmentGroupDisplay;
    FederalSupplyClass = FederalSupplyClass;
    FederalSupplyClassDisplay = FederalSupplyClassDisplay;
    InventoryControl = InventoryControl;
    InventoryControlDisplay = InventoryControlDisplay;
    InventoryStatus = InventoryStatus;
    InventoryStatusDisplay = InventoryStatusDisplay;
    isFetching = false;
    ItemClassesWithoutMultiple = ItemClassesWithoutMultiple;
    ItemInstanceInventoryTypeDisplay = ItemInstanceInventoryTypeDisplay;
    ItemMasterPropertyClass = ItemMasterPropertyClass;
    ItemMasterPropertyClassDisplay = ItemMasterPropertyClassDisplay;
    ItemMasterType = ItemMasterType;
    RefurbExpendSelectOptions = RefurbExpendSelectOptions;
    Side = Side;
    SideDisplay = SideDisplay;
    formData = {
        asBuiltNumber: null,
        bin: null,
        building: null,
        class: null,
        drawingNumber: null,
        equipmentGroup: null,
        federalSupplyClass: null,
        hasCeiNumber: null,
        hasChildrenInstalled: null,
        hasManagedQuantity: null,
        hasQuantity: true,
        hasUnmanagedQuantity: null,
        includeArchived: null,
        includeDrawings: true,
        includeInstances: true,
        includeInventory: true,
        includeMasters: true,
        initializationStatus: null,
        inventoryControl: null,
        isHazardousMaterial: null,
        isInstalled: null,
        isManaged: null,
        isRefurbishable: null,
        lotNumber: null,
        projectCode: null,
        propertyClass: null,
        room: null,
        serialNumber: null,
        side: null,
        status: null,
        stock: null,
        take: 100
    };
    get computedItemMasterTypeSelectOptions() {
        const imTypes = UserSupportContextToValidItemMasterTypes.get(this.currentUserActiveSupportContext);
        return imTypes
            ? imTypes.map((imType) => {
                return {
                    displayText: EquipmentGroupDisplay.get(imType),
                    value: imType
                };
            })
            : [];
    }
    get searchResultsUrl() {
        const queryString = qs.stringify(stripNullProperties({ ...this.formData }));
        if (queryString.length) {
            // since we're coming from the filters page, tell the results page to do a fresh search
            return `/hardware/results?${queryString}`;
        }
        return '/hardware/results';
    }
    get shouldShowInTransitTable() {
        return (Boolean(this.inTransitTableItems.length) && this.currentUserHasPermission(UserPermission.ITEM_INSTANCE_ADMIN));
    }
    get shouldShowInventoryDueBackTable() {
        return (Boolean(this.inventoryDueBackItems.length) && this.currentUserHasPermission(UserPermission.ITEM_INSTANCE_ADMIN));
    }
    get rowsPerPageItems() {
        return [
            5,
            10,
            {
                text: '$vuetify.dataIterator.rowsPerPageAll',
                value: -1
            }
        ];
    }
    created() {
        this.$listen(`reload_due_back`, this.fetchInventoryDueBack);
        Promise.all([this.fetchInventoryDueBack(), this.fetchPendingInventoryReceipts()]);
    }
    onClickOfClearFilters() {
        this.formData = {
            asBuiltNumber: null,
            bin: null,
            building: null,
            class: null,
            drawingNumber: null,
            equipmentGroup: null,
            federalSupplyClass: null,
            hasCeiNumber: null,
            hasChildrenInstalled: null,
            hasManagedQuantity: null,
            hasQuantity: true,
            hasUnmanagedQuantity: null,
            includeArchived: null,
            includeDrawings: true,
            includeInstances: true,
            includeInventory: true,
            includeMasters: true,
            initializationStatus: null,
            inventoryControl: null,
            isHazardousMaterial: null,
            isInstalled: null,
            isManaged: null,
            isRefurbishable: null,
            lotNumber: null,
            projectCode: null,
            propertyClass: null,
            room: null,
            serialNumber: null,
            side: null,
            status: null,
            stock: null,
            take: 100
        };
    }
};
HomeView = __decorate([
    Component
], HomeView);
export default HomeView;
