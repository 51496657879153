import gql from 'graphql-tag';
export const CosmicItemMasterSlimFragment = gql `
  fragment CosmicItemMasterSlim on ItemMaster {
    asBuiltNumber
    availableQuantity
    createdDateTime
    drawingNumber
    initializationAuthorityNumber
    initializationCode
    initializationStatus
    inventoryControl
    inventoryMethod
    inventoryUnitType
    isArchived
    isApprovedAsDesign
    itemDrawingDescription
    federalSupplyClass
    nodeId
    propertyClass
    side
    subType
  }
`;
