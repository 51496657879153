import { getBlurbAboutCreate, getBlurbAboutManage, getBlurbAboutSearch } from '~/nasa_ui/router/utils';
import { EntityType, EntityTypeSingularDisplay, UserPermission } from '~/nasa_ui/types';
// These routes are nested under `/hardware/...` for no other reason
// than it was like that in og
export const AssemblyTemplateSharedRoutes = [
    {
        path: `/hardware/${EntityType.ASSEMBLY_TEMPLATE}/create`,
        name: 'AssemblyTemplateCreateView',
        component: () => import(
        /* webpackChunkName: "AssemblyTemplate" */ '../views/AssemblyTemplateCreateView/AssemblyTemplateCreateView.vue'),
        meta: {
            displayName: 'Assembly Template create',
            description: getBlurbAboutCreate(EntityTypeSingularDisplay.get(EntityType.ASSEMBLY_TEMPLATE)),
            permissions: [UserPermission.ASSEMBLY_TEMPLATE_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/${EntityType.ASSEMBLY_TEMPLATE}/manage`,
        redirect: {
            name: 'AssemblyTemplateSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.ASSEMBLY_TEMPLATE)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.ASSEMBLY_TEMPLATE)),
            permissions: [UserPermission.ASSEMBLY_TEMPLATE_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/${EntityType.ASSEMBLY_TEMPLATE}/manage/:id`,
        name: 'AssemblyTemplateManageView',
        component: () => import(
        /* webpackChunkName: "AssemblyTemplate" */ '../views/AssemblyTemplateManageView/AssemblyTemplateManageView.vue'),
        meta: {
            displayName: 'Assembly Template manage',
            description: getBlurbAboutManage(EntityTypeSingularDisplay.get(EntityType.ASSEMBLY_TEMPLATE)),
            permissions: [UserPermission.ASSEMBLY_TEMPLATE_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/${EntityType.ASSEMBLY_TEMPLATE}/search`,
        name: 'AssemblyTemplateSearchView',
        component: () => import(
        /* webpackChunkName: "AssemblyTemplate" */ '../views/AssemblyTemplateSearchView/AssemblyTemplateSearchView.vue'),
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.ASSEMBLY_TEMPLATE)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.ASSEMBLY_TEMPLATE)),
            permissions: [UserPermission.ASSEMBLY_TEMPLATE_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/${EntityType.ASSEMBLY_TEMPLATE}`,
        redirect: {
            name: 'AssemblyTemplateSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.ASSEMBLY_TEMPLATE)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.ASSEMBLY_TEMPLATE)),
            permissions: [UserPermission.ASSEMBLY_TEMPLATE_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    }
];
