const setWithEncoding = (key, value) => {
    const encoded = btoa(JSON.stringify(value));
    localStorage.setItem(key, encoded);
};
const getDecoded = (key) => {
    try {
        const lsValue = localStorage.getItem(key);
        if (!lsValue) {
            return null;
        }
        const decoded = atob(lsValue);
        return JSON.parse(decoded);
    }
    catch (error) {
        console.error(error);
        return null;
    }
};
const getItem = (key) => localStorage.getItem(key);
const setItem = (key, value) => localStorage.setItem(key, value);
const removeItem = (key) => localStorage.removeItem(key);
const clear = () => localStorage.clear();
export default {
    setWithEncoding,
    getDecoded,
    getItem,
    setItem,
    removeItem,
    clear
};
