import gql from 'graphql-tag';
export const CosmicOrganizationSlimFragment = gql `
  fragment CosmicOrganizationSlim on Organization {
    attributes
    code
    createdDateTime
    isActive
    isCageCode
    name
    nodeId
    subType
  }
`;
