import { DocumentType, LinkType } from '~/db_types';
import { ItemMasterType } from './db_types/swagger_types';
import { EntityType, getEnvironment, UserPermission } from './nasa_ui/types';
import { EsocApplication } from './nasa_ui/types/cosmic/paths';
import { CosmicSystem, CosmicUserSupportContext } from './nasa_ui/types/cosmic/system';
// Defining system config which is used for example by routing
export const COSMIC_SYSTEM = CosmicSystem.ESOC;
export const COSMIC_APPLICATION = EsocApplication.OG;
export const COSMIC_ENVIRONMENT = getEnvironment();
// Every valid support context for this application
export var UserSupportContext;
(function (UserSupportContext) {
    UserSupportContext["CTSD"] = "CTSD";
    UserSupportContext["EHP"] = "EHP";
    UserSupportContext["ER"] = "ER";
    UserSupportContext["ES"] = "ES";
    UserSupportContext["ESOC"] = "ESOC";
    UserSupportContext["EV"] = "EV";
    UserSupportContext["xEMU"] = "xEMU";
})(UserSupportContext || (UserSupportContext = {}));
// "Tracking" here means we are not the source of truth
const allTrackingDocumentTypes = [
    DocumentType.ACCEPTANCE_DATA_PACK,
    DocumentType.BARCODE_DECLARATION,
    DocumentType.CONFIGURATION_CHANGE_ORDER,
    DocumentType.CR,
    DocumentType.DISCREPANCY_REPORT,
    DocumentType.DRAWING,
    DocumentType.EASY_TRANSFER,
    DocumentType.ELABEL,
    DocumentType.ELROD,
    DocumentType.ENGINEERING_CHANGE,
    DocumentType.FAILURE_INVESTIGATION_ANOMALY_REPORT,
    DocumentType.GCAR,
    DocumentType.HAZARD_RESPONSE_LEVEL,
    DocumentType.IRREGULAR_PARTS_AUTHORIZATION_REQUEST,
    DocumentType.ITEM_FOR_INVESTIGATION,
    DocumentType.MATERIAL_USAGE_AGREEMENT,
    DocumentType.NASA_SPACE_PART_AUTHORIZATION_REQUEST,
    DocumentType.NINE_ZERO_SIX,
    DocumentType.OPS_NOM_DECLARATION,
    DocumentType.OTHER_DOCUMENT,
    DocumentType.OVER_DUE_TAG,
    DocumentType.PROBLEM_REPORTING_AND_CORRECTIVE_ACTION,
    DocumentType.PROCEDURE,
    DocumentType.RSA,
    DocumentType.TPS,
    DocumentType.VEHICLE_CERTIFICATION,
    DocumentType.WAIVER,
    DocumentType.YELLOW_TAG_NOTICE
];
// Listing out, per UserSupportContext, which are valid ItemMasterTypes (equipment groups)
const allItemMasterTypes = [
    ItemMasterType.ANCILLARY_EQUIPMENT,
    ItemMasterType.BATTERY,
    ItemMasterType.CAPITAL_PROPERTY,
    ItemMasterType.COMMUNICATIONS_EQUIPMENT,
    ItemMasterType.CREW_EQUIPMENT_STOWAGE_PROV,
    ItemMasterType.CREW_ESCAPE_SYSTEM,
    ItemMasterType.CTSD,
    ItemMasterType.DECALS_PLACARDS_TAGS_PATCHES,
    ItemMasterType.DISPLAY_AND_CONTROL_EQUIPMENT,
    ItemMasterType.ELECTRICAL,
    ItemMasterType.EMU_LSS_FLIGHT_SSA_NBL,
    ItemMasterType.EVA_SUPPORT_EQUIPMENT,
    ItemMasterType.EVA_TOOLS,
    ItemMasterType.FACILITIES_CONTRACT,
    ItemMasterType.FILM,
    ItemMasterType.FOOD_AND_MEDICAL_SYSTEMS,
    ItemMasterType.GENERAL_COMPUTER_SUPPLIES,
    ItemMasterType.GROUND_SUPPORT_EQUIPMENT,
    ItemMasterType.IDWA,
    ItemMasterType.ISIL_IDWA,
    ItemMasterType.ISS_IDWA,
    ItemMasterType.ISS_M_AND_O_HARDWARE_PBA,
    ItemMasterType.ISS_WORK_THROUGH_FCE,
    ItemMasterType.MANNED_MANEUVERING_UNIT,
    ItemMasterType.MECHANICAL,
    ItemMasterType.MULTIPLE_EQ_GROUPS_FLIGHT,
    ItemMasterType.MULTIPLE_EQ_GROUPS_PSE,
    ItemMasterType.NON_FCE,
    ItemMasterType.OBS,
    ItemMasterType.OH_HOUSTON_OPS_JANITOR_SUPP,
    ItemMasterType.OH_HOUSTON_OPS_OFFICE_SUPP,
    ItemMasterType.OH_HOUSTON_OPS_REPROD_SUPP,
    ItemMasterType.OH_SR_QA_ONLY,
    ItemMasterType.OTHER_STS_SUPPORT_EQUIPMENT,
    ItemMasterType.PGSC_PCS,
    ItemMasterType.PHOTOGRAPHIC_EQUIPMENT,
    ItemMasterType.PREPACK_BENCH_REVIEW,
    ItemMasterType.SAFER,
    ItemMasterType.SHARED_CONSUMABLES,
    ItemMasterType.SPECIAL_ORDER,
    ItemMasterType.VEHICLE_ACCOMMODATIONS,
    ItemMasterType.VEHICLE_ACCOMMODATIONS,
    ItemMasterType.XEMU,
    ItemMasterType.XFSE,
    ItemMasterType.XINFO,
    ItemMasterType.XPGS,
    ItemMasterType.XPLSS
];
export const UserSupportContextToValidItemMasterTypes = new Map([
    [CosmicUserSupportContext.CTSD, allItemMasterTypes],
    [CosmicUserSupportContext.EHP, allItemMasterTypes],
    [CosmicUserSupportContext.ER, [ItemMasterType.ELECTRICAL, ItemMasterType.MECHANICAL]],
    [CosmicUserSupportContext.ES, allItemMasterTypes],
    [CosmicUserSupportContext.ESOC, allItemMasterTypes],
    [CosmicUserSupportContext.EV, allItemMasterTypes],
    [CosmicUserSupportContext.xEMU, allItemMasterTypes]
]);
// Listing out, per UserSupportContext, which are valid DocumentTypes
export const UserSupportContextToValidDocumentTypes = new Map([
    [UserSupportContext.CTSD, allTrackingDocumentTypes],
    [UserSupportContext.EHP, allTrackingDocumentTypes],
    [UserSupportContext.ER, allTrackingDocumentTypes],
    [UserSupportContext.ES, allTrackingDocumentTypes],
    [UserSupportContext.ESOC, allTrackingDocumentTypes],
    [UserSupportContext.EV, allTrackingDocumentTypes],
    [UserSupportContext.xEMU, allTrackingDocumentTypes]
]);
// Listing out, per UserSupportContext, which are valid EntityTypes
export const UserSupportContextToValidEntityTypes = new Map([
    [CosmicUserSupportContext.CTSD, [EntityType.DOCUMENT]],
    [CosmicUserSupportContext.EHP, [EntityType.DOCUMENT]],
    [CosmicUserSupportContext.ER, [EntityType.DOCUMENT]],
    [CosmicUserSupportContext.ES, [EntityType.DOCUMENT]],
    [CosmicUserSupportContext.ESOC, [EntityType.DOCUMENT]],
    [CosmicUserSupportContext.EV, [EntityType.DOCUMENT]],
    [CosmicUserSupportContext.xEMU, [EntityType.DOCUMENT]]
]);
// Listing out, per UserSupportContext, which are valid LinkTypes
export const UserSupportContextToValidLinkTypes = new Map([
    [CosmicUserSupportContext.CTSD, [LinkType.OTHER]],
    [CosmicUserSupportContext.EHP, [LinkType.OTHER]],
    [CosmicUserSupportContext.ER, [LinkType.OTHER]],
    [CosmicUserSupportContext.ES, [LinkType.OTHER]],
    [CosmicUserSupportContext.ESOC, [LinkType.OTHER]],
    [CosmicUserSupportContext.EV, [LinkType.OTHER]],
    [CosmicUserSupportContext.xEMU, [LinkType.OTHER]]
]);
// per UserSupportContext, valid UserPermissions
// atm for OG all ctxs support the same general perms
const OG_PERMISSIONS = [
    UserPermission.ACTIVITY_ADMIN,
    UserPermission.APPROVAL_ROUTING_TEMPLATE_ADMIN,
    UserPermission.ASSEMBLY_ADMIN,
    UserPermission.ASSEMBLY_TEMPLATE_ADMIN,
    UserPermission.CHANGE_SET_DELETE,
    UserPermission.CHANGE_SET_TEMPLATE_ADMIN,
    UserPermission.CONTACT_ADMIN,
    UserPermission.CONTRACT_ADMIN,
    UserPermission.DATA_REMEDIATION_ADMIN,
    UserPermission.DOCUMENT_ADMIN,
    UserPermission.EVENT_ADMIN,
    UserPermission.HARDWARE_LIST_ADMIN,
    UserPermission.HARDWARE_LIST_TEMPLATE_ADMIN,
    UserPermission.ITEM_DRAWING_ADMIN,
    UserPermission.ITEM_DRAWING_ALIAS,
    UserPermission.ITEM_DRAWING_CODES,
    UserPermission.ITEM_DRAWING_VALID_SIZES,
    UserPermission.ITEM_INSTANCE_ADMIN,
    UserPermission.ITEM_INSTANCE_DATES,
    UserPermission.ITEM_INSTANCE_LIMITED_LIFE,
    UserPermission.ITEM_MASTER_ADMIN,
    UserPermission.ITEM_MASTER_ALTERNATES,
    UserPermission.ITEM_MASTER_GROUND_SUPPORT_EQUIPMENT,
    UserPermission.ITEM_MASTER_INVENTORY_QUOTAS_ADMIN,
    UserPermission.ITEM_MASTER_LIMITED_LIFE,
    UserPermission.LOCATION_ADMIN,
    UserPermission.MERGE_ITEM_INSTANCES,
    UserPermission.ON_ORBIT_FAILURE_ADMIN,
    UserPermission.ORGANIZATION_ADMIN,
    UserPermission.PHYSICAL_INVENTORY_AUDIT_ADMIN,
    UserPermission.USER_ADMIN,
    UserPermission.USER_GROUPS_ADMIN,
    UserPermission.USER_PERMISSIONS,
    UserPermission.USER_SUPPORT_CONTEXT
];
export const UserSupportContextToValidPermissions = new Map([
    [CosmicUserSupportContext.CTSD, OG_PERMISSIONS],
    [CosmicUserSupportContext.EHP, OG_PERMISSIONS],
    [CosmicUserSupportContext.ER, OG_PERMISSIONS],
    [CosmicUserSupportContext.ES, OG_PERMISSIONS],
    [CosmicUserSupportContext.ESOC, OG_PERMISSIONS],
    [CosmicUserSupportContext.EV, OG_PERMISSIONS],
    [CosmicUserSupportContext.xEMU, OG_PERMISSIONS]
]);
// export installation method for Vue so its on the Vue prototype
export default {
    install(Vue) {
        Object.defineProperties(Vue.prototype, {
            $cosmicEnvironment: { value: COSMIC_ENVIRONMENT },
            $cosmicSystem: { value: COSMIC_SYSTEM },
            $validUserSupportContexts: { value: Object.values(UserSupportContext) }
        });
    }
};
