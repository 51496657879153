import { __decorate } from "tslib";
import { Component, Mixins } from 'vue-property-decorator';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import LocationsAutocompleteMixin, { LocationsAutocompleteFields } from '~/nasa_ui/mixins/LocationsAutocompleteMixin';
let AppSelectBin = class AppSelectBin extends Mixins(LocationsAutocompleteMixin, BaseCosmic) {
    field = LocationsAutocompleteFields.BIN;
};
AppSelectBin = __decorate([
    Component
], AppSelectBin);
export default AppSelectBin;
