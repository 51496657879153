export var Effectivity;
(function (Effectivity) {
    Effectivity["AA"] = "AA";
    Effectivity["ALT"] = "ALT";
    Effectivity["CC"] = "CC";
    Effectivity["CL"] = "CL";
    Effectivity["CMH"] = "CMH";
    Effectivity["CP"] = "CP";
    Effectivity["CPC"] = "CPC";
    Effectivity["FM"] = "FM";
    Effectivity["MH"] = "MH";
    Effectivity["MS"] = "MS";
    Effectivity["NC"] = "NC";
    Effectivity["ND"] = "ND";
    Effectivity["NM"] = "NM";
    Effectivity["OP"] = "OP";
    Effectivity["PC"] = "PC";
    Effectivity["RT"] = "RT";
    Effectivity["UR"] = "UR";
    Effectivity["XB"] = "XB";
    Effectivity["XD"] = "XD";
    Effectivity["XF"] = "XF";
    Effectivity["XM"] = "XM";
    Effectivity["XN"] = "XN";
})(Effectivity || (Effectivity = {}));
export const EffectivityDisplay = new Map([
    [Effectivity.AA, 'AA: acceptable alternate'],
    [Effectivity.ALT, 'ALT: alternate configuration'],
    [Effectivity.CC, 'CC: configuration change - incorporate as needed'],
    [Effectivity.CL, 'CL: closed'],
    [Effectivity.CMH, 'CMH: closed mandatory re-cert, no hardware configuration impact'],
    [Effectivity.CP, 'CP: specific crewperson by calendar date'],
    [Effectivity.CPC, 'CPC: closed, paper change'],
    [Effectivity.FM, 'FM: flight mandatory'],
    [Effectivity.MH, 'MH: mandatory recert, no hardware configuration impact'],
    [Effectivity.MS, 'MS: calendar date, in specific serial number'],
    [Effectivity.NC, 'NC: non configuration change'],
    [Effectivity.ND, 'ND: new design, fabricate'],
    [Effectivity.NM, 'NM: non-mandatory for flight'],
    [Effectivity.OP, 'OP: optional'],
    [Effectivity.PC, 'PC: paper change no hardware configuration impact'],
    [Effectivity.RT, 'RT: routine ecm to be incorporated as time allows'],
    [Effectivity.UR, 'UR: urgent'],
    [Effectivity.XB, 'XB: immediately incorporate in all units'],
    [Effectivity.XD, 'XD: by calendar date when hardware available'],
    [Effectivity.XF, 'XF: All units scheduled for specific flight and subs'],
    [Effectivity.XM, 'XM: Incorporate at scheduled maintenance'],
    [Effectivity.XN, 'XN: Incorporate in new builds/spares']
]);
