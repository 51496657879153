import addDays from 'date-fns/addDays';
import isBefore from 'date-fns/isBefore';
import isWithinInterval from 'date-fns/isWithinInterval';
import parseISO from 'date-fns/parseISO';
import { ErrorUtility } from '../ErrorUtility';
import { removeTime } from './removeTime';
/**
 *  Similar to date-fns' `isWithinInterval`, but is more inclusive. It checks
 *  if the specified date falls within the given date range. The start date
 *  will be reset to midnight. The end date will be set to midnight of the
 *  following day.
 *
 * @param dateToCheck Date object or ISO string representing the date you want
 * to compare. Retains its time setting.
 * @param interval The date range to test against.
 * @returns Returns true if the date falls within the date range (inclusive),
 * otherwise returns false. If end date is omitted, returns true if start date
 * is before `dateToCheck`.
 */
export const isWithinIntervalInclusive = (dateToCheck, interval) => {
    if (!dateToCheck) {
        ErrorUtility({
            err: new Error('isWithinIntervalInclusive requires dateToCheck.'),
            suppressNotification: true
        });
        return false;
    }
    if (!interval?.start) {
        ErrorUtility({
            err: new Error('isWithinIntervalInclusive requires start date.'),
            suppressNotification: true
        });
        return false;
    }
    let startDate = interval.start;
    let endDate = interval.end;
    if (typeof dateToCheck === 'string') {
        dateToCheck = parseISO(dateToCheck);
    }
    if (typeof dateToCheck === 'number') {
        dateToCheck = new Date(dateToCheck);
    }
    if (typeof startDate === 'string') {
        startDate = parseISO(startDate);
    }
    if (typeof startDate === 'number') {
        startDate = new Date(startDate);
    }
    if (typeof endDate === 'string') {
        endDate = parseISO(endDate);
    }
    if (typeof endDate === 'number') {
        endDate = new Date(endDate);
    }
    const startDateMidnight = removeTime(startDate);
    // if no end date, check if now is after the event start date
    if (!endDate) {
        console.warn("No end date specified in function, dateIsWithinRange. Assuming indefinite range. If this isn't the desired behavior, please provide an end date.");
        return isBefore(startDateMidnight, dateToCheck);
    }
    // technically 00:00 the next day
    const endDateMidnight = addDays(removeTime(endDate), 1);
    // if there is a start date *and* end date, check if now falls withing the
    // event's time range (inclusive check)
    return isWithinInterval(dateToCheck, {
        start: startDateMidnight,
        end: endDateMidnight
    });
};
