import { __decorate, __metadata } from "tslib";
import { DebounceAll } from 'lodash-decorators';
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Side } from '~/db_types/swagger_types';
import { DB_DEFAULT, DEFAULT_DASH } from '~/nasa_ui/constants';
import { BASE_NUMBER_COL, BASE_TEXT_COL, NAME_DISPLAY_COL, SEQUENCE_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import { SideDisplay } from '~/nasa_ui/types';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { transformHardwareListTemplateResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let MiniHardwareListTemplate = class MiniHardwareListTemplate extends Mixins(ReadOnlyMixin) {
    DB_DEFAULT = DB_DEFAULT;
    DEFAULT_DASH = DEFAULT_DASH;
    isFetching = false;
    showModalHardwareListTemplateItemCreate = false;
    showModalHardwareListTemplateEdit = false;
    showTemplateItemsList = false;
    selectedEntity = null;
    SideDisplay = SideDisplay;
    Side = Side;
    tableHeadersForTemplateItems = [
        SEQUENCE_COL,
        NAME_DISPLAY_COL,
        composeTableHeader(BASE_NUMBER_COL, {
            text: 'Min qty',
            value: '_min'
        }),
        composeTableHeader(BASE_NUMBER_COL, {
            text: 'Max qty',
            value: '_max'
        }),
        composeTableHeader(BASE_TEXT_COL, {
            text: 'Part constraints',
            value: '_hardwareListTemplateParts',
            width: '0'
        })
    ];
    allowChildCreation;
    allowEdit;
    entity;
    id;
    inlineClear;
    initiallyShowListItems;
    showManageLink;
    title;
    get computedId() {
        return this.selectedEntity?.id || this.id;
    }
    get hasAnyActions() {
        return this.showManageLink || this.inlineClear || this.hasTemplateItems;
    }
    get hasTemplateItems() {
        return (this.selectedEntity?.hardwareListTemplateSlots || []).length > 0;
    }
    get shouldShowCreateChildIcon() {
        return this.allowChildCreation;
    }
    get shouldShowInlineClearIcon() {
        return this.inlineClear && !this.readOnly;
    }
    get transformedSelectedEntity() {
        return this.selectedEntity ? transformHardwareListTemplateResponses([this.selectedEntity])[0] : null;
    }
    get verticalLabelDisplayItems() {
        if (!this.transformedSelectedEntity) {
            return [];
        }
        const items = [];
        items.push({
            name: 'Type',
            value: this.transformedSelectedEntity._subType
        });
        if (this.transformedSelectedEntity.pbsItemId) {
            items.push({
                name: 'PBS',
                value: this.transformedSelectedEntity.pbsItemId
            });
        }
        return items;
    }
    created() {
        this.$listen(`reload_${this.id}`, () => {
            if (this.id) {
                this.fetchEntity();
            }
        });
    }
    // Called from ui
    onClickOfEdit() {
        if (!this.allowEdit) {
            return;
        }
        this.showModalHardwareListTemplateEdit = true;
    }
    onClickOfAddItemToList() {
        this.showModalHardwareListTemplateItemCreate = true;
    }
    onClickOfShowTemplateItemsList() {
        this.showTemplateItemsList = !this.showTemplateItemsList;
    }
    onClickOfInlineClear() {
        return this.transformedSelectedEntity;
    }
    onEntityChange() {
        if (!this.entity) {
            return;
        }
        this.selectedEntity = { ...this.entity };
    }
    async fetchEntity() {
        if (!this.id) {
            return;
        }
        try {
            this.isFetching = true;
            const resp = await this.$http.get(`/hardware-list-templates/${this.computedId}`);
            this.selectedEntity = resp.data;
            this.isFetching = false;
        }
        catch (err) {
            this.isFetching = false;
            this.$errorUtility({ err });
        }
    }
    onInitiallyShowListItemsChange() {
        if (this.initiallyShowListItems) {
            this.showTemplateItemsList = true;
        }
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], MiniHardwareListTemplate.prototype, "allowChildCreation", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], MiniHardwareListTemplate.prototype, "allowEdit", void 0);
__decorate([
    Prop({
        required: false,
        type: Object
    }),
    __metadata("design:type", Object)
], MiniHardwareListTemplate.prototype, "entity", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], MiniHardwareListTemplate.prototype, "id", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], MiniHardwareListTemplate.prototype, "inlineClear", void 0);
__decorate([
    Prop({
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], MiniHardwareListTemplate.prototype, "initiallyShowListItems", void 0);
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], MiniHardwareListTemplate.prototype, "showManageLink", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], MiniHardwareListTemplate.prototype, "title", void 0);
__decorate([
    Emit('inlineClear'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MiniHardwareListTemplate.prototype, "onClickOfInlineClear", null);
__decorate([
    Watch('entity', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MiniHardwareListTemplate.prototype, "onEntityChange", null);
__decorate([
    DebounceAll(10),
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], MiniHardwareListTemplate.prototype, "fetchEntity", null);
__decorate([
    Watch('initiallyShowListItems', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MiniHardwareListTemplate.prototype, "onInitiallyShowListItemsChange", null);
MiniHardwareListTemplate = __decorate([
    Component
], MiniHardwareListTemplate);
export default MiniHardwareListTemplate;
