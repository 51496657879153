import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
/**
 * Expandable panel/container. Pass `is-open` to define the open/close state, or use
 * `v-model` to get two-way binding on the panel's open/close state. For backwards
 * compatibility, `is-open` defaults to true. If you are using `v-model` and want
 * the panel's initial state to be closed, you will need to pass `:is-open="false"` in
 * addition to the `v-model`.
 */
let AppExpansionPanel = class AppExpansionPanel extends Vue {
    color;
    dark;
    dense;
    fullWidthContent;
    isOpen;
    light;
    popout;
    title;
    value;
    isExpanded = [true];
    syncPane(isOpen) {
        this.isExpanded = [isOpen];
    }
    emitInput(val) {
        return val[0];
    }
};
__decorate([
    Prop({ default: 'primary lighten-3' }),
    __metadata("design:type", String)
], AppExpansionPanel.prototype, "color", void 0);
__decorate([
    Prop({ default: true, type: Boolean }),
    __metadata("design:type", Boolean)
], AppExpansionPanel.prototype, "dark", void 0);
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], AppExpansionPanel.prototype, "dense", void 0);
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], AppExpansionPanel.prototype, "fullWidthContent", void 0);
__decorate([
    Prop({ default: true, type: Boolean }),
    __metadata("design:type", Boolean)
], AppExpansionPanel.prototype, "isOpen", void 0);
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], AppExpansionPanel.prototype, "light", void 0);
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], AppExpansionPanel.prototype, "popout", void 0);
__decorate([
    Prop({ default: '' }),
    __metadata("design:type", String)
], AppExpansionPanel.prototype, "title", void 0);
__decorate([
    Prop({ default: true, type: Boolean }),
    __metadata("design:type", Boolean)
], AppExpansionPanel.prototype, "value", void 0);
__decorate([
    Watch('isOpen', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], AppExpansionPanel.prototype, "syncPane", null);
__decorate([
    Emit('input'),
    Watch('isExpanded', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], AppExpansionPanel.prototype, "emitInput", null);
AppExpansionPanel = __decorate([
    Component
], AppExpansionPanel);
export default AppExpansionPanel;
