import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import BaseButton from '~/nasa_ui/base/BaseButton';
let ButtonCancel = class ButtonCancel extends BaseButton {
    get computedColor() {
        return this.color || '--cosmic-gray-lighten-4';
    }
    get computedTextColor() {
        return this.textColor || '--cosmic-text';
    }
};
ButtonCancel = __decorate([
    Component
], ButtonCancel);
export default ButtonCancel;
