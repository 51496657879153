import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins } from 'vue-property-decorator';
import HttpMixin from '~/nasa_ui/mixins/HttpMixin';
import LoaderMixin from '~/nasa_ui/mixins/LoaderMixin';
import { queryFactory } from '../base/mixinFactories';
import { CosmicContactSlimFragment } from '../contact/slim';
import { CosmicOrganizationSlimFragment } from '../organization/slim';
import { CosmicUserGroupSlimFragment, CosmicUserSlimFragment } from './slim';
export * from './slim';
// Fragments ------------------------------------
export const CosmicUserGroupFragment = gql `
  ${CosmicUserSlimFragment}
  ${CosmicUserGroupSlimFragment}

  fragment CosmicUserGroup on UserGroup {
    ...CosmicUserGroupSlim

    createdByUser {
      ...CosmicUserSlim
    }

    delegatesForUser {
      ...CosmicUserSlim
    }
  }
`;
export const CosmicUserGroupWithUserCountFragment = gql `
  ${CosmicUserSlimFragment}
  ${CosmicUserGroupSlimFragment}

  fragment CosmicUserGroupWithUserCount on UserGroup {
    ...CosmicUserGroupSlim

    usersToUserGroups {
      totalCount
    }

    delegatesForUser {
      ...CosmicUserSlim
    }
  }
`;
export const CosmicUserFragment = gql `
  ${CosmicContactSlimFragment}
  ${CosmicUserSlimFragment}
  ${CosmicUserGroupFragment}
  ${CosmicOrganizationSlimFragment}

  fragment CosmicUser on User {
    attributes
    ...CosmicUserSlim

    createdDateTime
    permissions

    contact {
      ...CosmicContactSlim
    }

    delegateUserGroup {
      ...CosmicUserGroup
    }

    supports {
      nodes {
        nodeId
        organizationCode
        permissions

        organization {
          ...CosmicOrganizationSlim
        }
      }
    }

    usersToUserGroups {
      totalCount

      nodes {
        auid
        userId
        groupCode

        userGroup {
          ...CosmicUserGroup
        }
      }
    }
  }
`;
export const CosmicUserNoSupportNoPermsFragment = gql `
  ${CosmicContactSlimFragment}
  ${CosmicUserGroupFragment}

  fragment CosmicUserNoSupportNoPerms on User {
    ...CosmicUserSlim

    createdDateTime

    contact {
      ...CosmicContactSlim
    }

    delegateUserGroup {
      ...CosmicUserGroup
    }
  }
`;
export const UserGroupWithAssignedUsers = gql `
  ${CosmicUserSlimFragment}
  ${CosmicUserGroupFragment}

  fragment CosmicUserGroupWithAssignedUsers on UserGroup {
    ...CosmicUserGroup

    usersToUserGroups {
      totalCount

      nodes {
        groupCode

        user {
          ...CosmicUserSlim
        }
      }
    }
  }
`;
export const UserGroupWithAssignedUsersWithChangeSetTemplates = gql `
  ${UserGroupWithAssignedUsers}
  ${CosmicUserSlimFragment}

  fragment CosmicUserGroupWithAssignedUsersWithChangeSetTemplates on UserGroup {
    ...CosmicUserGroupWithAssignedUsers

    changeSetTemplates {
      nodes {
        createdByUserId
        createdDateTime
        groupCode
        isActive
        nodeId
        requiresComment
        requiresQualityControlStamp
        subType

        createdByUser {
          ...CosmicUserSlim
        }
      }
    }
  }
`;
// Queries --------------------------------------
export const GetCurrentUserWithGroup = gql `
  ${CosmicUserGroupFragment}

  query GetCurrentUserWithGroup($groupCode: String) {
    currentUser {
      nodeId
      usersToUserGroups(condition: { groupCode: $groupCode }) {
        totalCount

        nodes {
          nodeId
          groupCode

          userGroup {
            ...CosmicUserGroup
          }
        }
      }
    }
  }
`;
export const GetCurrentUserWithGroups = gql `
  ${CosmicUserFragment}

  query GetCurrentUserWithGroups {
    currentUser {
      ...CosmicUser
    }
  }
`;
export const GetAllUsersForContext = gql `
  ${CosmicUserFragment}

  query GetAllUsersForContext($supportContext: String) {
    userSupportContexts(filter: { organizationCode: { equalTo: $supportContext } }) {
      nodes {
        user {
          ...CosmicUser
        }
      }
    }
  }
`;
// gets all the users in a specified group
export const GetAllUsersForGroup = gql `
  ${CosmicUserSlimFragment}

  query GetAllUsersForGroup($nodeId: ID!) {
    userGroup(nodeId: $nodeId) {
      nodeId
      name

      usersToUserGroups {
        totalCount

        nodes {
          nodeId

          user {
            ...CosmicUserSlim
          }
        }
      }
    }
  }
`;
export const GetAllUserGroups = gql `
  ${CosmicUserGroupFragment}

  query GetAllUserGroups {
    userGroups(filter: { delegateGroupForUserId: { isNull: true } }) {
      nodes {
        ...CosmicUserGroup

        usersToUserGroups {
          totalCount
        }
      }
    }
  }
`;
export const GetUsersByAuid = gql `
  ${CosmicUserFragment}

  query GetUsersByAuid($userAuids: [String!]) {
    users(filter: { auid: { in: $userAuids } }) {
      nodes {
        ...CosmicUser
      }
    }
  }
`;
export const GetUsersByAuidOrEmail = gql `
  ${CosmicUserFragment}

  query GetUsersByAuidOrEmail($userAuids: [String!], $emails: [String!]) {
    users(filter: { or: [{ auid: { in: $userAuids } }, { email: { containedBy: $emails } }] }) {
      nodes {
        ...CosmicUser
      }
    }
  }
`;
export const GetUserDelegateGroup = gql `
  ${CosmicUserGroupSlimFragment}

  query GetUserDelegateGroup($userId: UUID!) {
    userGroups(condition: { delegateGroupForUserId: $userId }) {
      nodes {
        ...CosmicUserGroupSlim
      }
    }
  }
`;
export const GetUserGroupsByCodes = gql `
  ${CosmicUserGroupWithUserCountFragment}

  query GetUserGroupsByCodes($codes: [String!]!) {
    userGroups(filter: { code: { in: $codes } }) {
      nodes {
        ...CosmicUserGroupWithUserCount
      }
    }
  }
`;
export const GetUsersById = gql `
  ${CosmicUserFragment}

  query GetUsersById($userIds: [UUID!]) {
    users(filter: { id: { in: $userIds } }) {
      nodes {
        ...CosmicUser
      }
    }
  }
`;
export const GetSlimUsersById = gql `
  ${CosmicUserSlimFragment}

  query GetSlimUsersById($userIds: [UUID!]) {
    users(filter: { id: { in: $userIds } }) {
      nodes {
        ...CosmicUserSlim
      }
    }
  }
`;
export const GetUserGroupByCodeWithChangeSetTemplates = gql `
  ${UserGroupWithAssignedUsersWithChangeSetTemplates}

  query GetUserGroupByCodeWithChangeSetTemplates($code: String!) {
    userGroupByCode(code: $code) {
      ...CosmicUserGroupWithAssignedUsersWithChangeSetTemplates
    }
  }
`;
export const GetUsers = gql `
  ${CosmicUserSlimFragment}

  query GetUsers($q: String!) {
    searchUsers(query: $q) {
      nodes {
        ...CosmicUserSlim
      }
    }
  }
`;
export const SearchUserDelegateGroups = gql `
  ${CosmicUserGroupWithUserCountFragment}

  query SearchUserDelegateGroups($q: String) {
    searchUserGroups(subType: ["USER_DELEGATE"], query: $q) {
      nodes {
        ...CosmicUserGroupWithUserCount
      }
    }
  }
`;
export const SearchNonDelegateUserGroups = gql `
  ${CosmicUserGroupWithUserCountFragment}

  query SearchNonDelegateUserGroups($q: String) {
    searchUserGroups(query: $q, filter: { subType: { notEqualTo: USER_DELEGATE } }) {
      nodes {
        ...CosmicUserGroupWithUserCount
      }
    }
  }
`;
let UserRepoMixin = class UserRepoMixin extends Mixins(LoaderMixin, HttpMixin) {
    // Queries
    getAllUserGroups = queryFactory(this, GetAllUserGroups);
    getAllUsersForContext = queryFactory(this, GetAllUsersForContext);
    // TODO: Remove this and just use the fetch for user group by code and then set the users yourself
    // OR update this function to return the array of users (resp.data.users) UserResponse[]
    getAllUsersForGroup = async (code) => {
        return await this.httpGet(`/user-groups/${code}`);
    };
    getCurrentUserWithGroups = queryFactory(this, GetCurrentUserWithGroups);
    getCurrentUserWithGroup = queryFactory(this, GetCurrentUserWithGroup);
    getSlimUsersById = queryFactory(this, GetSlimUsersById);
    getUserGroupsByGroupCodes(groupCodes) {
        if (!groupCodes.length) {
            return null;
        }
        return this.$http.get('/user-groups', {
            params: {
                code: groupCodes,
                take: -1
            }
        });
    }
    getUserDelegateGroup = queryFactory(this, GetUserDelegateGroup);
    getUserById = async (id) => {
        return await this.httpGet(`/users/${id}`);
    };
    getUsersByAuid = queryFactory(this, GetUsersByAuid);
    getUsersByAuidOrEmail = queryFactory(this, GetUsersByAuidOrEmail);
    getUserGroupByCodeWithChangeSetTemplates = queryFactory(this, GetUserGroupByCodeWithChangeSetTemplates);
    getUsers = queryFactory(this, GetUsers);
    searchUserDelegateGroups = queryFactory(this, SearchUserDelegateGroups);
    searchNonDelegateUserGroups = queryFactory(this, SearchNonDelegateUserGroups);
};
UserRepoMixin = __decorate([
    Component
], UserRepoMixin);
export { UserRepoMixin };
