import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let AppPageDescription = class AppPageDescription extends Vue {
    color;
    removeMarginLeft;
    removeMarginBottom;
};
__decorate([
    Prop({
        type: String,
        default: 'default'
    }),
    __metadata("design:type", String)
], AppPageDescription.prototype, "color", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppPageDescription.prototype, "removeMarginLeft", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppPageDescription.prototype, "removeMarginBottom", void 0);
AppPageDescription = __decorate([
    Component
], AppPageDescription);
export default AppPageDescription;
