import humanize from 'string-humanize';
import NotificationModule from '~/nasa_ui/modules/Notification';
import { AlertType, EntityType, UserPermission } from '~/nasa_ui/types';
import { ErrorUtility } from '~/nasa_ui/utils/ErrorUtility';
import EventBus from '../modules/EventBus';
import { CosmicIcons } from '../utils/ui/CosmicIcons';
export default {
    install(Vue) {
        Object.defineProperties(Vue.prototype, {
            AlertType: { value: AlertType },
            EntityType: { value: EntityType },
            UserPermission: { value: UserPermission },
            $errorUtility: { value: ErrorUtility },
            $humanize: { value: humanize },
            $icons: { value: CosmicIcons },
            $listen: { value: EventBus.listen },
            $message: { value: EventBus.sendMessage },
            $notification: { value: new NotificationModule() },
            $openNewTab: { value: (val) => window.open(val, '_blank') },
            $stopListening: { value: EventBus.stopListening }
        });
    }
};
