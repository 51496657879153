export var SecureClass;
(function (SecureClass) {
    SecureClass["UNCLASSIFIED"] = "UNCLASSIFIED";
    SecureClass["PROPRIETORY"] = "PROPRIETORY";
    SecureClass["CONFIDENTIAL"] = "CONFIDENTIAL";
    SecureClass["SECRET"] = "SECRET";
})(SecureClass || (SecureClass = {}));
export const SecureClassDisplay = new Map([
    [SecureClass.UNCLASSIFIED, 'Unclassified'],
    [SecureClass.PROPRIETORY, 'Proprietory'],
    [SecureClass.CONFIDENTIAL, 'Confidential'],
    [SecureClass.SECRET, 'Secret']
]);
