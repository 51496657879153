import { __decorate, __metadata } from "tslib";
import { DebounceAll } from 'lodash-decorators';
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { DB_DEFAULT, DEFAULT_DASH } from '~/nasa_ui/constants';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import { transformItemDrawingResponseWithRefs } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let MiniItemDrawing = class MiniItemDrawing extends Mixins(ReadOnlyMixin) {
    isFetching = false;
    selectedEntity = null;
    drawingNumber;
    entity;
    inlineClear;
    showManageLink;
    showInfoIcon;
    title;
    get shouldShowInlineClearIcon() {
        return this.inlineClear && !this.readOnly;
    }
    get transformedSelectedEntity() {
        return this.selectedEntity ? transformItemDrawingResponseWithRefs([this.selectedEntity])[0] : null;
    }
    get verticalLabelDisplayItems() {
        if (!this.transformedSelectedEntity) {
            return [];
        }
        const items = [];
        const item = this.transformedSelectedEntity;
        if (item.sizes && item.sizes.length > 0) {
            items.push({
                name: 'Valid sizes',
                value: item.sizes
                    ? item.sizes.length <= 3
                        ? item.sizes
                            .map((size) => (size === DB_DEFAULT ? DEFAULT_DASH : size))
                            .join(', ')
                            .toString()
                        : `(${item.sizes.length} listed)`
                    : DEFAULT_DASH
            });
        }
        items.push({
            name: 'PoC',
            value: this.$contactDisplay(item.pointOfContact)
        });
        return items;
    }
    onClickOfInlineClear() {
        return this.transformedSelectedEntity;
    }
    emitInfoClicked() {
        return this.transformedSelectedEntity;
    }
    onEntityChange() {
        if (!this.entity) {
            return;
        }
        this.selectedEntity = { ...this.entity };
    }
    async fetchEntity() {
        if (!this.drawingNumber) {
            return;
        }
        try {
            this.isFetching = true;
            const resp = await this.$http.get(`/hardware/item-drawings/${encodeURIComponent(this.drawingNumber)}`);
            this.selectedEntity = resp.data;
            this.isFetching = false;
        }
        catch (err) {
            this.isFetching = false;
            this.$errorUtility({ err });
        }
    }
};
__decorate([
    Prop({
        type: String,
        required: false
    }),
    __metadata("design:type", String)
], MiniItemDrawing.prototype, "drawingNumber", void 0);
__decorate([
    Prop({
        required: false,
        type: Object
    }),
    __metadata("design:type", Object)
], MiniItemDrawing.prototype, "entity", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], MiniItemDrawing.prototype, "inlineClear", void 0);
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], MiniItemDrawing.prototype, "showManageLink", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], MiniItemDrawing.prototype, "showInfoIcon", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], MiniItemDrawing.prototype, "title", void 0);
__decorate([
    Emit('inlineClear'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MiniItemDrawing.prototype, "onClickOfInlineClear", null);
__decorate([
    Emit('infoClicked'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MiniItemDrawing.prototype, "emitInfoClicked", null);
__decorate([
    Watch('entity', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MiniItemDrawing.prototype, "onEntityChange", null);
__decorate([
    DebounceAll(10),
    Watch('drawingNumber', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], MiniItemDrawing.prototype, "fetchEntity", null);
MiniItemDrawing = __decorate([
    Component
], MiniItemDrawing);
export default MiniItemDrawing;
