var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('svg', {
    attrs: {
      "width": "41",
      "height": "34",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "fill": _vm.color,
      "fill-rule": "evenodd"
    }
  }, [_c('path', {
    attrs: {
      "d": "m8.78 10.044 2.245 2.245a11.057 11.057 0 0 0-1.23 8.355 11.05 11.05 0 0 0 2.132 4.289l-.234-.002c.446.006.905.003 1.376-.01l.475-.017c.399-.016.806-.04 1.22-.071l.5-.041.253-.023.51-.052c.171-.019.343-.038.516-.06l.523-.065.263-.036.53-.078.535-.085a45.456 45.456 0 0 0 3.583-.742l.325-.085 2.521 2.52c-.686.203-1.385.393-2.097.57a47.671 47.671 0 0 1-7.12 1.231 11.05 11.05 0 0 0 7.636.836 11.093 11.093 0 0 0 2.99-1.227l2.245 2.245a14.164 14.164 0 0 1-4.486 1.989c-5.007 1.248-10.057-.332-13.463-3.728l-.429-.027c-5.035-.362-8.653-2.055-9.403-5.063-.692-2.775 1.23-5.654 4.913-8.206l.325-.221.334-.22c.168-.11.34-.218.516-.326l.199-.12a14.258 14.258 0 0 1 1.797-3.775ZM30.6 7.93l.485.031.42.034c4.844.428 8.186 2.09 8.915 5.013.771 3.092-1.788 6.381-6.292 9.11a14.259 14.259 0 0 1-1.794 3.76l-4.233-4.232.222-.09.577-.248c.38-.167.751-.337 1.111-.51l.427-.208c.422-.21.83-.422 1.22-.637l.384-.216c.127-.072.251-.145.374-.217l.362-.219.351-.219c.46-.293.888-.588 1.282-.882l.29-.221c1.978-1.545 3.002-3.063 2.713-4.221-.258-1.038-1.786-1.944-4.301-2.42a14.24 14.24 0 0 1 1.457 4.372c-.868 1.085-1.84 1.945-2.921 2.586a11.12 11.12 0 0 0-.328-3.02c-1.482-5.944-7.502-9.56-13.446-8.079-1.08.27-2.083.688-2.993 1.23L12.637 6.18a14.165 14.165 0 0 1 4.488-1.99c5.013-1.25 10.068.335 13.475 3.74ZM6.364 18.006l-.169.138c-1.874 1.545-2.746 3.005-2.493 4.019.286 1.148 1.877 2.003 4.313 2.441a14.235 14.235 0 0 1-1.226-3.21 14.225 14.225 0 0 1-.425-3.388Z",
      "fill-rule": "nonzero",
      "opacity": ".597"
    }
  }), _c('path', {
    attrs: {
      "d": "m8.26 1.564 29.338 29.338a1.39 1.39 0 1 1-1.965 1.966L6.294 3.529A1.39 1.39 0 1 1 8.26 1.564Z"
    }
  })])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };