export var IntervalEnum;
(function (IntervalEnum) {
    IntervalEnum["seconds"] = "seconds";
    IntervalEnum["minutes"] = "minutes";
    IntervalEnum["hours"] = "hours";
    IntervalEnum["days"] = "days";
    IntervalEnum["months"] = "months";
    IntervalEnum["years"] = "years";
})(IntervalEnum || (IntervalEnum = {}));
export const IntervalDisplay = new Map([
    [IntervalEnum.seconds, 'Seconds'],
    [IntervalEnum.minutes, 'Minutes'],
    [IntervalEnum.hours, 'Hours'],
    [IntervalEnum.days, 'Days'],
    [IntervalEnum.months, 'Months'],
    [IntervalEnum.years, 'Years']
]);
