var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-flex', {
    staticClass: "px-4 pb-0 br-2 search_container"
  }, [_c('v-text-field', {
    staticClass: "search_input",
    attrs: {
      "autocomplete": "off",
      "autofocus": _vm.autofocus,
      "data-search": _vm.placeholder || '',
      "disabled": _vm.forceDisableSearch,
      "color": _vm.entityType || 'primary',
      "hint": _vm.hint,
      "placeholder": _vm.placeholder,
      "clearable": "",
      "outline": "",
      "persistent-hint": "",
      "type": "text"
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        $event.preventDefault();
      },
      "keyup": [function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.onEnterPressed.apply(null, arguments);
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        _vm.searchInput = '';
      }]
    },
    model: {
      value: _vm.searchInput,
      callback: function callback($$v) {
        _vm.searchInput = $$v;
      },
      expression: "searchInput"
    }
  }, [_c('v-btn', {
    staticClass: "search_button mt-0 mb-0",
    class: {
      'white--text': !_vm.darkText,
      'black--text': _vm.darkText
    },
    attrs: {
      "slot": "append-outer",
      "color": _vm.entityType || 'primary',
      "dark": !_vm.isLoading && !_vm.disableSearch && !_vm.forceDisableSearch,
      "disabled": _vm.isLoading || _vm.disableSearch || _vm.forceDisableSearch,
      "fab": ""
    },
    on: {
      "click": _vm.emitSearchButtonClicked
    },
    slot: "append-outer"
  }, [_c('c-icon-fa', {
    key: "search_icon",
    attrs: {
      "icon-color": "white",
      "icon": _vm.$icons.search
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };