var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { ContactRepoMixin } from '~/nasa_ui/DAL/contact';
import { AlertType, Maybe } from '~/nasa_ui/types';
let AppContactMultiple = class AppContactMultiple extends Mixins(BaseCosmic, ContactRepoMixin) {
    allContacts = [];
    shownContactInfo = null;
    showCreateContactModal = false;
    showInlineCreateForm = false;
    searchInput = '';
    disabled;
    label;
    inlineForm;
    inlineFormTitle;
    messages;
    required;
    value;
    get computedSelectedContacts() {
        return this.allContacts.filter((contact) => contact.id && this.value.includes(contact?.id));
    }
    get contactSelectOptions() {
        return this.allContacts.map((contact) => {
            const email = contact?.email?.[0] || 'no email';
            return {
                auid: contact?.auid || '',
                displayText: this.$contactDisplay(contact),
                value: contact?.id || '',
                firstName: contact?.firstName || '',
                lastName: contact?.lastName || '',
                email
            };
        });
    }
    get computedLabel() {
        return this.label || 'Contacts';
    }
    get computedMessages() {
        // if required and no value, show required message
        if (this.required && !this.value.length) {
            return ['REQUIRED'];
        }
        if (this.messages) {
            return this.messages;
        }
        // show messages based on rules
        return [''];
    }
    get selectionIncludesYourself() {
        return this.currentUser?.contactId ? this.value.includes(this.currentUser?.contactId) : false;
    }
    created() {
        this.fetchContacts();
        this.$listen('contact_created', (_contact) => {
            this.fetchContacts();
        });
    }
    copyEmail(id) {
        const contact = this.getContactInfo(id);
        const email = contact?.email ? contact.email[0] : 'no email';
        navigator.clipboard.writeText(email);
        this.$notification.add({
            type: AlertType.INFO,
            text: 'Email copied to clipboard.'
        });
    }
    async fetchContacts() {
        const resp = await this.getAllContacts({ error: `Couldn't fetch Contacts` });
        this.allContacts = resp?.data?.results || [];
    }
    getContactInfo(id) {
        return this.allContacts.find((contact) => contact.id === id);
    }
    async onClickOfCreateNewContactAppSelect(contactName) {
        const trimmedName = (contactName || '').trim();
        if (!trimmedName) {
            return;
        }
        if (this.inlineForm) {
            this.showInlineCreateForm = true;
        }
        else {
            this.showCreateContactModal = true;
        }
    }
    onClickOfShowContactInfo(item) {
        this.shownContactInfo = item;
    }
    onClickOfYourself() {
        if (this.currentUser?.contactId) {
            this.emitInput([...this.value, this.currentUser?.contactId]);
        }
    }
    onNewContactAdded(contact) {
        this.showCreateContactModal = false;
        this.showInlineCreateForm = false;
        this.allContacts.push(contact);
        this.emitInput([...this.value, contact.id || '']);
    }
    onSearchInput(val) {
        this.searchInput = val;
    }
    onSelectedContact(val) {
        return this.emitInput(val);
    }
    emitInputAsContacts(val) {
        return this.allContacts.filter((contact) => val && contact?.id && val.includes(contact.id));
    }
    emitInput(val) {
        this.emitInputAsContacts(val);
        return val;
    }
};
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], AppContactMultiple.prototype, "disabled", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof String !== "undefined" && String) === "function" ? _a : Object)
], AppContactMultiple.prototype, "label", void 0);
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], AppContactMultiple.prototype, "inlineForm", void 0);
__decorate([
    Prop({ required: false, type: String }),
    __metadata("design:type", String)
], AppContactMultiple.prototype, "inlineFormTitle", void 0);
__decorate([
    Prop({ type: [Array] }),
    __metadata("design:type", Array)
], AppContactMultiple.prototype, "messages", void 0);
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], AppContactMultiple.prototype, "required", void 0);
__decorate([
    Prop({ default: () => [], type: Array }),
    __metadata("design:type", Array)
], AppContactMultiple.prototype, "value", void 0);
__decorate([
    Emit('contacts-changed'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], AppContactMultiple.prototype, "emitInputAsContacts", null);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], AppContactMultiple.prototype, "emitInput", null);
AppContactMultiple = __decorate([
    Component
], AppContactMultiple);
export default AppContactMultiple;
