import gql from 'graphql-tag';
export const CosmicWorkingAssemblySlimFragment = gql `
  fragment CosmicWorkingAssemblySlim on WorkingAssembly {
    asBuiltNumber
    assemblyTemplateId
    attributes
    createdByUserId
    createdDateTime
    drawingNumber
    id
    installedOn
    nodeId
    parentId
    sequence
    side
  }
`;
