import { getBlurbAboutCreate, getBlurbAboutManage, getBlurbAboutSearch } from '~/nasa_ui/router/utils';
import { EntityType, EntityTypeSingularDisplay, UserPermission } from '~/nasa_ui/types';
export const LocationSharedRoutes = [
    {
        path: `/${EntityType.LOCATION}`,
        redirect: {
            name: 'LocationsSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.LOCATION)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.LOCATION)),
            permissions: [UserPermission.LOCATION_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.LOCATION}/manage`,
        redirect: {
            name: 'LocationsSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.LOCATION)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.LOCATION)),
            permissions: [UserPermission.LOCATION_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.LOCATION}/create`,
        name: 'LocationsCreateView',
        component: () => import(/* webpackChunkName: "Location" */ '../views/LocationsCreateView/LocationsCreateView.vue'),
        meta: {
            displayName: 'Locations create',
            description: getBlurbAboutCreate(EntityTypeSingularDisplay.get(EntityType.LOCATION)),
            permissions: [UserPermission.LOCATION_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.LOCATION}/manage/:id`,
        name: 'LocationsManageView',
        component: () => import(/* webpackChunkName: "Location" */ '../views/LocationsManageView/LocationsManageView.vue'),
        meta: {
            displayName: 'Locations manage',
            description: getBlurbAboutManage(EntityTypeSingularDisplay.get(EntityType.LOCATION)),
            permissions: [UserPermission.LOCATION_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.LOCATION}/search`,
        name: 'LocationsSearchView',
        component: () => import(/* webpackChunkName: "Location" */ '../views/LocationsSearchView/LocationsSearchView.vue'),
        meta: {
            displayName: 'Locations search',
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.LOCATION)),
            permissions: [UserPermission.LOCATION_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    }
];
