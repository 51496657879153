export var EntityTypeMode;
(function (EntityTypeMode) {
    EntityTypeMode["single"] = "single";
    EntityTypeMode["multi"] = "multi";
})(EntityTypeMode || (EntityTypeMode = {}));
export var EntitySelectorDisplayMode;
(function (EntitySelectorDisplayMode) {
    EntitySelectorDisplayMode["mini"] = "mini";
    EntitySelectorDisplayMode["table"] = "table";
})(EntitySelectorDisplayMode || (EntitySelectorDisplayMode = {}));
export var OutputMode;
(function (OutputMode) {
    OutputMode["string"] = "string";
    OutputMode["object"] = "object";
})(OutputMode || (OutputMode = {}));
