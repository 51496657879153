function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.computedTitle ? _c('AppHeader', {
    attrs: {
      "size": "mini"
    }
  }, [_vm._v(_vm._s(_vm.computedTitle))]) : _vm._e(), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.hasLocalEntity ? _c('div', {
    staticClass: "entity-mini",
    class: _defineProperty(_defineProperty({}, _vm.color, _vm.color), "hasNoActions", !_vm.hasAnyActions)
  }, [_vm.showEntityTypeIcon ? _c('c-icon-fa', {
    staticClass: "entity-mini-icon",
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.PHYSICAL_INVENTORY_AUDIT],
      "icon-size": "32px",
      "icon-color": "white"
    }
  }) : _vm._e(), _c('v-layout', {
    staticClass: "entity-mini-name text-truncate white--text"
  }, [_c('div', {
    staticClass: "mini-name text-truncate display-1",
    attrs: {
      "title": "PIA Name"
    }
  }, [_vm._v(_vm._s(_vm.name))])]), _c('div', {
    staticClass: "vertical-display-items hidden-xs-only white--text"
  }, _vm._l(_vm.verticalLabelDisplayItems, function (verticalDisplayItem) {
    return _c('div', {
      key: verticalDisplayItem.name,
      staticClass: "vertical-display-item text-truncate",
      class: verticalDisplayItem.class,
      style: verticalDisplayItem.style,
      attrs: {
        "title": verticalDisplayItem.title || verticalDisplayItem.value || ''
      }
    }, [_c('div', {
      staticClass: "mb-1 text-truncate"
    }, [_c('div', {
      directives: [{
        name: "safe-html",
        rawName: "v-safe-html",
        value: verticalDisplayItem.name,
        expression: "verticalDisplayItem.name"
      }]
    })]), _c('div', {
      staticClass: "text-truncate vertical-display-item-value"
    }, [_c('div', {
      directives: [{
        name: "safe-html",
        rawName: "v-safe-html",
        value: verticalDisplayItem.value,
        expression: "verticalDisplayItem.value"
      }]
    })])]);
  }), 0), _vm.hasAnyActions ? _c('div', {
    staticClass: "mini_actions"
  }, [_vm.shouldShowManageLink ? _c('router-link', {
    staticClass: "white--text",
    attrs: {
      "to": _vm.manageLink,
      "title": _vm.manageLinkTitle
    }
  }, [_c('c-icon-fa', {
    staticClass: "manage_icon",
    attrs: {
      "icon": _vm.manageIcon,
      "icon-color": "white"
    }
  })], 1) : _vm._e(), _vm.inlineClear && !_vm.readOnly ? _c('c-icon-fa', {
    staticClass: "inline_clear_icon",
    attrs: {
      "icon-color": "white",
      "tooltip": "Clear selection",
      "icon": _vm.inlineClearIcon
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.onClickOfInlineClear.apply(null, arguments);
      }
    }
  }) : _vm._e()], 1) : _vm._e()], 1) : _vm._e()])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };