var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.canNavigateToUser ? _c('button', {
    on: {
      "click": _vm.routeToUser
    }
  }, [_c('v-avatar', {
    staticClass: "canNavigateToUser avatar darken-1",
    attrs: {
      "color": _vm.EntityType.USER,
      "size": "40",
      "tooltip": "View your User profile",
      "tooltip-position": "bottom right"
    }
  }, [!_vm.currentUser ? _c('c-icon-fa', {
    attrs: {
      "icon": "fad fa-space-station-moon-alt",
      "icon-color": "white"
    }
  }) : _vm.isCosmic || !_vm.canUseInitials ? _c('c-icon-fa', {
    attrs: {
      "icon": _vm.randomIcon,
      "icon-color": "white"
    }
  }) : _vm.canUseInitials ? _c('span', {
    staticClass: "initials",
    attrs: {
      "aria-label": "user settings"
    }
  }, [_vm._v(_vm._s(_vm.initials))]) : _vm._e()], 1)], 1) : _c('button', [_c('v-avatar', {
    staticClass: "avatar darken-1",
    attrs: {
      "color": _vm.EntityType.USER,
      "size": "40"
    }
  }, [!_vm.currentUser ? _c('c-icon-fa', {
    attrs: {
      "icon": "fad fa-space-station-moon-alt",
      "icon-color": "white"
    }
  }) : _vm.isCosmic || !_vm.canUseInitials ? _c('c-icon-fa', {
    attrs: {
      "icon": _vm.randomIcon,
      "icon-color": "white"
    }
  }) : _vm.canUseInitials ? _c('span', {
    staticClass: "initials"
  }, [_vm._v(_vm._s(_vm.initials))]) : _vm._e()], 1)], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };