import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins } from 'vue-property-decorator';
import LoaderMixin from '~/nasa_ui/mixins/LoaderMixin';
import { mutationFactory, queryFactory } from '../base/mixinFactories';
import { CosmicItemMasterSlimFragment } from '../itemMaster/slim';
import { CosmicLocationSlimFragment } from '../location/slim';
import { CosmicOrganizationSlimFragment } from './slim';
export const CosmicResponsibleOrganizationFragment = gql `
  ${CosmicItemMasterSlimFragment}

  fragment CosmicResponsibleOrganization on ResponsibleOrganization {
    itemMaster {
      ...CosmicItemMasterSlim
    }
  }
`;
export const CosmicOrganizationFragment = gql `
  ${CosmicOrganizationSlimFragment}

  fragment CosmicOrganization on Organization {
    ...CosmicOrganizationSlim
  }
`;
export const CosmicOrganizationFullFragment = gql `
  ${CosmicLocationSlimFragment}
  ${CosmicOrganizationSlimFragment}
  ${CosmicResponsibleOrganizationFragment}

  fragment CosmicOrganizationFull on Organization {
    ...CosmicOrganizationSlim

    locations {
      nodes {
        ...CosmicLocationSlim
      }
    }

    responsibleOrganizations {
      nodes {
        ...CosmicResponsibleOrganization
      }
    }
  }
`;
export const GetAllUserSupportContextOrgs = gql `
  ${CosmicOrganizationSlimFragment}

  query GetAllUserSupportContextOrgs($userSupportOrgCodes: [String!]) {
    organizations(filter: { code: { in: $userSupportOrgCodes } }) {
      nodes {
        ...CosmicOrganizationSlim
      }
    }
  }
`;
export const CreateOrganization = gql `
  ${CosmicOrganizationSlimFragment}

  mutation CreateOrganization($organization: OrganizationInput!) {
    createOrganization(input: { organization: $organization }) {
      organization {
        ...CosmicOrganizationSlim
      }
    }
  }
`;
let OrganizationRepoMixin = class OrganizationRepoMixin extends Mixins(LoaderMixin) {
    // QUERIES
    getAllUserSupportContextOrgs = queryFactory(this, GetAllUserSupportContextOrgs);
    // MUTATIONS
    createOrganization = mutationFactory(this, CreateOrganization);
};
OrganizationRepoMixin = __decorate([
    Component
], OrganizationRepoMixin);
export { OrganizationRepoMixin };
