import parseISO from 'date-fns/parseISO';
/**
 * Takes a date in the form of either a Date object, full or partial date
 * string, or number. If passed a Date object, it simply returns it. If passed a
 * string or number, it converts it to a Date object, which it returns.
 * @param date The date to convert.
 * @returns a Date object representing the given date.
 */
export const dateFrom = (date) => {
    if (typeof date === 'string') {
        return parseISO(date);
    }
    if (typeof date === 'number') {
        return new Date(date);
    }
    return date;
};
