export var GloveCandidateUsage;
(function (GloveCandidateUsage) {
    GloveCandidateUsage["FLIGHT"] = "FLIGHT";
    GloveCandidateUsage["GROUND"] = "GROUND";
    GloveCandidateUsage["TRAINING"] = "TRAINING";
})(GloveCandidateUsage || (GloveCandidateUsage = {}));
export const GloveCandidateUsageDisplay = new Map([
    [GloveCandidateUsage.FLIGHT, 'Flight'],
    [GloveCandidateUsage.GROUND, 'Ground'],
    [GloveCandidateUsage.TRAINING, 'Training']
]);
export var GloveCandidateRank;
(function (GloveCandidateRank) {
    GloveCandidateRank["PRIME"] = "PRIME";
    GloveCandidateRank["BACKUP"] = "BACKUP";
    GloveCandidateRank["OFF_NOMINAL"] = "OFF_NOMINAL";
})(GloveCandidateRank || (GloveCandidateRank = {}));
export const GloveCandidateRankDisplay = new Map([
    [GloveCandidateRank.PRIME, 'Prime'],
    [GloveCandidateRank.BACKUP, 'Backup'],
    [GloveCandidateRank.OFF_NOMINAL, 'Off-Nominal']
]);
