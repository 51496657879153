var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "elevation-2 cosmic_table cosmic_table_actions app_table_actions",
    class: {
      sticky: _vm.sticky
    },
    attrs: {
      "entity-type": _vm.computedColor
    }
  }, [_vm.shouldDisplayHeader ? _c('v-toolbar', {
    attrs: {
      "color": _vm.computedColor,
      "dark": _vm.dark,
      "dense": _vm.dense,
      "flat": ""
    },
    on: {
      "dblclick": _vm.onDoubleClickOfTableToolbar
    }
  }, [_vm.computedIcon ? _c('c-icon-fa', {
    staticClass: "cosmic_table_header_icon",
    attrs: {
      "icon-color": _vm.computedIconColor,
      "icon": _vm.computedIcon
    }
  }) : _vm._e(), _vm.computedTitle || _vm.hasTitleSlot ? _c('v-toolbar-title', {
    staticClass: "cosmic_table_title"
  }, [_vm.hasTitleSlot ? _vm._t("title", function () {
    return [_vm._v(_vm._s(_vm.computedTitle))];
  }) : _c('div', [_c('span', {
    directives: [{
      name: "safe-html",
      rawName: "v-safe-html",
      value: _vm.computedTitle,
      expression: "computedTitle"
    }]
  }), _vm.showCount ? _c('span', [_vm._v(" ( "), _c('small', {
    staticClass: "monospace_font"
  }, [_c('strong', [_vm._v(_vm._s(_vm.$numberDisplay(_vm.computedItems.length)))])]), _vm._v(" ) ")]) : _vm._e()])], 2) : _vm._e(), _vm._t("headerBeforeSearchInput", function () {
    return [_c('v-spacer')];
  }), _vm._t("headerSearchInput", function () {
    return [_vm.computedShowSearchInput ? _c('v-text-field', {
      staticClass: "cosmic_filter_table",
      attrs: {
        "append-icon": _vm.$icons.filter,
        "color": _vm.computedColor,
        "clearable": "",
        "hide-details": "",
        "light": "",
        "placeholder": "Filter",
        "solo": ""
      },
      on: {
        "input": _vm.emitSearchFilterInput,
        "keyup": function keyup($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
          return _vm.clearSearchFilter.apply(null, arguments);
        }
      },
      model: {
        value: _vm.searchFilter,
        callback: function callback($$v) {
          _vm.searchFilter = $$v;
        },
        expression: "searchFilter"
      }
    }) : _vm._e()];
  }), _vm._t("headerAfterSearchInput")], 2) : _vm._e(), _vm.shouldShowTable ? _c('v-data-table', {
    class: _vm.computedClass,
    attrs: {
      "custom-sort": _vm.customSort,
      "disable-initial-sort": _vm.disableInitialSort,
      "headers": _vm.computedTableHeadersWithActionsColumn,
      "hide-actions": _vm.shouldHidePagination,
      "item-key": _vm.itemKey,
      "items": _vm.computedItems,
      "loading": _vm.isLoading,
      "pagination": _vm.pagination,
      "rows-per-page-items": _vm.rowsPerPageItems,
      "search": _vm.computedSearchFilter,
      "total-items": _vm.totalItems
    },
    on: {
      "update:pagination": function updatePagination($event) {
        _vm.pagination = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "progress",
      fn: function fn() {
        return [_c('AppLoader', {
          attrs: {
            "show": _vm.isLoading,
            "color": _vm.computedColor,
            "type": "linear"
          }
        })];
      },
      proxy: true
    }, {
      key: "headers",
      fn: function fn(props) {
        return [_c('tr', _vm._l(props.headers, function (header) {
          return _c('th', {
            key: header.text,
            staticClass: "column",
            class: [_vm.getAlignClass(header), _vm.pagination.descending ? 'desc' : 'asc', header.value === _vm.pagination.sortBy ? 'active' : '', header.sortable ? 'sortable' : '', header.class ? header.class : ''],
            attrs: {
              "width": header.width
            },
            on: {
              "click": function click($event) {
                return _vm.changeSort(header);
              }
            }
          }, [_c('span', {
            directives: [{
              name: "safe-html",
              rawName: "v-safe-html",
              value: header.text,
              expression: "header.text"
            }],
            staticClass: "d-inline-block"
          }), header.sortable && header.value === _vm.pagination.sortBy ? _c('c-icon-fa', {
            staticClass: "cosmic_sort_arrow pl-1",
            attrs: {
              "icon-size": "14px",
              "icon": "fa-solid fa-arrow-up",
              "flip-vertical": _vm.pagination.descending
            }
          }) : _vm._e()], 1);
        }), 0)];
      }
    }, {
      key: "items",
      fn: function fn(props) {
        return [_c('tr', {
          staticClass: "cosmic_table_row",
          class: "".concat(props.item.rowClass ? props.item.rowClass : '')
        }, [_vm._l(_vm.computedTableHeaders, function (header) {
          return _c('td', {
            key: header.value,
            class: [_vm.getAlignClass(header), header.noWrap ? 'no-wrap' : '', _vm.addMonospaceIfNeeded(header), header.tdClass ? header.tdClass : '', props.item.rowClass ? props.item.rowClass : ''],
            on: {
              "click": function click($event) {
                return _vm.onClickOfRow(props.item, $event);
              },
              "dblclick": function dblclick($event) {
                return _vm.onDblClickOfRow(props.item, $event);
              }
            }
          }, [_vm.hasSlot(header.value) ? _vm._t(header.value, null, {
            "defaultData": _vm.getTableData(header, props.item),
            "header": header,
            "item": props.item
          }) : _vm._t("table-cell", function () {
            return [_vm.isBlockOfTextHeader(header) ? _c('div', {
              staticClass: "block_of_text py-2 text-truncate",
              style: {
                width: header.width
              },
              attrs: {
                "title": _vm.getTableData(header, props.item)
              }
            }, [_vm._v(" " + _vm._s(_vm.getTableData(header, props.item)) + " ")]) : _c('div', {
              directives: [{
                name: "safe-html",
                rawName: "v-safe-html",
                value: _vm.getTableData(header, props.item),
                expression: "getTableData(header, props.item)"
              }],
              staticClass: "py-2"
            })];
          }, {
            "defaultData": _vm.getTableData(header, props.item),
            "header": header,
            "item": props.item
          })], 2);
        }), _c('td', {
          class: "".concat(props.item.rowClass ? props.item.rowClass : '')
        }, [_c('div', {
          staticClass: "actions"
        }, [_vm._t("actions", null, {
          "item": props.item
        })], 2)])], 2)];
      }
    }], null, true)
  }, [!_vm.loading ? _c('Alert', {
    attrs: {
      "slot": "no-data",
      "type": _vm.AlertType.WARNING
    },
    slot: "no-data"
  }, [_vm._v("No results found")]) : _c('span', {
    attrs: {
      "slot": "no-data"
    },
    slot: "no-data"
  }), _c('Alert', {
    attrs: {
      "slot": "no-results",
      "type": _vm.AlertType.WARNING
    },
    slot: "no-results"
  }, [_vm._v(" No "), _c('strong', [_vm._v("filtered")]), _vm._v(" results found ")])], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };