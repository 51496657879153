import { ItemInstanceInventoryTypeDisplay } from '~/nasa_ui/types';
import { addAsBuiltNumberDisplay, addDateDisplays, addDescriptionDisplay, addItemNumberDisplay, addLotNumberDisplay, addSerialNumberDisplay, addSideDisplay, addSizeDisplay, addSubTypeDisplay, addUserDisplays } from '../displayMappers';
export const transformAssemblies = (items) => {
    return items
        .map(addDateDisplays)
        .map(addDescriptionDisplay)
        .map(addLotNumberDisplay)
        .map(addSerialNumberDisplay)
        .map(addSideDisplay)
        .map(addSizeDisplay)
        .map(addSubTypeDisplay(ItemInstanceInventoryTypeDisplay))
        .map(addUserDisplays)
        .map(addAsBuiltNumberDisplay)
        .map(addItemNumberDisplay)
        .map((assy) => {
        const _assy = { ...assy };
        return _assy;
    });
};
