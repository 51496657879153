function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "cosmic_app_markdown"
  }, [_c('div', {
    staticClass: "cosmic_app_markdown_label",
    class: {
      required: _vm.required && !_vm.value
    }
  }, [_vm._v(_vm._s(_vm.label))]), _c('v-toolbar', {
    class: {
      required: _vm.required && !_vm.value
    },
    attrs: {
      "dense": "",
      "flat": ""
    }
  }, [_c('ButtonGeneric', {
    staticClass: "ml-0",
    class: {
      active_view_btn: _vm.shouldShowTextarea
    },
    attrs: {
      "flat": "",
      "text-color": "var(--cosmic-text)"
    },
    on: {
      "click": _vm.onClickOfWrite
    }
  }, [_vm._v(" Write ")]), _c('ButtonGeneric', {
    class: {
      active_view_btn: _vm.shouldShowMarkdownDisplay
    },
    attrs: {
      "flat": "",
      "text-color": "var(--cosmic-text)"
    },
    on: {
      "click": _vm.onClickOfPreview
    }
  }, [_vm._v(" Preview ")]), _c('v-spacer'), _c('div', {
    staticClass: "toolbar_icons"
  }, [_c('ButtonMiniAction', {
    staticClass: "pr-1",
    attrs: {
      "disabled": !_vm.isAnythingSelected,
      "icon": "fal fa-bold",
      "icon-size": "18px",
      "icon-color": "var(--cosmic-text)",
      "tooltip": "Bolds the selected text",
      "tooltip-position": "left"
    },
    on: {
      "click": _vm.onClickOfBold
    }
  }), _c('ButtonMiniAction', {
    staticClass: "pr-1",
    attrs: {
      "disabled": !_vm.isAnythingSelected,
      "icon": "fal fa-italic",
      "icon-size": "18px",
      "icon-color": "var(--cosmic-text)",
      "tooltip": "Italicises the selected text",
      "tooltip-position": "left"
    },
    on: {
      "click": _vm.onClickOfItalic
    }
  }), _c('ButtonMiniAction', {
    staticClass: "pr-1",
    attrs: {
      "disabled": !_vm.isAnythingSelected,
      "icon": "fal fa-strikethrough",
      "icon-size": "18px",
      "icon-color": "var(--cosmic-text)",
      "tooltip": "Strikes through the selected text",
      "tooltip-position": "left"
    },
    on: {
      "click": _vm.onClickOfStrikethrough
    }
  }), _c('ButtonMiniAction', {
    attrs: {
      "disabled": _vm.shouldShowMarkdownDisplay,
      "icon": "fas fa-quote-right",
      "icon-size": "18px",
      "icon-color": "var(--cosmic-text)",
      "tooltip": "Insert a quote",
      "tooltip-position": "left"
    },
    on: {
      "click": _vm.onClickOfQuote
    }
  })], 1)], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowTextarea ? _c('AppTextarea', _vm._g(_vm._b({
    ref: "app_markdown_textarea",
    attrs: {
      "outline": false,
      "placeholder": _vm.$props.placeholder,
      "required": _vm.required,
      "value": _vm.value
    },
    on: {
      "input": _vm.emitInput
    }
  }, 'AppTextarea', _objectSpread({}, _vm.$attrs), false), _vm.$listeners)) : _vm._e()], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowMarkdownDisplay ? _c('AppMarkdownDisplay', {
    staticClass: "markdown_display",
    attrs: {
      "markdown": _vm.value
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };