import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { DeliveryOrderLineItemStatusDisplay, DeliveryOrderLineItemTypeDisplay, DocumentTypeDisplay, NeedTypeDisplay } from '~/nasa_ui/types';
import { sortObjectBy } from '../..';
import { addContactDisplays, addDateDisplays, addDescriptionDisplay, addNameDisplay, addSubTypeDisplay, addTitleDisplay, addUserDisplays } from '../displayMappers';
import { contactDisplay, numberDisplay } from '../displays';
import { transformPartReferenceResponses } from '../transformEntityToTableFormat';
export const transformDocumentResponses = (items) => {
    return items
        .map(addDateDisplays)
        .map(addNameDisplay)
        .map(addSubTypeDisplay(DocumentTypeDisplay))
        .map(addTitleDisplay)
        .map(addUserDisplays)
        .map((item) => {
        const _item = { ...item };
        // _item._urlManage = _item.id ? `/hardware/lists/manage/${_item.id}#information` : '';
        return _item;
    });
};
export const transformDocumentResponseWithRefs = (items) => {
    return items
        .map(addDateDisplays)
        .map(addNameDisplay)
        .map(addSubTypeDisplay(DocumentTypeDisplay))
        .map(addUserDisplays)
        .map((item) => {
        const _item = { ...item };
        // _item._urlManage = _item.id ? `/hardware/lists/manage/${_item.id}#information` : '';
        return _item;
    });
};
export const transformDeliveryOrderResponses = (items) => {
    return items
        .map(addDateDisplays)
        .map(addDescriptionDisplay)
        .map(addNameDisplay)
        .map(addSubTypeDisplay(DocumentTypeDisplay))
        .map(addUserDisplays)
        .map(addTitleDisplay)
        .map((item) => {
        const _item = { ...item };
        _item._computedRevisionNumber = _item.overrideRevisionNumber || _item.revisionNumber?.toString() || DEFAULT_DASH;
        _item._numberPlusRev =
            _item._computedRevisionNumber !== DEFAULT_DASH
                ? `${_item.number} R${_item._computedRevisionNumber}`
                : `${_item.number}`;
        _item._urlManage = _item.id ? `/delivery_orders/manage/${_item.id}#information` : '';
        return _item;
    });
};
export const transformDeliveryOrderResponseWithRefs = (items) => {
    return items
        .map(addDateDisplays)
        .map(addDescriptionDisplay)
        .map(addNameDisplay)
        .map(addSubTypeDisplay(DocumentTypeDisplay))
        .map(addUserDisplays)
        .map(addTitleDisplay)
        .map((item) => {
        const _item = { ...item };
        _item._computedRevisionNumber = _item.overrideRevisionNumber || _item.revisionNumber?.toString() || DEFAULT_DASH;
        _item._numberPlusRev =
            _item._computedRevisionNumber !== DEFAULT_DASH
                ? `${_item.number} R${_item._computedRevisionNumber}`
                : `${_item.number}`;
        _item._urlManage = _item.id ? `/delivery_orders/manage/${_item.id}#information` : '';
        _item._lineItems = transformDeliveryOrderLineItemCollectionResponse(_item.lineItems);
        return _item;
    });
};
export const transformDeliveryOrderLineItemResponses = (items) => {
    return items
        .map(addDateDisplays)
        .map(addUserDisplays)
        .map(addContactDisplays)
        .map((item) => {
        const _item = { ...item };
        _item._deliveryItemsListNumber = _item.deliveryItemsListNumber || DEFAULT_DASH;
        _item._deliveryOrderLineItemStatus = _item.deliveryOrderLineItemStatus
            ? DeliveryOrderLineItemStatusDisplay.get(_item.deliveryOrderLineItemStatus) || DEFAULT_DASH
            : DEFAULT_DASH;
        _item._jDocumentNumber = _item.jDocumentNumber || DEFAULT_DASH;
        _item._lineItemType = _item.lineItemType
            ? DeliveryOrderLineItemTypeDisplay.get(_item.lineItemType) || DEFAULT_DASH
            : DEFAULT_DASH;
        _item._needType = _item.need ? NeedTypeDisplay.get(_item.need) || DEFAULT_DASH : DEFAULT_DASH;
        const _partReference = transformPartReferenceResponses([_item.partReference])[0];
        _item._partReference = _partReference;
        _item._quantity = numberDisplay(_item.lineItemQuantity);
        _item._shipperNumber = _item.shipperNumber || DEFAULT_DASH;
        _item._workBreakdownStructureNumber = _item.workBreakdownStructureNumber || DEFAULT_DASH;
        return _item;
    });
};
/**
 * autoHardwareLists: AutoHardwareListResponse[] | null;
 * changeSets: DeliveryOrderLineItemResponse[] | null;
 * contacts: ContactResponse[] | null;
 * documents: DeliveryOrderResponse[] | null;   <--- this one has documents
 * itemDrawings: ItemDrawingResponse[] | null;
 * itemInstances: ItemInstanceResponse[] | null;
 * itemMasters: ItemMasterResponse[] | null;
 *
 * @param item
 * @returns
 */
export const transformDeliveryOrderLineItemCollectionResponseWithDocuments = (item) => {
    const _item = { ...item };
    // changeSets
    _item._changeSets = transformDeliveryOrderLineItemResponses(item.changeSets || []).map((doli) => {
        const _doli = { ...doli };
        if (_doli.documentId && item) {
            const foundDo = item.documents.find((deeoh) => deeoh.id === _doli.documentId);
            if (foundDo) {
                const transformedDo = transformDeliveryOrderResponses([foundDo])[0];
                _doli._deliveryOrderNumber = transformedDo._numberPlusRev;
            }
        }
        if (_doli.ownerContactId && item) {
            const foundContact = item.contacts?.find((c) => c.id === _doli.ownerContactId);
            if (foundContact) {
                _doli._ownerContact = contactDisplay(foundContact);
            }
        }
        if (_doli.autoHardwareListIds && item) {
            _doli._autoHardwareLists = _doli.autoHardwareListIds
                .map((ahlid) => {
                return item?.autoHardwareLists?.find((ahl) => ahl.id === ahlid);
            })
                .sort(sortObjectBy('name'));
        }
        return _doli;
    });
    return _item;
};
/**
 * autoHardwareLists: AutoHardwareListResponse[] | null;
 * changeSets: DeliveryOrderLineItemResponse[] | null;
 * contacts: ContactResponse[] | null;
 * documents: DeliveryOrderResponse[] | null;
 * itemDrawings: ItemDrawingResponse[] | null;
 * itemInstances: ItemInstanceResponse[] | null;
 * itemMasters: ItemMasterResponse[] | null;
 *
 * @param item
 * @returns
 */
export const transformDeliveryOrderLineItemCollectionResponse = (item) => {
    const _item = { ...item };
    // changeSets
    _item._changeSets = transformDeliveryOrderLineItemResponses(item.changeSets || []).map((doli) => {
        const _doli = { ...doli };
        if (_doli.ownerContactId && item) {
            const foundContact = item.contacts?.find((c) => c.id === _doli.ownerContactId);
            if (foundContact) {
                _doli._ownerContact = contactDisplay(foundContact);
            }
        }
        if (_doli.autoHardwareListIds && item) {
            _doli._autoHardwareLists = _doli.autoHardwareListIds
                .map((ahlid) => {
                return item?.autoHardwareLists?.find((ahl) => ahl.id === ahlid);
            })
                .sort(sortObjectBy('name'));
        }
        return _doli;
    });
    return _item;
};
// #endregion
