import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { CosmicUserSupportContext, UserGroupTypeDisplay, UserRoleDisplay } from '~/nasa_ui/types';
import { addDateDisplays, addFullName, addNameDisplay, addSubTypeDisplay, addUserDisplays } from '../displayMappers';
import { contactDisplay, numberDisplay } from '../displays';
export const transformContactResponses = (items) => {
    return items
        .map(addDateDisplays)
        .map(addFullName)
        .map(addUserDisplays)
        .map((item) => {
        const _item = { ...item };
        _item._auid = _item.auid ?? DEFAULT_DASH;
        _item._displayName = contactDisplay(_item);
        _item._firstName = _item.firstName ?? DEFAULT_DASH;
        _item._id = _item.id;
        _item._lastName = _item.lastName ?? DEFAULT_DASH;
        _item._middleName = _item.middleName ?? DEFAULT_DASH;
        _item._uupic = _item.uupic ?? DEFAULT_DASH;
        // Email formatting
        if (!_item.email) {
            _item._email = '';
        }
        if (!_item.email?.length) {
            _item._email = DEFAULT_DASH;
        }
        else if (item.email?.length === 1) {
            _item._email = `<a class="user-email" href="mailto:${_item.email[0]}">${_item.email[0]}</a>` ?? '';
        }
        else {
            _item._email = item.email
                ? item.email.map((e) => `<a class="user-email" href="mailto:${e}">${e}</a>`).join('')
                : DEFAULT_DASH;
        }
        return _item;
    });
};
export const transformUserResponses = (items) => {
    return items
        .map(addDateDisplays)
        .map(addFullName)
        .map(addUserDisplays)
        .map((item) => {
        const _item = { ...item };
        _item._auid = _item.auid ?? DEFAULT_DASH;
        _item._displayName = contactDisplay(_item);
        _item._firstName = _item.firstName ?? DEFAULT_DASH;
        _item._id = _item.id;
        _item._lastName = _item.lastName ?? DEFAULT_DASH;
        _item._middleName = _item.middleName ?? DEFAULT_DASH;
        _item._userRole = item.role ? UserRoleDisplay.get(item.role) || DEFAULT_DASH : DEFAULT_DASH;
        _item._uupic = _item.uupic ?? DEFAULT_DASH;
        // Email formatting
        if (!_item.email) {
            _item._email = '';
        }
        if (!_item.email?.length) {
            _item._email = DEFAULT_DASH;
        }
        else if (item.email?.length === 1) {
            _item._email = `<a class="user-email" href="mailto:${_item.email[0]}">${_item.email[0]}</a>` ?? '';
        }
        else {
            _item._email = item.email
                ? item.email.map((e) => `<a class="user-email" href="mailto:${e}">${e}</a>`).join('')
                : DEFAULT_DASH;
        }
        // @ts-ignore
        if (_item.supports) {
            // @ts-ignore
            const validSupportContexts = _item.supports.filter((support) => support.organizationCode in CosmicUserSupportContext);
            const validSupportContextsDisplay = validSupportContexts
                ? validSupportContexts
                    ?.map((support) => support?.organizationCode)
                    .sort()
                    .join(', ')
                : DEFAULT_DASH;
            _item._supports = validSupportContextsDisplay;
        }
        return _item;
    });
};
export const transformUserGroups = (items) => {
    return items
        .map(addUserDisplays)
        .map(addDateDisplays)
        .map(addSubTypeDisplay(UserGroupTypeDisplay))
        .map((item) => {
        const _item = { ...item };
        _item._totalCount = numberDisplay(_item.usersToUserGroups?.totalCount || _item.usersToUserGroups?.nodes?.length || 0);
        const users = (_item.usersToUserGroups?.nodes || []).map((userToUg) => {
            return userToUg.user;
        });
        _item._users = transformUserResponses(users);
        _item._displayName = _item.name || _item.code || DEFAULT_DASH;
        return _item;
    });
};
export const transformUserGroupResponses = (items) => {
    return items
        .map(addUserDisplays)
        .map(addDateDisplays)
        .map(addNameDisplay)
        .map(addSubTypeDisplay(UserGroupTypeDisplay))
        .map((item) => {
        const _item = { ...item };
        _item._displayName = _item.name || _item.code || DEFAULT_DASH;
        return _item;
    });
};
export const transformUserGroupResponseWithRefs = (items) => {
    return items
        .map(addUserDisplays)
        .map(addDateDisplays)
        .map(addNameDisplay)
        .map(addSubTypeDisplay(UserGroupTypeDisplay))
        .map((item) => {
        const _item = { ...item };
        _item._totalCount = numberDisplay(_item.users?.length || 0);
        _item._users = transformUserResponses(item.users || []);
        _item._displayName = _item.name || _item.code || DEFAULT_DASH;
        return _item;
    });
};
export const transformUserGroupResponseWithSupportContexts = (items) => {
    return items
        .map(addUserDisplays)
        .map(addDateDisplays)
        .map(addNameDisplay)
        .map(addSubTypeDisplay(UserGroupTypeDisplay))
        .map((item) => {
        const _item = { ...item };
        _item._displayName = _item.name || _item.code || DEFAULT_DASH;
        return _item;
    });
};
