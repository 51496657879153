import gql from 'graphql-tag';
export const CosmicChangeSetSlimFragment = gql `
  fragment CosmicChangeSetSlim on ChangeSet {
    asBuiltNumber
    authorityCode
    authorityNumber
    childInventoryId
    computedClosedDate
    computedDocumentRevisionNumber
    computedStatus
    createdByUserId
    createdDateTime
    documentId
    drawingNumber
    id
    inventoryId
    isApplied
    isLineItem
    itemInstanceId
    nodeId
    openedDate
    payload
    projectedDate
    side
    subType
    userGroupCode
  }
`;
export const CosmicChangeSetAuthoritySlimFragment = gql `
  fragment CosmicChangeSetAuthoritySlim on ChangeSetAuthority {
    authorityType
    authorizedByUserId
    authorizedDate
    computedCommentText
    groupCode
    id
    isHistorical
    manualInspectionPointExplanation
    manualInspectionPointType
    nodeId
    qualityControlStamp
    requiresComment
    requiresQualityControlStamp
    status
    subType
  }
`;
export const CosmicChangeSetStatusSlimFragment = gql `
  fragment CosmicChangeSetStatusSlim on ChangeSetStatusInfo {
    closedByUserId
    closedDate
    isNew
    isReopen
    lastAuthorityId
    nextAuthorityId
    openedByUserId
    openedDate
    status
    subType
  }
`;
export const CosmicActionSlimFragment = gql `
  fragment CosmicActionSlim on CosmicAction {
    attributes
    createdByUserId
    createdDateTime
    description
    documentId
    dueDate
    id
    isApplied
    isOverdue
    nodeId
    openedDate
    payload
    percentComplete
    priority
    projectedDate
    subType
  }
`;
