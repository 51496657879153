var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.markdown ? _c('div', {
    directives: [{
      name: "safe-html",
      rawName: "v-safe-html",
      value: _vm.htmlFromMarkdown,
      expression: "htmlFromMarkdown"
    }],
    staticClass: "markdown-body",
    style: _vm.style
  }) : _c('div', {
    staticClass: "markdown_no_result"
  }, [_vm._v(" " + _vm._s(_vm.noMarkdownText || '-') + " ")])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };