import { differenceInDays } from 'date-fns';
import { isEmpty } from 'lodash';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { IntervalEnum } from '~/nasa_ui/types/enums/interval';
// Constants
const DAY_IN_HOURS = 24;
const DAY_IN_SECONDS = 86400;
const HOUR_IN_MINUTES = 60;
const MINUTE_IN_SECONDS = 60;
const MONTH_IN_DAYS = 30; // database rule, just default to 30 days per month
const YEAR_IN_DAYS = 360; // database rule, because of 30 day months we get a 360 day year
const YEAR_IN_MONTHS = 12;
export const addIntervals = (intervals) => {
    return intervals.reduce((total, val) => {
        Object.keys(val).forEach((key) => {
            if (key in total) {
                total[key] += val[key];
                return;
            }
            total[key] = val[key];
        });
        return total;
    }, {});
};
/**
 * @param {Interval | null | undefined} a
 * @param {Interval | null | undefined} b
 *
 * Receives two Intervals (null or undefined reduces to null), runs them through
 * `reduceIntervalToLargestMeasurement`, stringifies them into JSON, then
 * compares the strings.
 *
 * This might not be the most efficient way to do this but it's probably the
 * most straightforward way. There could be edge cases this function doesn't
 * account for.
 */
export const areIntervalsEqual = (a, b) => {
    const reducedA = a ? JSON.stringify(reduceIntervalToLargestMeasurement(a)) : null;
    const reducedB = b ? JSON.stringify(reduceIntervalToLargestMeasurement(b)) : null;
    return reducedA === reducedB;
};
/**
 * Adds n number of Intervals together and normalizes the keys so that each
 * key's excess time is converted up to the next highest unit, up to the
 * specified limit.
 *
 * @param intervals - an array of Intervals you want to combine
 * @param highestUnit - the highest unit you want to use in the returned
 * interval. Default: 'years'
 * @returns a new Interval
 */
export const combineIntervals = (intervals, highestUnit = 'years') => {
    let combinedIntervals = intervals.reduce((agg, val) => {
        if (!val) {
            return agg;
        }
        const keysWithValues = Object.keys(val).filter((k) => (typeof val[k] === 'number' || val[k]) && k !== '__typename');
        keysWithValues.forEach((key) => {
            agg[key] = (agg[key] || 0) + val[key];
        });
        return agg;
    }, {});
    const convertExcess = (interval, unit, nextUnit, timeConstant) => {
        if (interval[unit] && interval[unit] >= timeConstant) {
            const incrementNextUnitBy = Math.floor(interval[unit] / timeConstant);
            interval[unit] = interval[unit] % timeConstant;
            interval[nextUnit] = (interval[nextUnit] || 0) + incrementNextUnitBy;
        }
        return convertZeroKeysToNull(interval);
    };
    // convert excess seconds to minutes
    combinedIntervals = convertExcess(combinedIntervals, 'seconds', 'minutes', MINUTE_IN_SECONDS);
    // convert excess minutes to hours
    combinedIntervals = convertExcess(combinedIntervals, 'minutes', 'hours', HOUR_IN_MINUTES);
    // convert excess hours to days
    combinedIntervals = convertExcess(combinedIntervals, 'hours', 'days', DAY_IN_HOURS);
    // convert excess days to months
    combinedIntervals = convertExcess(combinedIntervals, 'days', 'months', MONTH_IN_DAYS);
    // convert excess months to years
    combinedIntervals = convertExcess(combinedIntervals, 'months', 'years', YEAR_IN_MONTHS);
    if (highestUnit === 'years') {
        return combinedIntervals;
    }
    return limitInterval(combinedIntervals, highestUnit);
};
/**
 * Loops through the keys of an Interval, converting values of `0` to `null`.
 *
 * @param interval - the interval to be converted
 * @returns a new Interval
 */
export const convertZeroKeysToNull = (interval) => {
    const copy = Object.assign({}, interval);
    Object.keys(interval).forEach((key) => {
        if (copy[key] === 0) {
            copy[key] = null;
        }
    });
    return copy;
};
export const daysFromDateAsInterval = (startDate, endDate) => {
    return {
        days: differenceInDays(new Date(endDate), new Date(startDate))
    };
};
/**
 * Finds the largest Interval unit that can evenly hold the given amount of time
 * (in seconds).
 *
 * @param input - a number of seconds
 * @returns key of Interval
 */
export const findLargestPossibleInterval = (input) => {
    // figure out what the largest interval is that we
    // can set the interval value to as a whole
    let secondsTotal = input;
    let intervalFlag = IntervalEnum.seconds; // default
    if ((secondsTotal / MINUTE_IN_SECONDS) % 1 === 0) {
        secondsTotal = secondsTotal / MINUTE_IN_SECONDS;
        intervalFlag = IntervalEnum.minutes;
    }
    else if ((secondsTotal / MINUTE_IN_SECONDS) % 1 !== 0) {
        return intervalFlag;
    }
    if ((secondsTotal / HOUR_IN_MINUTES) % 1 === 0) {
        secondsTotal = secondsTotal / HOUR_IN_MINUTES;
        intervalFlag = IntervalEnum.hours;
    }
    else if ((secondsTotal / HOUR_IN_MINUTES) % 1 !== 0) {
        return intervalFlag;
    }
    if ((secondsTotal / DAY_IN_HOURS) % 1 === 0) {
        secondsTotal = secondsTotal / DAY_IN_HOURS;
        intervalFlag = IntervalEnum.days;
    }
    else if ((secondsTotal / DAY_IN_HOURS) % 1 !== 0) {
        return intervalFlag;
    }
    if ((secondsTotal / MONTH_IN_DAYS) % 1 === 0) {
        secondsTotal = secondsTotal / MONTH_IN_DAYS;
        intervalFlag = IntervalEnum.months;
    }
    else if ((secondsTotal / MONTH_IN_DAYS) % 1 !== 0) {
        return intervalFlag;
    }
    if ((secondsTotal / YEAR_IN_MONTHS) % 1 === 0) {
        secondsTotal = secondsTotal / YEAR_IN_MONTHS;
        intervalFlag = IntervalEnum.years;
    }
    else if ((secondsTotal / YEAR_IN_MONTHS) % 1 !== 0) {
        return intervalFlag;
    }
    return intervalFlag;
};
export const getIntervalDifferenceFromDates = (dateOne, dateTwo) => {
    // convert to numbers
    const first = +dateOne;
    const second = +dateTwo;
    let diffInMilliSeconds = Math.abs(first - second) / 1000;
    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;
    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;
    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;
    return {
        days,
        hours,
        minutes
    };
};
/**
 * Given an Interval, returns the time duration of the interval in seconds.
 *
 * @param input - an Interval
 * @returns - a number of seconds representing the time duration of the interval
 */
export const getTotalInSeconds = (input) => {
    let totalInSeconds = 0;
    // Take the interval and reduce it to a value in seconds
    Object.keys(input).forEach((key) => {
        const intervalValue = input[key];
        if (!intervalValue) {
            return;
        }
        if (key === IntervalEnum.seconds) {
            totalInSeconds = totalInSeconds + intervalValue;
        }
        if (key === IntervalEnum.minutes) {
            const MULTIPLIER = MINUTE_IN_SECONDS;
            totalInSeconds = totalInSeconds + intervalValue * MULTIPLIER;
        }
        if (key === IntervalEnum.hours) {
            const MULTIPLIER = MINUTE_IN_SECONDS * HOUR_IN_MINUTES;
            totalInSeconds = totalInSeconds + intervalValue * MULTIPLIER;
        }
        if (key === IntervalEnum.days) {
            const MULTIPLIER = MINUTE_IN_SECONDS * HOUR_IN_MINUTES * DAY_IN_HOURS;
            totalInSeconds = totalInSeconds + intervalValue * MULTIPLIER;
        }
        if (key === IntervalEnum.months) {
            const MULTIPLIER = MINUTE_IN_SECONDS * HOUR_IN_MINUTES * DAY_IN_HOURS * MONTH_IN_DAYS;
            totalInSeconds = totalInSeconds + intervalValue * MULTIPLIER;
        }
        if (key === IntervalEnum.years) {
            const MULTIPLIER = DAY_IN_SECONDS * YEAR_IN_DAYS;
            totalInSeconds = totalInSeconds + intervalValue * MULTIPLIER;
        }
    });
    return totalInSeconds;
};
/**
 * Returns a displayable format for intervals
 *
 * @param val Interval
 * @param absoluteValue
 */
export const intervalDisplay = (val, absoluteValue = false) => {
    const intervalAbbr = new Map([['months', 'M']]);
    if (!val || isEmpty(val)) {
        return DEFAULT_DASH;
    }
    // The items we care to display
    const ordered = {};
    if (val.years) {
        ordered.years = absoluteValue ? Math.abs(val.years) : val.years;
    }
    if (val.months) {
        ordered.months = absoluteValue ? Math.abs(val.months) : val.months;
    }
    if (val.days) {
        ordered.days = absoluteValue ? Math.abs(val.days) : val.days;
    }
    if (val.hours) {
        ordered.hours = absoluteValue ? Math.abs(val.hours) : val.hours;
    }
    if (val.minutes) {
        ordered.minutes = absoluteValue ? Math.abs(val.minutes) : val.minutes;
    }
    if (val.seconds) {
        ordered.seconds = absoluteValue ? Math.abs(val.seconds) : val.seconds;
    }
    return Object.entries(ordered)
        .filter((item) => item[0] !== '__typename')
        .filter((item) => item[1] !== null)
        .map((item) => `${item[1]}${intervalAbbr.get(item[0]) || item[0].substr(0, 1)}`)
        .join(' ');
};
/**
 * If _any_ property is negative the entire Interval is considered negative.
 *
 * @param interval
 * @returns a new Interval
 */
export const isIntervalNegative = (interval) => {
    return Object.values(interval).some((val) => val && val < 0);
};
export const leadTimeToInterval = (value, unit) => {
    if (!unit) {
        return null;
    }
    const interval = {};
    interval[unit] = value;
    return interval;
};
/**
 * Given a unit of time as a limit (eg, 'hours'), converts all time contained in
 * units above the limit and converts them into the limit's unit.
 *
 * For example:
 *
 * ```
 * limitInterval({ days: 2, hours: 6 }, 'hours');
 * ```
 *
 * This would return a new Interval of 54 hours.
 *
 * @param interval - the Interval to be modified
 * @param limit - Interval key representing the highest desired unit of time
 * @returns - a new Interval that has all of its time represented in units that
 * are at or below the specified limit
 */
export const limitInterval = (interval, limit = 'years') => {
    if (limit === 'years') {
        return convertZeroKeysToNull(interval);
    }
    const squish = (interval, a, b, multiplier) => {
        const copy = Object.assign({}, interval);
        const timeInA = copy[a] || 0;
        copy[a] = 0;
        copy[b] = (copy[b] || 0) + timeInA * multiplier;
        return copy;
    };
    // limit to months
    interval = squish(interval, 'years', 'months', YEAR_IN_MONTHS);
    if (limit === 'months') {
        return convertZeroKeysToNull(interval);
    }
    interval = squish(interval, 'months', 'days', MONTH_IN_DAYS);
    if (limit === 'days') {
        return convertZeroKeysToNull(interval);
    }
    interval = squish(interval, 'days', 'hours', DAY_IN_HOURS);
    if (limit === 'hours') {
        return convertZeroKeysToNull(interval);
    }
    interval = squish(interval, 'hours', 'minutes', HOUR_IN_MINUTES);
    if (limit === 'minutes') {
        return convertZeroKeysToNull(interval);
    }
    interval = squish(interval, 'minutes', 'seconds', MINUTE_IN_SECONDS);
    if (limit === 'seconds') {
        return convertZeroKeysToNull(interval);
    }
    return convertZeroKeysToNull(interval);
};
/**
 * @param  {Interval} input
 * @returns Interval
 *
 * Takes an interval of multiple key:value pairs and reduces it to the single
 * largest possible interval measurement.
 *
 * For example:
 *
 * const foo: Interval = {
 *   years: 1,
 *   months: 2,
 * }
 *
 * const result = reduceIntervalToLargestMeasurement(foo);
 * console.log(result) // { months: 14 }
 */
export const reduceIntervalToLargestMeasurement = (input) => {
    const totalInSecondsTestValue = getTotalInSeconds(input);
    const largestIntervalMeasurement = findLargestPossibleInterval(totalInSecondsTestValue);
    let largestIntervalValue = 0; // default
    // once we know the largest possible measurement, we now take the totalInSeconds and
    // do the math to get the correct singular Interval value
    if (largestIntervalMeasurement === IntervalEnum.seconds) {
        largestIntervalValue = totalInSecondsTestValue;
    }
    if (largestIntervalMeasurement === IntervalEnum.minutes) {
        largestIntervalValue = totalInSecondsTestValue / MINUTE_IN_SECONDS;
    }
    if (largestIntervalMeasurement === IntervalEnum.hours) {
        largestIntervalValue = totalInSecondsTestValue / MINUTE_IN_SECONDS / HOUR_IN_MINUTES;
    }
    if (largestIntervalMeasurement === IntervalEnum.days) {
        largestIntervalValue = totalInSecondsTestValue / MINUTE_IN_SECONDS / HOUR_IN_MINUTES / DAY_IN_HOURS;
    }
    if (largestIntervalMeasurement === IntervalEnum.months) {
        largestIntervalValue = totalInSecondsTestValue / MINUTE_IN_SECONDS / HOUR_IN_MINUTES / DAY_IN_HOURS / MONTH_IN_DAYS;
    }
    if (largestIntervalMeasurement === IntervalEnum.years) {
        largestIntervalValue = totalInSecondsTestValue / MINUTE_IN_SECONDS / HOUR_IN_MINUTES / DAY_IN_HOURS / YEAR_IN_DAYS;
    }
    const result = {};
    result[largestIntervalMeasurement] = largestIntervalValue;
    return result;
};
