import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, from, split } from 'apollo-link';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { onError } from 'apollo-link-error';
import VueApollo from 'vue-apollo';
import { AUTH_PATH } from '~/nasa_ui/constants';
import { getActiveSupportContext } from './mixins/CurrentUserMixin';
import { CosmicEnvironment, getEnvironment } from './types';
import { createDbDateFromString } from './utils/dates';
const operationNamesToNeverLink = [
    'CommunityTopPagesVisited',
    'GetAllProjectCodes',
    'GetAsBuiltForAutoComplete',
    'GetBinLocationsForAutoComplete',
    'GetBuildingLocationsForAutoComplete',
    'GetCurrentUserWithGroups',
    'GetDrawingForAutoComplete',
    'GetLimitedLifeAlertsNavbar',
    'GetLotForAutoComplete',
    'GetRoomLocationsForAutoComplete',
    'GetSerialForAutoComplete',
    'GetStockLocationsForAutoComplete',
    'MyTopPagesVisited',
    'ReadinessReport'
];
const uri = '/api/graphql';
// HTTP connexion to the API
const link = new BatchHttpLink({
    uri
});
const noLink = new BatchHttpLink({
    batchMax: 1,
    uri
});
// __typename scrubing //https://github.com/apollographql/apollo-feature-requests/issues/6#issuecomment-465305186
const omitUnderscoreName = (key, value) => {
    return key.startsWith('_') ? undefined : value;
};
const errorLink = onError((errors) => {
    const networkError = errors.networkError;
    if (networkError) {
        // if 401 or 403, redirect to auth url
        if (networkError.statusCode === 401) {
            window.location.href = AUTH_PATH;
            throw new Error(networkError.message);
        }
        if (networkError.statusCode === 403) {
            window.location.href = '/no_access';
        }
    }
});
const omitTypenameLink = new ApolloLink((operation, forward) => {
    if (operation.variables) {
        operation.variables = JSON.parse(JSON.stringify(operation.variables), omitUnderscoreName);
    }
    // won't happen
    if (!forward) {
        return null;
    }
    return forward(operation);
});
const setAuthSubject = new ApolloLink((operation, forward) => {
    const devUserAccountId = window.localStorage.getItem('devUserAccountId');
    const env = getEnvironment();
    // for security, also check for localhost
    if (devUserAccountId && env === CosmicEnvironment.LOCAL) {
        operation.setContext((context) => {
            const headers = context.headers || {};
            return {
                headers: {
                    ...headers,
                    'x-auth-subject': devUserAccountId
                }
            };
        });
    }
    return forward(operation);
});
const setUserSupportContext = new ApolloLink((operation, forward) => {
    const currentUserActiveSupportContext = getActiveSupportContext();
    if (currentUserActiveSupportContext) {
        operation.setContext((context) => {
            const headers = context.headers || {};
            return {
                headers: {
                    ...headers,
                    'x-organization': currentUserActiveSupportContext ?? ''
                }
            };
        });
    }
    // won't happen
    if (!forward) {
        return null;
    }
    return forward(operation);
});
const setDatePropertiesToCST = new ApolloLink((operation, forward) => {
    const _isDateString = (property) => {
        const DATE_PROPERTIES = ['DateTime', 'Date'];
        return DATE_PROPERTIES.some((val) => property.endsWith(val));
    };
    function _addTimeZoneToDateProperties(obj) {
        Object.keys(obj).forEach((key) => {
            if (obj[key] && typeof obj[key] === 'object') {
                _addTimeZoneToDateProperties(obj[key]);
            }
            else {
                if (_isDateString(key) && obj[key]) {
                    obj[key] = createDbDateFromString(obj[key]);
                }
            }
        });
        return obj;
    }
    function _shallowIterator(target) {
        for (const key in target) {
            if (target[key] && typeof target[key] === 'string') {
                _addTimeZoneToDateProperties(target);
            }
            if (target[key] && typeof target[key] === 'object') {
                target[key] = _addTimeZoneToDateProperties(target[key]);
            }
        }
        return target;
    }
    operation.variables = _shallowIterator(operation.variables);
    return forward(operation);
});
// Cache implementation
const cache = new InMemoryCache({
    addTypename: true,
    dataIdFromObject: (object) => object.nodeId || object.id || null
});
// Create the apollo client
const apolloClient = new ApolloClient({
    link: from([
        errorLink,
        omitTypenameLink,
        setAuthSubject,
        setUserSupportContext,
        setDatePropertiesToCST,
        split((operation) => operationNamesToNeverLink.includes(operation.operationName), noLink, link)
    ]),
    cache,
    connectToDevTools: true,
    defaultOptions: {
        query: {
            fetchPolicy: 'network-only'
        }
    }
});
const vueApollo = new VueApollo({
    defaultClient: apolloClient
});
export { apolloClient };
export default vueApollo;
