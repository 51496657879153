import { intervalDisplay } from '../utils/dates/intervals';
import { asBuiltNumberDisplay, binDisplay, booleanIconDisplay, buildingDisplay, contactDisplay, currencyDisplay, itemNumberDisplay, locationDisplay, lotNumberDisplay, numberDisplay, quantityDisplay, serialNumberDisplay, sideDisplay, sizeDisplay, stockDisplay, typeDisplay } from '../utils/helpers/displays';
import { markdownToHtml } from '../utils/helpers/markdownToHtml';
export default {
    install(Vue) {
        Object.defineProperties(Vue.prototype, {
            $asBuiltNumberDisplay: { value: asBuiltNumberDisplay },
            $binDisplay: { value: binDisplay },
            $booleanIconDisplay: { value: booleanIconDisplay },
            $buildingDisplay: { value: buildingDisplay },
            $contactDisplay: { value: contactDisplay },
            $currencyDisplay: { value: currencyDisplay },
            $intervalDisplay: { value: intervalDisplay },
            $itemNumberDisplay: { value: itemNumberDisplay },
            $locationDisplay: { value: locationDisplay },
            $lotNumberDisplay: { value: lotNumberDisplay },
            $numberDisplay: { value: numberDisplay },
            $quantityDisplay: { value: quantityDisplay },
            $serialNumberDisplay: { value: serialNumberDisplay },
            $sideDisplay: { value: sideDisplay },
            $sizeDisplay: { value: sizeDisplay },
            $stockDisplay: { value: stockDisplay },
            $toHtml: { value: markdownToHtml },
            $typeDisplay: { value: typeDisplay }
        });
    }
};
