import gql from 'graphql-tag';
import { CosmicContactSlimFragment } from '../contact/slim';
import { CosmicUserSlimFragment } from '../user/slim';
export const SearchDocumentFragment = gql `
  ${CosmicUserSlimFragment}
  ${CosmicContactSlimFragment}

  fragment SearchDocument on Document {
    id
    nodeId
    computedCloseDate
    computedOpenDate
    description
    number
    subType
    title

    createdByUser {
      ...CosmicUserSlim
    }

    cosmicDocumentDr {
      id
      nodeId
      computedStatus
      initializationStatus
    }

    cosmicDocumentEzt {
      id
      nodeId
      computedStatus
      initializationStatus
    }

    cosmicDocumentTps {
      id
      nodeId
      computedStatus
      initializationStatus
    }

    cosmicDocumentRca {
      id
      nodeId
      computedStatus
      initializationStatus
    }

    cosmicDocumentWorkflow {
      id
      nodeId
      computedStatus
      dueDate
      initializationStatus
    }

    initiatorContact {
      ...CosmicContactSlim
    }

    lineItemCounts {
      status
      count
    }
  }
`;
export const GetDocuments = gql `
  ${SearchDocumentFragment}

  query SearchDocuments(
    $firstResults: Int
    $createdByUserId: [UUID]
    $initiatorContactId: [UUID]
    $number: [String]
    $query: String
    $subType: [String]
  ) {
    searchDocuments(
      first: $firstResults
      createdByUserId: $createdByUserId
      initiatorContactId: $initiatorContactId
      number: $number
      orderBy: [COMPUTED_OPEN_DATE_DESC, NUMBER_ASC]
      query: $query
      subType: $subType
    ) {
      nodes {
        ...SearchDocument
      }
    }
  }
`;
