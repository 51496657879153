import { __decorate } from "tslib";
import { Component, Mixins } from 'vue-property-decorator';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import LocationsAutocompleteMixin, { LocationsAutocompleteFields } from '~/nasa_ui/mixins/LocationsAutocompleteMixin';
let AppSelectStock = class AppSelectStock extends Mixins(LocationsAutocompleteMixin, BaseCosmic) {
    field = LocationsAutocompleteFields.STOCK;
};
AppSelectStock = __decorate([
    Component
], AppSelectStock);
export default AppSelectStock;
