var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('ButtonMiniAction', _vm._g({
    attrs: {
      "disabled": _vm.disabled,
      "icon": _vm.$icons.edit,
      "tooltip": _vm.computedTooltip,
      "tooltip-position": _vm.tooltipPosition,
      "icon-color": "var(--v-primary-base)",
      "icon-size": _vm.iconSize
    }
  }, _vm.$listeners));
};
var staticRenderFns = [];
export { render, staticRenderFns };