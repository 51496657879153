import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop } from 'vue-property-decorator';
import BaseButtonMiniActionImplementation from '~/nasa_ui/base/BaseButtonMiniActionImplementation';
import ButtonMiniAction from '~/nasa_ui/components/buttons/ButtonMiniAction/ButtonMiniAction.vue';
/**
 *
 */
let ButtonMiniActionManage = class ButtonMiniActionManage extends Mixins(BaseButtonMiniActionImplementation) {
    to;
    get computedTooltip() {
        return this.tooltip || 'View manage page';
    }
    get computedIconColor() {
        return this.iconColor;
    }
};
__decorate([
    Prop({
        type: String,
        default: 'var(--v-primary-base)'
    }),
    __metadata("design:type", String)
], ButtonMiniActionManage.prototype, "iconColor", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], ButtonMiniActionManage.prototype, "to", void 0);
ButtonMiniActionManage = __decorate([
    Component({
        components: { ButtonMiniAction }
    })
], ButtonMiniActionManage);
export default ButtonMiniActionManage;
