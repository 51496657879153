var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Debounce } from 'lodash-decorators';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { HardwareListType, Maybe } from '~/db_types';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { HardwareListRepo } from '~/nasa_ui/DAL/hardwareList';
import { AlertType } from '~/nasa_ui/types';
import { HardwareListTypeDisplay } from '~/nasa_ui/types/enums/hardware';
let ModalHardwareListTemplateEdit = class ModalHardwareListTemplateEdit extends Mixins(BaseModal, HardwareListRepo) {
    duplicateQueryResponse = null;
    isSaving = false;
    HardwareListType = HardwareListType;
    HardwareListTypeDisplay = HardwareListTypeDisplay;
    selectedEntity = null;
    formData = {
        name: null,
        pbsItemId: null,
        subType: null
    };
    hardwareListTemplateId;
    get closeIcon() {
        return this.$icons['strong_close'];
    }
    get duplicateAlertType() {
        return this.isDuplicate ? AlertType.ERROR : AlertType.INFO;
    }
    get isDuplicate() {
        return this.duplicateQueryResponse?.nodes.length === 1;
    }
    get hasChanged() {
        return (this.selectedEntity?.subType !== this.formData.subType ||
            this.selectedEntity?.name !== this.formData.name ||
            this.selectedEntity?.pbsItemId !== this.formData.pbsItemId);
    }
    get isFormValid() {
        return Boolean(this.formData.name && this.formData.subType);
    }
    get manageUrl() {
        if (!this.selectedEntity?.id) {
            return null;
        }
        return `/hardware/list_templates/manage/${this.selectedEntity.id}`;
    }
    get shouldDisableEditButton() {
        return !this.isFormValid || !this.hasChanged || this.isDuplicate;
    }
    get shouldDisplayManageLink() {
        return this.$route.params.id !== this.hardwareListTemplateId;
    }
    async deleteEntity() {
        if (this.selectedEntity?.hardwareListTemplateSlots?.totalCount ?? 0 > 0) {
            // currently just warning & blocking user from deleting due to fk cascade issues on the backend
            const errMessage = 'Cannot delete Hardware List Template as it currently has attached children.';
            const err = new Error(errMessage);
            this.$errorUtility({ err, backupErrorText: errMessage });
        }
        else {
            try {
                this.isSaving = true;
                const resp = await this.$apollo.mutate({
                    mutation: gql `
            mutation DeleteHardwareListTemplate($hardwareListTemplateId: UUID!) {
              deleteHardwareListTemplateById(input: { id: $hardwareListTemplateId }) {
                hardwareListTemplate {
                  id
                  nodeId
                }
              }
            }
          `,
                    variables: {
                        hardwareListTemplateId: this.$route.params.id
                    }
                });
                this.$notification.add({
                    type: AlertType.SUCCESS,
                    text: `Hardware List Template deleted.`
                });
                this.$message('reload', true);
                this.$router.push(`/hardware/list_templates/search`);
                this.closeDialog();
            }
            catch (err) {
                this.isSaving = false;
                this.$errorUtility({
                    err
                });
            }
        }
    }
    async editHardwareListTemplate(data) {
        try {
            this.isSaving = true;
            const resp = await this.$apollo.mutate({
                mutation: gql `
          mutation ModalHardwareListTemplateEdit(
            $hardwareListTemplateId: UUID!
            $pbsItemId: String
            $name: String
            $subType: HardwareListType
          ) {
            updateHardwareListTemplateById(
              input: { id: $hardwareListTemplateId, patch: { subType: $subType, name: $name, pbsItemId: $pbsItemId } }
            ) {
              hardwareListTemplate {
                id
                nodeId
              }
            }
          }
        `,
                variables: {
                    hardwareListTemplateId: data.id,
                    name: data.name,
                    subType: data.subType,
                    pbsItemId: data.pbsItemId
                }
            });
            if (resp?.errors) {
                throw resp.errors;
            }
            if (resp?.data) {
                this.$notification.add({
                    type: AlertType.SUCCESS,
                    text: `${data.name || 'Hardware List Template'} edited.`
                });
                this.$message('reload', true);
                this.closeDialog();
            }
            this.isSaving = false;
        }
        catch (err) {
            this.isSaving = false;
            this.$errorUtility({
                err,
                backupErrorText: 'Could not edit the Hardware List Template'
            });
        }
    }
    async fetchHardwareListTemplate(hardwareListTemplateId) {
        try {
            const resp = await this.$apollo.query({
                query: gql `
          query FetchHardwareListTemplateForEditHlModal($id: UUID!) {
            hardwareListTemplateById(id: $id) {
              id
              name
              nodeId
              pbsItemId
              subType

              hardwareListTemplateSlots {
                totalCount
              }
            }
          }
        `,
                variables: {
                    id: hardwareListTemplateId
                }
            });
            this.selectedEntity = resp.data.hardwareListTemplateById;
        }
        catch (err) {
            this.$errorUtility({
                err,
                backupErrorText: 'Could not fetch the hardware list template'
            });
        }
    }
    onClickOfModalHardwareListEditTemplate() {
        if (this.isFormValid) {
            this.editHardwareListTemplate(this.formData);
        }
    }
    onHardwareListTemplateIdChange(val) {
        if (val) {
            this.fetchHardwareListTemplate(val);
        }
    }
    async checkForHardwareListDuplicates() {
        if (!this.isFormValid || !this.hasChanged) {
            return;
        }
        const data = {
            name: this.formData.name,
            subType: this.formData.subType
        };
        try {
            const resp = await this.checkForHardwareListTemplateDuplicates(data);
            this.duplicateQueryResponse = resp?.hardwareListTemplates;
        }
        catch (error) {
            this.duplicateQueryResponse = null;
        }
    }
    onSelectedEntityChange(val) {
        if (val) {
            this.formData = Object.assign({}, this.formData, val);
        }
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], ModalHardwareListTemplateEdit.prototype, "hardwareListTemplateId", void 0);
__decorate([
    Watch('hardwareListTemplateId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], ModalHardwareListTemplateEdit.prototype, "onHardwareListTemplateIdChange", null);
__decorate([
    Debounce(200),
    Watch('formData.name'),
    Watch('formData.subType'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalHardwareListTemplateEdit.prototype, "checkForHardwareListDuplicates", null);
__decorate([
    Watch('selectedEntity'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], ModalHardwareListTemplateEdit.prototype, "onSelectedEntityChange", null);
ModalHardwareListTemplateEdit = __decorate([
    Component
], ModalHardwareListTemplateEdit);
export default ModalHardwareListTemplateEdit;
