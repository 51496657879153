import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { OrganizationTypeDisplay } from '~/nasa_ui/types';
import { addDateDisplays, addSubTypeDisplay } from '../displayMappers';
import { transformLocations } from '../transformEntityToTableFormat';
export const transformOrganizations = (items) => {
    return items
        .map(addDateDisplays)
        .map(addSubTypeDisplay(OrganizationTypeDisplay))
        .map((item) => {
        const _item = { ...item };
        if (item.locations?.nodes) {
            _item._locations = transformLocations(item.locations?.nodes);
        }
        else if (item.locations) {
            _item._locations = transformLocations(item.locations);
        }
        else {
            _item._locations = [];
        }
        _item._name = _item.name || DEFAULT_DASH;
        return _item;
    });
};
export const transformOrganizationResponses = (items) => {
    return transformOrganizations(items);
};
