export var MaterialReviewBoardDecision;
(function (MaterialReviewBoardDecision) {
    MaterialReviewBoardDecision["AS_IS"] = "AS_IS";
    MaterialReviewBoardDecision["SCRAP"] = "SCRAP";
    MaterialReviewBoardDecision["REPAIR"] = "REPAIR";
    MaterialReviewBoardDecision["WAIVER"] = "WAIVER";
    MaterialReviewBoardDecision["CLASS_CHANGE"] = "CLASS_CHANGE";
})(MaterialReviewBoardDecision || (MaterialReviewBoardDecision = {}));
export const MaterialReviewBoardDecisionDisplay = new Map([
    [MaterialReviewBoardDecision.AS_IS, 'Use "As Is"'],
    [MaterialReviewBoardDecision.SCRAP, 'Scrap'],
    [MaterialReviewBoardDecision.REPAIR, 'Repair'],
    [MaterialReviewBoardDecision.WAIVER, 'Waiver'],
    [MaterialReviewBoardDecision.CLASS_CHANGE, 'Class Change']
]);
