import { AttachmentType } from '~/db_types';
export const AttachmentTypeDisplay = new Map([
    [AttachmentType.CLOSEOUT, 'Closeout'],
    [AttachmentType.CORRECTIVE_ACTION, 'Corrective action'],
    [AttachmentType.DRAWING, 'Drawing'],
    [AttachmentType.GENERAL, 'General'],
    [AttachmentType.INCIDENT_ANALYSIS, 'Incident analysis'],
    [AttachmentType.PROCEDURE_EXECUTED_COPY, 'Procedure (Executed copy)'],
    [AttachmentType.PROCEDURE, 'Procedure'],
    [AttachmentType.REFERENCE_DOCUMENT, 'Reference document'],
    [AttachmentType.WORK_ORDER_STEP, 'Step for Docs']
]);
