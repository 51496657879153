function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.currentUserSupportContextCount ? _c('div', {
    staticClass: "active_user_support_display"
  }, [!_vm.isReloading ? _c('v-menu', {
    staticClass: "supportMenu",
    attrs: {
      "offset-y": "",
      "left": "",
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var menu = _ref.on;
        return [_c('button', _vm._g({
          class: _vm.hasOnlyOneContext ? 'one-context-support-display' : 'view-user-supports',
          attrs: {
            "disabled": _vm.hasOnlyOneContext,
            "tooltip": "Change ".concat(_vm.currentSystemName, " contexts")
          },
          on: {
            "mouseover": function mouseover($event) {
              _vm.isHoveringOverChangeCtx = true;
            },
            "mouseleave": function mouseleave($event) {
              _vm.isHoveringOverChangeCtx = false;
            }
          }
        }, _objectSpread({}, menu)), [_c('c-icon-fa', {
          staticClass: "organization-icon pr-2",
          attrs: {
            "icon": _vm.$icons[_vm.EntityType.ORGANIZATION],
            "icon-color": _vm.isHoveringOverChangeCtx ? 'var(--v-organizations-base)' : 'white'
          }
        }), _c('span', {
          staticClass: "organization-code-display"
        }, [_vm._v(_vm._s(_vm.currentUserSupportContext))])], 1)];
      }
    }], null, false, 1873673431)
  }, [_vm.showUserSupportContextList ? _c('v-list', _vm._l(_vm.selectOptions, function (support, i) {
    return _c('v-list-tile', {
      key: i,
      on: {
        "click": function click($event) {
          return _vm.handleUserSupportSelection(support.organizationCode);
        }
      }
    }, [_c('v-list-tile-title', [_c('span', {
      staticClass: "pa-3 organizations white--text"
    }, [_vm._v(_vm._s(support.organizationCode))])])], 1);
  }), 1) : _vm._e()], 1) : _vm._e(), _vm.isReloading ? _c('div', {
    staticClass: "loading_container"
  }, [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons.reload
    }
  })], 1) : _vm._e()], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };