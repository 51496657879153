var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { HardwareListType, Maybe } from '~/db_types';
import BaseModalHardwareList from '~/nasa_ui/base/BaseModalHardwareList';
import { AlertType } from '~/nasa_ui/types';
import { HardwareListTypeDisplay } from '~/nasa_ui/types/enums/hardware';
let ModalHardwareListEdit = class ModalHardwareListEdit extends Mixins(BaseModalHardwareList) {
    duplicateQueryResponse = null;
    isSaving = false;
    HardwareListType = HardwareListType;
    HardwareListTypeDisplay = HardwareListTypeDisplay;
    selectedEntity = null;
    get duplicateAlertType() {
        return this.isDuplicate ? AlertType.ERROR : AlertType.INFO;
    }
    get duplicateAlertText() {
        return `Hardware Lists must be unique by <strong>Type</strong> and <strong>Number</strong>.`;
    }
    get duplicateAlert() {
        return {
            text: this.duplicateAlertText,
            type: this.duplicateAlertType
        };
    }
    get canDeleteHardwareList() {
        return this.hardwareList && this.hardwareList?.id;
    }
    get isDuplicate() {
        return this.duplicateQueryResponse?.nodes.length === 1;
    }
    get hasChanged() {
        return this.selectedEntity?.subType !== this.formData.subType || this.selectedEntity?.name !== this.formData.name;
    }
    get shouldDisableSaveButton() {
        return !this.isFormValid || !this.hasChanged || this.isDuplicate || this.isSaving;
    }
    onClickOfDeleteHardwareList() {
        this.deleteEntity(this.hardwareList.id);
    }
    onClickOfSaveHardwareList() {
        if (this.isFormValid) {
            this.editEntity(this.formData);
        }
    }
    setFormData(val) {
        if (val) {
            this.formData = Object.assign({}, this.formData, val);
        }
    }
};
__decorate([
    Watch('hardwareList', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], ModalHardwareListEdit.prototype, "setFormData", null);
ModalHardwareListEdit = __decorate([
    Component
], ModalHardwareListEdit);
export default ModalHardwareListEdit;
