import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let AppBooleanDisplay = class AppBooleanDisplay extends Vue {
    greyscale;
    iconSize;
    value;
    get isTruthy() {
        const truthyValues = [true, 'True', 'true', 'TRUE', 'Yes', 'yes', 'YES'];
        return this.value ? truthyValues.indexOf(this.value) > -1 : false;
    }
    get isFalsy() {
        const falsyValues = [false, 'False', 'false', 'FALSE', 'No', 'no', 'NO'];
        return this.value !== null ? falsyValues.indexOf(this.value) > -1 : false;
    }
    get isNotDefined() {
        return this.value === '' || this.value === undefined || this.value === null;
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppBooleanDisplay.prototype, "greyscale", void 0);
__decorate([
    Prop({
        type: String,
        default: '24px'
    }),
    __metadata("design:type", String)
], AppBooleanDisplay.prototype, "iconSize", void 0);
__decorate([
    Prop({
        type: [Boolean, String]
    }),
    __metadata("design:type", Object)
], AppBooleanDisplay.prototype, "value", void 0);
AppBooleanDisplay = __decorate([
    Component
], AppBooleanDisplay);
export default AppBooleanDisplay;
