import { __decorate } from "tslib";
import { Component, Mixins } from 'vue-property-decorator';
import LoaderMixin from '~/nasa_ui/mixins/LoaderMixin';
import { AlertType } from '~/nasa_ui/types';
let HttpMixin = class HttpMixin extends Mixins(LoaderMixin) {
    async httpGet(url, options) {
        this.isLoading = true;
        try {
            const _config = { ...options?.config };
            const resp = await this.$http.get(url, _config);
            return resp;
        }
        catch (err) {
            this.$errorUtility({ err, backupErrorText: options?.message?.error });
            throw err;
        }
        finally {
            this.isLoading = false;
        }
    }
    async httpPost(url, body, message, axiosConfig) {
        this.isSaving = true;
        try {
            const resp = await this.$http.post(url, body, axiosConfig);
            if (message?.success) {
                this.$notification.add({ type: AlertType.SUCCESS, text: message.success });
            }
            return resp;
        }
        catch ({ response }) {
            this.onError(response);
        }
        finally {
            this.isSaving = false;
        }
    }
    async httpPut(url, body, message, axiosConfig) {
        this.isSaving = true;
        try {
            const resp = await this.$http.put(url, body, axiosConfig);
            if (message?.success) {
                this.$notification.add({ type: AlertType.SUCCESS, text: message.success });
            }
            return resp;
        }
        catch ({ response }) {
            this.onError(response);
        }
        finally {
            this.isSaving = false;
        }
    }
    async httpDelete(url, message, axiosConfig) {
        this.isSaving = true;
        try {
            const resp = await this.$http.delete(url, axiosConfig);
            if (message?.success) {
                this.$notification.add({ type: AlertType.SUCCESS, text: message.success });
            }
            return resp;
        }
        catch ({ response }) {
            this.onError(response);
        }
        finally {
            this.isSaving = false;
        }
    }
    onError(response) {
        const err = new Error(response.data?.title);
        this.$errorUtility({ err, backupErrorText: response.data?.title || '' });
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        throw err;
    }
};
HttpMixin = __decorate([
    Component
], HttpMixin);
export default HttpMixin;
