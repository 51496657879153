var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop } from 'vue-property-decorator';
import { OutputMode } from '~/nasa_ui/types/enums/general';
import BaseCosmic from './BaseCosmic';
let BaseSelector = class BaseSelector extends BaseCosmic {
    OutputMode = OutputMode;
    searchComponentResults = null;
    searchTerm = null;
    showRefreshAlert = false;
    clearable;
    autofocus;
    autosearch;
    existingValues;
    filters;
    keepSearchResultsOpen;
    keepSearchValueAfterSelection;
    hideShowOtherContextFilter;
    lockedFilters;
    outputMode;
    showFilterButton;
    showMaxResults;
    showCreateButton;
    /*
     * Limits list selection to just one
     */
    singleMode;
    size;
    title;
    // This is intended to be overriden in the implementing selector
    get transformedSearchResults() {
        return this.searchComponentResults;
    }
    emitResults(results) { }
    emitSearchButtonClicked() { }
    emitAndCacheResults(results) {
        this.searchComponentResults = results;
        this.emitResults(results);
    }
    // called from the UI
    onTermSearched(term) {
        this.searchTerm = term.toUpperCase();
    }
};
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], BaseSelector.prototype, "clearable", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], BaseSelector.prototype, "autofocus", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], BaseSelector.prototype, "autosearch", void 0);
__decorate([
    Prop({
        type: Array
    }),
    __metadata("design:type", Array)
], BaseSelector.prototype, "existingValues", void 0);
__decorate([
    Prop({ default: () => { } }),
    __metadata("design:type", Object)
], BaseSelector.prototype, "filters", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], BaseSelector.prototype, "keepSearchResultsOpen", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], BaseSelector.prototype, "keepSearchValueAfterSelection", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], BaseSelector.prototype, "hideShowOtherContextFilter", void 0);
__decorate([
    Prop({ default: () => [], type: Array }),
    __metadata("design:type", Array)
], BaseSelector.prototype, "lockedFilters", void 0);
__decorate([
    Prop({ default: OutputMode.string, type: [String, String] }),
    __metadata("design:type", Object)
], BaseSelector.prototype, "outputMode", void 0);
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], BaseSelector.prototype, "showFilterButton", void 0);
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], BaseSelector.prototype, "showMaxResults", void 0);
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], BaseSelector.prototype, "showCreateButton", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], BaseSelector.prototype, "singleMode", void 0);
__decorate([
    Prop({
        default: '2',
        type: String
    }),
    __metadata("design:type", String)
], BaseSelector.prototype, "size", void 0);
__decorate([
    Prop({
        default: '',
        type: String
    }),
    __metadata("design:type", String)
], BaseSelector.prototype, "title", void 0);
__decorate([
    Emit('results'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], BaseSelector.prototype, "emitResults", null);
__decorate([
    Emit('searchButtonClicked'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseSelector.prototype, "emitSearchButtonClicked", null);
BaseSelector = __decorate([
    Component
], BaseSelector);
export default BaseSelector;
