var _a;
import { __decorate, __metadata } from "tslib";
import { DebounceAll } from 'lodash-decorators';
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import { EntityType, Maybe } from '~/nasa_ui/types';
import { transformInventoryResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let MiniInventory = class MiniInventory extends Mixins(ReadOnlyMixin) {
    isFetching = false;
    selectedEntity = null;
    showDetail = false;
    allowEdit;
    id;
    entity;
    inlineClear;
    isExternal;
    showManageLink;
    showInfoIcon;
    title;
    sequence;
    showEntityTypeIcon;
    get manageLink() {
        return this.selectedEntity && this.transformedSelectedEntity?.itemInstanceId
            ? `/hardware/${EntityType.ITEM_INSTANCE}/manage/${this.transformedSelectedEntity.itemInstanceId}#inventory`
            : '';
    }
    get shouldShowSequence() {
        return this.sequence !== null;
    }
    get shouldShowDetail() {
        return this.showDetail;
    }
    get shouldShowInlineClearIcon() {
        return this.inlineClear && !this.readOnly;
    }
    get transformedSelectedEntity() {
        return this.selectedEntity ? transformInventoryResponses([this.selectedEntity])[0] : null;
    }
    // Called from the ui
    onClickOfShowDetail() {
        this.showDetail = !this.showDetail;
    }
    onClickOfInlineClear() {
        return this.transformedSelectedEntity;
    }
    onEntityChange() {
        if (!this.entity) {
            return;
        }
        this.selectedEntity = { ...this.entity };
    }
    async fetchEntity() {
        if (!this.id) {
            return;
        }
        try {
            this.isFetching = true;
            const resp = await this.$http.get(`/inventory/${this.id}`);
            this.selectedEntity = resp.data;
            this.isFetching = false;
        }
        catch (err) {
            this.isFetching = false;
            this.$errorUtility({ err });
        }
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], MiniInventory.prototype, "allowEdit", void 0);
__decorate([
    Prop({
        type: String,
        required: false
    }),
    __metadata("design:type", String)
], MiniInventory.prototype, "id", void 0);
__decorate([
    Prop({
        required: false,
        type: Object
    }),
    __metadata("design:type", Object)
], MiniInventory.prototype, "entity", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], MiniInventory.prototype, "inlineClear", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], MiniInventory.prototype, "isExternal", void 0);
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], MiniInventory.prototype, "showManageLink", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], MiniInventory.prototype, "showInfoIcon", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], MiniInventory.prototype, "title", void 0);
__decorate([
    Prop({
        default: null,
        type: Number
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], MiniInventory.prototype, "sequence", void 0);
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], MiniInventory.prototype, "showEntityTypeIcon", void 0);
__decorate([
    Emit('inlineClear'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MiniInventory.prototype, "onClickOfInlineClear", null);
__decorate([
    Watch('entity', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MiniInventory.prototype, "onEntityChange", null);
__decorate([
    DebounceAll(10),
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], MiniInventory.prototype, "fetchEntity", null);
MiniInventory = __decorate([
    Component
], MiniInventory);
export default MiniInventory;
