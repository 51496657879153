import endOfDay from 'date-fns/endOfDay';
import isAfter from 'date-fns/isAfter';
import { dateFrom } from './dateFrom';
/**
 * This util is modeled after date-fns' `isAfter` except that it interprets the
 * second date argument as midnight _following_ the given date.
 *
 * @param date The date that should be after the other one to return true
 * @param dateToCompare The date to compare with
 * @returns Returns whether the first date is after the midnight following
 * second date
 */
export const isAfterInclusive = (date, dateToCompare) => {
    if (!date) {
        return false;
    }
    if (!dateToCompare) {
        return false;
    }
    date = dateFrom(date);
    dateToCompare = dateFrom(dateToCompare);
    return isAfter(date, endOfDay(dateToCompare));
};
