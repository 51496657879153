import { __decorate } from "tslib";
import { Component, Mixins } from 'vue-property-decorator';
import BaseMini from '~/nasa_ui/base/BaseMini';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { EventRepoMixin, GetEventById } from '~/nasa_ui/DAL/event';
import { EntityType } from '~/nasa_ui/types';
import { EventTypeDisplay } from '~/nasa_ui/types/enums/event';
let MiniEvent = class MiniEvent extends Mixins(BaseMini, EventRepoMixin) {
    localEntity = null;
    entityType = EntityType.EVENT;
    BASE_FETCH = GetEventById;
    get manageLink() {
        return this.localEntity ? `/${EntityType.EVENT}/manage/${this.localEntity.id}` : '';
    }
    get name() {
        if (!this.localEntity && !this.isFetching) {
            return 'Not found';
        }
        return this.localEntity?.name || DEFAULT_DASH;
    }
    get endDisplay() {
        return this.localEntity ? this.$dateDisplay(this.localEntity.endDate) : DEFAULT_DASH;
    }
    get startDisplay() {
        return this.localEntity ? this.$dateDisplay(this.localEntity.startDate) : DEFAULT_DASH;
    }
    get typeDisplay() {
        return this.localEntity ? EventTypeDisplay.get(this.localEntity.subType) || DEFAULT_DASH : DEFAULT_DASH;
    }
    onFetchUpdate(data) {
        if (data.eventById) {
            this.localEntity = data.eventById;
        }
    }
};
MiniEvent = __decorate([
    Component
], MiniEvent);
export default MiniEvent;
