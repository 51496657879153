var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { Maybe } from '~/nasa_ui/types';
let AppMarkdown = class AppMarkdown extends Vue {
    currentlySelectedText = null;
    showTextarea = true;
    showMarkdownDisplay = false;
    label;
    messages;
    placeholder;
    required;
    value;
    get computedMessages() {
        // if required and no value, show required message
        if (this.required && this.$get(this, 'value', null) === null) {
            return ['REQUIRED'];
        }
        if (this.required && this.$get(this, 'value', undefined) === undefined) {
            return ['REQUIRED'];
        }
        if (this.required && this.$get(this, 'value', '') === '') {
            return ['REQUIRED'];
        }
        if (this.messages) {
            return this.messages;
        }
        // show messages based on rules
        // @ts-ignore
        return this.localRulesBaseOnProps.filter((_) => typeof _ === 'string');
    }
    get isAnythingSelected() {
        return Boolean(this.currentlySelectedText);
    }
    get shouldShowTextarea() {
        return this.showTextarea;
    }
    get shouldShowMarkdownDisplay() {
        return this.showMarkdownDisplay;
    }
    created() {
        setInterval(() => {
            this.currentlySelectedText = document.getSelection()?.toString() || '';
        }, 50);
    }
    emitInput(val) {
        return val && val.trim ? val.trim() : val;
    }
    onClickOfBold() {
        if (this.currentlySelectedText) {
            this.emitInput(this.value?.replace(this.currentlySelectedText, `**${this.currentlySelectedText}**`));
        }
    }
    onClickOfItalic() {
        if (this.currentlySelectedText) {
            this.emitInput(this.value?.replace(this.currentlySelectedText, `_${this.currentlySelectedText}_`));
        }
    }
    onClickOfStrikethrough() {
        if (this.currentlySelectedText) {
            this.emitInput(this.value?.replace(this.currentlySelectedText, `~~${this.currentlySelectedText}~~`));
        }
    }
    onClickOfQuote() {
        if (this.currentlySelectedText) {
            this.emitInput(this.value?.replace(this.currentlySelectedText, `> ${this.currentlySelectedText}`));
        }
        else {
            this.emitInput(`${this.value || ''}
> 
      `);
        }
    }
    onClickOfWrite() {
        this.showMarkdownDisplay = false;
        this.showTextarea = true;
    }
    onClickOfPreview() {
        this.showMarkdownDisplay = true;
        this.showTextarea = false;
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppMarkdown.prototype, "label", void 0);
__decorate([
    Prop({ type: [Array, String] }),
    __metadata("design:type", Array)
], AppMarkdown.prototype, "messages", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppMarkdown.prototype, "placeholder", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppMarkdown.prototype, "required", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], AppMarkdown.prototype, "value", void 0);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], AppMarkdown.prototype, "emitInput", null);
AppMarkdown = __decorate([
    Component
], AppMarkdown);
export default AppMarkdown;
