import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import BaseInput from '~/nasa_ui/base/BaseInput';
import { calculateTextColor } from '~/nasa_ui/utils/helpers/colors';
/**
 * AVAILABLE PROPS:
 *
 * autofocus
 * color - 'primary lighten-1'
 * clearable - puts lil 'x' on the input
 * disabled - greys out the field and button
 * label
 * placeholder
 * required
 * rules - array of functions
 * value - string[]
 * valueOutlined - maps to `outlined` in AppChip
 * valueColor - maps to `color` in AppChip defaults to `color`
 * valueTextColor - maps to `textColor` in AppChip defaults to `white`
 */
let AppListString = class AppListString extends BaseInput {
    clearOnNextBackspace = false;
    newText = '';
    selectedItems = [];
    valueColor;
    valueOutlined;
    valueTextColor;
    get computedColor() {
        return this.color || 'primary lighten-1';
    }
    get isNewTextInvalid() {
        return this.newText ? this.selectedItems.indexOf(this.newText) > -1 : true;
    }
    get isRequired() {
        return this.required && this.selectedItems.length === 0;
    }
    get selectedItemsAsAppChips() {
        return this.selectedItems.map((_) => {
            const appChip = {
                text: _,
                value: _,
                close: !this.disabled,
                color: this.valueColor || this.color,
                textColor: calculateTextColor(this.valueColor || this.color),
                outline: this.valueOutlined,
                disabled: this.disabled
            };
            return appChip;
        });
    }
    addNewText(text) {
        if (this.isNewTextInvalid || !text) {
            return;
        }
        this.selectedItems.push(text.trim());
        this.newText = '';
        this.emitInput();
    }
    removeItem(item) {
        this.selectedItems =
            this.selectedItems && this.selectedItems.length ? this.selectedItems.filter((_) => _ !== item.text) : [];
        this.emitInput();
    }
    removeLastItem() {
        if (this.clearOnNextBackspace) {
            this.selectedItems.pop();
            this.emitInput();
        }
        if (this.newText === '') {
            this.clearOnNextBackspace = true;
        }
    }
    onFocusout() {
        if (this.newText) {
            this.addNewText(this.newText);
        }
    }
    checkForClearOnBackspace(text) {
        if (text) {
            this.clearOnNextBackspace = false;
        }
    }
    syncValue(val) {
        if (val && val.length > 0 && Array.isArray(val)) {
            this.selectedItems = this.value ? this.value.slice(0) : [];
        }
        else {
            this.selectedItems = [];
        }
    }
    emitInput() {
        return this.selectedItems;
    }
};
__decorate([
    Prop(),
    __metadata("design:type", String)
], AppListString.prototype, "valueColor", void 0);
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], AppListString.prototype, "valueOutlined", void 0);
__decorate([
    Prop({ default: 'white' }),
    __metadata("design:type", String)
], AppListString.prototype, "valueTextColor", void 0);
__decorate([
    Watch('newText'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], AppListString.prototype, "checkForClearOnBackspace", null);
__decorate([
    Watch('value', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], AppListString.prototype, "syncValue", null);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppListString.prototype, "emitInput", null);
AppListString = __decorate([
    Component
], AppListString);
export default AppListString;
