import VueRouter, { isNavigationFailure } from 'vue-router';
export default (typesToSilence) => {
    const originalPush = VueRouter.prototype.push;
    VueRouter.prototype.push = async function (location) {
        let route;
        try {
            route = await originalPush.call(this, location);
        }
        catch (err) {
            const isIgnoreType = [...typesToSilence].map((navFailureType) => {
                return isNavigationFailure(err, navFailureType);
            });
            if (isIgnoreType.every((result) => result === false)) {
                throw err;
            }
            else {
                console.warn(err);
            }
        }
        return route;
    };
    const originalReplace = VueRouter.prototype.replace;
    VueRouter.prototype.replace = async function (location) {
        let route;
        try {
            route = await originalReplace.call(this, location);
        }
        catch (err) {
            const isIgnoreType = [...typesToSilence].map((navFailureType) => {
                return isNavigationFailure(err, navFailureType);
            });
            if (isIgnoreType.every((result) => result === false)) {
                throw err;
            }
            else {
                console.warn(err);
            }
        }
        return route;
    };
};
