import { DocumentType } from '~/db_types';
import { DocumentTypeDisplay } from './document';
export var AuthorityCode;
(function (AuthorityCode) {
    AuthorityCode["_1149"] = "_1149";
    AuthorityCode["_290"] = "_290";
    AuthorityCode["_528"] = "_528";
    AuthorityCode["ADP"] = "ADP";
    AuthorityCode["C_DISCREPANCY_REPORT"] = "C_DISCREPANCY_REPORT";
    AuthorityCode["C_EASY_TRANSFER"] = "C_EASY_TRANSFER";
    AuthorityCode["C_TPS"] = "C_TPS";
    AuthorityCode["DD250"] = "DD250";
    AuthorityCode["DR"] = "DR";
    AuthorityCode["EC"] = "EC";
    AuthorityCode["EZT"] = "EZT";
    AuthorityCode["FAB"] = "FAB";
    AuthorityCode["FIAR"] = "FIAR";
    AuthorityCode["GSA_FORM_1449"] = "GSA_FORM_1449";
    AuthorityCode["INV"] = "INV";
    AuthorityCode["OTH"] = "OTH";
    AuthorityCode["PO"] = "PO";
    AuthorityCode["TCDS"] = "TCDS";
    AuthorityCode["TPS"] = "TPS";
    AuthorityCode["WAIVER"] = "WAIVER";
    AuthorityCode["YTN"] = "YTN";
})(AuthorityCode || (AuthorityCode = {}));
export const AuthorityCodeToDocumentType = new Map([
    [AuthorityCode._1149, DocumentType.OTHER_DOCUMENT],
    [AuthorityCode._290, DocumentType.OTHER_DOCUMENT],
    [AuthorityCode._528, DocumentType.OTHER_DOCUMENT],
    [AuthorityCode.ADP, DocumentType.ACCEPTANCE_DATA_PACK],
    [AuthorityCode.C_DISCREPANCY_REPORT, DocumentType.C_DISCREPANCY_REPORT],
    [AuthorityCode.C_EASY_TRANSFER, DocumentType.C_EASY_TRANSFER],
    [AuthorityCode.C_TPS, DocumentType.C_TPS],
    [AuthorityCode.DD250, DocumentType.OTHER_DOCUMENT],
    [AuthorityCode.DR, DocumentType.DISCREPANCY_REPORT],
    [AuthorityCode.EC, DocumentType.ENGINEERING_CHANGE],
    [AuthorityCode.EZT, DocumentType.EASY_TRANSFER],
    [AuthorityCode.FAB, DocumentType.OTHER_DOCUMENT],
    [AuthorityCode.FIAR, DocumentType.FAILURE_INVESTIGATION_ANOMALY_REPORT],
    [AuthorityCode.GSA_FORM_1449, DocumentType.OTHER_DOCUMENT],
    [AuthorityCode.INV, DocumentType.OTHER_DOCUMENT],
    [AuthorityCode.OTH, DocumentType.OTHER_DOCUMENT],
    [AuthorityCode.PO, DocumentType.OTHER_DOCUMENT],
    [AuthorityCode.TPS, DocumentType.TPS],
    [AuthorityCode.WAIVER, DocumentType.WAIVER],
    [AuthorityCode.YTN, DocumentType.YELLOW_TAG_NOTICE]
]);
export const AuthorityCodeDisplay = new Map([
    [AuthorityCode._1149, 'DD1149'],
    [AuthorityCode._290, 'JSC290'],
    [AuthorityCode._528, 'JSC528'],
    [AuthorityCode.ADP, DocumentTypeDisplay.get(DocumentType.ACCEPTANCE_DATA_PACK)],
    [AuthorityCode.C_DISCREPANCY_REPORT, DocumentTypeDisplay.get(DocumentType.C_DISCREPANCY_REPORT)],
    [AuthorityCode.C_EASY_TRANSFER, DocumentTypeDisplay.get(DocumentType.C_EASY_TRANSFER)],
    [AuthorityCode.C_TPS, DocumentTypeDisplay.get(DocumentType.C_TPS)],
    [AuthorityCode.DD250, 'DD250'],
    [AuthorityCode.DR, DocumentTypeDisplay.get(DocumentType.DISCREPANCY_REPORT)],
    [AuthorityCode.EC, DocumentTypeDisplay.get(DocumentType.ENGINEERING_CHANGE)],
    [AuthorityCode.EZT, DocumentTypeDisplay.get(DocumentType.EASY_TRANSFER)],
    [AuthorityCode.FAB, 'FAB action breakdown'],
    [AuthorityCode.FIAR, 'FIAR'],
    [AuthorityCode.GSA_FORM_1449, 'GSA 1449'],
    [AuthorityCode.INV, 'Inventory'],
    [AuthorityCode.OTH, DocumentTypeDisplay.get(DocumentType.OTHER_DOCUMENT)],
    [AuthorityCode.PO, 'Purchase order'],
    [AuthorityCode.TCDS, 'Time cycle data sheet (TCDS)'],
    [AuthorityCode.TPS, DocumentTypeDisplay.get(DocumentType.TPS)],
    [AuthorityCode.YTN, DocumentTypeDisplay.get(DocumentType.YELLOW_TAG_NOTICE)],
    [AuthorityCode.WAIVER, DocumentTypeDisplay.get(DocumentType.WAIVER)]
]);
export const AuthorityCodeRequiresDocument = new Map([
    [AuthorityCode.ADP, false],
    [AuthorityCode.C_DISCREPANCY_REPORT, true],
    [AuthorityCode.C_EASY_TRANSFER, true],
    [AuthorityCode.C_TPS, true],
    [AuthorityCode.DR, true],
    [AuthorityCode.EC, true],
    [AuthorityCode.EZT, true],
    [AuthorityCode.FAB, false],
    [AuthorityCode.FIAR, false],
    [AuthorityCode.GSA_FORM_1449, false],
    [AuthorityCode.INV, false],
    [AuthorityCode.OTH, false],
    [AuthorityCode.PO, true],
    [AuthorityCode.TCDS, false],
    [AuthorityCode.TPS, true],
    [AuthorityCode.YTN, false],
    [AuthorityCode.WAIVER, false]
]);
