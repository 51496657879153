var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.ASSEMBLY_TEMPLATE,
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.closeDialog,
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.closeDialog.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('ButtonCancel', {
          on: {
            "click": _vm.closeDialog
          }
        }, [_vm._v("Close (esc)")])];
      },
      proxy: true
    }])
  }, [_c('template', {
    slot: "content"
  }, [_c('div', {
    staticClass: "c-grid"
  }, [_c('div', {
    staticClass: "col-full"
  }, [_c('MiniAssemblyTemplate', {
    key: _vm.assemblyTemplateId,
    attrs: {
      "title": "Selected template",
      "id": _vm.assemblyTemplateId,
      "show-children-initially": false,
      "read-only": ""
    }
  })], 1)]), _vm.transformedSelectedEntity ? _c('div', {
    staticClass: "c-grid mt-3"
  }, [_c('div', {
    staticClass: "col-sixth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Seq"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity.sequence) + " ")])])], 1), _c('div', {
    staticClass: "col-fourth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Name"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._name) + " ")])])], 1), _c('div', {
    staticClass: "col-fourth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Type"
    }
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._subType) + " ")])], 1), _c('div', {
    staticClass: "col-fourth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "PBS item ID"
    }
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._pbsItemId) + " ")])], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Children"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._childrenCount) + " ")])])], 1), _c('div', {
    staticClass: "col-fourth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Installed on"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._installedOn) + " ")])])], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Max qty / Min qty"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity.maxQuantity) + " ")]), _vm._v(" / "), _c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity.minQuantity) + " ")])])], 1)]) : _vm._e(), _vm.transformedSelectedEntity ? _c('div', {
    staticClass: "c-grid mt-2"
  }, [_c('div', {
    staticClass: "col-full"
  }, [!_vm.transformedSelectedEntity.autoHardwareListId ? _c('AppPageDescription', {
    attrs: {
      "remove-margin-left": "",
      "color": "info"
    }
  }, [_vm._v(" No associated COSMIC Item listing. ")]) : _c('div', [_c('AppBlockquote', {
    attrs: {
      "title": "Item listing"
    }
  }, [_c('div', {
    staticClass: "c-flex align-items-center",
    staticStyle: {
      "height": "40px"
    }
  }, [_c('IconAutoHardwareList', {
    attrs: {
      "icon-size": "24px"
    }
  }), _c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.transformedSelectedEntity.autoHardwareListName))])], 1)])], 1)], 1)]) : _vm._e(), _vm.transformedSelectedEntity ? _c('div', {
    staticClass: "c-grid mt-2"
  }, [_c('div', {
    staticClass: "col-full"
  }, [!_vm.transformedSelectedEntity.documentId ? _c('AppPageDescription', {
    attrs: {
      "remove-margin-left": "",
      "color": "info"
    }
  }, [_vm._v(" No associated COSMIC Document. ")]) : _c('MiniDocument', {
    attrs: {
      "title": "Associated Doc",
      "id": _vm.transformedSelectedEntity.documentId,
      "read-only": ""
    }
  })], 1)]) : _vm._e(), _vm.transformedSelectedEntity ? _c('div', {
    staticClass: "c-grid mt-2"
  }, [_c('div', {
    staticClass: "col-full"
  }, [!_vm.transformedSelectedEntity.cosmicEvents || !_vm.transformedSelectedEntity.cosmicEvents.length ? _c('AppPageDescription', {
    attrs: {
      "remove-margin-left": "",
      "color": "info"
    }
  }, [_vm._v(" No associated COSMIC Events. ")]) : _c('div', [_c('AppTable', {
    attrs: {
      "headers": _vm.cosmicEventTableHeaders,
      "items": _vm.transformedSelectedEntity._cosmicEvents
    }
  })], 1)], 1)]) : _vm._e(), _vm.transformedSelectedEntity ? _c('div', {
    staticClass: "c-grid mt-2"
  }, [_vm.transformedSelectedEntity.rootAssemblyTemplateId && _vm.transformedSelectedEntity.rootAssemblyTemplateId !== _vm.transformedSelectedEntity.parentId ? _c('div', {
    staticClass: "col-half"
  }, [_c('MiniAssemblyTemplate', {
    key: _vm.transformedSelectedEntity.rootAssemblyTemplateId,
    attrs: {
      "title": "Selected template's root",
      "id": _vm.transformedSelectedEntity.rootAssemblyTemplateId,
      "show-children-initially": false,
      "read-only": ""
    }
  })], 1) : _vm._e(), _vm.transformedSelectedEntity.parentId ? _c('div', {
    staticClass: "col-half"
  }, [_c('MiniAssemblyTemplate', {
    key: _vm.transformedSelectedEntity.parentId,
    attrs: {
      "title": "Selected template's direct parent",
      "id": _vm.transformedSelectedEntity.parentId,
      "show-children-initially": false,
      "read-only": ""
    }
  })], 1) : _vm._e()]) : _vm._e()])], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };