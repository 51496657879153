import { formatDistanceToNow } from 'date-fns';
import { toDate, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { CENTRAL_TIME_ZONE } from '~/nasa_ui/types';
export { format as formatDate } from 'date-fns-tz';
export * from './dateDisplay';
export * from './dateFrom';
export * from './dateTimeDisplay';
export * from './intervals';
export * from './isAfterInclusive';
export * from './isBeforeInclusive';
export * from './isWithinIntervalInclusive';
export * from './removeTime';
export * from './reportDateDisplay';
/**
 * Converts a "central-zoned" date object into a UTC date object.
 *
 * @param date The date to convert.
 */
export function centralToUtcTime(date) {
    try {
        if (typeof date === 'string' && date.includes('/')) {
            throw new Error('centralToUtcTime accepts only a whole or partial ISO-formatted string.');
        }
        return zonedTimeToUtc(date, CENTRAL_TIME_ZONE);
    }
    catch (err) {
        return null;
    }
}
/**
 * The purpose of this function is converting partial dates into complete ISO
 * datetime strings in UTC time. If the provided date string already has a time
 * zone offset then that will be used. Otherwise, the provided date string is
 * assumed to be central time and will be converted accordingly.
 *
 * @param dateString To date string to convert.
 * @returns A complete ISO datetime string in UTC time.
 */
export function createDbDateFromString(dateString) {
    let utcDate = null;
    // if it's already in yyyy-mm-dd format, return it
    if (isDateOnlyDateString(dateString)) {
        return dateString;
    }
    // if this date string includes a time zone offset...
    if (dateStringHasOffset(dateString)) {
        // ...we can parse this with the vanilla Date constructor, which will create
        // a normal UTC date
        utcDate = new Date(dateString);
    }
    else {
        // ...otherwise, assume it is central.
        // if it doesn't have a time, make it noon
        if (!dateString.includes('T')) {
            dateString = `${dateString}T12:00:00`;
        }
        // assume it is central and convert to UTC
        utcDate = zonedTimeToUtc(dateString, CENTRAL_TIME_ZONE);
    }
    // return an ISO string of the UTC date
    return utcDate.toISOString();
}
export const fromNow = (date, displayValue = DEFAULT_DASH) => {
    if (!date) {
        return displayValue;
    }
    return typeof date === 'string' ? formatDistanceToNow(new Date(date)) : formatDistanceToNow(date);
};
export function isDateOnlyDateString(dateString) {
    return dateString.length === 10 && dateString.substring(4, 5) === '-' && dateString.substring(7, 8) === '-';
}
/**
 * Parses a date string and converts it to an IParsedDate representing the
 * equivalent central time. The string provided is assumed to be central unless
 * it includes a specific time zone offset.
 *
 * @param date A whole or partial ISO-formatted date string
 */
export function parseDateIntoCentral(date) {
    try {
        if (date.includes('/')) {
            throw new Error('parseDate accepts only a whole or partial ISO-formatted string.');
        }
        // check if the date string already has an offset
        const hasOffset = dateStringHasOffset(date);
        let dateObj = null;
        if (hasOffset) {
            dateObj = utcToZonedTime(date, CENTRAL_TIME_ZONE);
        }
        else {
            // getting client timezone https://stackoverflow.com/questions/1091372/getting-the-clients-time-zone-and-offset-in-javascript
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || CENTRAL_TIME_ZONE;
            dateObj = toDate(date, { timeZone });
        }
        return {
            year: dateObj.getFullYear(),
            month: dateObj.getMonth(),
            day: dateObj.getDate(),
            hours: dateObj.getHours(),
            minutes: dateObj.getMinutes()
        };
    }
    catch (error) {
        console.log(error);
        return null;
    }
}
/**
 * Converts a UTC date (or a date string with _any_ offset) into a
 * "central-zoned" date object.
 *
 * @param date The date to convert.
 */
export function utcToCentralTime(date) {
    try {
        if (typeof date === 'string' && date.includes('/')) {
            throw new Error('utcToCentralTime accepts only a whole or partial ISO-formatted string.');
        }
        return utcToZonedTime(date, CENTRAL_TIME_ZONE);
    }
    catch (err) {
        return null;
    }
}
/**
 * Checks to see if the provided string includes an ISO-compliant time zone offset.
 *
 * @param dateString A whole or partial datetime string to be checked.
 */
export function dateStringHasOffset(dateString) {
    const parts = dateString.split('T');
    if (parts.length < 2) {
        return false;
    }
    return parts[1].includes('-') || parts[1].includes('+') || parts[1].includes('Z');
}
