import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component } from 'vue-property-decorator';
import { COSMIC_APPLICATION } from '~/application.config';
import BaseMini from '~/nasa_ui/base/BaseMini';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { CosmicContactSlimFragment } from '~/nasa_ui/DAL/contact/slim';
import { CosmicDocumentSlimFragment } from '~/nasa_ui/DAL/document/slim';
import { CosmicUserSlimFragment } from '~/nasa_ui/DAL/user/slim';
import { EntityType, EsocApplication } from '~/nasa_ui/types';
import { DocumentTypeDisplay, DocumentTypesWithoutLineItems } from '~/nasa_ui/types/enums/document';
// This component has been updated to use ONLY `id` no longer accepts NODE_ID as a prop
let MiniDocument = class MiniDocument extends BaseMini {
    localEntity = null;
    entityType = EntityType.DOCUMENT;
    BASE_FETCH = gql `
    ${CosmicContactSlimFragment}
    ${CosmicDocumentSlimFragment}
    ${CosmicUserSlimFragment}

    fragment MiniDocument on Document {
      ...CosmicDocumentSlim

      computedCloseDate
      computedOpenDate
      computedStatus
      createdDateTime
      initiatorContactId
      lastReviewDate
      releaseDate
      releaseStatus

      changeSets(first: 1) {
        totalCount
      }

      createdByUser {
        ...CosmicUserSlim
      }

      initiatorContact {
        ...CosmicContactSlim
      }
    }

    query MiniDocumentFetchById($id: UUID!) {
      documentById(id: $id) {
        ...MiniDocument
      }
    }
  `;
    get closeDateDisplay() {
        return this.$dateDisplay(this.localEntity?.computedCloseDate);
    }
    get createdByDisplay() {
        return this.$contactDisplay(this.localEntity?.createdByUser);
    }
    get hasLineItems() {
        return Boolean(this.localEntity?.changeSets?.totalCount);
    }
    get initiatorDisplay() {
        return this.$contactDisplay(this.localEntity?.initiatorContact);
    }
    get isClosed() {
        return Boolean(this.localEntity?.computedCloseDate);
    }
    get subTypeDisplay() {
        return this.localEntity ? DocumentTypeDisplay.get(this.localEntity.subType) || DEFAULT_DASH : DEFAULT_DASH;
    }
    get manageLink() {
        if (!this.localEntity) {
            return '';
        }
        if (COSMIC_APPLICATION === EsocApplication.CDOCS) {
            const docType = this.localEntity.subType;
            return `/${DocumentTypeDisplay.get(docType)?.toLowerCase()}/manage/${this.localEntity.id}`;
        }
        return `/${EntityType.DOCUMENT}/manage/${this.localEntity.id}`;
    }
    get name() {
        if (!this.localEntity && !this.isFetching) {
            return 'Not found';
        }
        return this.localEntity?.title || 'No title';
    }
    get number() {
        return this.localEntity?.number || '';
    }
    get usesLineItems() {
        return !(this.subType in DocumentTypesWithoutLineItems);
    }
    onFetchUpdate(data) {
        if (data.documentById) {
            this.localEntity = data.documentById;
        }
    }
};
MiniDocument = __decorate([
    Component
], MiniDocument);
export default MiniDocument;
