import { __decorate } from "tslib";
import { Component, Mixins } from 'vue-property-decorator';
import { DocumentType } from '~/db_types';
import BaseVue from '~/nasa_ui/base/BaseVue';
import { GENERAL_ENTITY_COLOR } from '~/nasa_ui/constants/colors';
import { AlertType, DocumentTypeDisplay, EntityType, UserPermission } from '~/nasa_ui/types';
import { baseAssetPath } from '~/nasa_ui/utils/helpers/assets';
import { currentSystemName } from '~/nasa_ui/utils/helpers/platform';
import CurrentUserMixin from '../mixins/CurrentUserMixin';
let BaseCosmic = class BaseCosmic extends Mixins(BaseVue, CurrentUserMixin) {
    AlertType = AlertType;
    baseAssetPath = baseAssetPath;
    DocumentType = DocumentType;
    DocumentTypeDisplay = DocumentTypeDisplay;
    EntityType = EntityType;
    GENERAL_ENTITY_COLOR = GENERAL_ENTITY_COLOR;
    UserPermission = UserPermission;
    get currentSystemName() {
        return currentSystemName(this.$cosmicSystem);
    }
    copyToClipboard(whatToCopy) {
        navigator.clipboard.writeText(whatToCopy).then(() => {
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'URL copied to clipboard!'
            });
        }, () => {
            this.$notification.add({
                type: AlertType.ERROR,
                text: 'Copy to clipboard failed.'
            });
        });
    }
};
BaseCosmic = __decorate([
    Component
], BaseCosmic);
export default BaseCosmic;
