var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.ASSEMBLY,
      "max-height": _vm.maxHeight,
      "hide-title-section": "",
      "modal-width": "95vw"
    },
    on: {
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_vm.id ? _c('MiniAssembly', {
          staticClass: "parent_mini",
          attrs: {
            "id": _vm.id,
            "allow-edit": false,
            "show-children": false,
            "title": "Install on to this parent"
          }
        }) : _vm._e(), !_vm.id && _vm.parentInventory && _vm.parentInventory.id ? _c('MiniInventory', {
          staticClass: "parent_mini",
          attrs: {
            "id": _vm.parentInventory.id,
            "title": "Install on to this parent"
          }
        }) : _vm._e(), _c('hr', {
          staticClass: "my-3"
        }), _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs6": "",
            "pr-3": ""
          }
        }, [_c('AppSelectEnum', {
          attrs: {
            "chips": false,
            "display": _vm.AuthorityCodeDisplay,
            "enum": _vm.AuthorityCode,
            "label": "Authority code",
            "sort": ""
          },
          model: {
            value: _vm.formData.authorityCode,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "authorityCode", $$v);
            },
            expression: "formData.authorityCode"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs6": ""
          }
        }, [_c('c-text', {
          attrs: {
            "label": "Authority number",
            "monospaced": "",
            "c-value": _vm.formData.authorityNumber
          },
          on: {
            "c-input": function cInput($event) {
              _vm.formData.authorityNumber = $event.detail;
            }
          }
        })], 1)], 1), _c('v-layout', {
          attrs: {
            "row": "",
            "nowrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs2": "",
            "mt-3": "",
            "mr-3": ""
          }
        }, [_c('AppNumber', {
          attrs: {
            "clearable": Boolean(_vm.parentAssemblyId),
            "error-messages": _vm.sequenceNumberErrorMessage,
            "messages": _vm.sequenceNumberMessage,
            "min": 0,
            "required": !_vm.isSequenceValid,
            "rules": [_vm.isSequenceZeroOrMore],
            "autofocus": "",
            "label": "Sequence"
          },
          on: {
            "error": _vm.onErrorSequence
          },
          model: {
            value: _vm.formData.sequence,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "sequence", $$v);
            },
            expression: "formData.sequence"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs2": "",
            "mt-3": "",
            "mr-3": ""
          }
        }, [_c('AppSelectEnum', {
          attrs: {
            "label": "Installed on",
            "enum": _vm.Side,
            "display": _vm.SideDisplay,
            "sort": false,
            "chips": false,
            "required": ""
          },
          model: {
            value: _vm.formData.installedOn,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "installedOn", $$v);
            },
            expression: "formData.installedOn"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs4": "",
            "ml-0": "",
            "mb-3": ""
          }
        }, [_c('AppDate', {
          attrs: {
            "label": "Override installed",
            "messages": ['* Overrides the inventory\'s default installed date of today.']
          },
          model: {
            value: _vm.formData.overrideInstalledDate,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "overrideInstalledDate", $$v);
            },
            expression: "formData.overrideInstalledDate"
          }
        })], 1)], 1), _c('AppFormWrapper', {
          attrs: {
            "color": _vm.EntityType.INVENTORY,
            "size": "1"
          }
        }, [_c('AppPageDescription', {
          attrs: {
            "color": "success"
          }
        }, [_vm._v("Inventory to install")]), _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_vm.assemblyTemplate ? _c('v-flex', {
          attrs: {
            "xs12": "",
            "mb-3": ""
          }
        }, [_c('AppTable', {
          attrs: {
            "color": _vm.EntityType.ITEM_MASTER,
            "headers": _vm.AssemblyTemplatePartTableHeaders,
            "hide-pagination": _vm.assemblyTemplatePartTableItems.length < 5,
            "items": _vm.assemblyTemplatePartTableItems,
            "rows-per-page-items": _vm.assemblyTemplateRowsPerPageItems
          }
        }, [_c('template', {
          slot: "title"
        }, [_vm._v(" Hardware shopping list ( "), _c('small', {
          staticClass: "monospace_font"
        }, [_c('strong', [_vm._v(_vm._s(_vm.assemblyTemplatePartTableItems.length))])]), _vm._v(" ) ")])], 2)], 1) : _vm._e(), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_vm.shouldShowLocationError ? _c('Alert', {
          attrs: {
            "type": _vm.AlertType.ERROR
          }
        }, [_c('p', [_vm._v(" The selected inventory's location differs from the parent location. "), _c('a', {
          attrs: {
            "href": _vm.buildHardwareMovementUrl
          }
        }, [_vm._v("Move this Inventory")]), _vm._v(" to the parent's location before attempting to install it. ")])]) : _vm._e(), _vm.shouldShowLocationAlert ? _c('Alert', {
          attrs: {
            "type": _vm.AlertType.WARNING
          }
        }, [_c('p', [_vm._v(" The selected inventory's location differs from the parent's. This inventory's location will be moved to the location of the parent. ")])]) : _vm._e(), _vm.shouldShowClassMismatchAlert ? _c('Alert', {
          attrs: {
            "type": _vm.AlertType.WARNING
          }
        }, [_c('p', [_vm._v(" The child's class ("), _c('b', [_vm._v(_vm._s(_vm.childClassDisplay))]), _vm._v(") you are about to install does not match the parent's class ("), _c('b', [_vm._v(_vm._s(_vm.parentClassDisplay))]), _vm._v("). ")])]) : _vm._e(), _vm.shouldShowProjectCodeAlert ? _c('Alert', {
          attrs: {
            "type": _vm.AlertType.WARNING
          }
        }, [_c('p', [_c('strong', [_vm._v("Project Code Warning:")]), _vm._v(" The child you are installing will have its project code changed to "), _c('strong', {
          staticClass: "monospace_font danger project_code"
        }, [_vm._v(_vm._s(_vm.parentProjectCode))]), _vm._v(" once it's installed, bypassing the normal approval routing for project code changes. ")])]) : _vm._e()], 1), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_vm.shouldShowSearchInventory ? _c('SearchInventory', {
          attrs: {
            "existingValues": [_vm.parentInventory ? _vm.parentInventory.id : ''],
            "filters": _vm.installSearchInventoryProps.filters,
            "output-mode": _vm.installSearchInventoryProps.outputMode,
            "locked-filters": _vm.installSearchInventoryProps.lockedFilters
          },
          on: {
            "input": _vm.onSelectOfInventoryFromSearch
          }
        }) : _vm._e(), !_vm.shouldShowSearchInventory && _vm.selectedInventory ? _c('AppHardwareDisplay', {
          attrs: {
            "node-id": _vm.selectedInventory.nodeId,
            "title": "Selected Inventory"
          }
        }) : _vm._e(), _vm.selectedInventory ? _c('ButtonClearSelection', {
          staticClass: "ml-0 mb-2",
          on: {
            "click": function click($event) {
              _vm.selectedInventory = null;
            }
          }
        }, [_c('span', [_vm._v("Clear selected Inventory")])]) : _vm._e()], 1), _vm.selectedInventory ? _c('v-flex', {
          attrs: {
            "xs3": "",
            "mt-3": ""
          }
        }, [_c('AppNumber', {
          attrs: {
            "disabled": _vm.shouldDisableQuantityField,
            "min": 0.01,
            "max": _vm.selectedInventory.quantity ? _vm.selectedInventory.quantity : 1,
            "color": _vm.EntityType.INVENTORY,
            "label": "Qty",
            "required": !_vm.shouldDisableQuantityField
          },
          on: {
            "error": _vm.onErrorQuantity
          },
          model: {
            value: _vm.formData.quantity,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "quantity", $$v);
            },
            expression: "formData.quantity"
          }
        })], 1) : _vm._e()], 1)], 1), _vm.openParentChangeSets.length ? _c('v-flex', {
          staticClass: "mt-3"
        }, [_vm._v(" Open Change Sets on this node ("), _c('small', {
          staticClass: "monospace_font"
        }, [_vm._v(_vm._s(_vm.openParentChangeSets.length))]), _vm._v(") "), _vm.openParentChangeSetIds.length ? _c('v-data-iterator', {
          attrs: {
            "items": _vm.openParentChangeSetIds,
            "content-tag": "v-layout",
            "row": "",
            "wrap": "",
            "hide-actions": ""
          },
          scopedSlots: _vm._u([{
            key: "item",
            fn: function fn(_ref) {
              var item = _ref.item;
              return [_c('v-flex', {
                attrs: {
                  "xs12": "",
                  "sm6": "",
                  "md4": "",
                  "lg3": "",
                  "px-1": ""
                }
              }, [_c('SummaryCardChangeSet', {
                attrs: {
                  "change-set-id": item
                }
              })], 1)];
            }
          }], null, false, 3183094037)
        }) : _vm._e()], 1) : _vm._e(), _c('v-layout', {
          staticClass: "pending-cs-warning",
          attrs: {
            "row": "",
            "no-wrap": "",
            "justify-start": ""
          }
        }, [_vm.userNeedsApprovalToInstallAssembly ? _c('span', [_c('v-avatar', [_c('c-icon-fa', {
          attrs: {
            "icon": "fad fa-info-circle"
          }
        })], 1), _c('em', [_vm._v("Installing an assembly requires approval. Click 'request installation' to open a pending change set for approval.")])], 1) : _vm._e(), _vm.openParentChangeSets && _vm.openParentChangeSets.length > 0 ? _c('span', [_c('v-avatar', [_c('c-icon-fa', {
          attrs: {
            "icon": "fad fa-info-circle"
          }
        })], 1), _c('em', [_vm._v("Cannot install an assembly on this parent as there are open change sets.")])], 1) : _vm._e()])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [!_vm.userNeedsApprovalToInstallAssembly ? _c('ButtonCreate', {
          attrs: {
            "disabled": _vm.shouldDisableCreateButton,
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfCreateAssembly
          },
          scopedSlots: _vm._u([{
            key: "icon",
            fn: function fn() {
              return [_c('c-icon-fa', {
                staticClass: "pr-2",
                attrs: {
                  "icon": _vm.$icons.install
                }
              })];
            },
            proxy: true
          }], null, false, 3784602053)
        }, [_vm._v(" Install Assembly ")]) : _c('ButtonCreate', {
          attrs: {
            "color": _vm.EntityType.CHANGE_SET,
            "disabled": _vm.shouldDisableCreateButton
          },
          on: {
            "click": _vm.onClickOfCreateAssembly
          },
          scopedSlots: _vm._u([{
            key: "icon",
            fn: function fn() {
              return [_c('c-icon-fa', {
                staticClass: "pr-2",
                attrs: {
                  "icon": _vm.$icons.install
                }
              })];
            },
            proxy: true
          }])
        }, [_vm._v(" Request Installation ")]), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };