var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import BaseMini from '~/nasa_ui/base/BaseMini';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import HttpMixin from '~/nasa_ui/mixins/HttpMixin';
import { EntityType } from '~/nasa_ui/types';
let MiniUserGroup = class MiniUserGroup extends Mixins(BaseMini, HttpMixin) {
    entityType = EntityType.USER_GROUP;
    localEntity = null;
    get codeDisplay() {
        return this.localEntity?.code || DEFAULT_DASH;
    }
    get manageLink() {
        return this.localEntity ? `/${EntityType.USER}/groups/manage/${this.localEntity.code}` : '';
    }
    get name() {
        if (!this.localEntity && !this.isFetching) {
            return 'Not found';
        }
        return (this.$get(this, 'localEntity.name') || this.codeDisplay) || DEFAULT_DASH;
    }
    get totalUsersDisplay() {
        if (!this.localEntity || !this.localEntity.users) {
            return '0';
        }
        return this.$numberDisplay(this.localEntity.users.length);
    }
    async fetchGroupByCode(code) {
        const resp = await this.httpGet(`/user-groups/${code}`);
        this.localEntity = resp.data;
    }
    onGroupCodeForMiniChange(code) {
        if (!code) {
            return;
        }
        this.fetchGroupByCode(code);
    }
};
__decorate([
    Watch('groupCodeForMini', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], MiniUserGroup.prototype, "onGroupCodeForMiniChange", null);
MiniUserGroup = __decorate([
    Component
], MiniUserGroup);
export default MiniUserGroup;
