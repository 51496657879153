var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop } from 'vue-property-decorator';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { ContactRepoMixin } from '~/nasa_ui/DAL/contact';
let ContactCreateForm = class ContactCreateForm extends Mixins(BaseCosmic, ContactRepoMixin) {
    isSaving = false;
    formData = {
        auid: null,
        email: [],
        firstName: null,
        lastName: null,
        middleName: null
    };
    defaults;
    get isValid() {
        return Boolean(this.formData?.email?.length);
    }
    created() {
        Object.assign(this.formData, this.defaults);
    }
    async createNewContact() {
        const resp = await this.createContact(this.formData, {
            success: `New Contact created: ${this.$contactDisplay(this.formData)}`
        });
        if (resp.data) {
            this.$message('contact_created', resp.data);
            this.$emit('save', resp.data);
        }
    }
};
__decorate([
    Prop({
        required: false,
        type: Object,
        default: () => ({})
    }),
    __metadata("design:type", typeof (_a = typeof Partial !== "undefined" && Partial) === "function" ? _a : Object)
], ContactCreateForm.prototype, "defaults", void 0);
ContactCreateForm = __decorate([
    Component
], ContactCreateForm);
export default ContactCreateForm;
