import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
let LoaderMixin = class LoaderMixin extends Vue {
    isLoading = false;
    isSaving = false;
};
LoaderMixin = __decorate([
    Component
], LoaderMixin);
export default LoaderMixin;
