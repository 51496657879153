var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.showNotification ? _c('div', {
    staticClass: "toaster-wrap",
    attrs: {
      "timeout": _vm.timeout
    }
  }, [_c('div', {
    staticClass: "toaster-bar toaster-animated",
    class: _vm.color
  }, [_c('span', {
    directives: [{
      name: "safe-html",
      rawName: "v-safe-html",
      value: _vm.displayText,
      expression: "displayText"
    }],
    staticClass: "mr-2",
    class: _vm.color === _vm.AlertType.WARNING ? 'black--text' : 'white--text'
  }), _c('ButtonMiniAction', {
    attrs: {
      "icon-color": _vm.color === _vm.AlertType.WARNING ? '#000' : '#fff',
      "icon": _vm.$icons.close,
      "icon-size": "20px"
    },
    on: {
      "click": _vm.clearNotification
    }
  })], 1)]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };