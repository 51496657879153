import { CommentType } from '~/db_types';
export var NonEditableCommentTypes;
(function (NonEditableCommentTypes) {
    NonEditableCommentTypes["SYSTEM"] = "SYSTEM";
})(NonEditableCommentTypes || (NonEditableCommentTypes = {}));
export var EditableCommentTypes;
(function (EditableCommentTypes) {
    EditableCommentTypes["ACTIVITY"] = "ACTIVITY";
    EditableCommentTypes["AUDIT"] = "AUDIT";
    EditableCommentTypes["AUTHORITY_COMMENT"] = "AUTHORITY_COMMENT";
    EditableCommentTypes["CANCEL_REASON"] = "CANCEL_REASON";
    EditableCommentTypes["CHANGE_NOTE"] = "CHANGE_NOTE";
    EditableCommentTypes["CLOSURE"] = "CLOSURE";
    EditableCommentTypes["DISPOSITION"] = "DISPOSITION";
    EditableCommentTypes["EFFECTIVITY"] = "EFFECTIVITY";
    EditableCommentTypes["ESEL"] = "ESEL";
    EditableCommentTypes["EVENT_MILESTONE"] = "EVENT_MILESTONE";
    EditableCommentTypes["FINAL_DISPOSITION"] = "FINAL_DISPOSITION";
    EditableCommentTypes["GENERAL"] = "GENERAL";
    EditableCommentTypes["INITIALIZATION_COMMENT"] = "INITIALIZATION_COMMENT";
    EditableCommentTypes["INITIATOR_CONCURRENCE"] = "INITIATOR_CONCURRENCE";
    EditableCommentTypes["LEAKAGE_HISTORY"] = "LEAKAGE_HISTORY";
    EditableCommentTypes["LIMITED_LIFE"] = "LIMITED_LIFE";
    EditableCommentTypes["LOGISTICS"] = "LOGISTICS";
    EditableCommentTypes["MAINTENANCE"] = "MAINTENANCE";
    EditableCommentTypes["MEETING_MINUTE"] = "MEETING_MINUTE";
    EditableCommentTypes["NOTE"] = "NOTE";
    EditableCommentTypes["OEM"] = "OEM";
    EditableCommentTypes["ORB_NOTE"] = "ORB_NOTE";
    EditableCommentTypes["PROCESSOR"] = "PROCESSOR";
    EditableCommentTypes["REVIEW_DISPOSITION"] = "REVIEW_DISPOSITION";
    EditableCommentTypes["SIZING"] = "SIZING";
    EditableCommentTypes["STATUS"] = "STATUS";
    EditableCommentTypes["TRANSACTION"] = "TRANSACTION";
    EditableCommentTypes["TREND_NOTE"] = "TREND_NOTE";
})(EditableCommentTypes || (EditableCommentTypes = {}));
export const CommentTypeDisplay = new Map([
    [CommentType.ACTIVITY, 'xCtx Activity'],
    [CommentType.AUDIT, 'Audit'],
    [CommentType.AUTHORITY_COMMENT, 'Authority'],
    [CommentType.CANCEL_REASON, 'Cancel reason'],
    [CommentType.CHANGE_NOTE, 'Change note'],
    [CommentType.CLOSURE, 'Closure'],
    [CommentType.DISPOSITION, 'Disposition'],
    [CommentType.EFFECTIVITY, 'Effectivity'],
    [CommentType.ESEL, 'ESEL'],
    [CommentType.EVENT_MILESTONE, 'Milestone'],
    [CommentType.FINAL_DISPOSITION, 'Final disposition'],
    [CommentType.GENERAL, 'General'],
    [CommentType.INITIALIZATION_COMMENT, 'Initialization'],
    [CommentType.INITIATOR_CONCURRENCE, 'Initiator concurrence'],
    [CommentType.LEAKAGE_HISTORY, 'Leakage history'],
    [CommentType.LIMITED_LIFE, 'Limited life'],
    [CommentType.LOGISTICS, 'Logistics'],
    [CommentType.MAINTENANCE, 'Maintenance'],
    [CommentType.MEETING_MINUTE, 'Meeting minute'],
    [CommentType.NASA_APPROVAL, 'NASA - Approval'],
    [CommentType.NASA_RECLAMA, 'NASA - Reclama'],
    [CommentType.NOTE, 'Note'],
    [CommentType.OEM, 'OEM'],
    [CommentType.ORB_NOTE, 'ORB note'],
    [CommentType.PROCESSOR, 'Processor'],
    [CommentType.REVIEW_DISPOSITION, 'Review disposition'],
    [CommentType.SIZING, 'Sizing'],
    [CommentType.STATUS, 'Status'],
    [CommentType.SYSTEM, 'System'],
    [CommentType.TRANSACTION, 'Transaction'],
    [CommentType.TREND_NOTE, 'Trend note']
]);
