import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins } from 'vue-property-decorator';
import HttpMixin from '~/nasa_ui/mixins/HttpMixin';
import LoaderMixin from '~/nasa_ui/mixins/LoaderMixin';
import { CosmicContactSlimFragment } from '../contact/slim';
import { CosmicOrganizationSlimFragment } from '../organization/slim';
import { CosmicLocationSlimFragment } from './slim';
export const CosmicLocationFragment = gql `
  ${CosmicLocationSlimFragment}
  ${CosmicContactSlimFragment}

  fragment CosmicLocation on Location {
    ...CosmicLocationSlim

    contact {
      ...CosmicContactSlim
    }
  }
`;
export const CosmicLocationWithOrgFragment = gql `
  ${CosmicLocationFragment}
  ${CosmicOrganizationSlimFragment}

  fragment CosmicLocationWithOrg on Location {
    ...CosmicLocation

    organization {
      ...CosmicOrganizationSlim
    }
  }
`;
export const LocationInventoryFragment = gql `
  ${CosmicLocationSlimFragment}

  fragment LocationInventory on Inventory {
    asBuiltNumber
    drawingNumber
    id
    isInstalled
    itemDrawingDescription
    lotNumber
    nodeId
    projectCode
    quantity
    serialNumber
    side
    size
    subType
    unitCost

    itemInstance {
      nodeId
    }

    location {
      ...CosmicLocationSlim
    }
  }
`;
let LocationRepoMixin = class LocationRepoMixin extends Mixins(HttpMixin, LoaderMixin) {
};
LocationRepoMixin = __decorate([
    Component
], LocationRepoMixin);
export { LocationRepoMixin };
