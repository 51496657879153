import { __decorate } from "tslib";
import { Component, Mixins } from 'vue-property-decorator';
import BaseButtonMiniActionImplementation from '~/nasa_ui/base/BaseButtonMiniActionImplementation';
import ButtonMiniAction from '~/nasa_ui/components/buttons/ButtonMiniAction/ButtonMiniAction.vue';
/**
 *
 */
let ButtonMiniActionEdit = class ButtonMiniActionEdit extends Mixins(BaseButtonMiniActionImplementation) {
    get computedTooltip() {
        return this.tooltip || 'Edit metadata';
    }
};
ButtonMiniActionEdit = __decorate([
    Component({
        components: { ButtonMiniAction }
    })
], ButtonMiniActionEdit);
export default ButtonMiniActionEdit;
