import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
let HeaderAlert = class HeaderAlert extends Vue {
    alertText;
    dismissible;
    navOpenState;
    type;
    emitInput(val) { }
};
__decorate([
    Prop(String),
    __metadata("design:type", Object)
], HeaderAlert.prototype, "alertText", void 0);
__decorate([
    Prop({ default: true }),
    __metadata("design:type", String)
], HeaderAlert.prototype, "dismissible", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], HeaderAlert.prototype, "navOpenState", void 0);
__decorate([
    Prop({ default: 'success' }),
    __metadata("design:type", String)
], HeaderAlert.prototype, "type", void 0);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], HeaderAlert.prototype, "emitInput", null);
HeaderAlert = __decorate([
    Component
], HeaderAlert);
export default HeaderAlert;
