var _a, _b;
import { __decorate, __metadata } from "tslib";
import debounce from 'lodash/debounce';
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import LoaderMixin from '~/nasa_ui/mixins/LoaderMixin';
import { Maybe } from '~/nasa_ui/types';
import { isNullOrUndefined } from '~/nasa_ui/utils';
let AppAutocomplete = class AppAutocomplete extends Mixins(LoaderMixin) {
    options = [];
    searchTerm = '';
    cacheOptions;
    chipTextColor;
    color;
    value;
    label;
    placeholder;
    multiple;
    searchFn;
    getByValueFn; // populate select options with pre-selected values
    debounceMs;
    searchTermMinLength;
    get debouncedSearch() {
        const debounceMs = parseInt(`${this.debounceMs}`);
        if (isNaN(debounceMs)) {
            throw Error(`Invalid debounce timer: ${this.debounceMs}`);
        }
        return debounce(async () => {
            const resp = await this.searchFn(this.searchTerm);
            this.options = resp ?? [];
            this.isLoading = false;
        }, debounceMs);
    }
    get searchTermHasMinimumCharacters() {
        return this.searchTerm?.length >= +this.searchTermMinLength;
    }
    async onSearchTermUpdate(q) {
        if (!q || q.length < +this.searchTermMinLength) {
            return;
        }
        this.isLoading = true;
        await this.debouncedSearch();
    }
    // if an item got added but isn't already in the list of options
    // we need to add it
    async onValueUpdate(value) {
        // handle multiselect
        // value could be a single value from the query string but in a multiple select so cast as array for multiple check
        const values = Array.isArray(value) || !value ? value : [value];
        if (this.multiple && Array.isArray(values) && this.getByValueFn) {
            const valuesNeedingOption = values.filter((v) => {
                return !this.options.some((opt) => opt.value === v);
            });
            if ((Array.isArray(values) && valuesNeedingOption.length) || (!Array.isArray(values) && valuesNeedingOption)) {
                const resp = await this.getByValueFn(valuesNeedingOption);
                const newOptions = Array.isArray(resp) ? resp : [resp]; // ensure array
                this.options = [...this.options, ...newOptions];
            }
            return;
        }
        // handle single select
        if (!this.multiple && !isNullOrUndefined(value) && this.getByValueFn) {
            const needsNewOption = !this.options.some((opt) => opt.value === value);
            if (needsNewOption) {
                const resp = await this.getByValueFn(value);
                const newOptions = Array.isArray(resp) ? resp : [resp]; // ensure array
                this.options = [...this.options, ...newOptions];
            }
        }
    }
    onChange(val) {
        this.searchTerm = '';
        return val ?? null;
    }
};
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], AppAutocomplete.prototype, "cacheOptions", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppAutocomplete.prototype, "chipTextColor", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppAutocomplete.prototype, "color", void 0);
__decorate([
    Prop({
        required: true
    }),
    __metadata("design:type", Object)
], AppAutocomplete.prototype, "value", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], AppAutocomplete.prototype, "label", void 0);
__decorate([
    Prop({
        type: String,
        default: 'Search'
    }),
    __metadata("design:type", String)
], AppAutocomplete.prototype, "placeholder", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], AppAutocomplete.prototype, "multiple", void 0);
__decorate([
    Prop({
        required: true,
        type: Function
    }),
    __metadata("design:type", Function)
], AppAutocomplete.prototype, "searchFn", void 0);
__decorate([
    Prop({
        required: false,
        type: Function
    }),
    __metadata("design:type", typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object)
], AppAutocomplete.prototype, "getByValueFn", void 0);
__decorate([
    Prop({
        default: 300,
        type: [Number, String]
    }),
    __metadata("design:type", Object)
], AppAutocomplete.prototype, "debounceMs", void 0);
__decorate([
    Prop({
        default: 3,
        type: [Number, String]
    }),
    __metadata("design:type", Object)
], AppAutocomplete.prototype, "searchTermMinLength", void 0);
__decorate([
    Watch('searchTerm'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", Promise)
], AppAutocomplete.prototype, "onSearchTermUpdate", null);
__decorate([
    Watch('value', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Promise)
], AppAutocomplete.prototype, "onValueUpdate", null);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AppAutocomplete.prototype, "onChange", null);
AppAutocomplete = __decorate([
    Component
], AppAutocomplete);
export default AppAutocomplete;
