import { __decorate, __metadata } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { UserSupportContext, UserSupportContextToValidDocumentTypes } from '~/application.config';
import { UserGroupType, UserRole } from '~/db_types/swagger_types';
import { CURRENT_SUPPORT_CONTEXT_LS_VARIABLE } from '../constants';
import { fetchCurrentUser } from '../router/utils';
import { store } from '../store';
import { EntityType } from '../types';
import LocalStorageService from '../utils/LocalStorageService';
export const initializeCurrentUser = async () => {
    try {
        const user = await fetchCurrentUser();
        if (!user) {
            throw Error('No user found.');
        }
        // Filter out ctx that dont belong
        const currentSupportContexts = (user?.supports || []).filter((cosmicContext) => Object.values(UserSupportContext).includes(cosmicContext.organizationCode));
        if (!currentSupportContexts || !currentSupportContexts.length) {
            throw Error('No support contexts found.');
        }
        const localStorageActiveContext = getActiveSupportContext();
        if (localStorageActiveContext) {
            return user;
        }
        const orgCode = currentSupportContexts[0].organizationCode;
        if (orgCode) {
            setActiveSupportContext(orgCode);
        }
        return user;
    }
    catch (error) {
        // TypeScript knows err is Error
        if (error instanceof Error) {
            if (error.message === 'No user found.') {
                if (!window.location.pathname.includes('no_access')) {
                    window.location.href = `${window.location.origin}/no_access`;
                    throw error;
                }
            }
            if (error.message === 'No support contexts found.') {
                if (!window.location.pathname.includes('no_supports')) {
                    window.location.href = `${window.location.origin}/no_supports`;
                }
            }
        }
        else {
            console.log('Unexpected error: ', error);
        }
    }
};
export const clearActiveSupportContext = () => LocalStorageService.removeItem(CURRENT_SUPPORT_CONTEXT_LS_VARIABLE);
export const setActiveSupportContext = (context) => LocalStorageService.setItem(CURRENT_SUPPORT_CONTEXT_LS_VARIABLE, context);
export const getActiveSupportContext = () => {
    return LocalStorageService.getItem(CURRENT_SUPPORT_CONTEXT_LS_VARIABLE);
};
export const logout = () => {
    LocalStorageService.clear();
    window.location.href = `/api/oauth/logout?redirect=${window.encodeURIComponent(window.location.toString())}`;
};
let CurrentUserMixin = class CurrentUserMixin extends Vue {
    currentUser = null;
    currentUserActiveSupportContext = null;
    get currentUserActiveSupportContextValidDocumentTypes() {
        return this.currentUserActiveSupportContext
            ? UserSupportContextToValidDocumentTypes.get(this.currentUserActiveSupportContext)
            : [];
    }
    get currentUserDelegateGroup() {
        return this.currentUser?.userGroups?.find((ug) => ug.supportContext === this.currentUserActiveSupportContext &&
            ug.delegateGroupForUserId === this.currentUser?.id &&
            ug.subType === UserGroupType.USER_DELEGATE);
    }
    get currentUserEmail() {
        return this.currentUser?.email ? this.currentUser?.email[0] : '';
    }
    get currentUserHasSupportContexts() {
        return Boolean(this.currentUserSupportContexts?.length);
    }
    get currentUserFirstLastNameDisplay() {
        if (!this.currentUser) {
            return null;
        }
        return this.$contactDisplay(this.currentUser);
    }
    get currentUserGroups() {
        if (!this.currentUser) {
            return null;
        }
        return this.currentUser.userGroups?.filter((g) => g.supportContext === this.currentUserActiveSupportContext) || [];
    }
    get currentUserId() {
        return this.currentUser?.id ?? null;
    }
    get currentUserIsAdmin() {
        return this.currentUserRole === UserRole.ADMIN;
    }
    // This cant/shouldnt happen in deployed applications, only locally
    get currentUserIsSystem() {
        return this.currentUserRole === UserRole.SYSTEM;
    }
    get currentUserPermissions() {
        return this.currentUser?.supports?.find((support) => support.organizationCode === this.currentUserActiveSupportContext)?.permissions;
    }
    get currentUserRole() {
        return this.currentUser?.role ?? null;
    }
    /**
     * Get all UserSupportContextResponse's the user
     */
    get currentUserSupportContexts() {
        return this.currentUser?.supports || [];
    }
    /**
     * Of the currentUserSupportContexts find the one that matches the active support context
     */
    get currentUserActiveSupportContextObject() {
        return (this.currentUserSupportContexts.find((ctxObj) => ctxObj.organizationCode === this.currentUserActiveSupportContext) ?? null);
    }
    /**
     * This is a shortcut method to get the currentUserActiveSupportContextRole array
     */
    get currentUserActiveSupportContextRoles() {
        return this.currentUserActiveSupportContextObject?.roles || [];
    }
    addFavorite(favorite, entityType) {
        if (!entityType || !this.currentUserActiveSupportContext) {
            console.log('no entity type or current active support context `addFavorite`');
            return;
        }
        const favorites = Object.assign({}, this.currentUser?.attributes?.favorites || {});
        if (!favorites[this.currentUserActiveSupportContext]) {
            favorites[this.currentUserActiveSupportContext] = {};
        }
        if (!favorites[this.currentUserActiveSupportContext][entityType]) {
            favorites[this.currentUserActiveSupportContext][entityType] = [];
        }
        favorites[this.currentUserActiveSupportContext][entityType].push(favorite);
        console.log(favorite);
        // update user attributes with favorites;
        return this.updateUserFavorites(favorites);
    }
    removeFavorite(favorite, entityType) {
        if (!entityType || !this.currentUserActiveSupportContext) {
            return;
        }
        const favorites = this.currentUser?.attributes?.favorites || {};
        if (!favorites[this.currentUserActiveSupportContext] ||
            !favorites[this.currentUserActiveSupportContext][entityType]) {
            return;
        }
        switch (entityType) {
            case EntityType.ITEM_DRAWING:
                // remove favorite using drawingNumber
                favorites[this.currentUserActiveSupportContext][entityType] = favorites[this.currentUserActiveSupportContext][entityType].filter((existingFavorite) => existingFavorite.drawingNumber !== favorite.drawingNumber);
                break;
            case EntityType.ITEM_MASTER:
                // remove favorite using drawingNumber, asBuiltNumber, side
                favorites[this.currentUserActiveSupportContext][entityType] = favorites[this.currentUserActiveSupportContext][entityType].filter((existingFavorite) => {
                    return (existingFavorite.drawingNumber !== favorite.drawingNumber &&
                        existingFavorite.asBuiltNumber !== favorite.asBuiltNumber &&
                        existingFavorite.side !== favorite.side);
                });
                break;
            default:
                // remove favorite using id/string/number
                favorites[this.currentUserActiveSupportContext][entityType] = favorites[this.currentUserActiveSupportContext][entityType].filter((existingFavorite) => existingFavorite !== favorite);
                break;
        }
        // update user attributes with favorites;
        return this.updateUserFavorites(favorites);
    }
    updateUserFavorites(favorites) {
        if (this.currentUserActiveSupportContext) {
            const headers = {
                'x-organization': this.currentUserActiveSupportContext
            };
            // favorites are listed under each support context
            const allFavorites = Object.assign({}, {
                ...favorites
            });
            const payload = Object.assign(this.currentUser?.attributes, { favorites: allFavorites });
            return this.$http.put(`/users/current/attributes`, payload, {
                headers
            });
        }
        else {
            console.log('no active support context');
        }
    }
    clearCurrentUserActiveSupportContext() {
        clearActiveSupportContext();
    }
    currentUserHasPermission(permissionToCheck) {
        if (!this.currentUserPermissions) {
            return false;
        }
        return Boolean(this.currentUserPermissions[permissionToCheck]);
    }
    currentUserHasUserRole(userRolesToCheck) {
        // If you dont have a UserRole assigned somehow by definition you dont have whatever is passed in
        if (!this.currentUserRole) {
            return false;
        }
        // Nullish values or empty array are treated as a bypass
        if (!userRolesToCheck || !userRolesToCheck.length) {
            return true;
        }
        // If youre an ADMIN you win
        if (this.currentUserIsAdmin) {
            return true;
        }
        // Does the user's current role exist in the valid list of UserRoles passed in?
        return userRolesToCheck.includes(this.currentUserRole);
    }
    currentUserHasContextRole(contextRolesToCheck) {
        if (contextRolesToCheck.length === 0) {
            return true;
        }
        // // requirements are meant to be ORs, so make sure at least one of them is met
        // return contextRolesToCheck.some((requiredContextRole) =>
        //   // the user meets the requirement if one of their CosmicRoles meets or exceeds the minimum role weight
        //   this.currentUserActiveSupportContextRoles.some((userCosmicRole) =>
        //     hasMinimumRoleWeight(requiredContextRole, userCosmicRole)
        //   )
        // );
    }
    created() {
        this.setDefaultActiveContext();
    }
    isCurrentUserInGroup(groupCode) {
        if (!groupCode || !this.currentUserGroups) {
            return false;
        }
        return this.currentUserGroups.some((group) => group?.code === groupCode) || false;
    }
    setCurrentUserActiveSupportContext(context) {
        this.currentUserActiveSupportContext = context;
        if (!context) {
            clearActiveSupportContext();
        }
        else {
            setActiveSupportContext(context);
        }
    }
    setDefaultActiveContext() {
        const activeSupportContext = getActiveSupportContext();
        if (activeSupportContext) {
            this.currentUserActiveSupportContext = activeSupportContext;
        }
        else {
            const allContexts = this.currentUserSupportContexts ?? [];
            const firstContext = allContexts[0]?.organizationCode;
            this.setCurrentUserActiveSupportContext(firstContext);
        }
    }
    onRoutezChange() {
        this.currentUser = Object.assign({}, store.currentUser);
    }
};
__decorate([
    Watch('$store.currentUser', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], CurrentUserMixin.prototype, "onRoutezChange", null);
CurrentUserMixin = __decorate([
    Component
], CurrentUserMixin);
export default CurrentUserMixin;
