import { PhysicalInventoryAuditAdjustmentReason } from '~/db_types';
export const AdjustmentReasonDisplay = new Map([
    [PhysicalInventoryAuditAdjustmentReason.DAMAGE_OR_DESTRUCTION, '02 - Damage or Destruction'],
    [PhysicalInventoryAuditAdjustmentReason.EXCESS_TRANSFERS_TO_PDO, '11 - Excess Transfers to PDO'],
    [PhysicalInventoryAuditAdjustmentReason.LOSS, '04 - Loss'],
    [PhysicalInventoryAuditAdjustmentReason.OBSOLESCENCE_OR_DETERIORATION, '03 - Obsolescence or Deterioration'],
    [PhysicalInventoryAuditAdjustmentReason.OPERATIONAL_ERRORS, '07 - Operational Errors'],
    [PhysicalInventoryAuditAdjustmentReason.PHYSICAL_INVENTORY_DISCREPANCIES, '01 - Physical Inventory Discrepancies'],
    [PhysicalInventoryAuditAdjustmentReason.RETURNS_TO_VENDOR, '10 - Returns to Vendor'],
    [PhysicalInventoryAuditAdjustmentReason.THEFT, '05 - Theft']
]);
