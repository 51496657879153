import { getBlurbAboutCreate, getBlurbAboutManage, getBlurbAboutSearch } from '~/nasa_ui/router/utils';
import { EntityType, EntityTypeSingularDisplay, UserPermission } from '~/nasa_ui/types';
export const ContactSharedRoutes = [
    {
        path: `/${EntityType.CONTACT}/create`,
        name: 'ContactsCreateView',
        component: () => import(/* webpackChunkName: "Contact" */ '../views/ContactsCreateView/ContactsCreateView.vue'),
        meta: {
            displayName: 'Contacts create',
            description: getBlurbAboutCreate(EntityTypeSingularDisplay.get(EntityType.CONTACT)),
            permissions: [UserPermission.CONTACT_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.CONTACT}/manage`,
        redirect: {
            name: 'ContactsSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.CONTACT)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.CONTACT)),
            permissions: [UserPermission.CONTACT_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.CONTACT}/manage/:id`,
        name: 'ContactsManageView',
        component: () => import(/* webpackChunkName: "Contact" */ '../views/ContactsManageView/ContactsManageView.vue'),
        meta: {
            displayName: 'Contacts manage',
            description: getBlurbAboutManage(EntityTypeSingularDisplay.get(EntityType.CONTACT)),
            permissions: [UserPermission.CONTACT_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.CONTACT}/search`,
        name: 'ContactsSearchView',
        component: () => import(/* webpackChunkName: "Contact" */ '../views/ContactsSearchView/ContactsSearchView.vue'),
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.CONTACT)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.CONTACT)),
            permissions: [UserPermission.CONTACT_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.CONTACT}`,
        redirect: {
            name: 'ContactsSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.CONTACT)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.CONTACT)),
            permissions: [UserPermission.CONTACT_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    }
];
