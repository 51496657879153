import { __decorate, __metadata } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import BaseButton from '~/nasa_ui/base/BaseButton';
/**
 * This component represents a single action button on a mini. It assumes its
 * direct parent is of class `mini_actions_v2`. It accepts these props:
 *
 * - icon: this is the class string for the desired icon. usually you would get
 *   this from $icons on a Vue instance. (prop in BaseButton)
 *
 * - iconSize: this is a string number with option `px` (if absent, `px` will be
 *   appended). Example: `24px`. Default: `28`
 */
let ButtonMiniAction = class ButtonMiniAction extends BaseButton {
    button = null;
    isHovered = false;
    isFocussed = false;
    iconColor;
    iconFlipHorizontal;
    iconFlipVertical;
    iconHoverColor;
    label;
    get computedIconColor() {
        return this.isHovered || this.isFocussed ? this.iconHoverColor || this.iconColor : this.iconColor;
    }
    get computedIconSize() {
        const size = this.iconSize || '22px';
        return size.includes('px') ? size : `${size}px`;
    }
    get isLink() {
        return Boolean(this.href || this.to);
    }
    destroyed() {
        this.button?.removeEventListener('mouseover', this.onHoverOrFocus);
        this.button?.removeEventListener('focus', this.onHoverOrFocus);
        this.button?.removeEventListener('mouseout', this.onMouseoutOrBlur);
        this.button?.removeEventListener('blur', this.onMouseoutOrBlur);
    }
    mounted() {
        const component = this.$refs.iconButton;
        if ('$el' in component) {
            this.button = component.$el;
        }
        else {
            this.button = component;
        }
        this.button?.addEventListener('mouseover', this.onHoverOrFocus);
        this.button?.addEventListener('focus', this.onHoverOrFocus);
        this.button?.addEventListener('mouseout', this.onMouseoutOrBlur);
        this.button?.addEventListener('blur', this.onMouseoutOrBlur);
    }
    onHoverOrFocus(event) {
        if (event.type === 'mouseover') {
            this.isHovered = true;
        }
        if (event.type === 'focus') {
            this.isFocussed = true;
        }
    }
    onMouseoutOrBlur(event) {
        if (event.type === 'mouseout') {
            this.isHovered = false;
        }
        if (event.type === 'blur') {
            this.isFocussed = false;
        }
    }
};
__decorate([
    Prop({
        default: '',
        type: String
    }),
    __metadata("design:type", String)
], ButtonMiniAction.prototype, "color", void 0);
__decorate([
    Prop({
        type: String,
        default: 'white'
    }),
    __metadata("design:type", String)
], ButtonMiniAction.prototype, "iconColor", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], ButtonMiniAction.prototype, "iconFlipHorizontal", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], ButtonMiniAction.prototype, "iconFlipVertical", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], ButtonMiniAction.prototype, "iconHoverColor", void 0);
__decorate([
    Prop({
        type: String,
        default: 'Action Button'
    }),
    __metadata("design:type", String)
], ButtonMiniAction.prototype, "label", void 0);
ButtonMiniAction = __decorate([
    Component
], ButtonMiniAction);
export default ButtonMiniAction;
