import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Side } from '~/db_types';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { DB_DEFAULT } from '~/nasa_ui/constants';
import { EntityType } from '~/nasa_ui/types';
import { getItemInstance } from '~/nasa_ui/utils';
import { transformItemInstanceResponseWithRefs } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
import { addNodeIdToResponseObj } from '~/nasa_ui/vue_plugins/axios';
let MiniItemInstance = class MiniItemInstance extends Mixins(BaseCosmic) {
    isFetching = false;
    selectedEntity = null;
    entity;
    id;
    inlineClear;
    showManageLink;
    showInfoIcon;
    title;
    get computedId() {
        return this.id;
    }
    get computedTitle() {
        return this.title;
    }
    get hasAnyActions() {
        return this.shouldShowManageLink || this.shouldShowInlineClearIcon;
    }
    get isArchived() {
        return this.selectedEntity?.isArchived;
    }
    get manageLink() {
        return this.computedId ? `/hardware/${EntityType.ITEM_INSTANCE}/manage/${this.computedId}` : '';
    }
    get shouldDisplayComputedClassLabel() {
        return this.selectedEntity?.subType;
    }
    get shouldShowInlineClearIcon() {
        return this.inlineClear;
    }
    get shouldShowManageLink() {
        return this.showManageLink && Boolean(this.computedId);
    }
    get selectedEntityNodeId() {
        return this.selectedEntity ? addNodeIdToResponseObj(this.selectedEntity).nodeId : null;
    }
    get transformedSelectedEntity() {
        return this.selectedEntity ? transformItemInstanceResponseWithRefs([this.selectedEntity])[0] : null;
    }
    get verticalLabelDisplayItems() {
        if (!this.selectedEntity || !this.transformedSelectedEntity) {
            return [];
        }
        const items = [];
        if (this.transformedSelectedEntity?.asDesignNumber) {
            items.push({
                name: 'AsDesign',
                value: `${this.transformedSelectedEntity?._asDesignNumber}`
            });
        }
        if (this.transformedSelectedEntity?.serialNumber) {
            items.push({
                name: 'Serial',
                value: this.transformedSelectedEntity._serialNumber
            });
        }
        if (this.transformedSelectedEntity?.lotNumber) {
            items.push({
                name: 'Lot',
                value: this.transformedSelectedEntity._lotNumber
            });
        }
        if (this.transformedSelectedEntity?.side && this.transformedSelectedEntity?.side !== Side.NONE) {
            items.push({
                name: 'Side',
                value: this.transformedSelectedEntity._side
            });
        }
        if (this.transformedSelectedEntity?.size && this.transformedSelectedEntity?.size !== DB_DEFAULT) {
            items.push({
                name: 'Size',
                value: this.transformedSelectedEntity._size
            });
        }
        return items;
    }
    emitInfoClicked() {
        return this.transformedSelectedEntity;
    }
    onClickOfInlineClear() {
        return this.computedId;
    }
    onEntityChange() {
        if (!this.entity) {
            return;
        }
        this.selectedEntity = { ...this.entity };
    }
    async fetchEntity() {
        if (!this.computedId) {
            return;
        }
        try {
            this.isFetching = true;
            const resp = await getItemInstance(this.computedId);
            this.selectedEntity = resp.data;
            this.isFetching = false;
        }
        catch (err) {
            this.$errorUtility({ err });
            this.isFetching = false;
        }
    }
};
__decorate([
    Prop({
        required: false,
        type: Object
    }),
    __metadata("design:type", Object)
], MiniItemInstance.prototype, "entity", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], MiniItemInstance.prototype, "id", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], MiniItemInstance.prototype, "inlineClear", void 0);
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], MiniItemInstance.prototype, "showManageLink", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], MiniItemInstance.prototype, "showInfoIcon", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], MiniItemInstance.prototype, "title", void 0);
__decorate([
    Emit('infoClicked'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MiniItemInstance.prototype, "emitInfoClicked", null);
__decorate([
    Emit('inlineClear'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MiniItemInstance.prototype, "onClickOfInlineClear", null);
__decorate([
    Watch('entity', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MiniItemInstance.prototype, "onEntityChange", null);
__decorate([
    Watch('computedId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], MiniItemInstance.prototype, "fetchEntity", null);
MiniItemInstance = __decorate([
    Component
], MiniItemInstance);
export default MiniItemInstance;
