import { getBlurbAboutCreate, getBlurbAboutManage, getBlurbAboutSearch } from '~/nasa_ui/router/utils';
import { EntityType, EntityTypeSingularDisplay, UserPermission } from '~/nasa_ui/types';
export const ProductBreakdownStructureSharedRoutes = [
    {
        path: `/${EntityType.PRODUCT_BREAKDOWN_STRUCTURE}/create`,
        name: 'ProductBreakdownStructuresCreateView',
        component: () => import(
        /* webpackChunkName: "Pbs" */ '../views/ProductBreakdownStructuresCreateView/ProductBreakdownStructuresCreateView.vue'),
        meta: {
            displayName: 'PBS create',
            description: getBlurbAboutCreate(EntityTypeSingularDisplay.get(EntityType.PRODUCT_BREAKDOWN_STRUCTURE)),
            permissions: [UserPermission.PRODUCT_BREAKDOWN_STRUCTURE_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.PRODUCT_BREAKDOWN_STRUCTURE}/manage`,
        redirect: {
            name: 'ProductBreakdownStructuresSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.PRODUCT_BREAKDOWN_STRUCTURE)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.PRODUCT_BREAKDOWN_STRUCTURE)),
            permissions: [UserPermission.PRODUCT_BREAKDOWN_STRUCTURE_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.PRODUCT_BREAKDOWN_STRUCTURE}/manage/:id`,
        name: 'ProductBreakdownStructuresManageView',
        component: () => import(
        /* webpackChunkName: "Pbs" */ '../views/ProductBreakdownStructuresManageView/ProductBreakdownStructuresManageView.vue'),
        meta: {
            displayName: 'PBS manage',
            description: getBlurbAboutManage(EntityTypeSingularDisplay.get(EntityType.PRODUCT_BREAKDOWN_STRUCTURE)),
            permissions: [UserPermission.PRODUCT_BREAKDOWN_STRUCTURE_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.PRODUCT_BREAKDOWN_STRUCTURE}/search`,
        name: 'ProductBreakdownStructuresSearchView',
        component: () => import(
        /* webpackChunkName: "Pbs" */ '../views/ProductBreakdownStructuresSearchView/ProductBreakdownStructuresSearchView.vue'),
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.PRODUCT_BREAKDOWN_STRUCTURE)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.PRODUCT_BREAKDOWN_STRUCTURE)),
            permissions: [UserPermission.PRODUCT_BREAKDOWN_STRUCTURE_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.PRODUCT_BREAKDOWN_STRUCTURE}`,
        redirect: {
            name: 'ProductBreakdownStructuresSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.PRODUCT_BREAKDOWN_STRUCTURE)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.PRODUCT_BREAKDOWN_STRUCTURE)),
            permissions: [UserPermission.PRODUCT_BREAKDOWN_STRUCTURE_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    }
];
