var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.shouldShowAlert ? _c('div', {
    staticClass: "cosmic_alert elevation-2",
    class: _vm.computedClasses
  }, [_c('div', {
    staticClass: "cosmic_alert_icon_container"
  }, [_vm._t("icon", function () {
    return [_c('c-icon-fa', {
      attrs: {
        "aria-hidden": "true",
        "icon": _vm.computedIcon,
        "icon-color": _vm.computedIconColor,
        "icon-size": "36px"
      }
    })];
  })], 2), _c('div', {
    staticClass: "cosmic_alert_text"
  }, [_vm.computedAlertText ? _c('div', {
    directives: [{
      name: "safe-html",
      rawName: "v-safe-html",
      value: _vm.computedAlertText,
      expression: "computedAlertText"
    }]
  }) : _vm._e(), _vm._t("default")], 2), _vm.dismissible ? _c('div', {
    staticClass: "cosmic_alert_close"
  }, [_c('c-icon-fa', {
    staticClass: "close_icon",
    attrs: {
      "icon": _vm.$icons.close,
      "tooltip": "Dismiss"
    },
    on: {
      "click": _vm.dismissAlert
    }
  })], 1) : _vm._e()]) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };