import { AlertType } from '~/nasa_ui/types';
export const queryFactory = (context, query) => {
    return async (variables, message) => {
        context.isLoading = true;
        try {
            const res = await context.$apollo.query({
                query,
                variables
            });
            if (message?.success) {
                context.$notification.add({ type: AlertType.SUCCESS, text: message.success });
            }
            return res.data;
        }
        catch (err) {
            context.$errorUtility({ err, backupErrorText: message?.error });
        }
        finally {
            context.isLoading = false;
        }
    };
};
export const mutationFactory = (context, mutation) => {
    return async (variables, message) => {
        context.isSaving = true;
        try {
            const res = await context.$apollo.mutate({
                mutation,
                variables
            });
            if (message?.success) {
                context.$notification.add({ type: AlertType.SUCCESS, text: message.success });
            }
            return res.data;
        }
        catch (err) {
            context.$errorUtility({ err, backupErrorText: message?.error });
            throw err;
        }
        finally {
            context.isSaving = false;
        }
    };
};
