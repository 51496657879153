import { __decorate, __metadata } from "tslib";
import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { EntityTypeMode } from '~/nasa_ui/types';
let BaseDisplay = class BaseDisplay extends BaseCosmic {
    entity = null;
    EntityTypeMode = EntityTypeMode;
    FETCH_QUERY;
    nodeId;
    onFetchUpdate(data) {
        console.error('THIS SHOULD BE OVERRIDEAN BY YOUR DISPLAY');
    }
    onNodeIdChange(nodeId) {
        if (!nodeId) {
            return;
        }
        this.executeFetchQuery(this.nodeId);
    }
    async executeFetchQuery(nodeId) {
        try {
            const response = await this.$apollo.query({
                query: this.FETCH_QUERY,
                fetchPolicy: 'network-only',
                variables: {
                    nodeId
                }
            });
            if (response && response.data) {
                this.onFetchUpdate(response.data);
            }
        }
        catch (err) {
            this.$errorUtility({
                err
            });
        }
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], BaseDisplay.prototype, "nodeId", void 0);
__decorate([
    Watch('nodeId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], BaseDisplay.prototype, "onNodeIdChange", null);
BaseDisplay = __decorate([
    Component
], BaseDisplay);
export default BaseDisplay;
