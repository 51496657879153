var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": "primary",
      "modal-width": "50vw",
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.closeDialog
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('ButtonCancel', {
          on: {
            "click": _vm.closeDialog
          },
          nativeOn: {
            "keyup": function keyup($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
              return _vm.closeDialog.apply(null, arguments);
            }
          }
        }, [_vm._v(" Close "), _c('small', {
          staticClass: "ml-2"
        }, [_vm._v("(esc)")])])];
      },
      proxy: true
    }])
  }, [_c('template', {
    slot: "content"
  }, [_c('CHANGELOG')], 1)], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };