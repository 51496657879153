export const GENERAL_ENTITY_COLOR = 'grey darken-1';
export const RISK_COLOR_HIGH = '#b71c1c';
export const RISK_COLOR_MEDIUM = '#fec821';
export const RISK_COLOR_LOW = '#008000';
/* cosmic colors */
export const COSMIC_COLORS = {
    '--cosmic-text': 'rgba(0, 0, 0, 0.87)',
    '--cosmic-red-base': '#f44336',
    '--cosmic-red-lighten-5': '#ffebee',
    '--cosmic-red-lighten-4': '#ffcdd2',
    '--cosmic-red-lighten-3': '#ef9a9a',
    '--cosmic-red-lighten-2': '#e57373',
    '--cosmic-red-lighten-1': '#ef5350',
    '--cosmic-red-darken-1': '#e53935',
    '--cosmic-red-darken-2': '#d32f2f',
    '--cosmic-red-darken-3': '#c62828',
    '--cosmic-red-darken-4': '#b71c1c',
    '--cosmic-red-accent-1': '#ff8a80',
    '--cosmic-red-accent-2': '#ff5252',
    '--cosmic-red-accent-3': '#ff1744',
    '--cosmic-red-accent-4': '#d50000',
    '--cosmic-pink-base': '#e91e63',
    '--cosmic-pink-lighten-5': '#fce4ec',
    '--cosmic-pink-lighten-4': '#f8bbd0',
    '--cosmic-pink-lighten-3': '#f48fb1',
    '--cosmic-pink-lighten-2': '#f06292',
    '--cosmic-pink-lighten-1': '#ec407a',
    '--cosmic-pink-darken-1': '#d81b60',
    '--cosmic-pink-darken-2': '#c2185b',
    '--cosmic-pink-darken-3': '#ad1457',
    '--cosmic-pink-darken-4': '#880e4f',
    '--cosmic-pink-accent-1': '#ff80ab',
    '--cosmic-pink-accent-2': '#ff4081',
    '--cosmic-pink-accent-3': '#f50057',
    '--cosmic-pink-accent-4': '#c51162',
    '--cosmic-purple-base': '#9c27b0',
    '--cosmic-purple-lighten-5': '#f3e5f5',
    '--cosmic-purple-lighten-4': '#e1bee7',
    '--cosmic-purple-lighten-3': '#ce93d8',
    '--cosmic-purple-lighten-2': '#ba68c8',
    '--cosmic-purple-lighten-1': '#ab47bc',
    '--cosmic-purple-darken-1': '#8e24aa',
    '--cosmic-purple-darken-2': '#7b1fa2',
    '--cosmic-purple-darken-3': '#6a1b9a',
    '--cosmic-purple-darken-4': '#4a148c',
    '--cosmic-purple-accent-1': '#ea80fc',
    '--cosmic-purple-accent-2': '#e040fb',
    '--cosmic-purple-accent-3': '#d500f9',
    '--cosmic-purple-accent-4': '#aa00ff',
    '--cosmic-deep-purple-base': '#673ab7',
    '--cosmic-deep-purple-lighten-5': '#ede7f6',
    '--cosmic-deep-purple-lighten-4': '#d1c4e9',
    '--cosmic-deep-purple-lighten-3': '#b39ddb',
    '--cosmic-deep-purple-lighten-2': '#9575cd',
    '--cosmic-deep-purple-lighten-1': '#7e57c2',
    '--cosmic-deep-purple-darken-1': '#5e35b1',
    '--cosmic-deep-purple-darken-2': '#512da8',
    '--cosmic-deep-purple-darken-3': '#4527a0',
    '--cosmic-deep-purple-darken-4': '#311b92',
    '--cosmic-deep-purple-accent-1': '#b388ff',
    '--cosmic-deep-purple-accent-2': '#7c4dff',
    '--cosmic-deep-purple-accent-3': '#651fff',
    '--cosmic-deep-purple-accent-4': '#6200ea',
    '--cosmic-indigo-base': '#3f51b5',
    '--cosmic-indigo-lighten-5': '#e8eaf6',
    '--cosmic-indigo-lighten-4': '#c5cae9',
    '--cosmic-indigo-lighten-3': '#9fa8da',
    '--cosmic-indigo-lighten-2': '#7986cb',
    '--cosmic-indigo-lighten-1': '#5c6bc0',
    '--cosmic-indigo-darken-1': '#3949ab',
    '--cosmic-indigo-darken-2': '#303f9f',
    '--cosmic-indigo-darken-3': '#283593',
    '--cosmic-indigo-darken-4': '#1a237e',
    '--cosmic-indigo-accent-1': '#8c9eff',
    '--cosmic-indigo-accent-2': '#536dfe',
    '--cosmic-indigo-accent-3': '#3d5afe',
    '--cosmic-indigo-accent-4': '#304ffe',
    '--cosmic-blue-base': '#2196f3',
    '--cosmic-blue-lighten-5': '#e3f2fd',
    '--cosmic-blue-lighten-4': '#bbdefb',
    '--cosmic-blue-lighten-3': '#90caf9',
    '--cosmic-blue-lighten-2': '#64b5f6',
    '--cosmic-blue-lighten-1': '#42a5f5',
    '--cosmic-blue-darken-1': '#1e88e5',
    '--cosmic-blue-darken-2': '#1976d2',
    '--cosmic-blue-darken-3': '#1565c0',
    '--cosmic-blue-darken-4': '#0d47a1',
    '--cosmic-blue-accent-1': '#82b1ff',
    '--cosmic-blue-accent-2': '#448aff',
    '--cosmic-blue-accent-3': '#2979ff',
    '--cosmic-blue-accent-4': '#2962ff',
    '--cosmic-light-blue-base': '#03a9f4',
    '--cosmic-light-blue-lighten-5': '#e1f5fe',
    '--cosmic-light-blue-lighten-4': '#b3e5fc',
    '--cosmic-light-blue-lighten-3': '#81d4fa',
    '--cosmic-light-blue-lighten-2': '#4fc3f7',
    '--cosmic-light-blue-lighten-1': '#29b6f6',
    '--cosmic-light-blue-darken-1': '#039be5',
    '--cosmic-light-blue-darken-2': '#0288d1',
    '--cosmic-light-blue-darken-3': '#0277bd',
    '--cosmic-light-blue-darken-4': '#01579b',
    '--cosmic-light-blue-accent-1': '#80d8ff',
    '--cosmic-light-blue-accent-2': '#40c4ff',
    '--cosmic-light-blue-accent-3': '#00b0ff',
    '--cosmic-light-blue-accent-4': '#0091ea',
    '--cosmic-cyan-base': '#00bcd4',
    '--cosmic-cyan-lighten-5': '#e0f7fa',
    '--cosmic-cyan-lighten-4': '#b2ebf2',
    '--cosmic-cyan-lighten-3': '#80deea',
    '--cosmic-cyan-lighten-2': '#4dd0e1',
    '--cosmic-cyan-lighten-1': '#26c6da',
    '--cosmic-cyan-darken-1': '#00acc1',
    '--cosmic-cyan-darken-2': '#0097a7',
    '--cosmic-cyan-darken-3': '#00838f',
    '--cosmic-cyan-darken-4': '#006064',
    '--cosmic-cyan-accent-1': '#84ffff',
    '--cosmic-cyan-accent-2': '#18ffff',
    '--cosmic-cyan-accent-3': '#00e5ff',
    '--cosmic-cyan-accent-4': '#00b8d4',
    '--cosmic-teal-base': '#009688',
    '--cosmic-teal-lighten-5': '#e0f2f1',
    '--cosmic-teal-lighten-4': '#b2dfdb',
    '--cosmic-teal-lighten-3': '#80cbc4',
    '--cosmic-teal-lighten-2': '#4db6ac',
    '--cosmic-teal-lighten-1': '#26a69a',
    '--cosmic-teal-darken-1': '#00897b',
    '--cosmic-teal-darken-2': '#00796b',
    '--cosmic-teal-darken-3': '#00695c',
    '--cosmic-teal-darken-4': '#004d40',
    '--cosmic-teal-accent-1': '#a7ffeb',
    '--cosmic-teal-accent-2': '#64ffda',
    '--cosmic-teal-accent-3': '#1de9b6',
    '--cosmic-teal-accent-4': '#00bfa5',
    '--cosmic-green-base': '#4caf50',
    '--cosmic-green-lighten-5': '#e8f5e9',
    '--cosmic-green-lighten-4': '#c8e6c9',
    '--cosmic-green-lighten-3': '#a5d6a7',
    '--cosmic-green-lighten-2': '#81c784',
    '--cosmic-green-lighten-1': '#66bb6a',
    '--cosmic-green-darken-1': '#43a047',
    '--cosmic-green-darken-2': '#388e3c',
    '--cosmic-green-darken-3': '#2e7d32',
    '--cosmic-green-darken-4': '#1b5e20',
    '--cosmic-green-accent-1': '#b9f6ca',
    '--cosmic-green-accent-2': '#69f0ae',
    '--cosmic-green-accent-3': '#00e676',
    '--cosmic-green-accent-4': '#00c853',
    '--cosmic-light-green-base': '#8bc34a',
    '--cosmic-light-green-lighten-5': '#f1f8e9',
    '--cosmic-light-green-lighten-4': '#dcedc8',
    '--cosmic-light-green-lighten-3': '#c5e1a5',
    '--cosmic-light-green-lighten-2': '#aed581',
    '--cosmic-light-green-lighten-1': '#9ccc65',
    '--cosmic-light-green-darken-1': '#7cb342',
    '--cosmic-light-green-darken-2': '#689f38',
    '--cosmic-light-green-darken-3': '#558b2f',
    '--cosmic-light-green-darken-4': '#33691e',
    '--cosmic-light-green-accent-1': '#ccff90',
    '--cosmic-light-green-accent-2': '#b2ff59',
    '--cosmic-light-green-accent-3': '#76ff03',
    '--cosmic-light-green-accent-4': '#64dd17',
    '--cosmic-lime-base': '#cddc39',
    '--cosmic-lime-lighten-5': '#f9fbe7',
    '--cosmic-lime-lighten-4': '#f0f4c3',
    '--cosmic-lime-lighten-3': '#e6ee9c',
    '--cosmic-lime-lighten-2': '#dce775',
    '--cosmic-lime-lighten-1': '#d4e157',
    '--cosmic-lime-darken-1': '#c0ca33',
    '--cosmic-lime-darken-2': '#afb42b',
    '--cosmic-lime-darken-3': '#9e9d24',
    '--cosmic-lime-darken-4': '#827717',
    '--cosmic-lime-accent-1': '#f4ff81',
    '--cosmic-lime-accent-2': '#eeff41',
    '--cosmic-lime-accent-3': '#c6ff00',
    '--cosmic-lime-accent-4': '#aeea00',
    '--cosmic-yellow-base': '#ffeb3b',
    '--cosmic-yellow-lighten-5': '#fffde7',
    '--cosmic-yellow-lighten-4': '#fff9c4',
    '--cosmic-yellow-lighten-3': '#fff59d',
    '--cosmic-yellow-lighten-2': '#fff176',
    '--cosmic-yellow-lighten-1': '#ffee58',
    '--cosmic-yellow-darken-1': '#fdd835',
    '--cosmic-yellow-darken-2': '#fbc02d',
    '--cosmic-yellow-darken-3': '#f9a825',
    '--cosmic-yellow-darken-4': '#f57f17',
    '--cosmic-yellow-accent-1': '#ffff8d',
    '--cosmic-yellow-accent-2': '#ffff00',
    '--cosmic-yellow-accent-3': '#ffea00',
    '--cosmic-yellow-accent-4': '#ffd600',
    '--cosmic-amber-base': '#ffc107',
    '--cosmic-amber-lighten-5': '#fff8e1',
    '--cosmic-amber-lighten-4': '#ffecb3',
    '--cosmic-amber-lighten-3': '#ffe082',
    '--cosmic-amber-lighten-2': '#ffd54f',
    '--cosmic-amber-lighten-1': '#ffca28',
    '--cosmic-amber-darken-1': '#ffb300',
    '--cosmic-amber-darken-2': '#ffa000',
    '--cosmic-amber-darken-3': '#ff8f00',
    '--cosmic-amber-darken-4': '#ff6f00',
    '--cosmic-amber-accent-1': '#ffe57f',
    '--cosmic-amber-accent-2': '#ffd740',
    '--cosmic-amber-accent-3': '#ffc400',
    '--cosmic-amber-accent-4': '#ffab00',
    '--cosmic-orange-base': '#ff9800',
    '--cosmic-orange-lighten-5': '#fff3e0',
    '--cosmic-orange-lighten-4': '#ffe0b2',
    '--cosmic-orange-lighten-3': '#ffcc80',
    '--cosmic-orange-lighten-2': '#ffb74d',
    '--cosmic-orange-lighten-1': '#ffa726',
    '--cosmic-orange-darken-1': '#fb8c00',
    '--cosmic-orange-darken-2': '#f57c00',
    '--cosmic-orange-darken-3': '#ef6c00',
    '--cosmic-orange-darken-4': '#e65100',
    '--cosmic-orange-accent-1': '#ffd180',
    '--cosmic-orange-accent-2': '#ffab40',
    '--cosmic-orange-accent-3': '#ff9100',
    '--cosmic-orange-accent-4': '#ff6d00',
    '--cosmic-deep-orange-base': '#ff5722',
    '--cosmic-deep-orange-lighten-5': '#fbe9e7',
    '--cosmic-deep-orange-lighten-4': '#ffccbc',
    '--cosmic-deep-orange-lighten-3': '#ffab91',
    '--cosmic-deep-orange-lighten-2': '#ff8a65',
    '--cosmic-deep-orange-lighten-1': '#ff7043',
    '--cosmic-deep-orange-darken-1': '#f4511e',
    '--cosmic-deep-orange-darken-2': '#e64a19',
    '--cosmic-deep-orange-darken-3': '#d84315',
    '--cosmic-deep-orange-darken-4': '#bf360c',
    '--cosmic-deep-orange-accent-1': '#ff9e80',
    '--cosmic-deep-orange-accent-2': '#ff6e40',
    '--cosmic-deep-orange-accent-3': '#ff3d00',
    '--cosmic-deep-orange-accent-4': '#dd2c00',
    '--cosmic-brown-base': '#795548',
    '--cosmic-brown-lighten-5': '#efebe9',
    '--cosmic-brown-lighten-4': '#d7ccc8',
    '--cosmic-brown-lighten-3': '#bcaaa4',
    '--cosmic-brown-lighten-2': '#a1887f',
    '--cosmic-brown-lighten-1': '#8d6e63',
    '--cosmic-brown-darken-1': '#6d4c41',
    '--cosmic-brown-darken-2': '#5d4037',
    '--cosmic-brown-darken-3': '#4e342e',
    '--cosmic-brown-darken-4': '#3e2723',
    '--cosmic-blue-grey-base': '#607d8b',
    '--cosmic-blue-grey-lighten-5': '#eceff1',
    '--cosmic-blue-grey-lighten-4': '#cfd8dc',
    '--cosmic-blue-grey-lighten-3': '#b0bec5',
    '--cosmic-blue-grey-lighten-2': '#90a4ae',
    '--cosmic-blue-grey-lighten-1': '#78909c',
    '--cosmic-blue-grey-darken-1': '#546e7a',
    '--cosmic-blue-grey-darken-2': '#455a64',
    '--cosmic-blue-grey-darken-3': '#37474f',
    '--cosmic-blue-grey-darken-4': '#263238',
    '--cosmic-grey-base': '#9e9e9e',
    '--cosmic-grey-lighten-5': '#fafafa',
    '--cosmic-grey-lighten-4': '#f5f5f5',
    '--cosmic-grey-lighten-3': '#eeeeee',
    '--cosmic-grey-lighten-2': '#e0e0e0',
    '--cosmic-grey-lighten-1': '#bdbdbd',
    '--cosmic-grey-darken-1': '#757575',
    '--cosmic-grey-darken-2': '#616161',
    '--cosmic-grey-darken-3': '#424242',
    '--cosmic-grey-darken-4': '#212121',
    '--cosmic-blue-gray-base': '#607d8b',
    '--cosmic-blue-gray-lighten-5': '#eceff1',
    '--cosmic-blue-gray-lighten-4': '#cfd8dc',
    '--cosmic-blue-gray-lighten-3': '#b0bec5',
    '--cosmic-blue-gray-lighten-2': '#90a4ae',
    '--cosmic-blue-gray-lighten-1': '#78909c',
    '--cosmic-blue-gray-darken-1': '#546e7a',
    '--cosmic-blue-gray-darken-2': '#455a64',
    '--cosmic-blue-gray-darken-3': '#37474f',
    '--cosmic-blue-gray-darken-4': '#263238',
    '--cosmic-gray-base': '#9e9e9e',
    '--cosmic-gray-lighten-5': '#fafafa',
    '--cosmic-gray-lighten-4': '#f5f5f5',
    '--cosmic-gray-lighten-3': '#eeeeee',
    '--cosmic-gray-lighten-2': '#e0e0e0',
    '--cosmic-gray-lighten-1': '#bdbdbd',
    '--cosmic-gray-darken-1': '#757575',
    '--cosmic-gray-darken-2': '#616161',
    '--cosmic-gray-darken-3': '#424242',
    '--cosmic-gray-darken-4': '#212121'
};
