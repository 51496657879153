import { AssemblyTemplateType } from '~/db_types/swagger_types';
import { EntityType } from '~/nasa_ui/types';
import { DEFAULT_DASH } from '.';
import { asBuiltNumberDisplay, numberDisplay, serialNumberDisplay, stringListDisplay } from '../utils/helpers/displays';
import { buildIconAsString } from '../utils/ui/buildIconAsString';
import { CosmicIcons } from '../utils/ui/CosmicIcons';
import { NOT_APPLICABLE } from './display';
export const createTableItemFromHardwareItem = (hardwareItem) => {
    return {
        _addendumDate: hardwareItem._addendumDate,
        _addendumNumber: hardwareItem._addendumNumber,
        _asBuiltNumber: hardwareItem._asBuiltNumber,
        _assemblyTemplateId: hardwareItem._assemblyTemplateId,
        _assemblyTemplate: hardwareItem._assemblyTemplate,
        _description: hardwareItem._description,
        _itemNumber: hardwareItem._itemNumber,
        _lotNumber: hardwareItem._lotNumber,
        _name: hardwareItem?._assemblyTemplate?.name || NOT_APPLICABLE,
        _notes: hardwareItem._notes,
        _quantity: numberDisplay(hardwareItem.quantity),
        _sequence: hardwareItem._sequence,
        _serialNumber: hardwareItem._serialNumber,
        hardware: hardwareItem,
        nodeId: hardwareItem.nodeId,
        sequence: hardwareItem._sequence,
        templateItem: null
    };
};
export const addIconsAndClasses = (item) => {
    if (item?._assemblyTemplate?.subType === AssemblyTemplateType.EVENT_ASSEMBLY) {
        item.rowClass = 'event_bill';
        item._entityIcon = buildIconAsString({ iconClass: CosmicIcons['EVENT_ASSEMBLY'], title: 'Event Assembly' });
        if (item?.templateItem) {
            item.rowClass = 'template_slot_filled';
        }
    }
    else if (item.templateItem && item.hardware) {
        item.rowClass = 'template_slot_filled';
        item._entityIcon = buildIconAsString({
            iconClass: CosmicIcons[EntityType.HARDWARE_LIST],
            color: EntityType.HARDWARE_LIST,
            title: 'Hardware List Template slot filled.'
        });
    }
    else if (item.templateItem && !item.hardware) {
        item.rowClass = 'template_slot';
        item._entityIcon = buildIconAsString({
            color: 'text--darken-4 warning--text',
            iconClass: CosmicIcons[EntityType.HARDWARE_LIST_TEMPLATE],
            title: 'Hardware List Template slot not yet filled in.'
        });
    }
    else if (item.hardware) {
        item._entityIcon = buildIconAsString({
            color: EntityType.INVENTORY,
            iconClass: CosmicIcons[EntityType.INVENTORY],
            title: 'Inventory selected.'
        });
    }
    return item;
};
export const handleEventAssemblyItems = (item) => {
    if (item?._assemblyTemplate?.subType !== AssemblyTemplateType.EVENT_ASSEMBLY) {
        return item;
    }
    const assemblyTemplateParts = item?._assemblyTemplate?.assemblyTemplateParts?.nodes;
    if (assemblyTemplateParts?.length) {
        const _atp = assemblyTemplateParts[0];
        item._addendumDate = NOT_APPLICABLE;
        item._addendumNumber = NOT_APPLICABLE;
        item._asBuiltNumber = asBuiltNumberDisplay(_atp?.asBuiltNumber);
        item._description = _atp.itemDrawing?.description ?? DEFAULT_DASH;
        item._itemNumber = _atp?.drawingNumber ?? DEFAULT_DASH;
        item._lotNumber = NOT_APPLICABLE;
        item._serialNumber = serialNumberDisplay(_atp?.serialNumber);
    }
    return item;
};
export const createTableItemFromTemplateItem = (templateItem) => {
    const descriptions = templateItem.parts.nodes.map((p) => p?.itemDrawing?.description).filter((_) => _);
    const drawingNumbers = templateItem.parts.nodes.map((p) => p?.drawingNumber).filter((_) => _);
    return {
        _addendumDate: DEFAULT_DASH,
        _addendumNumber: DEFAULT_DASH,
        _asBuiltNumber: DEFAULT_DASH,
        _assemblyTemplateId: null,
        _assemblyTemplate: null,
        _description: stringListDisplay(descriptions),
        _itemNumber: stringListDisplay(drawingNumbers),
        _lotNumber: DEFAULT_DASH,
        _notes: DEFAULT_DASH,
        _name: templateItem.name || NOT_APPLICABLE,
        _quantity: `<span>${numberDisplay(templateItem.minQuantity)} / ${numberDisplay(templateItem.maxQuantity)}`,
        _sequence: templateItem.sequence?.toString() ?? DEFAULT_DASH,
        _serialNumber: DEFAULT_DASH,
        hardware: null,
        rowClass: 'template_slot',
        sequence: templateItem.sequence?.toString() ?? DEFAULT_DASH,
        templateItem: templateItem
    };
};
