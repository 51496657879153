import gql from 'graphql-tag';
export const CosmicContactSlimFragment = gql `
  fragment CosmicContactSlim on Contact {
    auid
    createdDateTime
    email
    firstName
    id
    lastName
    middleName
    nodeId
    updatedDateTime
    userId
    uupic
  }
`;
