export var Constraint;
(function (Constraint) {
    Constraint["CATEGORY_I"] = "CATEGORY_I";
    Constraint["CATEGORY_II"] = "CATEGORY_II";
    Constraint["CATEGORY_III"] = "CATEGORY_III";
    Constraint["CATEGORY_OTT"] = "CATEGORY_OTT";
    Constraint["NO_CONSTRAINT"] = "NO_CONSTRAINT";
})(Constraint || (Constraint = {}));
export const ConstraintDisplay = new Map([
    [Constraint.CATEGORY_I, 'Category I'],
    [Constraint.CATEGORY_II, 'Category II'],
    [Constraint.CATEGORY_III, 'Category III'],
    [Constraint.CATEGORY_OTT, 'Category OTT'],
    [Constraint.NO_CONSTRAINT, 'No Constraint']
]);
