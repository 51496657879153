import { __decorate, __metadata } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import BaseButton from '~/nasa_ui/base/BaseButton';
let ButtonRemove = class ButtonRemove extends BaseButton {
    isRemoving;
    get computedColor() {
        return this.color || '--v-error-base';
    }
    get computedIcon() {
        return this.icon || this.$icons.trash;
    }
    get computedTextColor() {
        return this.textColor || '#fff';
    }
};
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], ButtonRemove.prototype, "isRemoving", void 0);
ButtonRemove = __decorate([
    Component
], ButtonRemove);
export default ButtonRemove;
