var _a, _b, _c, _d, _e, _f, _g;
import { __decorate, __metadata } from "tslib";
import { escape } from 'lodash';
import { DebounceAll } from 'lodash-decorators';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import BaseVue from '~/nasa_ui/base/BaseVue';
import { HeaderSortOptions, IAppTableUserDefinedPagination, Maybe, TableTypes } from '~/nasa_ui/types';
import { sortObjectBy } from '../utils';
export const CURRENCY_HEADERS = ['_unitCost', '_totalCost'];
let BaseTable = class BaseTable extends BaseVue {
    isLoading = false;
    // initializing as null to prevent the data table from automatically setting
    //  hasSearch to false, which currently breaks query pagination.
    searchFilter = null;
    showTable = true;
    tableType = TableTypes.TABLE;
    pagination = {
        descending: false,
        page: 1,
        sortBy: ''
    };
    //#region Computed
    get computedClass() {
        return `${this.denseRows ? 'dense_rows' : ''} ${this.sticky ? 'sticky' : ''}`.trim();
    }
    get computedColor() {
        return this.color || 'grey lighten-2';
    }
    get computedIcon() {
        return this.hideIcon ? '' : this.icon || this.$icons[this.color] || '';
    }
    get computedIconColor() {
        return this.iconColor ? this.iconColor : this.dark ? 'white' : 'black';
    }
    get computedItems() {
        return this.items;
    }
    get computedSearchFilter() {
        return this.search || this.searchFilter;
    }
    get computedShowSearchInput() {
        return this.showSearchInput;
    }
    get computedTableHeaders() {
        const headers = (this.headers || []).map((h) => typeof h !== 'string'
            ? h
            : {
                text: h,
                value: h,
                sortable: true
            });
        if (this.isAppTableExpandable) {
            headers.unshift({
                align: 'center',
                sortable: false,
                text: '',
                value: '_expandIcon',
                width: '50px' // does nothing really
            });
        }
        return headers.map((header) => {
            const isValueCurrencyDisplay = header.value.startsWith('_') && (header.value.endsWith('Currency') || header.value.endsWith('Cost'));
            const isValueDateDisplay = header.value.startsWith('_') && header.value.endsWith('Date');
            const isValueDateTimeDisplay = header.value.startsWith('_') && header.value.endsWith('DateTime');
            const isValueTimeDisplay = header.value.startsWith('_') && header.value.endsWith('Time') && !header.value.endsWith('DateTime');
            const isValueIconDisplay = header.value.startsWith('_') && header.value.endsWith('Icon');
            const isValueNumberDisplay = header.headerSortType === HeaderSortOptions.NUMBER || header.text.toLowerCase().includes('number');
            if (isValueCurrencyDisplay) {
                const defaultOptions = {
                    align: 'right',
                    noWrap: true,
                    headerSortType: HeaderSortOptions.CURRENCY
                };
                return Object.assign({}, defaultOptions, header);
            }
            if (isValueDateDisplay || isValueDateTimeDisplay || header.headerSortType === HeaderSortOptions.DATE) {
                const defaultOptions = {
                    align: 'left',
                    headerSortType: HeaderSortOptions.DATE,
                    noEscape: true,
                    noWrap: true
                };
                return Object.assign({}, defaultOptions, header);
            }
            if (isValueIconDisplay) {
                const defaultOptions = {
                    align: 'center',
                    noEscape: true
                };
                // Allow the user to override our defaults if needed
                return Object.assign({}, defaultOptions, header);
            }
            // if its time right align
            if (isValueTimeDisplay) {
                const defaultOptions = {
                    align: 'right',
                    headerSortType: HeaderSortOptions.TEXT,
                    monospacedFont: true
                };
                // Allow the user to override our defaults if needed
                return Object.assign({}, defaultOptions, header);
            }
            // Make sure if its a number to right align it, monospace it and tell the sorter its a number
            // things like item number/drawing number are overriding this to align left
            if (isValueNumberDisplay) {
                const defaultOptions = {
                    align: 'right',
                    headerSortType: HeaderSortOptions.NUMBER,
                    monospacedFont: true
                };
                // Allow the user to override our defaults if needed
                return Object.assign({}, defaultOptions, header);
            }
            return header;
        });
    }
    get computedTitle() {
        return this.title;
    }
    get dense() {
        return !this.hasSlot('title') && !this.showSearchInput;
    }
    get hasTitleSlot() {
        return this.hasSlot('title');
    }
    get hasIconSlot() {
        return this.hasSlot('icon');
    }
    get isAppTableExpandable() {
        return this.tableType === TableTypes.TABLE_EXPANDABLE;
    }
    get shouldDisplayHeader() {
        return this.showSearchInput || this.showHeader || this.computedTitle || this.hasTitleSlot;
    }
    get shouldHidePagination() {
        if (this.hidePagination) {
            return this.hidePagination;
        }
        if (this.totalItems) {
            return this.totalItems < (this.pagination?.rowsPerPage || 10) + 1;
        }
        return !this.computedItems.length;
    }
    get shouldShowTable() {
        return this.showTable;
    }
    //#endregion
    //#region Props
    color;
    customPagination;
    dark;
    disableInitialSort;
    denseRows;
    expandableRows;
    headers;
    hideIcon;
    icon;
    iconColor;
    items;
    itemKey;
    hidePagination;
    loading;
    rowsPerPageItems;
    search;
    selectableRows;
    showCount;
    showSearchInput;
    showHeader;
    sticky;
    title;
    totalItems;
    userDefinedPagination;
    value;
    //#endregion
    clearSearchFilter() {
        this.emitSearchFilterInput('');
    }
    changeSort(header) {
        // abort if `sortable` is explicitly set to false
        if (header.sortable === false) {
            return;
        }
        const column = header.value;
        if (this.pagination.sortBy === column) {
            this.pagination.descending = !this.pagination.descending;
        }
        else {
            this.pagination.sortBy = column;
            this.pagination.descending = false;
        }
    }
    isBlockOfTextHeader(header) {
        return header.headerSortType === HeaderSortOptions.BLOCK_OF_TEXT;
    }
    getTableData(header, item) {
        const headerIsString = typeof header === 'string';
        const headerValue = headerIsString ? header : header.value;
        const rowColData = this.$get(item, headerValue);
        const rowColDataIsNumber = typeof rowColData === 'number';
        const rowColDataIsValidUrl = this.isValidUrl(rowColData);
        const rowColDataIsValidEmailAddy = this.isValidEmailAddress(rowColData);
        if (rowColDataIsNumber) {
            return rowColData;
        }
        if (rowColDataIsValidUrl) {
            return `<a href="${rowColData}" target="_blank" rel="noopener">${rowColData}</a>`;
        }
        if (rowColDataIsValidEmailAddy) {
            return `<a href="mailto:${rowColData}" target="_blank" rel="noopener">${rowColData}</a>`;
        }
        // Assumed to be string so escape it
        if (headerIsString) {
            return escape(rowColData);
        }
        return header.noEscape === true ? rowColData : escape(rowColData);
    }
    onDblClickOfRow(val, event = null) {
        this.$emit('dblclick', val);
    }
    onDoubleClickOfTableToolbar() {
        this.showTable = !this.showTable;
    }
    onClickOfRow(val, event = null) {
        if (!event?.metaKey && !event?.ctrlKey) {
            this.$emit('rowClicked', val);
            this.$emit('click', val);
            this.$emit('input', val);
            return val;
        }
        this.$emit('metaRowClicked', val);
        return val;
    }
    emitFilteredItems() {
        if (!this.searchFilter && !this.pagination) {
            return [...this.items];
        }
        if (!this.searchFilter && this.pagination && this.pagination.sortBy) {
            const sorted = [...this.items];
            sorted.sort(sortObjectBy(this.pagination.sortBy, this.pagination.descending));
            return sorted;
        }
        if (this.searchFilter && this.pagination && this.pagination.sortBy) {
            const _items = [...this.items].filter((item) => {
                return (item !== null &&
                    typeof item !== 'boolean' &&
                    JSON.stringify(item).toLowerCase().indexOf(this.searchFilter) !== -1);
            });
            _items.sort(sortObjectBy(this.pagination.sortBy, this.pagination.descending));
            return _items;
        }
        if (this.searchFilter && !this.pagination) {
            const _items = [...this.items].filter((item) => {
                return (item !== null &&
                    typeof item !== 'boolean' &&
                    JSON.stringify(item).toLowerCase().indexOf(this.searchFilter) !== -1);
            });
            return _items;
        }
    }
    emitSearchFilterInput(val) {
        this.searchFilter = val;
    }
    /**
     * Monitor the internal `isLoading` value. Usually this value is set via the
     * `loading` prop, but when using query pagination `BaseTable` will manually
     * toggle `isLoading`. When it does, we vent it out so that the consumer can
     * use it if needed.
     */
    onLoadingInternalChange() { }
    onLoadingPropChange() {
        this.isLoading = this.loading;
    }
    onUserDefinedPaginationPropChange() {
        this.pagination = Object.assign({}, this.pagination, this.userDefinedPagination);
        if ((this.pagination.sortBy === '' || !this.pagination.sortBy) && !this.disableInitialSort) {
            this.pagination.sortBy = this.computedTableHeaders[0]?.value;
        }
    }
    resetPagination(items) {
        // if this prop has a value, it means we're controlling pagination externally
        if (this.totalItems) {
            return;
        }
        // reset the pagination to page 1
        // https://gitlab.com/mri-technologies/cosmic/nasa/nasa-ui/-/merge_requests/32
        this.pagination = Object.assign({}, this.pagination, this.userDefinedPagination);
        // keep sorting for new results
        if ((this.pagination.sortBy === '' || !this.pagination.sortBy) && !this.disableInitialSort) {
            this.pagination.sortBy = this.computedTableHeaders[0]?.value;
        }
    }
    getAlignClass(header) {
        return header && header.align ? `text-xs-${header.align}` : 'text-xs-left'; // vuetify default
    }
    addMonospaceIfNeeded(header) {
        if (header.monospacedFont) {
            return 'monospace_font';
        }
        // This is currently every HeaderSortOptions but in case we add more
        if (header?.headerSortType === HeaderSortOptions.CURRENCY ||
            header?.headerSortType === HeaderSortOptions.DATE ||
            header?.headerSortType === HeaderSortOptions.DATETIME ||
            header?.headerSortType === HeaderSortOptions.NUMBER) {
            return 'monospace_font';
        }
        return '';
    }
    hasSlot(name = 'default') {
        return !!this.$slots[name] || !!this.$scopedSlots[name];
    }
    isValidUrl(url) {
        const matcher = /^(?:\w+:)?\/\/([^\s.]+\.\S{2}[:?\d]*)\S*$/;
        return matcher.test(url);
    }
    isValidEmailAddress(url) {
        // https://stackoverflow.com/a/46181/4144
        const matcher = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return matcher.test(url);
    }
    onChangeOfSelectableRows() {
        console.warn('The selectableRows prop has been deprecated, please use an actions column instead.');
    }
    onPaginationChange(pagination) { }
    syncPagination(pagination) {
        this.pagination = pagination;
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], BaseTable.prototype, "color", void 0);
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof IAppTableUserDefinedPagination !== "undefined" && IAppTableUserDefinedPagination) === "function" ? _a : Object)
], BaseTable.prototype, "customPagination", void 0);
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], BaseTable.prototype, "dark", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], BaseTable.prototype, "disableInitialSort", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], BaseTable.prototype, "denseRows", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], BaseTable.prototype, "expandableRows", void 0);
__decorate([
    Prop({
        default: () => []
    }),
    __metadata("design:type", typeof (_b = typeof Array !== "undefined" && Array) === "function" ? _b : Object)
], BaseTable.prototype, "headers", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], BaseTable.prototype, "hideIcon", void 0);
__decorate([
    Prop(),
    __metadata("design:type", String)
], BaseTable.prototype, "icon", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], BaseTable.prototype, "iconColor", void 0);
__decorate([
    Prop({
        default: () => []
    }),
    __metadata("design:type", Array)
], BaseTable.prototype, "items", void 0);
__decorate([
    Prop({
        type: String,
        default: 'nodeId'
    }),
    __metadata("design:type", String)
], BaseTable.prototype, "itemKey", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], BaseTable.prototype, "hidePagination", void 0);
__decorate([
    Prop({
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], BaseTable.prototype, "loading", void 0);
__decorate([
    Prop({
        default: () => [
            50,
            100,
            250,
            {
                text: '$vuetify.dataIterator.rowsPerPageAll',
                value: -1
            }
        ]
    }),
    __metadata("design:type", typeof (_c = typeof Array !== "undefined" && Array) === "function" ? _c : Object)
], BaseTable.prototype, "rowsPerPageItems", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_d = typeof Maybe !== "undefined" && Maybe) === "function" ? _d : Object)
], BaseTable.prototype, "search", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], BaseTable.prototype, "selectableRows", void 0);
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], BaseTable.prototype, "showCount", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], BaseTable.prototype, "showSearchInput", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], BaseTable.prototype, "showHeader", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], BaseTable.prototype, "sticky", void 0);
__decorate([
    Prop(),
    __metadata("design:type", String)
], BaseTable.prototype, "title", void 0);
__decorate([
    Prop({
        type: Number
    }),
    __metadata("design:type", Number)
], BaseTable.prototype, "totalItems", void 0);
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_e = typeof IAppTableUserDefinedPagination !== "undefined" && IAppTableUserDefinedPagination) === "function" ? _e : Object)
], BaseTable.prototype, "userDefinedPagination", void 0);
__decorate([
    Prop(),
    __metadata("design:type", Array)
], BaseTable.prototype, "value", void 0);
__decorate([
    DebounceAll(50),
    Emit('filtered-items'),
    Watch('pagination', { deep: true }),
    Watch('searchFilter'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseTable.prototype, "emitFilteredItems", null);
__decorate([
    Emit('searchFilterInput'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], BaseTable.prototype, "emitSearchFilterInput", null);
__decorate([
    Emit('loading'),
    Watch('isLoading', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseTable.prototype, "onLoadingInternalChange", null);
__decorate([
    Watch('loading', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseTable.prototype, "onLoadingPropChange", null);
__decorate([
    Watch('userDefinedPagination', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseTable.prototype, "onUserDefinedPaginationPropChange", null);
__decorate([
    Watch('items', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], BaseTable.prototype, "resetPagination", null);
__decorate([
    Watch('selectableRows', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseTable.prototype, "onChangeOfSelectableRows", null);
__decorate([
    Watch('pagination', { deep: true }),
    Emit('updateCustomPagination'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_f = typeof IAppTableUserDefinedPagination !== "undefined" && IAppTableUserDefinedPagination) === "function" ? _f : Object]),
    __metadata("design:returntype", void 0)
], BaseTable.prototype, "onPaginationChange", null);
__decorate([
    Watch('customPagination', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_g = typeof IAppTableUserDefinedPagination !== "undefined" && IAppTableUserDefinedPagination) === "function" ? _g : Object]),
    __metadata("design:returntype", void 0)
], BaseTable.prototype, "syncPagination", null);
BaseTable = __decorate([
    Component
], BaseTable);
export default BaseTable;
