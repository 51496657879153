import { CosmicSystem } from './system';
export var EsocApplication;
(function (EsocApplication) {
    EsocApplication["CDOCS"] = "CDOCS";
    EsocApplication["KNOWLEDGE_BASE"] = "KNOWLEDGE_BASE";
    EsocApplication["LOGISTICS"] = "LOGISTICS";
    EsocApplication["OG"] = "OG";
    EsocApplication["RISK"] = "RISK";
})(EsocApplication || (EsocApplication = {}));
export const BASE_URL_BY_SYSTEM = {
    ESOC: {
        OG: {
            local: 'http://localhost:8000',
            development: 'http://localhost:8000',
            staging: 'https://staging.cosmic.appdat.jsc.nasa.gov',
            production: 'https://cosmic.appdat.jsc.nasa.gov'
        },
        CDOCS: {
            local: 'http://localhost:4400',
            development: 'http://localhost:4400',
            staging: 'https://docs.staging.cosmic.appdat.jsc.nasa.gov',
            production: 'https://docs.cosmic.appdat.jsc.nasa.gov'
        },
        KNOWLEDGE_BASE: {
            local: 'http://localhost:8081',
            development: 'http://knowledge.staging.cosmic.appdat.jsc.nasa.gov',
            staging: 'http://knowledge.staging.cosmic.appdat.jsc.nasa.gov',
            production: 'http://knowledge.cosmic.appdat.jsc.nasa.gov'
        },
        RISK: {
            local: 'http://localhost:4600',
            development: 'http://risk.staging.cosmic.appdat.jsc.nasa.gov',
            staging: 'http://risk.staging.cosmic.appdat.jsc.nasa.gov',
            production: 'http://risk.cosmic.appdat.jsc.nasa.gov'
        },
        LOGISTICS: {
            local: 'http://localhost:4400',
            development: 'http://logistics.staging.cosmic.appdat.jsc.nasa.gov',
            staging: 'http://logistics.staging.cosmic.appdat.jsc.nasa.gov',
            production: 'http://logistics.cosmic.appdat.jsc.nasa.gov'
        }
    }
};
