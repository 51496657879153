// Full list of project codes from Danny as of April 9, 2021
export const ProjectCodeDisplay = new Map([
    ['00010', 'Cold Stowage Hardware Development'],
    ['00070', 'Extra Vehicular Robotics Hardware'],
    ['02226', 'AES-Advanced EMU'],
    ['02856', 'Universal Waste Management System'],
    ['02934', 'Orion Crew Survival Systems'],
    ['02971', 'Orion Environmental Control and Life Support Systems (ECLSS)'],
    ['02984', 'Orion Flight Crew Equipment (FCE)'],
    ['03004', 'Wired Heart Rate Monitor'],
    ['03010', 'CCP GFE Crew Hardware - Richard Watson'],
    ['03021', 'Lunar EVA Tools - Heather Bergman'],
    ['0C001', 'Crew Systems Laboratory'],
    ['0C002', 'Space Environment Simulation Laboratory'],
    ['0R001', 'Robotic Systems Evaluation Laboratory (RESEL)'],
    ['1C405', 'Crew Escape Equipment'],
    ['1C408', 'HST EVA Hardware - Hubble Space Telescope EVA Tools'],
    ['1C504', 'Extravehicular Mobility Unit'],
    ['2C004', 'Cryo Freezer & Melfi'],
    ['2C411', 'Airlock Servicing Performance & Checkout Equip.'],
    ['2C411', 'Power Supply Assembly'],
    ['2C601', 'Bilateral Tools'],
    ['2R106', 'Robotic Berthing Target System'],
    ['2R503', 'Simplified Aid for EVA Rescue'],
    ['2R803', 'Autonomous Extravehicular Robotic Camera'],
    ['2R903', 'Robotic Micro Conical Tool'],
    ['OC001', 'Facilities'],
    ['OC002', 'Facilities'],
    ['OR001', 'Robotics Systems Evaluation Laboratory'],
    ['P00124', 'Vibration Isolation and Stabilization System (VIS)'],
    ['P00142', 'Lavatory On-Orbit (LOO)'],
    ['XA129111', 'EVA Office GFE']
]);
