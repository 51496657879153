/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./TopPages.vue?vue&type=template&id=d2331392&scoped=true"
import script from "./TopPages.ts?vue&type=script&lang=js&external"
export * from "./TopPages.ts?vue&type=script&lang=js&external"
import style0 from "./TopPages.vue?vue&type=style&index=0&id=d2331392&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2331392",
  null
  
)

export default component.exports