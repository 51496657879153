import { FederalSupplyClass, ObjectClass } from '~/db_types';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
export const ObjectClassDisplay = new Map([
    [ObjectClass._5691, '5691'],
    [ObjectClass._5671, '5671'],
    [ObjectClass._5691, '5691'],
    [ObjectClass._5671, '5671'],
    [ObjectClass._5742, '5742'],
    [ObjectClass._5721, '5721'],
    [ObjectClass._5765, '5765'],
    [ObjectClass._5661, '5661'],
    [ObjectClass._5631, '5631'],
    [ObjectClass._5681, '5681'],
    [ObjectClass._5641, '5641'],
    [ObjectClass._5711, '5711'],
    [ObjectClass._5611, '5611'],
    [ObjectClass._5762, '5762'],
    [ObjectClass._5751, '5751'],
    [ObjectClass._5651, '5651'],
    [ObjectClass._5621, '5621'],
    [ObjectClass._5731, '5731'],
    [ObjectClass._5761, '5761'],
    [ObjectClass._5741, '5741'],
    [ObjectClass._5725, '5725'],
    [ObjectClass.NO_OBJECT_CLASS_FOUND, 'None']
]);
export const FederalSupplyClassToObjectClass = new Map([
    [FederalSupplyClass.ABRASIVE_MATERIALS, ObjectClassDisplay.get(ObjectClass._5691)],
    [FederalSupplyClass.ACCESSORIES_FOR_SECONDARY_METALWORKING, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.ACCOUNTING_AND_CALCULATING_MACHINES, ObjectClassDisplay.get(ObjectClass._5742)],
    [FederalSupplyClass.ADDITIVE_METAL_MATERIALS_AND_MASTER_ALLOYS, ObjectClassDisplay.get(ObjectClass._5721)],
    [FederalSupplyClass.ADHESIVES, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.ADP_CENTRAL_PROCESSING_UNIT_CC_ANALOG, ObjectClassDisplay.get(ObjectClass._5765)],
    [FederalSupplyClass.ADP_CENTRAL_PROCESSING_UNIT_CC_DIGITAL, ObjectClassDisplay.get(ObjectClass._5765)],
    [FederalSupplyClass.ADP_CENTRAL_PROCESSING_UNIT_CC_HYBRID, ObjectClassDisplay.get(ObjectClass._5765)],
    [FederalSupplyClass.ADP_COMPONENTS, ObjectClassDisplay.get(ObjectClass._5765)],
    [FederalSupplyClass.ADP_INPUT_OUTPUT_AND_STORAGE_DEVICES, ObjectClassDisplay.get(ObjectClass._5765)],
    [FederalSupplyClass.ADP_SOFTWARE, ObjectClassDisplay.get(ObjectClass._5765)],
    [FederalSupplyClass.ADP_SUPPLIES, ObjectClassDisplay.get(ObjectClass._5765)],
    [FederalSupplyClass.ADP_SUPPORT_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5765)],
    [FederalSupplyClass.ADPE_SYSTEMS_CONFIGURATION, ObjectClassDisplay.get(ObjectClass._5765)],
    [FederalSupplyClass.AIR_CONDITIONING_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.AIR_PURIFICATION_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.AIRCRAFT_ALARM_AND_SIGNAL_SYSTEMS, ObjectClassDisplay.get(ObjectClass._5631)],
    [FederalSupplyClass.AIRCRAFT_MAINTENANCE_AND_REPAIR_SHOP, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.AMIMAL_DRAWN_VEHICLES_AND_FARM_TRAILERS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.AMMUNITION_AND_NUCLEAR_ORDNANCE_BOXES, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.AMMUNITION_MAINTENANCE_REPAIR_AND_CHECKOUT, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.ANTENNAS_WAVEGUIDE_AND_RELATED_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.ARMAMENT_TRAINING_DEVICES, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.ARMOR_PERSONAL, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.ATHLETIC_AND_SPORTING_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.ATTENUATORS, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.AUTOMATIC_PILOT_MECHANISMS_AND_AIRBORNE, ObjectClassDisplay.get(ObjectClass._5711)],
    [FederalSupplyClass.BADGES_AND_INSIGNIA, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.BAGS_AND_SACKS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.BAKERY_AND_CEREAL_PRODUCTS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.BALLASTS_LAMPHOLDERS_AND_STARTERS, ObjectClassDisplay.get(ObjectClass._5631)],
    [FederalSupplyClass.BARS_AND_RODS_IRON_AND_STEEL, ObjectClassDisplay.get(ObjectClass._5721)],
    [FederalSupplyClass.BARS_AND_RODS_NONFERROUS_BASE_METAL, ObjectClassDisplay.get(ObjectClass._5721)],
    [FederalSupplyClass.BATTERIES_NONRECHARGEABLE, ObjectClassDisplay.get(ObjectClass._5631)],
    [FederalSupplyClass.BATTERIES_RECHARGEABLE, ObjectClassDisplay.get(ObjectClass._5631)],
    [FederalSupplyClass.BEARINGS_ANTIFRICTION_UNMOUNTED, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.BEARINGS_PLAIN_UNMOUNTED, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.BEARINGS_UNMOUNTED, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.BELTING_DRIVE_BELTS_FAN_BELTS_AND_ACCESSORIES, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.BENDING_AND_FORMING_MACHINES, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.BEVERAGES_ALCOHOLIC, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.BEVERAGES_NONALCOHOLIC_H20_WATER, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.BLOCKS_TACKLE_RIGGING_AND_SLINGS, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.BOLTS, ObjectClassDisplay.get(ObjectClass._5691)],
    [FederalSupplyClass.BOOKS_AND_PAMPHLETS, ObjectClassDisplay.get(ObjectClass._5742)],
    [FederalSupplyClass.BORING_MACHINES, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.BOTTLES_AND_JARS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.BOXES_CARTONS_AND_CRATES, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.BRACKETS, ObjectClassDisplay.get(ObjectClass._5691)],
    [FederalSupplyClass.BRIDGES_FIXED_AND_FLOATING, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.BROACHING_MACHINES, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.BROOMS_BRUSHES_MOPS_AND_SPONGES, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.BUILDING_COMPONENTS_PREFABRICATED, ObjectClassDisplay.get(ObjectClass._5611)],
    [FederalSupplyClass.BUILDING_GLASS_TILE_BRICK_AND_BLOCK, ObjectClassDisplay.get(ObjectClass._5611)],
    [FederalSupplyClass.BUSHINGS_RINGS_SHIMS_AND_SPACERS, ObjectClassDisplay.get(ObjectClass._5691)],
    [FederalSupplyClass.CABINETS_LOCKERS_BINS_AND_SHELVING, ObjectClassDisplay.get(ObjectClass._5762)],
    [FederalSupplyClass.CABLE_CORD_AND_WIRE_ASSEMBLIES_COMMUNICATION, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.CAMERAS_MOTION_PICTURE, ObjectClassDisplay.get(ObjectClass._5751)],
    [FederalSupplyClass.CAMERAS_STILL_PICTURE, ObjectClassDisplay.get(ObjectClass._5751)],
    [FederalSupplyClass.CAMOUFLAGE_AND_DECEPTION_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.CAPACITORS, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.CARTRIDGE_AND_PROPELLANT_ACTUATED_DEVICES, ObjectClassDisplay.get(ObjectClass._5651)],
    [FederalSupplyClass.CENTRIFUGALS_SEPARATORS_H20_FILTER_AND_PRESSURE, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.CHAIN_AND_WIRE_ROPE, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.CHEMICAL_ANALYSIS_INSTRUMENTS, ObjectClassDisplay.get(ObjectClass._5711)],
    [FederalSupplyClass.CHEMICAL_AND_PHARMACEUTICAL_PRODUCTS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.CHEMICALS, ObjectClassDisplay.get(ObjectClass._5621)],
    [FederalSupplyClass.CHILDRENS_AND_INFANTS_APPAREL_ACCESSORIES, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.CIRCUIT_BREAKERS, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.CLAY_AND_CONCRETE_PRODUCTS_INDUSTRIES, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.CLEAN_WORK_STATIONS_FLOWBENCH_CONTROLLED_ENVIR, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.CLEANING_AND_POLISHING_COMPOUNDS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.CLOTHING_SPECIAL_PURPOSE, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.COFFEE_TEA_AND_COCOA, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.COIL_FLAT_AND_WIRE_SPRINGS, ObjectClassDisplay.get(ObjectClass._5691)],
    [FederalSupplyClass.COILS_AND_TRANSFORMERS, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.COLLECTORS_AND_OR_HISTORICAL_ITEMS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.COMBINATION_AND_MISCELLANEOUS_INSTRUMENTS, ObjectClassDisplay.get(ObjectClass._5711)],
    [FederalSupplyClass.COMMERCIAL_AND_INDUSTIAL_GAS_CYLINDERS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.COMMUNICATION_TRAINING_DEVICES, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.COMMUNICATIONS_SECURITY_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.COMPOSITE_FOOD_PACKAGES, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.COMPRESSORS_AND_VACUUM_PUMPS, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.CONDIMENTS_AND_RELATED_PRODUCTS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.CONNECTORS_ELECTRICAL, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.CONVERTERS_ELECTRICAL_NONROTATING, ObjectClassDisplay.get(ObjectClass._5631)],
    [FederalSupplyClass.CONVERTERS_ELECTRICAL_ROTATING_RESIDUE, ObjectClassDisplay.get(ObjectClass._5631)],
    [FederalSupplyClass.CONVEYORS, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.COUPLERS_SPLITTERS_AND_MIXERS, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.CRANE_AND_CRANE_SHOVEL_ATTACHMENTS, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.CRANES_AND_CRANE_SHOVELS, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.CRUDE_GRADES_OF_PLANT_MATERIALS, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.CRYSTAL_AND_GLASS_INDUSTRIES_MACHINERY, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.CUTLERY_AND_FLATWARE, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.CUTTING_AND_FORMING_TOOLS_FOR_SECONDARY, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.CUTTING_TOOLS_AND_MACHINE_TOOLS, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.DAIRY_FOODS_AND_EGGS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.DAIRY_POULTRY_AND_LIVESTOCK_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.DECONTAMINATING_AND_IMPREGNATING_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.DENTAL_INSTRUMENTS_EQUIPMENT_AND_SUPPLIES, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.DEPTH_CHARGES_AND_UNDERWATER_MINES_MAINTENANCE, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.DISKS_AND_STONES_ABRASIVE, ObjectClassDisplay.get(ObjectClass._5691)],
    [FederalSupplyClass.DRAFTING_SURVEYING_AND_MAPPING_INSTRUMENTS, ObjectClassDisplay.get(ObjectClass._5711)],
    [FederalSupplyClass.DRAPERIES_AWNINGS_AND_SHADES, ObjectClassDisplay.get(ObjectClass._5742)],
    [FederalSupplyClass.DRAWINGS_AND_SPECIFICATIONS, ObjectClassDisplay.get(ObjectClass._5742)],
    [FederalSupplyClass.DRIERS_DEHYDRATORS_AND_ANHYDRATORS, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.DRILL_BITS_COUNTERBORES_AND_COUNTERSINKS, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.DRILLING_AND_TAPPING_MACHINES, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.DRUGS_AND_BIOLOGICALS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.DRUMS_AND_CANS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.DYES, ObjectClassDisplay.get(ObjectClass._5621)],
    [FederalSupplyClass.EARTH_MOVING_AND_EXCAVATING_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.ECCLESIASTICAL_EQUIPMENT_FURNISHINGS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.ELECTRIC_ARC_WELDING_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.ELECTRIC_LAMPS, ObjectClassDisplay.get(ObjectClass._5631)],
    [FederalSupplyClass.ELECTRIC_PORTABLE_AND_HAND_LIGHTING_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5631)],
    [FederalSupplyClass.ELECTRIC_RESISTANCE_WELDING_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.ELECTRIC_VEHICULAR_LIGHTS_AND_FIXTURES, ObjectClassDisplay.get(ObjectClass._5631)],
    [FederalSupplyClass.ELECTRICAL_AND_ELECTRONIC_ASSEMBLIES_BOARDS, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.ELECTRICAL_AND_ELECTRONIC_PROPERTIES, ObjectClassDisplay.get(ObjectClass._5711)],
    [FederalSupplyClass.ELECTRICAL_AND_ULTRASONIC_EROSION_MACHINES, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.ELECTRICAL_CONTACT_BRUSHES_AND_ELECTRODES, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.ELECTRICAL_CONTROL_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5631)],
    [FederalSupplyClass.ELECTRICAL_HARDWARE_AND_SUPPLIES, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.ELECTRICAL_INSULATORS_AND_INSULATING_MATERIALS, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.ELECTRON_TUBES_ASSOCIATED_HARDWARE, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.ELECTRONIC_COUNTERMEASURES_COUNTER, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.ELECTRONIC_MODULES, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.ELEVATORS_AND_ESCALATORS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.ENGINE_AIR_AND_OIL_FILTERS_STRAINERS_NONAIRCRAFT, ObjectClassDisplay.get(ObjectClass._5731)],
    [FederalSupplyClass.ENGINE_COOLING_SYSTEM_COMPONENTS_NONAIRCRAFT, ObjectClassDisplay.get(ObjectClass._5731)],
    [FederalSupplyClass.ENGINE_ELECTRICAL_SYSTEM_COMPONENTS_NONAIRCRAFT, ObjectClassDisplay.get(ObjectClass._5731)],
    [FederalSupplyClass.ENGINE_INSTRUMENTS, ObjectClassDisplay.get(ObjectClass._5711)],
    [FederalSupplyClass.ENVIRONMENTAL_CHAMBERS_AND_RELATED_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5711)],
    [FederalSupplyClass.EXPLOSIVE_AND_PYROTECHNIC_COMPONENTS, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.FANS_AIR_CIRCULATORS_AND_BLOWER_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.FASTENING_DEVICES, ObjectClassDisplay.get(ObjectClass._5691)],
    [FederalSupplyClass.FENCING_FENCES_AND_GATES, ObjectClassDisplay.get(ObjectClass._5611)],
    [FederalSupplyClass.FERTILIZERS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.FIBER_OPIC_LIGHT_TRANSFER_AND_IMAGE_TRANSFER, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.FIBER_OPTIC_ACCESSORIES_AND_SUPPLIES, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.FIBER_OPTIC_CABLE_ASSEMBLIES_AND_HARNESSES, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.FIBER_OPTIC_CABLES, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.FIBER_OPTIC_CONDUCTORS, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.FIBER_OPTIC_DEVICES, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.FIBER_OPTIC_INTERCONNECTORS, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.FIBER_OPTIC_KITS_AND_SETS, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.FIBER_OPTIC_LIGHT_SOURCES, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.FIBER_OPTIC_MODULATORS_DEMODULATORS, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.FIBER_OPTIC_PASSIVE_DEVICES, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.FIBER_OPTIC_PHOTO_DETECTORS, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.FIBER_OPTIC_RECEIVERS, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.FIBER_OPTIC_SENSORS, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.FIBER_OPTIC_SWITCHES, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.FIBER_OPTIC_TRANSMITTERS, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.FIBER_ROPE_CORDAGE_AND_TWINE, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.FIBERS_VEGETABLE_ANIMAL_AND_SYNTHETIC, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.FILM_PROCESSED, ObjectClassDisplay.get(ObjectClass._5751)],
    [FederalSupplyClass.FILTERS_AND_NETWORKS, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.FILTERS, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.FIRE_CONTROL_MAINTENANCE_AND_REPAIR_SHOP, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.FIRE_FIGHTING_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.FITTINGS_AND_SPECIALTIES_HOSE_PIPEAND_TUBE, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.FITTINGS_FOR_ROPE_CABLE_AND_CHAIN, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.FLAGS_AND_PENNANTS, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.FLIGHT_INSTRUMENTS, ObjectClassDisplay.get(ObjectClass._5711)],
    [FederalSupplyClass.FLOOR_COVERINGS, ObjectClassDisplay.get(ObjectClass._5742)],
    [FederalSupplyClass.FLOOR_POLISHERS_AND_VACUUM_CLEANING_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.FOOD_COOKING_BAKING_AND_SERVING_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.FOOD_OILS_AND_FATS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.FOOD_PRODUCTS_MACHINERY_AND_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.FOOTWEAR_MENS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.FOOTWEAR_WOMENS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.FORAGE_AND_FEED, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.FORGING_MACHINERY_AND_HAMMERS, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.FOUNDRY_MACHINERY_RELATED_EQUIPMENT_AND_SUPPLIES, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.FRUITS_AND_VEGETABLES, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.FUEL_BURNING_EQUIPMENT_UNITS, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.FUEL_CELL_POWER_UNITS_COMPONENTS, ObjectClassDisplay.get(ObjectClass._5631)],
    [FederalSupplyClass.FUEL_OILS, ObjectClassDisplay.get(ObjectClass._5651)],
    [FederalSupplyClass.FUELS_SOLID, ObjectClassDisplay.get(ObjectClass._5651)],
    [FederalSupplyClass.FUR_MATERIALS, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.FUSES_ARRESTERS_ABSORBERS_AND_PROTECTORS, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.GAMES_TOYS_AND_WHEELED_GOODS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.GARDENING_IMPLEMENTS_AND_TOOLS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.GAS_GENERATING_AND_DISPENSING_SYSTEMS_FIXED, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.GAS_WELDING_HEAT_CUTTING_AND_METALIZING, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.GASES_COMPRESSED_AND_LIQUEFIED, ObjectClassDisplay.get(ObjectClass._5621)],
    [FederalSupplyClass.GEAR_CUTTING_AND_FINISHING_MACHINES, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.GEARS_PULLEYS_SPROCKETS_AND_TRANSMISSION, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.GENERATORS_AND_GENERATOR_SETS_ELECTRICAL, ObjectClassDisplay.get(ObjectClass._5631)],
    [FederalSupplyClass.GEOPHYSICAL_INSTRUMENTS, ObjectClassDisplay.get(ObjectClass._5711)],
    [FederalSupplyClass.GLASS_FABRICATED_MATERIALS, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.GRINDING_MACHINES, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.GROUP_10_WEAPONS, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.GROUP_11_NUCLEAR_ORDNANCE, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.GROUP_12_FIRE_CONTROL_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.GROUP_13_AMMUNITION_AND_EXPLOSIVES, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.GROUP_14_GUIDED_MISSLES, ObjectClassDisplay.get(ObjectClass._5731)],
    [FederalSupplyClass.GROUP_15_AIRCRAFT_AND_AIRFRAME_STRUCTURAL_EQUIP, ObjectClassDisplay.get(ObjectClass._5731)],
    [FederalSupplyClass.GROUP_16_AIRCRAFT_COMPONENTS_AND_ACCESSORIES, ObjectClassDisplay.get(ObjectClass._5731)],
    [FederalSupplyClass.GROUP_17_AIRCRAFT_LAUNCH_LAND_AND_GROUND_EQUIP, ObjectClassDisplay.get(ObjectClass._5731)],
    [FederalSupplyClass.GROUP_19_SHIPS_SM_CRAFTS_PONTOONS_FLOATING_DOCKS, ObjectClassDisplay.get(ObjectClass._5731)],
    [FederalSupplyClass.GROUP_20_SHIP_AND_MARINE_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5731)],
    [FederalSupplyClass.GROUP_22_RAILWAY_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5731)],
    [FederalSupplyClass.GROUP_23_GROUND_AND_MOTOR_VEHICLES_TRAILERS_CYCLES, ObjectClassDisplay.get(ObjectClass._5731)],
    [FederalSupplyClass.GROUP_24_TRACTORS, ObjectClassDisplay.get(ObjectClass._5731)],
    [FederalSupplyClass.GROUP_25_VEHICULAR_EQUIPMENT_COMPONENTS, ObjectClassDisplay.get(ObjectClass._5731)],
    [FederalSupplyClass.GROUP_26_TIRES_AND_TUBES, ObjectClassDisplay.get(ObjectClass._5731)],
    [FederalSupplyClass.GROUP_28_ENGINES_TURBINES_AND_COMPONENTS, ObjectClassDisplay.get(ObjectClass._5731)],
    [FederalSupplyClass.GROUP_29_ENGINE_ACCESSORIES, ObjectClassDisplay.get(ObjectClass._5731)],
    [FederalSupplyClass.GROUP_30_MECHANICAL_POWERED_TRANSMISSION_EQUIP, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.GROUP_31_BEARINGS, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.GROUP_32_WOODWORKING_MACHINERY_AND_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.GROUP_34_METALWORKING_MACHINERY, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.GROUP_35_SERVICE_AND_TRADE_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.GROUP_36_SPECIAL_INDUSTRY_MACHINERY, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.GROUP_37_AGRICULTURAL_MACHINERY_AND_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.GROUP_38_CONSTRUCTION_MINING_EXCAVATING_ROAD_EQUIP, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.GROUP_39_MATERIAL_HANDLING_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.GROUP_40_ROPE_CABLE_CHAIN_AND_FITTINGS, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.GROUP_41_REFRIGERATION_AIRCONDITIONING_CIRC_EQUIP, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.GROUP_42_FIRE_FIGHTING_RESCUE_AND_SAFETY_EQUIP, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.GROUP_43_PUMPS_AND_COMPRESSORS, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.GROUP_44_FURNANCE_STEAM_DRYING_EQUIP_NUC_REACTOR, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.GROUP_45_PLUMBING_HEATING_AND_SANITATION_EQUIP, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.GROUP_46_WATER_PURIFICATION_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.GROUP_47_PIPE_TUBING_HOSE_AND_FITTINGS, ObjectClassDisplay.get(ObjectClass._5761)],
    [FederalSupplyClass.GROUP_48_VALVES, ObjectClassDisplay.get(ObjectClass._5761)],
    [FederalSupplyClass.GROUP_49_MAINTENANCE_AND_REPAIR_SHOP_EQUIP, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.GROUP_51_HAND_TOOLS, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.GROUP_52_MEASURING_TOOLS, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.GROUP_53_HARDWARE_AND_ABRASIVES, ObjectClassDisplay.get(ObjectClass._5691)],
    [FederalSupplyClass.GROUP_54_PREFABRICATED_STRUCTURES_AND_SCAFFOLDING, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.GROUP_55_LUMBER_MILLWORK_PLYWOOD_AND_VANEER, ObjectClassDisplay.get(ObjectClass._5611)],
    [FederalSupplyClass.GROUP_56_CONSTRUCTION_AND_BUILDING_MATERIAL, ObjectClassDisplay.get(ObjectClass._5611)],
    [FederalSupplyClass.GROUP_58_COMMUNICATION_DETECTION_CO_RADIATION_EQUP, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.GROUP_59_ELECTRICAL_ELECTRONIC_EQUIP_COMPONENTS, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.GROUP_60_FIBER_OPTIC_MAT_COMPONENTS_ASSY_ACCESSARY, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.GROUP_61_ELECTRIC_WIRE_POWER_DISTRIBUTION_EQUIP, ObjectClassDisplay.get(ObjectClass._5631)],
    [FederalSupplyClass.GROUP_62_LIGHTING_FIXTURES_AND_LAMPS, ObjectClassDisplay.get(ObjectClass._5631)],
    [FederalSupplyClass.GROUP_63_ALARM_SIGNAL_SECURITY_DETECTION_SYS, ObjectClassDisplay.get(ObjectClass._5631)],
    [FederalSupplyClass.GROUP_65_MEDICAL_DENTAL_AND_VET_EQUIP_SUPPLIES, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.GROUP_66_INSTRUMENTS_AND_LABRATORY_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5711)],
    [FederalSupplyClass.GROUP_67_PHOTOGRAPHIC_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5751)],
    [FederalSupplyClass.GROUP_68_CHEMICALS_AND_CHEMICAL_PRODUCTS, ObjectClassDisplay.get(ObjectClass._5621)],
    [FederalSupplyClass.GROUP_69_TRAINING_AIDS_AND_DEVICES, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.GROUP_70_ADPE_EQUIP_SOFTWARE_SUPPLIES_AND_SUPPORT, ObjectClassDisplay.get(ObjectClass._5765)],
    [FederalSupplyClass.GROUP_71_FURNITURE, ObjectClassDisplay.get(ObjectClass._5762)],
    [FederalSupplyClass.GROUP_72_HOUSE_COMMERCIAL_FURNISHING_APPLIANCES, ObjectClassDisplay.get(ObjectClass._5742)],
    [FederalSupplyClass.GROUP_73_FOOD_PREPARATION_AND_SERVICE_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.GROUP_74_OFFICE_MACH_TEXT_VISIBLE_RECORD_EQUIP, ObjectClassDisplay.get(ObjectClass._5742)],
    [FederalSupplyClass.GROUP_75_OFFICE_SUPPLIES_AND_DEVICES, ObjectClassDisplay.get(ObjectClass._5741)],
    [FederalSupplyClass.GROUP_76_BOOKS_MAPS_AND_OTHER_PUBLICATIONS, ObjectClassDisplay.get(ObjectClass._5742)],
    [FederalSupplyClass.GROUP_77_MUSIC_INSTRUMENT_PHONOGRAPH_HOME_RADIO, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.GROUP_78_RECREATIONAL_AND_ATHLETIC_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.GROUP_79_CLEANING_EQUIPMENT_AND_SUPPLIES, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.GROUP_80_BRUSHES_PAINTS_SEALERS_AND_ADHESIVES, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.GROUP_81_CONTAINERS_PACKAGES_PACKING_SUPPLIES, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.GROUP_83_TEXTILESFURSAPPARELSHOESTENTSFLAGS, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.GROUP_84_CLOTHING_INDIVIUAL_EQUIPMENT_AND_INSIGNIA, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.GROUP_85_TOILETRIES, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.GROUP_87_AGRICULTURE_SUPPLIES, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.GROUP_88_LIVE_ANIMALS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.GROUP_89_SUBSISTENCE, ObjectClassDisplay.get(ObjectClass.NO_OBJECT_CLASS_FOUND)],
    [FederalSupplyClass.GROUP_91_FUELS_LUBRICATIONS_OILS_AND_WAXES, ObjectClassDisplay.get(ObjectClass._5651)],
    [FederalSupplyClass.GROUP_93_NON_METALLIC_FABRICATION_MATERIAL, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.GROUP_94_NON_METALLIC_RUDE_MATERIAL, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.GROUP_95_METAL_BARS_SHEETS_AND_SHAPES, ObjectClassDisplay.get(ObjectClass._5721)],
    [FederalSupplyClass.GROUP_96_ORES_MINERALS_THEIR_PRIMARY_PRODUCTS, ObjectClassDisplay.get(ObjectClass._5721)],
    [FederalSupplyClass.GROUP_99_MISCELLANEOUS, ObjectClassDisplay.get(ObjectClass._5691)],
    [FederalSupplyClass.GUIDED_MISSILE_MAINTENANCE_REPAIR, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.GUNS_OVER_125MM_THROUGH_150MM, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.HAND_TOOLS_EDGED_NONPOWERED, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.HAND_TOOLS_NONEDGED_NONPOWERED, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.HAND_TOOLS_POWER_DRIVEN, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.HARVESTING_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.HAZARD_DETECTING_INSTRUMENTS_AND_APPARATUS, ObjectClassDisplay.get(ObjectClass._5711)],
    [FederalSupplyClass.HEADSETS_HANDSETS_MICROPHONES_AND_SPEAKERS, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.HEAT_EXCHANGERS_AND_STEAM_CONDENSERS, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.HOSE_AND_TUBING_FLEXIBLE, ObjectClassDisplay.get(ObjectClass._5761)],
    [FederalSupplyClass.HOSIERY_HANDWEAR_AND_CLOTHING_MENS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.HOSIERY_HANDWEAR_BADGES_AND_CLOTHING_WOMENS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.HOSPITAL_AND_SURGICAL_CLOTHING_AND_RELATED, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.HOSPITAL_FURNITURE_EQUIPMENT_UTENSILS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.HOUSEHOLD_AND_COMMERCIAL_UTILITY_CONTAINERS, ObjectClassDisplay.get(ObjectClass._5742)],
    [FederalSupplyClass.HOUSEHOLD_FURNISHINGS, ObjectClassDisplay.get(ObjectClass._5742)],
    [FederalSupplyClass.HOUSEHOLD_FURNITURE, ObjectClassDisplay.get(ObjectClass._5762)],
    [FederalSupplyClass.HYDRAULIC_AND_PNEUMATIC_PRESSES_POWER, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.IN_VITRO_DIAGNOSTIC_SUBSTANCES_REAGENTS_TEST, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.INDIVIDUAL_EQUIPMENT_SUNGLASSES, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.INDOOR_AND_OUTDOOR_ELECTRIC_LIGHTING_FIXTURES, ObjectClassDisplay.get(ObjectClass._5631)],
    [FederalSupplyClass.INDUSTRIAL_ASSEMBLY_MACHINES, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.INDUSTRIAL_BOILERS, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.INDUSTRIAL_FURNACES_KILNS_LEHRS_AND_OVENS, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.INDUSTRIAL_MARKING_MACHINES, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.INDUSTRIAL_SEWING_MACHINES_MOBILE_TEXTILE, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.INDUSTRIAL_SIZE_REDUCTION_MACHINERY, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.INSPECTION_GAGES_AND_PRECISION_LAYOUT_TOOLS, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.INTEGRATED_OPTICAL_CIRCUITS, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.INTERCOMMUNICATION_AND_PUBLIC_ADDRESS_S_AIRBORNE, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.INTERCOMMUNICATION_AND_PUBLIC_ADDRESS_S_EXCEPT, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.IRON_AND_STEEL_PRIMARY_AND_SEMIFINISHED_PRODUCTS, ObjectClassDisplay.get(ObjectClass._5721)],
    [FederalSupplyClass.IRON_AND_STEEL_SCRAP, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.JAMS_JELLIES_AND_PRESERVES, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.JEWELRY, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.KITCHEN_EQUIPMENT_AND_APPLIANCES, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.KITCHEN_HAND_TOOLS_AND_UTENSILS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.KNOBS_AND_POINTERS, ObjectClassDisplay.get(ObjectClass._5691)],
    [FederalSupplyClass.LABORATORY_EQUIPMENT_AND_SUPPLIES, ObjectClassDisplay.get(ObjectClass._5711)],
    [FederalSupplyClass.LATHES, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.LAUNDRY_AND_DRY_CLEANING_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.LEATHER_TANNING_AND_LEATHER_WORKING, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.LEATHER, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.LIQUID_AND_GAS_FLOW_LIQUID_LEVEL, ObjectClassDisplay.get(ObjectClass._5711)],
    [FederalSupplyClass.LIQUID_PROPELLANTS_AND_FUELS_PETROLEUM_BASE, ObjectClassDisplay.get(ObjectClass._5651)],
    [FederalSupplyClass.LIQUID_PROPELLANTS_AND_OXIDIZERS, ObjectClassDisplay.get(ObjectClass._5651)],
    [FederalSupplyClass.LIVE_ANIMALS_NOT_RAISED_FOR_FOOD, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.LIVE_ANIMALS_RAISED_FOR_FOOD, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.LUBRICATION_AND_FUEL_DISPENSING_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.LUGGAGE, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.LUGS_TERMINALS_AND_TERMINAL_STRIPS, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.LUMBER_AND_RELATED_BASIC_WOOD_MATERIALS, ObjectClassDisplay.get(ObjectClass._5611)],
    [FederalSupplyClass.MACHINE_SHOP_SETS_KITS_AND_OUTFITS, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.MACHINE_TOOL_ACCESSORIES, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.MACHINE_TOOLS_PORTABLE, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.MACHINING_CENTERS_AND_WAY_TYPE_MACHINES, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.MANUAL_PRESSES, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.MAPS_ATLASES_CHARTS_AND_GLOBES, ObjectClassDisplay.get(ObjectClass._5742)],
    [FederalSupplyClass.MARINE_LIFESAVING_AND_DIVING_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.MATERIALS_FEEDERS, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.MATERIALS_HANDLING_EQUIPMENT_NONSELFPROPELLED, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.MEASURING_TOOLS_CRAFTSMENS, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.MEAT_POULTRY_AND_FISH, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.MECHANICAL_POWER_TRANSMISSION_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.MECHANICAL_PRESSES_POWER_DRIVEN, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.MEDICAL_AND_SURGICAL_INSTRUMENTS_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.MEDICATED_COSMETICS_AND_TOILETRIES, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.MEMORIALS_CEMETERIAL_AND_MORTUARY_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.METAL_FINISHING_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.METAL_HEAT_TREATING_AND_NON_THERMAL_TREATING, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.METAL_SCREENING, ObjectClassDisplay.get(ObjectClass._5691)],
    [FederalSupplyClass.METEROLOGICAL_INSTRUMENTS_AND_APPARATUS, ObjectClassDisplay.get(ObjectClass._5711)],
    [FederalSupplyClass.MICROCIRCUITS_ELECTRONIC, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.MICROFILM_PROCESSED, ObjectClassDisplay.get(ObjectClass._5742)],
    [FederalSupplyClass.MILLING_MACHINES, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.MILLWORK, ObjectClassDisplay.get(ObjectClass._5611)],
    [FederalSupplyClass.MINERAL_CONSTRUCTION_MATERIALS_BULK, ObjectClassDisplay.get(ObjectClass._5611)],
    [FederalSupplyClass.MINERALS_NATURAL_AND_SYNTHETIC, ObjectClassDisplay.get(ObjectClass._5721)],
    [FederalSupplyClass.MINI_AND_MICRO_COMPUTER_CONTROL_DEVICES, ObjectClassDisplay.get(ObjectClass._5725)],
    [FederalSupplyClass.MINING_ROCK_DRILLING_EARTH_BORING, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.MISCELLANEOUS_ALARM_SIGNAL_AND_SECURITY, ObjectClassDisplay.get(ObjectClass._5631)],
    [FederalSupplyClass.MISCELLANEOUS_BATTERY_RETAINING_FIXTURES_LINERS, ObjectClassDisplay.get(ObjectClass._5631)],
    [FederalSupplyClass.MISCELLANEOUS_CHEMICAL_SPECIALTIES, ObjectClassDisplay.get(ObjectClass._5621)],
    [FederalSupplyClass.MISCELLANEOUS_COMMUNICATION_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.MISCELLANEOUS_CONSTRUCTION_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.MISCELLANEOUS_CONSTRUCTION_MATERIALS, ObjectClassDisplay.get(ObjectClass._5611)],
    [FederalSupplyClass.MISCELLANEOUS_CRUDE_AGRICULTURAL_AND_FORESTRY, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.MISCELLANEOUS_CRUDE_ANIMAL_PRODUCTS, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.MISCELLANEOUS_ELECTRIC_POWER_AND_DISTRIBUTION, ObjectClassDisplay.get(ObjectClass._5631)],
    [FederalSupplyClass.MISCELLANEOUS_ELECTRICAL_AND_ELECTRONIC_COMPONENT, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.MISCELLANEOUS_ENGINE_ACCESSORIES_NONAIRCRAFT, ObjectClassDisplay.get(ObjectClass._5631)],
    [FederalSupplyClass.MISCELLANEOUS_ENGINES_AND_COMPONENTS, ObjectClassDisplay.get(ObjectClass._5731)],
    [FederalSupplyClass.MISCELLANEOUS_FABRICATED_NONMETALLIC, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.MISCELLANEOUS_FABRICATED_STRUCTURES, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.MISCELLANEOUS_FIBER_OPTIC_COMPONENTS, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.MISCELLANEOUS_FIRE_CONTROL_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.MISCELLANEOUS_FURNITURE_AND_FIXTURES, ObjectClassDisplay.get(ObjectClass._5762)],
    [FederalSupplyClass.MISCELLANEOUS_HARDWARE, ObjectClassDisplay.get(ObjectClass._5691)],
    [FederalSupplyClass.MISCELLANEOUS_HOUSEHOLD_AND_COMMERCIAL, ObjectClassDisplay.get(ObjectClass._5742)],
    [FederalSupplyClass.MISCELLANEOUS_ITEMS, ObjectClassDisplay.get(ObjectClass.NO_OBJECT_CLASS_FOUND)],
    [FederalSupplyClass.MISCELLANEOUS_MACHINE_TOOLS, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.MISCELLANEOUS_MAINTENANCE_AND_REPAIR_SHOP, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.MISCELLANEOUS_MATERIALS_HANDLING_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.MISCELLANEOUS_OFFICE_MACHINES, ObjectClassDisplay.get(ObjectClass._5742)],
    [FederalSupplyClass.MISCELLANEOUS_PLUMBING_HEATING_AND_SANITAION, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.MISCELLANEOUS_POWER_TRANSMISSION_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.MISCELLANEOUS_PRINTED_MATTER, ObjectClassDisplay.get(ObjectClass._5742)],
    [FederalSupplyClass.MISCELLANEOUS_SECONDARY_METAL_FORMING, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.MISCELLANEOUS_SERVICE_AND_TRADE_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.MISCELLANEOUS_SPECIAL_INDUSTRY_MACHINERY, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.MISCELLANEOUS_VEHICULAR_COMPONENTS, ObjectClassDisplay.get(ObjectClass._5731)],
    [FederalSupplyClass.MISCELLANEOUS_WAXES_OILS_AND_FATS, ObjectClassDisplay.get(ObjectClass._5651)],
    [FederalSupplyClass.MISCELLANEOUS_WELDING_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.MISCELLANEOUS_WELDING_SOLDERING_AND_BRAZING, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.MOTOR_VEHICLE_MAINTENANCE_AND_REPAIR_SHOP, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.MOTORS_ELECTRICAL, ObjectClassDisplay.get(ObjectClass._5631)],
    [FederalSupplyClass.MUSICAL_INSTRUMENT_PARTS_AND_ACCESSORIES, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.MUSICAL_INSTRUMENTS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.NAILS_KEYS_AND_PINS, ObjectClassDisplay.get(ObjectClass._5691)],
    [FederalSupplyClass.NAVIGATIONAL_INSTRUMENTS, ObjectClassDisplay.get(ObjectClass._5711)],
    [FederalSupplyClass.NEWSPAPERS_AND_PERIODICALS, ObjectClassDisplay.get(ObjectClass._5742)],
    [FederalSupplyClass.NIGHT_VISION_EQUIPMENT_EMITTED_AND_REFLECTED, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.NONELECTRICAL_LIGHTING_FIXTURES, ObjectClassDisplay.get(ObjectClass._5631)],
    [FederalSupplyClass.NONFERROUS_BASE_METAL_REFINERY, ObjectClassDisplay.get(ObjectClass._5721)],
    [FederalSupplyClass.NONFERROUS_METAL_SCRAP, ObjectClassDisplay.get(ObjectClass._5721)],
    [FederalSupplyClass.NONMETALLIC_SCRAP_EXCEPT_TEXTILE, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.NOTIONS_AND_APPAREL_FINDINGS, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.NUTS_AND_WASHERS, ObjectClassDisplay.get(ObjectClass._5691)],
    [FederalSupplyClass.O_RINGS_RINGS_SHIMS_AND_SPACERS, ObjectClassDisplay.get(ObjectClass._5691)],
    [FederalSupplyClass.OFFICE_DEVICES_AND_ACCESSORIES, ObjectClassDisplay.get(ObjectClass._5741)],
    [FederalSupplyClass.OFFICE_FURNITURE, ObjectClassDisplay.get(ObjectClass._5762)],
    [FederalSupplyClass.OFFICE_INFORMATION_SYSTEM_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5742)],
    [FederalSupplyClass.OFFICE_SUPPLIES, ObjectClassDisplay.get(ObjectClass._5741)],
    [FederalSupplyClass.OFFICE_TYPE_SOUND_RECORDING_AND_REPRODUCING, ObjectClassDisplay.get(ObjectClass._5742)],
    [FederalSupplyClass.OILS_AND_GREASES_CUTTING_LUBRICATING, ObjectClassDisplay.get(ObjectClass._5651)],
    [FederalSupplyClass.OPERATION_TRAINING_DEVICES, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.OPHTHALMIC_INSTRUMENT_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.OPTICAL_INSTRUMENTS_TEST_EQUIPMENT_COMPONENTS, ObjectClassDisplay.get(ObjectClass._5711)],
    [FederalSupplyClass.OPTICAL_MULTIPLEXERS_DEMULTIPLEXERS, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.OPTICAL_REPEATERS, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.OPTICAL_SIGHTING_AND_RANGING_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.OPTOELECTRONIC_DEVICES_AND_ASSOCIATED_HARDWARE, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.ORES, ObjectClassDisplay.get(ObjectClass._5721)],
    [FederalSupplyClass.OSCILLATORS_AND_PIEZOELECTRIC_CRYSTALS, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.OTHER_CRYPTOLOGIC_EQUIPMENT_AND_COMPONENTS, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.OUTERWEAR_MEN_S, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.OUTERWEAR_WOMEN_S, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.PACKAGING_AND_PACKING_BULK_MATERIALS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.PACKING_AND_GASKET_MATERIALS, ObjectClassDisplay.get(ObjectClass._5691)],
    [FederalSupplyClass.PADDING_AND_STUFFING_MATERIALS, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.PAINT_AND_ARTISTS_BRUSHES, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.PAINTS_DOPES_VARNISHES_AND_RELATED_PRODUCTS, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.PAPER_AND_PAPERBOARD, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.PARACHUTES_AERIAL_PICK_UP_DELIVERY_RECOVERY, ObjectClassDisplay.get(ObjectClass._5731)],
    [FederalSupplyClass.PERFUMES_TOILET_PREPARATIONS_AND_POWDERS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.PERSONAL_TOILETRY_ARTICLES, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.PEST_CONTROL_AGENTS_AND_DISINFECTANTS, ObjectClassDisplay.get(ObjectClass._5621)],
    [FederalSupplyClass.PEST_DISEASE_AND_FROST_CONTROL_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.PETROLEUM_PRODUCTION_AND_DISTRIBUTION, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.PHONOGRAPH_RECORDS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.PHONOGRAPHS_RADIOS_AND_TELEVISION_SETS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.PHOTOGRAPHIC_DEVELOPING_AND_FINISHING, ObjectClassDisplay.get(ObjectClass._5751)],
    [FederalSupplyClass.PHOTOGRAPHIC_EQUIPMENT_AND_ACCESSORIES, ObjectClassDisplay.get(ObjectClass._5751)],
    [FederalSupplyClass.PHOTOGRAPHIC_PROJECTION_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5751)],
    [FederalSupplyClass.PHOTOGRAPHIC_SETS_KITS_AND_OUTFITS, ObjectClassDisplay.get(ObjectClass._5751)],
    [FederalSupplyClass.PHOTOGRAPHIC_SUPPLIES, ObjectClassDisplay.get(ObjectClass._5751)],
    [FederalSupplyClass.PHYSICAL_PROPERTIES_TESTING_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5711)],
    [FederalSupplyClass.PIPE_AND_CONDUIT_NONMETALLIC, ObjectClassDisplay.get(ObjectClass._5611)],
    [FederalSupplyClass.PIPE_AND_TUBE, ObjectClassDisplay.get(ObjectClass._5761)],
    [FederalSupplyClass.PLANERS_AND_SHAPERS, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.PLASTICS_FABRICATED_MATERIALS, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.PLATE_SHEET_STRIP_AND_FOIL_IRON_AND_STEEL, ObjectClassDisplay.get(ObjectClass._5721)],
    [FederalSupplyClass.PLATE_SHEET_STRIP_AND_FOIL, ObjectClassDisplay.get(ObjectClass._5721)],
    [FederalSupplyClass.PLATE_SHEET_STRIP_FOIL_AND_WIRE, ObjectClassDisplay.get(ObjectClass._5721)],
    [FederalSupplyClass.PLUMBING_FIXTURES_TOWELS_AND_ACCESSORIES, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.PLYWOOD_AND_VENEER, ObjectClassDisplay.get(ObjectClass._5611)],
    [FederalSupplyClass.POWER_AND_HAND_PUMPS, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.PRECIOUS_METALS_PRIMARY_FORMS, ObjectClassDisplay.get(ObjectClass._5721)],
    [FederalSupplyClass.PREFABRICATED_AND_PORTABLE_BUILDINGS, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.PREFABRICATED_TOWER_STRUCTURES, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.PRESERVATIVE_AND_SEALING_COMPOUNDS, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.PRESSURE_TEMPERATURE_AND_HUMIDITY_MEASURING, ObjectClassDisplay.get(ObjectClass._5711)],
    [FederalSupplyClass.PRINTING_DUPLICATING_AND_BOOKBINDING, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.PRODUCTION_JIGS_FIXTURES_AND_TEMPLATES, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.PULP_AND_PAPER_INDUSTRIES_MACHINERY, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.PUNCHED_CARD_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5765)],
    [FederalSupplyClass.PUNCHING_AND_SHEARING_MACHINES, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.PYROTECHNICS, ObjectClassDisplay.get(ObjectClass._5651)],
    [FederalSupplyClass.RADAR_EQUIPMENT_AIRBORNE, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.RADAR_EQUIPMENT_EXCEPT_AIRBORNE, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.RADIO_AND_TELEVISION_COMMUNICATION_AIRBORNE, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.RADIO_AND_TELEVISION_COMMUNICATION_EXCEPT, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.RADIO_NAVIGATION_EQUIPMENT_AIRBORNE, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.RADIO_NAVIGATION_EQUIPMENT_EXCEPT_AIRBORNE, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.RAILROAD_SIGNAL_AND_WARNING_DEVICES, ObjectClassDisplay.get(ObjectClass._5631)],
    [FederalSupplyClass.RECREATIONAL_AND_GYMNASTIC_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.REELS_AND_SPOOLS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.REFRACTORIES_AND_FIRE_SURFACING_MATERIALS, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.REFRIGERATION_AND_AIR_CONDITIONING_COMPONENTS, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.REFRIGERATION_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.RELAYS_AND_SOLENOIDS, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.REPLENISHABLE_FIELD_MEDICAL_SETS_KITS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.RESISTORS, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.RIGID_WALL_SHELTERS, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.RIVETING_MACHINES, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.RIVETS, ObjectClassDisplay.get(ObjectClass._5691)],
    [FederalSupplyClass.ROAD_CLEARING_AND_CLEANING_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.ROCKET_MAINTENANCE_REPAIR_AND_CHECKOUT, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.ROCKETS_ROCKET_AMMUNITION_AND_ROCKET_COMPONENTS, ObjectClassDisplay.get(ObjectClass._5651)],
    [FederalSupplyClass.ROLLING_MILLS_AND_DRAWING_MACHINES, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.ROOFING_AND_SIDING_MATERIALS, ObjectClassDisplay.get(ObjectClass._5611)],
    [FederalSupplyClass.ROTARY_JOINTS, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.RUBBER_AND_PLASTICS_WORKING_MACHINERY, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.RUBBER_FABRICATED_MATERIALS_CUSHIONS, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.SADDLERY_HARNESS_WHIPS_AND_RELATED_ANIMAL, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.SAFETY_AND_RESCUE_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.SAWMILL_AND_PLANING_MILL_MACHINERY, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.SAWS_AND_FILING_MACHINES, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.SCAFFOLDING_EQUIPMENT_AND_CONCRETE_FORMS, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.SCALES_AND_BALANCES, ObjectClassDisplay.get(ObjectClass._5711)],
    [FederalSupplyClass.SCREWS, ObjectClassDisplay.get(ObjectClass._5691)],
    [FederalSupplyClass.SEEDS_AND_NURSERY_STOCK, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.SEMICONDUCTOR_DEVICES_AND_ASSOCIATED_HARDWARE, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.SETS_KITS_AND_OUTFITS_FOOD_PREPARATION, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.SETS_KITS_AND_OUTFITS_OF_HAND_TOOLS, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.SETS_KITS_AND_OUTFITS_OF_MEASURING_TOOLS, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.SEWAGE_TREATMENT_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.SHEET_AND_BOOK_MUSIC, ObjectClassDisplay.get(ObjectClass._5742)],
    [FederalSupplyClass.SHIPBOARD_ALARM_AND_SIGNAL_SYSTEMS, ObjectClassDisplay.get(ObjectClass._5631)],
    [FederalSupplyClass.SHOE_FINDINGS_AND_SOLING_MATERIALS, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.SHOE_REPAIRING_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.SIGNS_ADVERTISING_DISPLAYS_AND_IDENTIFICATION, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.SMALL_CRAFT_POWERED_AND_UNPOWERED_SMALL_CRAFT, ObjectClassDisplay.get(ObjectClass._5731)],
    [FederalSupplyClass.SMOKERS_ARTICLES_AND_MATCHES, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.SOIL_PREPARATION_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.SOLAR_ELECTRIC_POWER_SYSTEMS, ObjectClassDisplay.get(ObjectClass._5631)],
    [FederalSupplyClass.SOUND_RECORDING_AND_REPRODUCING_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.SOUPS_AND_BOUILLONS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.SPACE_HEATING_EQUIPMENT_AND_DOMESTIC, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.SPACE_SURVIVAL_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5731)],
    [FederalSupplyClass.SPACE_VEHICLE_COMPONENTS, ObjectClassDisplay.get(ObjectClass._5731)],
    [FederalSupplyClass.SPACE_VEHICLE_HANDLING_AND_SERVICING_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5731)],
    [FederalSupplyClass.SPACE_VEHICLE_MAINTENANCE_REPAIR_AND_CHECKOUT, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.SPECIAL_DIETARY_FOODS_AND_FOOD_SPECIALTY, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.SPECIALIZED_AMMUNITION_AND_ORDNANCE_MACHINERY, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.SPECIALIZED_FLIGHT_CLOTHING_ACCESSORIES, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.SPECIALIZED_METAL_CONTAINER_MANUFACTURING, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.SPECIALIZED_SEMICONDUCTOR_MICROCIRCUIT, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.SPECIALIZED_SHIPPING_AND_STORAGE_CONTAINERS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.SPECIALIZED_TEST_AND_HANDLING_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.STANDARD_FORMS, ObjectClassDisplay.get(ObjectClass._5741)],
    [FederalSupplyClass.STATIONERY_AND_RECORD_FORMS, ObjectClassDisplay.get(ObjectClass._5741)],
    [FederalSupplyClass.STIMULATED_COHERENT_RADIATION_DEVICES_COMPONENTS, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.STORAGE_TANKS, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.STRUCTURAL_SHAPES_IRON_AND_STEEL, ObjectClassDisplay.get(ObjectClass._5721)],
    [FederalSupplyClass.STRUCTURAL_SHAPES_NONFERROUS_BASE_METAL, ObjectClassDisplay.get(ObjectClass._5721)],
    [FederalSupplyClass.STUDS, ObjectClassDisplay.get(ObjectClass._5691)],
    [FederalSupplyClass.SUGAR_CONFECTIONERY_AND_NUTS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.SURGICAL_DRESSING_MATERIALS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.SWITCHES, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.SYNCHROS_AND_RESOLVERS, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.TABLEWARE, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.TAPS_DIES_AND_COLLETS_HAND_AND_MACHINE, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.TELEPHONE_AND_TELEGRAPH_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.TELETYPE_AND_FACSIMILE_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.TENTS_AND_TARPAULILNS, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.TEXTILE_FABRICS, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.TEXTILE_INDUSTRIES_MACHINERY, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.TIME_MEASURING_INSTRUMENTS, ObjectClassDisplay.get(ObjectClass._5711)],
    [FederalSupplyClass.TIRES_SOLID_AND_CUSHION, ObjectClassDisplay.get(ObjectClass._5731)],
    [FederalSupplyClass.TOBACCO_MANUFACTURING_MACHINERY, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.TOBACCO_PRODUCTS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.TOILET_SOAP_SHAVING_PREPARATIONS_DENTIFRICES, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.TOILETRY_PAPER_PRODUCTS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.TOOL_AND_HARDWARE_BOXES, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.TOOLS_AND_ATTACHMENTS_FOR_WOODWORKING_MACHINERY, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.TORPEDO_MAINTENANCE_REPAIR_AND_CHECKOUT, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.TRAFFIC_AND_TRANSIT_SIGNAL_SYSTEMS, ObjectClassDisplay.get(ObjectClass._5631)],
    [FederalSupplyClass.TRAINING_AIDS, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.TRANSFORMERS_DISTRIBUTION_AND_POWER_STATION, ObjectClassDisplay.get(ObjectClass._5631)],
    [FederalSupplyClass.TRUCK_AND_TRACTOR_ATTACHMENTS, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.TRUCKS_TRUCK_TRACTORS_WHEELED, ObjectClassDisplay.get(ObjectClass._5731)],
    [FederalSupplyClass.TURBOSUPERCHARGERS, ObjectClassDisplay.get(ObjectClass._5731)],
    [FederalSupplyClass.TYPEWRITERS_AND_OFFICE_TYPE_COMPOSING, ObjectClassDisplay.get(ObjectClass._5742)],
    [FederalSupplyClass.UNDERWATER_SOUND_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.UNDERWEAR_AND_NIGHTWEAR_MEN_S, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.UNDERWEAR_AND_NIGHTWEAR_WOMEN_S, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.UNKNOWN, DEFAULT_DASH],
    [FederalSupplyClass.VALVES_NONPOWERED, ObjectClassDisplay.get(ObjectClass._5761)],
    [FederalSupplyClass.VALVES_POWERED, ObjectClassDisplay.get(ObjectClass._5761)],
    [FederalSupplyClass.VENDING_AND_COIN_OPERATED_MACHINES, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.VIDEO_RECORDING_AND_REPRODUCING_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.VISIBLE_AND_INVISIBLE_LIGHT_COMMUNICATION, ObjectClassDisplay.get(ObjectClass._5641)],
    [FederalSupplyClass.VISIBLE_RECORD_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5742)],
    [FederalSupplyClass.WALLBOARD_BUILDING_PAPER_AND_THERMAL, ObjectClassDisplay.get(ObjectClass._5611)],
    [FederalSupplyClass.WAREHOUSE_TRUCKS_AND_TRACTORS_SELFPROPELLED, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.WATER_DISTILLATION_EQUIPMENT_MARINE, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.WATER_PURIFICATION_EQUIPMENT, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.WEAPONS_MAINTENANCE_AND_REPAIR_SHOP, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.WELDING_POSITIONERS_AND_MANIPULATORS, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.WINCHES_HOISTS_CRANES_AND_DERRICKS, ObjectClassDisplay.get(ObjectClass._5661)],
    [FederalSupplyClass.WIRE_AND_CABLE_ELECTRICAL, ObjectClassDisplay.get(ObjectClass._5631)],
    [FederalSupplyClass.WIRE_AND_METAL_RIBBON_FORMING_MACHINES, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.WIRE_NONELECTRICAL_IRON_AND_STEEL, ObjectClassDisplay.get(ObjectClass._5721)],
    [FederalSupplyClass.WIRE_NONELECTRICAL_NONFERROUS_BASE_METAL, ObjectClassDisplay.get(ObjectClass._5721)],
    [FederalSupplyClass.WOODWORKING_MACHINES, ObjectClassDisplay.get(ObjectClass._5671)],
    [FederalSupplyClass.WRAPPING_AND_PACKAGING_MACHINERY, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.X_RAY_EQUIPMENT_AND_SUPPLIES_MEDICAL_DENTAL, ObjectClassDisplay.get(ObjectClass._5681)],
    [FederalSupplyClass.YARN_AND_THREAD, ObjectClassDisplay.get(ObjectClass._5671)]
]);
