import { RiskHardwareType, RiskReportability } from '~/db_types/swagger_types';
export const RiskHardwareTypeDisplay = new Map([
    [RiskHardwareType.EMU, 'EMU'],
    [RiskHardwareType.TOOLS, 'Tools']
]);
export const RiskReportabilityDisplay = new Map([
    [RiskReportability.ALWAYS, 'Always'],
    [RiskReportability.DEFAULT, 'Default'],
    [RiskReportability.NEVER, 'Never']
]);
export var RiskNasaNotificationType;
(function (RiskNasaNotificationType) {
    RiskNasaNotificationType["AWARD_FEE"] = "AWARD_FEE";
    RiskNasaNotificationType["REQUESTED"] = "REQUESTED";
    RiskNasaNotificationType["SPRT_OR_BOARD_ACTION"] = "SPRT_OR_BOARD_ACTION";
})(RiskNasaNotificationType || (RiskNasaNotificationType = {}));
export const RiskNasaNotificationTypeDisplay = new Map([
    [RiskNasaNotificationType.AWARD_FEE, 'Award fee'],
    [RiskNasaNotificationType.REQUESTED, 'Requested'],
    [RiskNasaNotificationType.SPRT_OR_BOARD_ACTION, 'SPRT / Board action'] // SubSystem Problem Resolution Team
]);
