import { ActivityType, PressurizationRange } from '~/db_types';
export const ActivityTypeDisplay = new Map([
    [ActivityType.AUTOCYCLE, 'Autocycle'],
    [ActivityType.BATTERY_MAINTENANCE, 'Battery maintenance'],
    [ActivityType.CALIBRATION, 'Calibration'],
    [ActivityType.CHARGE, 'Charge'],
    [ActivityType.CHECK_OUT, 'Check-out'],
    [ActivityType.CYCLE, 'Cycle'],
    [ActivityType.DISCHARGE, 'Discharge'],
    [ActivityType.DRY_OUT, 'Dry Out'],
    [ActivityType.DUMP, 'Dump'],
    [ActivityType.EMU_H20_DUMP_AND_FILL, 'EMU water dump and fill'],
    [ActivityType.EMU_H20_RECHARGE, 'EMU water recharge'],
    [ActivityType.EVA_ACTIVITY, 'EVA'],
    [ActivityType.FILL, 'Fill'],
    [ActivityType.FLIGHT, 'Flight'],
    [ActivityType.GMIP, 'GMIP'],
    [ActivityType.GREASE_AND_CIRCULATE, 'Grease/Circulate'],
    [ActivityType.GSE_CALIBRATION, 'GSE calibration'],
    [ActivityType.INSPECTION, 'Inspection'],
    [ActivityType.INTERVAL, 'Interval'],
    [ActivityType.IODINATE_LOOP_SCRUB, 'Iodinate loop scrub'],
    [ActivityType.ITEM_146_CYCLE, 'Item 146 cycle'],
    [ActivityType.MAINTENANCE, 'Maintenance'],
    [ActivityType.MIP, 'MIP'],
    [ActivityType.O2_POPPET_VERIFICATION, 'O2 poppet verification'],
    [ActivityType.OPERATION, 'Operation'],
    [ActivityType.OTHER, 'Other'],
    [ActivityType.RECEIVING_INSPECTION, 'Receiving inspection'],
    [ActivityType.PRESSURIZATION, 'Pressurization'],
    [ActivityType.REGENERATION, 'Regeneration'],
    [ActivityType.RESIZE_OR_DRY_RUN, 'Resize/Dry run'],
    [ActivityType.SERVICE, 'Service'],
    [ActivityType.TAK_DATA_COLLECTION, 'Tak Data collection'],
    [ActivityType.TEN_POINT_TWO_OPS, '10.2 Ops'],
    [ActivityType.TEST, 'Test'],
    [ActivityType.TETHER_INSPECTION, 'Tether inspection'],
    [ActivityType.WETTING_OR_IODINATING, 'Wetting/Iodinating'],
    [ActivityType.WLVTA_REPLACEMENT, 'WLVTA replacement'],
    [ActivityType.VENT, 'Vent']
]);
export var AnalysisResult;
(function (AnalysisResult) {
    AnalysisResult["PASS"] = "PASS";
    AnalysisResult["FAIL"] = "FAIL";
    AnalysisResult["UNKNOWN"] = "UNKNOWN";
})(AnalysisResult || (AnalysisResult = {}));
export const AnalysisResultDisplay = new Map([
    [AnalysisResult.FAIL, 'Fail'],
    [AnalysisResult.PASS, 'Pass'],
    [AnalysisResult.UNKNOWN, 'Unknown']
]);
export const PressurizationRangeDisplay = new Map([
    [PressurizationRange.ZERO_POINT_TWO_TO_ONE_POINT_FOUR_ONE_PSI, '0.2 to 1.41 PSI'],
    [PressurizationRange.ONE_POINT_FOUR_ONE_TO_FIVE_POINT_THREE_PSI, '1.41 to 5.3 PSI'],
    [PressurizationRange.FIVE_POINT_THREE_ONE_PLUS_PSI, '5.31+ PSI']
]);
export var AutocycleLocation;
(function (AutocycleLocation) {
    AutocycleLocation["TWENTY_EIGHT_VOLT_PSA"] = "TWENTY_EIGHT_VOLT_PSA";
    AutocycleLocation["ONE_TWENTY_VOLT"] = "ONE_TWENTY_VOLT";
    AutocycleLocation["OTHER"] = "OTHER";
})(AutocycleLocation || (AutocycleLocation = {}));
export const AutocycleLocationDisplay = new Map([
    [AutocycleLocation.TWENTY_EIGHT_VOLT_PSA, '28 Volt-PSA'],
    [AutocycleLocation.ONE_TWENTY_VOLT, '120 Volt'],
    [AutocycleLocation.OTHER, 'Other']
]);
export var Channel;
(function (Channel) {
    Channel["A"] = "A";
    Channel["B"] = "B";
})(Channel || (Channel = {}));
export const ChannelDisplay = new Map([
    [Channel.A, 'A'],
    [Channel.B, 'B']
]);
export var CheckOutActivityHardwareType;
(function (CheckOutActivityHardwareType) {
    CheckOutActivityHardwareType["EMU"] = "EMU";
    CheckOutActivityHardwareType["EMU_ANNUAL"] = "EMU_ANNUAL";
    CheckOutActivityHardwareType["PGT"] = "PGT";
    CheckOutActivityHardwareType["SAFER"] = "SAFER";
    CheckOutActivityHardwareType["SAFER_TM"] = "SAFER_TM";
    CheckOutActivityHardwareType["SCOF"] = "SCOF";
    CheckOutActivityHardwareType["SOP"] = "SOP";
})(CheckOutActivityHardwareType || (CheckOutActivityHardwareType = {}));
export const CheckOutActivityHardwareTypeDisplay = new Map([
    [CheckOutActivityHardwareType.EMU_ANNUAL, 'EMU Annual C/O'],
    [CheckOutActivityHardwareType.EMU, 'EMU C/O'],
    [CheckOutActivityHardwareType.PGT, 'PGT C/O'],
    [CheckOutActivityHardwareType.SAFER_TM, 'SAFER TM C/O'],
    [CheckOutActivityHardwareType.SAFER, 'SAFER C/O'],
    [CheckOutActivityHardwareType.SCOF, 'SCOF Annual C/O'],
    [CheckOutActivityHardwareType.SOP, 'SOP C/O']
]);
export var ScuType;
(function (ScuType) {
    ScuType["NONE"] = "NONE";
    ScuType["SCU1"] = "SCU1";
    ScuType["SCU2"] = "SCU2";
})(ScuType || (ScuType = {}));
export const ScuTypeDisplay = new Map([
    [ScuType.NONE, 'none'],
    [ScuType.SCU1, 'SCU 1'],
    [ScuType.SCU2, 'SCU 2']
]);
export var WaterDumpType;
(function (WaterDumpType) {
    WaterDumpType["NOT_APPLICABLE"] = "NOT_APPLICABLE";
    WaterDumpType["FULL"] = "FULL";
    WaterDumpType["PARTIAL"] = "PARTIAL";
})(WaterDumpType || (WaterDumpType = {}));
export const WaterDumpTypeDisplay = new Map([
    [WaterDumpType.NOT_APPLICABLE, 'N/A'],
    [WaterDumpType.FULL, 'Full'],
    [WaterDumpType.PARTIAL, 'Partial']
]);
export var PgtStatus;
(function (PgtStatus) {
    PgtStatus["GO_FOR_EVA"] = "GO_FOR_EVA";
    PgtStatus["BACKUP_USE_ONLY"] = "BACKUP_USE_ONLY";
    PgtStatus["NO_GO_FOR_EVA"] = "NO_GO_FOR_EVA";
})(PgtStatus || (PgtStatus = {}));
export const PgtStatusDisplay = new Map([
    [PgtStatus.GO_FOR_EVA, 'Go for EVA'],
    [PgtStatus.BACKUP_USE_ONLY, 'Backup use only'],
    [PgtStatus.NO_GO_FOR_EVA, 'No Go for EVA']
]);
export var TetherStatus;
(function (TetherStatus) {
    TetherStatus["GO_FOR_EVA"] = "GO_FOR_EVA";
    TetherStatus["BACKUP_USE_ONLY"] = "BACKUP_USE_ONLY";
    TetherStatus["NO_GO_FOR_EVA"] = "NO_GO_FOR_EVA";
    TetherStatus["PENDING_ANALYSIS"] = "PENDING_ANALYSIS";
    TetherStatus["REQUIRES_INSPECTION"] = "REQUIRES_INSPECTION";
})(TetherStatus || (TetherStatus = {}));
export const TetherStatusDisplay = new Map([
    [TetherStatus.GO_FOR_EVA, 'Go for EVA'],
    [TetherStatus.BACKUP_USE_ONLY, 'Backup use only'],
    [TetherStatus.NO_GO_FOR_EVA, 'No Go for EVA'],
    [TetherStatus.PENDING_ANALYSIS, 'Pending analysis'],
    [TetherStatus.REQUIRES_INSPECTION, 'Requires inspection']
]);
export var EvaActivityHardwareType;
(function (EvaActivityHardwareType) {
    EvaActivityHardwareType["EMU"] = "EMU";
    EvaActivityHardwareType["EMU_BATTERY_LLB"] = "EMU_BATTERY_LLB";
    EvaActivityHardwareType["HL_BATTERY"] = "HL_BATTERY";
    EvaActivityHardwareType["IEU_SCU"] = "IEU_SCU";
    EvaActivityHardwareType["LPGT"] = "LPGT";
    EvaActivityHardwareType["LREBA"] = "LREBA";
    EvaActivityHardwareType["METOX"] = "METOX";
    EvaActivityHardwareType["PGT"] = "PGT";
    EvaActivityHardwareType["PGT_BATTERY"] = "PGT_BATTERY";
    EvaActivityHardwareType["REBA"] = "REBA";
    EvaActivityHardwareType["SAFER"] = "SAFER";
    EvaActivityHardwareType["TETHER"] = "TETHER";
})(EvaActivityHardwareType || (EvaActivityHardwareType = {}));
export const EvaActivityHardwareTypeDisplay = new Map([
    [EvaActivityHardwareType.EMU, 'EMU'],
    [EvaActivityHardwareType.EMU_BATTERY_LLB, 'EMU Battery LLB'],
    [EvaActivityHardwareType.HL_BATTERY, 'HL Battery'],
    [EvaActivityHardwareType.IEU_SCU, 'IEU / SCU'],
    [EvaActivityHardwareType.LPGT, 'LPGT'],
    [EvaActivityHardwareType.LREBA, 'LREBA'],
    [EvaActivityHardwareType.METOX, 'METOX'],
    [EvaActivityHardwareType.PGT_BATTERY, 'PGT Battery'],
    [EvaActivityHardwareType.PGT, 'PGT'],
    [EvaActivityHardwareType.REBA, 'REBA'],
    [EvaActivityHardwareType.SAFER, 'SAFER'],
    [EvaActivityHardwareType.TETHER, 'TETHER']
]);
export var MetoxStatus;
(function (MetoxStatus) {
    MetoxStatus["REGENERATED"] = "REGENERATED";
    MetoxStatus["FIFTY_PERCENT_EXPENDED"] = "FIFTY_PERCENT_EXPENDED";
    MetoxStatus["TWENTY_FIVE_PERCENT_EXPENDED"] = "TWENTY_FIVE_PERCENT_EXPENDED";
    MetoxStatus["SEVENTY_FIVE_PERCENT_EXPENDED"] = "SEVENTY_FIVE_PERCENT_EXPENDED";
    MetoxStatus["EXPENDED"] = "EXPENDED";
})(MetoxStatus || (MetoxStatus = {}));
export const MetoxStatusDisplay = new Map([
    [MetoxStatus.REGENERATED, 'Regenerated'],
    [MetoxStatus.FIFTY_PERCENT_EXPENDED, '50% Expended'],
    [MetoxStatus.TWENTY_FIVE_PERCENT_EXPENDED, '25% Expended'],
    [MetoxStatus.SEVENTY_FIVE_PERCENT_EXPENDED, '75% Expended'],
    [MetoxStatus.EXPENDED, 'Expended']
]);
export var PgtEvaRole;
(function (PgtEvaRole) {
    PgtEvaRole["EV1"] = "EV1";
    PgtEvaRole["EV2"] = "EV2";
    PgtEvaRole["AIRLOCK"] = "AIRLOCK";
})(PgtEvaRole || (PgtEvaRole = {}));
export const PgtEvaRoleDisplay = new Map([
    [PgtEvaRole.EV1, 'EV1'],
    [PgtEvaRole.EV2, 'EV2'],
    [PgtEvaRole.AIRLOCK, 'Airlock']
]);
export var IodinateLoopScrubActivityHardwareType;
(function (IodinateLoopScrubActivityHardwareType) {
    IodinateLoopScrubActivityHardwareType["EMU"] = "EMU";
    IodinateLoopScrubActivityHardwareType["EMU_BIOCIDE_FILTER"] = "EMU_BIOCIDE_FILTER";
    IodinateLoopScrubActivityHardwareType["EMU_ION_FILTER"] = "EMU_ION_FILTER";
    IodinateLoopScrubActivityHardwareType["EMU_MICRON_FILTER"] = "EMU_MICRON_FILTER";
    IodinateLoopScrubActivityHardwareType["EMU_SCRUB_ASSEMBLY"] = "EMU_SCRUB_ASSEMBLY";
    IodinateLoopScrubActivityHardwareType["EMU_WATER_PROCESSING_JUMPER"] = "EMU_WATER_PROCESSING_JUMPER";
    IodinateLoopScrubActivityHardwareType["IEU_SCU"] = "IEU_SCU";
    IodinateLoopScrubActivityHardwareType["LCVG"] = "LCVG";
})(IodinateLoopScrubActivityHardwareType || (IodinateLoopScrubActivityHardwareType = {}));
export const IodinateLoopScrubActivityHardwareTypeDisplay = new Map([
    [IodinateLoopScrubActivityHardwareType.EMU, 'EMU'],
    [IodinateLoopScrubActivityHardwareType.EMU_ION_FILTER, 'EMU Ion Filter'],
    [IodinateLoopScrubActivityHardwareType.EMU_MICRON_FILTER, 'EMU Micron Filter'],
    [IodinateLoopScrubActivityHardwareType.EMU_BIOCIDE_FILTER, 'EMU Biocide Filter'],
    [IodinateLoopScrubActivityHardwareType.LCVG, 'LCVG'],
    [IodinateLoopScrubActivityHardwareType.EMU_SCRUB_ASSEMBLY, 'EMU Scrub Assembly'],
    [IodinateLoopScrubActivityHardwareType.EMU_WATER_PROCESSING_JUMPER, 'EMU Water Processing Jumper'],
    [IodinateLoopScrubActivityHardwareType.IEU_SCU, 'IEU / SCU']
]);
export var TestType;
(function (TestType) {
    TestType["DCM_MWC_FLOW_TEST"] = "DCM_MWC_FLOW_TEST";
    TestType["SUBLIMATOR_LEAK_TEST"] = "SUBLIMATOR_LEAK_TEST";
    TestType["OTHER"] = "OTHER";
})(TestType || (TestType = {}));
export const TestTypeDisplay = new Map([
    [TestType.DCM_MWC_FLOW_TEST, 'DCM MWC Flow Test'],
    [TestType.SUBLIMATOR_LEAK_TEST, 'Sublimator Leak Test'],
    [TestType.OTHER, 'Other']
]);
export var OtherActivityHardwareType;
(function (OtherActivityHardwareType) {
    OtherActivityHardwareType["HL_BATTERY"] = "HL_BATTERY";
    OtherActivityHardwareType["EMU"] = "EMU";
    OtherActivityHardwareType["REBA"] = "REBA";
    OtherActivityHardwareType["METOX"] = "METOX";
    OtherActivityHardwareType["PGT_BATTERY"] = "PGT_BATTERY";
})(OtherActivityHardwareType || (OtherActivityHardwareType = {}));
export const OtherActivityHardwareTypeDisplay = new Map([
    [OtherActivityHardwareType.HL_BATTERY, 'HL Battery'],
    [OtherActivityHardwareType.EMU, 'EMU'],
    [OtherActivityHardwareType.PGT_BATTERY, 'PGT Battery'],
    [OtherActivityHardwareType.REBA, 'REBA'],
    [OtherActivityHardwareType.METOX, 'METOX']
]);
// EMU_WATER_RECHARGE
// EMU_WATER_DUMP_AND_FILL
export var EmuWaterActivityHardwareType;
(function (EmuWaterActivityHardwareType) {
    EmuWaterActivityHardwareType["EMU"] = "EMU";
    EmuWaterActivityHardwareType["CWC_I"] = "CWC_I";
    EmuWaterActivityHardwareType["DUMP_CWC"] = "DUMP_CWC";
    EmuWaterActivityHardwareType["PWR"] = "PWR";
    EmuWaterActivityHardwareType["UIA_BIOCIDE_FILTER"] = "UIA_BIOCIDE_FILTER";
})(EmuWaterActivityHardwareType || (EmuWaterActivityHardwareType = {}));
export const EmuWaterActivityHardwareTypeDisplay = new Map([
    [EmuWaterActivityHardwareType.CWC_I, 'CWC-I'],
    [EmuWaterActivityHardwareType.EMU, 'EMU'],
    [EmuWaterActivityHardwareType.DUMP_CWC, 'DUMP CWC'],
    [EmuWaterActivityHardwareType.PWR, 'PWR'],
    [EmuWaterActivityHardwareType.UIA_BIOCIDE_FILTER, 'UIA Biocide Filter']
]);
