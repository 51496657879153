import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins } from 'vue-property-decorator';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import AutocompleteMixin from '~/nasa_ui/mixins/AutocompleteMixin';
let AppSelectAsBuilt = class AppSelectAsBuilt extends Mixins(AutocompleteMixin, BaseCosmic) {
    async search(q) {
        const query = gql `
      query GetAsBuiltForAutoComplete($startsWith: String!) {
        autocompleteAsBuiltNumber(asBuiltNumberStartsWith: $startsWith)
      }
    `;
        const resp = await this.$apollo.query({
            query,
            variables: { startsWith: q }
        });
        return resp.data.autocompleteAsBuiltNumber?.map((ab) => ({ displayText: ab, value: ab })) ?? [];
    }
};
AppSelectAsBuilt = __decorate([
    Component
], AppSelectAsBuilt);
export default AppSelectAsBuilt;
