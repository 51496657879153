import { getBlurbAboutCreate, getBlurbAboutManage, getBlurbAboutSearch } from '~/nasa_ui/router/utils';
import { EntityType, EntityTypeSingularDisplay, UserPermission } from '~/nasa_ui/types';
export const OrganizationSharedRoutes = [
    {
        path: `/${EntityType.ORGANIZATION}`,
        redirect: {
            name: 'OrganizationSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.ORGANIZATION)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.ORGANIZATION)),
            permissions: [UserPermission.ORGANIZATION_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.ORGANIZATION}/manage`,
        redirect: {
            name: 'OrganizationSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.ORGANIZATION)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.ORGANIZATION)),
            permissions: [UserPermission.ORGANIZATION_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.ORGANIZATION}/manage/:code`,
        name: 'OrganizationManageView',
        component: () => import(/* webpackChunkName: "Organization" */ '../views/OrganizationManageView/OrganizationManageView.vue'),
        meta: {
            displayName: 'Organizations manage',
            description: getBlurbAboutManage(EntityTypeSingularDisplay.get(EntityType.ORGANIZATION)),
            permissions: [UserPermission.ORGANIZATION_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.ORGANIZATION}/search`,
        name: 'OrganizationSearchView',
        component: () => import(/* webpackChunkName: "Organization" */ '../views/OrganizationSearchView/OrganizationSearchView.vue'),
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.ORGANIZATION)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.ORGANIZATION)),
            permissions: [UserPermission.ORGANIZATION_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.ORGANIZATION}/create`,
        name: 'OrganizationCreateView',
        component: () => import(/* webpackChunkName: "Organization" */ '../views/OrganizationCreateView/OrganizationCreateView.vue'),
        meta: {
            displayName: 'Organizations create',
            description: getBlurbAboutCreate(EntityTypeSingularDisplay.get(EntityType.ORGANIZATION)),
            permissions: [UserPermission.ORGANIZATION_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    }
];
