import gql from 'graphql-tag';
export const CosmicIntervalFragment = gql `
  fragment CosmicInterval on Interval {
    seconds
    minutes
    hours
    days
    months
    years
  }
`;
