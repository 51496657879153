var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import BaseInput from '~/nasa_ui/base/BaseInput';
import { isNullOrUndefined } from '~/nasa_ui/utils';
import { isDateOnlyDateString, parseDateIntoCentral } from '~/nasa_ui/utils/dates';
let AppDate = class AppDate extends BaseInput {
    date = null;
    day = null;
    month = null;
    showInformationalText = false; // Used to toggle the blue info text
    year = null;
    datePickerCanInit = false;
    informationText;
    fatLabel;
    get computedErrorMessage() {
        if (Array.isArray(this.errorMessages) && this.errorMessages.length) {
            return this.errorMessages[0];
        }
        // if required and no value, show required message
        if (this.required && this.$get(this, 'value', null) === null) {
            return '* Required';
        }
        if (this.required && this.$get(this, 'value', undefined) === undefined) {
            return '* Required';
        }
        if (this.required && this.$get(this, 'value', '') === '') {
            return '* Required';
        }
        // show messages based on rules
        // @ts-ignore
        return this.localRulesBaseOnProps.filter((_) => typeof _ === 'string')[0];
    }
    // Regular textual messages
    get computedMessage() {
        if (this.messages?.length) {
            return this.messages[0];
        }
        return '';
    }
    get isYearValid() {
        return !!(this.year && this.year.length === 4 && parseInt(this.year) > 1900);
    }
    get isMonthValid() {
        return !!(this.month && this.month.length === 2 && this.month !== '00' && parseInt(this.month) <= 12);
    }
    get isDayValid() {
        return !!(this.day && this.day.length === 2 && this.day !== '00' && parseInt(this.day) <= 31);
    }
    get isInputsEmpty() {
        return Boolean(!this.year && !this.month && !this.day);
    }
    get isInputsValid() {
        // if all of the inputs are empty and this input is not required, consider the whole thing valid
        if (!this.required && this.isInputsEmpty) {
            return true;
        }
        return this.isYearValid && this.isMonthValid && this.isDayValid && this.errorMessages.length === 0;
    }
    get paddedMonth() {
        return this.month && this.month.length === 1 ? `0${this.month}` : this.month;
    }
    /**
     * Show if value == null/undefined AND every field is individually not valid
     */
    get shouldShowNoDateEnteredHelperIcon() {
        return !this.year && !this.month && !this.day;
    }
    /**
     * Show if at least one value is filled out and at least one input is invalid
     */
    get shouldShowInvalidDateHelperIcon() {
        return !this.isInputsEmpty && (!this.isYearValid || !this.isMonthValid || !this.isDayValid);
    }
    /**
     * Show if value != null/undefined AND everything is valid
     */
    get shouldShowValidDateHelperIcon() {
        return !isNullOrUndefined(this.value) && this.isInputsValid;
    }
    clearInput(inputName) {
        this[inputName] = null;
    }
    clearInputs() {
        const inputs = ['year', 'month', 'day'];
        inputs.forEach((inputName) => (this[inputName] = null));
    }
    formatInputWithTwoDigits(val) {
        return val !== undefined && val !== null && val.length === 1 ? `0${val}` : `${val}`;
    }
    mounted() {
        this.datePickerCanInit = true;
    }
    onDatePickerSelection(val) {
        if (val) {
            const [year, month, day] = val.split('-');
            this.year = year;
            this.month = month;
            this.day = day;
        }
    }
    onDblClickOfDatePicker() {
        if (!this.disabled) {
            this.updateDate(this.$dateDisplay(new Date()));
        }
    }
    onInputDay(event) {
        if (event.detail !== '' && !isNullOrUndefined(event.detail)) {
            this.day = event.detail.toString().padStart(2, '0');
        }
        else {
            this.day = null;
        }
    }
    onInputMonth(event) {
        if (event.detail !== '' && !isNullOrUndefined(event.detail)) {
            this.month = event.detail.toString().padStart(2, '0');
        }
        else {
            this.month = null;
        }
    }
    padMonth() {
        this.month = this.month && this.month.length === 1 && this.month !== '0' ? `0${this.month}` : this.month;
    }
    padDay() {
        this.day = this.day && this.day.length === 1 ? `0${this.day}` : this.day;
    }
    emitKeydown() { }
    emitInput(val) {
        const dateString = `${this.year}-${this.paddedMonth}-${this.day}`;
        // if the inputs are valid and are not empty, emit a date string. otherwise emit null
        return this.isInputsValid && !this.isInputsEmpty ? dateString : null;
    }
    updateDate(val) {
        // if it's already yyyy-mm-dd format, parse it directly
        if (typeof val === 'string' && isDateOnlyDateString(val)) {
            this.year = val.substring(0, 4);
            this.month = this.formatInputWithTwoDigits(val.substring(5, 7));
            this.day = this.formatInputWithTwoDigits(val.substring(8, 10));
        }
        else if (val) {
            if (val.toString().endsWith('T00:00:00Z') || val.toString().endsWith('T00:00:00+00:00')) {
                val = val.toString().replace('T00:00:00Z', 'T12:00:00Z').replace('T00:00:00+00:00', 'T12:00:00+00:00');
            }
            // if it includes a time, handle it with date conversion utils
            const date = val instanceof Object ? parseDateIntoCentral(val.toString()) : parseDateIntoCentral(val);
            if (date) {
                this.year = date.year + '';
                this.month = this.formatInputWithTwoDigits(date.month + 1 + '');
                this.day = this.formatInputWithTwoDigits(date.day + '');
            }
        }
        // clear the inputs when passed a value of null and not typing in the inputs
        if (val === null) {
            if (this.shouldShowInvalidDateHelperIcon) {
                // don't clear when typing in the inputs and its invalid
            }
            else {
                // clear inputs when passed null
                this.clearInputs();
            }
        }
    }
    emitError(isValid) {
        return !isValid;
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppDate.prototype, "informationText", void 0);
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], AppDate.prototype, "fatLabel", void 0);
__decorate([
    Emit('keydown'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppDate.prototype, "emitKeydown", null);
__decorate([
    Watch('month'),
    Watch('day'),
    Watch('year'),
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AppDate.prototype, "emitInput", null);
__decorate([
    Watch('value', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AppDate.prototype, "updateDate", null);
__decorate([
    Watch('isInputsValid'),
    Emit('error'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], AppDate.prototype, "emitError", null);
AppDate = __decorate([
    Component
], AppDate);
export default AppDate;
