import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Debounce } from 'lodash-decorators';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { AlertType } from '~/nasa_ui/types';
import { DEFAULT_DASH } from '../constants';
import { AS_BUILT_COL, BASE_ICON_COL, DESCRIPTION_COL, DRAWING_NUMBER_COL, SIDE_COL } from '../constants/genericColumnDefinitions';
import { DEFAULT_MODAL_ROWS_PER_PAGE_ITEMS } from '../constants/tablePagination';
import { composeTableHeader } from '../utils/helpers/composeTableHeader';
let BaseModalHardwareItemTemplate = class BaseModalHardwareItemTemplate extends BaseModal {
    duplicateQueryResponse = null;
    parentData = null;
    parentName = DEFAULT_DASH;
    parentSequence = 0;
    selectedItemDrawings = [];
    formData = {
        maxQuantity: 1,
        minQuantity: 1,
        name: null,
        sequence: null
    };
    hardwareItemTemplatePartTableHeaders = [
        composeTableHeader(BASE_ICON_COL, {
            text: 'Hardware<br/>level',
            value: '_entityType',
            width: '50px'
        }),
        DRAWING_NUMBER_COL,
        DESCRIPTION_COL,
        AS_BUILT_COL,
        SIDE_COL
    ];
    parentHardwareListTemplateId;
    transformedHardwareItems;
    get hardwareItemTemplatePartTableItems() {
        return this.selectedItemDrawings.map((itemDrawingDisplay) => {
            return Object.assign({}, { ...itemDrawingDisplay }, {
                _availableQuantity: itemDrawingDisplay.availableQuantity
                    ? itemDrawingDisplay.availableQuantity.toString()
                    : ''
            });
        });
    }
    get computedHardwareItemTemplateParts() {
        if (!this.selectedItemDrawings) {
            return null;
        }
        return this.selectedItemDrawings.map((itemDrawingDisplay) => {
            return {
                drawingNumber: itemDrawingDisplay.drawingNumber
            };
        });
    }
    get closeIcon() {
        return this.$icons['strong_close'];
    }
    get existingItemDrawingNodeIds() {
        if (!this.selectedItemDrawings) {
            return [];
        }
        return this.selectedItemDrawings.map((itemDrawingDisplay) => itemDrawingDisplay.nodeId);
    }
    get hasParent() {
        return Boolean(this.parentId);
    }
    get isDuplicateBySequenceByParent() {
        const duplicateIds = this.duplicateQueryResponse?.nodes || [];
        return duplicateIds.length !== 0;
    }
    get isParentHardwareListTemplate() {
        if (!this.hasParent || !this.parentId) {
            return false;
        }
        // no idea what this does
        return true;
    }
    get isQuantityValid() {
        return this.isNotMoreThanMaxQuantity(this.formData.minQuantity, this.formData.maxQuantity);
    }
    get isSequenceValid() {
        return (Boolean(this.formData.sequence) &&
            !this.isDuplicateBySequenceByParent &&
            this.sequenceIsWithinSmallIntRange &&
            !this.transformedHardwareItems.find((node) => node._sequence == this.formData.sequence));
    }
    get isFormValid() {
        return this.isQuantityValid && this.isSequenceValid;
    }
    get parentId() {
        if (this.parentHardwareListTemplateId) {
            return this.parentHardwareListTemplateId;
        }
        return;
    }
    get rowsPerPageItems() {
        return DEFAULT_MODAL_ROWS_PER_PAGE_ITEMS;
    }
    get sequenceIsWithinSmallIntRange() {
        // 32,767 is the maximum whole number value a SMALLINT data type can store.
        return (this.formData?.sequence || 0) < 32767;
    }
    get sequenceNumberErrorMessage() {
        if (this.isDuplicateBySequenceByParent) {
            return 'Sequence number exists.';
        }
        else if (!this.sequenceIsWithinSmallIntRange) {
            return 'Sequence number is out of range.';
        }
    }
    get sequenceNumberMessage() {
        if (this.isSequenceValid) {
            return 'Sequence number is accepted.';
        }
    }
    async createEntity(createHardwareItemTemplateMutationVariables) {
        if (!createHardwareItemTemplateMutationVariables) {
            this.$notification.add({
                type: AlertType.ERROR,
                text: 'Could not create the Hardware Item Template. No parent data found.'
            });
            return;
        }
        try {
            this.isSaving = true;
            const resp = await this.$apollo.mutate({
                mutation: gql `
          mutation CreateHardwareItemTemplateForHardwareListTemplate(
            $hardwareListTemplateParts: [HardwareListTemplatePartsInputRecordInput]
            $hardwareListTemplateId: UUID!
            $maxQuantity: Float!
            $minQuantity: Float!
            $name: String
            $sequence: Int!
          ) {
            createHardwareListTemplateSlotWithParts(
              input: {
                templateSlot: {
                  hardwareListTemplateParts: $hardwareListTemplateParts
                  hardwareListTemplateId: $hardwareListTemplateId
                  maxQuantity: $maxQuantity
                  minQuantity: $minQuantity
                  name: $name
                  sequence: $sequence
                }
              }
            ) {
              hardwareListTemplateSlot {
                id
                nodeId
              }
            }
          }
        `,
                variables: createHardwareItemTemplateMutationVariables
            });
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: `${this.formData.name || 'Hardware Item Template'} created.`
            });
            this.isSaving = false;
            this.emitRefreshSelfEvent();
            this.emitModalClose();
        }
        catch (err) {
            this.$errorUtility({
                err,
                backupErrorText: 'Could not create the Hardware Item Template'
            });
            this.isSaving = false;
        }
    }
    async deleteEntity(hardwareItemTemplateSlotId) {
        try {
            this.isSaving = true;
            const resp = await this.$apollo.mutate({
                mutation: gql `
          mutation DeleteHardwareItemTemplate($hardwareItemTemplateSlotId: UUID!) {
            deleteHardwareListTemplatePartById(input: { id: $hardwareItemTemplateSlotId }) {
              deletedHardwareListTemplatePartId
            }
          }
        `,
                variables: {
                    hardwareItemTemplateSlotId
                }
            });
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: `Deleted Hardware item template.`
            });
            this.emitRefreshParentEvent();
            this.emitModalClose();
        }
        catch (err) {
            this.isSaving = false;
            this.$errorUtility({
                err,
                backupErrorText: 'Could not delete the Hardware Item Template'
            });
            this.isSaving = false;
        }
    }
    async fetchEntity(parentId) {
        try {
            const resp = await this.$apollo.query({
                query: gql `
          fragment GenericHardwareListTemplateSlotOnBaseModalHardware on HardwareListTemplateSlot {
            hardwareListTemplateId
            id
            maxQuantity
            minQuantity
            name
            nodeId
            sequence

            hardwareListTemplateParts {
              nodes {
                itemDrawing {
                  drawingNumber
                  description
                  inventoryQuantity
                  initializationStatus
                  nodeId
                }
              }
            }
          }

          fragment GenericHardwareListTemplateOnItem on HardwareListTemplate {
            id
            name
            nodeId
            subType
          }

          query HardwareListTemplateOrSlot($id: UUID!) {
            hardwareListTemplateById(id: $id) {
              ...GenericHardwareListTemplateOnItem
            }

            hardwareListTemplateSlotById(id: $id) {
              ...GenericHardwareListTemplateSlotOnBaseModalHardware
            }
          }
        `,
                variables: {
                    id: parentId
                },
                fetchPolicy: 'network-only'
            });
            return resp.data;
        }
        catch (err) {
            this.$errorUtility({
                err,
                backupErrorText: 'Could not fetch the Parent Template'
            });
            this.isSaving = false;
            return null;
        }
    }
    async editEntity(editHardwareItemTemplateMutationVariables) {
        if (!editHardwareItemTemplateMutationVariables) {
            this.$notification.add({
                type: AlertType.ERROR,
                text: 'Could not edit the Hardware Item Template.'
            });
            return;
        }
        try {
            this.isSaving = true;
            const resp = await this.$apollo.mutate({
                mutation: gql `
          mutation EditHardwareItemTemplateForHardwareListTemplate(
            $hardwareListTemplateParts: [HardwareListTemplatePartsInputRecordInput]
            $hardwareListTemplateSlotId: UUID!
            $maxQuantity: Float!
            $minQuantity: Float!
            $name: String
            $sequence: Int!
          ) {
            updateHardwareListTemplateSlotWithParts(
              input: {
                templateSlot: {
                  hardwareListTemplateParts: $hardwareListTemplateParts
                  id: $hardwareListTemplateSlotId
                  maxQuantity: $maxQuantity
                  minQuantity: $minQuantity
                  name: $name
                  sequence: $sequence
                }
              }
            ) {
              hardwareListTemplateSlot {
                id
                nodeId
              }
            }
          }
        `,
                variables: editHardwareItemTemplateMutationVariables
            });
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: `${this.formData.name ? this.formData.name : 'Hardware Item Template'} edited.`
            });
            this.emitRefreshParentEvent();
            this.emitRefreshSelfEvent();
            this.emitModalClose();
        }
        catch (err) {
            this.isSaving = false;
            this.$errorUtility({
                err,
                backupErrorText: 'Could not edit the Hardware Item Template'
            });
        }
    }
    isNotMoreThanMaxQuantity(minQuantity, maxQuantity) {
        return minQuantity <= maxQuantity;
    }
    onSearchItemDrawingChange(itemDrawingDisplay) {
        this.selectedItemDrawings.push(itemDrawingDisplay);
    }
    onClickOfHardwareItemTemplatePartTableItem(item) {
        // Remove the clicked ItemDrawing
        this.selectedItemDrawings = [
            ...this.selectedItemDrawings.filter((itemDrawing) => itemDrawing.nodeId !== item.nodeId)
        ];
    }
    emitModalClose() { }
    onClickOfCloseModal() { }
    emitRefreshParentEvent() { }
    emitRefreshSelfEvent() { }
    async onSequenceChange() {
        if (!this.formData.sequence) {
            return;
        }
        try {
            const resp = await this.checkForDuplicateViaHardwareListTemplate();
            this.duplicateQueryResponse = resp.data.hardwareListTemplateSlots;
        }
        catch (error) {
            this.duplicateQueryResponse = null;
        }
    }
    async onParentIdChange(parentId) {
        if (!parentId) {
            return;
        }
        try {
            const resp = await this.fetchEntity(parentId);
            if (resp?.hardwareListTemplateById) {
                this.parentData = resp.hardwareListTemplateById;
                this.parentName = resp.hardwareListTemplateById.name;
            }
            else if (resp?.hardwareListTemplateSlotById) {
                this.parentData = resp.hardwareListTemplateSlotById;
                this.parentName = resp.hardwareListTemplateSlotById.name || DEFAULT_DASH;
                this.parentSequence = resp.hardwareListTemplateSlotById.sequence;
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    checkForDuplicateViaHardwareListTemplate() {
        return this.$apollo.query({
            query: gql `
        query CheckForHardwareListTemplateSlotDuplicatesHardwareListTemplate(
          $sequence: Int
          $hardwareListTemplateId: UUID!
        ) {
          hardwareListTemplateSlots(
            condition: { sequence: $sequence, hardwareListTemplateId: $hardwareListTemplateId }
          ) {
            nodes {
              id
              nodeId
            }
          }
        }
      `,
            fetchPolicy: 'network-only',
            variables: {
                sequence: this.formData.sequence,
                hardwareListTemplateId: this.parentHardwareListTemplateId
            }
        });
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], BaseModalHardwareItemTemplate.prototype, "parentHardwareListTemplateId", void 0);
__decorate([
    Prop({
        type: Array,
        required: true
    }),
    __metadata("design:type", Array)
], BaseModalHardwareItemTemplate.prototype, "transformedHardwareItems", void 0);
__decorate([
    Emit('modal-close'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseModalHardwareItemTemplate.prototype, "emitModalClose", null);
__decorate([
    Emit('modal-close'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseModalHardwareItemTemplate.prototype, "onClickOfCloseModal", null);
__decorate([
    Emit('refresh-parent'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseModalHardwareItemTemplate.prototype, "emitRefreshParentEvent", null);
__decorate([
    Emit('refresh-self'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseModalHardwareItemTemplate.prototype, "emitRefreshSelfEvent", null);
__decorate([
    Watch('formData.sequence'),
    Debounce(200),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], BaseModalHardwareItemTemplate.prototype, "onSequenceChange", null);
__decorate([
    Watch('parentId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Promise)
], BaseModalHardwareItemTemplate.prototype, "onParentIdChange", null);
BaseModalHardwareItemTemplate = __decorate([
    Component
], BaseModalHardwareItemTemplate);
export default BaseModalHardwareItemTemplate;
