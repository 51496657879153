import { __decorate, __metadata } from "tslib";
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import BaseTable from '~/nasa_ui/base/BaseTable';
import CurrentUserMixin from '~/nasa_ui/mixins/CurrentUserMixin';
let BaseDueInInventoryTable = class BaseDueInInventoryTable extends mixins(BaseTable, CurrentUserMixin) {
    selectedRow = null;
    hideIfEmpty;
    get link() {
        return '';
    }
    handleRowClick(row) {
        this.selectedRow = row;
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], BaseDueInInventoryTable.prototype, "hideIfEmpty", void 0);
BaseDueInInventoryTable = __decorate([
    Component
], BaseDueInInventoryTable);
export default BaseDueInInventoryTable;
