import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component } from 'vue-property-decorator';
import BaseMini from '~/nasa_ui/base/BaseMini';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { EntityType } from '~/nasa_ui/types';
import { ActivityTypeDisplay } from '~/nasa_ui/types/enums/activity';
import { transformActivities } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let MiniActivity = class MiniActivity extends BaseMini {
    localEntity = null;
    entityType = EntityType.ACTIVITY;
    BASE_FETCH = gql `
    fragment MiniActivity on Activity {
      authorityCode
      authorityNumber
      createdDateTime
      cycles
      endDateTime
      isManned
      id
      isPreventativeMaintenance
      name
      nodeId
      organizationCode
      pressurizationRange
      startDateTime
      subType
      changeSet {
        id
        nodeId
        subType
      }
      itemInstance {
        id
        nodeId
      }
    }

    query MiniActivityFetch($id: UUID!) {
      activityById(id: $id) {
        ...MiniActivity
      }
    }
  `;
    get manageLink() {
        return this.localEntity ? `/${EntityType.ACTIVITY}/manage/${this.localEntity.id}` : '';
    }
    get name() {
        if (!this.localEntity && !this.isFetching) {
            return 'Activity not found';
        }
        return this.localEntity?.name || DEFAULT_DASH;
    }
    get verticalLabelDisplayItems() {
        if (!this.localEntity) {
            return [];
        }
        let items = [];
        items = items.concat([
            {
                name: 'Type',
                value: ActivityTypeDisplay.get(this.localEntity.subType) || DEFAULT_DASH
            },
            {
                name: 'Activity date',
                value: this.localEntity._startDate
            }
        ]);
        if (this.localEntity.authorityNumber) {
            items.push({
                name: 'Authority number',
                value: this.localEntity._authorityNumber
            });
        }
        return items;
    }
    onFetchUpdate(data) {
        if (data.activityById) {
            this.localEntity = transformActivities([data.activityById])[0];
        }
    }
};
MiniActivity = __decorate([
    Component
], MiniActivity);
export default MiniActivity;
