export var Impact;
(function (Impact) {
    Impact["PRIOR_TO_NEXT_PRESSURIZATION"] = "PRIOR_TO_NEXT_PRESSURIZATION";
    Impact["PRIOR_TO_NEXT_FLIGHT_FIRST_FLIGHT"] = "PRIOR_TO_NEXT_FLIGHT_FIRST_FLIGHT";
    Impact["PRIOR_TO_NEXT_MANNED_USE"] = "PRIOR_TO_NEXT_MANNED_USE";
    Impact["DURING_NEXT_MAINTENANCE"] = "DURING_NEXT_MAINTENANCE";
})(Impact || (Impact = {}));
export const ImpactDisplay = new Map([
    [Impact.PRIOR_TO_NEXT_PRESSURIZATION, 'Prior to next Pressurization'],
    [Impact.PRIOR_TO_NEXT_FLIGHT_FIRST_FLIGHT, 'Prior to next Flight / First Flight'],
    [Impact.PRIOR_TO_NEXT_MANNED_USE, 'Prior to next manned use'],
    [Impact.DURING_NEXT_MAINTENANCE, 'During next maintenance']
]);
