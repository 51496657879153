import gql from 'graphql-tag';
export const CosmicUserSlimFragment = gql `
  fragment CosmicUserSlim on User {
    accountId
    auid
    contactId
    createdDateTime
    email
    firstName
    id
    lastName
    middleName
    nodeId
    role
    uupic
  }
`;
export const CosmicUserGroupSlimFragment = gql `
  fragment CosmicUserGroupSlim on UserGroup {
    code
    createdDateTime
    createdByUserId
    delegateGroupForUserId
    isIpt
    name
    nodeId
    subType
  }
`;
