import { formatDate, fromNow } from '../utils/dates';
import { dateDisplay } from '../utils/dates/dateDisplay';
import { dateTimeDisplay, timeDisplay } from '../utils/dates/dateTimeDisplay';
export default {
    install(Vue) {
        Object.defineProperties(Vue.prototype, {
            $dateDisplay: { value: dateDisplay },
            $dateTimeDisplay: { value: dateTimeDisplay },
            $formatDate: { value: formatDate },
            $fromNow: { value: fromNow },
            $timeDisplay: { value: timeDisplay }
        });
    }
};
