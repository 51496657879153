import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Emit } from 'vue-property-decorator';
import BaseMini from '~/nasa_ui/base/BaseMini';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { CosmicApprovalRoutingTemplateFragment } from '~/nasa_ui/DAL/approvalRoutingTemplate';
import { EntityType } from '~/nasa_ui/types';
import { transformApprovalRoutingTemplates } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let MiniApprovalRoutingTemplate = class MiniApprovalRoutingTemplate extends BaseMini {
    localEntity = null;
    entityType = EntityType.USER;
    BASE_FETCH = gql `
    ${CosmicApprovalRoutingTemplateFragment}

    query MiniApprovalRoutingTemplateFetch($id: UUID!) {
      approvalRoutingTemplateById(id: $id) {
        ...CosmicApprovalRoutingTemplate
      }
    }
  `;
    get iconClass() {
        return this.$icons[EntityType.APPROVAL_ROUTING_TEMPLATE];
    }
    get manageLink() {
        return this.localEntity ? `/${EntityType.USER}/approval_routing_templates/manage/${this.localEntity.id}` : '';
    }
    get name() {
        if (!this.localEntity && !this.isFetching) {
            return 'Approval Routing Template not found';
        }
        return this.localEntity?.name || DEFAULT_DASH;
    }
    get verticalLabelDisplayItems() {
        if (!this.localEntity) {
            return [];
        }
        const items = [];
        return items;
    }
    onFetchUpdate(data) {
        if (data.approvalRoutingTemplateById) {
            this.localEntity = transformApprovalRoutingTemplates([data.approvalRoutingTemplateById])[0];
        }
    }
    onClickOfInlineClear() {
        return this.computedId;
    }
};
__decorate([
    Emit('inlineClear'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MiniApprovalRoutingTemplate.prototype, "onClickOfInlineClear", null);
MiniApprovalRoutingTemplate = __decorate([
    Component
], MiniApprovalRoutingTemplate);
export default MiniApprovalRoutingTemplate;
