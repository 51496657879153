import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { transformUserResponses } from './transformEntityToTableFormat';
// most displays are in the ~/nasa_ui package but this display requires
// `transformUserGroups`, which lives in nasa_ui.
export const groupNameDisplay = (item) => {
    if (!item) {
        return DEFAULT_DASH;
    }
    let userGroup = null;
    let groupCode = '';
    // it's a thing with a user group
    if ('userGroup' in item) {
        userGroup = item.userGroup;
        groupCode = item.groupCode || userGroup?.code || DEFAULT_DASH;
    }
    else if ('code' in item) {
        userGroup = item;
        groupCode = item.code || DEFAULT_DASH;
    }
    else {
        groupCode = DEFAULT_DASH;
    }
    // if the user group is a delegate group, return the user's full name
    if (userGroup?.delegatesForUser) {
        const user = transformUserResponses([userGroup.delegatesForUser])[0];
        return user._fullName;
    }
    else if (userGroup?.delegateGroupForUser) {
        const user = transformUserResponses([userGroup.delegateGroupForUser])[0];
        return user._fullName;
    }
    else if (userGroup?.name) {
        // if the user group has a name, use it
        return userGroup.name;
    }
    else {
        // as a fallback, return the group code
        return groupCode;
    }
};
export function reorderGroupsByGroupCodeOrder(groups, codes) {
    if (groups.length !== codes.length) {
        console.error('Arrays of groups and codes are different lengths.');
        return null;
    }
    return codes.map((code) => groups.find((group) => group.code === code)).filter((_) => _);
}
