import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins } from 'vue-property-decorator';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import AutocompleteMixin from '~/nasa_ui/mixins/AutocompleteMixin';
let AppSelectLot = class AppSelectLot extends Mixins(AutocompleteMixin, BaseCosmic) {
    async search(q) {
        const query = gql `
      query GetLotForAutoComplete($startsWith: String!) {
        autocompleteLotNumber(lotNumberStartsWith: $startsWith)
      }
    `;
        const resp = await this.$apollo.query({
            query,
            variables: { startsWith: q }
        });
        return resp.data.autocompleteLotNumber?.map((ln) => ({ displayText: ln, value: ln })) ?? [];
    }
};
AppSelectLot = __decorate([
    Component
], AppSelectLot);
export default AppSelectLot;
