import { __decorate, __metadata } from "tslib";
import { DebounceAll } from 'lodash-decorators';
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { NOT_APPLICABLE } from '~/nasa_ui/constants/display';
import { AS_BUILT_COL, BASE_DATE_COL, BASE_ICON_COL, BASE_TEXT_COL, DESCRIPTION_COL, DRAWING_NUMBER_COL, LOT_COL, SEQUENCE_COL, SERIAL_COL, SIDE_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { transformHardwareListResponseWithRefs } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
const indexOfSequence = (thingWithSequence, things) => {
    return things.findIndex((_) => _.sequence === thingWithSequence.sequence);
};
let MiniHardwareList = class MiniHardwareList extends Mixins(ReadOnlyMixin) {
    isFetching = false;
    NOT_APPLICABLE = NOT_APPLICABLE;
    showModalHardwareListItemCreate = false;
    showModalHardwareListEdit = false;
    showPartsList = false;
    selectedEntity = null;
    tableHeadersForPartsList = [
        composeTableHeader(SEQUENCE_COL, { width: '50px' }),
        composeTableHeader(BASE_ICON_COL, {
            text: 'Item type',
            value: '_entityIcon',
            noEscape: true
        }),
        composeTableHeader(DRAWING_NUMBER_COL, {
            value: '_partReference.drawingNumber'
        }),
        composeTableHeader(AS_BUILT_COL, {
            value: '_partReference._asBuiltNumber',
            noWrap: true
        }),
        composeTableHeader(SIDE_COL, {
            value: '_partReference._side'
        }),
        composeTableHeader(SERIAL_COL, {
            value: '_partReference._serialNumber'
        }),
        composeTableHeader(LOT_COL, {
            value: '_partReference._lotNumber'
        }),
        composeTableHeader(DESCRIPTION_COL, {
            value: '_partReference._description'
        }),
        composeTableHeader(BASE_TEXT_COL, {
            text: 'Location',
            value: '_partReference._location'
        }),
        composeTableHeader(BASE_TEXT_COL, {
            text: 'CEI #',
            value: '_partReference._contractEndingItemNumber'
        }),
        composeTableHeader(BASE_DATE_COL, {
            text: 'Birth',
            value: '_partReference._birthDate'
        }),
        composeTableHeader(BASE_DATE_COL, {
            text: 'Usage expiry',
            value: '_partReference._shelfLifeExpirationDate'
        }),
        composeTableHeader(BASE_DATE_COL, {
            text: 'Shelf expiry',
            value: '_partReference._usageLifeExpirationDate'
        }),
        composeTableHeader(BASE_DATE_COL, {
            text: 'Last calibration',
            value: '_partReference._lastCalibrationDate'
        }),
        composeTableHeader(BASE_TEXT_COL, {
            text: 'Calibration #',
            value: '_partReference._calibrationNumber'
        })
    ];
    allowChildCreation;
    allowEdit;
    entity;
    id;
    inlineClear;
    initiallyShowListItems;
    showManageLink;
    title;
    get computedId() {
        return this.selectedEntity?.id || this.id;
    }
    get hasAnyActions() {
        return this.showManageLink || this.inlineClear || this.hasParts;
    }
    get hasParts() {
        return (this.transformedSelectedEntity?._hardwareListParts || []).length > 0;
    }
    get largestSequenceNumber() {
        return this.transformedSelectedEntity
            ? this.transformedSelectedEntity._hardwareListParts.reduce((acc, child) => (acc = acc > child.sequence ? acc : child.sequence), 0)
            : 0;
    }
    get nextListItemSequence() {
        return this.largestSequenceNumber + 10;
    }
    get shouldShowCreateChildIcon() {
        return this.allowChildCreation;
    }
    get shouldShowInlineClearIcon() {
        return this.inlineClear && !this.readOnly;
    }
    get transformedSelectedEntity() {
        return this.selectedEntity ? transformHardwareListResponseWithRefs([this.selectedEntity])[0] : null;
    }
    get verticalLabelDisplayItems() {
        if (!this.transformedSelectedEntity) {
            return [];
        }
        const items = [];
        items.push({
            name: 'Type',
            value: this.transformedSelectedEntity._subType
        });
        if (this.transformedSelectedEntity.authorizer) {
            items.push({
                name: 'Authorizer',
                value: this.transformedSelectedEntity.authorizer
            });
        }
        if (this.transformedSelectedEntity.revision) {
            items.push({
                name: 'Revision',
                value: this.transformedSelectedEntity._revision
            });
        }
        if (this.transformedSelectedEntity.addendumNumber) {
            items.push({
                name: 'Addendum #',
                value: this.transformedSelectedEntity._addendumNumber
            });
        }
        if (this.transformedSelectedEntity.addendumDate) {
            items.push({
                name: 'Addendum',
                value: this.transformedSelectedEntity._addendumDate
            });
        }
        return items;
    }
    onClickOfEdit() {
        this.showModalHardwareListEdit = true;
    }
    onClickOfShowPartsList() {
        this.showPartsList = !this.showPartsList;
    }
    onCloseOfModal() {
        this.showModalHardwareListItemCreate = false;
        this.showModalHardwareListEdit = false;
        this.fetchEntity();
    }
    onClickOfInlineClear() {
        return this.transformedSelectedEntity;
    }
    onEntityChange() {
        if (!this.entity) {
            return;
        }
        this.selectedEntity = { ...this.entity };
    }
    async fetchEntity() {
        if (!this.id) {
            return;
        }
        try {
            this.isFetching = true;
            const resp = await this.$http.get(`/hardware-lists/${this.computedId}`);
            this.selectedEntity = resp.data;
            this.isFetching = false;
        }
        catch (err) {
            this.isFetching = false;
            this.$errorUtility({ err });
        }
    }
    onInitiallyShowListItemsChange() {
        if (this.initiallyShowListItems) {
            this.showPartsList = true;
        }
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], MiniHardwareList.prototype, "allowChildCreation", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], MiniHardwareList.prototype, "allowEdit", void 0);
__decorate([
    Prop({
        required: false,
        type: Object
    }),
    __metadata("design:type", Object)
], MiniHardwareList.prototype, "entity", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], MiniHardwareList.prototype, "id", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], MiniHardwareList.prototype, "inlineClear", void 0);
__decorate([
    Prop({
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], MiniHardwareList.prototype, "initiallyShowListItems", void 0);
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], MiniHardwareList.prototype, "showManageLink", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], MiniHardwareList.prototype, "title", void 0);
__decorate([
    Emit('inlineClear'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MiniHardwareList.prototype, "onClickOfInlineClear", null);
__decorate([
    Watch('entity', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MiniHardwareList.prototype, "onEntityChange", null);
__decorate([
    DebounceAll(10),
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], MiniHardwareList.prototype, "fetchEntity", null);
__decorate([
    Watch('initiallyShowListItems', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MiniHardwareList.prototype, "onInitiallyShowListItemsChange", null);
MiniHardwareList = __decorate([
    Component
], MiniHardwareList);
export default MiniHardwareList;
