import { getBlurbAboutCreate, getBlurbAboutManage, getBlurbAboutSearch } from '~/nasa_ui/router/utils';
import { EntityType, EntityTypeSingularDisplay, UserPermission } from '~/nasa_ui/types';
export const ContractSharedRoutes = [
    {
        path: `/${EntityType.CONTRACT}/create`,
        name: 'ContractsCreateView',
        component: () => import(/* webpackChunkName: "Contract" */ '../views/ContractsCreateView/ContractsCreateView.vue'),
        meta: {
            displayName: 'Contracts create',
            description: getBlurbAboutCreate(EntityTypeSingularDisplay.get(EntityType.CONTRACT)),
            permissions: [UserPermission.CONTRACT_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.CONTRACT}/manage`,
        redirect: {
            name: 'ContractsSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.CONTRACT)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.CONTRACT)),
            permissions: [UserPermission.CONTRACT_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.CONTRACT}/manage/:number`,
        name: 'ContractsManageView',
        component: () => import(/* webpackChunkName: "Contract" */ '../views/ContractsManageView/ContractsManageView.vue'),
        meta: {
            displayName: 'Contracts manage',
            description: getBlurbAboutManage(EntityTypeSingularDisplay.get(EntityType.CONTRACT)),
            permissions: [UserPermission.CONTRACT_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.CONTRACT}/search`,
        name: 'ContractsSearchView',
        component: () => import(/* webpackChunkName: "Contract" */ '../views/ContractsSearchView/ContractsSearchView.vue'),
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.CONTRACT)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.CONTRACT)),
            permissions: [UserPermission.CONTRACT_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.CONTRACT}`,
        redirect: {
            name: 'ContractsSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.CONTRACT)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.CONTRACT)),
            permissions: [UserPermission.CONTRACT_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    }
];
