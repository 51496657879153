import { get } from 'lodash';
export * from './dal';
export * from './getRiskColor';
export const defaultInterval = {
    days: 0,
    hours: 0,
    minutes: 0,
    months: 0,
    seconds: 0,
    years: 0
};
export const buildTree = (items) => {
    const map = {};
    const tree = [];
    // Create a map of items using their id as keys
    items.forEach((item) => {
        map[item.id] = { ...item, children: [] };
    });
    // Loop through the items to build the tree structure
    items.forEach((item) => {
        if (item?.parentId && map[item.parentId]) {
            map[item.parentId].children.push(map[item.id]);
        }
        else {
            // If an item has no parent, it's a root item
            tree.push(map[item.id]);
        }
    });
    return tree;
};
export const isNullOrUndefined = (value) => value === null || value === undefined;
export const stripHtmlFromString = (slug) => {
    if (typeof slug !== 'string') {
        return '';
    }
    // preserving line breaks
    return slug.replace(/(<|&lt;)br\s*\/*(>|&gt;)/g, '\n').replace(/(<([^>]+)>)/gi, '');
};
export const currencyDisplay = (num, defaultReturnValue = '-') => {
    if (num === null || num === undefined) {
        return defaultReturnValue;
    }
    if (typeof num === 'string') {
        num = parseFloat(num);
    }
    return num.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};
export const sortObjectBy = (sortBy, desc = false) => {
    return (a, b) => {
        try {
            let sortByA = get(a, sortBy);
            let sortByB = get(b, sortBy);
            const order = desc ? 1 : -1;
            if (typeof sortByA === 'string' || sortByA instanceof String) {
                sortByA = sortByA.toLowerCase();
            }
            if (typeof sortByB === 'string' || sortByB instanceof String) {
                sortByB = sortByB.toLowerCase();
            }
            if (sortByA === sortByB) {
                return 0;
            }
            return sortByA > sortByB ? -1 * order : 1 * order;
        }
        catch (err) {
            console.log(err);
            return 0;
        }
    };
};
