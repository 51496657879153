export const store = {
    cosmicRoles: [],
    currentUser: null,
    /**
     * Deprecated for now
     */
    async getAllAvailableCosmicRoles() {
        // const http = createAxiosInstance();
        // const resp = await http.get<CosmicRoleAccessDomainResponse[]>('/users/role-list');
        // if (resp.data) {
        //   this.cosmicRoles = resp.data.find((domainObj) => domainObj.domain === AppDomain)?.roles || [];
        // }
    }
};
