import { getBlurbAboutCreate, getBlurbAboutManage, getBlurbAboutSearch } from '~/nasa_ui/router/utils';
import { EntityType, EntityTypeSingularDisplay, UserPermission } from '~/nasa_ui/types';
export const ActivitiesSharedRoutes = [
    {
        path: `/${EntityType.ACTIVITY}`,
        redirect: {
            name: 'ActivitiesSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.ACTIVITY)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.ACTIVITY)),
            permissions: [UserPermission.ACTIVITY_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.ACTIVITY}/manage`,
        redirect: {
            name: 'ActivitiesSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.ACTIVITY)} search`,
            description: getBlurbAboutManage(EntityTypeSingularDisplay.get(EntityType.ACTIVITY)),
            permissions: [UserPermission.ACTIVITY_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.ACTIVITY}/create`,
        name: 'ActivitiesCreateView',
        component: () => import(/* webpackChunkName: "Location" */ '../views/ActivitiesCreateView/ActivitiesCreateView.vue'),
        meta: {
            displayName: 'Activities create',
            description: getBlurbAboutCreate(EntityTypeSingularDisplay.get(EntityType.ACTIVITY)),
            permissions: [UserPermission.ACTIVITY_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.ACTIVITY}/manage/:id`,
        name: 'ActivitiesManageView',
        component: () => import(/* webpackChunkName: "Location" */ '../views/ActivitiesManageView/ActivitiesManageView.vue'),
        meta: {
            displayName: 'Activities manage',
            description: getBlurbAboutManage(EntityTypeSingularDisplay.get(EntityType.ACTIVITY)),
            permissions: [UserPermission.ACTIVITY_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.ACTIVITY}/search`,
        name: 'ActivitiesSearchView',
        component: () => import(/* webpackChunkName: "Location" */ '../views/ActivitiesSearchView/ActivitiesSearchView.vue'),
        meta: {
            displayName: 'Activities search',
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.ACTIVITY)),
            permissions: [UserPermission.ACTIVITY_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    }
];
