import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { GENERAL_ENTITY_COLOR } from '~/nasa_ui/constants/colors';
import { getAS2DownloadUrl } from '~/nasa_ui/DAL/attachments';
import { AttachmentTypeDisplay } from '~/nasa_ui/types';
import { buildIconAsString } from '../../ui/buildIconAsString';
import { getIconByContentType } from '../../ui/CosmicIcons';
import { addDateDisplays, addUserDisplays } from '../displayMappers';
import { buildAttachmentDownloadLink, buildAttachmentDownloadLinkWithFileName } from '../transformEntityToTableFormat';
export const transformAttachments = (attachments) => {
    return attachments
        .map(addUserDisplays)
        .map(addDateDisplays)
        .map((attachment) => {
        attachment._downloadKey = getAS2DownloadUrl(attachment.id, attachment.fileName);
        return attachment;
    })
        .map((attachment) => {
        return Object.assign({}, attachment, {
            _entityTypeIcon: buildIconAsString({
                iconClass: 'fal fa-file-upload',
                color: GENERAL_ENTITY_COLOR,
                title: attachment.fileName || 'Attachment'
            }),
            _subType: AttachmentTypeDisplay.get(attachment.subType),
            _description: attachment.description || DEFAULT_DASH,
            _fileName: attachment.fileName || DEFAULT_DASH,
            _fileIcon: buildIconAsString({
                iconClass: getIconByContentType(attachment.contentType),
                title: attachment.fileName || 'Attachment'
            }),
            _downloadLink: buildAttachmentDownloadLink(attachment),
            _downloadLinkWithFileName: buildAttachmentDownloadLinkWithFileName(attachment)
        });
    });
};
