var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('c-text', {
    domProps: {
      "cValue": _vm.value,
      "clearable": _vm.clearable,
      "disabled": _vm.disabled,
      "errorMessage": _vm.computedErrorMessage,
      "hideErrorMessage": _vm.hideErrorMessage,
      "labelInvert": _vm.labelInvert,
      "label": _vm.label,
      "message": _vm.computedMessage,
      "pattern": _vm.pattern,
      "placeholder": _vm.placeholder,
      "preComputedRules": _vm.localRulesBaseOnProps,
      "replaceSpacesWithUnderscores": _vm.replaceSpacesWithUnderscores,
      "required": _vm.required,
      "upperCase": _vm.upperCase
    },
    on: {
      "c-input": function cInput($event) {
        return _vm.emitInput($event.detail);
      },
      "c-blur": function cBlur($event) {
        return _vm.emitBlur($event.detail);
      }
    }
  }, [_vm.$scopedSlots['message'] ? _c('div', {
    attrs: {
      "slot": "message"
    },
    slot: "message"
  }, [_vm._t("message")], 2) : _vm._e(), _vm.$scopedSlots['after-input'] ? _c('div', {
    attrs: {
      "slot": "after-input"
    },
    slot: "after-input"
  }, [_vm._t("after-input")], 2) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };