export function getRiskColor(likelihood, consequence) {
    const x = consequence - 1;
    const y = likelihood - 1;
    // risk matrix levels
    const colorMatrix = [
        [0, 0, 0, 0, 0],
        [0, 0, 1, 1, 1],
        [0, 0, 1, 1, 2],
        [0, 1, 1, 2, 2],
        [1, 1, 2, 2, 2]
    ];
    // Make sure x and y are within valid range
    if (x < 0 || x >= colorMatrix.length || y < 0 || y >= colorMatrix[0].length) {
        return '';
    }
    const index = colorMatrix[x][y];
    return ['green', 'yellow', 'red'][index];
}
