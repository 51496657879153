export const DEFAULT_ROWS_PER_PAGE_ITEMS = [
    {
        text: '50',
        value: 50
    },
    {
        text: '100',
        value: 100
    },
    {
        text: '250',
        value: 250
    },
    {
        text: '$vuetify.dataIterator.rowsPerPageAll',
        value: -1
    }
];
export const DEFAULT_MODAL_ROWS_PER_PAGE_ITEMS = [
    {
        text: '10',
        value: 10
    },
    {
        text: '20',
        value: 20
    },
    {
        text: '50',
        value: 50
    },
    {
        text: '$vuetify.dataIterator.rowsPerPageAll',
        value: -1
    }
];
