import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let SearchFilterContainer = class SearchFilterContainer extends Vue {
    show;
    color;
};
__decorate([
    Prop({ default: false }),
    __metadata("design:type", Boolean)
], SearchFilterContainer.prototype, "show", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], SearchFilterContainer.prototype, "color", void 0);
SearchFilterContainer = __decorate([
    Component
], SearchFilterContainer);
export default SearchFilterContainer;
