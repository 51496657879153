var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('MenuMyApprovals', {
    attrs: {
      "approvals": _vm.approvalMenuItems,
      "approvals-limit": _vm.alertLimit,
      "approvals-report-url": _vm.approvalsReportUrl,
      "badge-count": _vm.badgeCount,
      "is-error": _vm.fetchFailed,
      "is-loading": _vm.isLoading
    },
    on: {
      "refresh": _vm.fetchCurrentUserApprovals
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };