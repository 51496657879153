var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppSelect', _vm._b({
    attrs: {
      "chips": false,
      "label": _vm.computedLabel,
      "loading": _vm.isLoading,
      "select-options": _vm.asBuiltOptions,
      "sort": false
    },
    model: {
      value: _vm.selectedOption,
      callback: function callback($$v) {
        _vm.selectedOption = $$v;
      },
      expression: "selectedOption"
    }
  }, 'AppSelect', _vm.$props, false));
};
var staticRenderFns = [];
export { render, staticRenderFns };