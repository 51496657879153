export var CosmicEnvironment;
(function (CosmicEnvironment) {
    CosmicEnvironment["LOCAL"] = "local";
    CosmicEnvironment["DEVELOPMENT"] = "development";
    CosmicEnvironment["STAGING"] = "staging";
    CosmicEnvironment["PRODUCTION"] = "production";
})(CosmicEnvironment || (CosmicEnvironment = {}));
export const getEnvironment = () => {
    const { host } = window.location;
    if (host.includes('localhost')) {
        return CosmicEnvironment.LOCAL;
    }
    if (host.includes('dev.')) {
        return CosmicEnvironment.DEVELOPMENT;
    }
    if (host.includes('staging.')) {
        return CosmicEnvironment.STAGING;
    }
    return CosmicEnvironment.PRODUCTION;
};
