import { UserRole } from '~/db_types';
export const AdminSharedRoutes = [
    {
        path: `/admin`,
        name: 'AdminView',
        component: () => import(/* webpackChunkName: "Admin" */ '../views/AdminView/AdminView.vue'),
        meta: {
            description: 'Admin stuff',
            displayName: 'Admin',
            permissions: [],
            supportContexts: [],
            userRoles: [UserRole.ADMIN, UserRole.SYSTEM]
        }
    }
];
