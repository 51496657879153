import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins } from 'vue-property-decorator';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import AutocompleteMixin from '~/nasa_ui/mixins/AutocompleteMixin';
let AppSelectDrawingNumber = class AppSelectDrawingNumber extends Mixins(AutocompleteMixin, BaseCosmic) {
    async search(q) {
        const query = gql `
      query GetDrawingNumberForAutocomplete($startsWith: String!) {
        autocompleteDrawingNumber(drawingNumberStartsWith: $startsWith)
      }
    `;
        const resp = await this.$apollo.query({
            query,
            variables: { startsWith: q }
        });
        return resp.data.autocompleteDrawingNumber?.map((dn) => ({ displayText: dn, value: dn })) ?? [];
    }
};
AppSelectDrawingNumber = __decorate([
    Component
], AppSelectDrawingNumber);
export default AppSelectDrawingNumber;
