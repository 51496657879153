var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { DebounceAll } from 'lodash-decorators';
import { Component, Watch } from 'vue-property-decorator';
import { SearchDrawingsAndMastersQuery } from '~/db_types';
import BaseSearch from '~/nasa_ui/base/BaseSearch';
import { AS_BUILT_COL, BASE_ICON_COL, DESCRIPTION_COL, DRAWING_NUMBER_COL, SIDE_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { SearchItemDrawingFragment } from '~/nasa_ui/DAL/itemDrawing';
import { CosmicItemMasterSlimFragment } from '~/nasa_ui/DAL/itemMaster/slim';
import { EntityType } from '~/nasa_ui/types';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { addFilterOutSelected } from '~/nasa_ui/utils/helpers/displayMappers';
import { transformItemDrawings, transformItemMasters } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
var SearchItemDrawingItemMasterScope;
(function (SearchItemDrawingItemMasterScope) {
    SearchItemDrawingItemMasterScope["ITEM_DRAWING"] = "ITEM_DRAWING";
    SearchItemDrawingItemMasterScope["ITEM_DRAWING_AND_ITEM_MASTER"] = "ITEM_DRAWING_AND_ITEM_MASTER";
    SearchItemDrawingItemMasterScope["ITEM_MASTER"] = "ITEM_MASTER";
})(SearchItemDrawingItemMasterScope || (SearchItemDrawingItemMasterScope = {}));
const SearchItemDrawingItemMasterScopeDisplay = new Map([
    [SearchItemDrawingItemMasterScope.ITEM_DRAWING, 'Item Drawings Only'],
    [SearchItemDrawingItemMasterScope.ITEM_DRAWING_AND_ITEM_MASTER, 'Item Drawings and Item Masters'],
    [SearchItemDrawingItemMasterScope.ITEM_MASTER, 'Item Masters Only']
]);
let SearchItemDrawingItemMaster = class SearchItemDrawingItemMaster extends BaseSearch {
    entityType = EntityType.ITEM_MASTER;
    hint = 'Drawing number, Description';
    SearchItemDrawingItemMasterScope = SearchItemDrawingItemMasterScope;
    SearchItemDrawingItemMasterScopeDisplay = SearchItemDrawingItemMasterScopeDisplay;
    placeholder = 'search item drawings and item masters';
    searchFilters = {
        query: '',
        searchScope: SearchItemDrawingItemMasterScope.ITEM_DRAWING_AND_ITEM_MASTER
    };
    SEARCH_QUERY = gql `
    ${SearchItemDrawingFragment}
    ${CosmicItemMasterSlimFragment}

    query SearchDrawingsAndMasters(
      $query: String
      $firstResults: Int
      $runItemDrawings: Boolean! = true
      $runItemMasters: Boolean! = true
    ) {
      searchItemDrawings(first: $firstResults, query: $query) @include(if: $runItemDrawings) {
        nodes {
          ...SearchItemDrawing
        }
      }

      searchItemMasters(first: $firstResults, query: $query) @include(if: $runItemMasters) {
        nodes {
          ...CosmicItemMasterSlim
        }
      }
    }
  `;
    get chipsFromFilters() {
        if (!this.pureSearchFilters || Object.keys(this.pureSearchFilters).length === 0) {
            return [];
        }
        const appChips = [];
        if (this.searchFilters.searchScope &&
            this.searchFilters.searchScope !== SearchItemDrawingItemMasterScope.ITEM_DRAWING_AND_ITEM_MASTER) {
            appChips.push({
                text: SearchItemDrawingItemMasterScopeDisplay.get(this.searchFilters.searchScope) || '',
                color: EntityType.ITEM_MASTER,
                value: this.searchFilters.searchScope,
                outline: true,
                close: false
            });
        }
        return appChips.filter((key) => key !== undefined);
    }
    get computedPlaceholder() {
        if (this.searchFilters.searchScope === SearchItemDrawingItemMasterScope.ITEM_DRAWING_AND_ITEM_MASTER) {
            return this.placeholder;
        }
        else if (this.searchFilters.searchScope === SearchItemDrawingItemMasterScope.ITEM_DRAWING) {
            return 'search item drawings';
        }
        else {
            return 'search item masters';
        }
    }
    get disableSearch() {
        return this.searchInput.length < 2;
    }
    get gqlSearchQueryVariables() {
        const base = {
            firstResults: 200,
            runItemDrawings: true,
            runItemMasters: true
        };
        const query = {};
        if (this.searchInput.trim().length > 0) {
            query.query = this.searchInput;
        }
        if (this.searchFilters.searchScope === SearchItemDrawingItemMasterScope.ITEM_MASTER) {
            base.runItemDrawings = false;
        }
        if (this.searchFilters.searchScope === SearchItemDrawingItemMasterScope.ITEM_DRAWING) {
            base.runItemMasters = false;
        }
        const variables = Object.assign({}, base, query);
        return variables;
    }
    get tableHeaders() {
        return [
            composeTableHeader(BASE_ICON_COL, {
                text: 'Hardware<br/>level',
                value: '_entityType',
                width: '50px'
            }),
            DRAWING_NUMBER_COL,
            DESCRIPTION_COL,
            AS_BUILT_COL,
            SIDE_COL
        ];
    }
    // Overriding this watcher because it was clearing out my initial search
    // filter state defined above
    onChangeOfFilterProp(newFilters) {
        if (!newFilters) {
            return;
        }
        const filters = Object.assign({}, this.searchFilters, newFilters);
        this.$set(this, 'searchFilters', filters);
    }
    onChangeOfRawResponse(data) {
        if (!data) {
            this.$set(this, 'searchResults', []);
            return;
        }
        // transform for table
        const itemDrawings = transformItemDrawings(data.searchItemDrawings?.nodes ?? []);
        const itemMasters = transformItemMasters(data.searchItemMasters?.nodes ?? []);
        // merge search results into one array
        let results = [...itemDrawings, ...itemMasters];
        // add __SELECTED and pull up nodes a level
        results = this.addSelectedPropIfSelected(results);
        this.$set(this, 'searchResults', results.filter(addFilterOutSelected));
    }
};
__decorate([
    Watch('filters', { deep: true, immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], SearchItemDrawingItemMaster.prototype, "onChangeOfFilterProp", null);
__decorate([
    DebounceAll(10),
    Watch('rawSearchResponse'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof SearchDrawingsAndMastersQuery !== "undefined" && SearchDrawingsAndMastersQuery) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], SearchItemDrawingItemMaster.prototype, "onChangeOfRawResponse", null);
SearchItemDrawingItemMaster = __decorate([
    Component
], SearchItemDrawingItemMaster);
export default SearchItemDrawingItemMaster;
