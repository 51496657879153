import { __decorate } from "tslib";
import { Component, Mixins } from 'vue-property-decorator';
import BaseMini from '~/nasa_ui/base/BaseMini';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { ContactRepoMixin } from '~/nasa_ui/DAL/contact';
import { EntityType } from '~/nasa_ui/types';
let MiniContact = class MiniContact extends Mixins(BaseMini, ContactRepoMixin) {
    entityType = EntityType.CONTACT;
    localEntity = null;
    get auidDisplay() {
        return this.localEntity?.auid || DEFAULT_DASH;
    }
    get emailDisplay() {
        return this.localEntity?.email && this.localEntity.email.length ? this.localEntity.email[0] : null;
    }
    get manageLink() {
        return this.localEntity?.id ? `/${EntityType.CONTACT}/manage/${this.localEntity.id}` : '';
    }
    get name() {
        if (!this.localEntity && !this.isFetching) {
            return 'Not found';
        }
        return this.$contactDisplay(this.localEntity);
    }
    get shouldShowManageLink() {
        return this.showManageLink;
    }
    get verticalLabelDisplayItems() {
        if (!this.localEntity) {
            return [];
        }
        const items = [];
        items.push({
            name: 'AUID',
            value: this.auidDisplay
        });
        return items;
    }
    async executeQuery() {
        if (!this.id) {
            return;
        }
        this.isFetching = true;
        try {
            const resp = await this.fetchContact(this.id);
            this.onFetchUpdate(resp.data);
            this.isFetching = false;
        }
        catch (error) {
            this.isFetching = false;
        }
    }
    onFetchUpdate(data) {
        if (data) {
            this.localEntity = data;
        }
    }
};
MiniContact = __decorate([
    Component
], MiniContact);
export default MiniContact;
