/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./AppFormWrapper.vue?vue&type=template&id=2f459032&scoped=true"
import script from "./AppFormWrapper.ts?vue&type=script&lang=ts&external"
export * from "./AppFormWrapper.ts?vue&type=script&lang=ts&external"
import style0 from "./AppFormWrapper.vue?vue&type=style&index=0&id=2f459032&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f459032",
  null
  
)

export default component.exports