/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./SearchDocument.vue?vue&type=template&id=eff07f30&scoped=true"
import script from "./SearchDocument.ts?vue&type=script&lang=ts&external"
export * from "./SearchDocument.ts?vue&type=script&lang=ts&external"
import style0 from "./SearchDocument.vue?vue&type=style&index=0&id=eff07f30&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eff07f30",
  null
  
)

export default component.exports