import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { ActivityTypeDisplay, AnalysisResultDisplay, CalibrationTypeDisplay, CheckOutActivityHardwareTypeDisplay } from '~/nasa_ui/types';
import { addActivityAttributesDisplay, addAsBuiltNumberDisplay, addAuthorityCodeDisplay, addAuthorityNumberDisplay, addCrewTimeDisplay, addCyclesDisplay, addDateDisplays, addDescriptionDisplay, addDrawingNumberDisplay, addItemNumberDisplay, addLotNumberDisplay, addOperationTimeDisplay, addPressurizationRangeDisplay, addPressurizedTimeDisplay, addPreventativeMaintenanceDisplay, addSerialNumberDisplay, addSideDisplay, addSizeDisplay, addStaticTimeDisplay, addSubTypeDisplay, addUserDisplays } from '../displayMappers';
import { markdownToHtml } from '../markdownToHtml';
import { transformItemInstances } from '../transformEntityToTableFormat';
export const transformActivities = (items) => {
    return items
        .map(addActivityAttributesDisplay)
        .map(addAsBuiltNumberDisplay)
        .map(addAuthorityCodeDisplay)
        .map(addAuthorityNumberDisplay)
        .map(addCrewTimeDisplay)
        .map(addCyclesDisplay)
        .map(addDateDisplays)
        .map(addDescriptionDisplay)
        .map(addDrawingNumberDisplay)
        .map(addItemNumberDisplay)
        .map(addLotNumberDisplay)
        .map(addOperationTimeDisplay)
        .map(addPressurizationRangeDisplay)
        .map(addPressurizedTimeDisplay)
        .map(addPreventativeMaintenanceDisplay)
        .map(addSerialNumberDisplay)
        .map(addSideDisplay)
        .map(addSizeDisplay)
        .map(addStaticTimeDisplay)
        .map(addSubTypeDisplay(ActivityTypeDisplay))
        .map(addUserDisplays)
        .map((activity) => {
        const _activity = { ...activity };
        _activity._checkOutType = activity.attributes?.checkOutType
            ? CheckOutActivityHardwareTypeDisplay.get(activity.attributes?.checkOutType) || DEFAULT_DASH
            : DEFAULT_DASH;
        _activity._calibrationType = activity.calibrationType
            ? CalibrationTypeDisplay.get(activity.calibrationType) || DEFAULT_DASH
            : DEFAULT_DASH;
        // inspection result
        _activity._inspectionResult = activity.attributes?.result
            ? AnalysisResultDisplay.get(activity.attributes?.result) || DEFAULT_DASH
            : DEFAULT_DASH;
        // inspection text
        _activity._inspectionText = activity.attributes?.inspectionText
            ? markdownToHtml(activity.attributes?.inspectionText)
            : DEFAULT_DASH;
        _activity._itemInstance = activity.itemInstance
            ? transformItemInstances([activity.itemInstance])[0]
            : null;
        return _activity;
    });
};
