import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
let NotificationModule = class NotificationModule extends Vue {
    notifications = [];
    get nextNotification() {
        return this.notifications[this.notifications.length - 1];
    }
    add(notification) {
        this.notifications.push(notification);
    }
    remove(notification) {
        const removalIndex = this.notifications.map((_) => _.text).indexOf(notification.text);
        if (removalIndex === -1) {
            return;
        }
        this.notifications.splice(removalIndex, 1);
    }
    clear() {
        this.notifications = [];
    }
};
NotificationModule = __decorate([
    Component
], NotificationModule);
export default NotificationModule;
