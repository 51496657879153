import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component } from 'vue-property-decorator';
import { ChangeSetStatus, ChangeSetType } from '~/db_types';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { COSMIC_USER_APPROVALS_POLLING_RATE } from '~/nasa_ui/constants/approvals';
import { CosmicUserGroupFragment } from '~/nasa_ui/DAL/user';
import { EntityType } from '~/nasa_ui/types';
import { ChangeSetTypeDisplay } from '~/nasa_ui/types/enums/change-set';
import { cDocsChangeSetTypes } from '~/nasa_ui/types/enums/document';
import { transformUserGroups } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
import { buildIconAsString } from '~/nasa_ui/utils/ui/buildIconAsString';
import { CosmicIcons } from '~/nasa_ui/utils/ui/CosmicIcons';
let MenuMyCosmicApprovals = class MenuMyCosmicApprovals extends BaseCosmic {
    alertLimit = 5;
    fetchFailed = false;
    isLoading = false;
    timerId = null; // interval for polling for updates
    userApprovals = [];
    get approvalMenuItems() {
        return this.filteredUserApprovals
            .map((approval) => {
            const cs = approval.changeSetAuthority?.changeSet;
            // redundant but shuts up TS
            if (!cs || !approval.changeSetAuthority) {
                return null;
            }
            const entityType = cs.inventory
                ? EntityType.INVENTORY
                : cs.itemInstance
                    ? EntityType.ITEM_INSTANCE
                    : cs.itemMaster
                        ? EntityType.ITEM_MASTER
                        : cs.itemDrawing
                            ? EntityType.ITEM_DRAWING
                            : null;
            if (!entityType && cs.subType !== ChangeSetType.ACCESS_REQUEST) {
                return null;
            }
            const iconClass = entityType ? CosmicIcons[entityType] : CosmicIcons[EntityType.CHANGE_SET];
            const icon = buildIconAsString({
                color: entityType || EntityType.CHANGE_SET,
                iconClass
            });
            const url = `/${EntityType.CHANGE_SET}/manage/${cs.id}`;
            const title = ChangeSetTypeDisplay.get(cs.subType) || '';
            const itemNumber = this.$itemNumberDisplay(cs);
            const itemDrawingDescription = cs.itemDrawing?.description;
            const description = `${itemNumber}${itemDrawingDescription ? ' · ' + itemDrawingDescription : ''}`;
            const groupName = transformUserGroups([approval.changeSetAuthority.userGroup])[0].name || '';
            return {
                description,
                groupName,
                icon,
                id: approval.changeSetAuthorityId,
                title,
                url
            };
        })
            .filter((_) => _);
    }
    get approvalsReportUrl() {
        return '/reporting/user_change_set_approvals';
    }
    get badgeCount() {
        if (this.fetchFailed) {
            return '!';
        }
        const count = this.userChangeSetApprovalCount;
        if (count === 0) {
            return null;
        }
        return count > 9 ? '9+' : `${count}`;
    }
    get filteredUserApprovals() {
        return this.userApprovals.filter((approval) => {
            if (!approval?.changeSetAuthority?.changeSet) {
                return false;
            }
            // abort if the change set isn't open
            if (approval.changeSetAuthority.changeSet.computedStatus !== ChangeSetStatus.OPEN) {
                return false;
            }
            // abort if it's a cDocs CS type
            if (cDocsChangeSetTypes.includes(approval.changeSetAuthority.changeSet.subType)) {
                return false;
            }
            return true;
        });
    }
    get userChangeSetApprovalCount() {
        if (!this.userApprovals) {
            return 0;
        }
        return this.approvalMenuItems.length;
    }
    created() {
        // inital fetch on create
        this.fetchCurrentUserApprovals();
        // listen for hardware ChangeSet updates to update navbar alert
        this.$listen('reload_user_alerts', () => {
            this.fetchCurrentUserApprovals();
        });
    }
    async fetchCurrentUserApprovals() {
        try {
            this.isLoading = true;
            this.fetchFailed = false;
            const resp = await this.$apollo.query({
                query: gql `
          ${CosmicUserGroupFragment}

          fragment UpcomingCsaOnApprovalsMenu on UpcomingChangeSetAuthority {
            changeSetAuthorityId
            positionInQueue

            changeSetAuthority {
              id
              nodeId

              changeSet {
                asBuiltNumber
                computedStatus
                drawingNumber
                id
                nodeId
                side
                subType

                inventory {
                  nodeId
                }

                itemDrawing {
                  description
                  nodeId
                }

                itemInstance {
                  nodeId
                  size
                }

                itemMaster {
                  nodeId
                  side
                }
              }

              userGroup {
                ...CosmicUserGroup
              }
            }
          }

          query GetUpcomingChangeSetAuthoritiesForApprovalsMenu {
            currentUser {
              nodeId
              upcomingChangeSetAuthorities(filter: { positionInQueue: { equalTo: 1 } }) {
                totalCount
                nodes {
                  ...UpcomingCsaOnApprovalsMenu
                }
              }
            }
          }
        `,
                fetchPolicy: 'network-only'
            });
            this.isLoading = false;
            if (!resp.data.currentUser) {
                console.error('Couldnt get the current user upcoming csas');
                return null;
            }
            this.userApprovals = resp.data.currentUser.upcomingChangeSetAuthorities.nodes;
            // this could be a manual refresh, reset the timer
            if (this.timerId) {
                clearTimeout(this.timerId);
            }
            // schedule next poll for updates
            // `window` needed because otherwise the type system thinks it's a NodeJS call
            this.timerId = window.setTimeout(() => {
                this.fetchCurrentUserApprovals();
            }, COSMIC_USER_APPROVALS_POLLING_RATE);
        }
        catch (err) {
            this.isLoading = false;
            this.fetchFailed = true;
            this.$errorUtility({
                err,
                suppressNotification: true
            });
        }
    }
};
MenuMyCosmicApprovals = __decorate([
    Component
], MenuMyCosmicApprovals);
export default MenuMyCosmicApprovals;
