import gql from 'graphql-tag';
export const CosmicDocumentSlimFragment = gql `
  fragment CosmicDocumentSlim on Document {
    archiveBoxNumber
    computedCloseDate
    computedIsCosmicManaged
    computedOpenDate
    computedProjectedCloseDate
    computedReviewDate
    computedRevisionNumber
    computedStatus
    contractNumber
    createdDateTime
    description
    id
    initiatorContactId
    isExportControlled
    isProprietary
    lastReviewDate
    nodeId
    number
    projectCode
    releaseStatus
    subType
    tags
    title
  }
`;
