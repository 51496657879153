import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { DESCRIPTION_COL, ITEM_NUMBER_COL, LOT_COL, QUANTITY_COL, SERIAL_COL, UNIT_COST_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import BaseDueInInventoryTable from '../BaseDueInInventoryTable';
let InventoryInTransitTable = class InventoryInTransitTable extends BaseDueInInventoryTable {
    selectedRow = null;
    get computedTableHeaders() {
        return [
            ITEM_NUMBER_COL,
            SERIAL_COL,
            LOT_COL,
            DESCRIPTION_COL,
            { text: 'Issued', value: '_issueDate' },
            { text: 'Issued from Org', value: '_issuedFromOrg', sortable: true },
            QUANTITY_COL,
            UNIT_COST_COL
        ];
    }
    get link() {
        return this.selectedRow && this.selectedRow.inventoryId && this.selectedRow._issuedFromOrg
            ? `/hardware/receive_inventory?inventoryId=${this.selectedRow.inventoryId}&context=${this.selectedRow._issuedFromOrg}`
            : '';
    }
    get selectedRowMatchesContext() {
        return this.selectedRow && this.selectedRow._issuedFromOrg === this.currentUserActiveSupportContext;
    }
};
InventoryInTransitTable = __decorate([
    Component
], InventoryInTransitTable);
export default InventoryInTransitTable;
