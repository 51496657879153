import gql from 'graphql-tag';
export const CosmicInventorySlimFragment = gql `
  fragment CosmicInventorySlim on Inventory {
    asBuiltNumber
    assemblyRemoveRequiresApproval
    assemblyInstallRequiresApproval
    computedEquipmentControlNumber
    computedInstalledDate
    computedIsHazardousMaterial
    computedReadinessStatus
    createdDateTime
    drawingNumber
    expectedReturnDate
    expectedReturnOrganizationCode
    id
    initialReceiptDate
    isArchived
    isBeingAudited
    isCostEstimated
    isInstalled
    issueDestinationLocationId
    issueDestinationOrganizationCode
    itemDrawingDescription
    itemInstanceId
    locationId
    lotNumber
    nodeId
    projectCode
    quantity
    serialNumber
    side
    size
    status
    subType
    totalCost
    unitCost
    updatedDateTime
    workingAssemblyId
  }
`;
