var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('c-modal', {
    attrs: {
      "border-color": _vm.computedBorderColor,
      "dialog-class": _vm.computedContentClass,
      "max-height": _vm.maxHeight,
      "modal-width": _vm.modalWidth
    },
    domProps: {
      "open": true
    },
    on: {
      "c-modal-close": _vm.emitModalClose,
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.emitModalClose.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "app_modal_content"
  }, [_vm._t("content")], 2), _c('div', {
    staticClass: "actions",
    attrs: {
      "slot": "actions"
    },
    slot: "actions"
  }, [_vm._t("actions")], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };