import { FederalSupplyClass } from '~/db_types';
export const FederalSupplyClassFCSRef = new Map([
    [FederalSupplyClass.DRILL_BITS_COUNTERBORES_AND_COUNTERSINKS, '5133'],
    [FederalSupplyClass.TAPS_DIES_AND_COLLETS_HAND_AND_MACHINE, '5136'],
    [FederalSupplyClass.TOOL_AND_HARDWARE_BOXES, '5140'],
    [FederalSupplyClass.SETS_KITS_AND_OUTFITS_OF_HAND_TOOLS, '5180'],
    [FederalSupplyClass.MEASURING_TOOLS_CRAFTSMENS, '5210'],
    [FederalSupplyClass.INSPECTION_GAGES_AND_PRECISION_LAYOUT_TOOLS, '5220'],
    [FederalSupplyClass.SETS_KITS_AND_OUTFITS_OF_MEASURING_TOOLS, '5280'],
    [FederalSupplyClass.SCREWS, '5305'],
    [FederalSupplyClass.BOLTS, '5306'],
    [FederalSupplyClass.STUDS, '5307'],
    [FederalSupplyClass.NUTS_AND_WASHERS, '5310'],
    [FederalSupplyClass.NAILS_KEYS_AND_PINS, '5315'],
    [FederalSupplyClass.RIVETS, '5320'],
    [FederalSupplyClass.FASTENING_DEVICES, '5325'],
    [FederalSupplyClass.PACKING_AND_GASKET_MATERIALS, '5330'],
    [FederalSupplyClass.METAL_SCREENING, '5335'],
    [FederalSupplyClass.MISCELLANEOUS_HARDWARE, '5340'],
    [FederalSupplyClass.DISKS_AND_STONES_ABRASIVE, '5345'],
    [FederalSupplyClass.ABRASIVE_MATERIALS, '5350'],
    [FederalSupplyClass.KNOBS_AND_POINTERS, '5355'],
    [FederalSupplyClass.O_RINGS_RINGS_SHIMS_AND_SPACERS, '5331'],
    [FederalSupplyClass.PREFABRICATED_AND_PORTABLE_BUILDINGS, '5410'],
    [FederalSupplyClass.RIGID_WALL_SHELTERS, '5411'],
    [FederalSupplyClass.BRIDGES_FIXED_AND_FLOATING, '5420'],
    [FederalSupplyClass.STORAGE_TANKS, '5430'],
    [FederalSupplyClass.SCAFFOLDING_EQUIPMENT_AND_CONCRETE_FORMS, '5440'],
    [FederalSupplyClass.PREFABRICATED_TOWER_STRUCTURES, '5445'],
    [FederalSupplyClass.MISCELLANEOUS_FABRICATED_STRUCTURES, '5450'],
    [FederalSupplyClass.LUMBER_AND_RELATED_BASIC_WOOD_MATERIALS, '5510'],
    [FederalSupplyClass.MILLWORK, '5520'],
    [FederalSupplyClass.PLYWOOD_AND_VENEER, '5530'],
    [FederalSupplyClass.MINERAL_CONSTRUCTION_MATERIALS_BULK, '5610'],
    [FederalSupplyClass.BUILDING_GLASS_TILE_BRICK_AND_BLOCK, '5620'],
    [FederalSupplyClass.PIPE_AND_CONDUIT_NONMETALLIC, '5630'],
    [FederalSupplyClass.WALLBOARD_BUILDING_PAPER_AND_THERMAL, '5640'],
    [FederalSupplyClass.ROOFING_AND_SIDING_MATERIALS, '5650'],
    [FederalSupplyClass.BUILDING_COMPONENTS_PREFABRICATED, '5670'],
    [FederalSupplyClass.SPACE_SURVIVAL_EQUIPMENT, '5731'],
    [FederalSupplyClass.MISCELLANEOUS_CONSTRUCTION_MATERIALS, '5680'],
    [FederalSupplyClass.TELEPHONE_AND_TELEGRAPH_EQUIPMENT, '5805'],
    [FederalSupplyClass.COMMUNICATIONS_SECURITY_EQUIPMENT, '5810'],
    [FederalSupplyClass.OTHER_CRYPTOLOGIC_EQUIPMENT_AND_COMPONENTS, '5811'],
    [FederalSupplyClass.TELETYPE_AND_FACSIMILE_EQUIPMENT, '5815'],
    [FederalSupplyClass.RADIO_AND_TELEVISION_COMMUNICATION_AIRBORNE, '5820'],
    [FederalSupplyClass.RADIO_AND_TELEVISION_COMMUNICATION_AIRBORNE, '5821'],
    [FederalSupplyClass.INTERCOMMUNICATION_AND_PUBLIC_ADDRESS_S_AIRBORNE, '5831'],
    [FederalSupplyClass.RADIO_NAVIGATION_EQUIPMENT_EXCEPT_AIRBORNE, '5825'],
    [FederalSupplyClass.RADIO_NAVIGATION_EQUIPMENT_AIRBORNE, '5826'],
    [FederalSupplyClass.INTERCOMMUNICATION_AND_PUBLIC_ADDRESS_S_EXCEPT, '5830'],
    [FederalSupplyClass.SOUND_RECORDING_AND_REPRODUCING_EQUIPMENT, '5835'],
    [FederalSupplyClass.VIDEO_RECORDING_AND_REPRODUCING_EQUIPMENT, '5836'],
    [FederalSupplyClass.RADAR_EQUIPMENT_EXCEPT_AIRBORNE, '5840'],
    [FederalSupplyClass.RADAR_EQUIPMENT_AIRBORNE, '5841'],
    [FederalSupplyClass.UNDERWATER_SOUND_EQUIPMENT, '5845'],
    [FederalSupplyClass.VISIBLE_AND_INVISIBLE_LIGHT_COMMUNICATION, '5850'],
    [FederalSupplyClass.NIGHT_VISION_EQUIPMENT_EMITTED_AND_REFLECTED, '5855'],
    [FederalSupplyClass.STIMULATED_COHERENT_RADIATION_DEVICES_COMPONENTS, '5860'],
    [FederalSupplyClass.ELECTRONIC_COUNTERMEASURES_COUNTER, '5865'],
    [FederalSupplyClass.MISCELLANEOUS_COMMUNICATION_EQUIPMENT, '5895'],
    [FederalSupplyClass.RESISTORS, '5905'],
    [FederalSupplyClass.CAPACITORS, '5910'],
    [FederalSupplyClass.FILTERS_AND_NETWORKS, '5915'],
    [FederalSupplyClass.FUSES_ARRESTERS_ABSORBERS_AND_PROTECTORS, '5920'],
    [FederalSupplyClass.CIRCUIT_BREAKERS, '5925'],
    [FederalSupplyClass.CONNECTORS_ELECTRICAL, '5935'],
    [FederalSupplyClass.LUGS_TERMINALS_AND_TERMINAL_STRIPS, '5940'],
    [FederalSupplyClass.RELAYS_AND_SOLENOIDS, '5945'],
    [FederalSupplyClass.OSCILLATORS_AND_PIEZOELECTRIC_CRYSTALS, '5955'],
    [FederalSupplyClass.ELECTRON_TUBES_ASSOCIATED_HARDWARE, '5960'],
    [FederalSupplyClass.SEMICONDUCTOR_DEVICES_AND_ASSOCIATED_HARDWARE, '5961'],
    [FederalSupplyClass.MICROCIRCUITS_ELECTRONIC, '5962'],
    [FederalSupplyClass.ELECTRONIC_MODULES, '5963'],
    [FederalSupplyClass.HEADSETS_HANDSETS_MICROPHONES_AND_SPEAKERS, '5965'],
    [FederalSupplyClass.ELECTRICAL_INSULATORS_AND_INSULATING_MATERIALS, '5970'],
    [FederalSupplyClass.ELECTRICAL_HARDWARE_AND_SUPPLIES, '5975'],
    [FederalSupplyClass.ELECTRICAL_CONTACT_BRUSHES_AND_ELECTRODES, '5977'],
    [FederalSupplyClass.OPTOELECTRONIC_DEVICES_AND_ASSOCIATED_HARDWARE, '5980'],
    [FederalSupplyClass.ANTENNAS_WAVEGUIDE_AND_RELATED_EQUIPMENT, '5985'],
    [FederalSupplyClass.SYNCHROS_AND_RESOLVERS, '5990'],
    [FederalSupplyClass.CABLE_CORD_AND_WIRE_ASSEMBLIES_COMMUNICATION, '5995'],
    [FederalSupplyClass.ELECTRICAL_AND_ELECTRONIC_ASSEMBLIES_BOARDS, '5998'],
    [FederalSupplyClass.MISCELLANEOUS_ELECTRICAL_AND_ELECTRONIC_COMPONENT, '5999'],
    [FederalSupplyClass.ROTARY_JOINTS, '6004'],
    [FederalSupplyClass.COUPLERS_SPLITTERS_AND_MIXERS, '6005'],
    [FederalSupplyClass.ATTENUATORS, '6006'],
    [FederalSupplyClass.FILTERS, '6007'],
    [FederalSupplyClass.OPTICAL_MULTIPLEXERS_DEMULTIPLEXERS, '6008'],
    [FederalSupplyClass.FIBER_OPTIC_CONDUCTORS, '6010'],
    [FederalSupplyClass.FIBER_OPTIC_CABLES, '6015'],
    [FederalSupplyClass.FIBER_OPTIC_CABLE_ASSEMBLIES_AND_HARNESSES, '6020'],
    [FederalSupplyClass.FIBER_OPTIC_SWITCHES, '6021'],
    [FederalSupplyClass.FIBER_OPTIC_TRANSMITTERS, '6025'],
    [FederalSupplyClass.FIBER_OPTIC_RECEIVERS, '6026'],
    [FederalSupplyClass.OPTICAL_REPEATERS, '6029'],
    [FederalSupplyClass.FIBER_OPTIC_DEVICES, '6030'],
    [FederalSupplyClass.INTEGRATED_OPTICAL_CIRCUITS, '6031'],
    [FederalSupplyClass.FIBER_OPTIC_LIGHT_SOURCES, '6032'],
    [FederalSupplyClass.FIBER_OPTIC_PHOTO_DETECTORS, '6033'],
    [FederalSupplyClass.FIBER_OPTIC_MODULATORS_DEMODULATORS, '6034'],
    [FederalSupplyClass.FIBER_OPIC_LIGHT_TRANSFER_AND_IMAGE_TRANSFER, '6035'],
    [FederalSupplyClass.FIBER_OPTIC_SENSORS, '6040'],
    [FederalSupplyClass.FIBER_OPTIC_PASSIVE_DEVICES, '6050'],
    [FederalSupplyClass.FIBER_OPTIC_INTERCONNECTORS, '6060'],
    [FederalSupplyClass.FIBER_OPTIC_ACCESSORIES_AND_SUPPLIES, '6070'],
    [FederalSupplyClass.FIBER_OPTIC_KITS_AND_SETS, '6080'],
    [FederalSupplyClass.MISCELLANEOUS_FIBER_OPTIC_COMPONENTS, '6099'],
    [FederalSupplyClass.MOTORS_ELECTRICAL, '6105'],
    [FederalSupplyClass.ELECTRICAL_CONTROL_EQUIPMENT, '6110'],
    [FederalSupplyClass.GENERATORS_AND_GENERATOR_SETS_ELECTRICAL, '6115'],
    [FederalSupplyClass.FUEL_CELL_POWER_UNITS_COMPONENTS, '6116'],
    [FederalSupplyClass.SOLAR_ELECTRIC_POWER_SYSTEMS, '6117'],
    [FederalSupplyClass.TRANSFORMERS_DISTRIBUTION_AND_POWER_STATION, '6120'],
    [FederalSupplyClass.CONVERTERS_ELECTRICAL_ROTATING_RESIDUE, '6125'],
    [FederalSupplyClass.CONVERTERS_ELECTRICAL_NONROTATING, '6130'],
    [FederalSupplyClass.BATTERIES_NONRECHARGEABLE, '6135'],
    [FederalSupplyClass.BATTERIES_RECHARGEABLE, '6140'],
    [FederalSupplyClass.WIRE_AND_CABLE_ELECTRICAL, '6145'],
    [FederalSupplyClass.MISCELLANEOUS_ELECTRIC_POWER_AND_DISTRIBUTION, '6150'],
    [FederalSupplyClass.INDOOR_AND_OUTDOOR_ELECTRIC_LIGHTING_FIXTURES, '6210'],
    [FederalSupplyClass.ELECTRIC_VEHICULAR_LIGHTS_AND_FIXTURES, '6220'],
    [FederalSupplyClass.ELECTRIC_PORTABLE_AND_HAND_LIGHTING_EQUIPMENT, '6230'],
    [FederalSupplyClass.ELECTRIC_LAMPS, '6240'],
    [FederalSupplyClass.BALLASTS_LAMPHOLDERS_AND_STARTERS, '6250'],
    [FederalSupplyClass.NONELECTRICAL_LIGHTING_FIXTURES, '6260'],
    [FederalSupplyClass.TRAFFIC_AND_TRANSIT_SIGNAL_SYSTEMS, '6310'],
    [FederalSupplyClass.RAILROAD_SIGNAL_AND_WARNING_DEVICES, '6330'],
    [FederalSupplyClass.AIRCRAFT_ALARM_AND_SIGNAL_SYSTEMS, '6340'],
    [FederalSupplyClass.MISCELLANEOUS_ALARM_SIGNAL_AND_SECURITY, '6350'],
    [FederalSupplyClass.DRUGS_AND_BIOLOGICALS, '6505'],
    [FederalSupplyClass.MEDICATED_COSMETICS_AND_TOILETRIES, '6508'],
    [FederalSupplyClass.SURGICAL_DRESSING_MATERIALS, '6510'],
    [FederalSupplyClass.MEDICAL_AND_SURGICAL_INSTRUMENTS_EQUIPMENT, '6515'],
    [FederalSupplyClass.DENTAL_INSTRUMENTS_EQUIPMENT_AND_SUPPLIES, '6520'],
    [FederalSupplyClass.X_RAY_EQUIPMENT_AND_SUPPLIES_MEDICAL_DENTAL, '6525'],
    [FederalSupplyClass.HOSPITAL_FURNITURE_EQUIPMENT_UTENSILS, '6530'],
    [FederalSupplyClass.HOSPITAL_AND_SURGICAL_CLOTHING_AND_RELATED, '6532'],
    [FederalSupplyClass.OPHTHALMIC_INSTRUMENT_EQUIPMENT, '6540'],
    [FederalSupplyClass.REPLENISHABLE_FIELD_MEDICAL_SETS_KITS, '6545'],
    [FederalSupplyClass.IN_VITRO_DIAGNOSTIC_SUBSTANCES_REAGENTS_TEST, '6550'],
    [FederalSupplyClass.NAVIGATIONAL_INSTRUMENTS, '6605'],
    [FederalSupplyClass.FLIGHT_INSTRUMENTS, '6610'],
    [FederalSupplyClass.AUTOMATIC_PILOT_MECHANISMS_AND_AIRBORNE, '6615'],
    [FederalSupplyClass.ENGINE_INSTRUMENTS, '6620'],
    [FederalSupplyClass.ELECTRICAL_AND_ELECTRONIC_PROPERTIES, '6625'],
    [FederalSupplyClass.CHEMICAL_ANALYSIS_INSTRUMENTS, '6630'],
    [FederalSupplyClass.PHYSICAL_PROPERTIES_TESTING_EQUIPMENT, '6635'],
    [FederalSupplyClass.ENVIRONMENTAL_CHAMBERS_AND_RELATED_EQUIPMENT, '6636'],
    [FederalSupplyClass.LABORATORY_EQUIPMENT_AND_SUPPLIES, '6640'],
    [FederalSupplyClass.TIME_MEASURING_INSTRUMENTS, '6645'],
    [FederalSupplyClass.OPTICAL_INSTRUMENTS_TEST_EQUIPMENT_COMPONENTS, '6650'],
    [FederalSupplyClass.GEOPHYSICAL_INSTRUMENTS, '6655'],
    [FederalSupplyClass.METEROLOGICAL_INSTRUMENTS_AND_APPARATUS, '6660'],
    [FederalSupplyClass.HAZARD_DETECTING_INSTRUMENTS_AND_APPARATUS, '6665'],
    [FederalSupplyClass.SCALES_AND_BALANCES, '6670'],
    [FederalSupplyClass.DRAFTING_SURVEYING_AND_MAPPING_INSTRUMENTS, '6675'],
    [FederalSupplyClass.LIQUID_AND_GAS_FLOW_LIQUID_LEVEL, '6680'],
    [FederalSupplyClass.PRESSURE_TEMPERATURE_AND_HUMIDITY_MEASURING, '6685'],
    [FederalSupplyClass.COMBINATION_AND_MISCELLANEOUS_INSTRUMENTS, '6695'],
    [FederalSupplyClass.CAMERAS_MOTION_PICTURE, '6710'],
    [FederalSupplyClass.CAMERAS_STILL_PICTURE, '6720'],
    [FederalSupplyClass.PHOTOGRAPHIC_PROJECTION_EQUIPMENT, '6730'],
    [FederalSupplyClass.PHOTOGRAPHIC_DEVELOPING_AND_FINISHING, '6740'],
    [FederalSupplyClass.PHOTOGRAPHIC_SUPPLIES, '6750'],
    [FederalSupplyClass.PHOTOGRAPHIC_EQUIPMENT_AND_ACCESSORIES, '6760'],
    [FederalSupplyClass.FILM_PROCESSED, '6770'],
    [FederalSupplyClass.PHOTOGRAPHIC_SETS_KITS_AND_OUTFITS, '6780'],
    [FederalSupplyClass.CHEMICALS, '6810'],
    [FederalSupplyClass.DYES, '6820'],
    [FederalSupplyClass.GASES_COMPRESSED_AND_LIQUEFIED, '6830'],
    [FederalSupplyClass.PEST_CONTROL_AGENTS_AND_DISINFECTANTS, '6840'],
    [FederalSupplyClass.MISCELLANEOUS_CHEMICAL_SPECIALTIES, '6850'],
    [FederalSupplyClass.OPERATION_TRAINING_DEVICES, '6930'],
    [FederalSupplyClass.TRAINING_AIDS, '6910'],
    [FederalSupplyClass.ARMAMENT_TRAINING_DEVICES, '6920'],
    [FederalSupplyClass.COMMUNICATION_TRAINING_DEVICES, '6940'],
    [FederalSupplyClass.ADPE_SYSTEMS_CONFIGURATION, '7010'],
    [FederalSupplyClass.ADP_CENTRAL_PROCESSING_UNIT_CC_ANALOG, '7020'],
    [FederalSupplyClass.ADP_CENTRAL_PROCESSING_UNIT_CC_DIGITAL, '7021'],
    [FederalSupplyClass.ADP_CENTRAL_PROCESSING_UNIT_CC_HYBRID, '7022'],
    [FederalSupplyClass.ADP_INPUT_OUTPUT_AND_STORAGE_DEVICES, '7025'],
    [FederalSupplyClass.ADP_SOFTWARE, '7030'],
    [FederalSupplyClass.ADP_SUPPORT_EQUIPMENT, '7035'],
    [FederalSupplyClass.PUNCHED_CARD_EQUIPMENT, '7040'],
    [FederalSupplyClass.MINI_AND_MICRO_COMPUTER_CONTROL_DEVICES, '7042'],
    [FederalSupplyClass.ADP_SUPPLIES, '7045'],
    [FederalSupplyClass.ADP_COMPONENTS, '7050'],
    [FederalSupplyClass.HOUSEHOLD_FURNITURE, '7105'],
    [FederalSupplyClass.OFFICE_FURNITURE, '7110'],
    [FederalSupplyClass.CABINETS_LOCKERS_BINS_AND_SHELVING, '7125'],
    [FederalSupplyClass.MISCELLANEOUS_FURNITURE_AND_FIXTURES, '7195'],
    [FederalSupplyClass.HOUSEHOLD_FURNISHINGS, '7210'],
    [FederalSupplyClass.FLOOR_COVERINGS, '7220'],
    [FederalSupplyClass.DRAPERIES_AWNINGS_AND_SHADES, '7230'],
    [FederalSupplyClass.HOUSEHOLD_AND_COMMERCIAL_UTILITY_CONTAINERS, '7240'],
    [FederalSupplyClass.MISCELLANEOUS_HOUSEHOLD_AND_COMMERCIAL, '7290'],
    [FederalSupplyClass.FOOD_COOKING_BAKING_AND_SERVING_EQUIPMENT, '7310'],
    [FederalSupplyClass.KITCHEN_EQUIPMENT_AND_APPLIANCES, '7320'],
    [FederalSupplyClass.KITCHEN_HAND_TOOLS_AND_UTENSILS, '7330'],
    [FederalSupplyClass.CUTLERY_AND_FLATWARE, '7340'],
    [FederalSupplyClass.TABLEWARE, '7350'],
    [FederalSupplyClass.SETS_KITS_AND_OUTFITS_FOOD_PREPARATION, '7360'],
    [FederalSupplyClass.ACCOUNTING_AND_CALCULATING_MACHINES, '7420'],
    [FederalSupplyClass.TYPEWRITERS_AND_OFFICE_TYPE_COMPOSING, '7430'],
    [FederalSupplyClass.OFFICE_INFORMATION_SYSTEM_EQUIPMENT, '7435'],
    [FederalSupplyClass.OFFICE_TYPE_SOUND_RECORDING_AND_REPRODUCING, '7450'],
    [FederalSupplyClass.VISIBLE_RECORD_EQUIPMENT, '7460'],
    [FederalSupplyClass.MISCELLANEOUS_OFFICE_MACHINES, '7490'],
    [FederalSupplyClass.OFFICE_SUPPLIES, '7510'],
    [FederalSupplyClass.OFFICE_DEVICES_AND_ACCESSORIES, '7520'],
    [FederalSupplyClass.STATIONERY_AND_RECORD_FORMS, '7530'],
    [FederalSupplyClass.STANDARD_FORMS, '7540'],
    [FederalSupplyClass.BOOKS_AND_PAMPHLETS, '7610'],
    [FederalSupplyClass.NEWSPAPERS_AND_PERIODICALS, '7630'],
    [FederalSupplyClass.MAPS_ATLASES_CHARTS_AND_GLOBES, '7640'],
    [FederalSupplyClass.DRAWINGS_AND_SPECIFICATIONS, '7650'],
    [FederalSupplyClass.SHEET_AND_BOOK_MUSIC, '7660'],
    [FederalSupplyClass.MICROFILM_PROCESSED, '7670'],
    [FederalSupplyClass.MISCELLANEOUS_PRINTED_MATTER, '7690'],
    [FederalSupplyClass.MUSICAL_INSTRUMENTS, '7710'],
    [FederalSupplyClass.MUSICAL_INSTRUMENT_PARTS_AND_ACCESSORIES, '7720'],
    [FederalSupplyClass.PHONOGRAPHS_RADIOS_AND_TELEVISION_SETS, '7730'],
    [FederalSupplyClass.PHONOGRAPH_RECORDS, '7740'],
    [FederalSupplyClass.ATHLETIC_AND_SPORTING_EQUIPMENT, '7810'],
    [FederalSupplyClass.GAMES_TOYS_AND_WHEELED_GOODS, '7820'],
    [FederalSupplyClass.RECREATIONAL_AND_GYMNASTIC_EQUIPMENT, '7830'],
    [FederalSupplyClass.FLOOR_POLISHERS_AND_VACUUM_CLEANING_EQUIPMENT, '7910'],
    [FederalSupplyClass.BROOMS_BRUSHES_MOPS_AND_SPONGES, '7920'],
    [FederalSupplyClass.CLEANING_AND_POLISHING_COMPOUNDS, '7930'],
    [FederalSupplyClass.PAINTS_DOPES_VARNISHES_AND_RELATED_PRODUCTS, '8010'],
    [FederalSupplyClass.PAINT_AND_ARTISTS_BRUSHES, '8020'],
    [FederalSupplyClass.PRESERVATIVE_AND_SEALING_COMPOUNDS, '8030'],
    [FederalSupplyClass.ADHESIVES, '8040'],
    [FederalSupplyClass.BAGS_AND_SACKS, '8105'],
    [FederalSupplyClass.DRUMS_AND_CANS, '8110'],
    [FederalSupplyClass.BOXES_CARTONS_AND_CRATES, '8115'],
    [FederalSupplyClass.COMMERCIAL_AND_INDUSTIAL_GAS_CYLINDERS, '8120'],
    [FederalSupplyClass.BOTTLES_AND_JARS, '8125'],
    [FederalSupplyClass.REELS_AND_SPOOLS, '8130'],
    [FederalSupplyClass.PACKAGING_AND_PACKING_BULK_MATERIALS, '8135'],
    [FederalSupplyClass.AMMUNITION_AND_NUCLEAR_ORDNANCE_BOXES, '8140'],
    [FederalSupplyClass.SPECIALIZED_SHIPPING_AND_STORAGE_CONTAINERS, '8145'],
    [FederalSupplyClass.TEXTILE_FABRICS, '8305'],
    [FederalSupplyClass.YARN_AND_THREAD, '8310'],
    [FederalSupplyClass.NOTIONS_AND_APPAREL_FINDINGS, '8315'],
    [FederalSupplyClass.PADDING_AND_STUFFING_MATERIALS, '8320'],
    [FederalSupplyClass.FUR_MATERIALS, '8325'],
    [FederalSupplyClass.LEATHER, '8330'],
    [FederalSupplyClass.SHOE_FINDINGS_AND_SOLING_MATERIALS, '8335'],
    [FederalSupplyClass.TENTS_AND_TARPAULILNS, '8340'],
    [FederalSupplyClass.FLAGS_AND_PENNANTS, '8345'],
    [FederalSupplyClass.OUTERWEAR_MEN_S, '8405'],
    [FederalSupplyClass.OUTERWEAR_WOMEN_S, '8410'],
    [FederalSupplyClass.UNDERWEAR_AND_NIGHTWEAR_MEN_S, '8420'],
    [FederalSupplyClass.UNDERWEAR_AND_NIGHTWEAR_WOMEN_S, '8425'],
    [FederalSupplyClass.FOOTWEAR_MENS, '8430'],
    [FederalSupplyClass.FOOTWEAR_WOMENS, '8435'],
    [FederalSupplyClass.HOSIERY_HANDWEAR_AND_CLOTHING_MENS, '8440'],
    [FederalSupplyClass.HOSIERY_HANDWEAR_BADGES_AND_CLOTHING_WOMENS, '8445'],
    [FederalSupplyClass.CHILDRENS_AND_INFANTS_APPAREL_ACCESSORIES, '8450'],
    [FederalSupplyClass.BADGES_AND_INSIGNIA, '8455'],
    [FederalSupplyClass.LUGGAGE, '8460'],
    [FederalSupplyClass.INDIVIDUAL_EQUIPMENT_SUNGLASSES, '8465'],
    [FederalSupplyClass.ARMOR_PERSONAL, '8470'],
    [FederalSupplyClass.PERFUMES_TOILET_PREPARATIONS_AND_POWDERS, '8510'],
    [FederalSupplyClass.TOILET_SOAP_SHAVING_PREPARATIONS_DENTIFRICES, '8520'],
    [FederalSupplyClass.PERSONAL_TOILETRY_ARTICLES, '8530'],
    [FederalSupplyClass.TOILETRY_PAPER_PRODUCTS, '8540'],
    [FederalSupplyClass.FORAGE_AND_FEED, '8710'],
    [FederalSupplyClass.FERTILIZERS, '8720'],
    [FederalSupplyClass.SEEDS_AND_NURSERY_STOCK, '8730'],
    [FederalSupplyClass.LIVE_ANIMALS_RAISED_FOR_FOOD, '8810'],
    [FederalSupplyClass.LIVE_ANIMALS_NOT_RAISED_FOR_FOOD, '8820'],
    [FederalSupplyClass.MEAT_POULTRY_AND_FISH, '8905'],
    [FederalSupplyClass.DAIRY_FOODS_AND_EGGS, '8910'],
    [FederalSupplyClass.FRUITS_AND_VEGETABLES, '8915'],
    [FederalSupplyClass.BAKERY_AND_CEREAL_PRODUCTS, '8920'],
    [FederalSupplyClass.SUGAR_CONFECTIONERY_AND_NUTS, '8925'],
    [FederalSupplyClass.JAMS_JELLIES_AND_PRESERVES, '8930'],
    [FederalSupplyClass.SOUPS_AND_BOUILLONS, '8935'],
    [FederalSupplyClass.SPECIAL_DIETARY_FOODS_AND_FOOD_SPECIALTY, '8940'],
    [FederalSupplyClass.FOOD_OILS_AND_FATS, '8945'],
    [FederalSupplyClass.CONDIMENTS_AND_RELATED_PRODUCTS, '8950'],
    [FederalSupplyClass.COFFEE_TEA_AND_COCOA, '8955'],
    [FederalSupplyClass.BEVERAGES_NONALCOHOLIC_H20_WATER, '8960'],
    [FederalSupplyClass.BEVERAGES_ALCOHOLIC, '8965'],
    [FederalSupplyClass.COMPOSITE_FOOD_PACKAGES, '8970'],
    [FederalSupplyClass.TOBACCO_PRODUCTS, '8975'],
    [FederalSupplyClass.FUELS_SOLID, '9110'],
    [FederalSupplyClass.LIQUID_PROPELLANTS_AND_FUELS_PETROLEUM_BASE, '9130'],
    [FederalSupplyClass.LIQUID_PROPELLANTS_AND_OXIDIZERS, '9135'],
    [FederalSupplyClass.FUEL_OILS, '9140'],
    [FederalSupplyClass.OILS_AND_GREASES_CUTTING_LUBRICATING, '9150'],
    [FederalSupplyClass.MISCELLANEOUS_WAXES_OILS_AND_FATS, '9160'],
    [FederalSupplyClass.PAPER_AND_PAPERBOARD, '9310'],
    [FederalSupplyClass.RUBBER_FABRICATED_MATERIALS_CUSHIONS, '9320'],
    [FederalSupplyClass.PLASTICS_FABRICATED_MATERIALS, '9330'],
    [FederalSupplyClass.GLASS_FABRICATED_MATERIALS, '9340'],
    [FederalSupplyClass.REFRACTORIES_AND_FIRE_SURFACING_MATERIALS, '9350'],
    [FederalSupplyClass.MISCELLANEOUS_FABRICATED_NONMETALLIC, '9390'],
    [FederalSupplyClass.CRUDE_GRADES_OF_PLANT_MATERIALS, '9410'],
    [FederalSupplyClass.FIBERS_VEGETABLE_ANIMAL_AND_SYNTHETIC, '9420'],
    [FederalSupplyClass.MISCELLANEOUS_CRUDE_ANIMAL_PRODUCTS, '9430'],
    [FederalSupplyClass.MISCELLANEOUS_CRUDE_AGRICULTURAL_AND_FORESTRY, '9440'],
    [FederalSupplyClass.NONMETALLIC_SCRAP_EXCEPT_TEXTILE, '9450'],
    [FederalSupplyClass.WIRE_NONELECTRICAL_IRON_AND_STEEL, '9505'],
    [FederalSupplyClass.BARS_AND_RODS_IRON_AND_STEEL, '9510'],
    [FederalSupplyClass.PLATE_SHEET_STRIP_AND_FOIL_IRON_AND_STEEL, '9515'],
    [FederalSupplyClass.STRUCTURAL_SHAPES_IRON_AND_STEEL, '9520'],
    [FederalSupplyClass.WIRE_NONELECTRICAL_NONFERROUS_BASE_METAL, '9525'],
    [FederalSupplyClass.BARS_AND_RODS_NONFERROUS_BASE_METAL, '9530'],
    [FederalSupplyClass.PLATE_SHEET_STRIP_AND_FOIL, '9535'],
    [FederalSupplyClass.STRUCTURAL_SHAPES_NONFERROUS_BASE_METAL, '9540'],
    [FederalSupplyClass.PLATE_SHEET_STRIP_FOIL_AND_WIRE, '9545'],
    [FederalSupplyClass.ORES, '9610'],
    [FederalSupplyClass.MINERALS_NATURAL_AND_SYNTHETIC, '9620'],
    [FederalSupplyClass.ADDITIVE_METAL_MATERIALS_AND_MASTER_ALLOYS, '9630'],
    [FederalSupplyClass.IRON_AND_STEEL_PRIMARY_AND_SEMIFINISHED_PRODUCTS, '9640'],
    [FederalSupplyClass.NONFERROUS_BASE_METAL_REFINERY, '9650'],
    [FederalSupplyClass.PRECIOUS_METALS_PRIMARY_FORMS, '9660'],
    [FederalSupplyClass.IRON_AND_STEEL_SCRAP, '9670'],
    [FederalSupplyClass.NONFERROUS_METAL_SCRAP, '9680'],
    [FederalSupplyClass.SIGNS_ADVERTISING_DISPLAYS_AND_IDENTIFICATION, '9905'],
    [FederalSupplyClass.JEWELRY, '9910'],
    [FederalSupplyClass.COLLECTORS_AND_OR_HISTORICAL_ITEMS, '9915'],
    [FederalSupplyClass.SMOKERS_ARTICLES_AND_MATCHES, '9920'],
    [FederalSupplyClass.ECCLESIASTICAL_EQUIPMENT_FURNISHINGS, '9925'],
    [FederalSupplyClass.MEMORIALS_CEMETERIAL_AND_MORTUARY_EQUIPMENT, '9930'],
    [FederalSupplyClass.MISCELLANEOUS_ITEMS, '9999'],
    [FederalSupplyClass.FENCING_FENCES_AND_GATES, '5660'],
    [FederalSupplyClass.SWITCHES, '5930'],
    [FederalSupplyClass.COILS_AND_TRANSFORMERS, '5950'],
    [FederalSupplyClass.SHIPBOARD_ALARM_AND_SIGNAL_SYSTEMS, '6320'],
    [FederalSupplyClass.CLOTHING_SPECIAL_PURPOSE, '8415'],
    [FederalSupplyClass.SPECIALIZED_FLIGHT_CLOTHING_ACCESSORIES, '8475'],
    [FederalSupplyClass.COIL_FLAT_AND_WIRE_SPRINGS, '5360'],
    [FederalSupplyClass.GROUP_10_WEAPONS, '1000'],
    [FederalSupplyClass.GROUP_11_NUCLEAR_ORDNANCE, '1100'],
    [FederalSupplyClass.GROUP_12_FIRE_CONTROL_EQUIPMENT, '1200'],
    [FederalSupplyClass.GROUP_13_AMMUNITION_AND_EXPLOSIVES, '1300'],
    [FederalSupplyClass.GROUP_14_GUIDED_MISSLES, '1400'],
    [FederalSupplyClass.GROUP_15_AIRCRAFT_AND_AIRFRAME_STRUCTURAL_EQUIP, '1500'],
    [FederalSupplyClass.GROUP_16_AIRCRAFT_COMPONENTS_AND_ACCESSORIES, '1600'],
    [FederalSupplyClass.GROUP_17_AIRCRAFT_LAUNCH_LAND_AND_GROUND_EQUIP, '1700'],
    [FederalSupplyClass.GROUP_19_SHIPS_SM_CRAFTS_PONTOONS_FLOATING_DOCKS, '1900'],
    [FederalSupplyClass.GROUP_20_SHIP_AND_MARINE_EQUIPMENT, '2000'],
    [FederalSupplyClass.GROUP_22_RAILWAY_EQUIPMENT, '2200'],
    [FederalSupplyClass.GROUP_23_GROUND_AND_MOTOR_VEHICLES_TRAILERS_CYCLES, '2300'],
    [FederalSupplyClass.GROUP_24_TRACTORS, '2400'],
    [FederalSupplyClass.GROUP_25_VEHICULAR_EQUIPMENT_COMPONENTS, '2500'],
    [FederalSupplyClass.GROUP_26_TIRES_AND_TUBES, '2600'],
    [FederalSupplyClass.GROUP_28_ENGINES_TURBINES_AND_COMPONENTS, '2800'],
    [FederalSupplyClass.GROUP_29_ENGINE_ACCESSORIES, '2900'],
    [FederalSupplyClass.GROUP_30_MECHANICAL_POWERED_TRANSMISSION_EQUIP, '3000'],
    [FederalSupplyClass.GROUP_31_BEARINGS, '3100'],
    [FederalSupplyClass.GROUP_32_WOODWORKING_MACHINERY_AND_EQUIPMENT, '3200'],
    [FederalSupplyClass.GROUP_34_METALWORKING_MACHINERY, '3400'],
    [FederalSupplyClass.GROUP_35_SERVICE_AND_TRADE_EQUIPMENT, '3500'],
    [FederalSupplyClass.GROUP_36_SPECIAL_INDUSTRY_MACHINERY, '3600'],
    [FederalSupplyClass.GROUP_37_AGRICULTURAL_MACHINERY_AND_EQUIPMENT, '3700'],
    [FederalSupplyClass.GROUP_38_CONSTRUCTION_MINING_EXCAVATING_ROAD_EQUIP, '3800'],
    [FederalSupplyClass.GROUP_39_MATERIAL_HANDLING_EQUIPMENT, '3900'],
    [FederalSupplyClass.GROUP_40_ROPE_CABLE_CHAIN_AND_FITTINGS, '4000'],
    [FederalSupplyClass.GROUP_41_REFRIGERATION_AIRCONDITIONING_CIRC_EQUIP, '4100'],
    [FederalSupplyClass.GROUP_42_FIRE_FIGHTING_RESCUE_AND_SAFETY_EQUIP, '4200'],
    [FederalSupplyClass.GROUP_43_PUMPS_AND_COMPRESSORS, '4300'],
    [FederalSupplyClass.GROUP_44_FURNANCE_STEAM_DRYING_EQUIP_NUC_REACTOR, '4400'],
    [FederalSupplyClass.GROUP_45_PLUMBING_HEATING_AND_SANITATION_EQUIP, '4500'],
    [FederalSupplyClass.GROUP_46_WATER_PURIFICATION_EQUIPMENT, '4600'],
    [FederalSupplyClass.GROUP_47_PIPE_TUBING_HOSE_AND_FITTINGS, '4700'],
    [FederalSupplyClass.GROUP_48_VALVES, '4800'],
    [FederalSupplyClass.GROUP_49_MAINTENANCE_AND_REPAIR_SHOP_EQUIP, '4900'],
    [FederalSupplyClass.GROUP_51_HAND_TOOLS, '5100'],
    [FederalSupplyClass.GROUP_52_MEASURING_TOOLS, '5200'],
    [FederalSupplyClass.GROUP_53_HARDWARE_AND_ABRASIVES, '5300'],
    [FederalSupplyClass.GROUP_54_PREFABRICATED_STRUCTURES_AND_SCAFFOLDING, '5400'],
    [FederalSupplyClass.GROUP_55_LUMBER_MILLWORK_PLYWOOD_AND_VANEER, '5500'],
    [FederalSupplyClass.GROUP_56_CONSTRUCTION_AND_BUILDING_MATERIAL, '5600'],
    [FederalSupplyClass.GROUP_58_COMMUNICATION_DETECTION_CO_RADIATION_EQUP, '5800'],
    [FederalSupplyClass.GROUP_60_FIBER_OPTIC_MAT_COMPONENTS_ASSY_ACCESSARY, '6000'],
    [FederalSupplyClass.GROUP_61_ELECTRIC_WIRE_POWER_DISTRIBUTION_EQUIP, '6100'],
    [FederalSupplyClass.GROUP_62_LIGHTING_FIXTURES_AND_LAMPS, '6200'],
    [FederalSupplyClass.GROUP_63_ALARM_SIGNAL_SECURITY_DETECTION_SYS, '6300'],
    [FederalSupplyClass.GROUP_65_MEDICAL_DENTAL_AND_VET_EQUIP_SUPPLIES, '6500'],
    [FederalSupplyClass.GROUP_66_INSTRUMENTS_AND_LABRATORY_EQUIPMENT, '6600'],
    [FederalSupplyClass.GROUP_59_ELECTRICAL_ELECTRONIC_EQUIP_COMPONENTS, '5900'],
    [FederalSupplyClass.GROUP_67_PHOTOGRAPHIC_EQUIPMENT, '6700'],
    [FederalSupplyClass.GROUP_68_CHEMICALS_AND_CHEMICAL_PRODUCTS, '6800'],
    [FederalSupplyClass.GROUP_69_TRAINING_AIDS_AND_DEVICES, '6900'],
    [FederalSupplyClass.GROUP_70_ADPE_EQUIP_SOFTWARE_SUPPLIES_AND_SUPPORT, '7000'],
    [FederalSupplyClass.GROUP_71_FURNITURE, '7100'],
    [FederalSupplyClass.GROUP_72_HOUSE_COMMERCIAL_FURNISHING_APPLIANCES, '7200'],
    [FederalSupplyClass.GROUP_73_FOOD_PREPARATION_AND_SERVICE_EQUIPMENT, '7300'],
    [FederalSupplyClass.GROUP_74_OFFICE_MACH_TEXT_VISIBLE_RECORD_EQUIP, '7400'],
    [FederalSupplyClass.GROUP_75_OFFICE_SUPPLIES_AND_DEVICES, '7500'],
    [FederalSupplyClass.GROUP_77_MUSIC_INSTRUMENT_PHONOGRAPH_HOME_RADIO, '7700'],
    [FederalSupplyClass.GROUP_76_BOOKS_MAPS_AND_OTHER_PUBLICATIONS, '7600'],
    [FederalSupplyClass.GROUP_78_RECREATIONAL_AND_ATHLETIC_EQUIPMENT, '7800'],
    [FederalSupplyClass.GROUP_79_CLEANING_EQUIPMENT_AND_SUPPLIES, '7900'],
    [FederalSupplyClass.GROUP_81_CONTAINERS_PACKAGES_PACKING_SUPPLIES, '8100'],
    [FederalSupplyClass.GROUP_80_BRUSHES_PAINTS_SEALERS_AND_ADHESIVES, '8000'],
    [FederalSupplyClass.GROUP_83_TEXTILESFURSAPPARELSHOESTENTSFLAGS, '8300'],
    [FederalSupplyClass.GROUP_84_CLOTHING_INDIVIUAL_EQUIPMENT_AND_INSIGNIA, '8400'],
    [FederalSupplyClass.GROUP_85_TOILETRIES, '8500'],
    [FederalSupplyClass.GROUP_87_AGRICULTURE_SUPPLIES, '8600'],
    [FederalSupplyClass.GROUP_88_LIVE_ANIMALS, '8800'],
    [FederalSupplyClass.GROUP_89_SUBSISTENCE, '8900'],
    [FederalSupplyClass.GROUP_91_FUELS_LUBRICATIONS_OILS_AND_WAXES, '9100'],
    [FederalSupplyClass.GROUP_93_NON_METALLIC_FABRICATION_MATERIAL, '9300'],
    [FederalSupplyClass.GROUP_94_NON_METALLIC_RUDE_MATERIAL, '9400'],
    [FederalSupplyClass.GROUP_95_METAL_BARS_SHEETS_AND_SHAPES, '9500'],
    [FederalSupplyClass.GROUP_96_ORES_MINERALS_THEIR_PRIMARY_PRODUCTS, '9600'],
    [FederalSupplyClass.GROUP_99_MISCELLANEOUS, '9900'],
    [FederalSupplyClass.MISCELLANEOUS_BATTERY_RETAINING_FIXTURES_LINERS, '6160'],
    [FederalSupplyClass.SMALL_CRAFT_POWERED_AND_UNPOWERED_SMALL_CRAFT, '1940'],
    [FederalSupplyClass.TRUCKS_TRUCK_TRACTORS_WHEELED, '2320'],
    [FederalSupplyClass.BUSHINGS_RINGS_SHIMS_AND_SPACERS, '5365'],
    [FederalSupplyClass.MISCELLANEOUS_MAINTENANCE_AND_REPAIR_SHOP, '4940'],
    [FederalSupplyClass.FIRE_CONTROL_MAINTENANCE_AND_REPAIR_SHOP, '4931'],
    [FederalSupplyClass.GUIDED_MISSILE_MAINTENANCE_REPAIR, '4935'],
    [FederalSupplyClass.WEAPONS_MAINTENANCE_AND_REPAIR_SHOP, '4933'],
    [FederalSupplyClass.SPACE_VEHICLE_MAINTENANCE_REPAIR_AND_CHECKOUT, '4960'],
    [FederalSupplyClass.VALVES_NONPOWERED, '4820'],
    [FederalSupplyClass.TORPEDO_MAINTENANCE_REPAIR_AND_CHECKOUT, '4921'],
    [FederalSupplyClass.DEPTH_CHARGES_AND_UNDERWATER_MINES_MAINTENANCE, '4923'],
    [FederalSupplyClass.ROCKET_MAINTENANCE_REPAIR_AND_CHECKOUT, '4927'],
    [FederalSupplyClass.AMMUNITION_MAINTENANCE_REPAIR_AND_CHECKOUT, '4925'],
    [FederalSupplyClass.LUBRICATION_AND_FUEL_DISPENSING_EQUIPMENT, '4930'],
    [FederalSupplyClass.AIRCRAFT_MAINTENANCE_AND_REPAIR_SHOP, '4920'],
    [FederalSupplyClass.CAMOUFLAGE_AND_DECEPTION_EQUIPMENT, '1080'],
    [FederalSupplyClass.EXPLOSIVE_AND_PYROTECHNIC_COMPONENTS, '1145'],
    [FederalSupplyClass.SPECIALIZED_TEST_AND_HANDLING_EQUIPMENT, '1190'],
    [FederalSupplyClass.OPTICAL_SIGHTING_AND_RANGING_EQUIPMENT, '1240'],
    [FederalSupplyClass.MISCELLANEOUS_FIRE_CONTROL_EQUIPMENT, '1290'],
    [FederalSupplyClass.ROCKETS_ROCKET_AMMUNITION_AND_ROCKET_COMPONENTS, '1340'],
    [FederalSupplyClass.PYROTECHNICS, '1370'],
    [FederalSupplyClass.CARTRIDGE_AND_PROPELLANT_ACTUATED_DEVICES, '1377'],
    [FederalSupplyClass.PARACHUTES_AERIAL_PICK_UP_DELIVERY_RECOVERY, '1670'],
    [FederalSupplyClass.GUNS_OVER_125MM_THROUGH_150MM, '1020'],
    [FederalSupplyClass.MISCELLANEOUS_VEHICULAR_COMPONENTS, '2590'],
    [FederalSupplyClass.TIRES_SOLID_AND_CUSHION, '2630'],
    [FederalSupplyClass.MISCELLANEOUS_ENGINES_AND_COMPONENTS, '2895'],
    [FederalSupplyClass.ENGINE_ELECTRICAL_SYSTEM_COMPONENTS_NONAIRCRAFT, '2920'],
    [FederalSupplyClass.ENGINE_COOLING_SYSTEM_COMPONENTS_NONAIRCRAFT, '2930'],
    [FederalSupplyClass.ENGINE_AIR_AND_OIL_FILTERS_STRAINERS_NONAIRCRAFT, '2940'],
    [FederalSupplyClass.TURBOSUPERCHARGERS, '2950'],
    [FederalSupplyClass.MISCELLANEOUS_ENGINE_ACCESSORIES_NONAIRCRAFT, '2990'],
    [FederalSupplyClass.MECHANICAL_POWER_TRANSMISSION_EQUIPMENT, '3010'],
    [FederalSupplyClass.GEARS_PULLEYS_SPROCKETS_AND_TRANSMISSION, '3020'],
    [FederalSupplyClass.BELTING_DRIVE_BELTS_FAN_BELTS_AND_ACCESSORIES, '3030'],
    [FederalSupplyClass.MISCELLANEOUS_POWER_TRANSMISSION_EQUIPMENT, '3040'],
    [FederalSupplyClass.BEARINGS_ANTIFRICTION_UNMOUNTED, '3110'],
    [FederalSupplyClass.BEARINGS_PLAIN_UNMOUNTED, '3120'],
    [FederalSupplyClass.BEARINGS_UNMOUNTED, '3130'],
    [FederalSupplyClass.SAWMILL_AND_PLANING_MILL_MACHINERY, '3210'],
    [FederalSupplyClass.WOODWORKING_MACHINES, '3220'],
    [FederalSupplyClass.TOOLS_AND_ATTACHMENTS_FOR_WOODWORKING_MACHINERY, '3230'],
    [FederalSupplyClass.SAWS_AND_FILING_MACHINES, '3405'],
    [FederalSupplyClass.MACHINING_CENTERS_AND_WAY_TYPE_MACHINES, '3408'],
    [FederalSupplyClass.ELECTRICAL_AND_ULTRASONIC_EROSION_MACHINES, '3410'],
    [FederalSupplyClass.BORING_MACHINES, '3411'],
    [FederalSupplyClass.BROACHING_MACHINES, '3412'],
    [FederalSupplyClass.DRILLING_AND_TAPPING_MACHINES, '3413'],
    [FederalSupplyClass.GEAR_CUTTING_AND_FINISHING_MACHINES, '3414'],
    [FederalSupplyClass.GRINDING_MACHINES, '3415'],
    [FederalSupplyClass.LATHES, '3416'],
    [FederalSupplyClass.MILLING_MACHINES, '3417'],
    [FederalSupplyClass.PLANERS_AND_SHAPERS, '3418'],
    [FederalSupplyClass.MISCELLANEOUS_MACHINE_TOOLS, '3419'],
    [FederalSupplyClass.ROLLING_MILLS_AND_DRAWING_MACHINES, '3422'],
    [FederalSupplyClass.METAL_HEAT_TREATING_AND_NON_THERMAL_TREATING, '3424'],
    [FederalSupplyClass.METAL_FINISHING_EQUIPMENT, '3426'],
    [FederalSupplyClass.ELECTRIC_ARC_WELDING_EQUIPMENT, '3431'],
    [FederalSupplyClass.ELECTRIC_RESISTANCE_WELDING_EQUIPMENT, '3432'],
    [FederalSupplyClass.GAS_WELDING_HEAT_CUTTING_AND_METALIZING, '3433'],
    [FederalSupplyClass.WELDING_POSITIONERS_AND_MANIPULATORS, '3436'],
    [FederalSupplyClass.MISCELLANEOUS_WELDING_EQUIPMENT, '3438'],
    [FederalSupplyClass.MISCELLANEOUS_WELDING_SOLDERING_AND_BRAZING, '3439'],
    [FederalSupplyClass.BENDING_AND_FORMING_MACHINES, '3441'],
    [FederalSupplyClass.HYDRAULIC_AND_PNEUMATIC_PRESSES_POWER, '3442'],
    [FederalSupplyClass.MECHANICAL_PRESSES_POWER_DRIVEN, '3443'],
    [FederalSupplyClass.MANUAL_PRESSES, '3444'],
    [FederalSupplyClass.PUNCHING_AND_SHEARING_MACHINES, '3445'],
    [FederalSupplyClass.FORGING_MACHINERY_AND_HAMMERS, '3446'],
    [FederalSupplyClass.WIRE_AND_METAL_RIBBON_FORMING_MACHINES, '3447'],
    [FederalSupplyClass.RIVETING_MACHINES, '3448'],
    [FederalSupplyClass.MISCELLANEOUS_SECONDARY_METAL_FORMING, '3449'],
    [FederalSupplyClass.MACHINE_TOOLS_PORTABLE, '3450'],
    [FederalSupplyClass.CUTTING_TOOLS_AND_MACHINE_TOOLS, '3455'],
    [FederalSupplyClass.CUTTING_AND_FORMING_TOOLS_FOR_SECONDARY, '3456'],
    [FederalSupplyClass.MACHINE_TOOL_ACCESSORIES, '3460'],
    [FederalSupplyClass.ACCESSORIES_FOR_SECONDARY_METALWORKING, '3461'],
    [FederalSupplyClass.PRODUCTION_JIGS_FIXTURES_AND_TEMPLATES, '3465'],
    [FederalSupplyClass.MACHINE_SHOP_SETS_KITS_AND_OUTFITS, '3470'],
    [FederalSupplyClass.LAUNDRY_AND_DRY_CLEANING_EQUIPMENT, '3510'],
    [FederalSupplyClass.SHOE_REPAIRING_EQUIPMENT, '3520'],
    [FederalSupplyClass.INDUSTRIAL_SEWING_MACHINES_MOBILE_TEXTILE, '3530'],
    [FederalSupplyClass.WRAPPING_AND_PACKAGING_MACHINERY, '3540'],
    [FederalSupplyClass.VENDING_AND_COIN_OPERATED_MACHINES, '3550'],
    [FederalSupplyClass.MISCELLANEOUS_SERVICE_AND_TRADE_EQUIPMENT, '3590'],
    [FederalSupplyClass.FOOD_PRODUCTS_MACHINERY_AND_EQUIPMENT, '3605'],
    [FederalSupplyClass.PRINTING_DUPLICATING_AND_BOOKBINDING, '3610'],
    [FederalSupplyClass.INDUSTRIAL_MARKING_MACHINES, '3611'],
    [FederalSupplyClass.PULP_AND_PAPER_INDUSTRIES_MACHINERY, '3615'],
    [FederalSupplyClass.RUBBER_AND_PLASTICS_WORKING_MACHINERY, '3620'],
    [FederalSupplyClass.TEXTILE_INDUSTRIES_MACHINERY, '3625'],
    [FederalSupplyClass.CLAY_AND_CONCRETE_PRODUCTS_INDUSTRIES, '3630'],
    [FederalSupplyClass.CRYSTAL_AND_GLASS_INDUSTRIES_MACHINERY, '3635'],
    [FederalSupplyClass.TOBACCO_MANUFACTURING_MACHINERY, '3640'],
    [FederalSupplyClass.LEATHER_TANNING_AND_LEATHER_WORKING, '3645'],
    [FederalSupplyClass.CHEMICAL_AND_PHARMACEUTICAL_PRODUCTS, '3650'],
    [FederalSupplyClass.GAS_GENERATING_AND_DISPENSING_SYSTEMS_FIXED, '3655'],
    [FederalSupplyClass.INDUSTRIAL_SIZE_REDUCTION_MACHINERY, '3660'],
    [FederalSupplyClass.SPECIALIZED_SEMICONDUCTOR_MICROCIRCUIT, '3670'],
    [FederalSupplyClass.FOUNDRY_MACHINERY_RELATED_EQUIPMENT_AND_SUPPLIES, '3680'],
    [FederalSupplyClass.SPECIALIZED_METAL_CONTAINER_MANUFACTURING, '3685'],
    [FederalSupplyClass.SPECIALIZED_AMMUNITION_AND_ORDNANCE_MACHINERY, '3690'],
    [FederalSupplyClass.INDUSTRIAL_ASSEMBLY_MACHINES, '3693'],
    [FederalSupplyClass.CLEAN_WORK_STATIONS_FLOWBENCH_CONTROLLED_ENVIR, '3694'],
    [FederalSupplyClass.MISCELLANEOUS_SPECIAL_INDUSTRY_MACHINERY, '3695'],
    [FederalSupplyClass.SOIL_PREPARATION_EQUIPMENT, '3710'],
    [FederalSupplyClass.HARVESTING_EQUIPMENT, '3720'],
    [FederalSupplyClass.DAIRY_POULTRY_AND_LIVESTOCK_EQUIPMENT, '3730'],
    [FederalSupplyClass.PEST_DISEASE_AND_FROST_CONTROL_EQUIPMENT, '3740'],
    [FederalSupplyClass.GARDENING_IMPLEMENTS_AND_TOOLS, '3750'],
    [FederalSupplyClass.AMIMAL_DRAWN_VEHICLES_AND_FARM_TRAILERS, '3760'],
    [FederalSupplyClass.SADDLERY_HARNESS_WHIPS_AND_RELATED_ANIMAL, '3770'],
    [FederalSupplyClass.EARTH_MOVING_AND_EXCAVATING_EQUIPMENT, '3805'],
    [FederalSupplyClass.CRANES_AND_CRANE_SHOVELS, '3810'],
    [FederalSupplyClass.CRANE_AND_CRANE_SHOVEL_ATTACHMENTS, '3815'],
    [FederalSupplyClass.MINING_ROCK_DRILLING_EARTH_BORING, '3820'],
    [FederalSupplyClass.ROAD_CLEARING_AND_CLEANING_EQUIPMENT, '3825'],
    [FederalSupplyClass.TRUCK_AND_TRACTOR_ATTACHMENTS, '3830'],
    [FederalSupplyClass.PETROLEUM_PRODUCTION_AND_DISTRIBUTION, '3835'],
    [FederalSupplyClass.MISCELLANEOUS_CONSTRUCTION_EQUIPMENT, '3895'],
    [FederalSupplyClass.CONVEYORS, '3910'],
    [FederalSupplyClass.MATERIALS_FEEDERS, '3915'],
    [FederalSupplyClass.MATERIALS_HANDLING_EQUIPMENT_NONSELFPROPELLED, '3920'],
    [FederalSupplyClass.WAREHOUSE_TRUCKS_AND_TRACTORS_SELFPROPELLED, '3930'],
    [FederalSupplyClass.BLOCKS_TACKLE_RIGGING_AND_SLINGS, '3940'],
    [FederalSupplyClass.WINCHES_HOISTS_CRANES_AND_DERRICKS, '3950'],
    [FederalSupplyClass.ELEVATORS_AND_ESCALATORS, '3960'],
    [FederalSupplyClass.MISCELLANEOUS_MATERIALS_HANDLING_EQUIPMENT, '3990'],
    [FederalSupplyClass.CHAIN_AND_WIRE_ROPE, '4010'],
    [FederalSupplyClass.FIBER_ROPE_CORDAGE_AND_TWINE, '4020'],
    [FederalSupplyClass.FITTINGS_FOR_ROPE_CABLE_AND_CHAIN, '4030'],
    [FederalSupplyClass.REFRIGERATION_EQUIPMENT, '4110'],
    [FederalSupplyClass.AIR_CONDITIONING_EQUIPMENT, '4120'],
    [FederalSupplyClass.REFRIGERATION_AND_AIR_CONDITIONING_COMPONENTS, '4130'],
    [FederalSupplyClass.FANS_AIR_CIRCULATORS_AND_BLOWER_EQUIPMENT, '4140'],
    [FederalSupplyClass.FIRE_FIGHTING_EQUIPMENT, '4210'],
    [FederalSupplyClass.MARINE_LIFESAVING_AND_DIVING_EQUIPMENT, '4220'],
    [FederalSupplyClass.DECONTAMINATING_AND_IMPREGNATING_EQUIPMENT, '4230'],
    [FederalSupplyClass.SAFETY_AND_RESCUE_EQUIPMENT, '4240'],
    [FederalSupplyClass.COMPRESSORS_AND_VACUUM_PUMPS, '4310'],
    [FederalSupplyClass.POWER_AND_HAND_PUMPS, '4320'],
    [FederalSupplyClass.CENTRIFUGALS_SEPARATORS_H20_FILTER_AND_PRESSURE, '4330'],
    [FederalSupplyClass.INDUSTRIAL_BOILERS, '4410'],
    [FederalSupplyClass.HEAT_EXCHANGERS_AND_STEAM_CONDENSERS, '4420'],
    [FederalSupplyClass.INDUSTRIAL_FURNACES_KILNS_LEHRS_AND_OVENS, '4430'],
    [FederalSupplyClass.DRIERS_DEHYDRATORS_AND_ANHYDRATORS, '4440'],
    [FederalSupplyClass.AIR_PURIFICATION_EQUIPMENT, '4460'],
    [FederalSupplyClass.PLUMBING_FIXTURES_TOWELS_AND_ACCESSORIES, '4510'],
    [FederalSupplyClass.SPACE_HEATING_EQUIPMENT_AND_DOMESTIC, '4520'],
    [FederalSupplyClass.FUEL_BURNING_EQUIPMENT_UNITS, '4530'],
    [FederalSupplyClass.MISCELLANEOUS_PLUMBING_HEATING_AND_SANITAION, '4540'],
    [FederalSupplyClass.WATER_PURIFICATION_EQUIPMENT, '4610'],
    [FederalSupplyClass.WATER_DISTILLATION_EQUIPMENT_MARINE, '4620'],
    [FederalSupplyClass.SEWAGE_TREATMENT_EQUIPMENT, '4630'],
    [FederalSupplyClass.PIPE_AND_TUBE, '4710'],
    [FederalSupplyClass.HOSE_AND_TUBING_FLEXIBLE, '4720'],
    [FederalSupplyClass.FITTINGS_AND_SPECIALTIES_HOSE_PIPEAND_TUBE, '4730'],
    [FederalSupplyClass.VALVES_POWERED, '4810'],
    [FederalSupplyClass.HAND_TOOLS_EDGED_NONPOWERED, '5110'],
    [FederalSupplyClass.HAND_TOOLS_NONEDGED_NONPOWERED, '5120'],
    [FederalSupplyClass.MOTOR_VEHICLE_MAINTENANCE_AND_REPAIR_SHOP, '4910'],
    [FederalSupplyClass.HAND_TOOLS_POWER_DRIVEN, '5130'],
    [FederalSupplyClass.SPACE_VEHICLE_HANDLING_AND_SERVICING_EQUIPMENT, '1735'],
    [FederalSupplyClass.SPACE_VEHICLE_COMPONENTS, '1675'],
    [FederalSupplyClass.BRACKETS, '5341'],
    [FederalSupplyClass.UNKNOWN, 'Unknown']
]);
export const FederalSupplyClassDisplay = new Map([
    [FederalSupplyClass.DRILL_BITS_COUNTERBORES_AND_COUNTERSINKS, 'Drill bits, counterbores, and countersinks;'],
    [FederalSupplyClass.TAPS_DIES_AND_COLLETS_HAND_AND_MACHINE, 'Taps, dies, and collets; hand and machine'],
    [FederalSupplyClass.TOOL_AND_HARDWARE_BOXES, 'Tool and hardware boxes'],
    [FederalSupplyClass.SETS_KITS_AND_OUTFITS_OF_HAND_TOOLS, 'Sets, kits and outfits of hand tools'],
    [FederalSupplyClass.MEASURING_TOOLS_CRAFTSMENS, "Measuring tools, craftsmen's"],
    [FederalSupplyClass.INSPECTION_GAGES_AND_PRECISION_LAYOUT_TOOLS, 'Inspection gages and precision layout tools'],
    [FederalSupplyClass.SETS_KITS_AND_OUTFITS_OF_MEASURING_TOOLS, 'Sets, kits and outfits of measuring tools'],
    [FederalSupplyClass.SCREWS, 'Screws'],
    [FederalSupplyClass.BOLTS, 'Bolts'],
    [FederalSupplyClass.STUDS, 'Studs'],
    [FederalSupplyClass.NUTS_AND_WASHERS, 'Nuts and washers'],
    [FederalSupplyClass.NAILS_KEYS_AND_PINS, 'Nails, keys, and pins'],
    [FederalSupplyClass.RIVETS, 'Rivets'],
    [FederalSupplyClass.FASTENING_DEVICES, 'Fastening devices'],
    [FederalSupplyClass.PACKING_AND_GASKET_MATERIALS, 'Packing and gasket materials'],
    [FederalSupplyClass.METAL_SCREENING, 'Metal screening'],
    [FederalSupplyClass.MISCELLANEOUS_HARDWARE, 'Miscellaneous hardware'],
    [FederalSupplyClass.DISKS_AND_STONES_ABRASIVE, 'Disks and stones, abrasive'],
    [FederalSupplyClass.ABRASIVE_MATERIALS, 'Abrasive materials'],
    [FederalSupplyClass.KNOBS_AND_POINTERS, 'Knobs and pointers'],
    [FederalSupplyClass.O_RINGS_RINGS_SHIMS_AND_SPACERS, 'O rings, rings, shims, and spacers'],
    [FederalSupplyClass.PREFABRICATED_AND_PORTABLE_BUILDINGS, 'Prefabricated and portable buildings'],
    [FederalSupplyClass.RIGID_WALL_SHELTERS, 'Rigid wall shelters'],
    [FederalSupplyClass.BRIDGES_FIXED_AND_FLOATING, 'Bridges, fixed and floating'],
    [FederalSupplyClass.STORAGE_TANKS, 'Storage tanks'],
    [FederalSupplyClass.SCAFFOLDING_EQUIPMENT_AND_CONCRETE_FORMS, 'Scaffolding equipment and concrete forms'],
    [FederalSupplyClass.PREFABRICATED_TOWER_STRUCTURES, 'Prefabricated tower structures'],
    [FederalSupplyClass.MISCELLANEOUS_FABRICATED_STRUCTURES, 'Miscellaneous fabricated structures'],
    [FederalSupplyClass.LUMBER_AND_RELATED_BASIC_WOOD_MATERIALS, 'Lumber, and related basic wood materials'],
    [FederalSupplyClass.MILLWORK, 'Millwork'],
    [FederalSupplyClass.PLYWOOD_AND_VENEER, 'Plywood and veneer'],
    [FederalSupplyClass.MINERAL_CONSTRUCTION_MATERIALS_BULK, 'Mineral construction materials, bulk'],
    [FederalSupplyClass.BUILDING_GLASS_TILE_BRICK_AND_BLOCK, 'Building glass, tile, brick, and block'],
    [FederalSupplyClass.PIPE_AND_CONDUIT_NONMETALLIC, 'Pipe and conduit, nonmetallic'],
    [FederalSupplyClass.WALLBOARD_BUILDING_PAPER_AND_THERMAL, 'Wallboard, building paper, and thermal'],
    [FederalSupplyClass.ROOFING_AND_SIDING_MATERIALS, 'Roofing and siding materials'],
    [FederalSupplyClass.BUILDING_COMPONENTS_PREFABRICATED, 'Building components, prefabricated'],
    [FederalSupplyClass.MISCELLANEOUS_CONSTRUCTION_MATERIALS, 'Miscellaneous construction materials'],
    [FederalSupplyClass.TELEPHONE_AND_TELEGRAPH_EQUIPMENT, 'Telephone and telegraph equipment'],
    [FederalSupplyClass.COMMUNICATIONS_SECURITY_EQUIPMENT, 'COMMUNICATIONS secuity equipment'],
    [FederalSupplyClass.OTHER_CRYPTOLOGIC_EQUIPMENT_AND_COMPONENTS, 'Other cryptologic equipment and components'],
    [FederalSupplyClass.TELETYPE_AND_FACSIMILE_EQUIPMENT, 'Teletype and facsimile equipment'],
    [FederalSupplyClass.RADIO_AND_TELEVISION_COMMUNICATION_EXCEPT, 'Radio and television communication except'],
    [FederalSupplyClass.RADIO_AND_TELEVISION_COMMUNICATION_AIRBORNE, 'Radio and television communication airborne'],
    [
        FederalSupplyClass.INTERCOMMUNICATION_AND_PUBLIC_ADDRESS_S_AIRBORNE,
        'Intercommunication and public address s. airborne'
    ],
    [FederalSupplyClass.RADIO_NAVIGATION_EQUIPMENT_EXCEPT_AIRBORNE, 'Radio navigation equipment, except airborne'],
    [FederalSupplyClass.RADIO_NAVIGATION_EQUIPMENT_AIRBORNE, 'Radio navigation equipment, airborne'],
    [
        FederalSupplyClass.INTERCOMMUNICATION_AND_PUBLIC_ADDRESS_S_EXCEPT,
        'Intercommunication and public address s. except'
    ],
    [FederalSupplyClass.SOUND_RECORDING_AND_REPRODUCING_EQUIPMENT, 'Sound recording and reproducing equipment'],
    [FederalSupplyClass.VIDEO_RECORDING_AND_REPRODUCING_EQUIPMENT, 'Video recording and reproducing equipment'],
    [FederalSupplyClass.RADAR_EQUIPMENT_EXCEPT_AIRBORNE, 'Radar equipment, except airborne'],
    [FederalSupplyClass.RADAR_EQUIPMENT_AIRBORNE, 'Radar equipment, airborne'],
    [FederalSupplyClass.UNDERWATER_SOUND_EQUIPMENT, 'Underwater sound equipment'],
    [FederalSupplyClass.VISIBLE_AND_INVISIBLE_LIGHT_COMMUNICATION, 'Visible and invisible light communication'],
    [FederalSupplyClass.NIGHT_VISION_EQUIPMENT_EMITTED_AND_REFLECTED, 'Night vision equipment, emitted and reflected'],
    [
        FederalSupplyClass.STIMULATED_COHERENT_RADIATION_DEVICES_COMPONENTS,
        'Stimulated coherent radiation devices, components'
    ],
    [FederalSupplyClass.ELECTRONIC_COUNTERMEASURES_COUNTER, 'Electronic countermeasures, counter'],
    [FederalSupplyClass.MISCELLANEOUS_COMMUNICATION_EQUIPMENT, 'Miscellaneous communication equipment'],
    [FederalSupplyClass.RESISTORS, 'Resistors'],
    [FederalSupplyClass.CAPACITORS, 'Capacitors'],
    [FederalSupplyClass.FILTERS_AND_NETWORKS, 'Filters and networks'],
    [FederalSupplyClass.FUSES_ARRESTERS_ABSORBERS_AND_PROTECTORS, 'Fuses, arresters, absorbers, and protectors'],
    [FederalSupplyClass.CIRCUIT_BREAKERS, 'Circuit breakers'],
    [FederalSupplyClass.CONNECTORS_ELECTRICAL, 'Connectors, electrical'],
    [FederalSupplyClass.LUGS_TERMINALS_AND_TERMINAL_STRIPS, 'Lugs, terminals, and terminal strips'],
    [FederalSupplyClass.RELAYS_AND_SOLENOIDS, 'Relays and solenoids'],
    [FederalSupplyClass.OSCILLATORS_AND_PIEZOELECTRIC_CRYSTALS, 'Oscillators and piezoelectric crystals'],
    [FederalSupplyClass.ELECTRON_TUBES_ASSOCIATED_HARDWARE, 'Electron tubes associated hardware'],
    [FederalSupplyClass.SEMICONDUCTOR_DEVICES_AND_ASSOCIATED_HARDWARE, 'Semiconductor devices and associated hardware'],
    [FederalSupplyClass.MICROCIRCUITS_ELECTRONIC, 'Microcircuits, electronic'],
    [FederalSupplyClass.ELECTRONIC_MODULES, 'Electronic modules'],
    [FederalSupplyClass.HEADSETS_HANDSETS_MICROPHONES_AND_SPEAKERS, 'Headsets, handsets, microphones and speakers'],
    [FederalSupplyClass.ELECTRICAL_INSULATORS_AND_INSULATING_MATERIALS, 'Electrical insulators and insulating materials'],
    [FederalSupplyClass.ELECTRICAL_HARDWARE_AND_SUPPLIES, 'Electrical hardware and supplies'],
    [FederalSupplyClass.ELECTRICAL_CONTACT_BRUSHES_AND_ELECTRODES, 'Electrical contact brushes and electrodes'],
    [FederalSupplyClass.OPTOELECTRONIC_DEVICES_AND_ASSOCIATED_HARDWARE, 'Optoelectronic devices and associated hardware'],
    [FederalSupplyClass.ANTENNAS_WAVEGUIDE_AND_RELATED_EQUIPMENT, 'Antennas, waveguide, and related equipment'],
    [FederalSupplyClass.SYNCHROS_AND_RESOLVERS, 'Synchros and resolvers'],
    [FederalSupplyClass.CABLE_CORD_AND_WIRE_ASSEMBLIES_COMMUNICATION, 'Cable, cord and wire assemblies: communication'],
    [FederalSupplyClass.ELECTRICAL_AND_ELECTRONIC_ASSEMBLIES_BOARDS, 'Electrical and electronic assemblies; boards'],
    [
        FederalSupplyClass.MISCELLANEOUS_ELECTRICAL_AND_ELECTRONIC_COMPONENT,
        'Miscellaneous electrical and electronic component'
    ],
    [FederalSupplyClass.ROTARY_JOINTS, 'Rotary joints'],
    [FederalSupplyClass.COUPLERS_SPLITTERS_AND_MIXERS, 'Couplers, splitters, and mixers'],
    [FederalSupplyClass.ATTENUATORS, 'Attenuators'],
    [FederalSupplyClass.FILTERS, 'Filters'],
    [FederalSupplyClass.OPTICAL_MULTIPLEXERS_DEMULTIPLEXERS, 'Optical multiplexers/demultiplexers'],
    [FederalSupplyClass.FIBER_OPTIC_CONDUCTORS, 'Fiber optic conductors'],
    [FederalSupplyClass.FIBER_OPTIC_CABLES, 'Fiber optic cables'],
    [FederalSupplyClass.FIBER_OPTIC_CABLE_ASSEMBLIES_AND_HARNESSES, 'Fiber optic cable assemblies and harnesses'],
    [FederalSupplyClass.FIBER_OPTIC_SWITCHES, 'Fiber optic switches'],
    [FederalSupplyClass.FIBER_OPTIC_TRANSMITTERS, 'Fiber optic transmitters'],
    [FederalSupplyClass.FIBER_OPTIC_RECEIVERS, 'Fiber optic receivers'],
    [FederalSupplyClass.OPTICAL_REPEATERS, 'Optical repeaters'],
    [FederalSupplyClass.FIBER_OPTIC_DEVICES, 'Fiber optic devices'],
    [FederalSupplyClass.INTEGRATED_OPTICAL_CIRCUITS, 'Integrated optical circuits'],
    [FederalSupplyClass.FIBER_OPTIC_LIGHT_SOURCES, 'Fiber optic light sources'],
    [FederalSupplyClass.FIBER_OPTIC_PHOTO_DETECTORS, 'Fiber optic photo detectors'],
    [FederalSupplyClass.FIBER_OPTIC_MODULATORS_DEMODULATORS, 'Fiber optic modulators/demodulators'],
    [FederalSupplyClass.FIBER_OPIC_LIGHT_TRANSFER_AND_IMAGE_TRANSFER, 'Fiber opic light transfer and image transfer'],
    [FederalSupplyClass.FIBER_OPTIC_SENSORS, 'Fiber optic sensors'],
    [FederalSupplyClass.FIBER_OPTIC_PASSIVE_DEVICES, 'Fiber optic passive devices'],
    [FederalSupplyClass.FIBER_OPTIC_INTERCONNECTORS, 'Fiber optic interconnectors'],
    [FederalSupplyClass.FIBER_OPTIC_ACCESSORIES_AND_SUPPLIES, 'Fiber optic accessories and supplies'],
    [FederalSupplyClass.FIBER_OPTIC_KITS_AND_SETS, 'Fiber optic kits and sets'],
    [FederalSupplyClass.MISCELLANEOUS_FIBER_OPTIC_COMPONENTS, 'Miscellaneous fiber optic components'],
    [FederalSupplyClass.MOTORS_ELECTRICAL, 'Motors, electrical'],
    [FederalSupplyClass.ELECTRICAL_CONTROL_EQUIPMENT, 'Electrical control equipment'],
    [FederalSupplyClass.GENERATORS_AND_GENERATOR_SETS_ELECTRICAL, 'Generators and generator sets, electrical'],
    [FederalSupplyClass.FUEL_CELL_POWER_UNITS_COMPONENTS, 'Fuel cell power units, components'],
    [FederalSupplyClass.SOLAR_ELECTRIC_POWER_SYSTEMS, 'Solar electric power systems'],
    [FederalSupplyClass.TRANSFORMERS_DISTRIBUTION_AND_POWER_STATION, 'Transformers: distribution and power station'],
    [FederalSupplyClass.CONVERTERS_ELECTRICAL_ROTATING_RESIDUE, 'Converters, electrical, rotating, residue'],
    [FederalSupplyClass.CONVERTERS_ELECTRICAL_NONROTATING, 'Converters, electrical, nonrotating'],
    [FederalSupplyClass.BATTERIES_NONRECHARGEABLE, 'Batteries, nonrechargeable'],
    [FederalSupplyClass.BATTERIES_RECHARGEABLE, 'Batteries, rechargeable'],
    [FederalSupplyClass.WIRE_AND_CABLE_ELECTRICAL, 'Wire and cable, electrical'],
    [FederalSupplyClass.MISCELLANEOUS_ELECTRIC_POWER_AND_DISTRIBUTION, 'Miscellaneous electric power and distribution'],
    [FederalSupplyClass.INDOOR_AND_OUTDOOR_ELECTRIC_LIGHTING_FIXTURES, 'Indoor and outdoor electric lighting fixtures'],
    [FederalSupplyClass.ELECTRIC_VEHICULAR_LIGHTS_AND_FIXTURES, 'Electric vehicular lights and fixtures'],
    [FederalSupplyClass.ELECTRIC_PORTABLE_AND_HAND_LIGHTING_EQUIPMENT, 'Electric portable and hand lighting equipment'],
    [FederalSupplyClass.ELECTRIC_LAMPS, 'Electric lamps'],
    [FederalSupplyClass.BALLASTS_LAMPHOLDERS_AND_STARTERS, 'Ballasts, lampholders, and starters'],
    [FederalSupplyClass.NONELECTRICAL_LIGHTING_FIXTURES, 'Nonelectrical lighting fixtures'],
    [FederalSupplyClass.TRAFFIC_AND_TRANSIT_SIGNAL_SYSTEMS, 'Traffic and transit signal systems'],
    [FederalSupplyClass.RAILROAD_SIGNAL_AND_WARNING_DEVICES, 'Railroad signal and warning devices'],
    [FederalSupplyClass.AIRCRAFT_ALARM_AND_SIGNAL_SYSTEMS, 'Aircraft alarm and signal systems'],
    [FederalSupplyClass.MISCELLANEOUS_ALARM_SIGNAL_AND_SECURITY, 'Miscellaneous alarm, signal, and security'],
    [FederalSupplyClass.DRUGS_AND_BIOLOGICALS, 'Drugs and biologicals'],
    [FederalSupplyClass.MEDICATED_COSMETICS_AND_TOILETRIES, 'Medicated cosmetics and toiletries'],
    [FederalSupplyClass.SURGICAL_DRESSING_MATERIALS, 'Surgical dressing materials'],
    [FederalSupplyClass.MEDICAL_AND_SURGICAL_INSTRUMENTS_EQUIPMENT, 'Medical and surgical instruments, equipment'],
    [FederalSupplyClass.DENTAL_INSTRUMENTS_EQUIPMENT_AND_SUPPLIES, 'Dental instruments, equipment, and supplies'],
    [FederalSupplyClass.X_RAY_EQUIPMENT_AND_SUPPLIES_MEDICAL_DENTAL, 'X-ray equipment and supplies: medical dental'],
    [FederalSupplyClass.HOSPITAL_FURNITURE_EQUIPMENT_UTENSILS, 'Hospital furniture, equipment, utensils'],
    [FederalSupplyClass.HOSPITAL_AND_SURGICAL_CLOTHING_AND_RELATED, 'Hospital and surgical clothing and related'],
    [FederalSupplyClass.OPHTHALMIC_INSTRUMENT_EQUIPMENT, 'Ophthalmic instrument, equipment'],
    [FederalSupplyClass.REPLENISHABLE_FIELD_MEDICAL_SETS_KITS, 'Replenishable field medical sets, kits'],
    [FederalSupplyClass.IN_VITRO_DIAGNOSTIC_SUBSTANCES_REAGENTS_TEST, 'In vitro diagnostic substances, reagents test'],
    [FederalSupplyClass.NAVIGATIONAL_INSTRUMENTS, 'Navigational instruments'],
    [FederalSupplyClass.FLIGHT_INSTRUMENTS, 'Flight instruments'],
    [FederalSupplyClass.AUTOMATIC_PILOT_MECHANISMS_AND_AIRBORNE, 'Automatic pilot mechanisms and airborne'],
    [FederalSupplyClass.ENGINE_INSTRUMENTS, 'Engine instruments'],
    [FederalSupplyClass.ELECTRICAL_AND_ELECTRONIC_PROPERTIES, 'Electrical and electronic properties'],
    [FederalSupplyClass.CHEMICAL_ANALYSIS_INSTRUMENTS, 'Chemical analysis instruments'],
    [FederalSupplyClass.PHYSICAL_PROPERTIES_TESTING_EQUIPMENT, 'Physical properties testing equipment'],
    [FederalSupplyClass.ENVIRONMENTAL_CHAMBERS_AND_RELATED_EQUIPMENT, 'Environmental chambers and related equipment'],
    [FederalSupplyClass.LABORATORY_EQUIPMENT_AND_SUPPLIES, 'Laboratory equipment and supplies'],
    [FederalSupplyClass.TIME_MEASURING_INSTRUMENTS, 'Time measuring instruments'],
    [FederalSupplyClass.OPTICAL_INSTRUMENTS_TEST_EQUIPMENT_COMPONENTS, 'Optical instruments, test equipment, components'],
    [FederalSupplyClass.GEOPHYSICAL_INSTRUMENTS, 'Geophysical instruments'],
    [FederalSupplyClass.METEROLOGICAL_INSTRUMENTS_AND_APPARATUS, 'Meterological instruments and apparatus'],
    [FederalSupplyClass.HAZARD_DETECTING_INSTRUMENTS_AND_APPARATUS, 'Hazard-detecting instruments and apparatus'],
    [FederalSupplyClass.SCALES_AND_BALANCES, 'Scales and balances'],
    [FederalSupplyClass.DRAFTING_SURVEYING_AND_MAPPING_INSTRUMENTS, 'Drafting, surveying, and mapping instruments'],
    [FederalSupplyClass.LIQUID_AND_GAS_FLOW_LIQUID_LEVEL, 'Liquid and gas flow, liquid level'],
    [FederalSupplyClass.PRESSURE_TEMPERATURE_AND_HUMIDITY_MEASURING, 'Pressure, temperature, and humidity measuring'],
    [FederalSupplyClass.COMBINATION_AND_MISCELLANEOUS_INSTRUMENTS, 'Combination and miscellaneous instruments'],
    [FederalSupplyClass.CAMERAS_MOTION_PICTURE, 'Cameras, motion picture'],
    [FederalSupplyClass.CAMERAS_STILL_PICTURE, 'Cameras, still picture'],
    [FederalSupplyClass.PHOTOGRAPHIC_PROJECTION_EQUIPMENT, 'Photographic projection equipment'],
    [FederalSupplyClass.PHOTOGRAPHIC_DEVELOPING_AND_FINISHING, 'Photographic developing and finishing'],
    [FederalSupplyClass.PHOTOGRAPHIC_SUPPLIES, 'Photographic supplies'],
    [FederalSupplyClass.PHOTOGRAPHIC_EQUIPMENT_AND_ACCESSORIES, 'Photographic equipment and accessories'],
    [FederalSupplyClass.FILM_PROCESSED, 'Film, processed'],
    [FederalSupplyClass.PHOTOGRAPHIC_SETS_KITS_AND_OUTFITS, 'Photographic sets, kits, and outfits'],
    [FederalSupplyClass.CHEMICALS, 'Chemicals'],
    [FederalSupplyClass.DYES, 'Dyes'],
    [FederalSupplyClass.GASES_COMPRESSED_AND_LIQUEFIED, 'Gases: compressed and liquefied'],
    [FederalSupplyClass.PEST_CONTROL_AGENTS_AND_DISINFECTANTS, 'Pest control agents and disinfectants'],
    [FederalSupplyClass.MISCELLANEOUS_CHEMICAL_SPECIALTIES, 'Miscellaneous chemical specialties'],
    [FederalSupplyClass.OPERATION_TRAINING_DEVICES, 'Operation training devices'],
    [FederalSupplyClass.TRAINING_AIDS, 'Training aids'],
    [FederalSupplyClass.ARMAMENT_TRAINING_DEVICES, 'Armament training devices'],
    [FederalSupplyClass.COMMUNICATION_TRAINING_DEVICES, 'Communication training devices'],
    [FederalSupplyClass.ADPE_SYSTEMS_CONFIGURATION, 'Adpe systems configuration'],
    [FederalSupplyClass.ADP_CENTRAL_PROCESSING_UNIT_CC_ANALOG, 'Adp central processing unit (c,c) analog'],
    [FederalSupplyClass.ADP_CENTRAL_PROCESSING_UNIT_CC_DIGITAL, 'Adp central processing unit (c,c) digital'],
    [FederalSupplyClass.ADP_CENTRAL_PROCESSING_UNIT_CC_HYBRID, 'Adp central processing unit (c,c) hybrid'],
    [FederalSupplyClass.ADP_INPUT_OUTPUT_AND_STORAGE_DEVICES, 'Adp input/output and storage devices'],
    [FederalSupplyClass.ADP_SOFTWARE, 'Adp software'],
    [FederalSupplyClass.ADP_SUPPORT_EQUIPMENT, 'Adp support equipment'],
    [FederalSupplyClass.PUNCHED_CARD_EQUIPMENT, 'Punched card equipment'],
    [FederalSupplyClass.MINI_AND_MICRO_COMPUTER_CONTROL_DEVICES, 'Mini and micro computer control devices'],
    [FederalSupplyClass.ADP_SUPPLIES, 'Adp supplies'],
    [FederalSupplyClass.ADP_COMPONENTS, 'Adp components'],
    [FederalSupplyClass.HOUSEHOLD_FURNITURE, 'Household furniture'],
    [FederalSupplyClass.OFFICE_FURNITURE, 'Office furniture'],
    [FederalSupplyClass.CABINETS_LOCKERS_BINS_AND_SHELVING, 'Cabinets, lockers, bins, and shelving'],
    [FederalSupplyClass.MISCELLANEOUS_FURNITURE_AND_FIXTURES, 'Miscellaneous furniture and fixtures'],
    [FederalSupplyClass.HOUSEHOLD_FURNISHINGS, 'Household furnishings'],
    [FederalSupplyClass.FLOOR_COVERINGS, 'Floor coverings'],
    [FederalSupplyClass.DRAPERIES_AWNINGS_AND_SHADES, 'Draperies, awnings, and shades'],
    [FederalSupplyClass.HOUSEHOLD_AND_COMMERCIAL_UTILITY_CONTAINERS, 'Household and commercial utility containers'],
    [FederalSupplyClass.MISCELLANEOUS_HOUSEHOLD_AND_COMMERCIAL, 'Miscellaneous household and commercial'],
    [FederalSupplyClass.FOOD_COOKING_BAKING_AND_SERVING_EQUIPMENT, 'Food cooking, baking, and serving equipment'],
    [FederalSupplyClass.KITCHEN_EQUIPMENT_AND_APPLIANCES, 'Kitchen equipment and appliances'],
    [FederalSupplyClass.KITCHEN_HAND_TOOLS_AND_UTENSILS, 'Kitchen hand tools and utensils'],
    [FederalSupplyClass.CUTLERY_AND_FLATWARE, 'Cutlery and flatware'],
    [FederalSupplyClass.TABLEWARE, 'Tableware'],
    [FederalSupplyClass.SETS_KITS_AND_OUTFITS_FOOD_PREPARATION, 'Sets, kits, and outfits: food preparation'],
    [FederalSupplyClass.ACCOUNTING_AND_CALCULATING_MACHINES, 'Accounting and calculating machines'],
    [FederalSupplyClass.TYPEWRITERS_AND_OFFICE_TYPE_COMPOSING, 'Typewriters and office type composing'],
    [FederalSupplyClass.OFFICE_INFORMATION_SYSTEM_EQUIPMENT, 'Office information system equipment'],
    [FederalSupplyClass.OFFICE_TYPE_SOUND_RECORDING_AND_REPRODUCING, 'Office type sound recording and reproducing'],
    [FederalSupplyClass.VISIBLE_RECORD_EQUIPMENT, 'Visible record equipment'],
    [FederalSupplyClass.MISCELLANEOUS_OFFICE_MACHINES, 'Miscellaneous office machines'],
    [FederalSupplyClass.OFFICE_SUPPLIES, 'Office supplies'],
    [FederalSupplyClass.OFFICE_DEVICES_AND_ACCESSORIES, 'Office devices and accessories'],
    [FederalSupplyClass.STATIONERY_AND_RECORD_FORMS, 'Stationery and record forms'],
    [FederalSupplyClass.STANDARD_FORMS, 'Standard forms'],
    [FederalSupplyClass.BOOKS_AND_PAMPHLETS, 'Books and pamphlets'],
    [FederalSupplyClass.NEWSPAPERS_AND_PERIODICALS, 'Newspapers and periodicals'],
    [FederalSupplyClass.MAPS_ATLASES_CHARTS_AND_GLOBES, 'Maps, atlases, charts, and globes'],
    [FederalSupplyClass.DRAWINGS_AND_SPECIFICATIONS, 'Drawings and specifications'],
    [FederalSupplyClass.SHEET_AND_BOOK_MUSIC, 'Sheet and book music'],
    [FederalSupplyClass.MICROFILM_PROCESSED, 'Microfilm, processed'],
    [FederalSupplyClass.MISCELLANEOUS_PRINTED_MATTER, 'Miscellaneous printed matter'],
    [FederalSupplyClass.MUSICAL_INSTRUMENTS, 'Musical instruments'],
    [FederalSupplyClass.MUSICAL_INSTRUMENT_PARTS_AND_ACCESSORIES, 'Musical instrument parts and accessories'],
    [FederalSupplyClass.PHONOGRAPHS_RADIOS_AND_TELEVISION_SETS, 'Phonographs, radios, and television sets:'],
    [FederalSupplyClass.PHONOGRAPH_RECORDS, 'Phonograph records'],
    [FederalSupplyClass.ATHLETIC_AND_SPORTING_EQUIPMENT, 'Athletic and sporting equipment'],
    [FederalSupplyClass.GAMES_TOYS_AND_WHEELED_GOODS, 'Games, toys, and wheeled goods'],
    [FederalSupplyClass.RECREATIONAL_AND_GYMNASTIC_EQUIPMENT, 'Recreational and gymnastic equipment'],
    [FederalSupplyClass.FLOOR_POLISHERS_AND_VACUUM_CLEANING_EQUIPMENT, 'Floor polishers and vacuum cleaning equipment'],
    [FederalSupplyClass.BROOMS_BRUSHES_MOPS_AND_SPONGES, 'Brooms, brushes, mops, and sponges'],
    [FederalSupplyClass.CLEANING_AND_POLISHING_COMPOUNDS, 'Cleaning and polishing compounds'],
    [FederalSupplyClass.PAINTS_DOPES_VARNISHES_AND_RELATED_PRODUCTS, 'Paints, dopes, varnishes, and related products'],
    [FederalSupplyClass.PAINT_AND_ARTISTS_BRUSHES, "Paint and artists' brushes"],
    [FederalSupplyClass.PRESERVATIVE_AND_SEALING_COMPOUNDS, 'Preservative and sealing compounds'],
    [FederalSupplyClass.ADHESIVES, 'Adhesives'],
    [FederalSupplyClass.BAGS_AND_SACKS, 'Bags and sacks'],
    [FederalSupplyClass.DRUMS_AND_CANS, 'Drums and cans'],
    [FederalSupplyClass.BOXES_CARTONS_AND_CRATES, 'Boxes, cartons, and crates'],
    [FederalSupplyClass.COMMERCIAL_AND_INDUSTIAL_GAS_CYLINDERS, 'Commercial and industial gas cylinders'],
    [FederalSupplyClass.BOTTLES_AND_JARS, 'Bottles and jars'],
    [FederalSupplyClass.REELS_AND_SPOOLS, 'Reels and spools'],
    [FederalSupplyClass.PACKAGING_AND_PACKING_BULK_MATERIALS, 'Packaging and packing bulk materials'],
    [FederalSupplyClass.AMMUNITION_AND_NUCLEAR_ORDNANCE_BOXES, 'Ammunition and nuclear ordnance boxes'],
    [FederalSupplyClass.SPECIALIZED_SHIPPING_AND_STORAGE_CONTAINERS, 'Specialized shipping and storage containers'],
    [FederalSupplyClass.TEXTILE_FABRICS, 'Textile fabrics'],
    [FederalSupplyClass.YARN_AND_THREAD, 'Yarn and thread'],
    [FederalSupplyClass.NOTIONS_AND_APPAREL_FINDINGS, 'Notions and apparel findings'],
    [FederalSupplyClass.PADDING_AND_STUFFING_MATERIALS, 'Padding and stuffing materials'],
    [FederalSupplyClass.FUR_MATERIALS, 'Fur materials'],
    [FederalSupplyClass.LEATHER, 'Leather'],
    [FederalSupplyClass.SHOE_FINDINGS_AND_SOLING_MATERIALS, 'Shoe findings and soling materials'],
    [FederalSupplyClass.TENTS_AND_TARPAULILNS, 'Tents and tarpaulilns'],
    [FederalSupplyClass.FLAGS_AND_PENNANTS, 'Flags and pennants'],
    [FederalSupplyClass.OUTERWEAR_MEN_S, "Outerwear, men's"],
    [FederalSupplyClass.OUTERWEAR_WOMEN_S, "Outerwear, women's"],
    [FederalSupplyClass.UNDERWEAR_AND_NIGHTWEAR_MEN_S, "Underwear and nightwear, men's"],
    [FederalSupplyClass.UNDERWEAR_AND_NIGHTWEAR_WOMEN_S, "Underwear and nightwear, women's"],
    [FederalSupplyClass.FOOTWEAR_MENS, "Footwear, men's"],
    [FederalSupplyClass.FOOTWEAR_WOMENS, "Footwear, women's"],
    [FederalSupplyClass.HOSIERY_HANDWEAR_AND_CLOTHING_MENS, "Hosiery, handwear, and clothing (men's)"],
    [FederalSupplyClass.HOSIERY_HANDWEAR_BADGES_AND_CLOTHING_WOMENS, "Hosiery, handwear, badges, and clothing (women's)"],
    [FederalSupplyClass.CHILDRENS_AND_INFANTS_APPAREL_ACCESSORIES, "Children's and infants' apparel accessories"],
    [FederalSupplyClass.BADGES_AND_INSIGNIA, 'Badges and insignia'],
    [FederalSupplyClass.LUGGAGE, 'Luggage'],
    [FederalSupplyClass.INDIVIDUAL_EQUIPMENT_SUNGLASSES, 'Individual equipment/sunglasses'],
    [FederalSupplyClass.ARMOR_PERSONAL, 'Armor, personal'],
    [FederalSupplyClass.PERFUMES_TOILET_PREPARATIONS_AND_POWDERS, 'Perfumes, toilet preparations, and powders'],
    [FederalSupplyClass.TOILET_SOAP_SHAVING_PREPARATIONS_DENTIFRICES, 'Toilet soap, shaving preparations, & dentifrices'],
    [FederalSupplyClass.PERSONAL_TOILETRY_ARTICLES, 'Personal toiletry articles'],
    [FederalSupplyClass.TOILETRY_PAPER_PRODUCTS, 'Toiletry paper products'],
    [FederalSupplyClass.FORAGE_AND_FEED, 'Forage and feed'],
    [FederalSupplyClass.FERTILIZERS, 'Fertilizers'],
    [FederalSupplyClass.SEEDS_AND_NURSERY_STOCK, 'Seeds and nursery stock'],
    [FederalSupplyClass.LIVE_ANIMALS_RAISED_FOR_FOOD, 'Live animals, raised for food'],
    [FederalSupplyClass.LIVE_ANIMALS_NOT_RAISED_FOR_FOOD, 'Live animals, not raised for food'],
    [FederalSupplyClass.MEAT_POULTRY_AND_FISH, 'Meat, poultry, and fish'],
    [FederalSupplyClass.DAIRY_FOODS_AND_EGGS, 'Dairy foods and eggs'],
    [FederalSupplyClass.FRUITS_AND_VEGETABLES, 'Fruits and vegetables'],
    [FederalSupplyClass.BAKERY_AND_CEREAL_PRODUCTS, 'Bakery and cereal products'],
    [FederalSupplyClass.SUGAR_CONFECTIONERY_AND_NUTS, 'Sugar confectionery, and nuts'],
    [FederalSupplyClass.JAMS_JELLIES_AND_PRESERVES, 'Jams, jellies, and preserves'],
    [FederalSupplyClass.SOUPS_AND_BOUILLONS, 'Soups and bouillons'],
    [FederalSupplyClass.SPECIAL_DIETARY_FOODS_AND_FOOD_SPECIALTY, 'Special dietary foods and food specialty'],
    [FederalSupplyClass.FOOD_OILS_AND_FATS, 'Food, oils and fats'],
    [FederalSupplyClass.CONDIMENTS_AND_RELATED_PRODUCTS, 'Condiments and related products'],
    [FederalSupplyClass.COFFEE_TEA_AND_COCOA, 'Coffee, tea, and cocoa'],
    [FederalSupplyClass.BEVERAGES_NONALCOHOLIC_H20_WATER, 'Beverages, nonalcoholic, h20 (water)'],
    [FederalSupplyClass.BEVERAGES_ALCOHOLIC, 'Beverages, alcoholic'],
    [FederalSupplyClass.COMPOSITE_FOOD_PACKAGES, 'Composite food packages'],
    [FederalSupplyClass.TOBACCO_PRODUCTS, 'Tobacco products'],
    [FederalSupplyClass.FUELS_SOLID, 'Fuels, solid'],
    [FederalSupplyClass.LIQUID_PROPELLANTS_AND_FUELS_PETROLEUM_BASE, 'Liquid propellants and fuels, petroleum base'],
    [FederalSupplyClass.LIQUID_PROPELLANTS_AND_OXIDIZERS, 'Liquid propellants and oxidizers'],
    [FederalSupplyClass.FUEL_OILS, 'Fuel oils'],
    [FederalSupplyClass.OILS_AND_GREASES_CUTTING_LUBRICATING, 'Oils and greases: cutting lubricating'],
    [FederalSupplyClass.MISCELLANEOUS_WAXES_OILS_AND_FATS, 'Miscellaneous waxes, oils, and fats'],
    [FederalSupplyClass.PAPER_AND_PAPERBOARD, 'Paper and paperboard'],
    [FederalSupplyClass.RUBBER_FABRICATED_MATERIALS_CUSHIONS, 'Rubber fabricated materials/cushions'],
    [FederalSupplyClass.PLASTICS_FABRICATED_MATERIALS, 'Plastics fabricated materials'],
    [FederalSupplyClass.GLASS_FABRICATED_MATERIALS, 'Glass fabricated materials'],
    [FederalSupplyClass.REFRACTORIES_AND_FIRE_SURFACING_MATERIALS, 'Refractories and fire surfacing materials'],
    [FederalSupplyClass.MISCELLANEOUS_FABRICATED_NONMETALLIC, 'Miscellaneous fabricated nonmetallic'],
    [FederalSupplyClass.CRUDE_GRADES_OF_PLANT_MATERIALS, 'Crude grades of plant materials'],
    [FederalSupplyClass.FIBERS_VEGETABLE_ANIMAL_AND_SYNTHETIC, 'Fibers: vegetable, animal, and synthetic'],
    [FederalSupplyClass.MISCELLANEOUS_CRUDE_ANIMAL_PRODUCTS, 'Miscellaneous crude animal products'],
    [FederalSupplyClass.MISCELLANEOUS_CRUDE_AGRICULTURAL_AND_FORESTRY, 'Miscellaneous crude agricultural and forestry'],
    [FederalSupplyClass.NONMETALLIC_SCRAP_EXCEPT_TEXTILE, 'Nonmetallic scrap, except textile'],
    [FederalSupplyClass.WIRE_NONELECTRICAL_IRON_AND_STEEL, 'Wire, nonelectrical, iron and steel'],
    [FederalSupplyClass.BARS_AND_RODS_IRON_AND_STEEL, 'Bars and rods, iron and steel'],
    [FederalSupplyClass.PLATE_SHEET_STRIP_AND_FOIL_IRON_AND_STEEL, 'Plate, sheet, strip and foil; iron and steel'],
    [FederalSupplyClass.STRUCTURAL_SHAPES_IRON_AND_STEEL, 'Structural shapes, iron and steel'],
    [FederalSupplyClass.WIRE_NONELECTRICAL_NONFERROUS_BASE_METAL, 'Wire, nonelectrical, nonferrous base metal'],
    [FederalSupplyClass.BARS_AND_RODS_NONFERROUS_BASE_METAL, 'Bars and rods, nonferrous base metal'],
    [FederalSupplyClass.PLATE_SHEET_STRIP_AND_FOIL, 'Plate, sheet, strip, and foil:'],
    [FederalSupplyClass.STRUCTURAL_SHAPES_NONFERROUS_BASE_METAL, 'Structural shapes, nonferrous base metal'],
    [FederalSupplyClass.PLATE_SHEET_STRIP_FOIL_AND_WIRE, 'Plate, sheet, strip, foil, and wire:'],
    [FederalSupplyClass.ORES, 'Ores'],
    [FederalSupplyClass.MINERALS_NATURAL_AND_SYNTHETIC, 'Minerals, natural and synthetic'],
    [FederalSupplyClass.ADDITIVE_METAL_MATERIALS_AND_MASTER_ALLOYS, 'Additive metal materials and master alloys'],
    [
        FederalSupplyClass.IRON_AND_STEEL_PRIMARY_AND_SEMIFINISHED_PRODUCTS,
        'Iron and steel primary and semifinished products'
    ],
    [FederalSupplyClass.NONFERROUS_BASE_METAL_REFINERY, 'Nonferrous base metal refinery'],
    [FederalSupplyClass.PRECIOUS_METALS_PRIMARY_FORMS, 'Precious metals primary forms'],
    [FederalSupplyClass.IRON_AND_STEEL_SCRAP, 'Iron and steel scrap'],
    [FederalSupplyClass.NONFERROUS_METAL_SCRAP, 'Nonferrous metal scrap'],
    [FederalSupplyClass.SIGNS_ADVERTISING_DISPLAYS_AND_IDENTIFICATION, 'Signs, advertising displays, and identification'],
    [FederalSupplyClass.JEWELRY, 'Jewelry'],
    [FederalSupplyClass.COLLECTORS_AND_OR_HISTORICAL_ITEMS, 'Collectors and/or historical items'],
    [FederalSupplyClass.SMOKERS_ARTICLES_AND_MATCHES, "Smokers' articles and matches"],
    [FederalSupplyClass.ECCLESIASTICAL_EQUIPMENT_FURNISHINGS, 'Ecclesiastical equipment, furnishings'],
    [FederalSupplyClass.MEMORIALS_CEMETERIAL_AND_MORTUARY_EQUIPMENT, 'Memorials; cemeterial and mortuary equipment'],
    [FederalSupplyClass.MISCELLANEOUS_ITEMS, 'Miscellaneous items'],
    [FederalSupplyClass.FENCING_FENCES_AND_GATES, 'Fencing, fences, and gates'],
    [FederalSupplyClass.SWITCHES, 'Switches'],
    [FederalSupplyClass.COILS_AND_TRANSFORMERS, 'Coils and transformers'],
    [FederalSupplyClass.SHIPBOARD_ALARM_AND_SIGNAL_SYSTEMS, 'Shipboard alarm and signal systems'],
    [FederalSupplyClass.CLOTHING_SPECIAL_PURPOSE, 'Clothing, special purpose'],
    [FederalSupplyClass.SPECIALIZED_FLIGHT_CLOTHING_ACCESSORIES, 'Specialized flight clothing/accessories'],
    [FederalSupplyClass.COIL_FLAT_AND_WIRE_SPRINGS, 'Coil, flat, and wire springs'],
    [FederalSupplyClass.GROUP_10_WEAPONS, 'Group 10 weapons'],
    [FederalSupplyClass.GROUP_11_NUCLEAR_ORDNANCE, 'Group 11 nuclear ordnance'],
    [FederalSupplyClass.GROUP_12_FIRE_CONTROL_EQUIPMENT, 'Group 12 fire control equipment'],
    [FederalSupplyClass.GROUP_13_AMMUNITION_AND_EXPLOSIVES, 'Group 13 ammunition and explosives'],
    [FederalSupplyClass.GROUP_14_GUIDED_MISSLES, 'Group 14 guided missles'],
    [
        FederalSupplyClass.GROUP_15_AIRCRAFT_AND_AIRFRAME_STRUCTURAL_EQUIP,
        'Group 15 aircraft and airframe structural equip.'
    ],
    [FederalSupplyClass.GROUP_16_AIRCRAFT_COMPONENTS_AND_ACCESSORIES, 'Group 16 aircraft components and accessories'],
    [
        FederalSupplyClass.GROUP_17_AIRCRAFT_LAUNCH_LAND_AND_GROUND_EQUIP,
        'Group 17 aircraft launch, land, and ground equip.'
    ],
    [
        FederalSupplyClass.GROUP_19_SHIPS_SM_CRAFTS_PONTOONS_FLOATING_DOCKS,
        'Group 19 ships,sm.crafts,pontoons,floating docks'
    ],
    [FederalSupplyClass.GROUP_20_SHIP_AND_MARINE_EQUIPMENT, 'Group 20 ship and marine equipment'],
    [FederalSupplyClass.GROUP_22_RAILWAY_EQUIPMENT, 'Group 22 railway equipment'],
    [
        FederalSupplyClass.GROUP_23_GROUND_AND_MOTOR_VEHICLES_TRAILERS_CYCLES,
        'Group 23 ground and motor vehicles,trailers,cycles'
    ],
    [FederalSupplyClass.GROUP_24_TRACTORS, 'Group 24 tractors'],
    [FederalSupplyClass.GROUP_25_VEHICULAR_EQUIPMENT_COMPONENTS, 'Group 25 vehicular equipment components'],
    [FederalSupplyClass.GROUP_26_TIRES_AND_TUBES, 'Group 26 tires and tubes'],
    [FederalSupplyClass.GROUP_28_ENGINES_TURBINES_AND_COMPONENTS, 'Group 28 engines, turbines and components'],
    [FederalSupplyClass.GROUP_29_ENGINE_ACCESSORIES, 'Group 29 engine accessories'],
    [FederalSupplyClass.GROUP_30_MECHANICAL_POWERED_TRANSMISSION_EQUIP, 'Group 30 mechanical powered transmission equip'],
    [FederalSupplyClass.GROUP_31_BEARINGS, 'Group 31 bearings'],
    [FederalSupplyClass.GROUP_32_WOODWORKING_MACHINERY_AND_EQUIPMENT, 'Group 32 woodworking machinery and equipment'],
    [FederalSupplyClass.GROUP_34_METALWORKING_MACHINERY, 'Group 34 metalworking machinery'],
    [FederalSupplyClass.GROUP_35_SERVICE_AND_TRADE_EQUIPMENT, 'Group 35 service and trade equipment'],
    [FederalSupplyClass.GROUP_36_SPECIAL_INDUSTRY_MACHINERY, 'Group 36 special industry machinery'],
    [FederalSupplyClass.GROUP_37_AGRICULTURAL_MACHINERY_AND_EQUIPMENT, 'Group 37 agricultural machinery and equipment'],
    [
        FederalSupplyClass.GROUP_38_CONSTRUCTION_MINING_EXCAVATING_ROAD_EQUIP,
        'Group 38 construction,mining,excavating,road equip'
    ],
    [FederalSupplyClass.GROUP_39_MATERIAL_HANDLING_EQUIPMENT, 'Group 39 material handling equipment'],
    [FederalSupplyClass.GROUP_40_ROPE_CABLE_CHAIN_AND_FITTINGS, 'Group 40 rope, cable, chain, and fittings'],
    [
        FederalSupplyClass.GROUP_41_REFRIGERATION_AIRCONDITIONING_CIRC_EQUIP,
        'Group 41 refrigeration,airconditioning,circ equip'
    ],
    [
        FederalSupplyClass.GROUP_42_FIRE_FIGHTING_RESCUE_AND_SAFETY_EQUIP,
        'Group 42 fire fighting, rescue, and safety equip.'
    ],
    [FederalSupplyClass.GROUP_43_PUMPS_AND_COMPRESSORS, 'Group 43 pumps and compressors'],
    [
        FederalSupplyClass.GROUP_44_FURNANCE_STEAM_DRYING_EQUIP_NUC_REACTOR,
        'Group 44 furnance,steam,drying, equip. nuc reactor'
    ],
    [
        FederalSupplyClass.GROUP_45_PLUMBING_HEATING_AND_SANITATION_EQUIP,
        'Group 45 plumbing, heating, and sanitation equip'
    ],
    [FederalSupplyClass.GROUP_46_WATER_PURIFICATION_EQUIPMENT, 'Group 46 water purification equipment'],
    [FederalSupplyClass.GROUP_47_PIPE_TUBING_HOSE_AND_FITTINGS, 'Group 47 pipe, tubing hose, and fittings'],
    [FederalSupplyClass.GROUP_48_VALVES, 'Group 48 valves'],
    [FederalSupplyClass.GROUP_49_MAINTENANCE_AND_REPAIR_SHOP_EQUIP, 'Group 49 maintenance and repair shop equip'],
    [FederalSupplyClass.GROUP_51_HAND_TOOLS, 'Group 51 hand tools'],
    [FederalSupplyClass.GROUP_52_MEASURING_TOOLS, 'Group 52 measuring tools'],
    [FederalSupplyClass.GROUP_53_HARDWARE_AND_ABRASIVES, 'Group 53 hardware and abrasives'],
    [
        FederalSupplyClass.GROUP_54_PREFABRICATED_STRUCTURES_AND_SCAFFOLDING,
        'Group 54 prefabricated structures and scaffolding'
    ],
    [FederalSupplyClass.GROUP_55_LUMBER_MILLWORK_PLYWOOD_AND_VANEER, 'Group 55 lumber, millwork, plywood, and vaneer'],
    [FederalSupplyClass.GROUP_56_CONSTRUCTION_AND_BUILDING_MATERIAL, 'Group 56 construction and building material'],
    [
        FederalSupplyClass.GROUP_58_COMMUNICATION_DETECTION_CO_RADIATION_EQUP,
        'Group 58 communication,detection,co radiation equp'
    ],
    [
        FederalSupplyClass.GROUP_60_FIBER_OPTIC_MAT_COMPONENTS_ASSY_ACCESSARY,
        'Group 60 fiber optic:mat,components,assy,accessary'
    ],
    [
        FederalSupplyClass.GROUP_61_ELECTRIC_WIRE_POWER_DISTRIBUTION_EQUIP,
        'Group 61 electric wire & power distribution equip'
    ],
    [FederalSupplyClass.GROUP_62_LIGHTING_FIXTURES_AND_LAMPS, 'Group 62 lighting fixtures and lamps'],
    [
        FederalSupplyClass.GROUP_63_ALARM_SIGNAL_SECURITY_DETECTION_SYS,
        'Group 63 alarm, signal, & security detection sys.'
    ],
    [
        FederalSupplyClass.GROUP_65_MEDICAL_DENTAL_AND_VET_EQUIP_SUPPLIES,
        'Group 65 medical, dental, and vet equip & supplies'
    ],
    [FederalSupplyClass.GROUP_66_INSTRUMENTS_AND_LABRATORY_EQUIPMENT, 'Group 66 instruments and labratory equipment'],
    [
        FederalSupplyClass.GROUP_59_ELECTRICAL_ELECTRONIC_EQUIP_COMPONENTS,
        'Group 59 electrical & electronic equip components'
    ],
    [FederalSupplyClass.GROUP_67_PHOTOGRAPHIC_EQUIPMENT, 'Group 67 photographic equipment'],
    [FederalSupplyClass.GROUP_68_CHEMICALS_AND_CHEMICAL_PRODUCTS, 'Group 68 chemicals and chemical products'],
    [FederalSupplyClass.GROUP_69_TRAINING_AIDS_AND_DEVICES, 'Group 69 training aids and devices'],
    [
        FederalSupplyClass.GROUP_70_ADPE_EQUIP_SOFTWARE_SUPPLIES_AND_SUPPORT,
        'Group 70 adpe equip, software, supplies & support'
    ],
    [FederalSupplyClass.GROUP_71_FURNITURE, 'Group 71 furniture'],
    [
        FederalSupplyClass.GROUP_72_HOUSE_COMMERCIAL_FURNISHING_APPLIANCES,
        'Group 72 house & commercial furnishing, appliances'
    ],
    [
        FederalSupplyClass.GROUP_73_FOOD_PREPARATION_AND_SERVICE_EQUIPMENT,
        'Group 73 food preparation and service equipment'
    ],
    [
        FederalSupplyClass.GROUP_74_OFFICE_MACH_TEXT_VISIBLE_RECORD_EQUIP,
        'Group 74 office mach. text & visible record equip'
    ],
    [FederalSupplyClass.GROUP_75_OFFICE_SUPPLIES_AND_DEVICES, 'Group 75 office supplies and devices'],
    [
        FederalSupplyClass.GROUP_77_MUSIC_INSTRUMENT_PHONOGRAPH_HOME_RADIO,
        'Group 77 music instrument, phonograph, home radio'
    ],
    [FederalSupplyClass.GROUP_76_BOOKS_MAPS_AND_OTHER_PUBLICATIONS, 'Group 76 books, maps and other publications'],
    [FederalSupplyClass.GROUP_78_RECREATIONAL_AND_ATHLETIC_EQUIPMENT, 'Group 78 recreational and athletic equipment'],
    [FederalSupplyClass.GROUP_79_CLEANING_EQUIPMENT_AND_SUPPLIES, 'Group 79 cleaning equipment and supplies'],
    [FederalSupplyClass.GROUP_81_CONTAINERS_PACKAGES_PACKING_SUPPLIES, 'Group 81 containers, packages, packing supplies'],
    [
        FederalSupplyClass.GROUP_80_BRUSHES_PAINTS_SEALERS_AND_ADHESIVES,
        'Group 80 brushes, paints, sealers, and adhesives'
    ],
    [FederalSupplyClass.GROUP_83_TEXTILESFURSAPPARELSHOESTENTSFLAGS, 'Group 83 textiles,furs,apparel,shoes,tents,flags'],
    [
        FederalSupplyClass.GROUP_84_CLOTHING_INDIVIUAL_EQUIPMENT_AND_INSIGNIA,
        'Group 84 clothing, indiviual equipment, & insignia'
    ],
    [FederalSupplyClass.GROUP_85_TOILETRIES, 'Group 85 toiletries'],
    [FederalSupplyClass.GROUP_87_AGRICULTURE_SUPPLIES, 'Group 87 agriculture supplies'],
    [FederalSupplyClass.GROUP_88_LIVE_ANIMALS, 'Group 88 live animals'],
    [FederalSupplyClass.GROUP_89_SUBSISTENCE, 'Group 89 subsistence'],
    [FederalSupplyClass.GROUP_91_FUELS_LUBRICATIONS_OILS_AND_WAXES, 'Group 91 fuels, lubrications, oils, and waxes'],
    [FederalSupplyClass.GROUP_93_NON_METALLIC_FABRICATION_MATERIAL, 'Group 93 non metallic fabrication material'],
    [FederalSupplyClass.GROUP_94_NON_METALLIC_RUDE_MATERIAL, 'Group 94 non metallic rude material'],
    [FederalSupplyClass.GROUP_95_METAL_BARS_SHEETS_AND_SHAPES, 'Group 95 metal bars, sheets, and shapes'],
    [
        FederalSupplyClass.GROUP_96_ORES_MINERALS_THEIR_PRIMARY_PRODUCTS,
        'Group 96 ores, minerals, & their primary products'
    ],
    [FederalSupplyClass.GROUP_99_MISCELLANEOUS, 'Group 99 miscellaneous'],
    [
        FederalSupplyClass.MISCELLANEOUS_BATTERY_RETAINING_FIXTURES_LINERS,
        'Miscellaneous battery retaining fixtures/liners'
    ],
    [FederalSupplyClass.SMALL_CRAFT_POWERED_AND_UNPOWERED_SMALL_CRAFT, 'Small craft; powered and unpowered small craft'],
    [FederalSupplyClass.TRUCKS_TRUCK_TRACTORS_WHEELED, 'Trucks/truck tractors, wheeled'],
    [FederalSupplyClass.BUSHINGS_RINGS_SHIMS_AND_SPACERS, 'Bushings, rings, shims and spacers'],
    [FederalSupplyClass.MISCELLANEOUS_MAINTENANCE_AND_REPAIR_SHOP, 'Miscellaneous maintenance and repair shop'],
    [FederalSupplyClass.FIRE_CONTROL_MAINTENANCE_AND_REPAIR_SHOP, 'Fire control maintenance and repair shop'],
    [FederalSupplyClass.GUIDED_MISSILE_MAINTENANCE_REPAIR, 'Guided missile maintenance, repair'],
    [FederalSupplyClass.WEAPONS_MAINTENANCE_AND_REPAIR_SHOP, 'Weapons maintenance and repair shop'],
    [FederalSupplyClass.SPACE_VEHICLE_MAINTENANCE_REPAIR_AND_CHECKOUT, 'Space vehicle maintenance, repair, and checkout'],
    [FederalSupplyClass.VALVES_NONPOWERED, 'Valves, nonpowered'],
    [FederalSupplyClass.TORPEDO_MAINTENANCE_REPAIR_AND_CHECKOUT, 'Torpedo maintenance, repair, and checkout'],
    [FederalSupplyClass.DEPTH_CHARGES_AND_UNDERWATER_MINES_MAINTENANCE, 'Depth charges and underwater mines maintenance'],
    [FederalSupplyClass.ROCKET_MAINTENANCE_REPAIR_AND_CHECKOUT, 'Rocket maintenance, repair and checkout'],
    [FederalSupplyClass.AMMUNITION_MAINTENANCE_REPAIR_AND_CHECKOUT, 'Ammunition maintenance, repair, and checkout'],
    [FederalSupplyClass.LUBRICATION_AND_FUEL_DISPENSING_EQUIPMENT, 'Lubrication and fuel dispensing equipment'],
    [FederalSupplyClass.AIRCRAFT_MAINTENANCE_AND_REPAIR_SHOP, 'Aircraft maintenance and repair shop'],
    [FederalSupplyClass.CAMOUFLAGE_AND_DECEPTION_EQUIPMENT, 'Camouflage and deception equipment'],
    [FederalSupplyClass.EXPLOSIVE_AND_PYROTECHNIC_COMPONENTS, 'Explosive and pyrotechnic components'],
    [FederalSupplyClass.SPECIALIZED_TEST_AND_HANDLING_EQUIPMENT, 'Specialized test and handling equipment'],
    [FederalSupplyClass.OPTICAL_SIGHTING_AND_RANGING_EQUIPMENT, 'Optical sighting and ranging equipment'],
    [FederalSupplyClass.MISCELLANEOUS_FIRE_CONTROL_EQUIPMENT, 'Miscellaneous fire control equipment'],
    [
        FederalSupplyClass.ROCKETS_ROCKET_AMMUNITION_AND_ROCKET_COMPONENTS,
        'Rockets, rocket ammunition and rocket components'
    ],
    [FederalSupplyClass.PYROTECHNICS, 'Pyrotechnics'],
    [FederalSupplyClass.CARTRIDGE_AND_PROPELLANT_ACTUATED_DEVICES, 'Cartridge and propellant actuated devices'],
    [FederalSupplyClass.PARACHUTES_AERIAL_PICK_UP_DELIVERY_RECOVERY, 'Parachutes; aerial pick up, delivery, recovery'],
    [FederalSupplyClass.GUNS_OVER_125MM_THROUGH_150MM, 'Guns, over 125mm through 150mm'],
    [FederalSupplyClass.MISCELLANEOUS_VEHICULAR_COMPONENTS, 'Miscellaneous vehicular components'],
    [FederalSupplyClass.TIRES_SOLID_AND_CUSHION, 'Tires, solid and cushion'],
    [FederalSupplyClass.MISCELLANEOUS_ENGINES_AND_COMPONENTS, 'Miscellaneous engines and components'],
    [
        FederalSupplyClass.ENGINE_ELECTRICAL_SYSTEM_COMPONENTS_NONAIRCRAFT,
        'Engine electrical system components, nonaircraft'
    ],
    [FederalSupplyClass.ENGINE_COOLING_SYSTEM_COMPONENTS_NONAIRCRAFT, 'Engine cooling system components, nonaircraft'],
    [
        FederalSupplyClass.ENGINE_AIR_AND_OIL_FILTERS_STRAINERS_NONAIRCRAFT,
        'Engine air and oil filters, strainers, nonaircraft'
    ],
    [FederalSupplyClass.TURBOSUPERCHARGERS, 'Turbosuperchargers'],
    [FederalSupplyClass.MISCELLANEOUS_ENGINE_ACCESSORIES_NONAIRCRAFT, 'Miscellaneous engine accessories, nonaircraft'],
    [FederalSupplyClass.MECHANICAL_POWER_TRANSMISSION_EQUIPMENT, 'Mechanical power transmission equipment'],
    [FederalSupplyClass.GEARS_PULLEYS_SPROCKETS_AND_TRANSMISSION, 'Gears, pulleys, sprockets, and transmission'],
    [
        FederalSupplyClass.BELTING_DRIVE_BELTS_FAN_BELTS_AND_ACCESSORIES,
        'Belting, drive belts, fan belts, and accessories'
    ],
    [FederalSupplyClass.MISCELLANEOUS_POWER_TRANSMISSION_EQUIPMENT, 'Miscellaneous power transmission equipment'],
    [FederalSupplyClass.BEARINGS_ANTIFRICTION_UNMOUNTED, 'Bearings, antifriction, unmounted'],
    [FederalSupplyClass.BEARINGS_PLAIN_UNMOUNTED, 'Bearings, plain unmounted'],
    [FederalSupplyClass.BEARINGS_UNMOUNTED, 'Bearings, unmounted'],
    [FederalSupplyClass.SAWMILL_AND_PLANING_MILL_MACHINERY, 'Sawmill and planing mill machinery'],
    [FederalSupplyClass.WOODWORKING_MACHINES, 'Woodworking machines'],
    [
        FederalSupplyClass.TOOLS_AND_ATTACHMENTS_FOR_WOODWORKING_MACHINERY,
        'Tools and attachments for woodworking machinery'
    ],
    [FederalSupplyClass.SAWS_AND_FILING_MACHINES, 'Saws and filing machines'],
    [FederalSupplyClass.MACHINING_CENTERS_AND_WAY_TYPE_MACHINES, 'Machining centers and way-type machines'],
    [FederalSupplyClass.ELECTRICAL_AND_ULTRASONIC_EROSION_MACHINES, 'Electrical and ultrasonic erosion machines'],
    [FederalSupplyClass.BORING_MACHINES, 'Boring machines'],
    [FederalSupplyClass.BROACHING_MACHINES, 'Broaching machines'],
    [FederalSupplyClass.DRILLING_AND_TAPPING_MACHINES, 'Drilling and tapping machines'],
    [FederalSupplyClass.GEAR_CUTTING_AND_FINISHING_MACHINES, 'Gear cutting and finishing machines'],
    [FederalSupplyClass.GRINDING_MACHINES, 'Grinding machines'],
    [FederalSupplyClass.LATHES, 'Lathes'],
    [FederalSupplyClass.MILLING_MACHINES, 'Milling machines'],
    [FederalSupplyClass.PLANERS_AND_SHAPERS, 'Planers and shapers'],
    [FederalSupplyClass.MISCELLANEOUS_MACHINE_TOOLS, 'Miscellaneous machine tools'],
    [FederalSupplyClass.ROLLING_MILLS_AND_DRAWING_MACHINES, 'Rolling mills and drawing machines'],
    [FederalSupplyClass.METAL_HEAT_TREATING_AND_NON_THERMAL_TREATING, 'Metal heat treating and non-thermal treating'],
    [FederalSupplyClass.METAL_FINISHING_EQUIPMENT, 'Metal finishing equipment'],
    [FederalSupplyClass.ELECTRIC_ARC_WELDING_EQUIPMENT, 'Electric arc welding equipment'],
    [FederalSupplyClass.ELECTRIC_RESISTANCE_WELDING_EQUIPMENT, 'Electric resistance welding equipment'],
    [FederalSupplyClass.GAS_WELDING_HEAT_CUTTING_AND_METALIZING, 'Gas welding, heat cutting, and metalizing'],
    [FederalSupplyClass.WELDING_POSITIONERS_AND_MANIPULATORS, 'Welding positioners and manipulators'],
    [FederalSupplyClass.MISCELLANEOUS_WELDING_EQUIPMENT, 'Miscellaneous welding equipment'],
    [FederalSupplyClass.MISCELLANEOUS_WELDING_SOLDERING_AND_BRAZING, 'Miscellaneous welding, soldering, and brazing'],
    [FederalSupplyClass.BENDING_AND_FORMING_MACHINES, 'Bending and forming machines'],
    [FederalSupplyClass.HYDRAULIC_AND_PNEUMATIC_PRESSES_POWER, 'Hydraulic and pneumatic presses, power'],
    [FederalSupplyClass.MECHANICAL_PRESSES_POWER_DRIVEN, 'Mechanical presses, power driven'],
    [FederalSupplyClass.MANUAL_PRESSES, 'Manual presses'],
    [FederalSupplyClass.PUNCHING_AND_SHEARING_MACHINES, 'Punching and shearing machines'],
    [FederalSupplyClass.FORGING_MACHINERY_AND_HAMMERS, 'Forging machinery and hammers'],
    [FederalSupplyClass.WIRE_AND_METAL_RIBBON_FORMING_MACHINES, 'Wire and metal ribbon forming machines'],
    [FederalSupplyClass.RIVETING_MACHINES, 'Riveting machines'],
    [FederalSupplyClass.MISCELLANEOUS_SECONDARY_METAL_FORMING, 'Miscellaneous secondary metal forming'],
    [FederalSupplyClass.MACHINE_TOOLS_PORTABLE, 'Machine tools, portable'],
    [FederalSupplyClass.CUTTING_TOOLS_AND_MACHINE_TOOLS, 'Cutting tools and machine tools'],
    [FederalSupplyClass.CUTTING_AND_FORMING_TOOLS_FOR_SECONDARY, 'Cutting and forming tools for secondary'],
    [FederalSupplyClass.MACHINE_TOOL_ACCESSORIES, 'Machine tool accessories'],
    [FederalSupplyClass.ACCESSORIES_FOR_SECONDARY_METALWORKING, 'Accessories for secondary metalworking'],
    [FederalSupplyClass.PRODUCTION_JIGS_FIXTURES_AND_TEMPLATES, 'Production jigs, fixtures, and templates'],
    [FederalSupplyClass.MACHINE_SHOP_SETS_KITS_AND_OUTFITS, 'Machine shop sets, kits, and outfits'],
    [FederalSupplyClass.LAUNDRY_AND_DRY_CLEANING_EQUIPMENT, 'Laundry and dry cleaning equipment'],
    [FederalSupplyClass.SHOE_REPAIRING_EQUIPMENT, 'Shoe repairing equipment'],
    [FederalSupplyClass.INDUSTRIAL_SEWING_MACHINES_MOBILE_TEXTILE, 'Industrial sewing machines; mobile textile'],
    [FederalSupplyClass.WRAPPING_AND_PACKAGING_MACHINERY, 'Wrapping and packaging machinery'],
    [FederalSupplyClass.VENDING_AND_COIN_OPERATED_MACHINES, 'Vending and coin operated machines'],
    [FederalSupplyClass.MISCELLANEOUS_SERVICE_AND_TRADE_EQUIPMENT, 'Miscellaneous service and trade equipment'],
    [FederalSupplyClass.FOOD_PRODUCTS_MACHINERY_AND_EQUIPMENT, 'Food products machinery and equipment'],
    [FederalSupplyClass.PRINTING_DUPLICATING_AND_BOOKBINDING, 'Printing, duplicating, and bookbinding'],
    [FederalSupplyClass.INDUSTRIAL_MARKING_MACHINES, 'Industrial marking machines'],
    [FederalSupplyClass.PULP_AND_PAPER_INDUSTRIES_MACHINERY, 'Pulp and paper industries machinery'],
    [FederalSupplyClass.RUBBER_AND_PLASTICS_WORKING_MACHINERY, 'Rubber and plastics working machinery'],
    [FederalSupplyClass.TEXTILE_INDUSTRIES_MACHINERY, 'Textile industries machinery'],
    [FederalSupplyClass.CLAY_AND_CONCRETE_PRODUCTS_INDUSTRIES, 'Clay and concrete products industries'],
    [FederalSupplyClass.CRYSTAL_AND_GLASS_INDUSTRIES_MACHINERY, 'Crystal and glass industries machinery'],
    [FederalSupplyClass.TOBACCO_MANUFACTURING_MACHINERY, 'Tobacco manufacturing machinery'],
    [FederalSupplyClass.LEATHER_TANNING_AND_LEATHER_WORKING, 'Leather tanning and leather working'],
    [FederalSupplyClass.CHEMICAL_AND_PHARMACEUTICAL_PRODUCTS, 'Chemical and pharmaceutical products'],
    [FederalSupplyClass.GAS_GENERATING_AND_DISPENSING_SYSTEMS_FIXED, 'Gas generating and dispensing systems, fixed'],
    [FederalSupplyClass.INDUSTRIAL_SIZE_REDUCTION_MACHINERY, 'Industrial size reduction machinery'],
    [FederalSupplyClass.SPECIALIZED_SEMICONDUCTOR_MICROCIRCUIT, 'Specialized semiconductor, microcircuit'],
    [
        FederalSupplyClass.FOUNDRY_MACHINERY_RELATED_EQUIPMENT_AND_SUPPLIES,
        'Foundry machinery, related equipment and supplies'
    ],
    [FederalSupplyClass.SPECIALIZED_METAL_CONTAINER_MANUFACTURING, 'Specialized metal container manufacturing'],
    [FederalSupplyClass.SPECIALIZED_AMMUNITION_AND_ORDNANCE_MACHINERY, 'Specialized ammunition and ordnance machinery'],
    [FederalSupplyClass.INDUSTRIAL_ASSEMBLY_MACHINES, 'Industrial assembly machines'],
    [
        FederalSupplyClass.CLEAN_WORK_STATIONS_FLOWBENCH_CONTROLLED_ENVIR,
        'Clean work stations, flowbench, controlled envir.'
    ],
    [FederalSupplyClass.MISCELLANEOUS_SPECIAL_INDUSTRY_MACHINERY, 'Miscellaneous special industry machinery'],
    [FederalSupplyClass.SOIL_PREPARATION_EQUIPMENT, 'Soil preparation equipment'],
    [FederalSupplyClass.HARVESTING_EQUIPMENT, 'Harvesting equipment'],
    [FederalSupplyClass.DAIRY_POULTRY_AND_LIVESTOCK_EQUIPMENT, 'Dairy, poultry, and livestock equipment'],
    [FederalSupplyClass.PEST_DISEASE_AND_FROST_CONTROL_EQUIPMENT, 'Pest, disease, and frost control equipment'],
    [FederalSupplyClass.GARDENING_IMPLEMENTS_AND_TOOLS, 'Gardening implements and tools'],
    [FederalSupplyClass.AMIMAL_DRAWN_VEHICLES_AND_FARM_TRAILERS, 'Amimal drawn vehicles and farm trailers'],
    [FederalSupplyClass.SADDLERY_HARNESS_WHIPS_AND_RELATED_ANIMAL, 'Saddlery, harness, whips and related animal'],
    [FederalSupplyClass.EARTH_MOVING_AND_EXCAVATING_EQUIPMENT, 'Earth moving and excavating equipment'],
    [FederalSupplyClass.CRANES_AND_CRANE_SHOVELS, 'Cranes and crane-shovels'],
    [FederalSupplyClass.CRANE_AND_CRANE_SHOVEL_ATTACHMENTS, 'Crane and crane-shovel attachments'],
    [FederalSupplyClass.MINING_ROCK_DRILLING_EARTH_BORING, 'Mining, rock drilling, earth boring'],
    [FederalSupplyClass.ROAD_CLEARING_AND_CLEANING_EQUIPMENT, 'Road clearing and cleaning equipment'],
    [FederalSupplyClass.TRUCK_AND_TRACTOR_ATTACHMENTS, 'Truck and tractor attachments'],
    [FederalSupplyClass.PETROLEUM_PRODUCTION_AND_DISTRIBUTION, 'Petroleum production and distribution'],
    [FederalSupplyClass.MISCELLANEOUS_CONSTRUCTION_EQUIPMENT, 'Miscellaneous construction equipment'],
    [FederalSupplyClass.CONVEYORS, 'Conveyors'],
    [FederalSupplyClass.MATERIALS_FEEDERS, 'Materials feeders'],
    [FederalSupplyClass.MATERIALS_HANDLING_EQUIPMENT_NONSELFPROPELLED, 'Materials handling equipment, nonselfpropelled'],
    [FederalSupplyClass.WAREHOUSE_TRUCKS_AND_TRACTORS_SELFPROPELLED, 'Warehouse trucks and tractors, selfpropelled'],
    [FederalSupplyClass.BLOCKS_TACKLE_RIGGING_AND_SLINGS, 'Blocks, tackle, rigging, and slings'],
    [FederalSupplyClass.WINCHES_HOISTS_CRANES_AND_DERRICKS, 'Winches, hoists, cranes, and derricks'],
    [FederalSupplyClass.ELEVATORS_AND_ESCALATORS, 'Elevators and escalators'],
    [FederalSupplyClass.MISCELLANEOUS_MATERIALS_HANDLING_EQUIPMENT, 'Miscellaneous materials handling equipment'],
    [FederalSupplyClass.CHAIN_AND_WIRE_ROPE, 'Chain and wire rope'],
    [FederalSupplyClass.FIBER_ROPE_CORDAGE_AND_TWINE, 'Fiber rope, cordage, and twine'],
    [FederalSupplyClass.FITTINGS_FOR_ROPE_CABLE_AND_CHAIN, 'Fittings for rope, cable, and chain'],
    [FederalSupplyClass.REFRIGERATION_EQUIPMENT, 'Refrigeration equipment'],
    [FederalSupplyClass.AIR_CONDITIONING_EQUIPMENT, 'Air conditioning equipment'],
    [FederalSupplyClass.REFRIGERATION_AND_AIR_CONDITIONING_COMPONENTS, 'Refrigeration and air conditioning components'],
    [FederalSupplyClass.FANS_AIR_CIRCULATORS_AND_BLOWER_EQUIPMENT, 'Fans, air circulators, and blower equipment'],
    [FederalSupplyClass.FIRE_FIGHTING_EQUIPMENT, 'Fire fighting equipment'],
    [FederalSupplyClass.MARINE_LIFESAVING_AND_DIVING_EQUIPMENT, 'Marine lifesaving and diving equipment'],
    [FederalSupplyClass.DECONTAMINATING_AND_IMPREGNATING_EQUIPMENT, 'Decontaminating and impregnating equipment'],
    [FederalSupplyClass.SAFETY_AND_RESCUE_EQUIPMENT, 'Safety and rescue equipment'],
    [FederalSupplyClass.COMPRESSORS_AND_VACUUM_PUMPS, 'Compressors and vacuum pumps'],
    [FederalSupplyClass.POWER_AND_HAND_PUMPS, 'Power and hand pumps'],
    [
        FederalSupplyClass.CENTRIFUGALS_SEPARATORS_H20_FILTER_AND_PRESSURE,
        'Centrifugals, separators, h20 filter and pressure'
    ],
    [FederalSupplyClass.INDUSTRIAL_BOILERS, 'Industrial boilers'],
    [FederalSupplyClass.HEAT_EXCHANGERS_AND_STEAM_CONDENSERS, 'Heat exchangers and steam condensers'],
    [FederalSupplyClass.INDUSTRIAL_FURNACES_KILNS_LEHRS_AND_OVENS, 'Industrial furnaces, kilns, lehrs, and ovens'],
    [FederalSupplyClass.DRIERS_DEHYDRATORS_AND_ANHYDRATORS, 'Driers, dehydrators, and anhydrators'],
    [FederalSupplyClass.AIR_PURIFICATION_EQUIPMENT, 'Air purification equipment'],
    [FederalSupplyClass.PLUMBING_FIXTURES_TOWELS_AND_ACCESSORIES, 'Plumbing fixtures, towels and accessories'],
    [FederalSupplyClass.SPACE_HEATING_EQUIPMENT_AND_DOMESTIC, 'Space heating equipment and domestic'],
    [FederalSupplyClass.FUEL_BURNING_EQUIPMENT_UNITS, 'Fuel burning equipment units'],
    [FederalSupplyClass.MISCELLANEOUS_PLUMBING_HEATING_AND_SANITAION, 'Miscellaneous plumbing, heating, and sanitaion'],
    [FederalSupplyClass.WATER_PURIFICATION_EQUIPMENT, 'Water purification equipment'],
    [FederalSupplyClass.WATER_DISTILLATION_EQUIPMENT_MARINE, 'Water distillation equipment, marine'],
    [FederalSupplyClass.SEWAGE_TREATMENT_EQUIPMENT, 'Sewage treatment equipment'],
    [FederalSupplyClass.PIPE_AND_TUBE, 'Pipe and tube'],
    [FederalSupplyClass.HOSE_AND_TUBING_FLEXIBLE, 'Hose and tubing, flexible'],
    [FederalSupplyClass.FITTINGS_AND_SPECIALTIES_HOSE_PIPEAND_TUBE, 'Fittings and specialties; hose, pipe, and tube'],
    [FederalSupplyClass.VALVES_POWERED, 'Valves, powered'],
    [FederalSupplyClass.HAND_TOOLS_EDGED_NONPOWERED, 'Hand tools, edged, nonpowered'],
    [FederalSupplyClass.HAND_TOOLS_NONEDGED_NONPOWERED, 'Hand tools, nonedged, nonpowered'],
    [FederalSupplyClass.MOTOR_VEHICLE_MAINTENANCE_AND_REPAIR_SHOP, 'Motor vehicle maintenance and repair shop'],
    [FederalSupplyClass.HAND_TOOLS_POWER_DRIVEN, 'Hand tools, power driven'],
    [FederalSupplyClass.SPACE_VEHICLE_HANDLING_AND_SERVICING_EQUIPMENT, 'Space vehicle handling and servicing equipment'],
    [FederalSupplyClass.SPACE_VEHICLE_COMPONENTS, 'Space vehicle components'],
    [FederalSupplyClass.SPACE_SURVIVAL_EQUIPMENT, 'Space survival equipment'],
    [FederalSupplyClass.BRACKETS, 'Brackets'],
    [FederalSupplyClass.UNKNOWN, 'Unknown']
]);
