import { COSMIC_SYSTEM } from '~/application.config';
import { BASE_URL_BY_SYSTEM, CosmicSystem, getEnvironment } from '~/nasa_ui/types';
export const getCdocsUrl = (path = null) => {
    const env = getEnvironment();
    const baseUrl = BASE_URL_BY_SYSTEM[CosmicSystem.ESOC]['CDOCS'][env];
    if (!path) {
        return baseUrl;
    }
    path = path.startsWith('/') ? path : `/${path}`;
    return baseUrl + path;
};
export const getKnowledgeBaseUrl = (path = null) => {
    const env = getEnvironment();
    const baseUrl = BASE_URL_BY_SYSTEM[COSMIC_SYSTEM]['KNOWLEDGE_BASE'][env];
    if (!path) {
        return baseUrl;
    }
    path = path.startsWith('/') ? path : `/${path}`;
    return baseUrl + path;
};
export const getLogisticsUrl = (path = null) => {
    const env = getEnvironment();
    const baseUrl = BASE_URL_BY_SYSTEM[CosmicSystem.ESOC]['LOGISTICS'][env];
    if (!path) {
        return baseUrl;
    }
    path = path.startsWith('/') ? path : `/${path}`;
    return baseUrl + path;
};
export const getOgUrl = (path = null) => {
    const env = getEnvironment();
    const baseUrl = BASE_URL_BY_SYSTEM[CosmicSystem.ESOC]['OG'][env];
    if (!path) {
        return baseUrl;
    }
    path = path.startsWith('/') ? path : `/${path}`;
    return baseUrl + path;
};
export const getRiskUrl = (path = null) => {
    const env = getEnvironment();
    const baseUrl = BASE_URL_BY_SYSTEM[CosmicSystem.ESOC]['RISK'][env];
    if (!path) {
        return baseUrl;
    }
    path = path.startsWith('/') ? path : `/${path}`;
    return baseUrl + path;
};
