/**
 * The slim file should only hold the top level data of an entity. And it
 * shouldn't import any other fragments. This is an attempt to share slim
 * fragments across the DAL while mitigating circular refs.
 */
import gql from 'graphql-tag';
export const CosmicLocationSlimFragment = gql `
  fragment CosmicLocationSlim on Location {
    address
    bin
    building
    city
    contactId
    contractNumber
    id
    isOffEarth
    nodeId
    organizationCode
    phone
    room
    state
    stock
    zip
  }
`;
