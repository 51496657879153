import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { OrganizationRepoMixin } from '~/nasa_ui/DAL/organization';
import CurrentUserMixin from '~/nasa_ui/mixins/CurrentUserMixin';
import LoaderMixin from '~/nasa_ui/mixins/LoaderMixin';
import { EntityType } from '~/nasa_ui/types';
let ModalInventoryDueBack = class ModalInventoryDueBack extends Mixins(BaseModal, LoaderMixin, OrganizationRepoMixin, CurrentUserMixin) {
    EntityType = EntityType;
    dateLocal = '';
    date;
    orgCode;
    id;
    isArchived;
    closeDialog() {
        this.emitModalClose();
    }
    async updateInventoryDueBack() {
        const params = {
            isArchived: false,
            expectedReturnDate: this.date,
            expectedReturnOrganizationCode: this.orgCode
        };
        try {
            const resp = await this.$http.put(`/inventory/${this.id}`, params);
        }
        catch (err) {
            this.$errorUtility({ err });
        }
        finally {
            this.$message('reload_due_back', true);
            this.$message(`reload_${this.id}`);
            this.emitModalClose();
        }
    }
    async clearExpectedReturnDate() {
        const params = {
            isArchived: false,
            expectedReturnDate: null,
            expectedReturnOrganizationCode: this.orgCode
        };
        try {
            const resp = await this.$http.put(`/inventory/${this.id}`, params);
        }
        catch (err) {
            this.$errorUtility({ err });
        }
        finally {
            this.$message('reload_due_back', true);
            this.$message(`reload_${this.id}`);
            this.emitModalClose();
        }
    }
    emitUpdated(date) { }
    onDateChange() {
        if (this.date !== this.dateLocal) {
            this.dateLocal = this.date;
        }
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], ModalInventoryDueBack.prototype, "date", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], ModalInventoryDueBack.prototype, "orgCode", void 0);
__decorate([
    Prop({
        type: String,
        default: ''
    }),
    __metadata("design:type", Object)
], ModalInventoryDueBack.prototype, "id", void 0);
__decorate([
    Prop({
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], ModalInventoryDueBack.prototype, "isArchived", void 0);
__decorate([
    Emit('updated'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], ModalInventoryDueBack.prototype, "emitUpdated", null);
__decorate([
    Watch('date', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalInventoryDueBack.prototype, "onDateChange", null);
ModalInventoryDueBack = __decorate([
    Component
], ModalInventoryDueBack);
export default ModalInventoryDueBack;
