var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Maybe } from '~/nasa_ui/types';
let AutocompleteMixin = class AutocompleteMixin extends Vue {
    multiple;
    required;
    value;
    get requiredError() {
        if (!this.required) {
            return;
        }
        return this.multiple ? !this.value?.length : !this.value;
    }
    /**
     * Utility in case the values are also the displayText
     * Example: drawing numbers, as-built numbers, etc
     */
    transformStringsToSelectOptions(value) {
        const values = Array.isArray(value) ? value : [value];
        return values.map((x) => ({ displayText: x, value: x }));
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AutocompleteMixin.prototype, "multiple", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AutocompleteMixin.prototype, "required", void 0);
__decorate([
    Prop({
        type: [String, Array]
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], AutocompleteMixin.prototype, "value", void 0);
AutocompleteMixin = __decorate([
    Component
], AutocompleteMixin);
export default AutocompleteMixin;
