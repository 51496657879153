import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { Bar, Doughnut, Pie } from 'vue-chartjs';
export default {
    install(Vue) {
        ChartJS.register(BarElement, Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale);
        Vue.component('Bar', Bar);
        Vue.component('Doughnut', Doughnut);
        Vue.component('Pie', Pie);
        const onHover = (event, chartElement) => {
            if (event?.native?.target?.style) {
                event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
            }
        };
        Object.defineProperties(Vue.prototype, {
            $chartDefaults: {
                value: {
                    bar: {
                        maxBarThickness: 150,
                        scales: {
                            y: {
                                beginAtZero: true
                            }
                        },
                        onHover
                    },
                    doughnut: {
                        onHover
                    }
                }
            }
        });
    }
};
