import { __decorate } from "tslib";
import axios from 'axios';
import qs from 'qs';
import { Component, Mixins } from 'vue-property-decorator';
import AutocompleteMixin from './AutocompleteMixin';
import { getActiveSupportContext } from './CurrentUserMixin';
export var LocationsAutocompleteFields;
(function (LocationsAutocompleteFields) {
    LocationsAutocompleteFields["BUILDING"] = "BUILDING";
    LocationsAutocompleteFields["ROOM"] = "ROOM";
    LocationsAutocompleteFields["STOCK"] = "STOCK";
    LocationsAutocompleteFields["BIN"] = "BIN";
    LocationsAutocompleteFields["CONTRACT_NUMBER"] = "CONTRACT_NUMBER";
    LocationsAutocompleteFields["ORGANIZATION_CODE"] = "ORGANIZATION_CODE";
})(LocationsAutocompleteFields || (LocationsAutocompleteFields = {}));
let LocationsAutocompleteMixin = class LocationsAutocompleteMixin extends Mixins(AutocompleteMixin) {
    field = LocationsAutocompleteFields.BUILDING;
    async search(q) {
        if (!q?.trim()) {
            return [];
        }
        const currentUserActiveSupportContext = getActiveSupportContext();
        const headers = {
            'Content-Type': 'text/plain',
            'x-organization': currentUserActiveSupportContext || ''
        };
        const httpClient = axios.create({
            baseURL: `/api2`,
            headers,
            withCredentials: true,
            paramsSerializer: (params) => qs.stringify(params, {
                allowDots: true,
                arrayFormat: 'repeat'
            })
        });
        const queryString = qs.stringify({
            field: this.field,
            query: q,
            maxResults: 50
        });
        const resp = await httpClient.get(`${this.EntityType.LOCATION}/autocomplete?${queryString}`);
        return (resp.data || []).map((value) => ({
            displayText: value,
            value: value
        }));
    }
};
LocationsAutocompleteMixin = __decorate([
    Component
], LocationsAutocompleteMixin);
export default LocationsAutocompleteMixin;
