import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins } from 'vue-property-decorator';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import AutocompleteMixin from '~/nasa_ui/mixins/AutocompleteMixin';
let AppSelectSerial = class AppSelectSerial extends Mixins(AutocompleteMixin, BaseCosmic) {
    async search(q) {
        const query = gql `
      query GetSerialForAutoComplete($startsWith: String!) {
        autocompleteSerialNumber(serialNumberStartsWith: $startsWith)
      }
    `;
        const resp = await this.$apollo.query({
            query,
            variables: { startsWith: q }
        });
        return resp.data.autocompleteSerialNumber?.map((sn) => ({ displayText: sn, value: sn })) ?? [];
    }
};
AppSelectSerial = __decorate([
    Component
], AppSelectSerial);
export default AppSelectSerial;
