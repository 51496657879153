import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import BaseInput from '~/nasa_ui/base/BaseInput';
let AppCheckbox = class AppCheckbox extends BaseInput {
    val = false;
    removeMarginTop;
    get formattedLabel() {
        return this.required ? `${this.computedLabel} <span class="red--text caption">Required</span>` : this.label;
    }
    handleInput(val) { }
    emitClick() { }
    emitMouseover() { }
    emitMouseleave() { }
    onValueChange() {
        if (this.value === null || this.value === undefined || this.value === '') {
            this.val = false;
            return;
        }
        this.val = this.value;
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppCheckbox.prototype, "removeMarginTop", void 0);
__decorate([
    Emit('input'),
    Watch('val'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], AppCheckbox.prototype, "handleInput", null);
__decorate([
    Emit('click'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppCheckbox.prototype, "emitClick", null);
__decorate([
    Emit('mouseover'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppCheckbox.prototype, "emitMouseover", null);
__decorate([
    Emit('mouseleave'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppCheckbox.prototype, "emitMouseleave", null);
__decorate([
    Watch('value', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppCheckbox.prototype, "onValueChange", null);
AppCheckbox = __decorate([
    Component
], AppCheckbox);
export default AppCheckbox;
