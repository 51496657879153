import { __decorate } from "tslib";
import { Component, Mixins } from 'vue-property-decorator';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { EntityType } from '~/nasa_ui/types';
let TheUserAvatar = class TheUserAvatar extends Mixins(BaseCosmic) {
    randomIcon = null;
    get canNavigateToUser() {
        return Boolean(this.currentUserActiveSupportContext);
    }
    get canUseInitials() {
        return Boolean(this.currentUser?.firstName && this.currentUser?.lastName);
    }
    get color() {
        return EntityType.USER;
    }
    get isCosmic() {
        return this.currentUser?.auid && this.currentUser?.auid.toUpperCase() === 'COSMIC';
    }
    get initials() {
        if (!this.currentUser) {
            return '';
        }
        if (!this.canUseInitials) {
            return '';
        }
        const firstInitial = this.currentUser.firstName ? this.currentUser.firstName.charAt(0) : '';
        const lastInitial = this.currentUser.lastName ? this.currentUser.lastName.charAt(0) : '';
        return `${firstInitial}${lastInitial}`;
    }
    routeToUser(event) {
        if (!this.currentUser?.id || !this.canNavigateToUser) {
            return;
        }
        if (event.ctrlKey || event.metaKey) {
            window.open(`/${EntityType.USER}/manage/${this.currentUser?.id}`);
        }
        else {
            this.$router.push(`/${EntityType.USER}/manage/${this.currentUser.id}`);
        }
    }
    created() {
        this.randomIcon = this.selectRandomIcon();
    }
    selectRandomIcon() {
        const listOfIcons = [
            'fad fa-alicorn',
            'fad fa-badger-honey',
            'fad fa-cat-space',
            'fad fa-cat',
            'fad fa-cow',
            'fad fa-deer-rudolph',
            'fad fa-dog',
            'fad fa-dove',
            'fad fa-dragon',
            'fad fa-duck',
            'fad fa-elephant',
            'fad fa-frog',
            'fad fa-hippo',
            'fad fa-horse-saddle',
            'fad fa-kiwi-bird',
            'fad fa-monkey',
            'fad fa-narwhal',
            'fad fa-otter',
            'fad fa-paw-alt',
            'fad fa-paw-claws',
            'fad fa-paw',
            'fad fa-pegasus',
            'fad fa-pig',
            'fad fa-rabbit-fast',
            'fad fa-rabbit',
            'fad fa-ram',
            'fad fa-sheep',
            'fad fa-snake',
            'fad fa-spider-black-widow',
            'fad fa-spider',
            'fad fa-squirrel',
            'fad fa-turtle',
            'fad fa-unicorn',
            'fad fa-whale'
        ];
        return listOfIcons[Math.floor(Math.random() * listOfIcons.length)];
    }
};
TheUserAvatar = __decorate([
    Component
], TheUserAvatar);
export default TheUserAvatar;
