export var CosmicSystem;
(function (CosmicSystem) {
    CosmicSystem["ESOC"] = "ESOC";
})(CosmicSystem || (CosmicSystem = {}));
export var CosmicUserSupportContext;
(function (CosmicUserSupportContext) {
    CosmicUserSupportContext["CTSD"] = "CTSD";
    CosmicUserSupportContext["EHP"] = "EHP";
    CosmicUserSupportContext["ER"] = "ER";
    CosmicUserSupportContext["ES"] = "ES";
    CosmicUserSupportContext["ESOC"] = "ESOC";
    CosmicUserSupportContext["EV"] = "EV";
    CosmicUserSupportContext["NASA"] = "NASA";
    CosmicUserSupportContext["xEMU"] = "xEMU";
})(CosmicUserSupportContext || (CosmicUserSupportContext = {}));
export const CosmicSystemToCosmicUserSupport = {
    ESOC: [
        CosmicUserSupportContext.CTSD,
        CosmicUserSupportContext.EHP,
        CosmicUserSupportContext.ER,
        CosmicUserSupportContext.ES,
        CosmicUserSupportContext.ESOC,
        CosmicUserSupportContext.EV,
        CosmicUserSupportContext.xEMU
    ]
};
