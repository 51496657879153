import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop } from 'vue-property-decorator';
import BaseButtonMiniActionImplementation from '~/nasa_ui/base/BaseButtonMiniActionImplementation';
import ButtonMiniAction from '~/nasa_ui/components/buttons/ButtonMiniAction/ButtonMiniAction.vue';
/**
 *
 */
let ButtonMiniActionCreate = class ButtonMiniActionCreate extends Mixins(BaseButtonMiniActionImplementation) {
    to;
    get computedTooltip() {
        return this.tooltip || 'Create item';
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], ButtonMiniActionCreate.prototype, "to", void 0);
ButtonMiniActionCreate = __decorate([
    Component({
        components: { ButtonMiniAction }
    })
], ButtonMiniActionCreate);
export default ButtonMiniActionCreate;
