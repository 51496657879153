import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { DESCRIPTION_COL, ITEM_NUMBER_COL, LOT_COL, QUANTITY_COL, SERIAL_COL, UNIT_COST_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { HeaderSortOptions } from '~/nasa_ui/types';
import BaseDueInInventoryTable from '../BaseDueInInventoryTable';
let InventoryDueBackTable = class InventoryDueBackTable extends BaseDueInInventoryTable {
    expectedReturnDate = '';
    expectedReturnOrganizationCode = '';
    isArchived = false;
    selectedRow = null;
    selectedRowId;
    shouldShowDueBackModal = false;
    get dueBackHardwareIsInUsersContext() {
        if (!this.selectedRow) {
            return false;
        }
        // if there no "issueDestinationOrganizationCode" then the inventory is still
        // in the user's context but just located at a manufacturer or vendor
        return this.selectedRow.issueDestinationOrganizationCode === null;
    }
    get computedTableHeaders() {
        return [
            ITEM_NUMBER_COL,
            SERIAL_COL,
            LOT_COL,
            DESCRIPTION_COL,
            {
                text: 'Issue',
                value: '_issueDate',
                sortable: true,
                headerSortType: HeaderSortOptions.DATE
            },
            {
                text: 'Issued to Org',
                value: 'currentLocationOrganizationName',
                sortable: true,
                noWrap: true
            },
            {
                text: 'Return',
                value: '_expectedReturnDate',
                sortable: true,
                headerSortType: HeaderSortOptions.DATE,
                monospacedFont: true
            },
            QUANTITY_COL,
            UNIT_COST_COL
        ];
    }
    get selectedInventoryId() {
        if (!this.selectedRow) {
            return null;
        }
        return this.selectedRow.inventoryId || null;
    }
    buildLink(item) {
        if (!item.inventoryId || !item.supportOrganizationCode) {
            return '';
        }
        return `/hardware/receive_inventory?inventoryId=${item.inventoryId}&context=${item.supportOrganizationCode}`;
    }
    updateDueBackDate(item) {
        if (!item.inventoryId || !item.expectedReturnDate || !item.expectedReturnOrganizationCode) {
            return '';
        }
        this.selectedRowId = item.inventoryId;
        this.shouldShowDueBackModal = true;
        this.expectedReturnDate = item.expectedReturnDate;
        this.expectedReturnOrganizationCode = item.expectedReturnOrganizationCode;
    }
};
InventoryDueBackTable = __decorate([
    Component
], InventoryDueBackTable);
export default InventoryDueBackTable;
