/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./AppTableSelectable.vue?vue&type=template&id=3b3a0aea&scoped=true"
import script from "./AppTableSelectable.ts?vue&type=script&lang=ts&external"
export * from "./AppTableSelectable.ts?vue&type=script&lang=ts&external"
import style0 from "./AppTableSelectable.vue?vue&type=style&index=0&id=3b3a0aea&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b3a0aea",
  null
  
)

export default component.exports