import { __decorate } from "tslib";
import { Component, Mixins } from 'vue-property-decorator';
import BaseModalHardwareItemTemplate from '~/nasa_ui/base/BaseModalHardwareItemTemplate';
let ModalHardwareListTemplateItemCreate = class ModalHardwareListTemplateItemCreate extends Mixins(BaseModalHardwareItemTemplate) {
    get createHardwareItemTemplateMutationVariables() {
        if (!this.isFormValid || !this.parentData) {
            return null;
        }
        return {
            hardwareListTemplateParts: this.computedHardwareItemTemplateParts,
            hardwareListTemplateId: this.parentData.id,
            maxQuantity: this.formData.maxQuantity,
            minQuantity: this.formData.minQuantity,
            name: this.formData.name,
            sequence: this.formData.sequence || 10
        };
    }
    get minLessThanOrEqualToMax() {
        return this.formData.minQuantity <= this.formData.maxQuantity || 'Min Qty must be less than or equal to Max Qty.';
    }
    get sequenceNumberErrorMessage() {
        if (this.isDuplicateBySequenceByParent ||
            this.transformedHardwareItems.find((node) => node._sequence == this.formData.sequence)) {
            return 'Sequence number exists.';
        }
        else if (!this.sequenceIsWithinSmallIntRange) {
            return 'Sequence number is out of range.';
        }
    }
    get shouldDisableCreateButton() {
        return !this.isFormValid;
    }
    onClickOfCreateAssemblyTemplate() {
        this.createEntity(this.createHardwareItemTemplateMutationVariables);
    }
    onMetaPlusEnter() {
        if (!this.isFormValid) {
            return;
        }
        this.createEntity(this.createHardwareItemTemplateMutationVariables);
    }
};
ModalHardwareListTemplateItemCreate = __decorate([
    Component
], ModalHardwareListTemplateItemCreate);
export default ModalHardwareListTemplateItemCreate;
