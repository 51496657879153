import gql from 'graphql-tag';
export const CosmicEventSlimFragment = gql `
  fragment CosmicEventSlim on Event {
    attributes
    createdByUserId
    createdDateTime
    description
    endDate
    id
    isHardwareLocationReturn
    locationId
    name
    nodeId
    startDate
    subType
    updatedDateTime
  }
`;
