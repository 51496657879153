import { getBlurbAboutCreate, getBlurbAboutManage, getBlurbAboutSearch } from '~/nasa_ui/router/utils';
import { EntityType, EntityTypeSingularDisplay } from '~/nasa_ui/types';
export const VehiclesSharedRoutes = [
    {
        path: `/${EntityType.VEHICLE}`,
        redirect: {
            name: 'VehiclesSearchView'
        },
        meta: {
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.VEHICLE)),
            displayName: `${EntityTypeSingularDisplay.get(EntityType.VEHICLE)} search`,
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.VEHICLE}/manage`,
        redirect: {
            name: 'VehiclesSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.VEHICLE)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.VEHICLE)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.VEHICLE}/create`,
        name: 'VehiclesCreateView',
        component: () => import(/* webpackChunkName: "Vehicles" */ '../views/VehiclesCreateView/VehiclesCreateView.vue'),
        meta: {
            displayName: 'Vehicles create',
            description: getBlurbAboutCreate(EntityTypeSingularDisplay.get(EntityType.VEHICLE)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.VEHICLE}/manage/:id`,
        name: 'VehiclesManageView',
        component: () => import(/* webpackChunkName: "Vehicles" */ '../views/VehiclesManageView/VehiclesManageView.vue'),
        meta: {
            displayName: 'Vehicles manage',
            description: getBlurbAboutManage(EntityTypeSingularDisplay.get(EntityType.VEHICLE)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.VEHICLE}/search`,
        name: 'VehiclesSearchView',
        component: () => import(/* webpackChunkName: "Vehicles" */ '../views/VehiclesSearchView/VehiclesSearchView.vue'),
        meta: {
            displayName: 'Vehicles search',
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.VEHICLE)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    }
];
