import { PreciousMetal } from '~/db_types';
export const PreciousMetalDisplay = new Map([
    [PreciousMetal.GOLD, 'AU: GOLD'],
    [PreciousMetal.IRIDIUM, 'IR: IRIDIUM'],
    [PreciousMetal.SILVER, 'AG: SILVER'],
    [PreciousMetal.PLATINUM, 'PT: PLATINUM'],
    [PreciousMetal.PALLADIUM, 'PD: PALLADIUM'],
    [PreciousMetal.OSMIUM, 'OS: OSMIUM'],
    [PreciousMetal.RHODIUM, 'RH: RHODIUM'],
    [PreciousMetal.RUTHENIUM, 'RU: RUTHENIUM']
]);
