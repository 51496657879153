import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins } from 'vue-property-decorator';
import LoaderMixin from '~/nasa_ui/mixins/LoaderMixin';
import { httpGetFactory, httpPostFactory, httpPutFactory } from '../base/httpFactories';
import { mutationFactory, queryFactory } from '../base/mixinFactories';
import { CosmicLocationSlimFragment } from '../location/slim';
import { CosmicUserSlimFragment } from '../user';
import { CosmicEventSlimFragment } from './slim';
export const CosmicEventFragment = gql `
  ${CosmicEventSlimFragment}
  ${CosmicLocationSlimFragment}
  ${CosmicUserSlimFragment}

  fragment CosmicEvent on Event {
    ...CosmicEventSlim

    createdByUser {
      ...CosmicUserSlim
    }

    location {
      ...CosmicLocationSlim
    }
  }
`;
export const CosmicEventWithHardwareListsFragment = gql `
  ${CosmicEventSlimFragment}

  fragment CosmicEventWithHardwareLists on Event {
    ...CosmicEventSlim

    hardwareLists {
      nodes {
        addendumDate
        addendumNumber
        createdByUserId
        createdDateTime
        id
        name
        number
        subType

        hardwareListAssemblies {
          totalCount
          nodes {
            id
            isExternal
            quantity
            sequence
          }
        }
      }
    }
  }
`;
export const CosmicEventWithLocationFragment = gql `
  ${CosmicEventFragment}
  ${CosmicLocationSlimFragment}

  fragment CosmicEventWithLocation on Event {
    ...CosmicEvent

    location {
      ...CosmicLocationSlim
    }
  }
`;
export const GetEventByNodeId = gql `
  ${CosmicEventFragment}

  query GetEventByNodeId($nodeId: ID!) {
    event(nodeId: $nodeId) {
      ...CosmicEvent
    }
  }
`;
export const GetEventById = gql `
  ${CosmicEventFragment}

  query GetEventById($id: UUID!) {
    eventById(id: $id) {
      ...CosmicEvent
    }
  }
`;
export const GetEventWithHardwareListsById = gql `
  ${CosmicEventWithHardwareListsFragment}

  query GetEventWithHardwareListsById($id: UUID!) {
    eventById(id: $id) {
      ...CosmicEventWithHardwareLists
    }
  }
`;
export const SearchEventsQuery = gql `
  ${CosmicEventWithLocationFragment}

  query searchEvents($between: DatetimeRangeInput, $firstResults: Int, $query: String, $subType: [String]) {
    searchEvents(between: $between, first: $firstResults, query: $query, subType: $subType) {
      nodes {
        ...CosmicEventWithLocation
      }
    }
  }
`;
export const CreateEvent = gql `
  ${CosmicEventFragment}

  mutation CreateDigitalRecordReviewEvent($eventInput: EventInput!) {
    createEvent(input: { event: $eventInput }) {
      event {
        ...CosmicEvent
      }
    }
  }
`;
export const UpdateEventById = gql `
  ${CosmicEventFragment}

  mutation UpdateEventById($id: UUID!, $patch: EventPatch!) {
    updateEventById(input: { id: $id, patch: $patch }) {
      event {
        ...CosmicEvent
      }
    }
  }
`;
let EventRepoMixin = class EventRepoMixin extends Mixins(LoaderMixin) {
    getEventByNodeId = queryFactory(this, GetEventByNodeId);
    getEventById = queryFactory(this, GetEventById);
    getEventWithHardwareListsById = queryFactory(this, GetEventWithHardwareListsById);
    updateEventById = mutationFactory(this, UpdateEventById);
    // Awkward naming -- these uses api v2
    fetchEvent(id, message, params) {
        const httpGet = httpGetFactory(this);
        return httpGet(`/cosmic-events/${id}`, message, params);
    }
    createEvent(body, message, config) {
        const httpPost = httpPostFactory(this, '/cosmic-events');
        return httpPost(body, message, config);
    }
    updateEvent(id, body, message, params) {
        const httpPut = httpPutFactory(this, `/cosmic-events/${id}`);
        return httpPut(body, message, params);
    }
};
EventRepoMixin = __decorate([
    Component
], EventRepoMixin);
export { EventRepoMixin };
