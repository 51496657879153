// NoAccess, NoSupports, 404, etc
export const NonnominalSharedRoutes = [
    {
        path: `/insufficient_user_role`,
        name: 'InsufficientUserRoleView',
        component: () => import(
        /* webpackChunkName: "NonnominalSharedRoutes" */ '../views/InsufficientUserRoleView/InsufficientUserRoleView.vue'),
        meta: {
            displayName: 'Insufficient User role',
            description: 'The logged in User does not have a role assignment equal to or greater than what is required.',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/insufficient_user_permission`,
        name: 'InsufficientUserPermissionView',
        component: () => import(
        /* webpackChunkName: "NonnominalSharedRoutes" */ '../views/InsufficientUserPermissionView/InsufficientUserPermissionView.vue'),
        meta: {
            displayName: 'Insufficient User permission',
            description: 'The logged in User does not have the required permission.',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/no_access`,
        name: 'NoAccess',
        component: () => import(/* webpackChunkName: "NonnominalSharedRoutes" */ '../components/NoAccessView/NoAccessView.vue'),
        meta: {
            displayName: 'No access',
            description: 'Outdated view where users get directed to if they generally do not have access.',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/*`,
        name: 'NotFound',
        component: () => import(/* webpackChunkName: "NonnominalSharedRoutes" */ '../components/NotFoundView/NotFoundView.vue'),
        meta: {
            displayName: 'No found',
            description: 'Something happened.',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    }
];
