var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-textarea', _vm._g(_vm._b({
    ref: "textarea",
    attrs: {
      "label": _vm.label,
      "messages": _vm.computedMessages,
      "rules": _vm.localRulesBaseOnProps,
      "outline": ""
    },
    on: {
      "input": _vm.emitInput
    }
  }, 'v-textarea', _vm.$props, false), _vm.$listeners));
};
var staticRenderFns = [];
export { render, staticRenderFns };