import { __decorate } from "tslib";
import Component from 'vue-class-component';
import { Mixins } from 'vue-property-decorator';
import LoaderMixin from '~/nasa_ui/mixins/LoaderMixin';
import { httpDeleteFactory, httpGetFactory, httpPostFactory, httpPutFactory } from '../base/httpFactories';
let ContactRepoMixin = class ContactRepoMixin extends Mixins(LoaderMixin) {
    createContact(body, message, config) {
        const httpPost = httpPostFactory(this, '/contacts');
        return httpPost(body, message, config);
    }
    deleteContact(id, message, params) {
        const httpDelete = httpDeleteFactory(this);
        return httpDelete(`/contacts/${id}`, message, params);
    }
    fetchContact(id, message, params) {
        const httpGet = httpGetFactory(this);
        return httpGet(`/contacts/${id}`, message, params);
    }
    getAllContacts(message, params) {
        const httpGet = httpGetFactory(this);
        return httpGet(`/contacts?take=-1`, message, params);
    }
    updateContact(id, body, message, params) {
        const httpPut = httpPutFactory(this, `/contacts/${id}`);
        return httpPut(body, message, params);
    }
};
ContactRepoMixin = __decorate([
    Component
], ContactRepoMixin);
export { ContactRepoMixin };
