var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST_TEMPLATE,
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('Alert', {
          staticClass: "shake",
          class: {
            on: _vm.isDuplicate,
            off: !_vm.isDuplicate
          },
          attrs: {
            "type": _vm.duplicateAlertType
          }
        }, [_vm._v(" Hardware List templates must be unique by "), _c('strong', [_vm._v("Name")]), _vm._v(" and "), _c('strong', [_vm._v("Type")]), _vm._v(". ")]), _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs4": "",
            "pr-2": ""
          }
        }, [_c('c-text', {
          attrs: {
            "label": "Name",
            "c-value": _vm.formData.name,
            "required": "",
            "clearable": ""
          },
          on: {
            "c-input": function cInput($event) {
              _vm.formData.name = $event.detail;
            }
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs4": "",
            "pr-2": ""
          }
        }, [_c('AppSelectEnum', {
          attrs: {
            "chips": false,
            "display": _vm.HardwareListTypeDisplay,
            "enum": _vm.HardwareListType,
            "color": _vm.EntityType.HARDWARE_LIST_TEMPLATE,
            "label": "List Type",
            "required": ""
          },
          model: {
            value: _vm.formData.subType,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "subType", $$v);
            },
            expression: "formData.subType"
          }
        })], 1)], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('ButtonSave', {
          staticClass: "ml-0",
          attrs: {
            "disabled": _vm.shouldDisableEditButton,
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfModalHardwareListEditTemplate
          }
        }), _vm.shouldDisplayManageLink ? _c('ButtonLink', {
          staticClass: "ml-2",
          attrs: {
            "color": _vm.EntityType.HARDWARE_LIST_TEMPLATE,
            "href": _vm.manageUrl,
            "dark": "",
            "icon": "$icons.part_tool"
          }
        }, [_vm._v(" Manage Hardware List Template ")]) : _vm._e(), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        }), _c('v-spacer'), _c('ButtonRemove', {
          on: {
            "click": _vm.deleteEntity
          }
        }, [_vm._v("Delete Hardware List Template")])];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };