function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "navbar_link mr-2",
    class: {
      is_open: _vm.isOpen
    }
  }, [_c('v-menu', {
    attrs: {
      "content-class": "approvals_menu",
      "offset-y": "",
      "left": "",
      "z-index": "11"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var menu = _ref.on;
        return [_c('button', _vm._g({
          staticClass: "toolbar_button",
          attrs: {
            "tooltip": "View your urgent Approvals"
          }
        }, _objectSpread({}, menu)), [_c('v-badge', {
          attrs: {
            "right": "",
            "color": "error"
          },
          scopedSlots: _vm._u([_vm.badgeCount ? {
            key: "badge",
            fn: function fn() {
              return [_c('span', {
                staticClass: "badge_display"
              }, [_vm._v(_vm._s(_vm.badgeCount))])];
            },
            proxy: true
          } : null], null, true)
        }, [_vm._v(" My Approvals ")])], 1)];
      }
    }]),
    model: {
      value: _vm.isOpen,
      callback: function callback($$v) {
        _vm.isOpen = $$v;
      },
      expression: "isOpen"
    }
  }, [_c('ButtonMiniAction', {
    staticClass: "refresh_button",
    class: {
      is_loading: _vm.isLoading
    },
    attrs: {
      "icon": _vm.$icons.refresh,
      "icon-size": "18"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.onClickOfRefresh.apply(null, arguments);
      }
    }
  }), _vm.approvals.length ? _c('v-list', {
    staticClass: "pb-0",
    attrs: {
      "three-line": ""
    }
  }, [_c('v-subheader', {
    staticClass: "approvals_subheader mt-2"
  }, [_vm._v("Urgent Approvals")]), _vm._l(_vm.computedApprovals, function (item, index) {
    return [_c('v-list-tile', {
      key: item.id,
      attrs: {
        "to": item.url,
        "active-class": ""
      }
    }, [_c('v-list-tile-avatar', {
      directives: [{
        name: "safe-html",
        rawName: "v-safe-html",
        value: item.icon,
        expression: "item.icon"
      }],
      staticClass: "approval_icon"
    }), _c('v-list-tile-content', [_c('v-list-tile-title', [_vm._v(_vm._s(item.title))]), _c('v-list-tile-sub-title', {
      staticClass: "approval_meta"
    }, [_vm._v(_vm._s(item.description))]), _c('v-list-tile-sub-title', {
      staticClass: "approval_meta"
    }, [_vm._v("On behalf of: " + _vm._s(item.groupName))])], 1)], 1), index + 1 < _vm.approvals.length ? _c('v-divider', {
      key: index,
      attrs: {
        "inset": ""
      }
    }) : _vm._e()];
  }), _vm.extraApprovals ? _c('p', {
    staticClass: "extra_approval_count ma-0 grey--text text--darken-3"
  }, [_vm._v(" … and "), _c('strong', {
    staticClass: "monospace_font mx-1"
  }, [_vm._v(_vm._s(_vm.extraApprovals))]), _vm._v(" more. ")]) : _vm._e(), _c('v-divider')], 2) : _vm.isError ? _c('v-list', {
    staticClass: "pb-0"
  }, [_c('v-subheader', {
    staticClass: "approvals_subheader mt-2"
  }, [_vm._v("Urgent Approvals")]), _c('v-list-tile', {
    staticClass: "red--text text--darken-2"
  }, [_vm._v("Failed to fetch approvals")]), _c('v-divider')], 1) : !_vm.approvals.length ? _c('v-list', {
    staticClass: "pb-0"
  }, [_c('v-subheader', {
    staticClass: "approvals_subheader mt-2"
  }, [_vm._v("Urgent Approvals")]), _c('v-list-tile', [_vm._v("No urgent Approvals")]), _c('v-divider')], 1) : _vm._e(), _c('div', {
    staticClass: "button_wrap"
  }, [_c('ButtonGeneric', {
    staticClass: "ml-2",
    attrs: {
      "icon": _vm.$icons['application_reporting'],
      "text-color": _vm.EntityType.CHANGE_SET,
      "to": _vm.approvalsReportUrl,
      "flat": ""
    }
  }, [_vm._v("View all Upcoming Approvals")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };