import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { CommentTypeDisplay } from '~/nasa_ui/types';
import { addCommentFromMarkdownDisplay, addDateDisplays, addSubTypeDisplay, addUserDisplays } from '../displayMappers';
import { buildEntityTypeAsString, buildEntityTypeIcons, transformItemDrawings, transformItemInstances, transformItemMasters } from '../transformEntityToTableFormat';
export const transformComments = (items) => {
    return items
        .map(addUserDisplays)
        .map(addDateDisplays)
        .map(addCommentFromMarkdownDisplay)
        .map(addSubTypeDisplay(CommentTypeDisplay))
        .map((comment) => {
        const _comment = { ...comment };
        _comment._entityType = buildEntityTypeIcons(_comment);
        _comment._entityTypeAsString = buildEntityTypeAsString(_comment);
        // UPDATE logic
        if (!_comment.updatedByUserId) {
            _comment._updatedByUser = _comment._createdByUser || DEFAULT_DASH;
            _comment._updatedDateTime = _comment._createdDateTime;
            _comment._updatedDateTime = _comment._createdDateTime;
            _comment._updatedDateTimeWithBreak = _comment._createdDateTimeWithBreak;
            _comment._updatedDateDistanceInWordsFromToday = _comment._createdDateTimeDistanceInWordsFromToday;
            _comment._updatedDateTicks = _comment._createdDateTicks;
        }
        _comment._itemDrawing = _comment.itemDrawing
            ? transformItemDrawings([_comment.itemDrawing])[0]
            : null;
        _comment._itemMaster = _comment.itemMaster
            ? transformItemMasters([_comment.itemMaster])[0]
            : null;
        _comment._itemInstance = _comment.itemInstance
            ? transformItemInstances([_comment.itemInstance])[0]
            : null;
        return _comment;
    });
};
