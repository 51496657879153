import { __decorate, __metadata } from "tslib";
import { DebounceAll } from 'lodash-decorators';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { END_DATE_COL, NAME_COL, START_DATE_COL, SUB_TYPE_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { EntityType } from '~/nasa_ui/types';
import { getAssemblyTemplate } from '~/nasa_ui/utils';
import { transformAssemblyTemplateResponseWithChildrens } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let ModalAssemblyTemplateInfo = class ModalAssemblyTemplateInfo extends Mixins(BaseModal) {
    entityType = EntityType.ASSEMBLY_TEMPLATE;
    selectedEntity = null;
    cosmicEventTableHeaders = [SUB_TYPE_COL, NAME_COL, START_DATE_COL, END_DATE_COL];
    assemblyTemplateId;
    get transformedSelectedEntity() {
        return this.selectedEntity ? transformAssemblyTemplateResponseWithChildrens([this.selectedEntity])[0] : null;
    }
    async fetchEntity() {
        if (!this.assemblyTemplateId) {
            return;
        }
        try {
            const resp = await getAssemblyTemplate(this.assemblyTemplateId);
            this.selectedEntity = resp.data;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
};
__decorate([
    Prop({
        required: true,
        type: String
    }),
    __metadata("design:type", String)
], ModalAssemblyTemplateInfo.prototype, "assemblyTemplateId", void 0);
__decorate([
    DebounceAll(100),
    Watch('assemblyTemplateId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalAssemblyTemplateInfo.prototype, "fetchEntity", null);
ModalAssemblyTemplateInfo = __decorate([
    Component
], ModalAssemblyTemplateInfo);
export default ModalAssemblyTemplateInfo;
