import { GreenProcurement } from '~/db_types';
export const GreenProcurementDisplay = new Map([
    [GreenProcurement.N2, 'N2'],
    [GreenProcurement.N3, 'N3'],
    [GreenProcurement.N4, 'N4'],
    [GreenProcurement.N5, 'N5'],
    [GreenProcurement.N6, 'N6'],
    [GreenProcurement.N7, 'N7'],
    [GreenProcurement.N8, 'N8'],
    [GreenProcurement.N9, 'N9'],
    [GreenProcurement.N10, 'N10'],
    [GreenProcurement.N11, 'N11'],
    [GreenProcurement.N12, 'N12'],
    [GreenProcurement.N13, 'N13'],
    [GreenProcurement.N16, 'N16'],
    [GreenProcurement.N17, 'N17'],
    [GreenProcurement.N18, 'N18'],
    [GreenProcurement.N19, 'N19'],
    [GreenProcurement.N20, 'N20'],
    [GreenProcurement.N21, 'N21'],
    [GreenProcurement.N22, 'N22'],
    [GreenProcurement.N23, 'N23'],
    [GreenProcurement.N24, 'N24'],
    [GreenProcurement.N25, 'N25'],
    [GreenProcurement.P1, 'P1'],
    [GreenProcurement.P2, 'P2'],
    [GreenProcurement.P3, 'P3'],
    [GreenProcurement.P4, 'P4'],
    [GreenProcurement.P5, 'P5'],
    [GreenProcurement.P6, 'P6'],
    [GreenProcurement.P7, 'P7'],
    [GreenProcurement.P8, 'P8'],
    [GreenProcurement.P9, 'P9'],
    [GreenProcurement.P10, 'P10'],
    [GreenProcurement.P11, 'P11'],
    [GreenProcurement.P12, 'P12'],
    [GreenProcurement.P13, 'P13'],
    [GreenProcurement.P14, 'P14'],
    [GreenProcurement.P15, 'P15'],
    [GreenProcurement.P16, 'P16'],
    [GreenProcurement.P17, 'P17'],
    [GreenProcurement.P18, 'P18'],
    [GreenProcurement.P19, 'P19'],
    [GreenProcurement.P20, 'P20'],
    [GreenProcurement.P21, 'P21'],
    [GreenProcurement.P22, 'P22'],
    [GreenProcurement.P23, 'P23'],
    [GreenProcurement.P24, 'P24'],
    [GreenProcurement.P25, 'P25'],
    [GreenProcurement.P26, 'P26'],
    [GreenProcurement.P27, 'P27'],
    [GreenProcurement.P28, 'P28'],
    [GreenProcurement.P29, 'P29'],
    [GreenProcurement.P30, 'P30'],
    [GreenProcurement.P31, 'P31'],
    [GreenProcurement.P32, 'P32'],
    [GreenProcurement.P33, 'P33'],
    [GreenProcurement.P34, 'P34'],
    [GreenProcurement.P35, 'P35'],
    [GreenProcurement.P36, 'P36'],
    [GreenProcurement.R1, 'R1'],
    [GreenProcurement.R2, 'R2'],
    [GreenProcurement.R3, 'R3'],
    [GreenProcurement.R4, 'R4'],
    [GreenProcurement.R5, 'R5'],
    [GreenProcurement.R6, 'R6'],
    [GreenProcurement.R7, 'R7'],
    [GreenProcurement.R8, 'R8'],
    [GreenProcurement.R9, 'R9'],
    [GreenProcurement.T1, 'T1'],
    [GreenProcurement.T2, 'T2'],
    [GreenProcurement.T3, 'T3'],
    [GreenProcurement.T4, 'T4'],
    [GreenProcurement.T5, 'T5'],
    [GreenProcurement.T6, 'T6'],
    [GreenProcurement.T14, 'T14'],
    [GreenProcurement.T7, 'T7'],
    [GreenProcurement.T8, 'T8'],
    [GreenProcurement.T9, 'T9'],
    [GreenProcurement.T10, 'T10'],
    [GreenProcurement.T11, 'T11'],
    [GreenProcurement.T12, 'T12'],
    [GreenProcurement.T15, 'T15'],
    [GreenProcurement.T13, 'T13'],
    [GreenProcurement.V1, 'V1'],
    [GreenProcurement.V2, 'V2'],
    [GreenProcurement.V3, 'V3'],
    [GreenProcurement.C1, 'C1'],
    [GreenProcurement.C2, 'C2'],
    [GreenProcurement.C4, 'C4'],
    [GreenProcurement.C5, 'C5'],
    [GreenProcurement.C6, 'C6'],
    [GreenProcurement.C7, 'C7'],
    [GreenProcurement.C8, 'C8'],
    [GreenProcurement.C9, 'C9'],
    [GreenProcurement.C10, 'C10'],
    [GreenProcurement.C11, 'C11'],
    [GreenProcurement.C12, 'C12'],
    [GreenProcurement.C13, 'C13'],
    [GreenProcurement.C14, 'C14'],
    [GreenProcurement.C15, 'C15'],
    [GreenProcurement.C16, 'C16'],
    [GreenProcurement.C17, 'C17'],
    [GreenProcurement.C18, 'C18'],
    [GreenProcurement.C19, 'C19'],
    [GreenProcurement.C20, 'C20'],
    [GreenProcurement.C21, 'C21'],
    [GreenProcurement.C22, 'C22'],
    [GreenProcurement.C23, 'C23'],
    [GreenProcurement.C24, 'C24'],
    [GreenProcurement.C25, 'C25'],
    [GreenProcurement.C26, 'C26'],
    [GreenProcurement.C27, 'C27'],
    [GreenProcurement.C28, 'C28'],
    [GreenProcurement.C29, 'C29'],
    [GreenProcurement.L1, 'L1'],
    [GreenProcurement.L2, 'L2'],
    [GreenProcurement.L3, 'L3'],
    [GreenProcurement.L4, 'L4'],
    [GreenProcurement.L5, 'L5'],
    [GreenProcurement.L6, 'L6'],
    [GreenProcurement.L7, 'L7'],
    [GreenProcurement.L8, 'L8'],
    [GreenProcurement.L9, 'L9'],
    [GreenProcurement.M1, 'M1'],
    [GreenProcurement.M2, 'M2'],
    [GreenProcurement.M3, 'M3'],
    [GreenProcurement.M4, 'M4'],
    [GreenProcurement.M5, 'M5'],
    [GreenProcurement.M6, 'M6'],
    [GreenProcurement.M7, 'M7'],
    [GreenProcurement.M8, 'M8'],
    [GreenProcurement.M9, 'M9'],
    [GreenProcurement.M10, 'M10'],
    [GreenProcurement.M11, 'M11'],
    [GreenProcurement.M12, 'M12'],
    [GreenProcurement.M13, 'M13'],
    [GreenProcurement.M14, 'M14'],
    [GreenProcurement.M15, 'M15'],
    [GreenProcurement.M16, 'M16'],
    [GreenProcurement.M17, 'M17'],
    [GreenProcurement.M18, 'M18'],
    [GreenProcurement.M19, 'M19'],
    [GreenProcurement.M20, 'M20'],
    [GreenProcurement.M21, 'M21'],
    [GreenProcurement.M22, 'M22'],
    [GreenProcurement.M23, 'M23'],
    [GreenProcurement.M24, 'M24'],
    [GreenProcurement.M25, 'M25'],
    [GreenProcurement.M26, 'M26'],
    [GreenProcurement.N1, 'N1'],
    [GreenProcurement.M27, 'M27']
]);
export const GreenProcurementCategoryMapping = new Map([
    [
        GreenProcurement.N2,
        {
            category: 'NON-PAPER OFFICE',
            subCategory: 'OFFICE RECYC CONT/WASTE RECEPTACLES-INDUSTRIAL PAPERBOARD'
        }
    ],
    [
        GreenProcurement.N3,
        {
            category: 'NON-PAPER OFFICE',
            subCategory: 'OFFICE RECYC CONT/WASTE RECEPTACLES-PAPER,SOLID FIBER BOX'
        }
    ],
    [
        GreenProcurement.N4,
        {
            category: 'NON-PAPER OFFICE',
            subCategory: 'OFFICE RECYC CONT/WASTE RECEPTACLES-PLASTIC'
        }
    ],
    [
        GreenProcurement.N5,
        {
            category: 'NON-PAPER OFFICE',
            subCategory: 'OFFICE RECYC CONT/WASTE RECEPTACLES-STEEL(BOF)'
        }
    ],
    [
        GreenProcurement.N6,
        {
            category: 'NON-PAPER OFFICE',
            subCategory: 'PAPER COVERED BINDERS'
        }
    ],
    [
        GreenProcurement.N7,
        {
            category: 'NON-PAPER OFFICE',
            subCategory: 'PLASTIC CLIP PORTFOLIOS-HDPE'
        }
    ],
    [
        GreenProcurement.N8,
        {
            category: 'NON-PAPER OFFICE',
            subCategory: 'PLASTIC CLIPBOARDS-HDPE'
        }
    ],
    [
        GreenProcurement.N9,
        {
            category: 'NON-PAPER OFFICE',
            subCategory: 'PLASTIC CLIPBOARDS-MISCELLANEOUS PLASTICS'
        }
    ],
    [
        GreenProcurement.N10,
        {
            category: 'NON-PAPER OFFICE',
            subCategory: 'PLASTIC CLIPBOARDS-POLYSTYRENE (PS)'
        }
    ],
    [
        GreenProcurement.N11,
        {
            category: 'NON-PAPER OFFICE',
            subCategory: 'PLASTIC COVERED BINDERS'
        }
    ],
    [
        GreenProcurement.N12,
        {
            category: 'NON-PAPER OFFICE',
            subCategory: 'PLASTIC DESKTOP ACCESSORIES'
        }
    ],
    [
        GreenProcurement.N13,
        {
            category: 'NON-PAPER OFFICE',
            subCategory: 'PLASTIC ENVELOPES'
        }
    ],
    [
        GreenProcurement.N16,
        {
            category: 'NON-PAPER OFFICE',
            subCategory: 'PLASTIC FILE FOLDERS-HDPE'
        }
    ],
    [
        GreenProcurement.N17,
        {
            category: 'NON-PAPER OFFICE',
            subCategory: 'PLASTIC PRESENTATION FOLDERS-HDPE'
        }
    ],
    [
        GreenProcurement.N18,
        {
            category: 'NON-PAPER OFFICE',
            subCategory: 'PLASTIC TRASH BAGS'
        }
    ],
    [
        GreenProcurement.N19,
        {
            category: 'NON-PAPER OFFICE',
            subCategory: 'PRESSBOARD BINDERS'
        }
    ],
    [
        GreenProcurement.N20,
        {
            category: 'NON-PAPER OFFICE',
            subCategory: 'PRINTER RIBBONS'
        }
    ],
    [
        GreenProcurement.N21,
        {
            category: 'NON-PAPER OFFICE',
            subCategory: 'SOLID PLASTIC BINDERS-HIGH DENSITY POLYETHYLENE(HDPE)'
        }
    ],
    [
        GreenProcurement.N22,
        {
            category: 'NON-PAPER OFFICE',
            subCategory: 'SOLID PLASTIC BINDERS-MISCELLANEOUS PLASTIC'
        }
    ],
    [
        GreenProcurement.N23,
        {
            category: 'NON-PAPER OFFICE',
            subCategory: 'SOLID PLASTIC BINDERS-POLYETHYLENE(PE)'
        }
    ],
    [
        GreenProcurement.N24,
        {
            category: 'NON-PAPER OFFICE',
            subCategory: 'SOLID PLASTIC BINDERS-POLYETHYLENE TETRAPHTALATE(PET)'
        }
    ],
    [
        GreenProcurement.N25,
        {
            category: 'NON-PAPER OFFICE',
            subCategory: 'TONER CARTRIDGES'
        }
    ],
    [
        GreenProcurement.P1,
        {
            category: 'PAPER OFFICE',
            subCategory: 'BATHROOM TISSUE'
        }
    ],
    [
        GreenProcurement.P2,
        {
            category: 'PAPER OFFICE',
            subCategory: 'BROWN PAPERS (WRAPPING PAPER, BAGS)'
        }
    ],
    [
        GreenProcurement.P3,
        {
            category: 'PAPER OFFICE',
            subCategory: 'CARBONLESS PAPER'
        }
    ],
    [
        GreenProcurement.P4,
        {
            category: 'PAPER OFFICE',
            subCategory: 'CARDS (INDEX,POSTAL,OTHER)'
        }
    ],
    [
        GreenProcurement.P5,
        {
            category: 'PAPER OFFICE',
            subCategory: 'CARRIERBOARD'
        }
    ],
    [
        GreenProcurement.P6,
        {
            category: 'PAPER OFFICE',
            subCategory: 'CHECK SAFETY PAPER'
        }
    ],
    [
        GreenProcurement.P7,
        {
            category: 'PAPER OFFICE',
            subCategory: 'COATED PRINTING PAPER'
        }
    ],
    [
        GreenProcurement.P8,
        {
            category: 'PAPER OFFICE',
            subCategory: 'COPIER PAPER AND BOND PAPER'
        }
    ],
    [
        GreenProcurement.P9,
        {
            category: 'PAPER OFFICE',
            subCategory: 'CORRUGATED CONTAINERS (<300PSI)'
        }
    ],
    [
        GreenProcurement.P10,
        {
            category: 'PAPER OFFICE',
            subCategory: 'CORRUGATED CONTAINERS (>300PSI)'
        }
    ],
    [
        GreenProcurement.P11,
        {
            category: 'PAPER OFFICE',
            subCategory: 'COTTON FIBER PAPER'
        }
    ],
    [
        GreenProcurement.P12,
        {
            category: 'PAPER OFFICE',
            subCategory: 'DYED FILING PRODUCTS'
        }
    ],
    [
        GreenProcurement.P13,
        {
            category: 'PAPER OFFICE',
            subCategory: 'ENVELOPE PAPER-KRAFT'
        }
    ],
    [
        GreenProcurement.P14,
        {
            category: 'PAPER OFFICE',
            subCategory: 'ENVELOPE PAPER-UNBLEACHED'
        }
    ],
    [
        GreenProcurement.P15,
        {
            category: 'PAPER OFFICE',
            subCategory: 'ENVELOPE PAPER-WHITE AND COLORED INCLUDING MANILLA'
        }
    ],
    [
        GreenProcurement.P16,
        {
            category: 'PAPER OFFICE',
            subCategory: 'FACIAL TISSUE'
        }
    ],
    [
        GreenProcurement.P17,
        {
            category: 'PAPER OFFICE',
            subCategory: 'FILE FOLDERS (MANILLA AND COLORED)'
        }
    ],
    [
        GreenProcurement.P18,
        {
            category: 'PAPER OFFICE',
            subCategory: 'FOLDING CARTONS'
        }
    ],
    [
        GreenProcurement.P19,
        {
            category: 'PAPER OFFICE',
            subCategory: 'FORMS(COMPUTER PRINTOUT, FORMS LEDGER)'
        }
    ],
    [
        GreenProcurement.P20,
        {
            category: 'PAPER OFFICE',
            subCategory: 'GENERAL PURPOSE INDUSTRIAL WIPERS'
        }
    ],
    [
        GreenProcurement.P21,
        {
            category: 'PAPER OFFICE',
            subCategory: 'INDUSTRIAL PAPERBOARD(TUBES,CORES,DRUMS,CANS)'
        }
    ],
    [
        GreenProcurement.P22,
        {
            category: 'PAPER OFFICE',
            subCategory: 'MACHINE FINISH GROUNDWOOD'
        }
    ],
    [
        GreenProcurement.P23,
        {
            category: 'PAPER OFFICE',
            subCategory: 'MISC(PAD BACKS,COVER BINDERS,BOOK COVERS,MAILTUBES,PROT PACK'
        }
    ],
    [
        GreenProcurement.P24,
        {
            category: 'PAPER OFFICE',
            subCategory: 'PAPER NAPKINS'
        }
    ],
    [
        GreenProcurement.P25,
        {
            category: 'PAPER OFFICE',
            subCategory: 'NEWSPRINT'
        }
    ],
    [
        GreenProcurement.P26,
        {
            category: 'PAPER OFFICE',
            subCategory: 'OFFSET PAPER'
        }
    ],
    [
        GreenProcurement.P27,
        {
            category: 'PAPER OFFICE',
            subCategory: 'PADDED MAILERS'
        }
    ],
    [
        GreenProcurement.P28,
        {
            category: 'PAPER OFFICE',
            subCategory: 'PAPETERIES'
        }
    ],
    [
        GreenProcurement.P29,
        {
            category: 'PAPER OFFICE',
            subCategory: 'PAPETERIES'
        }
    ],
    [
        GreenProcurement.P30,
        {
            category: 'PAPER OFFICE',
            subCategory: 'SOLID FIBER BOXES'
        }
    ],
    [
        GreenProcurement.P31,
        {
            category: 'PAPER OFFICE',
            subCategory: 'SUPERCALENDARED'
        }
    ],
    [
        GreenProcurement.P32,
        {
            category: 'PAPER OFFICE',
            subCategory: 'TABLET PAPER'
        }
    ],
    [
        GreenProcurement.P33,
        {
            category: 'PAPER OFFICE',
            subCategory: 'TAGS AND TICKETS'
        }
    ],
    [
        GreenProcurement.P34,
        {
            category: 'PAPER OFFICE',
            subCategory: 'TEXT AND COVER PAPER'
        }
    ],
    [
        GreenProcurement.P35,
        {
            category: 'PAPER OFFICE',
            subCategory: 'PAPER TOWELS'
        }
    ],
    [
        GreenProcurement.P36,
        {
            category: 'PAPER OFFICE',
            subCategory: 'TRAY LINERS'
        }
    ],
    [
        GreenProcurement.R1,
        {
            category: 'PARK/RECREATION',
            subCategory: 'PICNIC TABLES AND PARK BENCHES, ALUMINUM'
        }
    ],
    [
        GreenProcurement.R2,
        {
            category: 'PARK/RECREATION',
            subCategory: 'PICNIC TABLES AND PARK BENCHES, CONCRETE'
        }
    ],
    [
        GreenProcurement.R3,
        {
            category: 'PARK/RECREATION',
            subCategory: 'PICNIC TABLES AND PARK BENCHES, PLASTIC COMPOSITES'
        }
    ],
    [
        GreenProcurement.R4,
        {
            category: 'PARK/RECREATION',
            subCategory: 'PICNIC TABLES AND PARK BENCHES, PLASTICS'
        }
    ],
    [
        GreenProcurement.R5,
        {
            category: 'PARK/RECREATION',
            subCategory: 'PICNIC TABLES AND PARK BENCHES STEEL "(BOF METHOD)"'
        }
    ],
    [
        GreenProcurement.R6,
        {
            category: 'PARK/RECREATION',
            subCategory: 'PICNIC TABLES AND PARK BENCHES STEEL "(EAF METHOD)"'
        }
    ],
    [
        GreenProcurement.R7,
        {
            category: 'PARK/RECREATION',
            subCategory: 'PLASTIC FENCING'
        }
    ],
    [
        GreenProcurement.R8,
        {
            category: 'PARK/RECREATION',
            subCategory: 'PLAYGROUND SURFACES'
        }
    ],
    [
        GreenProcurement.R9,
        {
            category: 'PARK/RECREATION',
            subCategory: 'RUNNING TRACKS'
        }
    ],
    [
        GreenProcurement.T1,
        {
            category: 'TRANSPORTATION',
            subCategory: 'CHANNELIZERS, PLASTIC'
        }
    ],
    [
        GreenProcurement.T2,
        {
            category: 'TRANSPORTATION',
            subCategory: 'CHANNELIZERS, RUBBER(BASE ONLY)'
        }
    ],
    [
        GreenProcurement.T3,
        {
            category: 'TRANSPORTATION',
            subCategory: 'DELINERATORS,FLEXIBLE, PLASTIC'
        }
    ],
    [
        GreenProcurement.T4,
        {
            category: 'TRANSPORTATION',
            subCategory: 'DELINERATORS,PLASTIC'
        }
    ],
    [
        GreenProcurement.T5,
        {
            category: 'TRANSPORTATION',
            subCategory: 'DELINERATORS,RUBBER (BASE ONLY)'
        }
    ],
    [
        GreenProcurement.T6,
        {
            category: 'TRANSPORTATION',
            subCategory: 'DELINERATORS,STEEL(BOF METHOD)-BASE ONLY'
        }
    ],
    [
        GreenProcurement.T14,
        {
            category: 'TRANSPORTATION',
            subCategory: 'DELINERATORS,STEEL(EAF METHOD)-BASE ONLY'
        }
    ],
    [
        GreenProcurement.T7,
        {
            category: 'TRANSPORTATION',
            subCategory: 'PARKING STOPS, CONCRETE CONTAINING FLY ASH'
        }
    ],
    [
        GreenProcurement.T8,
        {
            category: 'TRANSPORTATION',
            subCategory: 'PARKING STOPS,CONCRETE CONT GROUND GRANULATED BLASTFURNACE'
        }
    ],
    [
        GreenProcurement.T9,
        {
            category: 'TRANSPORTATION',
            subCategory: 'PARKING STOPS,PLASTIC OR RUBBER'
        }
    ],
    [
        GreenProcurement.T10,
        {
            category: 'TRANSPORTATION',
            subCategory: 'TRAFFIC BARRICADES, PLASTIC(HDPE,LDPE,PET)'
        }
    ],
    [
        GreenProcurement.T11,
        {
            category: 'TRANSPORTATION',
            subCategory: 'TRAFFIC BARRICADES,FIBERGLASS'
        }
    ],
    [
        GreenProcurement.T12,
        {
            category: 'TRANSPORTATION',
            subCategory: 'TRAFFIC BARRICADES,STEEL (BOF METHOD)'
        }
    ],
    [
        GreenProcurement.T15,
        {
            category: 'TRANSPORTATION',
            subCategory: 'TRAFFIC BARRICADES,STEEL (EAF METHOD)'
        }
    ],
    [
        GreenProcurement.T13,
        {
            category: 'TRANSPORTATION',
            subCategory: 'TRAFFIC CONES(PVC,LDPE,CRUMB RUBBER))'
        }
    ],
    [
        GreenProcurement.V1,
        {
            category: 'VEHICULAR',
            subCategory: 'ENGINE COOLANTS (ANTIFREEZE)'
        }
    ],
    [
        GreenProcurement.V2,
        {
            category: 'VEHICULAR',
            subCategory: 'RE-REFINED MOTOR OIL'
        }
    ],
    [
        GreenProcurement.V3,
        {
            category: 'VEHICULAR',
            subCategory: 'RETREAD TIRES'
        }
    ],
    [
        GreenProcurement.C1,
        {
            category: 'CONSTRUCTION',
            subCategory: 'BUILDING INSULATION,CELLULOSE LOOSE-FILL OR SPRAY ON'
        }
    ],
    [
        GreenProcurement.C2,
        {
            category: 'CONSTRUCTION',
            subCategory: 'BUILDING INSULATION, FIBERGLASS'
        }
    ],
    [
        GreenProcurement.C4,
        {
            category: 'CONSTRUCTION',
            subCategory: 'BUILDING INSULATION, GLASS FIBER REINFORCED'
        }
    ],
    [
        GreenProcurement.C5,
        {
            category: 'CONSTRUCTION',
            subCategory: 'BUILDING INSULATION, PERLITE COMPOSITE BOARD'
        }
    ],
    [
        GreenProcurement.C6,
        {
            category: 'CONSTRUCTION',
            subCategory: 'BUILDING INSULATION, PHENOLIC RIGID FOAM'
        }
    ],
    [
        GreenProcurement.C7,
        {
            category: 'CONSTRUCTION',
            subCategory: 'BUILDING INSULATION, PLASTIC RIGID FOAM'
        }
    ],
    [
        GreenProcurement.C8,
        {
            category: 'CONSTRUCTION',
            subCategory: 'BUILDING INSULATION, PLASTIC, NON-WOVEN BATT'
        }
    ],
    [
        GreenProcurement.C9,
        {
            category: 'CONSTRUCTION',
            subCategory: 'BUILDING INSULATION, ROCK WOOL'
        }
    ],
    [
        GreenProcurement.C10,
        {
            category: 'CONSTRUCTION',
            subCategory: 'CARPET CUSHION, RUBBER (TIRE RUBBER)'
        }
    ],
    [
        GreenProcurement.C11,
        {
            category: 'CONSTRUCTION',
            subCategory: 'CARPET CUSHION SYNTHETIC " FIBERS (FABRICATION SCRAP)"'
        }
    ],
    [
        GreenProcurement.C12,
        {
            category: 'CONSTRUCTION',
            subCategory: 'CARPET-POLYESTER FACE FIBER'
        }
    ],
    [
        GreenProcurement.C13,
        {
            category: 'CONSTRUCTION',
            subCategory: 'CEMENT WITH FLY ASH OR GROUND GRANULATED BLAST FURNACE SLAG'
        }
    ],
    [
        GreenProcurement.C14,
        {
            category: 'CONSTRUCTION',
            subCategory: 'CONSOLIDATED LATEX PAINT'
        }
    ],
    [
        GreenProcurement.C15,
        {
            category: 'CONTSRUCTION',
            subCategory: 'FLOOR TILES, HEAVY DUTY COMMERCIAL, PLASTIC'
        }
    ],
    [
        GreenProcurement.C16,
        {
            category: 'CONSTRUCTION',
            subCategory: 'FLOOR TILES, HEAVY DUTY COMMERCIAL, PLASTIC'
        }
    ],
    [
        GreenProcurement.C17,
        {
            category: 'CONSTRUCTION',
            subCategory: 'LAMINATED PAPERBOARD'
        }
    ],
    [
        GreenProcurement.C18,
        {
            category: 'CONSTRUCTION',
            subCategory: 'PATIO BLOCKS, PLASTIC OR PLASTIC BLENDS'
        }
    ],
    [
        GreenProcurement.C19,
        {
            category: 'CONSTRUCTION',
            subCategory: 'PATIO BLOCKS, RUBBER OR RUBBER BLENDS'
        }
    ],
    [
        GreenProcurement.C20,
        {
            category: 'CONSTRUCTION',
            subCategory: 'RAILROAD GRADE CROSSING SURFACES, CONCRETE COAL'
        }
    ],
    [
        GreenProcurement.C21,
        {
            category: 'CONSTRUCTION',
            subCategory: 'RAILROAD GRADE CROSSING SURFACES, RUBBER'
        }
    ],
    [
        GreenProcurement.C22,
        {
            category: 'CONSTRUCTION',
            subCategory: 'RAILROAD GRADE CROSSING SURFACES STEEL"(BOF METHOD)"'
        }
    ],
    [
        GreenProcurement.C23,
        {
            category: 'CONSTRUCTION',
            subCategory: 'RAILROAD GRADE CROSSING SURFACES STEEL "(EAF METHOD)"'
        }
    ],
    [
        GreenProcurement.C24,
        {
            category: 'CONSTRUCTION',
            subCategory: 'REPROCESSED LATEX PAINT,GREY, BROWN, EARTHTONES, DARK COLORS'
        }
    ],
    [
        GreenProcurement.C25,
        {
            category: 'CONSTRUCTION',
            subCategory: 'REPROCESSED LATEX PAINT, WHITE, OFF-WHITE, PASTEL COLORS'
        }
    ],
    [
        GreenProcurement.C26,
        {
            category: 'CONSTRUCTION',
            subCategory: 'SHOWER AND RESTROOM DIVIDERS, PLASTIC'
        }
    ],
    [
        GreenProcurement.C27,
        {
            category: 'CONSTRUCTION',
            subCategory: 'SHOWER AND RESTROOM DIVIDERS STEEL "(BOF METHOD)"'
        }
    ],
    [
        GreenProcurement.C28,
        {
            category: 'CONSTRUCTION',
            subCategory: 'SHOWER AND RESTROOM DIVIDERS STEEL "(EAF METHOD)"'
        }
    ],
    [
        GreenProcurement.C29,
        {
            category: 'CONSTRUCTION',
            subCategory: 'STRUCTURAL FIBERBOARD'
        }
    ],
    [
        GreenProcurement.L1,
        {
            category: 'LANDSCAPING',
            subCategory: 'COMPOST (MADE FROM YARD TRIMMINGS AND/OR FOOD WASTE)'
        }
    ],
    [
        GreenProcurement.L2,
        {
            category: 'LANDSCAPING',
            subCategory: 'HOSES, GARDEN - RUBBER AND/OR PLASTIC'
        }
    ],
    [
        GreenProcurement.L3,
        {
            category: 'LANDSCAPING',
            subCategory: 'HYDRAULIC MULCH, PAPER BASED'
        }
    ],
    [
        GreenProcurement.L4,
        {
            category: 'LANDSCAPING',
            subCategory: 'HYDRAULIC MULCH, WOOD BASED'
        }
    ],
    [
        GreenProcurement.L5,
        {
            category: 'LANDSCAPING',
            subCategory: 'LAWN AND GARDEN EDGING (PLASIC AND/OR RUBBER)'
        }
    ],
    [
        GreenProcurement.L6,
        {
            category: 'LANDSCAPING',
            subCategory: 'TIMBER AND POSTS, HDPE'
        }
    ],
    [
        GreenProcurement.L7,
        {
            category: 'LANDSCAPING',
            subCategory: 'TIMBER AND POSTS, HDPE/FIBERGLASS'
        }
    ],
    [
        GreenProcurement.L8,
        {
            category: 'LANDSCAPING',
            subCategory: 'TIMBER AND POSTS, MIXED PLASTIC/SAWDUST'
        }
    ],
    [
        GreenProcurement.L9,
        {
            category: 'LANDSCAPING',
            subCategory: 'TIMBER AND POSTS, OTHER MIXED RESINS'
        }
    ],
    [
        GreenProcurement.M1,
        {
            category: 'MISCELLANEOUS',
            subCategory: 'AWARDS AND PLAQUES, GLASS'
        }
    ],
    [
        GreenProcurement.M2,
        {
            category: 'MISCELLANEOUS',
            subCategory: 'AWARDS AND PLAQUES, PAPER'
        }
    ],
    [
        GreenProcurement.M3,
        {
            category: 'MISCELLANEOUS',
            subCategory: 'AWARDS AND PLAQUES, PLASTIC AND PLASTIC/WOOD COMPOSITE'
        }
    ],
    [
        GreenProcurement.M4,
        {
            category: 'MISCELLANEOUS',
            subCategory: 'AWARDS AND PLAQUES, WOOD'
        }
    ],
    [
        GreenProcurement.M5,
        {
            category: 'MISCELLANEOUS',
            subCategory: 'DRUMS, FIBER'
        }
    ],
    [
        GreenProcurement.M6,
        {
            category: 'MISCELLANEOUS',
            subCategory: 'DRUMS, PLASTIC-HDPE'
        }
    ],
    [
        GreenProcurement.M7,
        {
            category: 'MISCELLANEOUS',
            subCategory: 'DRUMS STEEL "(BOF METHOD)"'
        }
    ],
    [
        GreenProcurement.M8,
        {
            category: 'MISCELLANEOUS',
            subCategory: 'MATS, PLASTIC'
        }
    ],
    [
        GreenProcurement.M9,
        {
            category: 'MISCELLANEOUS',
            subCategory: 'MATS, RUBBER'
        }
    ],
    [
        GreenProcurement.M10,
        {
            category: 'MISCELLANEOUS',
            subCategory: 'MATS, RUBBER/PLASTIC COMPOSITE'
        }
    ],
    [
        GreenProcurement.M11,
        {
            category: 'MISCELLANEOUS',
            subCategory: 'PALLETS, PAPERBOARD'
        }
    ],
    [
        GreenProcurement.M12,
        {
            category: 'MISCELLANEOUS',
            subCategory: 'PALLETS, PLASTIC LUMBER'
        }
    ],
    [
        GreenProcurement.M13,
        {
            category: 'MISCELLANEOUS',
            subCategory: 'PALLETS, THERMOFORMED'
        }
    ],
    [
        GreenProcurement.M14,
        {
            category: 'MISCELLANEOUS',
            subCategory: 'PALLETS, WOOD'
        }
    ],
    [
        GreenProcurement.M15,
        {
            category: 'MISCELLANEOUS',
            subCategory: 'SIGN SUPPORTS, PLASTIC'
        }
    ],
    [
        GreenProcurement.M16,
        {
            category: 'MISCELLANEOUS',
            subCategory: 'SIGN SUPPORTS STEEL "(BOF METHOD)"'
        }
    ],
    [
        GreenProcurement.M17,
        {
            category: 'MISCELLANEOUS',
            subCategory: 'SIGN SUPPORTS STEEL "(EAF METHOD)"'
        }
    ],
    [
        GreenProcurement.M18,
        {
            category: 'MISCELLANEOUS',
            subCategory: 'SIGNS, PLASTIC'
        }
    ],
    [
        GreenProcurement.M19,
        {
            category: 'MISCELLANEOUS',
            subCategory: 'SORBENTS FOR OIL/SOLVENTS CLEANUPS, ORGANICS/MULTIMATERIALS'
        }
    ],
    [
        GreenProcurement.M20,
        {
            category: 'MISCELLANEOUS',
            subCategory: 'SORBENTS FOR OIL/SOLVENTS CLEANUPS, PAPER'
        }
    ],
    [
        GreenProcurement.M21,
        {
            category: 'MISCELLANEOUS',
            subCategory: 'SORBENTS FOR OIL/SOLVENTS CLEANUPS, PLASTICS'
        }
    ],
    [
        GreenProcurement.M22,
        {
            category: 'MISCELLANEOUS',
            subCategory: 'SORBENTS FOR OIL/SOLVENTS CLEANUPS, TEXTILES'
        }
    ],
    [
        GreenProcurement.M23,
        {
            category: 'MISCELLANEOUS',
            subCategory: 'SORBENTS FOR OIL/SOLVENTS CLEANUPS, WOOD'
        }
    ],
    [
        GreenProcurement.M24,
        {
            category: 'MISCELLANEOUS',
            subCategory: 'STRAPPING, PET PLASTIC'
        }
    ],
    [
        GreenProcurement.M25,
        {
            category: 'MISCELLANEOUS',
            subCategory: 'STRAPPING, POLYPROPYLENE PLASTIC'
        }
    ],
    [
        GreenProcurement.M26,
        {
            category: 'MISCELLANEOUS',
            subCategory: 'STRAPPING STEEL"(BOF METHOD)"'
        }
    ],
    [
        GreenProcurement.N1,
        {
            category: 'MISCELLANEOUS',
            subCategory: 'STRAPPING STEEL "(EAF METHOD)"'
        }
    ],
    [
        GreenProcurement.M27,
        {
            category: 'NON-PAPER OFFICE',
            subCategory: 'OFFICE RECYC CONTAINERS/WASTE RECEPTACLES-PAPER CORRUGATED'
        }
    ]
]);
