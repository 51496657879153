var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('Alert', {
    staticClass: "header-alert",
    class: {
      nav_open: _vm.navOpenState
    },
    attrs: {
      "dismissible": _vm.dismissible,
      "type": _vm.type
    },
    on: {
      "input": _vm.emitInput
    }
  }, [_vm._t("alert-content")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };