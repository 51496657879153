import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { markdownToHtml } from '~/nasa_ui/utils/helpers/markdownToHtml';
let AppMarkdownDisplay = class AppMarkdownDisplay extends Vue {
    markdown;
    noMarkdownText;
    maxHeight;
    width;
    get htmlFromMarkdown() {
        return markdownToHtml(this.markdown);
    }
    get style() {
        return `maxHeight: ${this.maxHeight}; overflow-y: auto; width: ${this.width};`;
    }
};
__decorate([
    Prop(),
    __metadata("design:type", String)
], AppMarkdownDisplay.prototype, "markdown", void 0);
__decorate([
    Prop(),
    __metadata("design:type", String)
], AppMarkdownDisplay.prototype, "noMarkdownText", void 0);
__decorate([
    Prop({
        type: String,
        validator: (value) => value.endsWith('px') || value === 'auto',
        default: () => '350px'
    }),
    __metadata("design:type", String)
], AppMarkdownDisplay.prototype, "maxHeight", void 0);
__decorate([
    Prop({
        type: String,
        default: '100%'
    }),
    __metadata("design:type", String)
], AppMarkdownDisplay.prototype, "width", void 0);
AppMarkdownDisplay = __decorate([
    Component
], AppMarkdownDisplay);
export default AppMarkdownDisplay;
