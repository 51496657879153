/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./AppSelectSerial.vue?vue&type=template&id=bc3d88fe&scoped=true"
import script from "./AppSelectSerial.ts?vue&type=script&lang=ts&external"
export * from "./AppSelectSerial.ts?vue&type=script&lang=ts&external"
import style0 from "./AppSelectSerial.vue?vue&type=style&index=0&id=bc3d88fe&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc3d88fe",
  null
  
)

export default component.exports