import { __decorate } from "tslib";
import { Component, Mixins, Vue } from 'vue-property-decorator';
import { AlertType } from '~/nasa_ui/types';
let BaseVue = class BaseVue extends Mixins(Vue) {
    AlertType = AlertType;
    hasSlot(name = 'default') {
        return !!this.$slots[name] || !!this.$scopedSlots[name];
    }
};
BaseVue = __decorate([
    Component
], BaseVue);
export default BaseVue;
