import { UserGroupType, UserRole } from '~/db_types';
export const UserRoleDisplay = new Map([
    [UserRole.ADMIN, 'Admin'],
    [UserRole.DISABLED, 'Disabled'],
    [UserRole.SYSTEM, 'System'],
    [UserRole.USER, 'User']
]);
export var UserPermission;
(function (UserPermission) {
    UserPermission["ACTIVITY_ADMIN"] = "ACTIVITY_ADMIN";
    UserPermission["ANNOUNCEMENT_ADMIN"] = "ANNOUNCEMENT_ADMIN";
    UserPermission["APPROVAL_ROUTING_TEMPLATE_ADMIN"] = "APPROVAL_ROUTING_TEMPLATE_ADMIN";
    UserPermission["ASSEMBLY_ADMIN"] = "ASSEMBLY_ADMIN";
    UserPermission["ASSEMBLY_TEMPLATE_ADMIN"] = "ASSEMBLY_TEMPLATE_ADMIN";
    UserPermission["C_DOCS_DR_ADMIN"] = "C_DOCS_DR_ADMIN";
    UserPermission["C_DOCS_EZT_ADMIN"] = "C_DOCS_EZT_ADMIN";
    UserPermission["C_DOCS_FRACAS_ADMIN"] = "C_DOCS_FRACAS_ADMIN";
    UserPermission["C_DOCS_GCAR_ADMIN"] = "C_DOCS_GCAR_ADMIN";
    UserPermission["C_DOCS_RCA_ADMIN"] = "C_DOCS_RCA_ADMIN";
    UserPermission["C_DOCS_RCA_SUBMITTER"] = "C_DOCS_RCA_SUBMITTER";
    UserPermission["C_DOCS_TPS_ADMIN"] = "C_DOCS_TPS_ADMIN";
    UserPermission["C_DOCS_WORKFLOWS_ADMIN"] = "C_DOCS_WORKFLOWS_ADMIN";
    UserPermission["C_DOCS_WORKFLOWS_SUBMITTER"] = "C_DOCS_WORKFLOWS_SUBMITTER";
    UserPermission["CHANGE_SET_DELETE"] = "CHANGE_SET_DELETE";
    UserPermission["CHANGE_SET_TEMPLATE_ADMIN"] = "CHANGE_SET_TEMPLATE_ADMIN";
    UserPermission["CRITICAL_INVENTORY_TRACKING_ADMIN"] = "CRITICAL_INVENTORY_TRACKING_ADMIN";
    UserPermission["CONTACT_ADMIN"] = "CONTACT_ADMIN";
    UserPermission["CONTRACT_ADMIN"] = "CONTRACT_ADMIN";
    UserPermission["DATA_REMEDIATION_ADMIN"] = "DATA_REMEDIATION_ADMIN";
    UserPermission["DELIVERY_MATRIX_ITEM_ADMIN"] = "DELIVERY_MATRIX_ITEM_ADMIN";
    UserPermission["DOCUMENT_ADMIN"] = "DOCUMENT_ADMIN";
    UserPermission["EVENT_ADMIN"] = "EVENT_ADMIN";
    UserPermission["HARDWARE_LIST_ADMIN"] = "HARDWARE_LIST_ADMIN";
    UserPermission["HARDWARE_LIST_TEMPLATE_ADMIN"] = "HARDWARE_LIST_TEMPLATE_ADMIN";
    UserPermission["ITEM_DRAWING_ADMIN"] = "ITEM_DRAWING_ADMIN";
    UserPermission["ITEM_DRAWING_ALIAS"] = "ITEM_DRAWING_ALIAS";
    UserPermission["ITEM_DRAWING_CODES"] = "ITEM_DRAWING_CODES";
    UserPermission["ITEM_DRAWING_VALID_SIZES"] = "ITEM_DRAWING_VALID_SIZES";
    UserPermission["ITEM_INSTANCE_ADMIN"] = "ITEM_INSTANCE_ADMIN";
    UserPermission["ITEM_INSTANCE_DATES"] = "ITEM_INSTANCE_DATES";
    UserPermission["ITEM_INSTANCE_LIMITED_LIFE"] = "ITEM_INSTANCE_LIMITED_LIFE";
    UserPermission["ITEM_MASTER_ADMIN"] = "ITEM_MASTER_ADMIN";
    UserPermission["ITEM_MASTER_ALTERNATES"] = "ITEM_MASTER_ALTERNATES";
    UserPermission["ITEM_MASTER_GROUND_SUPPORT_EQUIPMENT"] = "ITEM_MASTER_GROUND_SUPPORT_EQUIPMENT";
    UserPermission["ITEM_MASTER_INVENTORY_QUOTAS_ADMIN"] = "ITEM_MASTER_INVENTORY_QUOTAS_ADMIN";
    UserPermission["ITEM_MASTER_LIMITED_LIFE"] = "ITEM_MASTER_LIMITED_LIFE";
    UserPermission["LOGISTICS_ADMIN"] = "LOGISTICS_ADMIN";
    UserPermission["LOCATION_ADMIN"] = "LOCATION_ADMIN";
    UserPermission["MERGE_ITEM_INSTANCES"] = "MERGE_ITEM_INSTANCES";
    UserPermission["ON_ORBIT_FAILURE_ADMIN"] = "ON_ORBIT_FAILURE_ADMIN";
    UserPermission["ORGANIZATION_ADMIN"] = "ORGANIZATION_ADMIN";
    UserPermission["PRODUCT_BREAKDOWN_STRUCTURE_ADMIN"] = "PRODUCT_BREAKDOWN_STRUCTURE_ADMIN";
    UserPermission["PHYSICAL_INVENTORY_AUDIT_ADMIN"] = "PHYSICAL_INVENTORY_AUDIT_ADMIN";
    UserPermission["RISK_ADMIN"] = "RISK_ADMIN";
    UserPermission["RISK_USER"] = "RISK_USER";
    UserPermission["USER_ADMIN"] = "USER_ADMIN";
    UserPermission["USER_GROUPS_ADMIN"] = "USER_GROUPS_ADMIN";
    UserPermission["USER_PERMISSIONS"] = "USER_PERMISSIONS";
    UserPermission["USER_SUPPORT_CONTEXT"] = "USER_SUPPORT_CONTEXT";
})(UserPermission || (UserPermission = {}));
export const UserPermissionBlurb = new Map([
    [UserPermission.ACTIVITY_ADMIN, 'Grants the superpower of creating/deleting Activities.'],
    [UserPermission.ANNOUNCEMENT_ADMIN, 'Allows the creation and deletion of Announcements.'],
    [UserPermission.APPROVAL_ROUTING_TEMPLATE_ADMIN, 'Allows the User to create/update Approval Routing Templates.'],
    [UserPermission.ASSEMBLY_ADMIN, 'Grants the boon of creating/managing an Assembly.'],
    [UserPermission.ASSEMBLY_TEMPLATE_ADMIN, 'Same as ASSEMBLY_ADMIN but with Assembly Templates.'],
    [UserPermission.C_DOCS_DR_ADMIN, 'Allows the creation and deletion of a cDR'],
    [UserPermission.C_DOCS_EZT_ADMIN, 'Allows the creation and deletion of a cEZT'],
    [UserPermission.C_DOCS_FRACAS_ADMIN, 'Allows the creation and deletion of a cFRACAS'],
    [UserPermission.C_DOCS_GCAR_ADMIN, 'Allows the creation and deletion of a cGCAR'],
    [UserPermission.C_DOCS_RCA_ADMIN, 'Allows the creation and deletion of a cRCA'],
    [UserPermission.C_DOCS_RCA_SUBMITTER, 'Allows the creation of a cRCA'],
    [UserPermission.C_DOCS_TPS_ADMIN, 'Allows the creation and deletion of a cTPS'],
    [UserPermission.C_DOCS_WORKFLOWS_ADMIN, 'Allows the creation and deletion of a cWorkflow'],
    [UserPermission.C_DOCS_WORKFLOWS_SUBMITTER, 'Allows the creation of a cWorkflow'],
    [UserPermission.CHANGE_SET_DELETE, 'A User with this permission can fully DELETE a Change Set.'],
    [
        UserPermission.CHANGE_SET_TEMPLATE_ADMIN,
        'Allows the User to create/update Change Set templates, sometimes referred to as "Approval routing".'
    ],
    [UserPermission.CONTACT_ADMIN, 'You guessed it, allows the User to create/manage Contacts.'],
    [UserPermission.CONTRACT_ADMIN, 'Gives the User the ability to create/manage Contracts.'],
    [
        UserPermission.DATA_REMEDIATION_ADMIN,
        'Gives the User access to a super secret area where they can, for example, merge Item Instances.'
    ],
    [UserPermission.CRITICAL_INVENTORY_TRACKING_ADMIN, 'Alpha build of a new logistics based tracking tool.'],
    [UserPermission.DOCUMENT_ADMIN, 'Allows the User general access to create "Tracking Documents".'],
    [UserPermission.EVENT_ADMIN, 'Allows the User to create/manage Events.'],
    [UserPermission.HARDWARE_LIST_ADMIN, 'Allows the User to create/manage Hardware Lists.'],
    [UserPermission.HARDWARE_LIST_TEMPLATE_ADMIN, 'Allows the User to create/manage Hardware List templates.'],
    [UserPermission.ITEM_DRAWING_ADMIN, 'Allows the User to create/manage/attach links to an Item Drawing.'],
    [UserPermission.ITEM_DRAWING_ALIAS, 'Allows the User to assign/update aliases for the selected Item Drawing.'],
    [
        UserPermission.ITEM_DRAWING_CODES,
        'Grants the User the superpower of attach code pairings to the selected Item Drawing.'
    ],
    [UserPermission.ITEM_DRAWING_VALID_SIZES, 'Gives the User the ability to edit Item Drawing sizes.'],
    [
        UserPermission.ITEM_INSTANCE_ADMIN,
        'Allows the User to receive hardware (initially or otherwise), archive, attach links to Item Instances.'
    ],
    [
        UserPermission.ITEM_INSTANCE_DATES,
        'Grants the User the ability to edit the "Date" section of the Item Instance manage page.'
    ],
    [UserPermission.ITEM_INSTANCE_LIMITED_LIFE, 'Allows the User to edit Limited Life at the Item Instance level.'],
    [
        UserPermission.ITEM_MASTER_ADMIN,
        'Allows the User to create/update/add responsible organizations/archive/unarchive/attach links to an Item Master.'
    ],
    [
        UserPermission.ITEM_MASTER_ALTERNATES,
        'Allows the User to update the "alternate type" on a particular Item Master.'
    ],
    [
        UserPermission.ITEM_MASTER_GROUND_SUPPORT_EQUIPMENT,
        'Allows the User to update the GSE section of an Item Master manage page.'
    ],
    [
        UserPermission.ITEM_MASTER_INVENTORY_QUOTAS_ADMIN,
        'Allows this User to set up Inventory thresholds (quotas) at the Item Master level.'
    ],
    [
        UserPermission.ITEM_MASTER_LIMITED_LIFE,
        'Allows this User to update Limited Life properties at the Item Master level.'
    ],
    [UserPermission.LOCATION_ADMIN, 'Allows the User to create/manage/attach Locations.'],
    [UserPermission.LOGISTICS_ADMIN, 'Allows the User to access logistics modules.'],
    [UserPermission.MERGE_ITEM_INSTANCES, 'Allows the User to merge two item instances into one.'],
    [UserPermission.ON_ORBIT_FAILURE_ADMIN, 'Allows the User to create/manage an On-Orbit Failure.'],
    [UserPermission.ORGANIZATION_ADMIN, 'Allows the User to create/manage Organizations.'],
    [UserPermission.PRODUCT_BREAKDOWN_STRUCTURE_ADMIN, 'Grants the ability to create/manage a PBS.'],
    [UserPermission.PHYSICAL_INVENTORY_AUDIT_ADMIN, 'This power up gives the User the ability to create/manage a PIA.'],
    [UserPermission.RISK_ADMIN, 'Allows the User to create/manage COSMIC Risks.'],
    [UserPermission.RISK_USER, 'Look but do not touch COSMIC Risks.'],
    [UserPermission.USER_ADMIN, 'Allows the User to create other Users.'],
    [UserPermission.USER_GROUPS_ADMIN, 'Allows the User to create/manage User Groups for the current context.'],
    [
        UserPermission.USER_PERMISSIONS,
        "Gives the User the boon of setting another User's permissions for the current context."
    ],
    [UserPermission.USER_SUPPORT_CONTEXT, "Gives the User the ability to set another User's support contexts."]
]);
export const UserGroupTypeDisplay = new Map([
    [UserGroupType.SYSTEM, 'System'],
    [UserGroupType.USER_DELEGATE, 'User delegate'],
    [UserGroupType.USER_MANAGED, 'User managed']
]);
