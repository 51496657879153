import { get } from 'lodash';
export const sortObjectNumericallyBy = (sortBy, desc = false) => {
    return (a, b) => {
        try {
            let sortByA = get(a, sortBy);
            let sortByB = get(b, sortBy);
            const order = desc ? 1 : -1;
            // convert into number by getting the first matched digits
            if (typeof sortByA === 'string') {
                const matchA = sortByA.match(/\d+/);
                if (matchA && matchA.index === 0) {
                    sortByA = parseFloat(matchA[0]);
                }
            }
            if (typeof sortByB === 'string') {
                const matchB = sortByB.match(/\d+/);
                if (matchB && matchB.index === 0) {
                    sortByB = parseFloat(matchB[0]);
                }
            }
            if (sortByA === sortByB) {
                return 0;
            }
            // if comparing a number to a string, sort number first
            if (typeof sortByA === 'number' && typeof sortByB === 'string') {
                return desc ? 1 : -1;
            }
            else if (typeof sortByA === 'string' && typeof sortByB === 'number') {
                return desc ? -1 : 1;
            }
            return sortByA > sortByB ? -1 * order : 1 * order;
        }
        catch (err) {
            return 0;
        }
    };
};
