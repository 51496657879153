import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Prop } from 'vue-property-decorator';
import BaseMini from '~/nasa_ui/base/BaseMini';
import { CosmicChangeSetStatusFragment } from '~/nasa_ui/DAL/changeSet';
import { CosmicItemInstanceSlimFragment } from '~/nasa_ui/DAL/itemInstance/slim';
import { CosmicUserFragment } from '~/nasa_ui/DAL/user';
import { EntityType } from '~/nasa_ui/types';
import { transformChangeSets } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let MiniChangeSet = class MiniChangeSet extends BaseMini {
    entityType = EntityType.CHANGE_SET;
    localEntity = null;
    changeSetAuthorityForMiniFrag = gql `
    fragment ChangeSetAuthorityForMini on ChangeSetAuthority {
      authorizedDate
      computedCommentText
      createdDateTime
      createdByUser {
        ...CosmicUser
      }
      nodeId
      projectedDate
      qualityControlStamp
      status
      subType
      template {
        createdByUserId
        createdDateTime
        documentType
        groupCode
        isActive
        itemInstanceInventoryType
        itemMasterType
        nodeId
        subType
        userGroup {
          ...CosmicUserGroup
        }
      }

      userGroup {
        ...CosmicUserGroup
      }
    }
  `;
    BASE_FETCH = this.id
        ? gql `
        ${CosmicUserFragment}
        ${CosmicChangeSetStatusFragment}
        ${CosmicItemInstanceSlimFragment}

        ${this.changeSetAuthorityForMiniFrag}

        fragment ChangeSetFullForMini on ChangeSet {
          asBuiltNumber
          drawingNumber
          computedStatus
          id
          nodeId
          payload
          projectedDate
          subType

          itemDrawing {
            nodeId
            description
            drawingNumber
          }
          itemMaster {
            asBuiltNumber
            drawingNumber
            nodeId
            itemDrawingDescription
          }
          itemInstance {
            ...CosmicItemInstanceSlim
          }
          status {
            ...CosmicChangeSetStatus
          }
          changeSetAuthorities {
            nodes {
              ...ChangeSetAuthorityForMini
            }
          }
        }

        query ChangeSetFullForMini($id: Int!) {
          changeSetById(id: $id) {
            ...ChangeSetFullForMini
          }
        }
      `
        : gql `
        ${CosmicUserFragment}
        ${CosmicChangeSetStatusFragment}
        ${CosmicItemInstanceSlimFragment}

        ${this.changeSetAuthorityForMiniFrag}

        fragment ChangeSetFullForMini on ChangeSet {
          asBuiltNumber
          drawingNumber
          computedStatus
          id
          nodeId
          payload
          projectedDate
          subType

          itemDrawing {
            nodeId
            description
            drawingNumber
          }
          itemMaster {
            asBuiltNumber
            drawingNumber
            nodeId
            itemDrawingDescription
          }
          itemInstance {
            ...CosmicItemInstanceSlim
          }
          status {
            ...CosmicChangeSetStatus
          }
          changeSetAuthorities {
            nodes {
              ...ChangeSetAuthorityForMini
            }
          }
        }

        query ChangeSetByIdFullForMini($id: Int!) {
          changeSetById(id: $id) {
            ...ChangeSetFullForMini
          }
        }
      `;
    showChangeSetAuthorityForm;
    get changeSetAuthorities() {
        return (this.localEntity?.changeSetAuthorities?.nodes || []);
    }
    get computedStatusIcon() {
        return this.transformedChangeSet?._status.replace('approved', '').replace('rejected', '');
    }
    get manageLink() {
        return this.localEntity ? `/${EntityType.CHANGE_SET}/manage/${this.localEntity.id}` : '';
    }
    get name() {
        return this.$typeDisplay(EntityType.CHANGE_SET, this.subType);
    }
    get transformedChangeSet() {
        return this.localEntity ? transformChangeSets([this.localEntity])[0] : null;
    }
    get verticalLabelDisplayItems() {
        if (!this.localEntity) {
            return [];
        }
        const items = [];
        const openedTitle = `
    Opened By: ${this.$contactDisplay(this.localEntity.status?.openedByUser)} 
    Opened On: ${this.$dateTimeDisplay(this.localEntity.status?.openedDate)}
  `;
        items.push({
            name: 'Opened On',
            title: openedTitle,
            value: `<span class="no-wrap" title="${openedTitle}">${this.$dateDisplay(this.localEntity.status?.openedDate)}</span>`
        });
        if (this.localEntity.status?.closedDate) {
            const closedTitle = `
        Closed By: ${this.$contactDisplay(this.localEntity.status?.closedByUser)} 
        Closed On: ${this.$dateTimeDisplay(this.localEntity.status?.closedDate)}
      `;
            items.push({
                name: 'Closed On',
                title: closedTitle || '',
                value: `<span class="no-wrap" title="${closedTitle}">${this.$dateDisplay(this.localEntity.status?.closedDate)}</span>`
            });
        }
        if (this.localEntity.projectedDate) {
            items.push({
                name: 'Proj. Close',
                value: `<span class="no-wrap">${this.$dateDisplay(this.localEntity.projectedDate)}</span>`
            });
        }
        return items;
    }
    onFetchUpdate(data) {
        this.$set(this, 'localEntity', data.changeSet || data.changeSetById);
    }
};
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Boolean)
], MiniChangeSet.prototype, "showChangeSetAuthorityForm", void 0);
MiniChangeSet = __decorate([
    Component
], MiniChangeSet);
export default MiniChangeSet;
