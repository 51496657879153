import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins } from 'vue-property-decorator';
import LoaderMixin from '~/nasa_ui/mixins/LoaderMixin';
import { mutationFactory, queryFactory } from '../base/mixinFactories';
import { CosmicContactSlimFragment } from '../contact/slim';
import { CosmicDocumentSlimFragment } from '../document/slim';
import { CosmicItemDrawingSlimFragment } from '../itemDrawing/slim';
import { CosmicItemInstanceSlimFragment } from '../itemInstance/slim';
import { CosmicItemMasterSlimFragment } from '../itemMaster/slim';
import { CosmicUserGroupSlimFragment } from '../user';
import { CosmicUserSlimFragment } from '../user/slim';
import { CosmicChangeSetAuthoritySlimFragment, CosmicChangeSetSlimFragment, CosmicChangeSetStatusSlimFragment } from './slim';
export const CosmicChangeSetAuthorityFragment = gql `
  ${CosmicChangeSetAuthoritySlimFragment}
  ${CosmicContactSlimFragment}
  ${CosmicUserSlimFragment}
  ${CosmicUserGroupSlimFragment}

  fragment CosmicChangeSetAuthority on ChangeSetAuthority {
    ...CosmicChangeSetAuthoritySlim

    authorizedByUser {
      ...CosmicUserSlim
    }

    userGroup {
      ...CosmicUserGroupSlim

      delegatesForUser {
        ...CosmicUserSlim

        contact {
          ...CosmicContactSlim
        }
      }

      usersToUserGroups {
        nodes {
          user {
            ...CosmicUserSlim

            contact {
              ...CosmicContactSlim
            }
          }
        }
      }
    }
  }
`;
export const CosmicChangeSetStatusFragment = gql `
  ${CosmicUserSlimFragment}
  ${CosmicChangeSetStatusSlimFragment}
  ${CosmicChangeSetAuthoritySlimFragment}

  fragment CosmicChangeSetStatus on ChangeSetStatusInfo {
    ...CosmicChangeSetStatusSlim

    closedByUser {
      ...CosmicUserSlim
    }

    lastAuthority {
      ...CosmicChangeSetAuthoritySlim
    }

    nextAuthority {
      ...CosmicChangeSetAuthoritySlim
    }

    openedByUser {
      ...CosmicUserSlim
    }
  }
`;
export const CosmicChangeSetFragment = gql `
  ${CosmicChangeSetAuthorityFragment}
  ${CosmicChangeSetSlimFragment}
  ${CosmicChangeSetStatusSlimFragment}
  ${CosmicDocumentSlimFragment}
  ${CosmicItemDrawingSlimFragment}
  ${CosmicItemInstanceSlimFragment}
  ${CosmicItemMasterSlimFragment}
  ${CosmicUserSlimFragment}

  fragment CosmicChangeSet on ChangeSet {
    ...CosmicChangeSetSlim

    createdByUser {
      ...CosmicUserSlim
    }

    document {
      ...CosmicDocumentSlim
    }

    itemDrawing {
      ...CosmicItemDrawingSlim
    }

    itemMaster {
      ...CosmicItemMasterSlim
    }

    itemInstance {
      ...CosmicItemInstanceSlim
    }

    status {
      ...CosmicChangeSetStatusSlim
    }

    changeSetAuthorities {
      nodes {
        ...CosmicChangeSetAuthority
      }
    }
  }
`;
export const ReactivateChangeSetTemplateById = gql `
  mutation ReactivateChangeSetTemplateById($changeSetTemplateId: Int!) {
    updateChangeSetTemplateById(input: { id: $changeSetTemplateId, patch: { isActive: true } }) {
      changeSetTemplate {
        id
      }
    }
  }
`;
export const DeactivateChangeSetTemplateById = gql `
  mutation DeactivateChangeSetTemplateById($changeSetTemplateId: Int!) {
    updateChangeSetTemplateById(input: { id: $changeSetTemplateId, patch: { isActive: false } }) {
      changeSetTemplate {
        id
      }
    }
  }
`;
export const ReopenChangeSets = gql `
  mutation ReopenChangeSets($changeSetIds: [Int!], $authoritiesOverride: [ChangeSetAuthorityOverrideInput]) {
    reopenChangeSets(
      input: { changeSetIds: $changeSetIds, reopenApproved: true, authoritiesOverride: $authoritiesOverride }
    ) {
      changeSets {
        id
        nodeId
      }
    }
  }
`;
export const ChangeSetById = gql `
  ${CosmicChangeSetFragment}

  query ChangeSetById($id: Int!) {
    changeSetById(id: $id) {
      id
      nodeId
      ...CosmicChangeSet
    }
  }
`;
export const CreateChangeSet = gql `
  mutation CreateChangeSet($input: CreateChangeSetInput!) {
    createChangeSet(input: $input) {
      changeSet {
        id
        nodeId
        status {
          nextAuthorityId
        }
      }
    }
  }
`;
export const UpdateChangeSet = gql `
  mutation UpdateChangeSet($id: Int!, $patch: ChangeSetPatch!) {
    updateChangeSetById(input: { id: $id, patch: $patch }) {
      clientMutationId
    }
  }
`;
export const UpdateChangeSetById = gql `
  mutation UpdateChangeSetById($changeSetId: Int!, $patch: ChangeSetPatch!) {
    updateChangeSetById(input: { id: $changeSetId, patch: $patch }) {
      clientMutationId
    }
  }
`;
export const UpdateChangeSetAuthority = gql `
  mutation UpdateChangeSetAuthority($input: UpdateChangeSetAuthorityInput!) {
    updateChangeSetAuthority(input: $input) {
      changeSetAuthority {
        id
        nodeId
      }
    }
  }
`;
export const ApproveChangeSetAuthority = gql `
  mutation ApproveChangeSetAuthority($changeSetAuthorityId: Int!, $authorizedDate: Date!, $comment: String) {
    updateChangeSetAuthority(
      input: {
        authorizedDate: $authorizedDate
        changeSetAuthorityId: $changeSetAuthorityId
        comment: $comment
        status: APPROVED
      }
    ) {
      changeSetAuthority {
        id
        nodeId
      }
    }
  }
`;
export const RejectChangeSetAuthority = gql `
  mutation RejectChangeSetAuthority($changeSetAuthorityId: Int!, $authorizedDate: Date!, $comment: String) {
    updateChangeSetAuthority(
      input: {
        authorizedDate: $authorizedDate
        changeSetAuthorityId: $changeSetAuthorityId
        comment: $comment
        status: REJECTED
      }
    ) {
      changeSetAuthority {
        id
        nodeId
      }
    }
  }
`;
export const DeleteChangeSetById = gql `
  mutation DeleteChangeSetById($id: Int!) {
    deleteChangeSetById(input: { id: $id }) {
      changeSet {
        id
        nodeId
      }
    }
  }
`;
let ChangeSetsRepoMixin = class ChangeSetsRepoMixin extends Mixins(LoaderMixin) {
    changeSetById = queryFactory(this, ChangeSetById);
    createChangeSet = mutationFactory(this, CreateChangeSet);
    reopenChangeSets = mutationFactory(this, ReopenChangeSets);
    updateChangeSet = mutationFactory(this, UpdateChangeSet);
    updateChangeSetById = mutationFactory(this, UpdateChangeSetById);
    deactivateChangeSetTemplate = mutationFactory(this, DeactivateChangeSetTemplateById);
    reactivateChangeSetTemplate = mutationFactory(this, ReactivateChangeSetTemplateById);
    updateChangeSetAuthority = mutationFactory(this, UpdateChangeSetAuthority);
    acceptChangeSetAuthority = mutationFactory(this, ApproveChangeSetAuthority);
    rejectChangeSetAuthority = mutationFactory(this, RejectChangeSetAuthority);
    deleteChangeSetById = mutationFactory(this, DeleteChangeSetById);
};
ChangeSetsRepoMixin = __decorate([
    Component
], ChangeSetsRepoMixin);
export { ChangeSetsRepoMixin };
