var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "home_view",
    attrs: {
      "data-app": "true"
    }
  }, [_c('div', {
    staticClass: "c-grid"
  }, [_vm.currentUser && _vm.currentUser.id ? _c('div', {
    staticClass: "col-full"
  }, [_c('TopPages', {
    attrs: {
      "user-id": _vm.currentUser.id
    }
  })], 1) : _vm._e()]), _c('c-card', {
    attrs: {
      "border-color": "var(--v-item_instances-base)"
    }
  }, [_c('div', {
    staticClass: "c-grid"
  }, [_c('div', {
    staticClass: "col-fourth"
  }, [_c('AppSelectDrawingNumber', {
    attrs: {
      "clearable": ""
    },
    model: {
      value: _vm.formData.drawingNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "drawingNumber", $$v);
      },
      expression: "formData.drawingNumber"
    }
  })], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('AppSelectAsBuilt', {
    attrs: {
      "clearable": ""
    },
    model: {
      value: _vm.formData.asBuiltNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "asBuiltNumber", $$v);
      },
      expression: "formData.asBuiltNumber"
    }
  })], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('AppSelectSerial', {
    attrs: {
      "clearable": ""
    },
    model: {
      value: _vm.formData.serialNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "serialNumber", $$v);
      },
      expression: "formData.serialNumber"
    }
  })], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('AppSelectLot', {
    attrs: {
      "clearable": ""
    },
    model: {
      value: _vm.formData.lotNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "lotNumber", $$v);
      },
      expression: "formData.lotNumber"
    }
  })], 1), _c('div', {
    staticClass: "col-twelveth"
  }, [_c('AppSelectEnum', {
    attrs: {
      "label": "Side",
      "display": _vm.SideDisplay,
      "enum": _vm.Side,
      "chips": false,
      "color": _vm.EntityType.ITEM_MASTER,
      "clearable": "",
      "monospace-selections": ""
    },
    model: {
      value: _vm.formData.side,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "side", $$v);
      },
      expression: "formData.side"
    }
  })], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('AppSelectEnum', {
    attrs: {
      "chips": false,
      "display": _vm.ItemInstanceInventoryTypeDisplay,
      "enum": _vm.ItemClassesWithoutMultiple,
      "color": _vm.EntityType.ITEM_INSTANCE,
      "clearable": "",
      "monospace-selections": "",
      "label": "Class"
    },
    model: {
      value: _vm.formData.class,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "class", $$v);
      },
      expression: "formData.class"
    }
  })], 1)]), _c('div', {
    staticClass: "c-grid mt-2"
  }, [_c('div', {
    staticClass: "col-sixth"
  }, [_c('AppSelectProjectCode', {
    attrs: {
      "allow-create": false,
      "clearable": ""
    },
    model: {
      value: _vm.formData.projectCode,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "projectCode", $$v);
      },
      expression: "formData.projectCode"
    }
  })], 1), _c('div', {
    staticClass: "col-third"
  }, [_c('AppSelectEnum', {
    attrs: {
      "chips": false,
      "label": "Federal supply class",
      "enum": _vm.FederalSupplyClass,
      "display": _vm.FederalSupplyClassDisplay,
      "clearable": ""
    },
    model: {
      value: _vm.formData.federalSupplyClass,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "federalSupplyClass", $$v);
      },
      expression: "formData.federalSupplyClass"
    }
  })], 1), _c('div', {
    staticClass: "col-fourth"
  }, [_c('AppSelectEnum', {
    attrs: {
      "chips": false,
      "label": "Property class",
      "enum": _vm.ItemMasterPropertyClass,
      "display": _vm.ItemMasterPropertyClassDisplay,
      "clearable": ""
    },
    model: {
      value: _vm.formData.propertyClass,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "propertyClass", $$v);
      },
      expression: "formData.propertyClass"
    }
  })], 1), _c('div', {
    staticClass: "col-fourth"
  }, [_c('AppSelectEnum', {
    attrs: {
      "chips": false,
      "display": _vm.InventoryStatusDisplay,
      "enum": _vm.InventoryStatus,
      "label": "Status",
      "sort": "",
      "clearable": ""
    },
    model: {
      value: _vm.formData.status,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "status", $$v);
      },
      expression: "formData.status"
    }
  })], 1)]), _c('div', {
    staticClass: "c-grid mt-2"
  }, [_c('div', {
    staticClass: "col-fourth"
  }, [_c('AppSelect', {
    attrs: {
      "chips": false,
      "select-options": _vm.RefurbExpendSelectOptions,
      "color": _vm.EntityType.ITEM_MASTER,
      "label": "Refurb/Expend"
    },
    model: {
      value: _vm.formData.isRefurbishable,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "isRefurbishable", $$v);
      },
      expression: "formData.isRefurbishable"
    }
  })], 1), _c('div', {
    staticClass: "col-fourth"
  }, [_c('AppSelect', {
    attrs: {
      "chips": false,
      "select-options": _vm.computedItemMasterTypeSelectOptions,
      "label": "Equipment group",
      "sort": ""
    },
    model: {
      value: _vm.formData.equipmentGroup,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "equipmentGroup", $$v);
      },
      expression: "formData.equipmentGroup"
    }
  })], 1), _c('div', {
    staticClass: "col-fourth"
  }, [_c('AppSelectEnum', {
    attrs: {
      "chips": false,
      "display": _vm.InventoryControlDisplay,
      "enum": _vm.InventoryControl,
      "color": _vm.EntityType.ITEM_MASTER,
      "clearable": "",
      "label": "Inventory control"
    },
    model: {
      value: _vm.formData.inventoryControl,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "inventoryControl", $$v);
      },
      expression: "formData.inventoryControl"
    }
  })], 1), _c('div', {
    staticClass: "col-fourth"
  }, [_c('AppSelectEnum', {
    attrs: {
      "chips": false,
      "label": "Initialization status",
      "enum": _vm.ChangeSetStatus,
      "display": _vm.ChangeSetStatusDisplay,
      "sort": ""
    },
    model: {
      value: _vm.formData.initializationStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "initializationStatus", $$v);
      },
      expression: "formData.initializationStatus"
    }
  })], 1)])]), _c('v-slide-y-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.formData.includeInventory ? _c('c-card', {
    staticClass: "my-2",
    attrs: {
      "border-color": "var(--v-locations-base)"
    }
  }, [_c('AppPageDescription', {
    attrs: {
      "color": _vm.EntityType.LOCATION,
      "remove-margin-left": ""
    }
  }, [_vm._v(" Location filters only apply at the Inventory level. ")]), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "sm3": "",
      "pr-2": ""
    }
  }, [_c('AppSelectBuilding', {
    attrs: {
      "clearable": ""
    },
    model: {
      value: _vm.formData.building,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "building", $$v);
      },
      expression: "formData.building"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "sm3": "",
      "pr-2": ""
    }
  }, [_c('AppSelectRoom', {
    attrs: {
      "clearable": ""
    },
    model: {
      value: _vm.formData.room,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "room", $$v);
      },
      expression: "formData.room"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "sm3": "",
      "pr-2": ""
    }
  }, [_c('AppSelectStock', {
    attrs: {
      "clearable": ""
    },
    model: {
      value: _vm.formData.stock,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "stock", $$v);
      },
      expression: "formData.stock"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "sm3": "",
      "pr-2": ""
    }
  }, [_c('AppSelectBin', {
    attrs: {
      "clearable": ""
    },
    model: {
      value: _vm.formData.bin,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "bin", $$v);
      },
      expression: "formData.bin"
    }
  })], 1)], 1)], 1) : _vm._e()], 1), _c('c-card', {
    staticClass: "mt-2",
    attrs: {
      "border-color": "var(--v-primary-base)"
    }
  }, [_c('div', {
    staticClass: "c-grid"
  }, [_c('div', {
    staticClass: "col-fourth"
  }, [_c('AppNumber', {
    attrs: {
      "label": "Max records per Hardware type",
      "min": "1"
    },
    model: {
      value: _vm.formData.take,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "take", $$v);
      },
      expression: "formData.take"
    }
  })], 1), _c('div', {
    staticClass: "col-three-fourths"
  }, [_c('AppCheckbox', {
    attrs: {
      "label": "Include Item drawings"
    },
    model: {
      value: _vm.formData.includeDrawings,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "includeDrawings", $$v);
      },
      expression: "formData.includeDrawings"
    }
  }), _c('AppCheckbox', {
    staticClass: "mt-1",
    attrs: {
      "label": "Include Item masters"
    },
    model: {
      value: _vm.formData.includeMasters,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "includeMasters", $$v);
      },
      expression: "formData.includeMasters"
    }
  }), _c('AppCheckbox', {
    staticClass: "mt-1",
    attrs: {
      "label": "Include Item instances"
    },
    model: {
      value: _vm.formData.includeInstances,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "includeInstances", $$v);
      },
      expression: "formData.includeInstances"
    }
  }), _c('AppCheckbox', {
    staticClass: "mt-1",
    attrs: {
      "label": "Include Inventories"
    },
    model: {
      value: _vm.formData.includeInventory,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "includeInventory", $$v);
      },
      expression: "formData.includeInventory"
    }
  }), _c('AppCheckbox', {
    staticClass: "mt-1",
    attrs: {
      "label": "Include archived"
    },
    model: {
      value: _vm.formData.includeArchived,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "includeArchived", $$v);
      },
      expression: "formData.includeArchived"
    }
  })], 1)]), _c('div', {
    attrs: {
      "slot": "actions"
    },
    slot: "actions"
  }, [_c('ButtonGeneric', {
    staticClass: "mr-2",
    attrs: {
      "icon": _vm.$icons.search,
      "to": _vm.searchResultsUrl,
      "color": "success"
    }
  }, [_c('span', [_vm._v("Search for hardware")])]), _c('ButtonClearSelection', {
    staticClass: "mr-2",
    on: {
      "click": _vm.onClickOfClearFilters
    }
  }, [_c('span', [_vm._v("Clear all filters")])])], 1)]), _vm.shouldShowInventoryDueBackTable ? _c('c-card', {
    staticClass: "mt-2",
    attrs: {
      "border-color": "var(--v-movement-base)"
    }
  }, [_c('InventoryDueBackTable', {
    attrs: {
      "items": _vm.inventoryDueBackItems,
      "rows-per-page-items": _vm.rowsPerPageItems
    }
  })], 1) : _vm._e(), _vm.shouldShowInTransitTable ? _c('c-card', {
    staticClass: "mt-2"
  }, [_c('InventoryInTransitTable', {
    attrs: {
      "items": _vm.inTransitTableItems,
      "rows-per-page-items": _vm.rowsPerPageItems
    }
  })], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };