/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./AppModal.vue?vue&type=template&id=0c99463a&scoped=true"
import script from "./AppModal.ts?vue&type=script&lang=ts&external"
export * from "./AppModal.ts?vue&type=script&lang=ts&external"
import style0 from "./AppModal.vue?vue&type=style&index=0&id=0c99463a&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c99463a",
  null
  
)

export default component.exports