import { getBlurbAboutCreate, getBlurbAboutManage, getBlurbAboutSearch } from '~/nasa_ui/router/utils';
import { EntityType, EntityTypeSingularDisplay, UserPermission } from '~/nasa_ui/types';
// These routes are nested under `/hardware/...` for no other reason
// than it was like that in og
// we got cute with route naming so this is backwards compatible
const alternatePathName = 'list_templates';
export const HardwareListTemplateSharedRoutes = [
    {
        path: `/hardware/${alternatePathName}/create`,
        name: 'HardwareListTemplateCreateView',
        component: () => import(
        /* webpackChunkName: "HardwareListTemplate" */ '../views/HardwareListTemplateCreateView/HardwareListTemplateCreateView.vue'),
        meta: {
            displayName: 'Hardware List Template create',
            description: getBlurbAboutCreate(EntityTypeSingularDisplay.get(EntityType.HARDWARE_LIST_TEMPLATE)),
            permissions: [UserPermission.HARDWARE_LIST_TEMPLATE_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/${alternatePathName}/manage`,
        redirect: {
            name: 'HardwareListTemplateSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.HARDWARE_LIST_TEMPLATE)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.HARDWARE_LIST_TEMPLATE)),
            permissions: [UserPermission.HARDWARE_LIST_TEMPLATE_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/${alternatePathName}/manage/:id`,
        name: 'HardwareListTemplateManageView',
        component: () => import(
        /* webpackChunkName: "HardwareListTemplate" */ '../views/HardwareListTemplateManageView/HardwareListTemplateManageView.vue'),
        meta: {
            displayName: 'Hardware List Template manage',
            description: getBlurbAboutManage(EntityTypeSingularDisplay.get(EntityType.HARDWARE_LIST_TEMPLATE)),
            permissions: [UserPermission.HARDWARE_LIST_TEMPLATE_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/${alternatePathName}/search`,
        name: 'HardwareListTemplateSearchView',
        component: () => import(
        /* webpackChunkName: "HardwareListTemplate" */ '../views/HardwareListTemplateSearchView/HardwareListTemplateSearchView.vue'),
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.HARDWARE_LIST_TEMPLATE)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.HARDWARE_LIST_TEMPLATE)),
            permissions: [UserPermission.HARDWARE_LIST_TEMPLATE_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/${alternatePathName}`,
        redirect: {
            name: 'HardwareListTemplateSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.HARDWARE_LIST_TEMPLATE)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.HARDWARE_LIST_TEMPLATE)),
            permissions: [UserPermission.HARDWARE_LIST_TEMPLATE_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/${alternatePathName}`,
        redirect: {
            name: 'HardwareListTemplateSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.HARDWARE_LIST_TEMPLATE)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.HARDWARE_LIST_TEMPLATE)),
            permissions: [UserPermission.HARDWARE_LIST_TEMPLATE_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    }
];
