export var LocationContractOptions;
(function (LocationContractOptions) {
    LocationContractOptions["ALL"] = "ALL";
    LocationContractOptions["MANAGED"] = "MANAGED";
    LocationContractOptions["UNMANAGED"] = "UNMANAGED";
})(LocationContractOptions || (LocationContractOptions = {}));
export const LocationContractOptionsDisplay = new Map([
    [LocationContractOptions.ALL, 'Show all Locations'],
    [LocationContractOptions.MANAGED, 'Show managed Locations only'],
    [LocationContractOptions.UNMANAGED, 'Show unmanaged Locations only']
]);
