import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component } from 'vue-property-decorator';
import BaseMini from '~/nasa_ui/base/BaseMini';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { CosmicLocationWithOrgFragment as CosmicLocationWithOrgFragmentVarOverride } from '~/nasa_ui/DAL/location';
import { EntityType } from '~/nasa_ui/types';
import { UsStateDisplay } from '~/nasa_ui/types/enums/us-states';
let MiniLocation = class MiniLocation extends BaseMini {
    entityType = EntityType.LOCATION;
    localEntity = null;
    BASE_FETCH = gql `
    ${CosmicLocationWithOrgFragmentVarOverride}

    query MiniLocationFetch($id: UUID!) {
      locationById(id: $id) {
        ...CosmicLocationWithOrg
      }
    }
  `;
    get buildingDisplay() {
        return this.localEntity && this.$buildingDisplay(this.localEntity.building);
    }
    get binDisplay() {
        return this.localEntity && this.$binDisplay(this.localEntity.bin);
    }
    get hasContract() {
        return !!(this.localEntity && this.localEntity.contractNumber);
    }
    get isManagedLocation() {
        return !!(this.localEntity && this.localEntity.contractNumber);
    }
    get locationAddress() {
        if (!this.localEntity) {
            return DEFAULT_DASH;
        }
        return this.localEntity.address || DEFAULT_DASH;
    }
    get locationCity() {
        if (!this.localEntity) {
            return DEFAULT_DASH;
        }
        return this.localEntity.city || DEFAULT_DASH;
    }
    get locationState() {
        if (!this.localEntity) {
            return DEFAULT_DASH;
        }
        return this.localEntity.state ? UsStateDisplay.get(this.localEntity.state) || DEFAULT_DASH : DEFAULT_DASH;
    }
    get locationZip() {
        if (!this.localEntity) {
            return DEFAULT_DASH;
        }
        return this.localEntity.zip || DEFAULT_DASH;
    }
    get locationPhone() {
        if (!this.localEntity) {
            return DEFAULT_DASH;
        }
        return this.localEntity.phone
            ? `<a href="tel:${this.localEntity.phone}">${this.localEntity.phone}</a>`
            : DEFAULT_DASH;
    }
    get locationContact() {
        if (!this.localEntity) {
            return DEFAULT_DASH;
        }
        return this.localEntity.contact || DEFAULT_DASH;
    }
    get locationDisplay() {
        return this.$locationDisplay(this.localEntity);
    }
    get manageLink() {
        return this.localEntity ? `/${EntityType.LOCATION}/manage/${this.localEntity.id}` : '';
    }
    get managedChip() {
        return [
            {
                color: 'white',
                text: this.hasContract ? 'MANAGED' : 'UNMANAGED',
                outline: true,
                value: this.hasContract ? 'Managed' : 'Unmanaged'
            }
        ];
    }
    get name() {
        return `${this.buildingDisplay} / ${this.stockDisplay} / ${this.binDisplay}`;
    }
    nameDisplay(verticalDisplayItem) {
        if (!this.localEntity && !this.isFetching) {
            return 'Not found';
        }
        return verticalDisplayItem.name || DEFAULT_DASH;
    }
    get roomDisplay() {
        return this.localEntity && this.localEntity.room;
    }
    get stockDisplay() {
        return this.localEntity && this.$stockDisplay(this.localEntity.stock);
    }
    get verticalLabelDisplayItems() {
        if (!this.localEntity) {
            return [];
        }
        const items = [];
        items.push({
            name: 'Building',
            value: this.buildingDisplay
        });
        items.push({
            name: 'Room',
            value: this.$get(this, 'localEntity.room') || DEFAULT_DASH
        });
        items.push({
            name: 'Stock',
            value: this.stockDisplay
        });
        items.push({
            name: 'Bin',
            value: this.binDisplay
        });
        items.push({
            name: 'Org Code / Name',
            value: `${this.$get(this, 'localEntity.organization.code', DEFAULT_DASH) || DEFAULT_DASH} / ${this.$get(this, 'localEntity.organization.name', DEFAULT_DASH) || DEFAULT_DASH}`
        });
        if (this.localEntity.contractNumber) {
            items.push({
                name: 'Contract',
                value: `${this.localEntity.contractNumber || DEFAULT_DASH}`
            });
        }
        return items;
    }
    onFetchUpdate(data) {
        if (data.locationById) {
            this.localEntity = data.locationById;
        }
    }
};
MiniLocation = __decorate([
    Component
], MiniLocation);
export default MiniLocation;
