import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop } from 'vue-property-decorator';
import BaseVue from '~/nasa_ui/base/BaseVue';
import { isValidColorValue, parseColorClasses } from '../utils/helpers/colors';
let BaseModal = class BaseModal extends BaseVue {
    isFetching = false;
    isRemoving = false;
    isSaving = false;
    formData;
    contentClass;
    color;
    hideTitleSection;
    maxHeight;
    modalWidth;
    title;
    get computedBorderColor() {
        if (!this.color) {
            return '';
        }
        if (isValidColorValue(this.color)) {
            return this.color;
        }
        return parseColorClasses(this.color, 'lighten2');
    }
    get computedColor() {
        if (!this.color) {
            return '';
        }
        if (isValidColorValue(this.color)) {
            return this.color;
        }
        return parseColorClasses(this.color);
    }
    get computedContentClass() {
        if (this.contentClass) {
            return `${this.contentClass} app_modal_active`;
        }
        return 'app_modal_active';
    }
    get isFormValid() {
        return false;
    }
    fetchEntity(id) {
        console.log('Implement fetchEntity!');
    }
    editEntity(payload, nodeId) {
        console.log('Implement editEntity!');
    }
    deleteEntity(nodeId) {
        console.log('Implement deleteEntity!');
    }
    createEntity(payload) {
        console.log('Implement createEntity!');
    }
    closeDialog() {
        this.emitModalClose();
    }
    emitModalClose() { }
    emitEntityCreated(msg) {
        this.$notification.add({
            type: 'success',
            text: msg ? msg : 'Created.'
        });
        return this;
    }
    emitEntityEdited(msg) {
        this.$notification.add({
            type: 'success',
            text: msg ? msg : 'Editted.'
        });
        return this;
    }
    emitEntityDeleted(msg) {
        this.$notification.add({
            type: 'success',
            text: msg ? msg : 'Deletion successful.'
        });
        return this;
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], BaseModal.prototype, "contentClass", void 0);
__decorate([
    Prop({
        type: String,
        default: 'primary'
    }),
    __metadata("design:type", String)
], BaseModal.prototype, "color", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], BaseModal.prototype, "hideTitleSection", void 0);
__decorate([
    Prop({
        type: String,
        default: '75vh'
    }),
    __metadata("design:type", String)
], BaseModal.prototype, "maxHeight", void 0);
__decorate([
    Prop({
        type: String,
        default: '90vw'
    }),
    __metadata("design:type", String)
], BaseModal.prototype, "modalWidth", void 0);
__decorate([
    Prop({ type: String }),
    __metadata("design:type", String)
], BaseModal.prototype, "title", void 0);
__decorate([
    Emit('modal-close'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseModal.prototype, "emitModalClose", null);
__decorate([
    Emit('entityCreated'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], BaseModal.prototype, "emitEntityCreated", null);
__decorate([
    Emit('entityEdited'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], BaseModal.prototype, "emitEntityEdited", null);
__decorate([
    Emit('entityDeleted'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], BaseModal.prototype, "emitEntityDeleted", null);
BaseModal = __decorate([
    Component
], BaseModal);
export default BaseModal;
