import { getBlurbAboutCreate, getBlurbAboutManage, getBlurbAboutSearch } from '~/nasa_ui/router/utils';
import { EntityType, EntityTypeSingularDisplay, UserPermission } from '~/nasa_ui/types';
export const UserSharedRoutes = [
    {
        path: `/${EntityType.USER}`,
        redirect: {
            name: 'UserSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.USER)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.USER)),
            permissions: [UserPermission.USER_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.USER}/manage`,
        redirect: {
            name: 'UserSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.USER)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.USER)),
            permissions: [UserPermission.USER_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.USER}/manage/:id`,
        name: 'UserManageView',
        component: () => import(/* webpackChunkName: "User" */ '../views/UserManageView/UserManageView.vue'),
        meta: {
            displayName: 'Users manage',
            description: getBlurbAboutManage(EntityTypeSingularDisplay.get(EntityType.USER)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.USER}/search`,
        name: 'UserSearchView',
        component: () => import(/* webpackChunkName: "User" */ '../views/UserSearchView/UserSearchView.vue'),
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.USER)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.USER)),
            permissions: [UserPermission.USER_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.USER}/create`,
        name: 'UserCreateView',
        component: () => import(/* webpackChunkName: "User" */ '../views/UserCreateView/UserCreateView.vue'),
        meta: {
            displayName: 'Users create',
            description: getBlurbAboutCreate(EntityTypeSingularDisplay.get(EntityType.USER)),
            permissions: [UserPermission.USER_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.USER}/groups`,
        redirect: {
            name: 'UserGroupsSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.USER_GROUP)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.USER_GROUP)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.USER}/groups/create`,
        name: 'UserGroupsCreateView',
        component: () => import(/* webpackChunkName: "User" */ '../views/UserGroupsCreateView/UserGroupsCreateView.vue'),
        meta: {
            displayName: 'User Groups create',
            description: getBlurbAboutCreate(EntityTypeSingularDisplay.get(EntityType.USER_GROUP)),
            permissions: [UserPermission.USER_GROUPS_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.USER}/groups/search`,
        name: 'UserGroupsSearchView',
        component: () => import(/* webpackChunkName: "User" */ '../views/UserGroupsSearchView/UserGroupsSearchView.vue'),
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.USER_GROUP)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.USER_GROUP)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.USER}/groups/manage`,
        redirect: {
            name: 'UserGroupsSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.USER_GROUP)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.USER_GROUP)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.USER}/groups/manage/:code`,
        name: 'UserGroupsManageView',
        component: () => import(/* webpackChunkName: "User" */ '../views/UserGroupsManageView/UserGroupsManageView.vue'),
        meta: {
            displayName: 'User Groups manage',
            description: getBlurbAboutManage(EntityTypeSingularDisplay.get(EntityType.USER_GROUP)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.USER}/approval_routing_templates`,
        redirect: {
            name: 'ApprovalRoutingTemplatesSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.APPROVAL_ROUTING_TEMPLATE)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.APPROVAL_ROUTING_TEMPLATE)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.USER}/${EntityType.APPROVAL_ROUTING_TEMPLATE}/create`,
        name: 'ApprovalRoutingTemplateCreateView',
        component: () => import(
        /* webpackChunkName: "User" */ '../views/ApprovalRoutingTemplateCreateView/ApprovalRoutingTemplateCreateView.vue'),
        meta: {
            displayName: 'Approval Routing Template create',
            description: getBlurbAboutCreate(EntityTypeSingularDisplay.get(EntityType.APPROVAL_ROUTING_TEMPLATE)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.USER}/${EntityType.APPROVAL_ROUTING_TEMPLATE}/search`,
        name: 'ApprovalRoutingTemplatesSearchView',
        component: () => import(
        /* webpackChunkName: "User" */ '../views/ApprovalRoutingTemplatesSearchView/ApprovalRoutingTemplatesSearchView.vue'),
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.APPROVAL_ROUTING_TEMPLATE)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.APPROVAL_ROUTING_TEMPLATE)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.USER}/${EntityType.APPROVAL_ROUTING_TEMPLATE}/manage`,
        redirect: {
            name: 'ApprovalRoutingTemplatesSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.APPROVAL_ROUTING_TEMPLATE)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.APPROVAL_ROUTING_TEMPLATE)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.USER}/${EntityType.APPROVAL_ROUTING_TEMPLATE}/manage/:id`,
        component: () => import(
        /* webpackChunkName: "User" */ '../views/ApprovalRoutingTemplatesManageView/ApprovalRoutingTemplatesManageView.vue'),
        meta: {
            displayName: 'Approval Routing Template manage',
            description: getBlurbAboutManage(EntityTypeSingularDisplay.get(EntityType.APPROVAL_ROUTING_TEMPLATE)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.USER}/auth`,
        name: 'UserAuthentication',
        component: () => import(/* webpackChunkName: "User" */ '../views/UserAuthentication/UserAuthentication.vue'),
        meta: {
            displayName: 'User Authentication',
            description: 'Authentication',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    }
];
