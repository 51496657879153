import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop } from 'vue-property-decorator';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import LocationsAutocompleteMixin, { LocationsAutocompleteFields } from '~/nasa_ui/mixins/LocationsAutocompleteMixin';
let AppSelectBuilding = class AppSelectBuilding extends Mixins(LocationsAutocompleteMixin, BaseCosmic) {
    label;
    field = LocationsAutocompleteFields.BUILDING;
};
__decorate([
    Prop({
        required: false,
        default: 'Building',
        type: String
    }),
    __metadata("design:type", String)
], AppSelectBuilding.prototype, "label", void 0);
AppSelectBuilding = __decorate([
    Component
], AppSelectBuilding);
export default AppSelectBuilding;
