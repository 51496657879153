import { ChangeSetStatusDisplay } from '~/nasa_ui/types/enums/change-set';
import { CosmicIcons } from './CosmicIcons';
export const buildIconAsString = (options) => {
    if (!options) {
        return '';
    }
    const iconClass = options.iconClass || '';
    const _color = options.color || 'default';
    const iconColor = _color.endsWith('--text') ? _color : `${_color}--text`;
    return `
   <i class="${iconClass} ${iconColor}" title="${options.title || ''}" tooltip="${options.title || ''}"></i>
  `;
};
export const buildIconWithLabelAsString = (options) => {
    if (!options) {
        return '';
    }
    const iconClass = options.iconClass || '';
    const _color = options.color || 'default';
    const iconColor = _color.endsWith('--text') ? _color : `${_color}--text`;
    const justifyClass = options.labelAlignment === 'center' ? '' : 'side_by_side_icon';
    return `
  <div class=" d-flex align-center justify-center ${justifyClass}" style="flex-direction: column;" tooltip="${options.title || ''}">
    <i class="text-xs-left ${iconClass} ${iconColor}" title="${options.title || ''}"></i>
    <small class="font-weight-bold ${options.labelCssClass}" style="font-size:14px;">${options.label}</small>
  </div>
  
  `;
};
export const buildChangeSetStatusIcon = (changeSetStatus) => {
    const prefix = 'pipeline_';
    const status = ChangeSetStatusDisplay.get(changeSetStatus)?.toLowerCase();
    const iconClass = `status-icon ${status} ${CosmicIcons[prefix + status] || CosmicIcons['pipeline_null']}`;
    const title = ChangeSetStatusDisplay.get(changeSetStatus) || 'Unknown';
    return buildIconAsString({
        color: '',
        iconClass,
        title
    });
};
export const buildOkIcon = (title) => {
    return buildIconAsString({
        color: 'success--text',
        iconClass: CosmicIcons['circle_check'],
        title
    });
};
export const buildNotOkIcon = (title) => {
    return buildIconAsString({
        color: 'error--text',
        iconClass: CosmicIcons['false'],
        title
    });
};
export const buildNullUndefinedIcon = (title = 'Value not defined.') => {
    return buildIconAsString({
        iconClass: CosmicIcons['pipeline_null'],
        title
    });
};
