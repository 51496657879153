import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { Side } from '~/db_types/swagger_types';
import { DB_DEFAULT, DEFAULT_DASH } from '~/nasa_ui/constants';
import { transformItemDrawingResponseWithRefs } from '~/nasa_ui/utils/helpers/transforms/hardware';
import { transformLinks } from '~/nasa_ui/utils/helpers/transforms/links';
let NewBaseHardwareDisplay = class NewBaseHardwareDisplay extends Vue {
    DB_DEFAULT = DB_DEFAULT;
    DEFAULT_DASH = DEFAULT_DASH;
    isFetching = false;
    isFetchingHardware = false;
    isFetchingItemDrawing = false;
    isFetchingItemMaster = false;
    isMinimized = false;
    itemDrawing = null;
    itemMaster = null;
    links = null;
    searchHardwareResults = null;
    selectedEntity = null;
    Side = Side;
    get hasLinks() {
        return this.transformedLinks.length > 0;
    }
    get isArchived() {
        return this.selectedEntity?.isArchived;
    }
    get transformedItemDrawing() {
        return this.itemDrawing ? transformItemDrawingResponseWithRefs([this.itemDrawing])[0] : null;
    }
    get transformedLinks() {
        return this.links ? transformLinks(this.links) : [];
    }
    onClickOfMinimize() {
        this.isMinimized = true;
    }
    onClickOfMaximize() {
        this.isMinimized = false;
    }
};
NewBaseHardwareDisplay = __decorate([
    Component
], NewBaseHardwareDisplay);
export default NewBaseHardwareDisplay;
