import { __decorate } from "tslib";
import { Component, Mixins } from 'vue-property-decorator';
import BaseButtonMiniActionImplementation from '~/nasa_ui/base/BaseButtonMiniActionImplementation';
import ButtonMiniAction from '~/nasa_ui/components/buttons/ButtonMiniAction/ButtonMiniAction.vue';
let ButtonMiniActionDelete = class ButtonMiniActionDelete extends Mixins(BaseButtonMiniActionImplementation) {
    get computedTooltip() {
        return this.tooltip ?? 'PERMANENTLY delete item';
    }
};
ButtonMiniActionDelete = __decorate([
    Component({
        components: { ButtonMiniAction }
    })
], ButtonMiniActionDelete);
export default ButtonMiniActionDelete;
