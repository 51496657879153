import { __decorate } from "tslib";
import { Component, Mixins } from 'vue-property-decorator';
import { UserRole } from '~/db_types/swagger_types';
import BaseMini from '~/nasa_ui/base/BaseMini';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import HttpMixin from '~/nasa_ui/mixins/HttpMixin';
import { EntityType } from '~/nasa_ui/types';
import { UserRoleDisplay } from '~/nasa_ui/types/enums/user';
let MiniUser = class MiniUser extends Mixins(BaseMini, HttpMixin) {
    entityType = EntityType.USER;
    localEntity = null;
    get auidDisplay() {
        return this.localEntity?.auid || DEFAULT_DASH;
    }
    get emailDisplay() {
        return this.localEntity?.email && this.localEntity.email.length ? this.localEntity.email[0] : null;
    }
    get iconClass() {
        if (!this.localEntity) {
            return this.$icons[EntityType.USER];
        }
        const role = this.localEntity.role;
        return this.$icons[role] || this.$icons[EntityType.USER];
    }
    get isCosmicUser() {
        return this.localEntity?.role === UserRole.SYSTEM;
    }
    get manageLink() {
        return this.localEntity?.id ? `/${EntityType.USER}/manage/${this.localEntity.id}` : '';
    }
    get name() {
        if (!this.localEntity && !this.isFetching) {
            return 'Not found';
        }
        if (this.isCosmicUser) {
            return this.currentSystemName || '?';
        }
        if (this.userHasFirstAndLastName) {
            const fn = this.$get(this, 'localEntity.firstName', '') || '';
            const ln = this.$get(this, 'localEntity.lastName', '') || '';
            return `${fn} ${ln}`;
        }
        return 'No name';
    }
    get uupicDisplay() {
        return this.localEntity?.uupic || DEFAULT_DASH;
    }
    get userHasFirstAndLastName() {
        return Boolean(this.localEntity?.firstName && this.localEntity?.lastName);
    }
    /**
     * According to https://www.nasa.gov/offices/ocio/launchpad_faq.html
     * AUID is a max of 8 characters
     */
    get shouldShowAuidWarning() {
        return this.auidDisplay === DEFAULT_DASH || this.auidDisplay.length > 8;
    }
    get shouldShowManageLink() {
        return this.showManageLink && !this.isCosmicUser;
    }
    get verticalLabelDisplayItems() {
        if (!this.localEntity) {
            return [];
        }
        const items = [];
        items.push({
            name: 'AUID',
            value: this.auidDisplay
        });
        items.push({
            name: 'Role',
            value: this.localEntity.role ? UserRoleDisplay.get(this.localEntity.role) : DEFAULT_DASH
        });
        return items;
    }
    async executeQuery() {
        if (!this.id) {
            return;
        }
        const resp = await this.httpGet(`/users/${this.id}`);
        this.localEntity = resp.data;
    }
};
MiniUser = __decorate([
    Component
], MiniUser);
export default MiniUser;
