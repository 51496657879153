import colors from 'vuetify/es5/util/colors';
import { EntityType } from '~/nasa_ui/types';
export const isValidColorValue = (color) => {
    if (!color) {
        return false;
    }
    return (color.startsWith('--') ||
        color.startsWith('var(') ||
        color.startsWith('#') ||
        color.startsWith('rgb') ||
        color.startsWith('hsl') ||
        namedColors.includes(color.toLowerCase()));
};
export const parseColorClasses = (classStr, modifier = 'base') => {
    let color = '';
    const classes = classStr.replaceAll('--text', '').replaceAll('text--', '');
    // check entity types
    Object.values(EntityType).forEach((entitiyType) => {
        if (classes.includes(entitiyType)) {
            color = `--v-${entitiyType}`;
        }
    });
    // if no entity types, check for vuetify colors
    if (!color) {
        const classesArray = classes.split(' ');
        // a few of the ui colors are not in the vuetify colors object
        const uiColors = ['primary', 'secondary', 'success', 'info', 'warning', 'error', 'movement'];
        // mush all the vuetify colors into the uiColors array
        uiColors.push(...Object.keys(colors).slice(0, -1));
        // use the first matched color
        for (const c of uiColors) {
            const colorMatch = classesArray.find((val) => val.replace('-', '').toLowerCase() === c.toLowerCase());
            if (colorMatch) {
                color = `--v-${colorMatch}`;
                break;
            }
        }
    }
    // if still no color, return empty string
    if (!color) {
        return '';
    }
    // check for color modifer
    const matches = classes.match(/(lighten|darken)-[1-5]/);
    if (matches?.[0]) {
        color += '-' + matches[0].replace('-', '');
    }
    else {
        color += `-${modifier}`;
    }
    return color;
};
// https://wunnle.com/dynamic-text-color-based-on-background
export const getCosmicColor = (color) => {
    if (!color) {
        return '';
    }
    if (color.startsWith('#') ||
        color.startsWith('rgb') ||
        color.startsWith('hsl') ||
        color.startsWith('lch') ||
        color.startsWith('okl')) {
        return color;
    }
    const colorVariable = parseColorClasses(color);
    return getComputedStyle(document.documentElement).getPropertyValue(colorVariable).trim();
};
export const getRGB = (c) => {
    return parseInt(c, 16) || c;
};
export const calculateRGB = (c) => {
    return getRGB(c) / 255 <= 0.03928 ? getRGB(c) / 255 / 12.92 : Math.pow((getRGB(c) / 255 + 0.055) / 1.055, 2.4);
};
export const calculateLuminance = (hexColor) => {
    return (0.2126 * calculateRGB(hexColor.substr(1, 2)) +
        0.7152 * calculateRGB(hexColor.substr(3, 2)) +
        0.0722 * calculateRGB(hexColor.substr(-2)));
};
export const calculateContrast = (f, b) => {
    const L1 = calculateLuminance(f);
    const L2 = calculateLuminance(b);
    return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
};
export const calculateTextColor = (color) => {
    const parsedColor = getCosmicColor(color);
    const whiteContrast = calculateContrast(parsedColor, '#ffffff');
    const blackContrast = calculateContrast(parsedColor, '#000066');
    return whiteContrast > blackContrast ? '#ffffff' : '#000066';
};
export const namedColors = [
    'aliceblue',
    'antiquewhite',
    'aqua',
    'aquamarine',
    'azure',
    'beige',
    'bisque',
    'black',
    'blanchedalmond',
    'blue',
    'blueviolet',
    'brown',
    'burlywood',
    'cadetblue',
    'chartreuse',
    'chocolate',
    'coral',
    'cornflowerblue',
    'cornsilk',
    'crimson',
    'cyan',
    'darkblue',
    'darkcyan',
    'darkgoldenrod',
    'darkgray',
    'darkgreen',
    'darkgrey',
    'darkkhaki',
    'darkmagenta',
    'darkolivegreen',
    'darkorange',
    'darkorchid',
    'darkred',
    'darksalmon',
    'darkseagreen',
    'darkslateblue',
    'darkslategray',
    'darkslategrey',
    'darkturquoise',
    'darkviolet',
    'deeppink',
    'deepskyblue',
    'dimgray',
    'dimgrey',
    'dodgerblue',
    'firebrick',
    'floralwhite',
    'forestgreen',
    'fuchsia',
    'gainsboro',
    'ghostwhite',
    'gold',
    'goldenrod',
    'gray',
    'green',
    'greenyellow',
    'grey',
    'honeydew',
    'hotpink',
    'indianred',
    'indigo',
    'ivory',
    'khaki',
    'lavender',
    'lavenderblush',
    'lawngreen',
    'lemonchiffon',
    'lightblue',
    'lightcoral',
    'lightcyan',
    'lightgoldenrodyellow',
    'lightgray',
    'lightgreen',
    'lightgrey',
    'lightpink',
    'lightsalmon',
    'lightseagreen',
    'lightskyblue',
    'lightslategray',
    'lightslategrey',
    'lightsteelblue',
    'lightyellow',
    'lime',
    'limegreen',
    'linen',
    'magenta',
    'maroon',
    'mediumaquamarine',
    'mediumblue',
    'mediumorchid',
    'mediumpurple',
    'mediumseagreen',
    'mediumslateblue',
    'mediumspringgreen',
    'mediumturquoise',
    'mediumvioletred',
    'midnightblue',
    'mintcream',
    'mistyrose',
    'moccasin',
    'navajowhite',
    'navy',
    'oldlace',
    'olive',
    'olivedrab',
    'orange',
    'orangered',
    'orchid',
    'palegoldenrod',
    'palegreen',
    'paleturquoise',
    'palevioletred',
    'papayawhip',
    'peachpuff',
    'peru',
    'pink',
    'plum',
    'powderblue',
    'purple',
    'red',
    'rosybrown',
    'royalblue',
    'saddlebrown',
    'salmon',
    'sandybrown',
    'seagreen',
    'seashell',
    'sienna',
    'silver',
    'skyblue',
    'slateblue',
    'slategray',
    'slategrey',
    'snow',
    'springgreen',
    'steelblue',
    'tan',
    'teal',
    'thistle',
    'tomato',
    'turquoise',
    'violet',
    'wheat',
    'white',
    'whitesmoke',
    'yellow',
    'yellowgreen'
];
