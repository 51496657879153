var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import AppSelect from '~/nasa_ui/components/inputs/AppSelect/AppSelect';
import { sortObjectBy } from '~/nasa_ui/utils';
import { calculateTextColor } from '~/nasa_ui/utils/helpers/colors';
let AppSelectEnum = class AppSelectEnum extends AppSelect {
    enum;
    existingValuesToBeDisabled;
    existingValuesToBeRemoved;
    display;
    get computedChipTextColor() {
        return this.chipTextColor || calculateTextColor(this.color);
    }
    // we want to default to the outline style if no other style is specified
    get computedOutlineProp() {
        if ('solo' in this.$attrs || 'box' in this.$attrs) {
            return false;
        }
        return true;
    }
    get computedSelectOptions() {
        const displayableType = Object.assign({}, this.enum);
        let output = Object.keys(displayableType).map((key) => {
            return {
                displayText: this.display ? this.display.get(key) : key,
                value: key
            };
        });
        if (this.sort) {
            output.sort(sortObjectBy('displayText'));
        }
        if (this.existingValuesToBeRemoved?.length) {
            output = output.filter((option) => {
                return !this.existingValuesToBeRemoved?.includes(option.value);
            });
        }
        if (this.existingValuesToBeDisabled?.length) {
            output = output.map((option) => {
                if (this.existingValuesToBeDisabled?.includes(option.value)) {
                    return {
                        ...option,
                        disabled: true
                    };
                }
                return option;
            });
        }
        return output || [];
    }
};
__decorate([
    Prop({
        default: []
    }),
    __metadata("design:type", typeof (_a = typeof Enumerator !== "undefined" && Enumerator) === "function" ? _a : Object)
], AppSelectEnum.prototype, "enum", void 0);
__decorate([
    Prop({
        type: Array
    }),
    __metadata("design:type", Array)
], AppSelectEnum.prototype, "existingValuesToBeDisabled", void 0);
__decorate([
    Prop({
        type: Array
    }),
    __metadata("design:type", Array)
], AppSelectEnum.prototype, "existingValuesToBeRemoved", void 0);
__decorate([
    Prop({
        default: null
    }),
    __metadata("design:type", typeof (_b = typeof Map !== "undefined" && Map) === "function" ? _b : Object)
], AppSelectEnum.prototype, "display", void 0);
AppSelectEnum = __decorate([
    Component
], AppSelectEnum);
export default AppSelectEnum;
