export var DrawingType;
(function (DrawingType) {
    DrawingType["COPY"] = "COPY";
    DrawingType["ORIGINAL"] = "ORIGINAL";
    DrawingType["PATTERN"] = "PATTERN";
    DrawingType["RETIRED"] = "RETIRED";
    DrawingType["UNRELEASED"] = "UNRELEASED";
    DrawingType["VENDOR"] = "VENDOR";
})(DrawingType || (DrawingType = {}));
export const DrawingTypeDisplay = new Map([
    [DrawingType.COPY, 'Copy'],
    [DrawingType.ORIGINAL, 'Original'],
    [DrawingType.PATTERN, 'Pattern'],
    [DrawingType.RETIRED, 'Retired'],
    [DrawingType.UNRELEASED, 'Unreleased'],
    [DrawingType.VENDOR, 'Vendor']
]);
export var SheetSize;
(function (SheetSize) {
    SheetSize["A"] = "A";
    SheetSize["B"] = "B";
    SheetSize["C"] = "C";
    SheetSize["D"] = "D";
    SheetSize["E"] = "E";
    SheetSize["F"] = "F";
    SheetSize["J"] = "J";
})(SheetSize || (SheetSize = {}));
export const SheetSizeDisplay = new Map([
    [SheetSize.A, 'A'],
    [SheetSize.B, 'B'],
    [SheetSize.C, 'C'],
    [SheetSize.D, 'D'],
    [SheetSize.E, 'E'],
    [SheetSize.F, 'F'],
    [SheetSize.J, 'J']
]);
export var CopyType;
(function (CopyType) {
    CopyType["BOTH"] = "Both";
    CopyType["FILM"] = "Film";
    CopyType["HARDCOPY"] = "Hardcopy";
})(CopyType || (CopyType = {}));
export const CopyTypeDisplay = new Map([
    [CopyType.BOTH, 'Both'],
    [CopyType.FILM, 'Film'],
    [CopyType.HARDCOPY, 'Hardcopy']
]);
