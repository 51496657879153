import gql from 'graphql-tag';
export const CosmicContractSlimFragment = gql `
  fragment CosmicContractSlim on Contract {
    endDate
    name
    nodeId
    number
    startDate
  }
`;
