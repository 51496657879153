const AUTH_DOMAINS = [
    // keycloak
    'keycloak.shared-services.appdat.jsc.nasa.gov',
    'keycloak.shared-services.dev.appdat.jsc.nasa.gov',
    'keycloak.shared-services.staging.appdat.jsc.nasa.gov',
    // google
    'accounts.google.com',
    'accounts.youtube.com'
    // nasa
    // TODO
    // collins
    // TODO
    // oceaneering
    // TODO
];
const URL_PATH_STORAGE_KEY = 'cosmic-ui_auth_redirect_url_path';
const isLocal = window.location.host.includes('local');
const getCurrentUrlPath = () => {
    return [window.location.pathname, window.location.search, window.location.hash].join('');
};
const isAuthUrl = (urlString) => {
    const url = new URL(urlString);
    return AUTH_DOMAINS.includes(url.hostname);
};
const isFromAuth = () => {
    return document.referrer ? isAuthUrl(document.referrer) : false;
};
/**
 * Store the user's path when they visit the site, as long as they're not coming
 * from auth. This should be called prior to the first API call.
 */
export const storeAuthRedirect = () => {
    // if the user is NOT coming from auth, we store the path to be used later
    if (!isFromAuth() && !isLocal) {
        window.localStorage.setItem(URL_PATH_STORAGE_KEY, getCurrentUrlPath());
    }
};
/**
 * If applicable, retrieve the stored redirect, then unconditionally delete it
 * to ensure the user doesn't get locked into a redirect loop.
 *
 * This should be used after the first API call, probably with $router.replace.
 *
 * @return false if there's no stored redirect or if the stored redirect
 *         already matches the current URL.
 */
export const popStoredAuthRedirect = () => {
    const storedRedirect = window.localStorage.getItem(URL_PATH_STORAGE_KEY);
    if (storedRedirect && getCurrentUrlPath() !== storedRedirect) {
        window.localStorage.removeItem(URL_PATH_STORAGE_KEY);
        return storedRedirect;
    }
    return false;
};
