var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.INVENTORY,
      "hide-title-section": "",
      "modal-width": "50vw"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('AppDate', {
          staticClass: "mb-2",
          attrs: {
            "value": _vm.dateLocal,
            "label": "Expected return"
          },
          on: {
            "input": _vm.emitUpdated
          }
        })];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('ButtonSave', {
          on: {
            "click": _vm.updateInventoryDueBack
          }
        }), _c('ButtonCancel', {
          staticClass: "mr-2",
          on: {
            "click": _vm.closeDialog
          }
        }), _c('ButtonRemove', {
          on: {
            "click": _vm.clearExpectedReturnDate
          }
        }, [_vm._v("Clear expected return")])];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };