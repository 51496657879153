var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-slide-y-transition', [_vm.show ? _c('AppFormWrapper', {
    staticClass: "mb-2",
    attrs: {
      "color": _vm.color,
      "size": "1"
    }
  }, [_c('div', {
    staticClass: "headline font-weight-light mb-1"
  }, [_vm._v("Available filters")]), _vm._t("default")], 2) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };