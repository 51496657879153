import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import BaseInput from '~/nasa_ui/base/BaseInput';
import { calculateTextColor, parseColorClasses } from '~/nasa_ui/utils/helpers/colors';
let AppSelect = class AppSelect extends BaseInput {
    allowCreate;
    autocomplete;
    chips;
    chipTextColor;
    createText;
    deletableChips;
    disableCache;
    hideNoData;
    inverted;
    // The generic name for the type of item this is.
    // Example: Create ABC Organization
    itemName;
    lazyLoad;
    monospaceSelections;
    multiple;
    selectOptions;
    showInactive;
    sort;
    useDeprecated;
    width;
    get hasUserDefinedWidth() {
        return this.width !== '100%';
    }
    get propsMinusAutoComplete() {
        const props = { ...this.$props };
        delete props.autocomplete;
        delete props.color;
        return props;
    }
    get calculatedWidth() {
        return `calc(${this.width} - 56px)`;
    }
    get computedChipTextColor() {
        return this.chipTextColor || calculateTextColor(this.color);
    }
    get computedColor() {
        return parseColorClasses(this.color);
    }
    // we want to default to the outline style if no other style is specified
    get computedOutlineProp() {
        if ('solo' in this.$attrs || 'box' in this.$attrs) {
            return false;
        }
        return true;
    }
    get computedSelectOptions() {
        const dedupedOptions = this.selectOptions.filter((option, idx, self) => {
            return (idx === self.findIndex((opt) => opt.value.toString().toLowerCase() === option.value.toString().toLowerCase()));
        }) || [];
        // filter inactive options based on showInactive prop
        return this.showInactive
            ? dedupedOptions
            : dedupedOptions.filter((option) => option.displayText && !option.displayText.toLowerCase().includes('inactive after'));
    }
    get searchValueIsUnique() {
        // if search value doesn't match any existing values, accounting for case
        return (this.selectOptions &&
            !this.selectOptions
                .map((o) => o.displayText)
                .some((c) => (c?.toLowerCase() === this.searchValue ? this.searchValue?.toLowerCase() : '')));
    }
    displaySelection(text) {
        if (!this.multiple || this.value.length === 1) {
            return text;
        }
        const lastItemIndex = this.value.length - 1;
        if (this.value.findIndex((val) => val === text) < lastItemIndex) {
            return `${text},`;
        }
        return text;
    }
    isSelected(item) {
        return this.value?.includes(item.value);
    }
    onUpdateOfSearchInput(val) { }
    emitCreateEvent(val) {
        // trim value for whitespace
        return val.trim();
    }
    // we are emitting input in several different cases so input is not reliable for
    // watching for *only* changes in the user's selection. so that's what this
    // event is for.
    emitSelectionChanged(selection) {
        this.searchValue = '';
        return selection;
    }
    onSelectOptionsChange(selectOptions) {
        // if the options changed but you have a value; check to see if its still valid if not emit null
        if (this.value && selectOptions && selectOptions.find((opt) => opt.value === this.value)) {
            return;
        }
        // If the DDL is a multiselect, filter out any values that aren't in selectOptions
        if (this.multiple && this.value) {
            const validSelections = selectOptions.filter((opt) => {
                return this.value?.some((val) => val === opt.value);
            });
            if (validSelections.length === this.value.length) {
                return;
            }
            this.emitInput(validSelections.map((s) => s.value));
            return;
        }
        this.emitInput(null);
    }
};
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], AppSelect.prototype, "allowCreate", void 0);
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], AppSelect.prototype, "autocomplete", void 0);
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], AppSelect.prototype, "chips", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppSelect.prototype, "chipTextColor", void 0);
__decorate([
    Prop({
        default: '',
        type: String
    }),
    __metadata("design:type", String)
], AppSelect.prototype, "createText", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], AppSelect.prototype, "deletableChips", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], AppSelect.prototype, "disableCache", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], AppSelect.prototype, "hideNoData", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], AppSelect.prototype, "inverted", void 0);
__decorate([
    Prop({
        default: '',
        type: String
    }),
    __metadata("design:type", String)
], AppSelect.prototype, "itemName", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], AppSelect.prototype, "lazyLoad", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppSelect.prototype, "monospaceSelections", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], AppSelect.prototype, "multiple", void 0);
__decorate([
    Prop({
        type: Array
    }),
    __metadata("design:type", Array)
], AppSelect.prototype, "selectOptions", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], AppSelect.prototype, "showInactive", void 0);
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], AppSelect.prototype, "sort", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], AppSelect.prototype, "useDeprecated", void 0);
__decorate([
    Prop({
        type: String,
        default: '100%'
    }),
    __metadata("design:type", String)
], AppSelect.prototype, "width", void 0);
__decorate([
    Emit('update:search-input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], AppSelect.prototype, "onUpdateOfSearchInput", null);
__decorate([
    Emit('option-create'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], AppSelect.prototype, "emitCreateEvent", null);
__decorate([
    Emit('selectionChanged'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AppSelect.prototype, "emitSelectionChanged", null);
__decorate([
    Watch('computedSelectOptions'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], AppSelect.prototype, "onSelectOptionsChange", null);
AppSelect = __decorate([
    Component
], AppSelect);
export default AppSelect;
