import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import BaseDisplay from '~/nasa_ui/base/BaseDisplay';
import CurrentUserMixin, { setActiveSupportContext } from '~/nasa_ui/mixins/CurrentUserMixin';
import { AlertType } from '~/nasa_ui/types';
let ActiveUserSupportsDisplay = class ActiveUserSupportsDisplay extends Mixins(BaseDisplay, CurrentUserMixin) {
    currentUserSupportContext = null;
    isHoveringOverChangeCtx = false;
    isReloading = false;
    // Override the Base
    onFetchUpdate(data) {
        this.entity = data;
    }
    id;
    get selectOptions() {
        return this.currentUserSupportContexts
            ? this.currentUserSupportContexts.filter((support) => {
                // Filter out the current selected ctx
                return support?.organizationCode !== this.currentUserSupportContext;
            })
            : [];
    }
    get showCurrentUserSupoprtContext() {
        return Boolean(this.currentUserSupportContext);
    }
    get currentUserSupportContextCount() {
        return this.currentUserSupportContexts?.length || 0;
    }
    get showUserSupportContextList() {
        return this.currentUserSupportContextCount > 1;
    }
    get hasOnlyOneContext() {
        return this.currentUserSupportContextCount === 1;
    }
    created() {
        this.pollLocalStorage();
    }
    async executeFetchQuery(id) {
        try {
            const resp = await this.$http.get(`/users/${id}`);
            if (resp && resp.data) {
                this.onFetchUpdate(resp.data);
            }
        }
        catch (err) {
            this.$errorUtility({
                err
            });
        }
    }
    handleUserSupportSelection(support) {
        sessionStorage.clear();
        // save current user support context and reload
        this.setCurrentUserSupport(support);
    }
    setCurrentUserSupport(organizationCode) {
        // set current user support context and reload window
        setActiveSupportContext(organizationCode);
        this.$notification.add({
            type: AlertType.SUCCESS,
            text: 'User Support Context updated. Reloading now.'
        });
        this.isReloading = true;
        // reload page
        setTimeout(() => {
            location.reload();
        }, 1500);
    }
    pollLocalStorage() {
        const polling = setInterval(() => {
            if (this.currentUserActiveSupportContext) {
                this.currentUserSupportContext = this.currentUserActiveSupportContext;
                clearInterval(polling);
            }
        }, 200);
    }
    onIdChange(id) {
        if (!id || !this.currentUserActiveSupportContext) {
            return;
        }
        this.executeFetchQuery(id);
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], ActiveUserSupportsDisplay.prototype, "id", void 0);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], ActiveUserSupportsDisplay.prototype, "onIdChange", null);
ActiveUserSupportsDisplay = __decorate([
    Component
], ActiveUserSupportsDisplay);
export default ActiveUserSupportsDisplay;
