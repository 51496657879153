import { getBlurbAboutCreate, getBlurbAboutManage, getBlurbAboutSearch } from '~/nasa_ui/router/utils';
import { EntityType, EntityTypeSingularDisplay, UserPermission } from '~/nasa_ui/types';
// These routes are nested under `/hardware/...` for no other reason
// than it was like that in og
// we got cute with route naming so this is backwards compatible
const alternatePathName = 'lists';
export const HardwareListSharedRoutes = [
    {
        path: `/hardware/${alternatePathName}/create`,
        name: 'HardwareListCreateView',
        component: () => import(/* webpackChunkName: "HardwareList" */ '../views/HardwareListCreateView/HardwareListCreateView.vue'),
        meta: {
            displayName: 'Hardware List  create',
            description: getBlurbAboutCreate(EntityTypeSingularDisplay.get(EntityType.HARDWARE_LIST)),
            permissions: [UserPermission.HARDWARE_LIST_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/${alternatePathName}/manage`,
        redirect: {
            name: 'HardwareListSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.HARDWARE_LIST)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.HARDWARE_LIST)),
            permissions: [UserPermission.HARDWARE_LIST_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/${alternatePathName}/manage/:id`,
        name: 'HardwareListManageView',
        component: () => import(/* webpackChunkName: "HardwareList" */ '../views/HardwareListManageView/HardwareListManageView.vue'),
        meta: {
            displayName: 'Hardware List manage',
            description: getBlurbAboutManage(EntityTypeSingularDisplay.get(EntityType.HARDWARE_LIST)),
            permissions: [UserPermission.HARDWARE_LIST_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/${alternatePathName}/search`,
        name: 'HardwareListSearchView',
        component: () => import(/* webpackChunkName: "HardwareList" */ '../views/HardwareListSearchView/HardwareListSearchView.vue'),
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.HARDWARE_LIST)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.HARDWARE_LIST)),
            permissions: [UserPermission.HARDWARE_LIST_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/${alternatePathName}`,
        redirect: {
            name: 'HardwareListSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.HARDWARE_LIST)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.HARDWARE_LIST)),
            permissions: [UserPermission.HARDWARE_LIST_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/${alternatePathName}`,
        redirect: {
            name: 'HardwareListSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.HARDWARE_LIST)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.HARDWARE_LIST)),
            permissions: [UserPermission.HARDWARE_LIST_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    }
];
