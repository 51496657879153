import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins } from 'vue-property-decorator';
import { CosmicIntervalFragment } from '~/nasa_ui/DAL/base/interval';
import { mutationFactory, queryFactory } from '~/nasa_ui/DAL/base/mixinFactories';
import { CosmicItemMasterSlimFragment } from '~/nasa_ui/DAL/itemMaster/slim';
import LoaderMixin from '~/nasa_ui/mixins/LoaderMixin';
import { CosmicChangeSetStatusFragment } from '../changeSet';
import { CosmicContactSlimFragment } from '../contact/slim';
import { CosmicItemInstanceFragment } from '../itemInstance/slim';
import { CosmicLocationSlimFragment } from '../location/slim';
import { CosmicItemDrawingSlimFragment } from './slim';
export const CosmicItemDrawingFragment = gql `
  ${CosmicItemDrawingSlimFragment}
  ${CosmicContactSlimFragment}

  fragment CosmicItemDrawing on ItemDrawing {
    ...CosmicItemDrawingSlim

    pointOfContact {
      ...CosmicContactSlim
    }
  }
`;
export const SearchItemDrawingFragment = gql `
  ${CosmicItemDrawingFragment}

  fragment SearchItemDrawing on ItemDrawing {
    ...CosmicItemDrawing
  }
`;
export const GetItemDrawingByNodeId = gql `
  ${CosmicItemDrawingFragment}

  query GetItemDrawingByNodeId($nodeId: ID!) {
    itemDrawing(nodeId: $nodeId) {
      ...CosmicItemDrawing
    }
  }
`;
export const GetItemDrawingByDrawingNumberWithOptionalHardware = gql `
  ${CosmicItemDrawingSlimFragment}
  ${CosmicItemMasterSlimFragment}
  ${CosmicItemInstanceFragment}

  query GetItemDrawingByDrawingNumberWithOptionalHardware(
    $drawingNumber: String!
    $shouldFetchItemMasters: Boolean = false
    $shouldFetchItemInstances: Boolean = false
  ) {
    itemDrawingByDrawingNumber(drawingNumber: $drawingNumber) {
      ...CosmicItemDrawingSlim

      itemMasters(orderBy: INVENTORY_QUANTITY_DESC) @include(if: $shouldFetchItemMasters) {
        nodes {
          ...CosmicItemMasterSlim
        }
      }

      itemInstances(orderBy: INVENTORY_QUANTITY_DESC) @include(if: $shouldFetchItemInstances) {
        nodes {
          ...CosmicItemInstance
        }
      }
    }
  }
`;
export const ItemDrawingsWithOptionalHardware = gql `
  ${CosmicLocationSlimFragment}
  ${CosmicIntervalFragment}
  ${CosmicItemDrawingFragment}
  ${CosmicItemDrawingSlimFragment}
  ${CosmicChangeSetStatusFragment}

  query ItemDrawingsWithOptionalHardware(
    $nodeId: ID!
    $shouldFetchItemMasters: Boolean = false
    $shouldFetchItemInstances: Boolean = false
    $shouldFetchChangeSets: Boolean = false
  ) {
    itemDrawing(nodeId: $nodeId) {
      ...CosmicItemDrawing

      meanTimeBetweenFailure {
        ...CosmicInterval
      }

      meanTimeToRepair {
        ...CosmicInterval
      }

      mrbAuthorities {
        nodes {
          authority
          drawingNumber
          id
          isForOnOrbit
          locationDescriptions
        }
      }

      inventoryQuantityCrossContext {
        quantity
        supportOrganizationCode
      }

      itemMasters(orderBy: INVENTORY_QUANTITY_DESC) @include(if: $shouldFetchItemMasters) {
        nodes {
          asBuiltNumber
          drawingNumber
          nodeId
          inventoryQuantity
          initializationStatus
          inventoryQuantityCrossContext {
            quantity
            supportOrganizationCode
          }
          side
          subType
          itemDrawing {
            ...CosmicItemDrawingSlim
          }
        }
      }

      itemInstances(orderBy: INVENTORY_QUANTITY_DESC) @include(if: $shouldFetchItemInstances) {
        nodes {
          asBuiltNumber
          asDesignNumber
          drawingNumber
          lotNumber
          nodeId
          serialNumber
          side
          size
          subType
          inventories {
            nodes {
              status
              isInstalled
              initialReceiptDate
              nodeId
              subType
              unitCost
              location {
                ...CosmicLocationSlim
              }
            }
          }
          inventoryQuantity
          inventoryQuantityCrossContext {
            quantity
            supportOrganizationCode
          }
          itemDrawing {
            ...CosmicItemDrawingSlim
          }
        }
      }

      # ONLY FOR ITEM DRAWINGS, DON'T COPY TO OTHER TYPES
      changeSets(
        filter: { subType: { in: [ITEM_DRAWING_CODES_CHANGE, ITEM_DRAWING_VALID_SIZES_CHANGE] } }
        orderBy: CREATED_DATE_TIME_DESC
      ) @include(if: $shouldFetchChangeSets) {
        nodes {
          asBuiltNumber
          computedStatus
          createdByUserId
          createdDateTime
          drawingNumber
          id
          isApplied
          nodeId
          payload
          side
          subType
          status {
            ...CosmicChangeSetStatus
          }
        }
      }
    }
  }
`;
export const UpdateItemDrawingByDrawingNumber = gql `
  mutation UpdateItemDrawingByDrawingNumber($input: UpdateItemDrawingByDrawingNumberInput!) {
    updateItemDrawingByDrawingNumber(input: $input) {
      itemDrawing {
        nodeId
      }
    }
  }
`;
let ItemDrawingRepoMixin = class ItemDrawingRepoMixin extends Mixins(LoaderMixin) {
    getItemDrawingByNodeId = queryFactory(this, GetItemDrawingByNodeId);
    getItemDrawingByDrawingNumberWithOptionalHardware = queryFactory(this, GetItemDrawingByDrawingNumberWithOptionalHardware);
    getItemDrawingsWithOptionalHardware = queryFactory(this, ItemDrawingsWithOptionalHardware);
    updateItemDrawingByDrawingNumber = mutationFactory(this, UpdateItemDrawingByDrawingNumber);
};
ItemDrawingRepoMixin = __decorate([
    Component
], ItemDrawingRepoMixin);
export { ItemDrawingRepoMixin };
