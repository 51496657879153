var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { ChangeSetType } from '~/db_types';
import BaseModalAssembly from '~/nasa_ui/base/BaseModalAssembly';
import { INumberOfChangeSets } from '~/nasa_ui/components/MiniAssembly/MiniAssembly';
let ModalAssemblyUninstall = class ModalAssemblyUninstall extends Mixins(BaseModalAssembly) {
    numberOfChangeSets;
    formData = {
        authorityCode: null,
        authorityNumber: null,
        comment: null,
        overrideRemovalDate: null
    };
    get numberOfOpenChildChangeSets() {
        if (!this.numberOfChangeSets?.openChildChangeSets) {
            return 0;
        }
        return this.numberOfChangeSets?.openChildChangeSets.length;
    }
    get numberOfOpenParentChangeSets() {
        if (!this.numberOfChangeSets?.openParentChangeSets) {
            return 0;
        }
        return this.numberOfChangeSets?.openParentChangeSets.length;
    }
    get removalChangeSetVariables() {
        if (!this.selectedWorkingAssemblyInventory?.id || !this.parentData?.inventory?.id) {
            return null;
        }
        return {
            authorityCode: this.formData?.authorityCode,
            authorityNumber: this.formData?.authorityNumber,
            comment: this.formData?.comment,
            inventoryId: this.parentData?.inventory?.id,
            childInventoryId: this.selectedWorkingAssemblyInventory?.id,
            subType: ChangeSetType.ITEM_INSTANCE_INVENTORY_ASSEMBLY_REMOVE,
            // if override date has been altered, pass openedDate
            ...(this.formData?.overrideRemovalDate ? { openedDate: this.formData?.overrideRemovalDate } : {}),
            openedDate: this.$dateDisplay(new Date())
        };
    }
    async onClickOfRequestRemoval() {
        if (!this.removalChangeSetVariables) {
            return;
        }
        try {
            await this.createEntity(this.removalChangeSetVariables);
            this.$message(`reload_${this.$route.params.id}`);
            // if this is the last child of the root node, let's broadsast the uninstall
            if (!this.parentData?.parent && this.parentData?.childWorkingAssemblies.nodes.length === 1) {
                this.$message('last_child_requested_uninstall');
            }
        }
        catch (error) {
            this.$errorUtility({
                err: error
            });
        }
    }
};
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof INumberOfChangeSets !== "undefined" && INumberOfChangeSets) === "function" ? _a : Object)
], ModalAssemblyUninstall.prototype, "numberOfChangeSets", void 0);
ModalAssemblyUninstall = __decorate([
    Component
], ModalAssemblyUninstall);
export default ModalAssemblyUninstall;
