import { format, toDate, utcToZonedTime } from 'date-fns-tz';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { CENTRAL_TIME_ZONE } from '~/nasa_ui/types';
import { isDateOnlyDateString } from '.';
/**
 * Returns a "central-zoned" date string.
 *
 * The display will assume that the date given is in UTC. If provided a date
 * string with an offset, it will follow the offset. If the date to be displayed
 * is already in central, pass false to the third parameter.
 *
 * @param date The date to display.
 * @param displayValue Default display value.
 * @param isUtc Is the date argument in UTC?
 * @returns A date string in the format `yyyy-MM-dd`.
 */
export var DateFormatOptions;
(function (DateFormatOptions) {
    DateFormatOptions["YearMonthDay"] = "yyyy-MM-dd";
    DateFormatOptions["ReadableMonthPlusYear"] = "LLL yyyy"; // Oct 2021
})(DateFormatOptions || (DateFormatOptions = {}));
export const dateDisplay = (date, { displayValue = DEFAULT_DASH, isUtc = true, dateFormat = DateFormatOptions.YearMonthDay } = {}) => {
    if (!date) {
        return displayValue;
    }
    // if it's already yyyy-mm-dd format, return it
    if (typeof date === 'string' && isDateOnlyDateString(date)) {
        return date;
    }
    if (date.toString().endsWith('T00:00:00Z') || date.toString().endsWith('T00:00:00+00:00')) {
        date = date.toString().replace('T00:00:00Z', 'T12:00:00Z').replace('T00:00:00+00:00', 'T12:00:00+00:00');
    }
    const zonedTime = isUtc ? utcToZonedTime(date, CENTRAL_TIME_ZONE) : toDate(date, { timeZone: CENTRAL_TIME_ZONE });
    return format(zonedTime, dateFormat);
};
