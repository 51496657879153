var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('c-card', {
    attrs: {
      "border-color": _vm.borderColor,
      "border-size": _vm.borderSize,
      "remove-shadow": _vm.removeShadow
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "center"
    }
  }, [_c('ButtonGeneric', {
    staticClass: "mb-2",
    attrs: {
      "color": _vm.getTabColor(_vm.PartReferenceTab.NEW_PART_REFERENCE),
      "icon": _vm.$icons.create,
      "text-color": _vm.getTabTextColor(_vm.PartReferenceTab.NEW_PART_REFERENCE),
      "small": ""
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.currentTab = _vm.PartReferenceTab.NEW_PART_REFERENCE;
      }
    }
  }, [_vm._v(" New part ")]), _c('ButtonGeneric', {
    staticClass: "mb-2",
    attrs: {
      "color": _vm.getTabColor(_vm.PartReferenceTab.PART_REFERENCE_SEARCH),
      "icon": _vm.$icons.search,
      "text-color": _vm.getTabTextColor(_vm.PartReferenceTab.PART_REFERENCE_SEARCH),
      "small": ""
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.currentTab = _vm.PartReferenceTab.PART_REFERENCE_SEARCH;
      }
    }
  }, [_vm._v(" Search parts ")]), _c('ButtonGeneric', {
    staticClass: "mb-2",
    attrs: {
      "color": _vm.getTabColor(_vm.PartReferenceTab.SELECTED_PART_REFERENCE),
      "icon": _vm.selectedPartReference && _vm.selectedPartReference.itemInstanceId ? _vm.$icons.item_instances : _vm.$icons.part_reference,
      "text-color": _vm.getTabTextColor(_vm.PartReferenceTab.SELECTED_PART_REFERENCE),
      "small": ""
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.currentTab = _vm.PartReferenceTab.SELECTED_PART_REFERENCE;
      }
    }
  }, [_vm._v(" Selected part ")])], 1), _vm.currentTab === _vm.PartReferenceTab.NEW_PART_REFERENCE ? _c('div', [_c('div', {
    staticClass: "mb-2"
  }, [_c('AppCheckbox', {
    attrs: {
      "label": "Is this a Tool?"
    },
    model: {
      value: _vm.newPartReferenceFormData.isTool,
      callback: function callback($$v) {
        _vm.$set(_vm.newPartReferenceFormData, "isTool", $$v);
      },
      expression: "newPartReferenceFormData.isTool"
    }
  })], 1), _c('div', {
    staticClass: "c-grid"
  }, [_c('div', {
    staticClass: "col-half mb-2"
  }, [_c('AppText', {
    attrs: {
      "label": "Description"
    },
    model: {
      value: _vm.newPartReferenceFormData.drawingDescription,
      callback: function callback($$v) {
        _vm.$set(_vm.newPartReferenceFormData, "drawingDescription", $$v);
      },
      expression: "newPartReferenceFormData.drawingDescription"
    }
  })], 1), _c('div', {
    staticClass: "col-third mb-2"
  }, [_c('AppText', {
    attrs: {
      "label": "Drawing number"
    },
    model: {
      value: _vm.newPartReferenceFormData.drawingNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.newPartReferenceFormData, "drawingNumber", $$v);
      },
      expression: "newPartReferenceFormData.drawingNumber"
    }
  })], 1), _c('div', {
    staticClass: "col-sixth mb-2"
  }, [_c('AppText', {
    attrs: {
      "label": "AsBuilt"
    },
    model: {
      value: _vm.newPartReferenceFormData.asBuiltNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.newPartReferenceFormData, "asBuiltNumber", $$v);
      },
      expression: "newPartReferenceFormData.asBuiltNumber"
    }
  })], 1)]), _c('div', {
    staticClass: "c-grid"
  }, [_c('div', {
    staticClass: "col-sixth mb-2"
  }, [_c('AppSelectEnum', {
    attrs: {
      "display": _vm.SideDisplay,
      "enum": _vm.Side,
      "color": _vm.EntityType.ITEM_MASTER,
      "label": "Side",
      "sort": ""
    },
    model: {
      value: _vm.newPartReferenceFormData.side,
      callback: function callback($$v) {
        _vm.$set(_vm.newPartReferenceFormData, "side", $$v);
      },
      expression: "newPartReferenceFormData.side"
    }
  })], 1), _c('div', {
    staticClass: "col-sixth mb-2"
  }, [_c('AppText', {
    attrs: {
      "label": "Size"
    },
    model: {
      value: _vm.newPartReferenceFormData.size,
      callback: function callback($$v) {
        _vm.$set(_vm.newPartReferenceFormData, "size", $$v);
      },
      expression: "newPartReferenceFormData.size"
    }
  })], 1), _c('div', {
    staticClass: "col-sixth mb-2"
  }, [_c('AppText', {
    attrs: {
      "label": "Serial"
    },
    model: {
      value: _vm.newPartReferenceFormData.serialNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.newPartReferenceFormData, "serialNumber", $$v);
      },
      expression: "newPartReferenceFormData.serialNumber"
    }
  })], 1), _c('div', {
    staticClass: "col-sixth mb-2"
  }, [_c('AppText', {
    attrs: {
      "label": "Lot"
    },
    model: {
      value: _vm.newPartReferenceFormData.lotNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.newPartReferenceFormData, "lotNumber", $$v);
      },
      expression: "newPartReferenceFormData.lotNumber"
    }
  })], 1), _c('div', {
    staticClass: "col-sixth mb-2"
  }, [_c('AppSelectEnum', {
    attrs: {
      "display": _vm.ItemInstanceInventoryTypeDisplay,
      "enum": _vm.ItemInstanceInventoryTypeWithoutMultiple,
      "sort": false,
      "value": _vm.newPartReferenceFormData.itemClass && _vm.newPartReferenceFormData.itemClass[0],
      "label": "Class",
      "monospace-selections": ""
    },
    on: {
      "input": function input($event) {
        _vm.newPartReferenceFormData.itemClass = [$event];
      }
    }
  })], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('AppText', {
    attrs: {
      "label": "Calibration number"
    },
    model: {
      value: _vm.newPartReferenceFormData.calibrationNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.newPartReferenceFormData, "calibrationNumber", $$v);
      },
      expression: "newPartReferenceFormData.calibrationNumber"
    }
  })], 1)]), _c('div', {
    staticClass: "c-grid my-2"
  }, [_c('div', {
    staticClass: "col-third mb-2"
  }, [_c('AppDate', {
    attrs: {
      "label": "Birth"
    },
    model: {
      value: _vm.newPartReferenceFormData.birthDate,
      callback: function callback($$v) {
        _vm.$set(_vm.newPartReferenceFormData, "birthDate", $$v);
      },
      expression: "newPartReferenceFormData.birthDate"
    }
  })], 1), _c('div', {
    staticClass: "col-third mb-2"
  }, [_c('AppDate', {
    attrs: {
      "label": "Calibration"
    },
    model: {
      value: _vm.newPartReferenceFormData.lastCalibrationDate,
      callback: function callback($$v) {
        _vm.$set(_vm.newPartReferenceFormData, "lastCalibrationDate", $$v);
      },
      expression: "newPartReferenceFormData.lastCalibrationDate"
    }
  })], 1), _c('div', {
    staticClass: "col-third mb-2"
  }, [_c('AppDate', {
    attrs: {
      "label": "Usage life expiry"
    },
    model: {
      value: _vm.newPartReferenceFormData.usageLifeExpirationDate,
      callback: function callback($$v) {
        _vm.$set(_vm.newPartReferenceFormData, "usageLifeExpirationDate", $$v);
      },
      expression: "newPartReferenceFormData.usageLifeExpirationDate"
    }
  })], 1), _c('div', {
    staticClass: "col-third mb-2"
  }, [_c('AppDate', {
    attrs: {
      "label": "Shelf life expiry"
    },
    model: {
      value: _vm.newPartReferenceFormData.shelfLifeExpirationDate,
      callback: function callback($$v) {
        _vm.$set(_vm.newPartReferenceFormData, "shelfLifeExpirationDate", $$v);
      },
      expression: "newPartReferenceFormData.shelfLifeExpirationDate"
    }
  })], 1), _c('div', {
    staticClass: "col-half mb-2"
  }, [_c('AppText', {
    attrs: {
      "label": "Location"
    },
    model: {
      value: _vm.newPartReferenceFormData.location,
      callback: function callback($$v) {
        _vm.$set(_vm.newPartReferenceFormData, "location", $$v);
      },
      expression: "newPartReferenceFormData.location"
    }
  })], 1)])]) : _vm._e(), _vm.currentTab === _vm.PartReferenceTab.PART_REFERENCE_SEARCH ? _c('div', {
    staticClass: "mb-2"
  }, [_c('SearchPartReference', {
    attrs: {
      "output-mode": "object"
    },
    on: {
      "input": function input($event) {
        _vm.selectedPartReference = $event;
      }
    }
  })], 1) : _vm._e(), _vm.currentTab === _vm.PartReferenceTab.SELECTED_PART_REFERENCE ? _c('div', [!_vm.selectedPartReference ? _c('AppPageDescription', {
    attrs: {
      "color": _vm.AlertType.WARNING
    }
  }, [_vm._v(" No part selected. ")]) : _vm.selectedPartReference.itemInstanceId ? _c('AppItemInstanceDisplay', {
    staticClass: "mt-2",
    attrs: {
      "id": _vm.selectedPartReference.itemInstanceId
    }
  }) : _c('div', [_c('div', {
    staticClass: "mb-2"
  }, [_c('AppCheckbox', {
    attrs: {
      "label": "Is this a Tool?"
    },
    model: {
      value: _vm.selectedPartReferenceFormData.isTool,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedPartReferenceFormData, "isTool", $$v);
      },
      expression: "selectedPartReferenceFormData.isTool"
    }
  })], 1), _c('div', {
    staticClass: "c-grid"
  }, [_c('div', {
    staticClass: "col-half mb-2"
  }, [_c('AppText', {
    attrs: {
      "label": "Description"
    },
    model: {
      value: _vm.selectedPartReferenceFormData.drawingDescription,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedPartReferenceFormData, "drawingDescription", $$v);
      },
      expression: "selectedPartReferenceFormData.drawingDescription"
    }
  })], 1), _c('div', {
    staticClass: "col-third mb-2"
  }, [_c('AppText', {
    attrs: {
      "label": "Drawing number"
    },
    model: {
      value: _vm.selectedPartReferenceFormData.drawingNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedPartReferenceFormData, "drawingNumber", $$v);
      },
      expression: "selectedPartReferenceFormData.drawingNumber"
    }
  })], 1), _c('div', {
    staticClass: "col-sixth mb-2"
  }, [_c('AppText', {
    attrs: {
      "label": "AsBuilt"
    },
    model: {
      value: _vm.selectedPartReferenceFormData.asBuiltNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedPartReferenceFormData, "asBuiltNumber", $$v);
      },
      expression: "selectedPartReferenceFormData.asBuiltNumber"
    }
  })], 1)]), _c('div', {
    staticClass: "c-grid"
  }, [_c('div', {
    staticClass: "col-sixth mb-2"
  }, [_c('AppSelectEnum', {
    attrs: {
      "display": _vm.SideDisplay,
      "enum": _vm.Side,
      "color": _vm.EntityType.ITEM_MASTER,
      "label": "Side",
      "required": "",
      "sort": ""
    },
    model: {
      value: _vm.selectedPartReferenceFormData.side,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedPartReferenceFormData, "side", $$v);
      },
      expression: "selectedPartReferenceFormData.side"
    }
  })], 1), _c('div', {
    staticClass: "col-sixth mb-2"
  }, [_c('AppText', {
    attrs: {
      "label": "Size"
    },
    model: {
      value: _vm.newPartReferenceFormData.size,
      callback: function callback($$v) {
        _vm.$set(_vm.newPartReferenceFormData, "size", $$v);
      },
      expression: "newPartReferenceFormData.size"
    }
  })], 1), _c('div', {
    staticClass: "col-sixth mb-2"
  }, [_c('AppText', {
    attrs: {
      "label": "Serial"
    },
    model: {
      value: _vm.selectedPartReferenceFormData.serialNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedPartReferenceFormData, "serialNumber", $$v);
      },
      expression: "selectedPartReferenceFormData.serialNumber"
    }
  })], 1), _c('div', {
    staticClass: "col-sixth mb-2"
  }, [_c('AppText', {
    attrs: {
      "label": "Lot"
    },
    model: {
      value: _vm.selectedPartReferenceFormData.lotNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedPartReferenceFormData, "lotNumber", $$v);
      },
      expression: "selectedPartReferenceFormData.lotNumber"
    }
  })], 1), _c('div', {
    staticClass: "col-sixth mb-2"
  }, [_c('AppSelectEnum', {
    attrs: {
      "display": _vm.ItemInstanceInventoryTypeDisplay,
      "enum": _vm.ItemInstanceInventoryTypeWithoutMultiple,
      "sort": false,
      "value": _vm.selectedPartReferenceFormData.itemClass && _vm.selectedPartReferenceFormData.itemClass[0],
      "label": "Class",
      "monospace-selections": ""
    },
    on: {
      "input": function input($event) {
        _vm.selectedPartReferenceFormData.itemClass = [$event];
      }
    }
  })], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('AppText', {
    attrs: {
      "label": "Calibration number"
    },
    model: {
      value: _vm.selectedPartReferenceFormData.calibrationNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedPartReferenceFormData, "calibrationNumber", $$v);
      },
      expression: "selectedPartReferenceFormData.calibrationNumber"
    }
  })], 1)]), _c('div', {
    staticClass: "c-grid my-2"
  }, [_c('div', {
    staticClass: "col-third mb-2"
  }, [_c('AppDate', {
    attrs: {
      "label": "Birth"
    },
    model: {
      value: _vm.selectedPartReferenceFormData.birthDate,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedPartReferenceFormData, "birthDate", $$v);
      },
      expression: "selectedPartReferenceFormData.birthDate"
    }
  })], 1), _c('div', {
    staticClass: "col-third mb-2"
  }, [_c('AppDate', {
    attrs: {
      "label": "Calibration"
    },
    model: {
      value: _vm.selectedPartReferenceFormData.lastCalibrationDate,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedPartReferenceFormData, "lastCalibrationDate", $$v);
      },
      expression: "selectedPartReferenceFormData.lastCalibrationDate"
    }
  })], 1), _c('div', {
    staticClass: "col-third mb-2"
  }, [_c('AppDate', {
    attrs: {
      "label": "Usage life expiry"
    },
    model: {
      value: _vm.selectedPartReferenceFormData.usageLifeExpirationDate,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedPartReferenceFormData, "usageLifeExpirationDate", $$v);
      },
      expression: "selectedPartReferenceFormData.usageLifeExpirationDate"
    }
  })], 1), _c('div', {
    staticClass: "col-third mb-2"
  }, [_c('AppDate', {
    attrs: {
      "label": "Shelf life expiry"
    },
    model: {
      value: _vm.selectedPartReferenceFormData.shelfLifeExpirationDate,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedPartReferenceFormData, "shelfLifeExpirationDate", $$v);
      },
      expression: "selectedPartReferenceFormData.shelfLifeExpirationDate"
    }
  })], 1), _c('div', {
    staticClass: "col-half mb-2"
  }, [_c('AppText', {
    attrs: {
      "label": "Location"
    },
    model: {
      value: _vm.selectedPartReferenceFormData.location,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedPartReferenceFormData, "location", $$v);
      },
      expression: "selectedPartReferenceFormData.location"
    }
  })], 1)])]), _c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "gap": "1rem"
    }
  }, [_vm.selectedPartReference && !_vm.selectedPartReference.itemInstanceId ? _c('ButtonCancel', {
    attrs: {
      "disabled": !_vm.hasSelectedPartReferenceBeenEdited
    },
    on: {
      "click": _vm.onClickOfUndoEditFormChanges
    }
  }, [_vm._v("Undo form changes")]) : _vm._e(), _vm.selectedPartReference ? _c('ButtonClearSelection', {
    staticClass: "mt-2",
    staticStyle: {
      "margin-left": "auto"
    },
    on: {
      "click": _vm.onClickOfClearSelection
    }
  }) : _vm._e()], 1)], 1) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };