import { __decorate, __metadata } from "tslib";
import { Component, Emit } from 'vue-property-decorator';
import BaseModal from '~/nasa_ui/base/BaseModal';
let AppModal = class AppModal extends BaseModal {
    get isFormValid() {
        return Boolean(this.formData);
    }
    editEntity(payload, nodeId) {
        throw new Error('Method not implemented.');
    }
    deleteEntity(nodeId) {
        throw new Error('Method not implemented.');
    }
    createEntity(payload) {
        throw new Error('Method not implemented.');
    }
    fetchEntity(id) {
        throw new Error('Method not implemented.');
    }
    emitModalClose() { }
};
__decorate([
    Emit('modal-close'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppModal.prototype, "emitModalClose", null);
AppModal = __decorate([
    Component
], AppModal);
export default AppModal;
