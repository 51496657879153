import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let IconNotCosmic = class IconNotCosmic extends Vue {
    color;
};
__decorate([
    Prop({
        type: String,
        default: '#ffffff'
    }),
    __metadata("design:type", String)
], IconNotCosmic.prototype, "color", void 0);
IconNotCosmic = __decorate([
    Component
], IconNotCosmic);
export default IconNotCosmic;
