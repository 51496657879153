import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let ReadOnlyMixin = class ReadOnlyMixin extends Vue {
    readOnly;
    get isReadOnly() {
        return this.readOnly;
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], ReadOnlyMixin.prototype, "readOnly", void 0);
ReadOnlyMixin = __decorate([
    Component
], ReadOnlyMixin);
export default ReadOnlyMixin;
