import { EntityType } from '~/nasa_ui/types';
export const typenameToEntityType = (typename) => {
    switch (typename) {
        case 'Activity':
            return EntityType.ACTIVITY;
        case 'ChangeSet':
            return EntityType.CHANGE_SET;
        case 'Contract':
            return EntityType.CONTRACT;
        case 'Crew':
            return EntityType.CREW;
        case 'Document':
            return EntityType.DOCUMENT;
        case 'Event':
            return EntityType.EVENT;
        case 'Inventory':
            return EntityType.INVENTORY;
        case 'ItemInstance':
            return EntityType.ITEM_INSTANCE;
        case 'ItemMaster':
            return EntityType.ITEM_MASTER;
        case 'ItemDrawing':
            return EntityType.ITEM_DRAWING;
        case 'Location':
            return EntityType.LOCATION;
        case 'Organization':
            return EntityType.ORGANIZATION;
        case 'User':
            return EntityType.USER;
        case 'UserGroup':
            return EntityType.USER_GROUP;
        default:
            return null;
    }
};
