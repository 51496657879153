import { getStaticResourcePath } from '~/nasa_ui/constants';
import { EsocApplication } from '~/nasa_ui/types/cosmic/paths';
import { CosmicSystem } from '~/nasa_ui/types/cosmic/system';
export const baseAssetPath = getStaticResourcePath();
export const getAssetPath = (appName) => {
    let cosmicSystem = null;
    if (Object.values(EsocApplication).includes(appName)) {
        cosmicSystem = CosmicSystem.ESOC;
    }
    else {
        throw new Error(`Asset path ${cosmicSystem}/${appName} is not supported by getAssetPath.`);
    }
    return `${baseAssetPath}/${cosmicSystem.toLowerCase()}/${appName.toLowerCase()}`;
};
export const getBaseImagePath = () => {
    return `${baseAssetPath}/images`;
};
